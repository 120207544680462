import React, { FC } from 'react';
import { css } from 'emotion';
import { Text } from '@weave/design-system';
import {
  DSMSelect as Select,
  SelectItem,
  weaveBlue,
} from '@weave/platform-ui-components';

import { DeviceOptionModel } from '../../../redux/actions/devices';

interface Props {
  id: string;
  label: string;
  onChange: (value: string) => void;
  value?: string | null;
  options: DeviceOptionModel[];
}

export const DeviceRowSelect: FC<Props> = ({ id, label, value, onChange, options }) => {
  return (
    <li
      className={css`
        margin-top: 24px;
        max-width: 300px;

        .MuiFormControl-root {
          margin-bottom: 0;

          .MuiInputBase-root {
            height: 40px;
          }

          .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: ${weaveBlue};
          }
        }
      `}
    >
      <Text
        size="medium"
        weight="bold"
        className={css`
          margin-bottom: 8px;
        `}
      >
        {label}
      </Text>
      <Select
        id={id}
        name={id}
        label=""
        fullWidth
        value={value || 'null'}
        onChange={(e) => onChange(e.target.value)}
      >
        {options.map((opt) => (
          <SelectItem key={opt.label} value={opt.value || 'null'}>
            {opt.label}
          </SelectItem>
        ))}
      </Select>
    </li>
  );
};
