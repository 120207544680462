import React from 'react';
import { css } from 'emotion';
import { locationDashboardStyles } from './location-dashboard.styles';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import { InteractiveCellText } from '../../interactive-cell-text.component';
import { dateTimeFormat } from '../../../../helpers/utils';
import { Writebacks } from '../../../../redux/actions/smart-alerts/smart-alerts.action';
import { DotSvg } from './dot-svg';

interface Props {
  writebacksData: Writebacks[];
}

export interface State {
  modalOpen: boolean;
  startDate: Date;
  endDate: Date;
}

const formatDate = (seconds: string | number): string => {
  if (!seconds) {
    return '';
  }

  return moment(seconds, ['X', 'YYYY-MM-DDTHH:mm:ssZ']).format(dateTimeFormat);
};

export default class WritebackTableComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    moment.locale('en');
    momentLocalizer();

    this.state = {
      modalOpen: false,
      startDate: moment().subtract(1, 'week').toDate(),
      endDate: new Date(),
    };
  }

  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };

  render() {
    return (
      <tbody className={locationDashboardStyles.tableBody}>
        {!this.props.writebacksData
          ? 'Loading props writebacks data'
          : this.props.writebacksData.map((writeback, index) => (
              <tr key={index}>
                <td> Writeback </td>
                <td className={locationDashboardStyles.dotColumn}>
                  <DotSvg color="error" />
                  {formatDate(writeback.writeback_modified_at)}
                </td>
                <td
                  className={css`
                    padding: 10px;
                  `}
                >
                  {writeback.writeback_status_description === ''
                    ? 'No description available'
                    : writeback.writeback_status_description.substring(
                        0,
                        writeback.writeback_status_description.indexOf('|')
                      )}
                </td>
                <td>
                  <div className={locationDashboardStyles.tableActions}>
                    <InteractiveCellText
                      text={
                        writeback.writeback_status_description === ''
                          ? 'There are no details for the alert'
                          : 'Type: ' +
                            writeback.source_type +
                            '\nSource ID: ' +
                            writeback.source_id +
                            '\nID' +
                            writeback.id +
                            '\nDescription: ' +
                            writeback.writeback_status_description
                      }
                    />
                  </div>
                </td>
              </tr>
            ))}
      </tbody>
    );
  }
}
