import { Reducer } from 'redux';
import { keyBy, omit } from 'lodash';
import { LocationsState, LocationActions, LocationsActionTypes } from './locations.types';
import { reduceReducers } from '../../../store/store-utils';
import {
  LocationPhoneNumbersActions,
  locationPhoneNumbersReducer,
} from '../location-entities/location-phone-numbers/location-phone-numbers.actions';
import {
  LocationRingtonesActions,
  locationRingtonesReducer,
} from '../location-entities/location-ringtones/location-ringtones.actions';
import {
  LocationDevicesActions,
  locationDevicesReducer,
} from '../location-entities/location-devices/location-devices.actions';
import {
  LocationFwdNumbersActions,
  locationFwdNumbersReducer,
} from '../location-entities/location-fwd-numbers/location-fwd-numbers.actions';
import {
  LocationDepartmentsActions,
  locationDepartmentsReducer,
} from '../location-entities/location-departments/location-departments.actions';

export const rootLocationsReducer: Reducer<LocationsState, LocationActions> = (
  state = {},
  action
) => {
  switch (action.type) {
    case LocationsActionTypes.AddLocations:
      return {
        ...state,
        ...keyBy(action.payload, (location) => location.LocationID),
      };
    case LocationsActionTypes.RemoveLocation:
      return omit(state, action.payload);
    case LocationsActionTypes.UpdateLocation: {
      const location = state[action.payload.locationId];
      return location
        ? {
            ...state,
            [action.payload.locationId]: { ...location, ...action.payload.location },
          }
        : state;
    }
    default:
      return state;
  }
};

type CombinedActions =
  | LocationActions
  | LocationPhoneNumbersActions
  | LocationRingtonesActions
  | LocationDevicesActions
  | LocationFwdNumbersActions
  | LocationDepartmentsActions;

export const locationsReducer = reduceReducers<LocationsState, CombinedActions>(
  rootLocationsReducer,
  locationRingtonesReducer,
  locationPhoneNumbersReducer,
  locationDevicesReducer,
  locationFwdNumbersReducer,
  locationDepartmentsReducer
);
