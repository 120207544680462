import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useAlert } from '@weave/alert-system';
import { WeaveTheme } from '@weave/theme-original';
import { NakedButton, PrimaryButton, XIcon, Text } from '@weave/design-system';
import { DSMSelect as Select, SelectItem } from '@weave/platform-ui-components';
import { TableStyles } from '../../../styles/table-style';
import { find } from 'lodash';
import { css } from '@emotion/core';

import { Page } from '../../shared/page/page.component';
import { useResources } from '../../../helpers/useResources';
import {
  selectCurrentLocationId,
  selectIsMultiLocationBeta,
} from '../../../redux/actions/location/current-location';
import { Store } from '../../../redux/store/store.model';
import { CallGroup, CallLeg } from './call-groups.types';
import { PHONES, injectParams } from '../../../constants/paths';
import { DeviceModel } from '../../../redux/actions/devices/devices.types';
import { CallForwardingNumber } from '../../../redux/actions/call-forwarding/call-forwarding.types';
import { AutoSaveInput } from '../auto-save-input.component';
import { CustomAxios } from '../../../redux/axios';
import { ConfirmationModal } from '../../shared/confirmation-modal/confirmation-modal.component';
import { CallLegSlider } from './call-leg-slider';
import { AssignDevicesModal } from './assign-devices-modal/AssignDevicesModal';
import { Ringtone } from '../../../redux/actions/ringtones/ringtones.types';
import { CallGroupLocationData } from '../../../redux/actions';

const reflect = (promise) =>
  promise.then(
    (value) => ({ value, resolved: true }),
    (error) => {
      console.error(error);
      return { error, resolved: false };
    }
  );

export const CallGroupEdit = () => {
  const history = useHistory();
  const [originalLocationId, setOriginalLocationId] = useState<string>('');
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);
  const [assignModalIsOpen, setAssignModalIsOpen] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const [callLegPendingDelete, setCallLegPendingDelete] = useState<CallLeg | null>(null);
  const { locationId } = useSelector((store: Store) => ({
    locationId: selectCurrentLocationId(store),
  }));
  const { callGroupId } = useParams<{ callGroupId: string }>();
  const alerts = useAlert();
  const isMultiOfficeBeta = useSelector(selectIsMultiLocationBeta);

  const {
    loading: loadingCallGroup,
    data: callGroup,
    setResources: setCallGroup,
    refresh: refreshCallGroup,
  } = useResources<CallGroup>(`support/v1/phone/callgroups/${callGroupId}`, {
    deps: [locationId],
    suppressFetch: !locationId,
    resourceLabel: 'call groups',
  });

  const { loading: loadingDevices, data: devices } = useResources<DeviceModel[]>(
    'support/v1/phone/devices?show=all',
    {
      deps: [locationId],
      suppressFetch: !locationId,
      resourceLabel: 'devices',
    }
  );

  const key = [useSelector(selectCurrentLocationId)];

  const { data: locationData } = useResources<CallGroupLocationData[]>(
    `support/v1/locations/${key}/family`,
    {
      resourceLabel: 'family',
    }
  );

  const locationMapper = {};
  if (locationData.length > 0) {
    locationData.map((location) => {
      locationMapper[location.LocationID] = location.Name;
      return locationMapper;
    });
  }

  const { loading: loadingRingtones, data: ringtones } = useResources<Ringtone[]>(
    'support/v1/phone/callgroups/options/ringtones',
    {
      deps: [locationId],
      suppressFetch: !locationId,
      resourceLabel: 'ringtones',
    }
  );

  const { loading: loadingForwardingNumbers, data: forwardingNumbers } = useResources<
    CallForwardingNumber[]
  >('support/v1/forwarding?show=all', {
    deps: [locationId],
    suppressFetch: !locationId,
    resourceLabel: 'forwarding numbers',
  });

  const goBackToCallGroups = () => {
    history.push(injectParams(PHONES.callGroups, { id: locationId }));
  };

  useEffect(() => {
    if (originalLocationId && locationId !== originalLocationId) {
      // Location changed so go back to call groups page
      goBackToCallGroups();
    } else if (locationId) {
      // Set the location we're starting with in local state
      setOriginalLocationId(locationId);
    }
  }, [locationId]);

  const onNameSave = async (newName: string) => {
    const { name, ...cg } = callGroup;
    const newCallGroup = {
      ...cg,
      name: newName,
    };

    // Optimistic Update
    setCallGroup(newCallGroup);

    // Persist the name change
    try {
      await CustomAxios.put(`support/v1/phone/callgroups/${cg.ID}`, newCallGroup);
    } catch {
      alerts.error('Error updating call group name. Please try again.');

      // Revert Optimistic Update
      setCallGroup({
        ...cg,
        name,
      });
    }
  };

  const onRingtoneChange = (activeCallLeg: CallLeg, newRingtone: string) => {
    const { ringtone, ...cl } = activeCallLeg;
    const originalCallGroup = { ...callGroup };
    const newCallLeg = {
      ...cl,
      ringtone: newRingtone === 'null' ? null : newRingtone,
    };

    // Optimistically update the call group
    setCallGroup({
      ...callGroup,
      callLegs: callGroup.callLegs?.map((callLeg) => {
        if (callLeg.ID === activeCallLeg.ID) {
          return newCallLeg;
        }

        return callLeg;
      }),
    });

    // Persist the name change
    try {
      CustomAxios.put(`support/v1/phone/calllegs/${activeCallLeg.ID}`, newCallLeg);
    } catch {
      alerts.error('Error updating device. Please try again.');

      // Revert optimistic update of the call group
      setCallGroup(originalCallGroup);
    }
  };

  const onConfirmDeleteCallLeg = async () => {
    const oldCallGroup = { ...callGroup };

    // Optimistacally delete the call leg and close the modal
    setCallGroup({
      ...callGroup,
      callLegs: callGroup.callLegs?.filter((cl) => {
        return cl.ID !== callLegPendingDelete?.ID;
      }),
    });
    setDeleteModalIsOpen(false);

    try {
      await CustomAxios.delete(`support/v1/phone/calllegs/${callLegPendingDelete?.ID}`);
    } catch {
      alerts.error(`Error removing device from the ${callGroup.name} call group.`);

      // Revert the optimistic update
      setCallGroup(oldCallGroup);
    }

    setCallLegPendingDelete(null);
  };

  const onRingChange = async (callLeg: CallLeg, delayStart: number, timeout: number) => {
    const oldCallGroup = { ...callGroup };
    const newCallLeg = {
      ...callLeg,
      delayStart,
      timeout,
    };

    // Optimistically update the call group
    setCallGroup({
      ...callGroup,
      callLegs: callGroup.callLegs?.map((cl) => {
        if (cl.ID === callLeg.ID) {
          return newCallLeg;
        }

        return cl;
      }),
    });

    // Persist the change
    try {
      await CustomAxios.put(`support/v1/phone/calllegs/${callLeg.ID}`, newCallLeg);
    } catch {
      alerts.error(`Server error updating the call leg`);

      // Revert the optimistic update
      setCallGroup(oldCallGroup);
    }
  };

  const onCreateNewCallLegs = async (sipIds: string[], fwdNumIds: string[]) => {
    setUpdating(true);

    try {
      const sipPromises = sipIds.map((sipId) =>
        CustomAxios.post('support/v1/phone/calllegs', {
          sipProfileID: sipId,
          callLegType: 'sip_profile',
          callGroupID: callGroup.ID,
        })
      );
      const fwdPromises = fwdNumIds.map((fnId) =>
        CustomAxios.post('support/v1/phone/calllegs', {
          forwardingNumberID: fnId,
          callLegType: 'forwarding_number',
          callGroupID: callGroup.ID,
        })
      );

      const results = await Promise.all([...sipPromises, ...fwdPromises].map(reflect));
      const errors = results.filter((result) => result.resolved === false);

      if (errors.length) {
        alerts.error(`${errors.length} devices failed to be assigned to the call group.`);
      }
    } catch {
      // global error handling
      alerts.error('Error assigning devices to call group');
    }

    setUpdating(false);
    refreshCallGroup();
  };

  return (
    <Page
      title={<AutoSaveInput onSave={onNameSave} originalText={callGroup.name} />}
      subtitle="Assign devices to this group, have them ring simultaneously or in a cascading order, and set each device's ring duration and ringtone. Note that mobile app devices can't have a delayed start."
      showBackBtn
      customBackUrl={injectParams(PHONES.callGroups, { id: locationId })}
      headerActions={
        <div>
          <PrimaryButton onClick={() => setAssignModalIsOpen(true)}>
            Assign Devices to Group
          </PrimaryButton>
        </div>
      }
    >
      <AssignDevicesModal
        callGroupName={callGroup.name}
        currentSipProfiles={callGroup.callLegs?.map((cl) => cl.sipProfileID ?? '') ?? []}
        locationMapper={locationMapper}
        currentForwardingNums={
          callGroup.callLegs?.map((cl) => cl.forwardingNumberID ?? '') ?? []
        }
        devices={devices}
        forwardingNumbers={forwardingNumbers ?? []}
        isOpen={assignModalIsOpen}
        onRequestClose={() => setAssignModalIsOpen(false)}
        onSave={onCreateNewCallLegs}
      />
      <ConfirmationModal
        title="Remove Device From Call Group"
        note={`Would you like to remove this device from the ${callGroup.name} call group? Your device will not be deleted.`}
        isOpen={deleteModalIsOpen}
        onRequestClose={() => setDeleteModalIsOpen(false)}
        confirmBtnTitle="Remove"
        onConfirmClick={onConfirmDeleteCallLeg}
      />
      <ReactTable
        columns={[
          {
            Header: 'Assigned Device (Type)',
            id: 'assignedDevice',
            minWidth: 260,
            maxWidth: 400,
            accessor: (callLeg) => {
              let sipName = '';
              let deviceName = '';
              let type = '';

              if (callLeg.callLegType === 'forwarding_number') {
                type = 'Forwarding';
                const fwdNumber = find(
                  forwardingNumbers,
                  (f) => f.id === callLeg.forwardingNumberID
                );

                sipName = fwdNumber?.name ?? '';
              } else {
                const activeDevice = devices.find((d) => {
                  return d.sipProfile?.ID === callLeg.sipProfileID;
                });

                sipName = (activeDevice?.sipProfile?.name || '-').substring(0, 20);
                deviceName = activeDevice?.name ?? '';
                type = activeDevice?.type === 'mobile_app' ? 'Mobile' : 'Desk';
              }

              return (
                <Text as="strong" size="large" weight="bold">
                  {sipName} ({type})
                  {type === 'Mobile' && (
                    <>
                      <br />
                      <Text size="medium" weight="regular">
                        {deviceName}
                      </Text>
                    </>
                  )}
                </Text>
              );
            },
          },
          {
            Header: 'Location',
            id: 'location',
            show: isMultiOfficeBeta ? true : false,
            minWidth: 140,
            maxWidth: 300,
            accessor: (callLeg) => {
              const activeDevice = devices.find((d) => {
                return d.sipProfile?.ID === callLeg.sipProfileID;
              });
              const activeFwdNum: CallForwardingNumber | undefined =
                forwardingNumbers?.find((num) => num.id === callLeg.ID);

              let location = 'NA';
              if (activeDevice && activeDevice.labels && activeDevice.labels[0]) {
                location = locationMapper[activeDevice.labels[0].value];
              } else if (activeFwdNum && activeFwdNum.Labels) {
                location = locationMapper[activeFwdNum.Labels[0].Value];
              }
              return (
                <Text
                  size="medium"
                  weight="regular"
                  css={css`
                    margin-left: 1rem;
                    min-width: 260px;
                  `}
                >
                  {location}
                </Text>
              );
            },
          },
          {
            Header: 'Ring Start and End Times',
            id: 'timingSlider',
            minWidth: 400,
            maxWidth: 600,
            accessor: (callLeg) => {
              const currentRingEnd =
                (callLeg.delayStart ?? 0) + (callLeg.timeout ?? 5000);
              const activeDevice = devices.find((d) => {
                return d.sipProfile?.ID === callLeg.sipProfileID;
              });

              return (
                <div
                  css={css`
                    margin-right: 32px;
                    padding: 32px 24px;
                    width: 100%;
                  `}
                >
                  <CallLegSlider
                    disableRingStartEdit={activeDevice?.type === 'mobile_app'}
                    maxRingEnd={Math.max(60000, currentRingEnd) / 1000}
                    ringStart={(callLeg.delayStart ?? 0) / 1000}
                    ringEnd={currentRingEnd / 1000}
                    onChange={(values: readonly number[]) => {
                      if (values.length === 1) {
                        const [ringEnd] = values;
                        onRingChange(callLeg, 0, ringEnd * 1000 || 5000);
                      } else {
                        const [ringStart, ringEnd] = values;
                        onRingChange(
                          callLeg,
                          ringStart * 1000,
                          (ringEnd - ringStart) * 1000
                        );
                      }
                    }}
                  />
                </div>
              );
            },
          },
          {
            Header: 'Ringtone',
            id: 'ringtone',
            minWidth: 230,
            accessor: (callLeg) => {
              const activeDevice: DeviceModel | undefined = devices.find((d) => {
                return d.sipProfile?.ID === callLeg.sipProfileID;
              });

              const isDeskPhone =
                callLeg.callLegType !== 'forwarding_number' &&
                activeDevice?.type === 'desk_phone';

              return (
                <>
                  {isDeskPhone && (
                    <Select
                      id={`ringtone-${callLeg.ID}`}
                      css={css`
                        margin: 0;
                        width: 150px;

                        .MuiInputBase-root {
                          height: 32px;
                          width: 150px;

                          .MuiSelect-root {
                            height: 32px;
                          }
                        }
                      `}
                      name="ringtone"
                      label=""
                      value={callLeg.ringtone || 'null'}
                      onChange={(e) => onRingtoneChange(callLeg, e.target.value)}
                    >
                      {ringtones.map((ringtone) => {
                        return (
                          <SelectItem
                            key={ringtone.value}
                            value={ringtone.value || 'null'}
                          >
                            {ringtone.label}
                          </SelectItem>
                        );
                      })}
                    </Select>
                  )}
                  {!isDeskPhone && (
                    <span
                      css={css`
                        width: 150px;
                      `}
                    >
                      {''}
                    </span>
                  )}
                  <NakedButton
                    css={(theme: WeaveTheme) => css`
                      margin-left: ${theme.spacing(4)};

                      :focus {
                        outline: none;
                      }
                    `}
                  >
                    <XIcon
                      aria-label="Remove"
                      css={(theme: WeaveTheme) => css`
                        cursor: pointer;
                        transition: color 300ms;

                        :hover {
                          color: ${theme.colors.gray[600]};
                        }
                      `}
                      color="light"
                      onClick={() => {
                        setCallLegPendingDelete(callLeg);
                        setDeleteModalIsOpen(true);
                      }}
                    />
                  </NakedButton>
                </>
              );
            },
          },
        ]}
        data={callGroup.callLegs}
        showPagination={false}
        loading={
          loadingCallGroup ||
          loadingDevices ||
          loadingRingtones ||
          loadingForwardingNumbers ||
          updating
        }
        pageSize={callGroup?.callLegs?.length || 5}
        defaultSorted={[{ id: 'name', desc: false }]}
        css={[
          TableStyles,
          css`
            .rt-th {
              :nth-of-type(2),
              :nth-of-type(3) {
                padding-left: 1rem !important;
              }
            }
          `,
        ]}
      />
    </Page>
  );
};
