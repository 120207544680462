import {
  OutageAlertActionTypes,
  OutageAlertState,
  SetActiveOutageAction,
} from './outage-alerts.types';

/**
 * @desc : Reducer for outage alerts data
 * @param state : OutageAlertState
 * @param action : SetActiveOutageAction
 * @returns : OutageAlertState
 */
export function outageAlertsReducer(
  state: OutageAlertState = [],
  action: SetActiveOutageAction
): OutageAlertState {
  switch (action.type) {
    case OutageAlertActionTypes.SET_ACTIVE_OUTAGE_ALERTS:
      return action.payload;
    default:
      return state;
  }
}
