import moment from 'moment';

enum DateRangeValues {
  today = 'today',
  yesterday = 'yesterday',
  weekToDate = 'weekToDate',
  lastWeek = 'lastWeek',
  last2Weeks = 'last2Weeks',
  last4Weeks = 'last4Weeks',
  last8Weeks = 'last8Weeks',
  last12Weeks = 'last12Weeks',
  monthToDate = 'monthToDate',
  lastMonth = 'lastMonth',
  last30Days = 'last30Days',
  yearToDate = 'yearToDate',
  lastYear = 'lastYear',
}
export type DateRangeValue = keyof typeof DateRangeValues;

export const dateRangeOptions: {
  value: DateRangeValues;
  label: string;
}[] = [
  {
    value: DateRangeValues.lastWeek,
    label: 'Last Week',
  },
  {
    value: DateRangeValues.last2Weeks,
    label: 'Last 2 Weeks',
  },
  {
    value: DateRangeValues.last4Weeks,
    label: 'Last 4 Weeks',
  },
  {
    value: DateRangeValues.last8Weeks,
    label: 'Last 8 Weeks',
  },
  {
    value: DateRangeValues.last12Weeks,
    label: 'Last 12 Weeks',
  },
];

export const dateFilter = (
  value: DateRangeValue,
  customStart?: moment.Moment,
  customEnd?: moment.Moment
): { start: number; end: number } => {
  const start = customStart || moment().startOf('day');
  const end = customEnd || moment();
  switch (value) {
    case DateRangeValues.today:
      start.startOf('day');
      break;
    case DateRangeValues.yesterday:
      start.startOf('day').subtract(1, 'day');
      end.endOf('day').subtract(1, 'day');
      break;
    case DateRangeValues.weekToDate:
      start.startOf('week');
      break;
    case DateRangeValues.lastWeek:
      start.startOf('week').subtract(1, 'week');
      end.endOf('week').subtract(1, 'week');
      break;
    case DateRangeValues.last2Weeks:
      start.startOf('week').subtract(2, 'week');
      break;
    case DateRangeValues.last4Weeks:
      start.startOf('week').subtract(4, 'week');
      break;
    case DateRangeValues.last8Weeks:
      start.startOf('week').subtract(8, 'week');
      break;
    case DateRangeValues.last12Weeks:
      start.startOf('week').subtract(12, 'week');
      break;
    case DateRangeValues.monthToDate:
      start.startOf('month');
      break;
    case DateRangeValues.lastMonth:
      start.startOf('month').subtract(1, 'month');
      break;
    case DateRangeValues.last30Days:
      start.startOf('day').subtract(30, 'days');
      break;
    case DateRangeValues.yearToDate:
      start.startOf('year');
      break;
    case DateRangeValues.lastYear:
      start.startOf('year').subtract(1, 'year');
      end.endOf('year').subtract(1, 'year');
      break;
    default:
      break;
  }
  return {
    start: parseInt(start.format('YYYYMMDD'), 10),
    end: parseInt(end.format('YYYYMMDD'), 10),
  };
};
