import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/core';
import noop from 'lodash/noop';
import { Heading, Text } from '@weave/design-system';
import { SyncAppInterface } from './sync-app.types';
import { SyncAppCardContainer } from './sync-app-card/sync-app-card.container';
import { SyncAppPopoutComponent } from './sync-app-card/sync-app-popout.component';
import { selectIntegrationsSyncApps } from '../../redux/actions/sync-app';

const noSyncAppDiv = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(600px - 2rem);
`;

const mainDiv = css`
  width: 100%;
  padding: 10px 15px;
`;

export const SyncApps = () => {
  const integrationsSyncApps = useSelector(selectIntegrationsSyncApps);
  const [poppedOutSyncApps, setPoppedOutSyncApps] = useState<SyncAppInterface[]>([]);

  const popoutSyncApp = (syncApp: SyncAppInterface) => {
    const syncAppOpen = poppedOutSyncApps.find(
      (item) => item.SourceID === syncApp.SourceID
    );
    if (!syncAppOpen) {
      setPoppedOutSyncApps([...poppedOutSyncApps, syncApp]);
    }
  };

  const closeSyncAppWindow = (sourceId: string) => {
    const syncAppOpen = poppedOutSyncApps.filter((item) => item.SourceID !== sourceId);
    setPoppedOutSyncApps(syncAppOpen);
  };

  return (
    <>
      <div css={mainDiv}>
        {integrationsSyncApps.length < 1 ? (
          <div css={noSyncAppDiv}>
            <Heading>No Sync App Detected</Heading>
            <Text>Please get a remote session on the server to install the DSA</Text>
          </div>
        ) : (
          integrationsSyncApps.map((syncApp) => (
            <SyncAppCardContainer
              key={syncApp.SourceID}
              syncApp={syncApp}
              popoutSyncApp={popoutSyncApp}
            />
          ))
        )}
      </div>
      {poppedOutSyncApps.map((syncApp) => (
        <SyncAppPopoutComponent
          key={syncApp.SourceID}
          sourceId={syncApp.SourceID}
          closeSyncAppWindow={closeSyncAppWindow}
          title={syncApp.PracticeManagementSystem}
        >
          <SyncAppCardContainer syncApp={syncApp} popoutSyncApp={noop} />
        </SyncAppPopoutComponent>
      ))}
    </>
  );
};
