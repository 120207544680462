export enum Endpoint {
  emailHistory = 'email-history',
  smsHistory = 'sms-history',
  oldSmsHistory = 'old-sms-history',
  syncs = 'syncs',
  writebacks = 'writebacks',
}

export const parseDate = (date: Date | string, longer?: boolean) => {
  const newDate = new Date(date).toISOString();
  if (!longer) return newDate.slice(0, 10); // Needed for all non-sms-history pages
  return newDate; // needed for sms-history page
};

export const getEndpoint = (
  endpoint: Endpoint,
  locationId?: string,
  start?: Date,
  end?: Date,
  limit?: number,
  skip?: number,
  order?: string
) => {
  switch (endpoint) {
    case Endpoint.writebacks:
      return (
        '/support/v1/writebacks?search=&start=' +
        start +
        '&end=' +
        end +
        '&limit=' +
        limit +
        '&skip=' +
        skip +
        '&order=' +
        order
      );
    case Endpoint.syncs:
      return '/support/v1/syncapp/health';
    case Endpoint.oldSmsHistory:
      // Parameters are used - without them, the messages scheduled to be sent are procured, which doesn't give any failure data
      return (
        `/sms/v2/sms/list?location_id=${locationId}&start=` +
        start +
        '&end=' +
        end +
        '&limit=' +
        limit +
        '&skip=' +
        skip
      );
    case Endpoint.smsHistory:
      // Parameters are used - without them, the messages scheduled to be sent are procured, which doesn't give any failure data
      return (
        `/sms/data/v3/sms/list?location_id=${locationId}&start=` +
        parseDate(start!, true) +
        '&end=' +
        parseDate(end!, true) +
        '&limit=' +
        limit +
        '&skip=' +
        skip
      );
    case Endpoint.emailHistory:
      return `/support/v1/locations/${locationId}/emails`;
    default:
      return '/';
  }
};
