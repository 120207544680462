import React, { useCallback, useState } from 'react';
import {
  ConfirmationModal,
  IconButton,
  PlusIcon,
  useModalControl,
} from '@weave/design-system';

import {
  schedulingApiV2,
  schedulingQueryV2,
} from '../../../../apis/protos/scheduling/scheduling.proto-api';
import { Provider } from './providers.types';
import { ProvidersTable } from './providers-table.component';
import { OnlineSchedulingTabHeader } from '../online-scheduling-tab-header.component';
import { ProviderForm } from './provider-form.component';
import { ProviderScheduleModal } from './provider-schedule-modal.component';

export const useProvidersQuery = schedulingQueryV2('/providers');
export const providersApi = schedulingApiV2('/providers');

export const useProviderQuery = schedulingQueryV2('/providers/{id}');
export const providerApi = schedulingApiV2('/providers/{id}');

export const useOfficeClosedTimesApi = schedulingApiV2('/offices/closed-times');
export const useOfficeClosedTimesQuery = schedulingQueryV2('/offices/closed-times');

interface ProvidersManagerProps {
  setCurrentTab: (tab: string) => void;
}

export const ProvidersManager = ({ setCurrentTab }: ProvidersManagerProps) => {
  const [selectedProvider, setSelectedProvider] = useState<Provider | null>(null);
  const { data: providersData, refetch: refetchProvidersData } = useProvidersQuery();
  const { data: officeHoursData } = useOfficeClosedTimesQuery();
  const {
    modalProps: confirmationModalProps,
    triggerProps: confirmationModalTriggerProps,
  } = useModalControl();
  const { modalProps: formModalProps, triggerProps: formTriggerProps } =
    useModalControl();
  const { modalProps: scheduleModalProps, triggerProps: scheduleTriggerProps } =
    useModalControl();
  //@ts-ignore - until imported types from proto-gen are resolved
  const { delete: deleteProvider } = providerApi({
    id: selectedProvider?.id ?? '',
  });

  const clearSelectedProvider = () => {
    setSelectedProvider(null);
  };

  const handleCancelDelete = useCallback(async () => {
    await clearSelectedProvider();
    confirmationModalProps.onClose();
  }, [selectedProvider]);

  const handleConfirmDelete = useCallback(async () => {
    if (selectedProvider?.id) {
      await deleteProvider().then(() => {
        refetchProvidersData();
      });
    }
    await clearSelectedProvider();
    confirmationModalProps.onClose();
  }, [selectedProvider]);

  return (
    <>
      <OnlineSchedulingTabHeader
        title="Providers"
        refresh={refetchProvidersData}
        refreshLabel="Refresh providers list"
      >
        <IconButton
          label="create a provider"
          onClick={async () => {
            await setSelectedProvider(null);
            formTriggerProps.onClick();
          }}
        >
          <PlusIcon />
        </IconButton>
      </OnlineSchedulingTabHeader>

      <ProvidersTable
        providers={providersData?.data?.providers || []}
        refetch={refetchProvidersData}
        setSelectedProvider={setSelectedProvider}
        confirmationModalTriggerProps={confirmationModalTriggerProps}
        formTriggerProps={formTriggerProps}
        scheduleTriggerProps={scheduleTriggerProps}
      />

      <ProviderForm
        modalProps={formModalProps}
        refetchProviders={refetchProvidersData}
        selectedProvider={selectedProvider}
        clearSelectedProvider={clearSelectedProvider}
        providers={providersData?.data?.providers || []}
      />

      <ConfirmationModal
        {...confirmationModalProps}
        title="Delete Provider"
        message={`Are you sure you want to delete ${selectedProvider?.firstName} ${selectedProvider?.lastName}?`}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />

      <ProviderScheduleModal
        modalProps={scheduleModalProps}
        selectedProvider={selectedProvider}
        clearSelectedProvider={clearSelectedProvider}
        officeHours={officeHoursData?.data}
        setCurrentTab={setCurrentTab}
      />
    </>
  );
};
