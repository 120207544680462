import React from 'react';
import { css } from '@emotion/core';
import { WeaveTheme } from '@weave/theme-original';
import {
  Heading,
  Modal,
  PrimaryButton,
  Text,
  ModalControlModalProps,
} from '@weave/design-system';

interface Props {
  modalProps: ModalControlModalProps;
  title: string;
  image?: string;
  description: string;
  features: string[];
}

export const SoftwareInfoModal = ({
  modalProps,
  title,
  image,
  description,
  features,
}: Props) => {
  return (
    <Modal {...modalProps} css={modalStyles}>
      <div css={modalContentStyles}>
        <Heading css={modalTitleStyles}>{title}</Heading>

        <img css={modalImageStyles} src={image} alt={`${title}`} />

        <Text css={modalDescriptionStyles}>{`${description}`}</Text>

        <ul css={featuresListStyles}>
          {features.map((feature, idx) => (
            <li key={idx} css={featuresListItemStyles}>
              <Text color="light">{feature}</Text>
            </li>
          ))}
        </ul>

        <PrimaryButton css={buttonStyles} onClick={modalProps.onClose}>
          Close
        </PrimaryButton>
      </div>
    </Modal>
  );
};

const modalStyles = css`
  width: 568px !important;
  padding: 32px 24px;
`;
const modalContentStyles = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;
`;
const modalTitleStyles = css`
  margin-bottom: 24px;
`;
const modalImageStyles = css`
  margin-bottom: 24px;
  height: 60px;
`;
const modalDescriptionStyles = css`
  margin-bottom: 32px;
  text-align: center;
`;
const featuresListStyles = (theme: WeaveTheme) => css`
  color: ${theme.colors.gray[500]};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
`;
const featuresListItemStyles = css`
  flex-basis: 45%;
  flex-grow: 1;
`;
const buttonStyles = css`
  width: 318px;
  height: 48px;
  margin-top: 64px;
`;
