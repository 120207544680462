import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { CustomAxios } from '../../../../../redux/axios';
import { useAlert } from '@weave/alert-system';
import { WeaveTheme } from '@weave/theme-original';
import { Heading, Text } from '@weave/design-system';
import { IntegrationsStatus, ReviewsMappings } from '../../helpers/types';
import { useSelector } from 'react-redux';
import { selectCurrentLocationId } from '../../../../../redux/actions/location/current-location';
import { getFacebookErrors, getGoogleErrors } from '../../helpers';
import {
  AuthenticationStatusDetail,
  SiteName,
} from './authentication-status-detail.component';

type AuthenticationStatusProps = {
  mappings: ReviewsMappings | null;
};

export const AuthenticationStatus = ({ mappings }: AuthenticationStatusProps) => {
  const locationId = useSelector(selectCurrentLocationId);
  const [facebookErrors, setFacebookErrors] = useState<string[]>([]);
  const [googleErrors, setGoogleErrors] = useState<string[]>([]);
  const alerts = useAlert();

  useEffect(() => {
    (async () => {
      try {
        if (mappings === null) {
          return;
        }
        const googleNeedsAttention = !!mappings.googleMapping?.needsAttention;
        const facebookNeedsAttention = !!mappings.facebookMapping?.needsAttention;
        if (googleNeedsAttention || facebookNeedsAttention) {
          const url = `/reviews/integrationsstatus?google=${googleNeedsAttention}&facebook=${facebookNeedsAttention}`;
          const response = await CustomAxios.get(url);
          const integrationsStatus: IntegrationsStatus = response.data.data;
          setFacebookErrors(getFacebookErrors(integrationsStatus.facebook));
          setGoogleErrors(getGoogleErrors(integrationsStatus.google));
        }
      } catch {
        alerts.error('Failed to get needs attention error states!');
      }
    })();
  }, [mappings, locationId]);

  return (
    <div
      css={css`
        flex-grow: 1;
      `}
    >
      <Heading level={2}>Authentication Status</Heading>
      {mappings === null ? (
        <Text
          color="light"
          size="large"
          weight="bold"
          css={css`
            margin: 0;
          `}
        >
          An error occurred while fetching mappings!
        </Text>
      ) : (
        <div
          css={(theme: WeaveTheme) => css`
            background: ${theme.colors.gray[100]};
            border: solid 1px ${theme.colors.gray[300]};
            margin: ${theme.spacing(1, 1, 1, 0)};
          `}
        >
          <AuthenticationStatusDetail
            site={SiteName.Google}
            mapping={mappings.googleMapping}
            errors={googleErrors}
          />
          <AuthenticationStatusDetail
            site={SiteName.Facebook}
            mapping={mappings.facebookMapping}
            errors={facebookErrors}
          />
        </div>
      )}
    </div>
  );
};
