import { takeEvery } from 'redux-saga/effects';

import {
  disableTracer,
  enableTracer,
  handleDisableTracer,
  handleEnableTracer,
} from './internal-tools.action';

export const internalToolsSaga = function* () {
  yield takeEvery(enableTracer.toString(), handleEnableTracer);
  yield takeEvery(disableTracer.toString(), handleDisableTracer);
};
