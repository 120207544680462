import React from 'react';
import * as styles from '../../patient-list.style';
import { useSelector } from 'react-redux';
import { format, utcToZonedTime } from 'date-fns-tz';
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/core';
import { Text, NakedButton, useModalControl } from '@weave/design-system';
import { createSelector } from 'reselect';

import { MsgDirectionType, SmsModel } from '../../../../../../models/sms-history.model';
import { UuidCopier } from '../../../uuid-copier';
import {
  selectCurrentLocationId,
  selectCurrentLocationTimeZone,
} from '../../../../../../redux/actions/location';
import { Store } from '../../../../../../redux/store/store.model';
import {
  Error,
  MsgStatusTypeMapping,
} from '../../../../sms-details-container/sms-details/sms-info/sms-info.component';
import { SmsMessage } from './sms-message';

const itemContainer = css`
  text-transform: capitalize;
  border: 1px solid #d4dbdf;
  border-radius: 3px;
  background-color: #fafcfc;
  margin: 5px;
  padding: 10px;
  p {
    padding: 0px;
    margin: 0px;
  }
  div {
    display: flex;
    justify-content: space-between;
  }
  .autogenerated {
    font-weight: bold;
  }
`;

interface Props {
  sms: SmsModel;
}

const notificationTypes = {
  RELATED_TYPE_BIRTHDAY: 'birthdays',
  RELATED_TYPE_APPOINTMENT: 'appointments',
  RELATED_TYPE_REVIEW: 'reviews',
};

const smsHistorySelector = createSelector(
  [
    selectCurrentLocationId,
    selectCurrentLocationTimeZone,
    (state: Store) => state.notificationsSettings?.categories,
  ],
  (currentLocation, timezone, notificationsSettings) => ({
    currentLocation,
    timezone,
    notificationSettings: notificationsSettings ?? {},
  })
);

export const alternateID = '00000000-0000-0000-0000-000000000000';

export const SmsHistoryCard = ({ sms }: Props) => {
  const history = useHistory();
  const { timezone, currentLocation, notificationSettings } =
    useSelector(smsHistorySelector);
  const tz = timezone || 'US/Mountain';
  const timeZonedDate = utcToZonedTime(sms.createdAt, tz);

  const dateCreated = format(timeZonedDate, 'MM/dd/yyyy h:mma z', {
    timeZone: tz,
  });

  const getSetting = (id, type) => {
    const notifyType = notificationTypes?.[type] ?? type;
    const notifySettings = notificationSettings?.[notifyType] ?? [];
    return notifySettings.find((setting) => setting.id === id) || {};
  };

  const autogenStyle = sms.autogeneratedBy ? 'autogenerated' : '';
  const isOutbound = sms.direction === MsgDirectionType.Outbound;

  const status = MsgStatusTypeMapping[sms.status];

  let typeId, type;

  if (sms.relatedIds && sms.relatedIds.length > 0) {
    typeId = sms.relatedIds[0].id ?? '';
    type = sms.relatedIds[0].type ?? '';
  }

  const setting = sms.autogeneratedBy ? getSetting(sms.autogeneratedBy, type) : {};

  const { modalProps, triggerProps } = useModalControl();

  return (
    <div css={itemContainer}>
      <div>
        <Text className={autogenStyle}>
          {sms.autogeneratedBy ? 'Autogenerated' : 'Manual'}
        </Text>
        <Text weight="bold">{dateCreated}</Text>
      </div>
      <div>
        <Text>{isOutbound ? 'Outbound' : 'Inbound'}</Text>
        <Text className={sms.status === Error ? styles.error : ''}>
          {status}
          {sms.statusDetails ? `: ${sms.statusDetails}` : ''}
        </Text>
      </div>
      <div>
        <div className={styles.flexDisplay}>
          <Text as="span">{setting.type}</Text>
          {!!typeId && (
            <UuidCopier
              uuid={
                typeId && sms.direction !== 'DIRECTION_INBOUND' ? typeId : alternateID
              }
            />
          )}
        </div>
        <div>
          <Text className={styles.actionBtn} as="span">
            {setting.name}
          </Text>
          <UuidCopier uuid={sms.autogeneratedBy ?? alternateID} />
        </div>
      </div>
      <NakedButton
        className={styles.actionBtn}
        onClick={() =>
          history.push(`/location/${currentLocation}/history/sms-details?uuid=${sms.id}`)
        }
      >
        Details
      </NakedButton>
      <NakedButton className={styles.actionBtn} {...triggerProps}>
        View
      </NakedButton>
      <SmsMessage sms={sms} modalProps={modalProps} />
    </div>
  );
};
