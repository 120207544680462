import React from 'react';
import { css } from 'emotion';

export type OnboardingStatusIndicatorProps = {
  status: string;
};

export const OnboardingStatusIndicator = ({ status }: OnboardingStatusIndicatorProps) => {
  let statuscolor;
  let statustext;
  switch (status) {
    case 'requested':
      statuscolor = 'lightgray';
      statustext = 'Requested';
      break;
    case 'pending_sync':
      statuscolor = 'lightgoldenrodyellow';
      statustext = 'Pending Sync';
      break;
    case 'etl_enabled':
      statuscolor = 'lightgoldenrodyellow';
      statustext = 'Waiting for ETL';
      break;
    case 'demo_ready':
      statuscolor = 'lawngreen';
      statustext = 'Ready to Demo';
      break;
    case 'complete':
      statuscolor = 'cornflowerblue';
      statustext = 'Complete';
      break;
    case 'none':
      statuscolor = 'orange';
      statustext = 'No Request Submitted';
      break;
    default:
      statuscolor = 'orange';
      statustext = 'Unknown';
      break;
  }
  return (
    <span
      className={css`
        padding: 10px;
        minwidth: 100px;
        text-align: center;
        background-color: ${statuscolor};
      `}
    >
      {statustext}
    </span>
  );
};
