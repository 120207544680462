import React, { useEffect, useState } from 'react';
import { showError, showSuccess } from '@weave/alert-system';
import { Modal } from '@weave/design-system';
import { CustomAxios, getErrorMessage } from '../../redux/axios';
import { useDispatch } from 'react-redux';

interface Props {
  flagName: string;
  onDelete: Function;
  close: Function;
}

export const DeleteFlagModal = (props: Props) => {
  const [locationsWithFlag, setLocationsWithFlag] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    let flagCount = 0;
    const setTrueProm = CustomAxios.get(
      `/feature-flags/v1/locations/flags/${props.flagName}?value=true`
    ).then((res) => {
      if (res.data.hasOwnProperty('locationIds')) {
        flagCount += res.data.locationIds.length;
      }
    });

    const setFalseProm = CustomAxios.get(
      `/feature-flags/v1/locations/flags/${props.flagName}`
    ).then((res) => {
      if (res.data.hasOwnProperty('locationIds')) {
        flagCount += res.data.locationIds.length;
      }
    });

    Promise.allSettled([setTrueProm, setFalseProm]).finally(() => {
      setLocationsWithFlag(flagCount);
    });
  }, []);

  function deleteFlag(flagName) {
    return CustomAxios.delete(`/feature-flags/v1/flags/${flagName}`)
      .then((res) => {
        dispatch(showSuccess('Flag Deleted'));
      })
      .catch((err) => {
        dispatch(showError(getErrorMessage(err)));
      });
  }

  return (
    <Modal
      show={true}
      onClose={() => {
        props.close();
      }}
    >
      <Modal.Header>Delete [{props.flagName}]?</Modal.Header>
      <Modal.Body>
        {locationsWithFlag} Locations have this flag set, are you sure you want to delete
        it?
        <Modal.Actions
          // @ts-ignore It's the right type, Typescript just doesn't recognize that.
          onPrimaryClick={() => {
            deleteFlag(props.flagName).then((res) => {
              props.onDelete();
            });
          }}
          onSecondaryClick={() => props.close()}
          primaryLabel="Delete"
          secondaryLabel="Cancel"
        />
      </Modal.Body>
    </Modal>
  );
};
