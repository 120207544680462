import React from 'react';

export const SuccessSVG = () => (
  <svg width="134px" height="134px" viewBox="0 0 134 134" version="1.1">
    <g
      id="Onboarding-Tab"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Onboarding---Handoff-Verification-Copy"
        transform="translate(-653.000000, -538.000000)"
        stroke="#2BD53C"
        strokeWidth="10"
      >
        <g id="Group-4" transform="translate(658.000000, 543.000000)">
          <polyline
            id="Stroke-1"
            points="95.7723577 42.3414634 51.2144447 86.699187 30.2439024 65.822861"
          />
          <path
            d="M124,62 C124,96.241276 96.2420872,124 62,124 C27.758724,124 0,96.241276 0,62 C0,27.758724 27.758724,0 62,0 C96.2420872,0 124,27.758724 124,62 Z"
            id="Stroke-2"
          />
        </g>
      </g>
    </g>
  </svg>
);
