import { css } from 'emotion';
import { theme } from '@weave/theme-original';
import { modalMainSectionHeight } from './greeting-upload-modal.styles';

export const mainSection = css`
  height: ${modalMainSectionHeight};
  overflow: hidden;

  label: mainSection;
`;

export const dropzone = css`
  background-color: ${theme.colors.gray100};
  border: 2px dashed ${theme.colors.gray400};
  border-radius: 8px;
  display: block;
  height: 254px;
  margin-top: 15px;
  padding: 50px 100px;

  &.hasFile {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 100px;
    padding: 0;
  }

  label: dropzone;
`;

export const browseButton = css`
  display: inline-block;
  margin: 25px 0;
  max-width: 150px;
  label: browseButton;
`;

export const containFile = css`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  label: containFile;
`;

export const dropFirst = css`
  margin-top: 65px;

  label: dropFirst;
`;

export const dropAnother = css`
  text-align: center;
  width: 100%;
`;

export const hr = css`
  margin: 20px 0;

  label: hr;
`;

export const instructions = css`
  margin-top: 10px;

  label: instructions;
`;

export const buttonContainer = css`
  margin-top: 50px;
  text-align: center;

  label: buttonContainer;
`;

export const submitButton = css`
  display: inline-block;
`;

export const error = css`
  color: ${theme.font.colors.error};
  margin-top: 15px;
`;

export const containRename = css`
  margin-top: 60px;
  text-align: left;
`;

export const greetingName = css`
  margin-top: 5px;
`;
