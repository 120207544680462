import React from 'react';
import * as _ from 'lodash';
import { cx } from 'emotion';
import { Link } from 'react-router-dom';
import * as styles from '../sms-details.styles';
import { SmsModel } from '../../../../../models/sms-history.model';
import { NotificationsSettings } from '../../../../../redux/actions/notifications-settings/notification-settings.types';

export type SmsWeaveMessageProps = {
  locationID: string;
  smsDetails: SmsModel;
  settings: NotificationsSettings;
  uuid: string;
  getNotificationsSettings: () => void;
};

export class SmsWeaveMessage extends React.Component<SmsWeaveMessageProps, {}> {
  UNSAFE_componentWillMount() {
    this.props.getNotificationsSettings();
  }

  componentDidUpdate(prevProps) {
    if (this.props.settings !== prevProps.settings) {
      this.findNotificationSetting();
    }
  }

  findNotificationSetting() {
    const autogeneratedID = this.props.uuid;
    const types = ['appointments', 'birthdays', 'recall', 'reviews'];
    let setting;

    for (let idx = 0; idx < types.length; idx++) {
      setting = _.find(
        this.props.settings[types[idx]],
        _.matchesProperty('id', autogeneratedID)
      );
      if (setting) {
        break;
      }
    }

    if (!setting) {
      setting = {
        name: 'No Data',
        type: 'No Data',
        template: {
          text: 'No Data',
          multi_template_text: 'No Data',
        },
      };
    }
    return setting;
  }

  render() {
    const settingID = this.props.uuid.slice(0, 4) + '..';
    const setting = this.findNotificationSetting();
    return (
      <div>
        <p className={styles.header}>Auto Reminder Setting</p>
        <div className={styles.infoBox}>
          <label className={styles.infoBoxLeft}>Template:</label>
          <Link
            className={cx(styles.infoBoxRight, styles.linkBtn, styles.uuid)}
            title="View Auto Reminder"
            to={{
              pathname: `/location/${this.props.locationID}/settings/auto-reminders`,
            }}
          >
            {settingID}
          </Link>
        </div>
        <div className={styles.infoBox}>
          <label className={styles.infoBoxLeft}>Name:</label>
          <p className={styles.infoBoxRight}>{setting.name}</p>
        </div>
        <div className={styles.infoBox}>
          <label className={styles.infoBoxLeft}>Type:</label>
          <p className={styles.infoBoxRight}>{setting.type}</p>
        </div>
        <div className={styles.infoBox}>
          <label className={styles.infoBoxLeft}>Text Template:</label>
          <p className={cx(styles.infoBoxRight, styles.uuid)}>{setting.template.text}</p>
        </div>
        <div className={styles.infoBox}>
          <label className={styles.infoBoxLeft}>Multi Text Template:</label>
          <p className={cx(styles.infoBoxRight, styles.uuid)}>
            {setting.template.multi_template_text}
          </p>
        </div>
      </div>
    );
  }
}
