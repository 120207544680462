import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { appointment } from '../../../../redux/actions/appointments/appointment';
import { WeaveTheme } from '@weave/theme-original';
import { Text } from '@weave/design-system';
import { css } from '@emotion/core';
import { AppointmentStateModel } from '../../../../redux/actions/appointments/appointment/appointment.action';
import { Store } from '../../../../redux/store/store.model';
import { useReminderSettings } from '../reminder-settings.hook';

import { AutoMessageQueue } from '../auto-message-queue';
import { AutoRemindersDebug } from './auto-reminders-debug';
import { dateTimeDateFns } from '../../../../helpers/utils';

const capitalize = css`
  text-transform: capitalize;
`;

const mainContainer = (theme: WeaveTheme) => css`
  .yellow {
    color: ${theme.colors.warning};
  }
  .info {
    color: ${theme.colors.pressedBlue};
  }
  .error {
    color: ${theme.colors.error};
  }
  .suggestion {
    color: black;
  }
  .green {
    color: ${theme.colors.strawberry};
  }
`;

const apptInfoContainer = css`
  display: flex;
`;

const dataset = css`
  display: flex;
  width: 400px;
  span {
    font-weight: bold;
    text-align: right;
    width: 40%;
    padding-right: 16px;
  }
  & .rawStatus {
    p {
      margin: 0;
      padding: 0;
    }
    margin-bottom: 8px;
  }
`;

export const AppointmentDebugger = ({ query }) => {
  const dispatch = useDispatch();
  const { data: appointmentData }: AppointmentStateModel = useSelector(
    (store: Store) => store.appointment
  );
  const type = query.type ?? '';
  const reminderSetting = useReminderSettings(type);

  useEffect(() => {
    if (query.id) dispatch(appointment(query.id));
  }, [query.id]);

  const apptDate = appointmentData.AppointmentDate
    ? format(new Date(appointmentData.AppointmentDate), dateTimeDateFns)
    : 'N/A';
  const entry = new Date(appointmentData.EntryDate);
  const entryDate = appointmentData.EntryDate ? format(entry, dateTimeDateFns) : 'N/A';

  return (
    <div css={mainContainer}>
      <Text css={capitalize} weight="bold">
        {type}
      </Text>

      <div css={apptInfoContainer}>
        <div>
          <div css={dataset}>
            <Text as="span">Date</Text>
            <Text>{apptDate}</Text>
          </div>

          <div css={dataset}>
            <Text as="span">PM Status</Text>
            <Text>{appointmentData.StatusPM || 'N/A'}</Text>
          </div>

          <div css={dataset}>
            <Text as="span">Created At</Text>
            <Text>{entryDate}</Text>
          </div>
        </div>

        <div>
          <div css={dataset}>
            <Text as="span">Type</Text>
            <Text>{appointmentData.Type}</Text>
          </div>

          <div css={dataset}>
            <Text as="span">Provider</Text>
            <Text>{appointmentData.Provider}</Text>
          </div>

          <div css={dataset}>
            <Text as="span">Operatory</Text>
            <Text>{appointmentData.Operatory}</Text>
          </div>
        </div>
      </div>

      <hr />

      <AutoMessageQueue query={query} reminderSetting={reminderSetting} />

      <hr />

      <AutoRemindersDebug
        query={query}
        reminderSetting={reminderSetting}
        data={appointmentData}
      />
    </div>
  );
};
