import { createAction } from 'redux-actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { showError, showSuccess } from '@weave/alert-system';
import { CustomAxios, getErrorMessage } from '../../axios';

export const postReviewsSignup = createAction('POST_REVIEWS_SIGNUP');
export const postReviewsSignupFailure = createAction('POST_REVIEWS_SIGNUP_FAILURE');
export const postReviewsSignupSuccess = createAction('POST_REVIEWS_SIGNUP_SUCCESS');

export const reviewsSignupUrl = '/support/v1/reviews/signup';
export const postReviewsSignupRequest = function* (action) {
  try {
    const params = action.payload;
    yield call(CustomAxios.post, reviewsSignupUrl, params);
    yield put(showSuccess('New place for reviews created!'));
  } catch (error: any) {
    yield put(showError(getErrorMessage(error)));
  }
};

export const postReviewsSignupSaga = function* () {
  yield takeLatest(postReviewsSignup.toString(), postReviewsSignupRequest);
};
