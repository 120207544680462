import {
  DepartmentModel,
  DepartmentScheduleTypes,
} from '../../../apis/department/department.types';
import { OnCompleteFn, RequestAction } from '../../store/store-utils';
export type DepartmentState = { [id: string]: DepartmentModel };
export interface CreateDepartmentDto {
  name: string;
}

export enum DepartmentActionTypes {
  RequestList = 'Departments:RequestList',
  RequestGet = 'Departments:RequestGet',
  RequestCreate = 'Departments:RequestCreate',
  RequestUpdate = 'Departments:RequestUpdate',
  RequestDelete = 'Departments:RequestDelete',
  Add = 'Departments:Add',
  Remove = 'Departments:Remove',
  Update = 'Departments:Update',
}

type Action<
  ActionType extends DepartmentActionTypes,
  Payload = undefined,
  onCompleteRes = undefined
> = {
  type: ActionType;
  payload: Payload;
  onComplete?: OnCompleteFn<onCompleteRes>;
};

export type RequestListAction = RequestAction<
  DepartmentActionTypes.RequestList,
  undefined,
  DepartmentModel[]
>;
export type RequestGetAction = RequestAction<
  DepartmentActionTypes.RequestGet,
  string,
  DepartmentModel
>;
export type RequestCreateAction = RequestAction<
  DepartmentActionTypes.RequestCreate,
  CreateDepartmentDto,
  DepartmentModel
>;
export type RequestUpdateAction = RequestAction<
  DepartmentActionTypes.RequestUpdate,
  { id: string; update: Partial<DepartmentModel> },
  DepartmentModel
>;
export type RequestDeleteAction = RequestAction<
  DepartmentActionTypes.RequestDelete,
  string,
  string
>;

export type AddAction = Action<DepartmentActionTypes.Add, DepartmentModel[]>;
export type RemoveAction = Action<DepartmentActionTypes.Remove, string>;
export type UpdateAction = Action<DepartmentActionTypes.Update, DepartmentModel>;

export type DepartmentActions =
  | RequestListAction
  | RequestGetAction
  | RequestCreateAction
  | RequestUpdateAction
  | RequestDeleteAction
  | AddAction
  | RemoveAction
  | UpdateAction;

export const mockDepartment = (data: Partial<DepartmentModel> = {}): DepartmentModel =>
  ({
    ID: 'testID1',
    name: 'Scheduling',
    activeSchedule: DepartmentScheduleTypes.Open,
    routingSummary: 'Phone tree',
    mailboxId: '5cfd0f1b-5f18-48b5-9471-792dc1df465c',
    callGroupId: '80db6023-d236-47e2-86ea-a49cbdebae5e',
    defaultOutboundPhoneNumberId: '',
    ...data,
  } as DepartmentModel);

export const sampleDepartmentsList = [
  mockDepartment({
    ID: 'testID0',
    name: 'Main Line',
  }),
  mockDepartment({
    ID: 'testID1',
    name: 'Engineering',
  }),
  mockDepartment({
    ID: 'testID2',
    name: 'Scheduling',
  }),
  mockDepartment({
    ID: 'testID3',
    name: 'Finance',
  }),
];
