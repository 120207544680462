import { handleActions } from 'redux-actions';
import { getRecordsSuccess, setIsNextPageAvailable, setLoading } from './records.action';

const initialState = {
  records: [],
  loading: false,
  isNextPageAvailable: false,
};

const recordsReducer = handleActions(
  {
    [getRecordsSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { records: action.payload }),
    [setLoading.toString()]: (state, action) =>
      Object.assign({}, state, { loading: action.payload }),
    [setIsNextPageAvailable.toString()]: (state, action) =>
      Object.assign({}, state, { isNextPageAvailable: action.payload }),
  },
  initialState
);

export default recordsReducer;
