import React from 'react';
import { css } from '@emotion/core';
import { WeaveTheme } from '@weave/theme-original';
import { Text, AlertIcon, XIcon, IconButton } from '@weave/design-system';
import { useState, useEffect } from 'react';

type Props = {
  show: boolean;
  message: string;
  outageId: string;
};

/**Css for the component */
const banner = (theme: WeaveTheme) => css`
  padding: ${theme.spacing(2)};
  display: flex;
  background-color: #ff6b59;
  align-items: center;
  margin-left: ${theme.spacing(1)};
  margin-right: ${theme.spacing(1)};
  border-radius: 10px;
`;

const bannerText = css`
  flex: 1;
  color: white;
  margin: 0 1%;
`;
/** */

export const OutageAlertsBanner = ({ show, message, outageId }: Props) => {
  const [showBanner, setShowBanner] = useState<boolean>(show);

  useEffect(() => {
    setShowBanner(show);
  }, [show, message]);

  return showBanner ? (
    <div css={banner}>
      <AlertIcon color="white" />
      <Text css={bannerText}>{message}</Text>
      <IconButton label="Dismiss" onClick={() => setShowBanner(false)}>
        <XIcon color="white" />
      </IconButton>
    </div>
  ) : null;
};
