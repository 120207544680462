import React, { useEffect } from 'react';
import { css } from '@emotion/core';
import isFunction from 'lodash/isFunction';
import {
  TextButton,
  useFormField,
  DropdownField,
  Text,
  CaretLeftIconSmall,
  CaretRightIconSmall,
  usePreviousValue,
} from '@weave/design-system';
import { WeaveTheme } from '@weave/theme-original';

export type RowsPerPageOption = 10 | 25 | 50 | 75;

type DetailedTablePaginationProps = {
  onNextClick?: () => void;
  onPrevClick?: () => void;
  onNumRowsChange?: (num: RowsPerPageOption) => void;
  currentPage: number;
  disableNext?: boolean;
  disablePrevious?: boolean;
  hideNumRows?: boolean;
  rowsPerPage?: RowsPerPageOption;
};

export const DetailedTablePagination = ({
  currentPage,
  disableNext,
  disablePrevious,
  hideNumRows,
  onNextClick,
  onNumRowsChange,
  onPrevClick,
  rowsPerPage,
}: DetailedTablePaginationProps) => {
  return (
    <div
      role="navigation"
      aria-label="Pagination Navigation"
      css={(theme: WeaveTheme) => css`
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        > *:not(:first-child) {
          margin-left: ${theme.spacing(2)};
        }
      `}
    >
      <TextButton
        onClick={onPrevClick}
        aria-label="View Previous Page"
        disabled={disablePrevious}
        icon={CaretLeftIconSmall}
      >
        Previous
      </TextButton>
      <Text
        weight="bold"
        color="light"
        css={{ marginTop: 0, marginBottom: 0 }}
        aria-label={`Current page, page ${currentPage}`}
      >
        Page {currentPage}
      </Text>
      {!hideNumRows && (
        <PageSelector perPage={rowsPerPage} onSelectionChanged={onNumRowsChange} />
      )}
      <TextButton
        onClick={onNextClick}
        aria-label="View Next Page"
        disabled={disableNext}
        css={(theme: WeaveTheme) =>
          css`
            font-size: ${theme.fontSize(16)};
          `
        }
      >
        Next
        <CaretRightIconSmall
          css={(theme: WeaveTheme) =>
            css`
              margin-left: ${theme.spacing(1)};
            `
          }
        />
      </TextButton>
    </div>
  );
};

type PageSelectorProps = {
  perPage?: RowsPerPageOption;
  onSelectionChanged?: (num: RowsPerPageOption) => void;
};

const PageSelector = ({ perPage = 25, onSelectionChanged }: PageSelectorProps) => {
  const dropdownProps = useFormField({ type: 'dropdown', value: perPage.toString() });
  const [previousValue, currentValue] = usePreviousValue(dropdownProps.value);

  useEffect(() => {
    if (previousValue !== currentValue && isFunction(onSelectionChanged)) {
      onSelectionChanged(+dropdownProps.value as RowsPerPageOption);
    }
  }, [dropdownProps.value]);

  return (
    <DropdownField {...dropdownProps} label="" name="rows per page">
      <DropdownField.Option value="10">10 Rows</DropdownField.Option>
      <DropdownField.Option value="25">25 Rows</DropdownField.Option>
      <DropdownField.Option value="50">50 Rows</DropdownField.Option>
      <DropdownField.Option value="75">75 Rows</DropdownField.Option>
    </DropdownField>
  );
};
