import { gql } from '@apollo/client';

export enum TemporalWorkflowStatus {
  Unspecified = 'Unspecified',
  Running = 'Running',
  Completed = 'Completed',
  Failed = 'Failed',
  Canceled = 'Canceled',
  Terminated = 'Terminated',
  ContinuedAsNew = 'ContinueAsNew',
  TimedOut = 'Timedout',
}

export interface TemporalWorkflowResponse {
  id: string;
  runId: string;
  status: TemporalWorkflowStatus;
  urlPolicy: {
    destinationURL: string;
    referrerURL: string;
  };
  error: any;
  typeName: string;
}

export interface DisconnectStripeAccountResponse {
  disconnectProcessor: TemporalWorkflowResponse;
}

export interface WorkflowResponse {
  workflow: TemporalWorkflowResponse;
}

export const disconnectStripeAccountQuery = gql`
  mutation disconnectProcessor($input: DisconnectProcessorInput) {
    disconnectProcessor(input: $input) {
      id
      runId
      status
      error
      typeName
    }
  }
`;

export const WorflowStatusQuery = gql`
  query workflow($input: WorkflowInput!) {
    workflow(input: $input) {
      id
      runId
      status
      error
      typeName
    }
  }
`;
