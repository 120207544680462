import React from 'react';
import { css, cx } from 'emotion';

export const filterToggleWrapper = css`
  display: flex;
  justify-content: space-evenly;
  border-radius: 20px;
  padding: 0;
  color: rgb(55, 67, 74);
  background-color: #d4dbdf;
`;

export const filterToggleItem = css`
  padding: 7px 15px;
  border-radius: 20px;
  flex: 1;
  cursor: pointer;
  text-align: center;
`;

export const filterToggleActive = css`
  background-color: #37cff1;
  color: white;
  cursor: default;
  text-align: center;
`;

export type FilterToggleArrayItem = {
  displayText: string;
  clickFunction: () => void;
};

export type FilterToggleProps = {
  filterButtons: FilterToggleArrayItem[];
  activeFilterButton: string;
};

export const FilterToggle = (props: FilterToggleProps) => {
  return (
    <div className={filterToggleWrapper}>
      {props.filterButtons.map((arrayItem, index) => {
        const buttonStyles =
          arrayItem.displayText.toLowerCase() === props.activeFilterButton.toLowerCase()
            ? filterToggleActive
            : '';
        return (
          <div
            key={index}
            className={cx(filterToggleItem, buttonStyles)}
            onClick={arrayItem.clickFunction}
          >
            {arrayItem.displayText}
          </div>
        );
      })}
    </div>
  );
};
