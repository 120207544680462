import { call, select, CallEffect, SelectEffect } from 'redux-saga/effects';
import moment from 'moment';

import { CustomAxios } from '../../../axios';
import { getFactorTestResults } from '../handoff-hub.action';
import { FactorLogicResult, FactorStatus, FactorTest } from '../handoff-hub.reducer';
import { Store } from '../../../store/store.model';
import { VerticalEnum } from '../../../../models/vertical-enum';
import { selectCurrentLocationId } from '../../location';
import {
  handleGetFeatureFlags,
  selectFeatureFlagLoaded,
  selectHasFeatureFlagEnabled,
} from '../../feature-flags';
import { MESSAGES_URL, OLD_MESSAGES_URL } from '../../sms/api';
import { parseDate } from '../../smart-alerts/endpoints';

export interface INotificationSettings {
  appointments: any[];
  birthdays: any[];
  recall: any[];
  reviews: any[];
  orders: any[];
}

const TOTAL_SMS_PASS_AMOUNT = 10;
const INBOUND_SMS_PASS_AMOUNT = 1;
const OUTBOUND_SMS_PASS_AMOUNT = 1;
const USERS_PASS_AMOUNT = 2;
const APPT_REMINDERS_PASS_AMOUNT = 2;
const RECALL_REMINDERS_PASS_AMOUNT = 1;
const EYEWEAR_REMINDERS_PASS_AMOUNT = 1;

// checkTextMessageUsage
export const inboundTextCriteria = `Must have received at least ${INBOUND_SMS_PASS_AMOUNT} inbound message(s) in the last week`;
export const outboundTextCriteria = `Must have sent at least ${OUTBOUND_SMS_PASS_AMOUNT} outbound message(s) in the last week`;
export const totalTextCriteria = `Must have total of ${TOTAL_SMS_PASS_AMOUNT} messages in the last week`;
// checkUsersAdded
export const userCountCriteria = `Must have a total of ${USERS_PASS_AMOUNT} users`;
// checkAutoMessagingOn
export const recallRemindersCriteria = `Must have at least ${RECALL_REMINDERS_PASS_AMOUNT} recall reminder(s) turned on`;
export const appointmentRemindersCriteria = `Must have at least ${APPT_REMINDERS_PASS_AMOUNT} appointment reminder(s) turned on`;
export const eyewearRemindersCriteria = `Must have at least ${EYEWEAR_REMINDERS_PASS_AMOUNT} eyewear reminder(s) turned on`;

export const getNotificationsSettings = function* () {
  const response = yield call(CustomAxios.get, '/support/v1/notifications/settings');
  const notificationSettings = response.data.data.notificationSettings || {};
  return notificationSettings;
};

export const checkRecallReminders = (
  notificationSettings: INotificationSettings
): [FactorStatus, FactorTest[]] => {
  let status: FactorStatus = 'pass';
  const tests: FactorTest[] = [];
  const name = recallRemindersCriteria;

  let recallCount = 0;
  if (notificationSettings.recall) {
    for (const appt of notificationSettings.recall) {
      if (appt.enabled) {
        recallCount++;
      }
    }
    if (recallCount < RECALL_REMINDERS_PASS_AMOUNT) {
      status = 'fail';
      tests.push({ status, name, actual: String(recallCount) });
    } else {
      status = 'pass';
      tests.push({ status, name, actual: String(recallCount) });
    }
  } else {
    status = 'fail';
    tests.push({ status, name, actual: 'no settings' });
  }

  return [status, tests];
};

export const checkAppointmentReminders = (
  notificationSettings: INotificationSettings
): [FactorStatus, FactorTest[]] => {
  let status: FactorStatus = 'pass';
  const tests: FactorTest[] = [];
  const appointments = notificationSettings.appointments;
  const name = appointmentRemindersCriteria;

  let apptRemindersOnCount = 0;
  if (appointments) {
    for (const appt of appointments) {
      if (appt.enabled) {
        apptRemindersOnCount++;
      }
    }
    if (!(apptRemindersOnCount >= APPT_REMINDERS_PASS_AMOUNT)) {
      status = 'fail';
      tests.push({ status, name, actual: String(apptRemindersOnCount) });
    } else {
      tests.push({ status, name, actual: String(apptRemindersOnCount) });
    }
  } else {
    status = 'fail';
    tests.push({ status, name, actual: 'no settings' });
  }

  return [status, tests];
};

export const checkEyewearReminders = (
  notificationSettings: INotificationSettings
): [FactorStatus, FactorTest[]] => {
  const tests: FactorTest[] = [];
  let status: FactorStatus = 'pass';
  const name = eyewearRemindersCriteria;

  let count = 0;
  if (notificationSettings.orders) {
    for (const order of notificationSettings.orders) {
      if (order.enabled) {
        count++;
      }
    }
    if (count < EYEWEAR_REMINDERS_PASS_AMOUNT) {
      status = 'fail';
      tests.push({ status, name, actual: String(count) });
    } else {
      status = 'pass';
      tests.push({ status, name, actual: String(count) });
    }
  } else {
    status = 'fail';
    tests.push({ status, name, actual: 'no settings' });
  }

  return [status, tests];
};

export const checkAutoMessagingOn = function* (): IterableIterator<
  FactorLogicResult | CallEffect | SelectEffect
> {
  let factorStatus: FactorStatus = 'pass';

  const notificationSettings = yield call(getNotificationsSettings);

  const [recallRemindersStatus, recallRemindersTests] = checkRecallReminders(
    // @ts-ignore
    notificationSettings
  );
  const [apptRemindersStatus, apptRemindersTests] = checkAppointmentReminders(
    // @ts-ignore
    notificationSettings
  );

  const isOptometry = yield select(
    (store: Store) =>
      store.currentLocationId &&
      store.locations[store.currentLocationId]?.VerticalID === VerticalEnum.Optometry
  );

  let eyewearRemindersStatus = 'pass';
  let eyewearRemindersTests: FactorTest[] = [];
  if (isOptometry) {
    [eyewearRemindersStatus, eyewearRemindersTests] = checkEyewearReminders(
      // @ts-ignore
      notificationSettings
    );
  }

  const tests = [
    ...recallRemindersTests,
    ...apptRemindersTests,
    ...eyewearRemindersTests,
  ];

  if (
    recallRemindersStatus === 'fail' ||
    apptRemindersStatus === 'fail' ||
    eyewearRemindersStatus === 'fail'
  ) {
    factorStatus = 'fail';
  }

  return { status: factorStatus, tests };
};

export const getTextMessageCounts = function* () {
  const commxMigrationFlagLoaded = yield select((store) =>
    selectFeatureFlagLoaded(store, 'commx-migration')
  );
  const locationID: string = yield select(selectCurrentLocationId);

  if (!commxMigrationFlagLoaded) {
    yield handleGetFeatureFlags({
      type: 'GET_FEATURE_FLAGS',
      payload: locationID,
    });
  }

  const commxMigrationFlag = yield select((store) =>
    selectHasFeatureFlagEnabled(store, 'commx-migration')
  );
  const url = commxMigrationFlag ? MESSAGES_URL : OLD_MESSAGES_URL;

  const params = {
    params: {
      limit: 200,
      start: parseDate(moment().subtract(1, 'week').toDate()),
      end: parseDate(moment().add(1, 'day').toDate()),
      location_id: locationID,
    },
  };

  const responses = yield call(CustomAxios.get, url, params);

  const textMessages = responses.data.smsList || [];

  let [inbound, outbound, total] = [0, 0, 0];
  for (const msg of textMessages) {
    if (!msg.autogeneratedBy) {
      total++;
      if (msg.direction === 'DIRECTION_OUTBOUND') {
        outbound++;
      } else {
        inbound++;
      }
    }
  }

  return { inbound, outbound, total };
};

export const checkTextMessageUsage = function* (): IterableIterator<
  FactorLogicResult | CallEffect
> {
  const tests: FactorTest[] = [];
  let factorStatus: FactorStatus = 'pass';
  let factorTest: FactorTest | undefined = undefined;

  const textMessages = yield call(getTextMessageCounts);
  // @ts-ignore
  const { inbound, outbound, total } = textMessages;

  [factorTest, factorStatus] = getFactorTestResults(
    factorStatus,
    inboundTextCriteria,
    inbound,
    () => inbound >= INBOUND_SMS_PASS_AMOUNT
  );
  tests.push(factorTest);

  [factorTest, factorStatus] = getFactorTestResults(
    factorStatus,
    outboundTextCriteria,
    outbound,
    () => outbound >= OUTBOUND_SMS_PASS_AMOUNT
  );
  tests.push(factorTest);

  [factorTest, factorStatus] = getFactorTestResults(
    factorStatus,
    totalTextCriteria,
    total,
    () => total >= TOTAL_SMS_PASS_AMOUNT
  );
  tests.push(factorTest);

  return { status: factorStatus, tests };
};

export const getUsers = function* () {
  const response = yield call(CustomAxios.get, 'support/v1/locations/users', {
    params: {
      limit: 200,
    },
  });
  const users = response.data.data || [];
  return users;
};

export const checkUsersAdded = function* (): IterableIterator<
  FactorLogicResult | CallEffect
> {
  const tests: FactorTest[] = [];
  let factorStatus: FactorStatus = 'pass';
  let factorTest: FactorTest | undefined = undefined;

  const users = yield call(getUsers);

  [factorTest, factorStatus] = getFactorTestResults(
    factorStatus,
    userCountCriteria,
    // @ts-ignore
    users.length,
    // @ts-ignore
    () => users.length >= USERS_PASS_AMOUNT
  );
  tests.push(factorTest);

  return { status: factorStatus, tests };
};
