import { FirebaseTokenState, SetFireBaseTokenAction } from './firebase-token.types';
import { FirebaseActionTypes } from './firebase-token.types';

const defaultState: FirebaseTokenState = {
  firebaseToken: '',
};

/**
 * @desc : Reducer for firebase token
 * @param state : FirebaseTokenState
 * @param action : SetFireBaseTokenAction
 * @returns : FirebaseTokenState
 */
export function firebaseTokenReducer(
  state: FirebaseTokenState = defaultState,
  action: SetFireBaseTokenAction
): FirebaseTokenState {
  switch (action.type) {
    case FirebaseActionTypes.SET_FIREBASE_TOKEN:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
