import React from 'react';

import { Modal } from '../../shared/modal/modal.component';
import { HotKeys } from 'react-hotkeys';
import { jaegerTracerId } from '@weave/js-internal-tools';

interface KeystrokesProps {
  disableTracer: () => void;
  enableTracer: () => void;
  tracerEnabled: boolean;
  tracerId: string;
}

interface State {
  showModal: boolean;
}

const keyMap = {
  toggleTracer: 'ctrl+shift+x',
};

export class Keystrokes extends React.Component<KeystrokesProps, State> {
  public handlers;
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };

    this.handlers = {
      toggleTracer: this.toggleTracer,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tracerEnabled !== this.props.tracerEnabled) {
      this.setState({ showModal: true });
    }
  }

  toggleTracer = () => {
    this.props.tracerEnabled ? this.props.disableTracer() : this.props.enableTracer();
  };

  render() {
    return (
      <HotKeys keyMap={keyMap}>
        <HotKeys handlers={this.handlers}>
          {this.props.children}
          <Modal
            isOpen={this.state.showModal}
            title="Tracer Status"
            onRequestClose={() => {
              this.setState({ showModal: false });
            }}
          >
            <h1>Tracer {this.props.tracerEnabled ? 'Active' : 'Disabled'}</h1>
            {this.props.tracerEnabled ? (
              <span>
                {jaegerTracerId}={this.props.tracerId}
              </span>
            ) : (
              <span>Tracer header will not longer be sent.</span>
            )}
          </Modal>
        </HotKeys>
      </HotKeys>
    );
  }
}
