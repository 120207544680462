import { all } from 'redux-saga/effects';

import {
  getManualTemplatesSaga,
  postManualTemplatesSaga,
} from './manual-templates.action';

export { getManualTemplates, postManualTemplates } from './manual-templates.action';

export { default as manualTemplatesReducer } from './manual-templates.reducer';

export const manualTemplatesSaga = function* () {
  yield all([getManualTemplatesSaga(), postManualTemplatesSaga()]);
};
