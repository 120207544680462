import React from 'react';
import { css } from '@emotion/core';
import { WeaveTheme } from '@weave/theme-original';
import { SecondaryButton, Text } from '@weave/design-system';
import { PhoneCard } from './phone-card/phone-card';
import { CartItem, Product } from '../sales-pricing.types';

type Props = {
  phoneProductData: Product[];
  willUsePhones: boolean;
  getProductServiceFeeText: (product: Product) => string;
  getCartItem: (itemLabel: string) => CartItem | undefined;
  onWillUsePhonesClicked: (willUse: boolean) => void;
  onPhoneProductQuantityChanged: (phoneProduct: Product, quantity: number) => void;
};

export const PhonesPricing = ({
  phoneProductData,
  willUsePhones,
  getProductServiceFeeText,
  getCartItem,
  onWillUsePhonesClicked,
  onPhoneProductQuantityChanged,
}: Props) => {
  return (
    <div>
      <Text css={questionTextStyles} size="large" weight="bold">
        Will your business be using phones?
      </Text>
      <div css={buttonGroupStyles}>
        <SecondaryButton
          css={(theme) => [buttonStyles, willUsePhones && activeButtonStyle(theme)]}
          onClick={() => onWillUsePhonesClicked(true)}
        >
          Yes
        </SecondaryButton>
        <SecondaryButton
          css={(theme) => [buttonStyles, !willUsePhones && activeButtonStyle(theme)]}
          onClick={() => onWillUsePhonesClicked(false)}
        >
          No
        </SecondaryButton>
      </div>

      {willUsePhones && (
        <div css={selectPhonesSectionStyles}>
          <Text size="large" weight="bold">
            Select your Phones below
          </Text>
          <Text size="medium" color="light">
            Enter the amount below to see total price
          </Text>

          <div css={cardsSectionStyles}>
            {phoneProductData.map((phoneProduct) => (
              <PhoneCard
                key={phoneProduct.label}
                productData={phoneProduct}
                initialQuantity={getCartItem(phoneProduct.label)?.quantity}
                getProductServiceFeeText={getProductServiceFeeText}
                onPhoneProductQuantityChanged={onPhoneProductQuantityChanged}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const questionTextStyles = css`
  margin-bottom: 16px;
`;
const buttonGroupStyles = css`
  button:first-of-type {
    margin-right: 16px;
  }
`;
const buttonWidth = '136px';
const buttonHeight = '40px';
const buttonStyles = css`
  width: ${buttonWidth};
  height: ${buttonHeight};
  border-radius: 4px;

  // These styles are just to fix the outline border that gets added on a focus state to
  // buttons from the SecondaryButton component. Here we just set the width, height and
  // border-radius values to the same values we set above so that they do not get
  // overwritten during the focus state.
  &::before {
    width: ${buttonWidth};
    height: ${buttonHeight};
    border-radius: 4px;
  }
`;
const activeButtonStyle = (theme: WeaveTheme) => css`
  border-color: ${theme.colors.weaveBlue};
  color: ${theme.colors.weaveBlue};
`;
const selectPhonesSectionStyles = css`
  margin-top: 32px;
`;
const cardsSectionStyles = css`
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  width: 480px;

  @media only screen and (max-width: 560px) {
    width: 100%;
  }
`;
