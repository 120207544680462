import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';

export const headerStyles = css`
  --header-height: 48px;
  background: ${theme.colors.weaveBlue};
  color: ${theme.colors.white};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
  height: var(--header-height);

  .container-fluid {
    display: flex;
    flex-direction: column;
    height: var(--header-height);
    justify-content: center;
  }

  .group {
    display: flex;
    justify-content: space-between;

    .logo {
      font-size: 20px;
      margin: 0;
      padding-right: ${theme.spacing(2)};
      font-weight: 400;
      display: flex;
      align-items: center;

      a {
        color: ${theme.colors.white};
        cursor: pointer;
        text-decoration: none;

        svg {
          margin-left: 5px;
          transition: transform 100ms ease-out;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }

    img {
      height: calc(var(--header-height) * 0.75);
      margin-left: 10px;
    }
  }

  .location-tabs {
    position: relative;
    display: flex;
    height: var(--header-height);
    width: calc(100vw - 220px);
    padding: 0 !important;
    margin-right: 5px;
    overflow-x: auto;
    overflow-y: hidden;
    align-items: center;
  }

  .location-tabs::-webkit-scrollbar {
    position: absolute;
    height: 8px;
  }

  .location-tabs::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .location-tabs::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.8);
    outline: 1px solid ${theme.colors.weaveBlue};
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }

  .location-info {
    margin-top: 4px;
  }
`;
