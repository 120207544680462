import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { useAlert } from '@weave/alert-system';

import { CustomAxios, getResponseData } from '../redux/axios';
import { selectCurrentLocationId } from '../redux/actions/location';

export type UseResourcesResponse = {
  data: any;
  loading: boolean;
  setResources: Dispatch<SetStateAction<any>>;
  refresh: () => void;
};

export type Primitive = string | number | bigint | boolean | undefined | null | symbol;

export type Options<T = any> = {
  deps?: Array<Primitive>;
  resourceLabel?: string;
  emptyState?: any;
  suppressFetch?: boolean;
  sort?: (data: T) => T;
  onFetch?: (data: T) => void;
};

export const useResources = <T = any>(url: string, options: Options<T>) => {
  const {
    deps = [],
    resourceLabel = 'resource',
    emptyState = [],
    suppressFetch = false,
    sort = (data) => data,
    onFetch = () => {},
  } = options;
  const locationID = useSelector(selectCurrentLocationId);
  const alerts = useAlert();
  const {
    data: queryData,
    isLoading,
    refetch,
    isError,
  } = useQuery<T>(
    [url, locationID],
    () => {
      if (locationID) {
        return CustomAxios.get(url)
          .then<T>(getResponseData)
          .then(sort)
          .then((data) => {
            onFetch(data);
            return data;
          });
      }
      return new Promise<T>(() => {});
    },
    { enabled: !suppressFetch }
  );
  const [data, setResources] = useState<T>(emptyState as T);

  useEffect(() => {
    if (queryData) {
      setResources(queryData);
    }
  }, [queryData]);

  useEffect(() => {
    if (isError) {
      alerts.error(`Error fetching ${resourceLabel}`);
    }
  }, [isError]);

  useEffect(() => {
    if (!suppressFetch) {
      refetch();
    }
  }, [...deps]);

  const refresh = () => {
    refetch();
  };

  return { data, loading: isLoading, setResources, refresh };
};

export type Params<T = any> = Options<T>;
