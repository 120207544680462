import { CustomAxios, getResponseData } from '../../redux/axios';
import { DashboardSubmission } from './dashboard.types';

export const postDashboardSubmission = (
  body: DashboardSubmission
): Promise<DashboardSubmission> =>
  CustomAxios.post('/support/v1/portingdata/dashboardsubmission', body).then(
    getResponseData
  );

export const dashboardApi = {
  postDashboardSubmission,
};
