import { handleActions } from 'redux-actions';
import { setLoading } from './user-roles.action';
import { userSelect, userSelectSuccess } from './user-search.action';

const initialState = {
  data: {},
  loading: false,
};

const userSelectReducer = handleActions(
  {
    [userSelect.toString()]: (state, action) => Object.assign({}, state, {}),
    [userSelectSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { data: action.payload }),
    [setLoading.toString()]: (state, action) =>
      Object.assign({}, state, { loading: action.payload }),
  },
  initialState
);

export default userSelectReducer;
