import { createAction } from 'redux-actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getErrorMessage } from '../../axios';
import { showError } from '@weave/alert-system';
import _ from 'lodash';
import { ResolvedPromise } from '../../store/store-utils';
import { usersApi } from './users.api';

/* ~~~~~~~~~~~~ ACTION CREATORS ~~~~~~~~~~~~ */

export const getAvailableJobTitles = createAction('GET_AVAILABLE_JOB_TITLES');
export const getAvailableJobTitlesSuccess = createAction(
  'GET_AVAILABLE_JOB_TITLES_SUCCESS'
);
export const getJobTitles = createAction('GET_JOB_TITLES');
export const getJobTitlesSuccess = createAction('GET_JOB_TITLES_SUCCESS');
export const updateJobTitles = createAction('UPDATE_JOB_TITLES');
export const updateJobTitlesSuccess = createAction('UPDATE_JOB_TITLES_SUCCESS');
export const setLoading = createAction('SET_JOB_TITLES_LOADING');

/* ~~~~~~~~~~~~ GENERATOR FUNCTIONS ~~~~~~~~~~~~ */

export const handleGetAvailableJobTitles = function* (action) {
  try {
    const availableJobs: ResolvedPromise<ReturnType<typeof usersApi.getAvailableJobs>> =
      yield call(usersApi.getAvailableJobs);
    yield put(getAvailableJobTitlesSuccess(availableJobs || []));
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    yield put(showError(getErrorMessage(error)));
  }
};

export const handleUpdateJobTitles = function* (action) {
  const user = action.payload;
  const jobTitles = {
    JobTitleIDs: _.map(user.JobTitles),
  };
  try {
    const updatedJobs: ResolvedPromise<ReturnType<typeof usersApi.updateJobTitles>> =
      yield call(usersApi.updateJobTitles, user.UserID, jobTitles);
    yield put(updateJobTitlesSuccess(updatedJobs || []));
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    yield put(showError(getErrorMessage(error)));
  }
};

export const handleGetJobTitles = function* (action) {
  const user = action.payload;
  try {
    const userJobs: ResolvedPromise<ReturnType<typeof usersApi.getUserJobs>> = yield call(
      usersApi.getUserJobs,
      user
    );
    yield put(getJobTitlesSuccess(userJobs || []));
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    yield put(showError(getErrorMessage(error)));
  }
};

/* ~~~~~~~~~~~~ SAGAS ~~~~~~~~~~~~ */

export const updateJobTitlesSaga = function* () {
  yield takeEvery(updateJobTitles.toString(), handleUpdateJobTitles);
};

export const getAvailableJobTitlesSaga = function* () {
  yield takeEvery(getAvailableJobTitles.toString(), handleGetAvailableJobTitles);
};

export const getJobTitlesSaga = function* () {
  yield takeEvery(getJobTitles.toString(), handleGetJobTitles);
};
