import {
  FirebaseActionTypes,
  FirebaseObject,
  GetAndAuthenticateFirebaseTokenAction,
  LogoutFireBaseAction,
  SetFireBaseTokenAction,
} from './firebase-token.types';

/**
 * @desc : Action for gettting and authentication firebase token
 */
export const getAndAuthenticateFirebaseToken =
  (): GetAndAuthenticateFirebaseTokenAction => ({
    type: FirebaseActionTypes.GET_AND_AUTHENTICATE_FIREBASE_TOKEN,
    payload: '',
  });

/**
 * @desc : Action for setting firebase Token details in redux
 * @param data : FirebaseObject
 */
export function setFirebaseToken(data: FirebaseObject): SetFireBaseTokenAction {
  return {
    type: FirebaseActionTypes.SET_FIREBASE_TOKEN,
    payload: data,
  };
}

/**
 * @desc : Action to logout out firebase Token
 */
export function logoutFirebase(): LogoutFireBaseAction {
  return {
    type: FirebaseActionTypes.LOGOUT_FIREBASE,
    payload: '',
  };
}
