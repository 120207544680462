import React, { useMemo } from 'react';
import { css } from '@emotion/core';
import { WeaveTheme } from '@weave/theme-original';
import { Heading, SecondaryButton } from '@weave/design-system';
import {
  CalculationType,
  CartItem,
  SoftwareBundleDiscount,
} from '../sales-pricing.types';
import { ChargeList } from './charge-list/charge-list';
import { currencyFormatter, upperCaseWord } from './pricing-calculator.helper';

interface Props {
  cartItems: CartItem[];
  calculationType: CalculationType;
  softwareBundleDiscount: SoftwareBundleDiscount;
  onCalculationTypeChanged: (type: CalculationType) => void;
}

export const PricingCalculator = ({
  cartItems,
  calculationType,
  softwareBundleDiscount,
  onCalculationTypeChanged,
}: Props) => {
  const hasOneTimeCharge = (cartItem: CartItem) => !!cartItem.price;

  const hasRecurringCharges = (cartItem: CartItem) => !!cartItem.recurringPrice;

  const getOneTimeChargeAmount = (cartItem: CartItem) =>
    (cartItem.price ?? 0) * cartItem.quantity;

  const getOneTimeChargeName = (cartItem: CartItem) =>
    `${cartItem.type} - ${cartItem.quantity} ${cartItem.displayName}`;

  const getMonthlyRecurringChargeAmount = (cartItem: CartItem) =>
    (cartItem.recurringPrice ?? 0) * cartItem.quantity;

  const getAnnualRecurringChargeAmount = (cartItem: CartItem) =>
    (cartItem.recurringPrice ?? 0) * cartItem.quantity * 12;

  const getRecurringChargeAmount = (cartItem: CartItem) =>
    calculationType === 'monthly'
      ? getMonthlyRecurringChargeAmount(cartItem)
      : getAnnualRecurringChargeAmount(cartItem);

  const getRecurringChargeName = (cartItem: CartItem) =>
    `${cartItem.type} - ${cartItem.quantity} ${cartItem.displayName}`;

  const sumUpOneTimeCharges = (runningTotal: number, cartItem: CartItem) => {
    if (!cartItem.price) {
      return runningTotal;
    }

    return runningTotal + getOneTimeChargeAmount(cartItem);
  };

  const sumUpRecurringCharges = (runningTotal: number, cartItem: CartItem) => {
    if (!cartItem.recurringPrice) {
      return runningTotal;
    }

    return runningTotal + getRecurringChargeAmount(cartItem);
  };

  const getSoftwareBundleDiscountName = () => {
    const filterSoftwareCartItems = (item) => item.type === 'software';
    const softwareBundlesCount = cartItems.filter(filterSoftwareCartItems).length;

    return `${softwareBundlesCount} Software bundle discount (${softwareBundleDiscount})`;
  };

  const getSoftwareBundleDiscountDecimal = () => {
    if (softwareBundleDiscount === '20%') {
      return 0.2;
    } else if (softwareBundleDiscount === '10%') {
      return 0.1;
    } else {
      return 0;
    }
  };

  const getSoftwareBundleDiscountAmount = () => {
    const discountDecimal = getSoftwareBundleDiscountDecimal();

    if (discountDecimal) {
      return recurringChargesTotal * discountDecimal;
    } else {
      return 0;
    }
  };

  const oneTimeChargesTotal = useMemo(
    () => cartItems.filter(hasOneTimeCharge).reduce(sumUpOneTimeCharges, 0),
    [cartItems, sumUpOneTimeCharges]
  );

  const recurringChargesTotal = useMemo(
    () => cartItems.filter(hasRecurringCharges).reduce(sumUpRecurringCharges, 0),
    [cartItems, calculationType, sumUpRecurringCharges]
  );

  const softwareBundleDiscountAmount = useMemo(
    () => getSoftwareBundleDiscountAmount(),
    [cartItems, calculationType, softwareBundleDiscount, getSoftwareBundleDiscountAmount]
  );

  const discountedRecurringChargesTotal =
    recurringChargesTotal - softwareBundleDiscountAmount;
  const overallChargesTotal =
    oneTimeChargesTotal + recurringChargesTotal - softwareBundleDiscountAmount;

  const handleCalculationTypeChanged = (type: CalculationType) => {
    onCalculationTypeChanged(type);
  };

  return (
    <div css={cardStyles}>
      <div css={headerStyles}>
        <Heading css={titleStyles} level={2}>
          Order Summary
        </Heading>
        <hr css={horizontalLineStyles} />
        <div css={buttonGroupStyles}>
          <SecondaryButton
            css={(theme) => [
              buttonStyles,
              calculationType === 'monthly' && activeButtonStyle(theme),
            ]}
            onClick={() => handleCalculationTypeChanged('monthly')}
            data-testid="monthlyCalculationButton"
          >
            Monthly
          </SecondaryButton>
          <SecondaryButton
            css={(theme) => [
              buttonStyles,
              calculationType === 'annual' && activeButtonStyle(theme),
            ]}
            onClick={() => handleCalculationTypeChanged('annual')}
            data-testid="annualCalculationButton"
          >
            Annual
          </SecondaryButton>
        </div>
      </div>

      <div css={chargesSectionStyle}>
        <ChargeList
          title="One-Time Charges"
          total={oneTimeChargesTotal}
          testId="oneTimeCharges"
        >
          {cartItems.filter(hasOneTimeCharge).map((cartItem, idx) => (
            <ChargeList.ListItem
              key={idx}
              name={getOneTimeChargeName(cartItem)}
              amount={currencyFormatter(getOneTimeChargeAmount(cartItem))}
            />
          ))}
        </ChargeList>

        <ChargeList
          title={`${upperCaseWord(calculationType)} Recurring Charges`}
          total={discountedRecurringChargesTotal}
          testId="recurringCharges"
        >
          {cartItems.filter(hasRecurringCharges).map((cartItem, idx) => (
            <ChargeList.ListItem
              key={idx}
              name={getRecurringChargeName(cartItem)}
              amount={currencyFormatter(getRecurringChargeAmount(cartItem))}
            />
          ))}
          {!!softwareBundleDiscount && (
            <ChargeList.ListItem
              name={getSoftwareBundleDiscountName()}
              amount={`- (${currencyFormatter(getSoftwareBundleDiscountAmount())})`}
            />
          )}
        </ChargeList>
      </div>

      <div css={totalsFooterStyles}>
        <Heading level={2}>Total</Heading>
        <Heading level={2} data-testid="overallChargesTotal">
          {currencyFormatter(overallChargesTotal)}
        </Heading>
      </div>
    </div>
  );
};

const cardStyles = css`
  height: max-content;
  width: 282px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 2%;
  border-radius: 10px;

  @media only screen and (max-width: 450px) {
    width: 100%;
  }
`;
const headerStyles = css`
  margin-bottom: 24px;
`;
const titleStyles = css`
  margin: 0;
`;
const horizontalLineStyles = css`
  margin: 16px 0px;
`;
const buttonWidth = '125px';
const buttonHeight = '40px';
const buttonGroupStyles = css`
  button:first-of-type {
    border-radius: 4px 0px 0px 4px;
  }
  button:last-of-type {
    border-radius: 0px 4px 4px 0px;
  }

  // These styles are just to fix the outline border that gets added on a focus state to
  // buttons from the SecondaryButton component. Here we just set the width, height and
  // border-radius values to the same values we set above so that they do not get
  // overwritten during the focus state.
  & button::before {
    width: ${buttonWidth};
    height: ${buttonHeight};
  }
  & button:first-of-type::before {
    border-radius: 4px 0px 0px 4px;
  }
  & button:last-of-type::before {
    border-radius: 0px 4px 4px 0px;
  }
`;
const buttonStyles = css`
  width: ${buttonWidth};
  height: ${buttonHeight};
`;
const activeButtonStyle = (theme: WeaveTheme) => css`
  border-color: ${theme.colors.weaveBlue};
  color: ${theme.colors.weaveBlue};
`;
const chargesSectionStyle = css`
  flex: 2;
`;
const totalsFooterStyles = css`
  display: flex;
  justify-content: space-between;
`;
