import React from 'react';
import { Button, Svg } from '@weave/platform-ui-components';
import { TableStyles } from '../../../styles/table-style';
import { css } from 'emotion';
import ReactTable from 'react-table';
import { cloneDeep, find, findIndex, get, isEqual, toLower } from 'lodash';

import { MediaUploadModal } from './media-upload-modal/media-upload-modal.component';
import { prettifyDatestring } from '../../../helpers/time';
import { getFileExtensionFromFileName } from '../../../helpers/utils';
import * as styles from './media-library.styles';
import { PhoneMedia } from '../../../redux/actions';
import { ConfirmationModal } from '../../shared/confirmation-modal/confirmation-modal.component';
import { Page } from '../../shared';

const maxFileNameLength = 32;

type Props = {
  deletePhoneMedia: (mediaId: string) => void;
  updatePhoneMedia: (payload: { mediaId: string; fileName: string }) => void;
  uploadPhoneMedia: (payload: { phoneMedia: File; fileName: string }) => void;
  loadingPhoneMedia: boolean;
  locationId: string;
  phoneMedia: PhoneMedia[];
  token: string;
};

export interface StateFileRow {
  mediaId: string;
  fileExt: string;
  fileName: string;
  hasChanges: boolean;
}

export interface State {
  deleteModalIsOpen: boolean;
  fileRows: StateFileRow[];
  mediaIdPendingDeletion: string;
  fileNamePendingDeletion: string;
  uploadModalIsOpen: boolean;
}

export class MediaLibrary extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      deleteModalIsOpen: false,
      fileRows: [],
      mediaIdPendingDeletion: '',
      fileNamePendingDeletion: '',
      uploadModalIsOpen: false,
    };
  }

  componentDidMount() {
    if (this.props.phoneMedia.length > 0) {
      this.updateFileRowsState();
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.phoneMedia, prevProps.phoneMedia)) {
      // media files were fetched
      this.updateFileRowsState();
    }
  }

  updateFileRowsState = () => {
    const fileRows: StateFileRow[] = [];

    this.props.phoneMedia.forEach((media) => {
      const fileExt = getFileExtensionFromFileName(media.FileName);
      let fileName = media.FileName.replace(/.wav|.mp3|.mp4|.wma|.ogg|.m4a/, '');
      const fileInState = find(this.state.fileRows, { mediaId: media.MediaID });

      fileName = fileName.substring(fileName.length - maxFileNameLength, fileName.length);

      fileRows.push(
        fileInState || {
          mediaId: media.MediaID,
          fileExt,
          fileName,
          hasChanges: false,
        }
      );
    });

    this.setState({ fileRows });
  };

  onCancelDelete = () => {
    this.setState({
      deleteModalIsOpen: false,
      mediaIdPendingDeletion: '',
      fileNamePendingDeletion: '',
    });
  };

  onDeleteMedia = () => {
    this.props.deletePhoneMedia(this.state.mediaIdPendingDeletion);
    this.setState({
      deleteModalIsOpen: false,
      mediaIdPendingDeletion: '',
      fileNamePendingDeletion: '',
    });
  };

  onFileNameChange = (mediaId: string, fileName: string) => {
    if (fileName.length > maxFileNameLength) {
      return;
    }

    const newState = { ...cloneDeep(this.state) };
    const fileIndex = findIndex(newState.fileRows, { mediaId });

    newState.fileRows[fileIndex] = {
      ...newState.fileRows[fileIndex],
      fileName,
      hasChanges: true,
    };

    this.setState(newState);
  };

  onSaveMediaFile = (mediaId: string) => {
    const newFileRows = cloneDeep(this.state.fileRows);
    const fileIndex = findIndex(newFileRows, { mediaId });
    const fileToSave: StateFileRow = newFileRows[fileIndex];
    const fileName = `${fileToSave.fileName}.${fileToSave.fileExt}`;

    if (!fileToSave.hasChanges || !fileToSave.fileName) {
      return;
    }

    fileToSave.hasChanges = false;

    this.setState({ fileRows: newFileRows });
    this.props.updatePhoneMedia({ mediaId, fileName });
  };

  renderAudio = (fileUrl: string) => {
    return (
      <audio
        controls
        src={`${fileUrl}?token=${this.props.token}&location_id=${this.props.locationId}`}
        className={css`
          height: 35px;
        `}
        preload="metadata"
      >
        Your browser does not support the audio tag.
      </audio>
    );
  };

  renderActions = (mediaId: string, fileName: string) => {
    const fileInState = find(this.state.fileRows, { mediaId });
    const showSave = fileInState && fileInState.fileName && fileInState.hasChanges;

    return (
      <div
        className={css`
          display: flex;
          align-items: center;
        `}
      >
        <Svg
          onClick={() => {
            this.setState({
              deleteModalIsOpen: true,
              mediaIdPendingDeletion: mediaId,
              fileNamePendingDeletion: fileName,
            });
          }}
          icon="Trash"
          className="rg"
          color="gray5"
          interactive
        />
        <Button
          onClick={() => this.onSaveMediaFile(mediaId)}
          size="small"
          className={css`
            margin-left: 10px;
            display: ${showSave ? 'inline-flex !important' : 'none !important'};
          `}
        >
          Save
        </Button>
      </div>
    );
  };

  renderName = (mediaId: string) => {
    const fileIndex = findIndex(this.state.fileRows, { mediaId });

    return (
      <input
        className={styles.nameInput}
        placeholder="Enter a media name"
        name={`name${mediaId}`}
        value={get(this.state.fileRows, `[${fileIndex}].fileName`, '')}
        onChange={(e) => this.onFileNameChange(mediaId, e.target.value)}
      />
    );
  };

  render() {
    return (
      <Page title="Media Library">
        <ConfirmationModal
          isOpen={this.state.deleteModalIsOpen}
          onConfirmClick={this.onDeleteMedia}
          onRequestClose={this.onCancelDelete}
          title={`Are you sure you want to delete the "${
            this.state.fileNamePendingDeletion || 'unnamed'
          }" media file?`}
        />
        <MediaUploadModal
          onRequestClose={() => this.setState({ uploadModalIsOpen: false })}
          isOpen={this.state.uploadModalIsOpen}
          maxFileNameLength={maxFileNameLength}
          uploadMediaFile={this.props.uploadPhoneMedia}
        />
        <div
          className={css`
            display: flex;
            justify-content: flex-end;
            padding-right: 30px;
          `}
        >
          <Button onClick={() => this.setState({ uploadModalIsOpen: true })}>
            Upload Media
            <Svg
              icon="Upload"
              color="white"
              className={css`
                margin-left: 10px;
              `}
            />
          </Button>
        </div>
        <div
          className={css`
            margin: 50px 0;
            padding-bottom: 100px;
          `}
        >
          <ReactTable
            columns={[
              {
                Header: 'Media Name',
                id: 'mediaName',
                accessor: (mediaFile: PhoneMedia) => this.renderName(mediaFile.MediaID),
                sortMethod: (a, b) => {
                  const aValue = toLower(a.props.value);
                  const bValue = toLower(b.props.value);

                  if (aValue === bValue) {
                    return 0;
                  }

                  return aValue > bValue ? 1 : -1;
                },
              },
              {
                Header: 'Uploaded At',
                id: 'createdAt',
                accessor: (mediaFile: PhoneMedia) =>
                  prettifyDatestring(mediaFile.CreatedAt),
              },
              {
                Header: 'Audio',
                id: 'audio',
                sortable: false,
                accessor: (mediaFile: PhoneMedia) => this.renderAudio(mediaFile.FilePath),
              },
              {
                Header: 'Actions',
                id: 'actions',
                sortable: false,
                accessor: (mediaFile: PhoneMedia) =>
                  this.renderActions(mediaFile.MediaID, mediaFile.FileName),
              },
            ]}
            data={this.props.phoneMedia}
            resolveData={(mediaFiles: PhoneMedia[]) => {
              return mediaFiles.filter((mediaFile: PhoneMedia) => {
                return (
                  !mediaFile.DeletedAt || mediaFile.DeletedAt === '0001-01-01T00:00:00Z'
                );
              });
            }}
            showPagination={false}
            loading={this.props.loadingPhoneMedia}
            pageSize={this.props.phoneMedia.length || 5}
            css={[TableStyles, styles.customTableStyles]}
          />
        </div>
      </Page>
    );
  }
}
