import React from 'react';
import { css } from '@emotion/core';
import { Text } from '@weave/design-system';
import { Select, Option, Textarea } from '@weave/platform-ui-components';
import { Opportunity } from './multi-location.types';

interface Props {
  questionData: Opportunity;
  handleAnswer: (prop: string, value: any) => void;
  showBlankErrorText: boolean;
}

export const MultiQuestions = (props: Props) => {
  return (
    <table css={() => tableStyles}>
      <tbody>
        <tr css={() => flexRow}>
          <Text
            css={() => tableCell}
            as="span"
            color={
              props.showBlankErrorText &&
              !props.questionData.multiple_locations_sharing_one_database
                ? 'error'
                : 'light'
            }
          >
            Multiple locations sharing database?
          </Text>
          <Select
            css={() => tableCellSelect}
            header="Select"
            value={props.questionData.multiple_locations_sharing_one_database}
            onChange={(value) => {
              props.handleAnswer('multiple_locations_sharing_one_database', value);
            }}
          >
            <Option value="">- Select One -</Option>
            <Option value="Yes">Yes</Option>
            <Option value="No">No</Option>
          </Select>
          <Text
            css={() => tableCell}
            as="span"
            color={
              props.showBlankErrorText &&
              !props.questionData.multiple_databases_in_single_location
                ? 'error'
                : 'light'
            }
          >
            Multiple databases in single location?
          </Text>
          <Select
            css={() => tableCellSelect}
            header="Select"
            value={props.questionData.multiple_databases_in_single_location}
            onChange={(value) => {
              props.handleAnswer('multiple_databases_in_single_location', value);
            }}
          >
            <Option value="">- Select One -</Option>
            <Option value="Yes">Yes</Option>
            <Option value="No">No</Option>
          </Select>
        </tr>
        <tr css={() => flexRow}>
          <Text
            css={() => tableCell}
            as="span"
            color={
              props.showBlankErrorText &&
              !props.questionData.do_these_locations_share_phone_number
                ? 'error'
                : 'light'
            }
          >
            Do these locations share a phone number?
          </Text>
          <Select
            css={() => tableCellSelect}
            header="Select"
            value={props.questionData.do_these_locations_share_phone_number}
            onChange={(value) => {
              props.handleAnswer('do_these_locations_share_phone_number', value);
            }}
          >
            <Option value="">- Select One -</Option>
            <Option value="Yes">Yes</Option>
            <Option value="No">No</Option>
          </Select>
          <Text
            css={() => tableCell}
            as="span"
            color={
              props.showBlankErrorText &&
              !props.questionData.cross_schedules_10_of_appointments
                ? 'error'
                : 'light'
            }
          >
            Cross schedules 10% of appointments?
          </Text>
          <Select
            css={() => tableCellSelect}
            header="Select"
            value={props.questionData.cross_schedules_10_of_appointments}
            onChange={(value) => {
              props.handleAnswer('cross_schedules_10_of_appointments', value);
            }}
          >
            <Option value="">- Select One -</Option>
            <Option value="Yes">Yes</Option>
            <Option value="No">No</Option>
          </Select>
        </tr>
        <tr css={() => flexRow}>
          <Text
            css={() => tableCell}
            as="span"
            color={
              props.showBlankErrorText && !props.questionData.needs_call_center
                ? 'error'
                : 'light'
            }
          >
            Needs call center?
          </Text>
          <Select
            css={() => tableCellSelect}
            header="Select"
            value={props.questionData.needs_call_center}
            onChange={(value) => {
              props.handleAnswer('needs_call_center', value);
            }}
          >
            <Option value="">- Select One -</Option>
            <Option value="Yes">Yes</Option>
            <Option value="No">No</Option>
          </Select>
          <Text css={() => tableCell} as="span" color="light">
            Notes
          </Text>
          <Textarea
            placeholder="For Call Center & Shared Phone Number, please include detailed explanation of setup"
            value={props.questionData.multi_notes}
            onChange={(e) => {
              props.handleAnswer('multi_notes', e.target.value);
            }}
          />
        </tr>
      </tbody>
    </table>
  );
};

const flexRow = css`
  display: flex;
  width: 100%;
  div {
    flex: 1;
    margin-right: 15px;
  }
`;

const tableStyles = css`
  width: 100%;
  margin-bottom: 20px;
`;

const tableCell = css`
  flex: 1;
  margin-top: 22px;
  margin-right: 15px;
`;

const tableCellSelect = css`
  flex: 1;
  margin-right: 15px;
`;
