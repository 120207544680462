import { createAction, handleActions } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { CustomAxios } from '../../../axios';
import { SmsModel, MsgDirectionType } from '../../../../models/sms-history.model';

export type SmsDetailsPayload = {
  uuid: string | string[] | null | undefined;
  locationId: string;
};

export type SmsDetailsResultPayload = SmsModel;

export const smsDetails = createAction<SmsDetailsPayload>('SMS_DETAILS');
export const smsDetailsSuccess =
  createAction<SmsDetailsResultPayload>('SMS_DETAILS_SUCCESS');
export const smsDetailsFailure = createAction<Error>('SMS_DETAILS_FAILURE');
export const resetSmsDetails = createAction('RESET_SMS_DETAILS');
export const selectSmsDetails = (state) => state.smsDetails;

export const handleSmsDetails = function* (action) {
  try {
    const { uuid, locationId, commxMigrationFlag } = action.payload;
    const baseUrl = commxMigrationFlag ? '/sms/data/v3/sms' : '/sms/v2/sms';
    const response = yield call(
      CustomAxios.get,
      `${baseUrl}?sms_id=${uuid}&location_id=${locationId}`
    );
    yield put(smsDetailsSuccess(response.data.sms));
  } catch (error) {
    yield put(smsDetailsFailure(error));
  }
};

export const smsDetailsSaga = function* () {
  yield all([takeEvery(smsDetails.toString(), handleSmsDetails)]);
};

export type SmsDetailsStateModel = {
  loading: boolean;
  data: SmsModel;
  error?: Error;
};

const defaultState: SmsDetailsStateModel = {
  loading: false,
  data: {
    id: '',
    createdAt: '',
    status: '',
    statusDetails: '',
    personPhone: '',
    direction: MsgDirectionType.Unspecified,
    personId: '',
    createdBy: '',
    created_by_username: '',
    autogeneratedBy: '',
  },
};

export const smsDetailsReducer = handleActions<SmsDetailsStateModel, any>(
  {
    [smsDetails.toString()]: (state) => ({ ...state, loading: true }),
    [smsDetailsSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { data: action.payload, loading: false }),
    [smsDetailsFailure.toString()]: (state, action) =>
      Object.assign({}, state, { error: action.payload, loading: false }),
    [resetSmsDetails.toString()]: (state, action) => defaultState,
  },
  defaultState
);
