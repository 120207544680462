interface ExponentialBackoffOptions {
  numOfAttempts?: number;
  startingDelay?: number;
  maxDelay?: number;
  timeMultiple?: number;
  beforeEachRetry?: () => void;
}

type ExponentialBackoffResponse<T> = [() => Promise<T | undefined>, () => void];

const sleep = (delayMilliSeconds = 0): Promise<NodeJS.Timeout | null> => {
  return new Promise((resolve) => {
    const timeoutRef = setTimeout(() => {
      resolve(timeoutRef);
    }, delayMilliSeconds);
  });
};

export const exponentialBackoff = <T>(
  retryFunction: () => Promise<T | undefined>,
  {
    numOfAttempts = 10,
    startingDelay = 100,
    timeMultiple = 2,
    maxDelay = Infinity,
    beforeEachRetry,
  }: ExponentialBackoffOptions
): ExponentialBackoffResponse<T> => {
  let delayMultiple = 1;
  let timeoutRef: NodeJS.Timeout | null = null;
  let i = 0;
  const executeBackoff = async () => {
    let response: T | undefined;
    for (i; i < numOfAttempts; i++) {
      try {
        if (beforeEachRetry) beforeEachRetry();
        response = await retryFunction();
        if (!response) throw new Error('retryFunction returned false');
        return response;
      } catch {
        timeoutRef = await sleep(Math.min(startingDelay * delayMultiple, maxDelay));
        delayMultiple *= timeMultiple;
      }
    }
    throw new Error('Maximum retries reached');
  };
  const stopBackoffLoop = () => {
    i = numOfAttempts + 1;
    if (timeoutRef) clearTimeout(timeoutRef);
  };

  return [executeBackoff, stopBackoffLoop];
};
