import { Action, Reducer } from 'redux';

/**
 * Resolved unwraps a resolved value from a promise or nested promises
 */
export type ResolvedPromise<T> = T extends PromiseLike<infer U> ? ResolvedPromise<U> : T;

export type OnCompleteFn<Res extends any = any> = (res: Res) => void;
export type RequestAction<
  ActionType extends string,
  Payload = undefined,
  Res extends any = any
> = {
  type: ActionType;
  payload: Payload;
  onComplete?: (res: Res) => void;
};

export const enforceArray = <T>(value: T | T[]): T[] =>
  Array.isArray(value) ? value : [value];

/**
 * Functions much like combineReducers, but combines reducers that are meant for the same state key
 */
export const reduceReducers = <State, CombinedActions extends Action>(
  ...reducers
): Reducer<State, CombinedActions> => {
  return (previous, current) => {
    return reducers.reduce((acc, reducer) => reducer(acc, current), previous);
  };
};
