import React from 'react';
import ReactTable, {
  ComponentPropsGetterR,
  ComponentPropsGetterRC,
  SortingRule,
} from 'react-table';
import 'react-table/react-table.css';
import { TableStyles } from '../../../styles/table-style';
import { css } from '@emotion/core';

import PaginationWithUnknownTotal from './pagination-with-unknown-total.component';

interface Props {
  className?: string;
  columns: any[];
  data: any;
  defaultSortColumn?: string;
  loadData: (skip, limit, sortColumn, sortOrder) => void;
  loading: boolean;
  resetPage: boolean;
  sortable: boolean;
  getTdProps?: ComponentPropsGetterRC | ComponentPropsGetterR;
}

interface State {
  page: number;
  pageSize: number;
  sortColumn: string;
  isSortDesc: boolean;
}

export default class ServerDataTableComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      pageSize: 25,
      sortColumn: this.props.defaultSortColumn || '',
      isSortDesc: false,
    };

    this.loadData();
  }

  // ResetPage allows the parent component to reset our page number if needed (filter params have changed, etc).
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.resetPage) {
      this.setState({ page: 0 });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state !== prevState) {
      this.loadData();
    }
  }

  // Determine if the pager should show the next button
  getPagerProps = () => {
    return {
      // this only works because we grab an extra record during loadData
      canNext: this.props.data.length > this.state.pageSize,
      pageSizeOptions: [10, 25, 50, 75],
    };
  };

  handlePageChange = (page) => {
    this.setState({ page });
  };

  handlePageSizeChange = (pageSize, page) => {
    this.setState({ pageSize, page });
  };

  handleSortChange = (sorted) => {
    const { id, desc } = sorted[0];
    this.setState({ sortColumn: id, isSortDesc: desc, page: 0 });
  };

  loadData = () => {
    // We try to grab one extra record to see if we need a 'next' button
    const limit = this.state.pageSize + 1;
    const skip = this.state.pageSize * this.state.page;

    this.props.loadData(skip, limit, this.state.sortColumn, this.state.isSortDesc);
  };

  render() {
    const visibleData =
      this.props.data.length > 0 ? this.props.data.slice(0, this.state.pageSize) : [];
    const defaultSortedColumn: SortingRule = {
      id: this.props.defaultSortColumn || '',
      desc: true,
    };

    return (
      <ReactTable
        columns={this.props.columns}
        data={visibleData}
        loading={this.props.loading}
        manual
        css={[css(this.props.className), TableStyles]}
        multiSort={false}
        sortable={this.props.sortable}
        defaultSorted={[defaultSortedColumn]}
        page={this.state.page}
        pageSize={this.state.pageSize}
        getPaginationProps={this.getPagerProps}
        getTdProps={this.props.getTdProps}
        PaginationComponent={PaginationWithUnknownTotal}
        onPageChange={this.handlePageChange}
        onPageSizeChange={this.handlePageSizeChange}
        onSortedChange={this.handleSortChange}
      />
    );
  }
}
