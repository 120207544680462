import { handleActions } from 'redux-actions';

import {
  postReviewsSignup,
  postReviewsSignupFailure,
  postReviewsSignupSuccess,
} from './post-reviews.action';

import {
  getReviewsSettings,
  getReviewsSettingsFailure,
  getReviewsSettingsSuccess,
} from './get-reviews-settings.action';

import {
  postReviewsResync,
  postReviewsResyncFailure,
  postReviewsResyncSuccess,
} from './post-reviews-resync.action';

import {
  updateReviewsSettings,
  updateReviewsSettingsFailure,
  updateReviewsSettingsSuccess,
} from './update-reviews-settings.action';

import {
  sendReviewInvitation,
  sendReviewInvitationFailure,
  sendReviewInvitationSuccess,
} from './send-review-invitation.action';

const defaultState = {};

export const reviewsReducer = handleActions(
  {
    [postReviewsSignup.toString()]: (state, action) =>
      Object.assign({}, state, { payload: action.payload, loading: false }),
    [postReviewsSignupFailure.toString()]: (state, action) =>
      Object.assign({}, state, { loading: false, action }),
    [postReviewsSignupSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { loading: false, action }),

    [getReviewsSettings.toString()]: (state, action) =>
      Object.assign({}, state, { data: {}, loading: true }),
    [getReviewsSettingsFailure.toString()]: (state, action) =>
      Object.assign({}, state, { loading: false }),
    [getReviewsSettingsSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { data: action.payload, loading: false }),

    [postReviewsResync.toString()]: (state, action) =>
      Object.assign({}, state, { loading: true }),
    [postReviewsResyncFailure.toString()]: (state, action) =>
      Object.assign({}, state, { loading: false }),
    [postReviewsResyncSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { loading: false }),

    [updateReviewsSettings.toString()]: (state, action) =>
      Object.assign({}, state, { loading: true }),
    [updateReviewsSettingsSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { data: action.payload, loading: false }),
    [updateReviewsSettingsFailure.toString()]: (state, action) =>
      Object.assign({}, state, { error: action.payload, loading: false }),

    [sendReviewInvitation.toString()]: (state) =>
      Object.assign({}, state, { invitationLoading: true }),
    [sendReviewInvitationFailure.toString()]: (state) =>
      Object.assign({}, state, { invitationLoading: false }),
    [sendReviewInvitationSuccess.toString()]: (state) =>
      Object.assign({}, state, { invitationLoading: false }),
  },
  defaultState
);
