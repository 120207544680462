import { createAction } from 'redux-actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';
import { CustomAxios } from '../../axios';
import { showError, showSuccess } from '@weave/alert-system';

export const getBlockedNumbers = createAction('GET_BLOCKED_NUMBERS');
export const getBlockedNumbersSuccess = createAction('GET_BLOCKED_NUMBERS_SUCCESS');
export const getBlockedNumbersFailure = createAction('GET_BLOCKED_NUMBERS_FAILURE');
export const deleteBlockedNumber = createAction('DELETE_BLOCKED_NUMBER');
export const deleteBlockedNumberSuccess = createAction('DELETE_BLOCKED_NUMBER_SUCCESS');
export const deleteBlockedNumberFailure = createAction('DELETE_BLOCKED_NUMBER_FAILURE');
export const addBlockedNumber = createAction('ADD_BLOCKED_NUMBER');
export const addBlockedNumberSuccess = createAction('ADD_BLOCKED_NUMBER_SUCCESS');
export const addBlockedNumberFailure = createAction('ADD_BLOCKED_NUMBER_FAILURE');

interface Action {
  payload: any;
  type: string;
}

const handleGetBlockedNumbers = function* () {
  try {
    const response = yield call(CustomAxios.get, '/support/v1/phone/blockedNumbers');
    const blockedNumbers = response.data.data;

    yield put(getBlockedNumbersSuccess(blockedNumbers || []));
  } catch {
    yield put(showError('Failed to get the blocked numbers. Please refresh the page.'));
    yield put(getBlockedNumbersFailure());
  }
};

export const getBlockedNumbersSaga = function* () {
  yield takeLatest(getBlockedNumbers.toString(), handleGetBlockedNumbers);
};

const handleDeleteBlockedNumber = function* (action: Action) {
  try {
    yield call(CustomAxios.delete, `/support/v1/phone/blockedNumbers/${action.payload}`);
    yield put(deleteBlockedNumberSuccess(action.payload));
    yield put(showSuccess('Phone number unblocked successfully'));
  } catch {
    yield put(showError('Failed to delete number - please try again'));
    yield put(deleteBlockedNumberFailure());
  }
};

export const deleteBlockedNumberSaga = function* () {
  yield takeLatest(deleteBlockedNumber.toString(), handleDeleteBlockedNumber);
};

const handleAddBlockedNumber = function* (action: Action) {
  const body = {
    blockedNumbers: [action.payload],
  };

  try {
    const response = yield call(
      CustomAxios.post,
      `/support/v1/phone/blockedNumbers`,
      body
    );
    const { id, createdAt, phoneNumber } = response.data.data[0];

    yield put(addBlockedNumberSuccess({ id, phoneNumber, createdAt }));
    yield put(showSuccess('Phone number blocked successfully'));
  } catch (error: any) {
    let errorMsg = 'Failed to add number - please try again';

    if (get(error, 'response.status') === 422) {
      errorMsg = 'This number has already been blocked';
    }

    yield put(showError(errorMsg));
    yield put(addBlockedNumberFailure());
  }
};

export const addBlockedNumberSaga = function* () {
  yield takeLatest(addBlockedNumber.toString(), handleAddBlockedNumber);
};
