import { Reducer } from 'redux';
import { keyBy, omit } from 'lodash';
import { DevicesActionTypes } from './devices.types';
import { DevicesActions, DeviceModel } from './';

export type DevicesState = { [id: string]: DeviceModel };
export const devicesReducer: Reducer<DevicesState, DevicesActions> = (
  state = {},
  action
) => {
  switch (action.type) {
    case DevicesActionTypes.Add:
      return keyBy([...Object.values(state), ...action.payload], (device) => device.ID);
    case DevicesActionTypes.Remove:
      return omit(state, action.payload);
    case DevicesActionTypes.Update:
      return { ...state, [action.payload.ID]: action.payload };
    case DevicesActionTypes.UpdateMultiple:
      return {
        ...state,
        ...keyBy(action.payload, (device) => device.ID),
      };
    default:
      return state;
  }
};
