import React, { useState, useEffect } from 'react';
import { cx } from 'emotion';
import { CaretRightIconSmall } from '@weave/design-system';

import * as styles from '../sms-list.styles';
import {
  SmsMetadataModel,
  SmsMetadataPayload,
} from '../../../../../models/sms-metadata.model';
import { SmsNotificationQueueContainer } from '../sms-notification-queue/sms-notification-queue.container';
import { SmsWritebacksContainer } from '../sms-writebacks/sms-writebacks.container';
import { MetadataDetailsContainer } from './metadata-details.container';
import { UuidCopier } from '../../../patient-details-container/uuid-copier';
import { useSelector } from 'react-redux';
import { Store } from '../../../../../redux/store/store.model';
import { selectHasFeatureFlagEnabled } from '../../../../../redux/actions/feature-flags';

interface Props {
  locationID: string;
  smsMetadata: SmsMetadataModel[];
  smsUUID: string;
  apptID?: string;
  getSmsMetadata: (payload: SmsMetadataPayload) => void;
  resetSmsMetadata: () => void;
}

export function SmsMetadata(props: Props) {
  const [selectedEvent, setSelectedEvent] = useState<SmsMetadataModel>({
    relatedDataID: '',
    relatedDataType: '',
  });
  const { resetSmsMetadata, smsUUID } = props;
  const commxMigrationFlag = useSelector((state: Store) =>
    selectHasFeatureFlagEnabled(state, 'commx-migration')
  );

  useEffect(() => {
    return () => {
      resetSmsMetadata();
    };
  }, []);

  useEffect(() => {
    getSmsMetaData();
  }, [smsUUID]);

  useEffect(() => {
    selectInitialEvent();
  }, [props.smsMetadata]);

  const selectInitialEvent = () => {
    let selectedEvent;
    if (props.apptID) {
      const filter = props.smsMetadata.find((item) => {
        return item.relatedDataID === props.apptID;
      });
      selectedEvent = filter;
    } else {
      selectedEvent = props.smsMetadata[0];
    }
    setSelectedEvent(selectedEvent);
  };

  const getSmsMetaData = () => {
    if (smsUUID && !props.smsMetadata.length) {
      props.getSmsMetadata({ smsUUID, locationId: props.locationID, commxMigrationFlag });
    }
  };

  const renderData = (item, isSmsAppt = false) => {
    const isSelected = selectedEvent === item;
    const selected = isSelected ? styles.selectedItem : '';
    const isAppt = props.apptID !== item.relatedDataID;
    const smsApptStyle = isSmsAppt ? styles.smsAppt : '';
    if (isAppt || isSmsAppt) {
      return (
        <div
          className={cx(styles.dataItem, smsApptStyle, selected, styles.selectable)}
          key={item.relatedDataID}
        >
          <div
            className={styles.flexStyle}
            key={item.relatedDataID}
            onClick={() => setSelectedEvent(item)}
          >
            <p>
              <strong className={styles.type}>{item.relatedDataType}:</strong>
            </p>
            <UuidCopier uuid={item.relatedDataID} />
            {isSelected && (
              <p className={styles.pSelected}>
                <CaretRightIconSmall />
              </p>
            )}
          </div>
          {isSelected && <MetadataDetailsContainer metadata={item} />}
        </div>
      );
    } else {
      return;
    }
  };

  let smsMetadata;
  if (props.smsMetadata.length) {
    smsMetadata = props.smsMetadata.find((item) => {
      return item.relatedDataID === props.apptID;
    });
  } else {
    smsMetadata = props.smsMetadata[0];
  }

  return (
    <div className={cx(styles.flexStyle, styles.component)}>
      <div className={styles.box}>
        <p>
          <strong>Associated Data </strong>
        </p>
        <div className={styles.eventBox}>
          {!props.smsMetadata.length && <p className={styles.blur}>No Data</p>}
          {smsMetadata && renderData(smsMetadata, true)}
          {!!props.smsMetadata.length &&
            props.smsMetadata.map((item) => renderData(item))}
        </div>
      </div>
      <SmsWritebacksContainer apptID={selectedEvent?.relatedDataID ?? ''} />
      <SmsNotificationQueueContainer relatedData={selectedEvent ?? {}} />
    </div>
  );
}
