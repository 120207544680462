import { handleActions } from 'redux-actions';
import {
  NotificationSettings,
  NotificationsState,
} from '../../../models/recall-types.models';
import { flatten } from 'lodash';

export const selectNotifications = (store) => ({
  loading: store.notifications.loading,
  error: store.notifications.error,
});

export const selectNotificationSetting = (store: any, id: string) => {
  try {
    const settings: NotificationSettings = store.notifications.notificationsSettings;
    return flatten(Object.values(settings).map((setting) => [...setting])).find(
      (setting) => setting.id === id
    );
  } catch (error: any) {
    console.error(error);
    return {};
  }
};

const defaultState: NotificationsState = {
  templates: { email: {}, sms: {}, loading: false, error: false },
  notificationsSettings: {
    recall: [],
  },
  advancedOptions: {
    'appointment-type': [],
    operatory: [],
    practitioner: [],
    location: [],
    'data-source': [],
  },
  loading: false,
};

export const notificationsReducer = handleActions<NotificationsState, any>(
  {},
  defaultState
);
