import React from 'react';
import { WeaveTheme } from '@weave/theme-original';
import { Text, NakedButton, Modal } from '@weave/design-system';
import { css } from '@emotion/core';
import { MsgDirectionType } from '../../../../../models/sms-history.model';

const modalContainer = css`
  height: 400px;
  width: 400px;
  padding: 15px;
  button {
    outline: none;
    padding: 0 8px;
    border-radius: 4px;
  }
  div {
    margin-bottom: 16px;
  }
  p {
    margin: 0;
    padding: 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  .btnIDStyle {
    border: 1px solid #eee;
    margin: 4px;
  }
`;

const highlight = (theme: WeaveTheme, shouldHighlight) =>
  shouldHighlight
    ? css`
        background-color: ${theme.colors.weaveBlue};
        color: white;
      `
    : '';

export const SmsHistoryModal = ({
  filters,
  dedupedLists,
  addFilter,
  removeFilter,
  modalProps,
}) => {
  const filterArguments = {
    dedupedLists,
    filters,
    removeFilter,
    addFilter,
  };

  const autogeneratedByFilterArr = [
    { name: 'Manual', value: false },
    { name: 'Autogenerated', value: true },
  ];

  const directionFilterArr = [
    { name: 'Inbound', value: MsgDirectionType.Inbound },
    { name: 'Outbound', value: MsgDirectionType.Outbound },
  ];

  return (
    <Modal {...modalProps}>
      <Text
        css={css`
          margin-bottom: 5px;
          padding-left: 15px;
        `}
      >
        SMS History Filters
      </Text>
      <div css={modalContainer}>
        <Text weight="bold">Generated By</Text>
        {renderFilters({
          ...filterArguments,
          filterName: 'autogeneratedBy',
          unfilterLabel: 'All',
          addFilterArr: autogeneratedByFilterArr,
        })}

        <Text weight="bold">Direction</Text>
        {renderFilters({
          ...filterArguments,
          filterName: 'direction',
          unfilterLabel: 'All',
          addFilterArr: directionFilterArr,
        })}

        <Text weight="bold">Event ID (appointment, order, recall, etc.)</Text>
        {renderFilters({
          ...filterArguments,
          filterName: 'typeId',
          unfilterLabel: 'None',
          addFilterArr: null,
        })}

        <Text weight="bold">Reminder ID</Text>
        {renderFilters({
          ...filterArguments,
          filterName: 'reminderId',
          unfilterLabel: 'None',
          addFilterArr: null,
        })}
      </div>
    </Modal>
  );
};

const renderFilters = ({
  dedupedLists,
  filters,
  removeFilter,
  addFilter,
  filterName,
  unfilterLabel,
  addFilterArr,
}) => {
  return (
    <div>
      <NakedButton
        css={(theme) => highlight(theme, !(filterName in filters))}
        onClick={() => removeFilter(filterName)}
      >
        {unfilterLabel}
      </NakedButton>
      {addFilterArr &&
        addFilterArr.map((obj) => {
          const { value, name } = obj;
          return (
            <NakedButton
              key={`filter-${filterName}-${name}-${value}`}
              css={(theme) => highlight(theme, filters[filterName] === value)}
              onClick={() => addFilter(filterName, value)}
            >
              {name}
            </NakedButton>
          );
        })}
      {dedupedLists &&
        dedupedLists[filterName] &&
        dedupedLists[filterName].length > 0 &&
        dedupedLists[filterName].map((item, index) => {
          return (
            <NakedButton
              key={item + index}
              css={(theme) => highlight(theme, filters[filterName] === item)}
              className="btnIDStyle"
              onClick={() => addFilter(filterName, item)}
            >
              {item.slice(0, 4)}...
            </NakedButton>
          );
        })}
    </div>
  );
};
