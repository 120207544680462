import React, { useEffect } from 'react';
import { css } from '@emotion/core';
import { WeaveTheme } from '@weave/theme-original';
import { Text } from '@weave/design-system';
import { useDispatch, useSelector } from 'react-redux';
import { event, selectEvent } from '../../../../redux/actions/events';
import { dateTimeDateFns } from '../../../../helpers/utils';
import { format } from 'date-fns';
import { UuidCopier } from '../../../history-list-container/patient-details-container/uuid-copier';
import { AutoMessageQueue } from '../auto-message-queue';
import { useReminderSettings } from '../reminder-settings.hook';
import { AutoRemindersDebug } from '../appointment-debugger/auto-reminders-debug';
const capitalize = css`
  text-transform: capitalize;
`;

const mainContainer = (theme: WeaveTheme) => css`
  .yellow {
    color: ${theme.colors.warning};
  }
  .info {
    color: ${theme.colors.pressedBlue};
  }
  .error {
    color: ${theme.colors.error};
  }
  .suggestion {
    color: black;
  }
  .green {
    color: ${theme.colors.strawberry};
  }
`;

const apptInfoContainer = css`
  display: flex;
`;

const dataset = css`
  display: flex;
  width: 400px;
  span {
    font-weight: bold;
    text-align: right;
    width: 40%;
    padding-right: 16px;
  }
  & .rawStatus {
    p {
      margin: 0;
      padding: 0;
    }
    margin-bottom: 8px;
  }
`;

export const OrderDebugger = ({ query }) => {
  const dispatch = useDispatch();
  const { data: order } = useSelector(selectEvent);
  const type = query.type ?? '';
  const reminderSetting = useReminderSettings(type);

  useEffect(() => {
    if (query.id) dispatch(event(`/${query.id}`));
  }, [query.id]);

  const eventTime = order.EventTime && format(new Date(order.EventTime), dateTimeDateFns);

  return (
    <div css={mainContainer}>
      <Text css={capitalize} weight="bold">
        {type}
      </Text>

      <div css={apptInfoContainer}>
        <div>
          <div css={dataset}>
            <Text as="span">Date</Text>
            <Text>{eventTime}</Text>
          </div>
          <div css={dataset}>
            <Text as="span">Description</Text>
            <Text>{order.Description}</Text>
          </div>
          <div css={dataset}>
            <Text as="span">Subtype</Text>
            <Text>{order.EventSubType}</Text>
          </div>
        </div>

        <div>
          <div css={dataset}>
            <Text as="span">PMID</Text>
            <Text>{order.PMID}</Text>
          </div>
          <div css={dataset}>
            <Text as="span">ID</Text>
            <UuidCopier uuid={order.ID} />
          </div>
        </div>
      </div>

      <hr />

      <AutoMessageQueue query={query} reminderSetting={reminderSetting} />

      <hr />

      <AutoRemindersDebug query={query} reminderSetting={reminderSetting} data={order} />
    </div>
  );
};
