import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { css } from '@emotion/core';
import { useLocation } from 'react-router-dom';

import { selectCurrentLocationId } from '../../redux/actions/location/current-location';
import { useResources } from '../../helpers/useResources';
import {
  selectSmsHistoryDashboard,
  fetchSmsHistory,
} from '../../redux/actions/sms/sms-history.action';
import { SmsModule, AutoMessagingModule } from './modules';
import { NotificationsSettings } from '../../redux/actions/notifications-settings/notification-settings.types';
import {
  getAllNotificationsQueue,
  selectNotificationQueue,
} from '../../redux/actions/notification-queue/notification-queue.action';
import {
  getMerchant,
  selectMerchantState,
} from '../../redux/actions/merchant/merchant.action';
import { LegacySettings } from '../settings/reviews/helpers';
import { PrimaryButton } from '@weave/design-system';

const MODULE_CONFIG: Record<ModuleTypes, { dependencies: ModuleDependency[] }> = {
  sms: {
    dependencies: ['smsHistory'],
  },
  'auto-messaging': {
    dependencies: [
      'smsHistory',
      'notificationQueue',
      'notificationsSettings',
      'reviewSettings',
      'merchant',
    ],
  },
  'sync-app': {
    dependencies: [],
  },
};

type ModuleDependency =
  | 'smsHistory'
  | 'notificationQueue'
  | 'notificationsSettings'
  | 'reviewSettings'
  | 'merchant';

enum ModuleTypes {
  sms = 'sms',
  autoMessaging = 'auto-messaging',
  syncApp = 'sync-app',
}

export const ContextualDashboard = () => {
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(useLocation().search);
  const requestedModules = queryParams.get('data')?.split(',');

  const locationId = useSelector(selectCurrentLocationId);
  const smsHistory = useSelector(selectSmsHistoryDashboard);
  const merchantState = useSelector((state: any) => selectMerchantState(state));
  const notificationQueue = useSelector((state) => selectNotificationQueue(state));
  const {
    data: notificationsSettingsData,
    loading: notificationsSettingsLoading,
    refresh: refreshNotificationsSettings,
  } = useResources<{ notificationSettings: NotificationsSettings }>(
    `/support/v1/notifications/settings`,
    {
      suppressFetch: true,
    }
  );
  const {
    data: reviewSettingsData,
    loading: reviewSettingsLoading,
    refresh: refreshReviewSettings,
  } = useResources<LegacySettings>('support/v1/reviews/settings', {
    suppressFetch: true,
  });

  useEffect(() => {
    if (locationId) {
      refresh();
    }
  }, [locationId]);

  /**
   * Returns a unique set of strings that represent the dependencies listed in the module
   * config object. This is so that a dependency, that could possibly be required by more
   * than one module, will not be listed more than once.
   */
  const getDependencies = () => {
    const dependencies = new Set<ModuleDependency>();
    //eslint-disable-next-line
    requestedModules?.forEach((module) => {
      const moduleConfig = MODULE_CONFIG[module];
      if (!moduleConfig) {
        console.error(`Config not set for module: ${module}`);
        return;
      }

      const moduleDependencies = moduleConfig.dependencies;
      if (!moduleDependencies) {
        console.error(`Dependencies not set for module: ${module}`);
        return;
      }

      moduleDependencies.forEach((dep) => dependencies.add(dep));
    });

    return dependencies;
  };

  const refreshAllSmsHistory = () => {
    dispatch(fetchSmsHistory());
  };

  const refreshAllNotificationsQueue = () => {
    const startDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
    const endDate = moment().add(1, 'days').format('YYYY-MM-DD');
    const payload = `?start=${startDate}&end=${endDate}&limit=250`;

    dispatch(getAllNotificationsQueue({ params: payload, startDate }));
  };

  const refresh = () => {
    const dependencies = getDependencies();

    dependencies.forEach((dependency) => {
      if (dependency === 'smsHistory') {
        refreshAllSmsHistory();
      }
      if (dependency === 'notificationQueue') {
        refreshAllNotificationsQueue();
      }
      if (dependency === 'notificationsSettings') {
        refreshNotificationsSettings();
      }
      if (dependency === 'reviewSettings') {
        refreshReviewSettings();
      }
      if (dependency === 'merchant') {
        dispatch(getMerchant());
      }
    });
  };

  return (
    <div css={containerStyles}>
      <div
        css={css`
          width: 100%;
        `}
      >
        <PrimaryButton
          css={css`
            width: 150px;
          `}
          onClick={refresh}
          disabled={
            notificationsSettingsLoading ||
            smsHistory.loading ||
            reviewSettingsLoading ||
            notificationQueue.loading ||
            merchantState.loading
          }
        >
          Refresh
        </PrimaryButton>
      </div>

      {requestedModules?.includes(ModuleTypes.sms) && (
        <SmsModule data={smsHistory.data} loading={smsHistory.loading} />
      )}

      {requestedModules?.includes(ModuleTypes.autoMessaging) && (
        <AutoMessagingModule
          notificationSettings={notificationsSettingsData?.notificationSettings ?? {}}
          reviewSettings={reviewSettingsData}
          smsHistory={smsHistory.data}
          notificationQueueData={notificationQueue.data}
          merchant={merchantState.merchant}
          loading={
            notificationsSettingsLoading ||
            smsHistory.loading ||
            reviewSettingsLoading ||
            notificationQueue.loading ||
            merchantState.loading
          }
        />
      )}

      {/* {requestedModules?.includes(ModuleTypes.syncApp) && (
        <SyncAppModule data={[]} loading={false} />
      )} */}
    </div>
  );
};

const containerStyles = css`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px;
`;
