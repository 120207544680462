import { handleActions } from 'redux-actions';
import {
  getManualTemplates,
  getManualTemplatesSuccess,
  postManualTemplates,
  postManualTemplatesSuccess,
} from './manual-templates.action';

export interface ManualTemplate {
  Template: string;
  TypeDescription: string;
  Type: string;
}

const manualTemplatesDefaultState = {
  data: [],
  loading: false,
};

const manualTemplatesReducer = handleActions(
  {
    [getManualTemplates.toString()]: (state, action) =>
      Object.assign({}, state, { loading: true }),
    [getManualTemplatesSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { loading: false, data: action.payload }),
    [postManualTemplates.toString()]: (state, action) =>
      Object.assign({}, state, { loading: true }),
    [postManualTemplatesSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { loading: false, data: action.payload }),
  },
  manualTemplatesDefaultState
);

export default manualTemplatesReducer;
