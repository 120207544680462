import { connect } from 'react-redux';

import { SmsNotificationSettings } from './sms-notification-settings.component';
import { getUsers } from '../../../../../redux/actions/users/users-list.action';
import { selectCurrentLocationId } from '../../../../../redux/actions/location/current-location';
import { Store } from '../../../../../redux/store/store.model';

export const SmsNotificationSettingsContainer = connect(
  (store: Store) => ({
    locationID: selectCurrentLocationId(store),
    smsDetails: store.smsDetails.data,
    users: store.users.data,
  }),
  {
    getUsers,
  }
)(SmsNotificationSettings);
