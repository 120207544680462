import { createAction } from 'redux-actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { arrayBufferToBase64 } from '../../../helpers/utils';
import { CustomAxios, getErrorMessage } from '../../axios';
import { showError } from '@weave/alert-system';

export const getUserPhoto = createAction('GET_USER_PHOTO');
export const getUserPhotoSuccess = createAction('GET_USER_PHOTO_SUCCESS');
export const setLoading = createAction('SET_USER_PHOTO_LOADING');

export const getUserPhotoSaga = function* () {
  const handleGetUserPhoto = function* (action) {
    const { userId, imgSize } = action.payload;
    const size: string = imgSize === 'tmb' ? 'thumbnail' : 'photo';
    try {
      yield put(setLoading(true));
      const response = yield call(
        CustomAxios.get,
        `/support/v1/person/${userId}/${size}`,
        { responseType: 'arraybuffer' }
      );
      const bufferData = response.data;
      const data = arrayBufferToBase64(bufferData);
      yield put(getUserPhotoSuccess(data || []));
      yield put(setLoading(false));
    } catch (error: any) {
      yield put(showError(getErrorMessage(error)));
    }
  };

  yield takeEvery(getUserPhoto.toString(), handleGetUserPhoto);
};
