import React, { useState } from 'react';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';

import { PatientAppointmentsModel } from './patient-appointments.model';
import * as styles from '../patient-list.style';
import { dateTimeZoneFormat } from '../../../../../helpers/utils';
import { UuidCopier } from '../../uuid-copier';
import { injectParams, OTHER } from '../../../../../constants/paths';

interface Props {
  appt: PatientAppointmentsModel;
  timezone: string;
  currentLocation: string;
}

export const PatientAppointmentListItem = ({
  appt,
  currentLocation,
  timezone,
}: Props) => {
  const [displayInfo, setDisplayInfo] = useState(false);
  const history = useHistory();
  const {
    StatusPM,
    Type,
    PersonID,
    AppointmentID,
    AppointmentPMID,
    AppointmentDate,
    EntryDate,
    Provider,
    Operatory,
    ClientLocationID,
  } = appt;

  const routeToDebug = () => {
    history.push(
      injectParams(
        OTHER.autoMessageDubugger,
        { id: currentLocation },
        {
          type: 'appointment',
          personId: PersonID,
          id: AppointmentID,
        }
      )
    );
  };

  return (
    <div className={styles.listStyle}>
      <div className={styles.container}>
        <p className="left">Appointment</p>
        <p>{moment(AppointmentDate).tz(timezone).format(dateTimeZoneFormat)}</p>
      </div>

      <div className={styles.container}>
        <p className="left">PM Status</p>
        <p>{StatusPM}</p>
      </div>

      <div className={styles.container}>
        <p className="left">Appointment ID</p>
        <UuidCopier uuid={AppointmentID} />
      </div>

      {displayInfo && (
        <>
          <div className={styles.container}>
            <p className="left">Type</p>
            <p>{Type}</p>
          </div>

          <div className={styles.container}>
            <p className="left">PMID</p>
            <p>{AppointmentPMID}</p>
          </div>

          <div className={styles.container}>
            <p className="left">Created</p>
            <p>
              {EntryDate
                ? moment(EntryDate).tz(timezone).format(dateTimeZoneFormat)
                : 'N/A'}
            </p>
          </div>

          <div className={styles.container}>
            <p className="left">Provider</p>
            <p>{Provider}</p>
          </div>

          <div className={styles.container}>
            <p className="left">Operatory</p>
            <p>{Operatory}</p>
          </div>

          <div className={styles.container}>
            <p className="left">
              <strong>Client Location</strong>
            </p>
            <UuidCopier uuid={ClientLocationID} />
          </div>
        </>
      )}

      <div>
        <button className={styles.actionBtn} onClick={() => setDisplayInfo(!displayInfo)}>
          {displayInfo ? 'show less' : 'show more'}
        </button>
        <button className={styles.actionBtn} onClick={routeToDebug}>
          Debug
        </button>
      </div>
    </div>
  );
};
