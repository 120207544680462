import React, { useEffect } from 'react';
import { css } from 'emotion';
import { Loader, Card, weaveRed } from '@weave/platform-ui-components';
import { useQuery } from '@apollo/client';

import { QueryStepValue } from '../analytics-roi-scorecard-page.types';
import { dateFilter, DateRangeValue } from '../date-filter';
import { Doughnut } from '../charts/doughnut';
import { MetricOption } from '../metrics';

interface ChartLoaderProps {
  metric: MetricOption;
  locationId: string;
  dateRange: DateRangeValue;
  shouldRefresh: boolean;
  updateCardsLoading: (loading: boolean) => void;
}

export const ChartLoader = ({
  metric,
  locationId,
  dateRange,
  updateCardsLoading,
  shouldRefresh,
}: ChartLoaderProps) => {
  const { data, loading, error, refetch } = useQuery(metric.query, {
    variables: {
      ...dateFilter(dateRange),
      step: QueryStepValue.weekly,
    },
  });

  useEffect(() => {
    updateCardsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if ((locationId && dateRange) || shouldRefresh) {
      refetch().catch((e) => console.error('error refetching data: ', error));
    }
  }, [locationId, dateRange, shouldRefresh]);

  return (
    <Card className={cardStyles}>
      <div className={cardTitle}>{metric.label}</div>
      <Loader show={loading} className={loaderStyles} />

      {error && <div className={errorMessage}>{error.message}</div>}

      {!loading && !error && data && metric.graphConfig.type === 'doughnut' && (
        <Doughnut data={data} metric={metric} />
      )}
    </Card>
  );
};

const cardStyles = css`
  width: 360px;
  height: 456px;
  margin-left: 8px;
`;

const cardTitle = css`
  color: #31353d; /* Gray 600 */
  font-size: 16px;
  line-height: 22px;
  margin-top: 24px;
  margin-left: 24px;
`;

const loaderStyles = css`
  margin-top: 130px;
`;

const errorMessage = css`
  text-align: center;
  font-size: 14px;
  color: ${weaveRed};
  padding: 16px;
`;
