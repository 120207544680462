import React from 'react';
import { css } from '@emotion/core';
import { useThemeValues } from '@weave/design-system';

import { formatEndTime, formatStartTime } from '../schedule-display-utils';

interface TimeBlockStylesProps {
  background: string;
  startRow: number;
  endRow: number;
  startCol: number;
  endCol: number;
  height: number;
  top: number;
  theme: any;
}

const timeblockStyles = ({
  background,
  startRow,
  endRow,
  startCol,
  endCol,
  height,
  top,
  theme,
}: TimeBlockStylesProps) => css`
  grid-row: ${startRow} / ${endRow};
  grid-column: ${startCol} / ${endCol};
  margin: ${top}px 0 0 0;
  padding: 0;
  height: ${height}px;
  width: 100%;
  line-height: ${theme.spacing(5)};
  text-align: left;
  background-color: ${background};
  border-radius: ${theme.borderRadius.medium};
  border: ${theme.colors.gray300} 1px solid;
  filter: opacity(30%);
  &:hover {
    filter: opacity(100%);
    border: ${theme.colors.gray600} 3px solid;
    cursor: pointer;
  }
`;

interface CalendarTimeblockProps {
  background: string;
  startCol: number;
  endCol: number;
  startHour: number;
  startMinute: number;
  duration: number;
  onClickAction: () => any;
}

export const CalendarTimeblock = ({
  background,
  startCol,
  endCol,
  startHour = 0,
  startMinute = 0,
  duration,
  onClickAction,
}: CalendarTimeblockProps) => {
  const theme = useThemeValues();
  const startRow = startHour + 2;
  const endRow = Math.ceil(duration / 60) + startRow;
  const height = (duration / 60) * 40;
  const top = (startMinute / 60) * 40;
  const timeLabel = `${formatStartTime(startHour, startMinute)} to ${formatEndTime(
    startHour,
    startMinute,
    duration
  )}`;

  return (
    <button
      css={timeblockStyles({
        background: background,
        startRow: startRow,
        endRow: endRow,
        startCol: startCol,
        endCol: endCol,
        height: height,
        top: top,
        theme,
      })}
      onClick={onClickAction}
      title={timeLabel}
      aria-label={timeLabel}
    />
  );
};
