import { connect } from 'react-redux';
import { OptionsWrapper } from './auto-reminder-options.component';
import {
  getNotificationAdvancedOptionsList,
  updateNotificationSettings,
} from '../../../../redux/actions/notifications-settings/notifications-settings.action';
import {
  NotificationAdvancedOptions,
  ReminderDetails,
  ReminderUpdate,
} from '../../../../redux/actions/notifications-settings/notification-settings.types';

interface OwnProps {
  selectedAutoReminder: ReminderDetails;
  changeLogDetails: ReminderDetails | { template: {} } | null;
  clearChangeLogSelection: () => void;
}

type DispatchProps = {
  getNotificationAdvancedOptionsList: (optionType: string) => void;
  updateNotificationSettings: (statusMapSettings: ReminderUpdate) => void;
};

type MapStateToProps = {
  notificationAdvancedOptions: NotificationAdvancedOptions;
};

type Store = {
  notificationsSettings: {
    notificationAdvancedOptions: NotificationAdvancedOptions;
  };
};

const mapStateToProps = (store) => ({
  notificationAdvancedOptions: store.notificationsSettings.notificationAdvancedOptions,
});

const mapDispatchToProps = {
  updateNotificationSettings,
  getNotificationAdvancedOptionsList,
};

export const OptionsContainer = connect<MapStateToProps, DispatchProps, OwnProps, Store>(
  mapStateToProps,
  mapDispatchToProps
)(OptionsWrapper);
