import { Reducer } from 'redux';
import { keyBy, omit } from 'lodash';
import { DepartmentPhoneNumbersActionTypes } from './department-phone-numbers.types';
import { DepartmentPhoneNumbersActions, DepartmentPhoneNumberModel } from '.';

export type DepartmentPhoneNumbersState = { [id: string]: DepartmentPhoneNumberModel };
export const departmentPhoneNumbersReducer: Reducer<
  DepartmentPhoneNumbersState,
  DepartmentPhoneNumbersActions
> = (state = {}, action) => {
  switch (action.type) {
    case DepartmentPhoneNumbersActionTypes.Add:
      return keyBy(
        [...Object.values(state), ...action.payload],
        (departmentPhoneNumber) => departmentPhoneNumber.phoneNumberId
      );
    case DepartmentPhoneNumbersActionTypes.Remove:
      return omit(state, action.payload);
    case DepartmentPhoneNumbersActionTypes.Update:
      return { ...state, [action.payload.phoneNumberId]: action.payload };
    case DepartmentPhoneNumbersActionTypes.UpdateMultiple:
      return {
        ...state,
        ...keyBy(
          action.payload,
          (departmentPhoneNumber) => departmentPhoneNumber.phoneNumberId
        ),
      };
    default:
      return state;
  }
};
