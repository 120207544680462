import React, { useEffect } from 'react';
import { SecondaryButton, SpinningLoader, Text } from '@weave/design-system';

import { syncStatusContainer, refreshButton, tabLoading } from '../sync-app-card.styles';
import { GetSyncAppInfoPayload } from '../../sync-app.types';
import { copyStyles } from '../../../../helpers/utils';

type SyncStatus = {
  [key: string]: {
    [key: string]: Number;
  };
};

interface SyncStatusTabProps {
  sourceId: string;
  loading: boolean;
  externalWindowDoc?: Document;
  syncStatusInfo: SyncStatus;
  getSyncAppInfo: (payload: GetSyncAppInfoPayload) => void;
}

const getSyncStatusHeaders = (syncStatusInfo: SyncStatus) => {
  let headers: string[] = [];
  Object.values(syncStatusInfo).forEach((info) => {
    const infoKeys = Object.keys(info);
    if (infoKeys.length > headers.length) {
      headers = infoKeys;
    }
  });
  return headers;
};

export const SyncStatusTab = ({
  sourceId,
  loading,
  externalWindowDoc,
  syncStatusInfo = {},
  getSyncAppInfo,
}: SyncStatusTabProps) => {
  const getTabInfo = () => {
    getSyncAppInfo({
      sourceId,
      rpcMethod: 'RPCAdapter.SyncStatus',
      rpcParams: [],
      syncAppInfoKey: 'syncStatus',
    });
  };

  useEffect(() => {
    if (externalWindowDoc) {
      externalWindowDoc.head.innerHTML = '';
      copyStyles(document, externalWindowDoc);
    }
    getTabInfo();
  }, []);

  return (
    <div css={syncStatusContainer}>
      {loading && (
        <div css={tabLoading}>
          <SpinningLoader />
        </div>
      )}
      {!loading && (
        <table>
          <thead>
            <tr>
              <th>{}</th>
              {getSyncStatusHeaders(syncStatusInfo).map((header, index) => (
                <th key={index}>
                  <Text as="span" weight="bold">
                    {header}
                  </Text>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.entries(syncStatusInfo).map(([key, info], index) => (
              <tr key={index}>
                <td>
                  <Text as="span" weight="bold">
                    {key}
                  </Text>
                </td>
                {Object.values(info).map((cell, cellIndex) => (
                  <td key={cellIndex}>
                    <Text as="span">{cell}</Text>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <SecondaryButton css={refreshButton} onClick={getTabInfo} disabled={loading}>
        Refresh Data
      </SecondaryButton>
    </div>
  );
};
