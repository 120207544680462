import { useAlert } from '@weave/alert-system';
import { useSelector } from 'react-redux';
import { useResources } from '../../helpers/useResources';
import { selectCurrentLocationId } from '../../redux/actions/location';
import { CustomAxios } from '../../redux/axios';
import { REACT_APP_API_URL } from '../../config/app';

interface Setting {
  set: string;
  key: string;
  value: string;
  modified_at: string;
  created_at: string;
}

const nullSetting: Setting = {
  set: '',
  key: '',
  value: '',
  modified_at: '',
  created_at: '',
};

// NOTE this may not be an exhaustive list. Feel free to add different "set" types
type Sets =
  | 'adapter'
  | 'email'
  | 'sms'
  | 'reviews'
  | 'location'
  | 'messaging'
  | 'notifications'
  | 'client'
  | 'alerts';
export const useLocationSettingsResource = (set: Sets = 'adapter') => {
  const locationId = useSelector(selectCurrentLocationId);
  const url = `/support/v1/locations/${locationId}/settings`;
  const alert = useAlert();
  const { data, refresh } = useResources<Setting[]>(`${url}?set=${set}`, {
    deps: [locationId, set],
    suppressFetch: !locationId,
    emptyState: [],
  });

  const updateSetting = (setting: Omit<Setting, 'modified_at' | 'created_at'>) => {
    // TODO FIX THIS HACK ONCE EDGE API HAS HANDLER
    CustomAxios.put(`${REACT_APP_API_URL}client-api/settings`, setting)
      .then(() => {
        alert.success('updated');
        refresh();
      })
      .catch(() => {
        alert.error('oops there was a problem');
      });
  };

  const findSetting = (key: string) => {
    return data?.find((setting) => setting.key === key) ?? nullSetting;
  };

  return { locationSettings: data, updateSetting, findSetting };
};
