import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactTable from 'react-table';
import { format, formatDuration } from 'date-fns';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import {
  Alert,
  ConfirmBadgeIcon,
  ConfirmationModal,
  EditIcon,
  ErrorBadgeIcon,
  IconButton,
  PlusIcon,
  Text,
  TrashIcon,
  useModalControl,
} from '@weave/design-system';

import {
  centerH,
  formatEndTime,
  tableStyle,
  tableHeaderStyle,
  tableHeaderTextStyle,
  textCellStyle,
  timeBlockIndex,
} from '../schedule-display-utils';
import { OfficeHoursAddTimeBlock } from './office-hours-add-timeblock.component';
import { OfficeHoursContext } from './office-hours.provider';
import { OfficeHoursEndField } from './office-hours-end-field.component';
import { OfficeHoursStartField } from './office-hours-start-field.component';
import { reactTableStyle } from '../../../shared/server-data-table/react-table.style';
import { selectCurrentLocationId } from '../../../../redux/actions/location/current-location';
import { selectCurrentWeaveUser } from '../../../../redux/actions/auth/user/user.selector';
import {
  schedulingApi,
  SchedulingTypes,
} from '../../../../apis/protos/scheduling/scheduling.proto-api';

interface OfficeHoursDayProps {
  day: Partial<SchedulingTypes['scheduleReservedTime']>[] | [];
  dayLabel: string;
}

const officeClosedTimesApi = schedulingApi('/schedule/v1/office-closed-times');

export const OfficeHoursDay = ({ day, dayLabel }: OfficeHoursDayProps) => {
  const methods = officeClosedTimesApi();
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const context = useContext(OfficeHoursContext);
  const locationId = useSelector(selectCurrentLocationId);
  const weaveUserID = useSelector(selectCurrentWeaveUser);

  const { state, dispatch } = context;
  const {
    modalProps: createTimeBlockModalProps,
    triggerProps: createTimeBlockTriggerProps,
  } = useModalControl();
  const {
    modalProps: deleteTimeBlockModalProps,
    triggerProps: deleteTimeBlockTriggerProps,
  } = useModalControl();

  const handlePost = () => {
    methods.post({
      LocationID: { uuid: locationId },
      CreatedByUserID: { uuid: weaveUserID },
      Monday: state.week.Monday,
      Tuesday: state.week.Tuesday,
      Wednesday: state.week.Wednesday,
      Thursday: state.week.Thursday,
      Friday: state.week.Friday,
      Saturday: state.week.Saturday,
      Sunday: state.week.Sunday,
    });
  };

  const handleOpenAddTimeBlockModal = () => {
    if (
      state.week[dayLabel]
        ?.map((timeBlock) => {
          return parseInt(timeBlock.DurationMinutes);
        })
        .reduce((a, b) => a + b, 0) === 1440
    ) {
      dispatch({
        type: 'UPDATE_ERRORS',
        payload: {
          dayLabel,
          index: 0,
          timeType: 'start',
          message: `${dayLabel} is fully blocked out. In order to add a new time block to this day you must delete an existing time block.`,
          messageType: 'error',
        },
      });
    } else {
      dispatch({
        type: 'SELECTED_DAY',
        payload: dayLabel,
      });
      createTimeBlockTriggerProps.onClick();
    }
  };

  const startTime = {
    Header: 'Start Time',
    id: 'startTime',
    accessor: (rowData) => rowData,
    Cell: (props) => {
      const hours = props.value?.TimeOfDay?.Hours || 0;
      const minutes = props.value?.TimeOfDay?.Minutes || 0;
      if (state.editFlags[dayLabel][props.index]) {
        return (
          <OfficeHoursStartField
            startTime={format(new Date(0, 0, 0, hours, minutes), 'H:mm')}
            endTime={formatEndTime(
              hours,
              minutes,
              parseInt(props.value?.DurationMinutes) || 0
            )}
            prevEndTime={
              props.index > 0
                ? formatEndTime(
                    state.week[dayLabel][props.index - 1]?.TimeOfDay?.Hours || 0,
                    state.week[dayLabel][props.index - 1]?.TimeOfDay?.Minutes || 0,
                    parseInt(state.week[dayLabel][props.index - 1]?.DurationMinutes) || 0
                  )
                : null
            }
            dayLabel={dayLabel}
            index={props.index}
            prevState={props.original}
          />
        );
      } else {
        return (
          <Text css={textCellStyle}>
            {format(new Date(0, 0, 0, hours, minutes), 'H:mm')}
          </Text>
        );
      }
    },
  };

  const endTime = {
    Header: 'End Time',
    id: 'endTime',
    accessor: (rowData) => rowData,
    Cell: (props) => {
      if (state.editFlags[dayLabel][props.index]) {
        const hours = props.value?.TimeOfDay?.Hours || 0;
        const minutes = props.value?.TimeOfDay?.Minutes || 0;
        return (
          <OfficeHoursEndField
            startTime={format(new Date(0, 0, 0, hours, minutes), 'H:mm')}
            endTime={formatEndTime(
              hours,
              minutes,
              parseInt(props.value?.DurationMinutes) || 0
            )}
            nextStartTime={
              props.index < state.week[dayLabel].length - 1
                ? format(
                    new Date(
                      0,
                      0,
                      0,
                      state.week[dayLabel][props.index + 1]?.TimeOfDay?.Hours || 0,
                      state.week[dayLabel][props.index + 1]?.TimeOfDay?.Minutes || 0
                    ),
                    'H:mm'
                  )
                : null
            }
            dayLabel={dayLabel}
            index={props.index}
            prevState={props.original}
          />
        );
      } else {
        return (
          <Text css={textCellStyle}>
            {formatEndTime(
              props.value?.TimeOfDay?.Hours || 0,
              props.value?.TimeOfDay?.Minutes || 0,
              parseInt(props.value?.DurationMinutes) || 0
            )}
          </Text>
        );
      }
    },
  };

  const duration = {
    Header: 'Duration',
    id: 'duration',
    accessor: (rowData) => rowData?.DurationMinutes || 0,
    Cell: (props) => {
      return (
        <Text css={textCellStyle}>
          {formatDuration({
            hours: props.value > 60 ? Math.round(props.value / 60) : 0,
            minutes: props.value % 60,
          })}
        </Text>
      );
    },
  };

  const controlButtons = {
    Header: '',
    id: 'edit',
    accessor: (rowData) => rowData,
    Cell: (props) => {
      const { errors, editFlags } = state;
      const { index, original } = props;
      const displayIndex = index + 1;

      const toggleEdit = (prevState) => ({
        type: 'TOGGLE_EDIT',
        payload: {
          dayLabel,
          index: index,
          prevState: prevState,
        },
      });

      const handleOpenDeleteTimeBlockConfirmation = () => {
        setSelectedIndex(index);
        deleteTimeBlockTriggerProps.onClick();
      };

      const handleDeleteTimeBlock = () => {
        dispatch({
          type: 'DELETE_TIMEBLOCK',
          payload: {
            index: selectedIndex,
            dayLabel: dayLabel,
          },
        });
        handlePost();
      };

      const handleSaveEdits = () => {
        dispatch(toggleEdit(null));
        handlePost();
      };

      return (
        <div
          css={css`
            ${centerH}
            width: 100%;
            height: ${theme.spacing(5)};
          `}
        >
          {!editFlags[dayLabel][index] && (
            <IconButton
              label={`edit ${dayLabel} timeblock ${displayIndex}`}
              size="small"
              onClick={() => {
                dispatch(toggleEdit(null));
              }}
            >
              <EditIcon />
            </IconButton>
          )}

          {editFlags[dayLabel][index] && (
            <IconButton
              label={`cancel edit ${dayLabel} time block #${displayIndex}`}
              size="small"
              onClick={() => {
                dispatch(toggleEdit(original));
              }}
            >
              <ErrorBadgeIcon />
            </IconButton>
          )}

          {editFlags[dayLabel][index] && (
            <IconButton
              color={
                errors[dayLabel][index].start.messageType === 'error' ||
                errors[dayLabel][index].end.messageType === 'error'
                  ? 'light'
                  : 'default'
              }
              label={`save edit ${dayLabel} time block #${displayIndex}`}
              size="small"
              onClick={handleSaveEdits}
              disabled={
                errors[dayLabel][index].start.messageType === 'error' ||
                errors[dayLabel][index].end.messageType === 'error'
              }
            >
              <ConfirmBadgeIcon />
            </IconButton>
          )}

          {!editFlags[dayLabel][index] && (
            <IconButton
              color="default"
              label={`delete ${dayLabel} time block #${displayIndex}`}
              onClick={() => handleOpenDeleteTimeBlockConfirmation()}
            >
              <TrashIcon />
            </IconButton>
          )}

          {!state.editFlags[dayLabel][index] && (
            <ConfirmationModal
              {...deleteTimeBlockModalProps}
              title="Confirm time block deletion"
              message={`Are you sure you want to delete this time block from ${dayLabel}?`}
              onCancel={() => {
                setSelectedIndex(null);
                deleteTimeBlockModalProps.onClose();
              }}
              onConfirm={handleDeleteTimeBlock}
            />
          )}
        </div>
      );
    },
    width: 75,
  };

  return (
    <>
      <div css={tableHeaderStyle(day.length > 0)}>
        <Text size="large" weight="bold" css={tableHeaderTextStyle}>
          {dayLabel}
        </Text>
        <IconButton
          label="add time block"
          color="light"
          onClick={handleOpenAddTimeBlockModal}
        >
          <PlusIcon />
        </IconButton>
      </div>

      {state.errors[dayLabel].length > 0 &&
        state.errors[dayLabel].map((error, i) => {
          const AlertMessage = (timeType: string) => (
            <Alert
              key={`${dayLabel}-startError-${i}`}
              type={error[timeType].messageType}
              onClick={() => {
                dispatch({
                  type: 'TOGGLE_ERROR_ALERT',
                  payload: {
                    dayLabel,
                    index: i,
                    timeType: timeType,
                  },
                });
              }}
            >
              {error[timeType].message}
            </Alert>
          );

          return (
            <>
              {error?.start?.message && error?.start?.show && AlertMessage('start')}
              {error?.end?.message && error?.end?.show && AlertMessage('end')}
            </>
          );
        })}

      {day.length > 0 && (
        <ReactTable
          columns={[timeBlockIndex, startTime, endTime, duration, controlButtons]}
          data={day}
          pageSize={day.length}
          showPagination={false}
          css={[reactTableStyle, tableStyle]}
        />
      )}

      {state.selectedDay === dayLabel && (
        <OfficeHoursAddTimeBlock
          modalProps={createTimeBlockModalProps}
          handlePost={handlePost}
        />
      )}
    </>
  );
};
