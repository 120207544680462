import { css } from 'emotion';

export const changeLogRow = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &:nth-child(even) {
    background: rgba(0, 0, 0, 0.03);
  }
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
  & > span {
    display: flex;
    flex: 1;
  }
  span.smallCol {
    width: 45px;
    flex: inherit;
  }
  span.medCol {
    width: 90px;
    flex: inherit;
    overflow: hidden;
  }
  span.bigCol {
    width: 160px;
    flex: inherit;
  }
  span {
    flex: 1;
    display: flex;
    align-items: center;
  }
  span:first-child {
    padding-left: 5px;
  }
  span:last-child {
    width: 45px;
    flex: inherit;
    justify-content: right;
  }
`;
