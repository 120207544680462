import React, { useEffect, useReducer } from 'react';
import { ContentLoader } from '@weave/design-system';

import {
  initialOfficeHoursState,
  OfficeHoursContext,
  OfficeHoursReducer,
} from './office-hours.provider';
import { weekdays } from '../schedule-display-utils';
import { OfficeHoursDay } from './office-hours-day.component';
import { OnlineSchedulingTabHeader } from '../online-scheduling-tab-header.component';
import { schedulingQuery } from '../../../../apis/protos/scheduling/scheduling.proto-api';

const useOfficeClosedTimes = schedulingQuery('/schedule/v1/office-closed-times');

export const OfficeHours = () => {
  const { data, refetch } = useOfficeClosedTimes();
  const [state, dispatch] = useReducer(OfficeHoursReducer, initialOfficeHoursState);

  useEffect(() => {
    if (data?.data?.LocationID && state.isLoading) {
      dispatch({
        type: 'REFRESH_DATA',
        payload: data?.data,
      });
    }
  }, [data]);

  return (
    <OfficeHoursContext.Provider
      value={{
        state: state,
        dispatch: dispatch,
      }}
    >
      <OnlineSchedulingTabHeader
        title="Unavailable Office Hours"
        createdAtDate={data?.data?.CreatedAt}
        refresh={refetch}
        refreshLabel="Refresh Log"
      />

      {state.isLoading && (
        <ContentLoader show message={'Loading...'} backgroundOpacity={0} />
      )}

      {weekdays.map(
        (dayLabel) =>
          state?.week?.[dayLabel] && (
            <OfficeHoursDay day={state.week[dayLabel]} dayLabel={dayLabel} />
          )
      )}
    </OfficeHoursContext.Provider>
  );
};
