import React from 'react';
import * as styles from '../patient-history-lists/patient-list.style';
import { cx } from 'emotion';
import { UuidCopier } from '../uuid-copier';
import { ResourceHooks } from '../../../shared/hooks/resource-hooks';

interface ContactInfo {
  destination: string;
  type: string;
  typePM: string;
}

interface Props {
  contactInfoList: ContactInfo[];
  personID: string;
}

const usePersonOptOut = ResourceHooks[`/support/v1/phones/sms/optout/:personID`];

export const PatientContactInfo = ({ personID, contactInfoList }: Props) => {
  const optOutData = usePersonOptOut(personID);

  return (
    <>
      <p>
        <strong>Contact Info</strong>
      </p>
      <div className={cx(styles.listBox, styles.contactInfoBox)}>
        {Object.values(contactInfoList).map((values: any, index) =>
          values.map((item, indexValues) => {
            const number = `${item.destination.slice(0, 4)}...`;
            return (
              <div
                className={styles.listStyle}
                key={item.destination + index + indexValues}
              >
                <div className={styles.shortList}>
                  <p>{item.typePM}</p>
                  <UuidCopier uuid={item.destination} />
                </div>
                {item.destination in optOutData &&
                  optOutData[item.destination].map((item) => (
                    <div className={styles.shortList} key={item.tenant}>
                      <span>
                        {item.tenant} / {number}
                      </span>
                      <span>{item.optedOut ? 'Opted Out' : 'Opted in'}</span>
                    </div>
                  ))}
              </div>
            );
          })
        )}
      </div>
    </>
  );
};
