import { createAction, handleActions } from 'redux-actions';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { CustomAxios } from '../../axios';
import { NotificationQueueModel } from '../../../models/notification-queue.model';

export type NotificationQueuePayload = {
  typeID: string;
  notificationSettingID: string;
};

type GetAllNotificationsQueuePayload = {
  params: string;
  startDate: string;
};

export type NotificationQueueResultPayload = NotificationQueueModel[];

export const notificationQueue =
  createAction<NotificationQueuePayload>('NOTIFICATION_QUEUE');
export const notificationQueueSuccess = createAction<NotificationQueueResultPayload>(
  'NOTIFICATION_QUEUE_SUCCESS'
);
export const notificationQueueFailure = createAction<Error>('NOTIFICATION_QUEUE_FAILURE');
export const resetNotificationQueueFailure = createAction('RESET_NOTIFICATION_QUEUE');

export const getAllNotificationsQueue = createAction<GetAllNotificationsQueuePayload>(
  'GET_ALL_NOTIFICATIONS_QUEUE'
);

export const selectNotificationQueue = (state) => state.notificationQueue;

export const handleNotificationQueue = function* (action) {
  try {
    const typeID = action.payload.typeID ? `type_id=${action.payload.typeID}` : '';
    const notificationSettingID = action.payload.notificationSettingID
      ? `notification_setting_id=${action.payload.notificationSettingID}`
      : '';
    const queryBoth =
      action.payload.typeID && action.payload.notificationSettingID ? '&' : '';
    const response = yield call(
      CustomAxios.get,
      `/support/v1/notifications/queue?${typeID}${queryBoth}${notificationSettingID}`
    );
    const newResponse = response.data.data || [];
    yield put(notificationQueueSuccess(newResponse));
  } catch (error: any) {
    yield put(notificationQueueFailure(error));
  }
};

/**
 * Attempts to retrieve all paginated data from an endpoint while more data is available
 * using the next link from the response meta data.
 */
export const handleGetAllNotificationsQueue = function* (action) {
  try {
    const params = action.payload.params;
    let url = `/support/v1/notifications/queue${params}`;
    let more = true;
    let allData: any[] = [];
    while (more) {
      const response = yield call(CustomAxios.get, url);
      if (response?.data?.data?.length > 0) {
        let responseData = response.data.data;
        // NOTE: There is currently an issue where the /notifications/queue endpoint does
        // not honor the "start" query param and will keep on sending data past the start
        // date. Since this function is meant to keep requesting data as long as more
        // data is available, this condition is put in place to prevent requesting an
        // enormous amount of data. (action.payload.startDate is only required for this
        // workaround)
        const hasDataPastStartDate = responseData.some(
          (data) => data?.deliver_at?.slice(0, 10) < action.payload.startDate
        );
        if (hasDataPastStartDate) {
          more = false;
          responseData = responseData.filter(
            (data) => !(data?.deliver_at?.slice(0, 10) < action.payload.startDate)
          );
        }

        allData = [...allData, ...responseData];

        url = response?.data?.meta?.links.next;
        if (!url) {
          more = false;
        }
      } else {
        more = false;
      }
    }
    yield put(notificationQueueSuccess(allData));
  } catch (error: any) {
    yield put(notificationQueueFailure(error));
  }
};

export const notificationQueueSaga = function* () {
  yield all([
    takeEvery(notificationQueue.toString(), handleNotificationQueue),
    takeLatest(getAllNotificationsQueue.toString(), handleGetAllNotificationsQueue),
  ]);
};

export interface NotificationQueueStateModel {
  loading: boolean;
  data: NotificationQueueModel[];
  error?: Error;
}

const defaultState: NotificationQueueStateModel = {
  loading: false,
  data: [],
  error: undefined,
};

export const notificationQueueReducer = handleActions(
  {
    [notificationQueue.toString()]: (state) =>
      Object.assign({}, state, { loading: true }),
    [notificationQueueSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { data: action.payload, loading: false }),
    [notificationQueueFailure.toString()]: (state, action) =>
      Object.assign({}, state, { error: action.payload, loading: false }),
    [resetNotificationQueueFailure.toString()]: () => defaultState,
    [getAllNotificationsQueue.toString()]: (state) =>
      Object.assign({}, defaultState, { loading: true }),
  },
  defaultState
);
