import { connect } from 'react-redux';

import { CallSettings } from './call-settings/call-settings.component';
import {
  addForwardingNumber,
  callSettings,
  deleteForwardingNumber,
  updateCallSettings,
  updateForwardingNumber,
} from '../../../redux/actions/call-settings/call-settings.action';
import { selectCurrentLocationId } from '../../../redux/actions/location';
import { Store } from '../../../redux/store/store.model';

export const CallSettingsContainer = connect(
  (state: Store) => ({
    locationId: selectCurrentLocationId(state),
    activeSmsNumber: state.callSettings.activeSmsNumber,
    callRecordingStatus: state.callSettings.callRecordingStatus,
    loading: state.callSettings.loading,
    forwardingNumbers: state.callSettings.forwardingNumbers,
    forwardingStatus: state.callSettings.forwardingStatus,
    smsNumberList: state.callSettings.smsNumberList,
  }),
  {
    addForwardingNumber,
    updateForwardingNumber,
    deleteForwardingNumber,
    getCallSettings: callSettings,
    updateCallSettings,
  }
)(CallSettings);
