import { connect } from 'react-redux';
import { LocationSearch } from './location-search/location-search.component';
import {
  locationSearch,
  userLocationsSearch,
} from '../../../redux/actions/location/location-search';
import { userSearch } from '../../../redux/actions/users';

export const LocationSearchContainer = connect(
  (state: any) => ({
    loading: state.locationSearch.loading || state.userSearch.loading,
    locations: state.locationSearch.locations,
    userSearchResults: state.userSearch.data,
    userLocations: state.locationSearch.userLocations,
  }),
  {
    locationSearch,
    userSearch,
    userLocationsSearch,
  }
)(LocationSearch);
