import { Product } from '../sales-pricing.types';
import YEALINK_T53 from '../../../images/sales-pricing/yealink-t53.jpg';
import YEALINK_T54W from '../../../images/sales-pricing/yealink-t54w.jpg';
import YEALINK_WP60 from '../../../images/sales-pricing/yealink-wp60.png';

export const phoneProductData: Product[] = [
  {
    label: 'yealink_t53',
    displayName: 'Yealink T53',
    type: 'phone',
    price: 99,
    recurringPrice: 10,
    image: YEALINK_T53,
  },
  {
    label: 'yealink_t54',
    displayName: 'Yealink T54',
    type: 'phone',
    price: 199,
    recurringPrice: 10,
    image: YEALINK_T54W,
  },
  {
    label: 'cordless_w60p',
    displayName: 'Cordless W60P',
    type: 'phone',
    price: 199,
    recurringPrice: 10,
    image: YEALINK_WP60,
  },
];
