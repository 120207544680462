import React from 'react';
import { DeleteIcon, Text } from '@weave/design-system';
import { css } from '@emotion/core';

const listView = css`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
`;

const statusCircle = css`
  margin-left: auto;
`;

export const ListView = ({ item, index }) => {
  return (
    <div css={listView} key={index}>
      <div>
        <Text weight="bold" as="span">
          {item.outageName}
        </Text>
        <p>
          <b>Paths: </b>
        </p>
        {item.path && item.path.map((path, i) => <p key={i}>{path} </p>)}
        <Text color="light">
          <b>Message: </b>
          {item.message}
        </Text>
      </div>

      <div css={statusCircle}>
        {!item.isDeleted ? (
          <svg height="20" width="20">
            <circle cx="10" cy="10" r="5" fill={item.isOutageActive ? 'green' : 'red'} />
          </svg>
        ) : (
          <DeleteIcon color="disabled" />
        )}
      </div>
    </div>
  );
};
