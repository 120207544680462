import React from 'react';
import { useAlert } from '@weave/alert-system';
import {
  Heading,
  PrimaryButton,
  Text,
  useModalControl,
  Modal,
} from '@weave/design-system';
import { PublishModal } from './publish-modal';
import { PublishDetails } from './models';
import { publishRelease } from './api';

interface Props {
  releaseId: string;
  onFinish: () => void;
  isPublished: boolean;
}

export const Publish = ({ releaseId, onFinish, isPublished }: Props) => {
  const alert = useAlert();
  const { modalProps, triggerProps } = useModalControl();

  const handlePublish = (details: PublishDetails) => {
    publishRelease(releaseId, details)
      .then(() => {
        onFinish();
        alert.success('Success!');
      })
      .catch((error) => {
        console.error(error);
        alert.error('Error publishing release');
      });
  };

  return (
    <>
      <Heading level={2}>Publish Release</Heading>
      <Text>This will publish the release to all locations</Text>
      <PrimaryButton size="tiny" {...triggerProps}>
        {isPublished ? 'Edit Publish Details' : 'Publish'}
      </PrimaryButton>

      <Modal {...modalProps}>
        <PublishModal onClose={modalProps.onClose} onSubmit={handlePublish} />
      </Modal>
    </>
  );
};
