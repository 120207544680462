import {
  format,
  subDays,
  isBefore,
  isAfter,
  addDays,
  subHours,
  addHours,
  getDay,
  differenceInMinutes,
} from 'date-fns';
import { CustomAxios } from '../../../redux/axios';
import { dateTimeDateFns, dayOfWeekDateFns } from '../../../helpers/utils';
import { ReminderDetails } from '../../../redux/actions/notifications-settings/notification-settings.types';

export interface DebugLog {
  type: 'info' | 'error' | 'suggestion' | string;
  label?: string;
  message: string;
}

export interface LogStatus {
  error: number;
  suggestion?: number;
  info?: number;
}

type EventDataMap = {
  type: string;
  value: string;
};

type EventData = {
  AppointmentDate: string;
  AppointmentID: string;
  AppointmentPMID: string;
  ClientLocationID?: string;
  ConfirmationStatus: string;
  EntryDate: string;
  Length: number;
  Operatory: string;
  PersonID: string;
  Provider: string;
  SourceID: string;
  Status: number;
  StatusPM: string;
  Type: string;
};

const mapToAppt = {
  'appointment-type': 'Type',
  practitioner: 'Provider',
  location: 'ClientLocationID',
  operatory: 'Operatory',
  'data-source': 'DataSource',
};

const dayOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const historyUrl = (id) => `/support/v1/notifications/settings/${id}/history`;

const validateDayOfWeek = (reminderSendDate, dayOfWeekSettings) => {
  const sendDayofWeekIdx = getDay(reminderSendDate);
  return dayOfWeekSettings[dayOfWeek[sendDayofWeekIdx]];
};

export const getReminderSendDate = (sendBefore, date) => {
  const apptDate = new Date(date);
  const period = sendBefore.value;
  if (sendBefore.type === 'days') {
    return sendBefore.value > 0 ? subDays(apptDate, period) : addDays(apptDate, period);
  } else {
    return sendBefore.value > 0 ? subHours(apptDate, period) : addHours(apptDate, period);
  }
};

export const getRelevantReminder = (currentReminder, data, eventData, dateFormat) => {
  let lastModifiedAt;
  const historyIndex = data.findIndex((item) => {
    const reminderSendDate = getReminderSendDate(item.send_before, eventData[dateFormat]);
    lastModifiedAt = new Date(item.modified_at);
    return isBefore(lastModifiedAt, reminderSendDate);
  });
  const reminder = historyIndex > 0 ? data[historyIndex] : currentReminder;
  return {
    reminder,
    historyIndex,
    lastModifiedAt,
    sendDate: getReminderSendDate(reminder.send_before, eventData[dateFormat]),
  };
};

export const checkDayOfWeekSettings = ({
  reminder,
  eventData,
  reminderSendDate,
  dayOfWeekSettings,
  dateFormat,
}) => {
  if (reminder.send_before.type === 'days') {
    let dayOfWeekDate;
    const validDayOfWeek = validateDayOfWeek(reminderSendDate, dayOfWeekSettings);
    if (!validDayOfWeek) {
      for (let i = 1; i < 7; i++) {
        const leadValue = reminder.send_before.value;
        const subDate = subDays(reminderSendDate, i);
        const subValidDayOfWeek = validateDayOfWeek(subDate, dayOfWeekSettings);
        const addDate = addDays(reminderSendDate, i);
        const addValidDayOfWeek =
          i < leadValue && validateDayOfWeek(addDate, dayOfWeekSettings);
        if (subValidDayOfWeek && addValidDayOfWeek) {
          const subDifference = differenceInMinutes(eventData[dateFormat], subDate);
          const addDifference = differenceInMinutes(eventData[dateFormat], addDate);
          if (addDifference < subDifference) {
            dayOfWeekDate = addDate;
            break;
          } else if (subDifference < addDifference) {
            dayOfWeekDate = subDate;
            break;
          }
        } else if (subValidDayOfWeek) {
          dayOfWeekDate = subDate;
          break;
        } else if (addValidDayOfWeek) {
          dayOfWeekDate = addDate;
          break;
        }
      }
    }

    return dayOfWeekDate;
  }
};

const checkCreationDates = ({ reminder, reminderSendDate, eventData }) => {
  const log: DebugLog[] = [];
  const reminderCreatedAt = new Date(reminder.created_at);
  const didReminderExist = isBefore(reminderCreatedAt, reminderSendDate);
  if (!didReminderExist) {
    log.push({
      type: 'error',
      message: `Reminder created (${format(
        reminderCreatedAt,
        dateTimeDateFns
      )}) after the send date`,
    });
  }
  if (eventData.EntryDate !== null) {
    const apptCreatedDate = new Date(eventData.EntryDate);
    const apptCreatedAfter = isAfter(apptCreatedDate, reminderSendDate);
    if (apptCreatedAfter) {
      log.push({
        type: 'error',
        message: `Appointment created (${format(
          apptCreatedDate,
          dateTimeDateFns
        )}) after the send date`,
      });
    }
  } else {
    log.push({
      type: 'suggestion',
      message: `Could not verify the Appointment's "created at" date`,
    });
  }
  return log;
};

const checkConfirmationRules = ({
  reminder,
  reminderSendDate,
  eventData,
  writebacks,
}) => {
  const log: DebugLog[] = [];
  if (!writebacks?.length) {
    if (reminder.template.type === 'confirmed' && eventData.Status !== 1) {
      log.push({
        type: 'error',
        message: 'Reminder set to send to confirmed, appointment is not confirmed',
      });
    }
    if (reminder.template.type === 'unconfirmed' && eventData.Status === 1) {
      log.push({
        type: 'error',
        message:
          'Reminder set to send to unconfirmed, appointment may have already been confirmed',
      });
    }
  } else {
    const writeback = writebacks.find((item) => {
      const writebackModified = new Date(item.writeback_modified_at);
      return isBefore(writebackModified, reminderSendDate);
    });
    const statusCheck =
      writeback?.writeback_status === 'Completed' ? writeback.status : '';

    if (statusCheck !== 'Confirmed' && reminder.template.type === 'confirmed') {
      log.push({
        type: 'error',
        message: `Reminder set to send to confirmed, appointment may not have been confirmed`,
      });
    }

    if (statusCheck === 'Confirmed' && reminder.template.type === 'unconfirmed') {
      log.push({
        type: 'error',
        message: `Reminder set to send to unconfirmed, appointment may have been confirmed`,
      });
    }
  }
  return log;
};

export const checkAvancedOptions = ({
  reminder,
  eventData,
}: {
  reminder: ReminderDetails;
  eventData: EventData;
}) => {
  let log: DebugLog[] = [];
  let eventDataMap: EventDataMap[] = [
    {
      type: 'appointment-type',
      value: eventData.Type,
    },
    {
      type: 'practitioner',
      value: eventData.Provider,
    },
    {
      type: 'operatory',
      value: eventData.Operatory,
    },
  ];

  if (reminder.advanced_options.length) {
    const advancedOptionsCheck = reminder.advanced_options.reduce((prev, item) => {
      const { key, value } = item;
      const isExcluded: boolean = reminder.optionTypeInfo[key].excludeOptions;
      const type = mapToAppt[key];
      let check = false;
      if (value === eventData[type]) {
        eventDataMap = eventDataMap.filter((item) => item.value !== value);
        check = true;
      }
      if (check) {
        return {
          ...prev,
          [type]: { check, isExcluded },
        };
      }

      return {
        ...prev,
      };
    });

    Object.entries(advancedOptionsCheck).forEach(([key, value]) => {
      if (value.check && value.isExcluded) {
        log.push({
          type: 'error',
          message: `${key} ${eventData[key]}  is excluded in the Advanced Options`,
        });
      }
    });

    const notIncludedLogs = eventDataMap
      .filter((value) => !reminder.optionTypeInfo[value.type].excludeOptions)
      .map((value) => ({
        type: 'error',
        message: `${mapToAppt[value.type]} ${
          eventData[mapToAppt[value.type]]
        }  is not included in the Advanced Options`,
      }));

    log = log.concat(notIncludedLogs);
  }
  return log;
};

export const getDayOfWeekLogs = (dayOfWeekDate, reminderSendDate) => {
  const dayOfWeekLog: DebugLog[] = dayOfWeekDate
    ? [
        {
          type: 'info',
          message: `Day of Week preferences altered the reminder send date from (${format(
            reminderSendDate,
            dayOfWeekDateFns
          )}) to (${format(dayOfWeekDate, dayOfWeekDateFns)})`,
        },
      ]
    : [];
  return dayOfWeekLog;
};

export const getSendLogs = ({
  reminder,
  reminderSendDate,
  historyIndex,
  lastModifiedAt,
}) => {
  const log: DebugLog[] = [];
  log.push({
    type: 'info',
    label: 'Reminder Send Date',
    message: format(reminderSendDate, dateTimeDateFns),
  });

  if (historyIndex > 0) {
    log.push({
      type: 'info',
      message: `Using most relevant historic reminder settings, modified ${format(
        lastModifiedAt,
        dateTimeDateFns
      )}`,
    });
  } else {
    log.push({
      type: 'info',
      message: `Using current reminder settings, last modified ${format(
        new Date(reminder.modified_at),
        dateTimeDateFns
      )}`,
    });
  }
  return log;
};

export const getStatus = (log) => {
  const logStatus: LogStatus = log.reduce(
    (prev, item) => {
      const value = item.type in prev ? prev[item.type] + 1 : 1;
      return {
        ...prev,
        [item.type]: value,
      };
    },
    { error: 0 }
  );

  return logStatus?.error > 0 ? `Fail ${logStatus.error} Error(s)` : ``;
};

export const checkAppointmentReminder = ({
  currentReminder,
  appointmentData,
  writebacks,
  dayOfWeekSettings,
  setLogging,
}) => {
  let log: DebugLog[] = [];

  CustomAxios.get(historyUrl(currentReminder.id))
    .then((res) => {
      const { data } = res.data;
      const { reminder, sendDate, historyIndex, lastModifiedAt } = getRelevantReminder(
        currentReminder,
        data,
        appointmentData,
        'AppointmentDate'
      );
      let reminderSendDate = sendDate;

      const dayOfWeekDate = checkDayOfWeekSettings({
        reminder,
        eventData: appointmentData,
        reminderSendDate,
        dayOfWeekSettings,
        dateFormat: 'AppointmentDate',
      });

      const dayOfWeekLog: DebugLog[] = getDayOfWeekLogs(dayOfWeekDate, reminderSendDate);

      if (dayOfWeekDate) {
        reminderSendDate = new Date(dayOfWeekDate);
      }

      //Get Send Logs
      const sendLogs = getSendLogs({
        reminder,
        reminderSendDate,
        historyIndex,
        lastModifiedAt,
      });

      //Check Enabled
      const enabledLog: DebugLog[] = !reminder.enabled
        ? [{ type: 'error', message: `Not enabled at reminder send date` }]
        : [];

      //Check Created dates
      const creationLogs = checkCreationDates({
        reminder,
        reminderSendDate,
        eventData: appointmentData,
      });

      //Check Confirmation rules
      const confirmationLogs = checkConfirmationRules({
        reminder,
        reminderSendDate,
        eventData: appointmentData,
        writebacks,
      });

      //Check Advanced Options
      const advancedOptionsLogs = checkAvancedOptions({
        reminder,
        eventData: appointmentData,
      });

      log = [
        ...log,
        ...sendLogs,
        ...dayOfWeekLog,
        ...enabledLog,
        ...creationLogs,
        ...confirmationLogs,
        ...advancedOptionsLogs,
      ];

      const status = getStatus(log);

      //Associate logs with reminder
      setLogging((logging) => ({
        ...logging,
        [currentReminder.id]: {
          status,
          display: true,
          log,
        },
      }));
    })
    .catch((err) => console.error(err));
};
