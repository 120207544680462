import React from 'react';
import { css } from '@emotion/core';
import { Text, SpinningLoader } from '@weave/design-system';
import { useSelector } from 'react-redux';
import { CustomAxios } from '../../../../redux/axios';
import { selectCurrentLocation } from '../../../../redux/actions/location';
import { useAlert } from '@weave/alert-system';
// @ts-ignore - ignoring the module import error for the .js file
import Worker from '../../../../workers/sync-app-multi-location-map-count.worker';

export const SyncAppMultiCount = () => {
  const alerts = useAlert();
  const [count, setCount] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(true);

  const [loadingLocations, setLoadingLocations] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any>([]);

  const [loadingDataSource, setLoadingDataSource] = React.useState<boolean>(true);
  const [dataSource, setDataSource] = React.useState<any>([]);

  const [loadingAccess, setLoadingAccess] = React.useState<boolean>(true);
  const [dataAccess, setDataAccess] = React.useState<any>([]);

  const [loadingChildrenLocations, setLoadingChildrenLocations] =
    React.useState<boolean>(true);
  const [dataChildren, setDataChildren] = React.useState<any>([]);

  const currentLocation = useSelector(selectCurrentLocation);

  React.useEffect(() => {
    if (!currentLocation?.LocationID) {
      return;
    }
    CustomAxios.get('support/v1/client-location').then((response) => {
      setLoadingLocations(false);
      setData(response.data.data);
    });

    CustomAxios.get('support/v1/datasource').then((response) => {
      setLoadingDataSource(false);
      setDataSource(response.data.data);
    });

    CustomAxios.get('support/v1/access').then((response) => {
      setLoadingAccess(false);
      setDataAccess(response.data.data);
    });

    CustomAxios.get(`support/v1/location/${currentLocation.LocationID}/children`).then(
      (response) => {
        setLoadingChildrenLocations(false);
        setDataChildren(response.data.data);
      }
    );
  }, [currentLocation?.LocationID]);

  React.useEffect(() => {
    if (
      !loadingAccess &&
      !loadingDataSource &&
      !loadingLocations &&
      !loadingChildrenLocations
    ) {
      const worker = new Worker();
      worker.postMessage({
        type: 'getMappedCount',
        data: { dataChildren, dataSource, data, dataAccess },
      });
      worker.onerror = (err) => {
        alerts.error('Error while mapping multi setup locations');
        setLoading(false);
      };
      worker.onmessage = (e) => {
        const mappedCount = e.data;
        setCount(mappedCount);
        setLoading(false);
        worker.terminate();
      };
    }
  }, [dataAccess, data, dataSource, dataChildren]);

  if (!currentLocation) {
    return null;
  }

  return (
    <>
      <div
        css={css`
          display: flex;
          margin-top: 12px;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;
        `}
      >
        <Text
          as="div"
          css={css`
            color: #8d919b;
            font-size: 16px;
            font-weight: bold;
          `}
        >
          Multi Setup
        </Text>

        {!loading ? (
          <Text
            as="div"
            css={css`
              font-size: 14px;
              font-weight: bold;
            `}
          >
            {count} of {dataChildren.length} mapped
          </Text>
        ) : (
          <SpinningLoader size="small" />
        )}
      </div>
    </>
  );
};
