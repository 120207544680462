import { createSelector } from 'reselect';
import { selectLocations } from '../../locations/locations.selectors';
import { selectCurrentLocationId } from '../../current-location';

export const selectLocationDepartmentIds = (locationId: string) =>
  createSelector(selectLocations, (locations) => locations[locationId]?.departmentIds);

export const selectCurrentLocationDepartmentIds = createSelector(
  selectLocations,
  selectCurrentLocationId,
  (locations, currentLocationId) => locations[currentLocationId]?.departmentIds
);
