import React, { useState, useEffect } from 'react';

import { Page } from '../shared/page/page.component';

export const Phone404 = () => {
  const [show404Message, setShow404Message] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => setShow404Message(true), 2000);
  });

  return (
    <Page
      title={show404Message ? '404' : ''}
      subtitle={
        show404Message
          ? 'This page either does not exist or is not enabled for this location'
          : ''
      }
      loading={!show404Message}
    />
  );
};
