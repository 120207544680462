import { Reducer } from 'redux';
import { keyBy } from 'lodash';
import {
  RingtonesActions,
  RingtonesActionTypes,
  RingtonesState,
} from './ringtones.types';

export const ringtonesReducer: Reducer<RingtonesState, RingtonesActions> = (
  state = {},
  action
) => {
  switch (action.type) {
    case RingtonesActionTypes.AddRingtones:
      return { ...state, ...keyBy(action.payload, (ringtone) => ringtone.value) };
    default:
      return state;
  }
};
