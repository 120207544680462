import { createAction } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { CustomAxios } from '../../axios';
import { groupBy } from 'lodash';
import { showError, showSuccess } from '@weave/alert-system';

export const onboardingGetTasks = createAction('ONBOARDING_GET_TASKS');
export const onboardingGetTasksSuccess = createAction<any>(
  'ONBOARDING_GET_TASKS_SUCCESS'
);
export const onboardingGetTasksFailure = createAction('ONBOARDING_GET_TASKS_FAILURE');

export const onboardingSetCurrentTask = createAction<any>('ONBOARDING_SET_CURRENT_TASK');
export const markTaskAsComplete = createAction<any>('ONBOARDING_MARK_TASK_AS_COMPLETE');

export const saveExplanationOnTask = createAction<any>('SAVE_EXPLANATION_ON_TASK');

export const getOnboarderSchedulingLinks = createAction<any>(
  'GET_ONBOARDER_SCHEDULING_LINKS'
);
export const getOnboarderSchedulingLinksSuccess = createAction<any>(
  'GET_ONBOARDER_SCHEDULING_LINKS_SUCCESS'
);
export const getOnboarderSchedulingLinksFailure = createAction(
  'GET_ONBOARDER_SCHEDULING_LINKS_FAILURE'
);

export const getOnboarders = createAction('GET_ONBOARDERS');
export const getOnboardersSuccess = createAction<any>('GET_ONBOARDERS_SUCCESS');
export const getOnboardersFailure = createAction('GET_ONBOARDERS_FAILURE');

export const deleteOnboarder = createAction<any>('DELETE_ONBOARDER');
export const deleteOnboarderSuccess = createAction('DELETE_ONBOARDER_SUCCESS');
export const deleteOnboarderFailure = createAction('DELETE_ONBOARDER_FAILURE');

export const updateOnboarderLinks = createAction('UPDATE_ONBOARDER_LINKS');

export const saveOnboarderSchedulingLinks = createAction<any>(
  'SAVE_ONBOARDER_SCHEDULING_LINKS'
);

export const handleUpdateOnboarderSchedulingLinks = function* (action) {
  try {
    const response = yield call(
      CustomAxios.put,
      `support/v1/onboarders/${action.payload.userId}`,
      action.payload.schedulingLinks
    );
    const onboarder = response.data.data;

    yield put(
      getOnboarderSchedulingLinksSuccess({
        scheduleCustomizationLink: onboarder.scheduleCustomizationLink,
        scheduleNetworkLink: onboarder.scheduleNetworkLink,
        scheduleSoftwareTrainingLink: onboarder.scheduleSoftwareTrainingLink,
        schedulePhoneTrainingLink: onboarder.schedulePhoneTrainingLink,
        salesforceUserId: onboarder.salesforceUserID,
        id: onboarder.id,
        deletedAt: onboarder.deletedAt,
      })
    );
    yield put(showSuccess('Links saved'));
  } catch {
    yield put(showError('Failed to save links'));
  }
};

export const updateOnboarderLinksSaga = function* () {
  yield takeEvery(updateOnboarderLinks.toString(), handleUpdateOnboarderSchedulingLinks);
};

export const handleDeleteOnboarder = function* (action) {
  try {
    yield call(CustomAxios.delete, `support/v1/onboarders/${action.payload.id}`);
    yield put(
      getOnboarderSchedulingLinksSuccess({
        scheduleCustomizationLink: '',
        scheduleNetworkLink: '',
        scheduleSoftwareTrainingLink: '',
        schedulePhoneTrainingLink: '',
        salesforceUserId: '',
        id: '',
        deletedAt: '',
      })
    );
    yield put(deleteOnboarderSuccess());
    yield put(showSuccess('Onboarder links deleted'));
  } catch {
    yield put(showError('Did not delete onboarder links'));
    yield put(deleteOnboarderFailure());
  }
};

export const deleteOnboarderSaga = function* () {
  yield takeEvery(deleteOnboarder.toString(), handleDeleteOnboarder);
};

export const handleGetOnboarderSchedulingLinks = function* (action) {
  try {
    const response = yield call(
      CustomAxios.get,
      `support/v1/onboarders/${action.payload.id}`
    );
    const onboarder = response.data.data;
    if (onboarder.deletedAt !== '0001-01-01T00:00:00Z') {
      yield put(getOnboarderSchedulingLinksFailure());
    } else {
      yield put(
        getOnboarderSchedulingLinksSuccess({
          scheduleCustomizationLink: onboarder.scheduleCustomizationLink,
          scheduleNetworkLink: onboarder.scheduleNetworkLink,
          scheduleSoftwareTrainingLink: onboarder.scheduleSoftwareTrainingLink,
          schedulePhoneTrainingLink: onboarder.schedulePhoneTrainingLink,
          salesforceUserId: onboarder.salesforceUserID,
          id: onboarder.id,
          deletedAt: onboarder.deletedAt,
        })
      );
    }
  } catch {
    yield put(getOnboarderSchedulingLinksFailure());
  }
};

export const getOnboarderLinksSaga = function* () {
  yield takeEvery(
    getOnboarderSchedulingLinks.toString(),
    handleGetOnboarderSchedulingLinks
  );
};

export const handleGetOnboarders = function* (action) {
  try {
    const response = yield call(CustomAxios.get, `support/v1/onboarders`);
    const onboarders = response.data.data;
    const activeOnboarders: any = [];

    const nonDeletedOnboarders = onboarders.filter(
      (onboarder) => onboarder.deletedAt === '0001-01-01T00:00:00Z'
    );

    yield all(
      nonDeletedOnboarders.map(function* (onboarder) {
        try {
          const userRes = yield call(
            CustomAxios.get,
            `support/v1/users/${onboarder.userID}`,
            { headers: { 'Location-Id': 'dfbbf8cd-ac87-42be-87fc-2c243272f122' } }
          );
          const user = userRes.data.data;
          onboarder.name = `${user.FirstName} ${user.LastName}`;
          onboarder.email = user.Username;
          activeOnboarders.push(onboarder);
        } catch {
          const index = onboarder.scheduleCustomizationLink.lastIndexOf('/');
          const result = onboarder.scheduleCustomizationLink.substring(index + 1);
          onboarder.name = result;
          activeOnboarders.push(onboarder);
        }
      })
    );

    yield put(getOnboardersSuccess(activeOnboarders));
  } catch {
    yield put(getOnboardersFailure());
  }
};

export const getOnboardersSaga = function* () {
  yield takeEvery(getOnboarders.toString(), handleGetOnboarders);
};

export const handleSaveOnboarderSchedulingLinks = function* (action) {
  try {
    const response = yield call(
      CustomAxios.put,
      `support/v1/onboarders/${action.payload.user.id}`,
      action.payload.schedulingLinks
    );
    const onboarder = response.data.data;
    yield call(
      CustomAxios.put,
      `support/v1/onboarderslocation/${action.payload.location.id}`,
      { onboarderID: onboarder.id }
    );
    yield put(
      getOnboarderSchedulingLinksSuccess({
        scheduleCustomizationLink: onboarder.scheduleCustomizationLink,
        scheduleNetworkLink: onboarder.scheduleNetworkLink,
        scheduleSoftwareTrainingLink: onboarder.scheduleSoftwareTrainingLink,
        schedulePhoneTrainingLink: onboarder.schedulePhoneTrainingLink,
        salesforceUserId: onboarder.salesforceUserID,
        id: onboarder.id,
        deletedAt: onboarder.deletedAt,
      })
    );
    yield put(onboardingGetTasks());
    if (action.payload.installsAlreadyScheduled) {
      const taskResponse = yield call(
        CustomAxios.get,
        `support/v1/onboarding/task_instances`
      );
      const tasks = taskResponse.data.data;
      for (const task of tasks) {
        if (
          task.taskID === 'fd4f656c-c9f1-47b8-96ad-3080b999a843' ||
          task.taskID === '16a6dc91-ec6b-4b09-b591-a5b0dfa92932' ||
          task.taskID === '720af494-38a4-499f-8633-9c8d5169cd43'
        ) {
          yield put(
            markTaskAsComplete({
              task,
              status: 2,
            })
          );
        }
      }
    }
  } catch {
    yield put(getOnboarderSchedulingLinksFailure());
    yield put(showError('Failed to save links'));
  }
};

export const saveOnboarderLinksSaga = function* () {
  yield takeEvery(
    saveOnboarderSchedulingLinks.toString(),
    handleSaveOnboarderSchedulingLinks
  );
};

export const sortByDisplayOrder = (a, b) => {
  if (a.displayOrder < b.displayOrder) {
    return -1;
  }
  if (a.displayOrder > b.displayOrder) {
    return 1;
  }
  return 0;
};

export const sortByIsReadyToWorkOn = (a, b) => {
  if (a.isReadyToWorkOn && !b.isReadyToWorkOn) {
    return -1;
  }
  if (!a.isReadyToWorkOn && b.isReadyToWorkOn) {
    return 1;
  }
  return 0;
};

export const groupByCategoryID = (task) => {
  return task.categoryID;
};

export const markTaskAsReadyToWorkOn = function (uncompletedTasks) {
  const tasksGroupedByCategory = groupBy(uncompletedTasks, groupByCategoryID);
  for (const key in tasksGroupedByCategory) {
    if (tasksGroupedByCategory.hasOwnProperty(key)) {
      const categoryTasks = tasksGroupedByCategory[key];
      if (categoryTasks.length > 0) {
        categoryTasks[0].isReadyToWorkOn = true;
      }
    }
  }
};

export const sortTasks = function* (completedTasks, uncompletedTasks) {
  yield call([uncompletedTasks, 'sort'], sortByDisplayOrder);
  yield call([completedTasks, 'sort'], sortByDisplayOrder);
  yield call(markTaskAsReadyToWorkOn, uncompletedTasks);
  yield call([uncompletedTasks, 'sort'], sortByIsReadyToWorkOn);
  for (const uncompletedTask of uncompletedTasks) {
    if (!uncompletedTask.isReadyToWorkOn) {
      uncompletedTask.displayTitle = 'NOT READY';
      break;
    }
  }
  if (completedTasks[0]) {
    completedTasks[0].displayTitle = 'COMPLETED';
  }
  const sortedTasks = yield call([uncompletedTasks, 'concat'], completedTasks);

  return sortedTasks;
};

export const filterCompletedTasks = (task) => {
  return task.status === 'Completed' || task.status === 'Verified';
};

export const filterUncompletedTasks = (task) => {
  return task.status === 'WaitingOnCustomer' || task.status === 'WaitingOnWeave';
};

export const handleGetOnboardingTasks = function* (action) {
  try {
    const response = yield call(CustomAxios.get, `support/v1/onboarding/task_instances`);
    const tasks = response.data.data;
    if (tasks && tasks.length > 0) {
      const completedTasks = yield call([tasks, 'filter'], filterCompletedTasks);
      const uncompletedTasks = yield call([tasks, 'filter'], filterUncompletedTasks);
      const sortedTasks = yield call(sortTasks, completedTasks, uncompletedTasks);

      yield put(onboardingGetTasksSuccess(sortedTasks));
    } else {
      yield put(onboardingGetTasksFailure());
    }
  } catch {
    yield put(onboardingGetTasksFailure());
    yield put(showError('Failed to get tasks'));
  }
};

export const getOnboardingTasksSaga = function* () {
  yield takeEvery(onboardingGetTasks.toString(), handleGetOnboardingTasks);
};

export const handleMarkTaskAsComplete = function* (action) {
  try {
    yield call(
      CustomAxios.put,
      `support/v1/onboarding/task_instances/${action.payload.task.id}`,
      { status: action.payload.status }
    );
    yield put(onboardingGetTasks());
  } catch {
    yield put(onboardingGetTasks());
    yield put(showError('Failed to mark task as complete'));
  }
};

export const markTaskAsCompleteSaga = function* () {
  yield takeEvery(markTaskAsComplete.toString(), handleMarkTaskAsComplete);
};

export const handleSaveExplanationOnTaskSaga = function* (action) {
  try {
    yield call(
      CustomAxios.put,
      `support/v1/onboarding/task_instances/${action.payload.task.id}`,
      { explanation: action.payload.explanation, status: 1 }
    );
    yield put(onboardingGetTasks());
  } catch {
    yield put(onboardingGetTasks());
    yield put(showError('Failed to update explanation on task'));
  }
};

export const saveExplanationOnTaskSaga = function* () {
  yield takeEvery(saveExplanationOnTask.toString(), handleSaveExplanationOnTaskSaga);
};
