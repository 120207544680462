import React from 'react';
import { Heading } from '@weave/design-system';
import { multiSyncAppLocation, multiSyncAppText } from '../integrations.styles';

export const SyncAppAccessCardHeader = () => {
  return (
    <>
      <div className={multiSyncAppLocation}>
        <Heading level={2} className={multiSyncAppText} style={{ width: '50%' }}>
          Integration
        </Heading>
        <Heading level={2} className={multiSyncAppText}>
          Enabled (Active)
        </Heading>
      </div>
      <hr />
    </>
  );
};
