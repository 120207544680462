import React, { useState, useEffect } from 'react';
import { Page } from '../../shared';
import { RouteComponentProps } from 'react-router-dom';
import { useAlert, useRequestState } from '@weave/alert-system';
import {
  PrimaryButton,
  SpinningLoader,
  TextField,
  useFormField,
} from '@weave/design-system';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/core';
import {
  getLocationSettings,
  updateLocationSettings,
  selectLocationSettingByKey,
  LocationSettingsState,
} from '../../../redux/actions/location/location-settings/location-settings.action';
import { CustomAxios } from '../../../redux/axios';
import { LocationSettingsActionTypes } from '../../../redux/actions/location/location-settings/location-settings.action';

const isValidInput = ({ value }) => {
  // no leading zeros allowed
  if (/^0/.test(value.trim())) return ERROR_MSG;
  const num = +value.trim();
  return !isNaN(num) && num > 0 && num <= 100 ? '' : ERROR_MSG;
};

type MatchParams = { id: string };
type Store = { locationSettings: LocationSettingsState };

const ERROR_MSG = 'Limit must be between 1 and 100';
const CAMPAIGNS_URL = 'support/v1/massemail/maxCampaigns';

export const EmailMarketingPage = ({ match }: RouteComponentProps<MatchParams>) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLocationSettings(match.params.id));
  }, []);

  const [sending, setSending] = useState(false);
  const { loading } = useRequestState(LocationSettingsActionTypes.Fetch);
  const setting = useSelector((state: Store) =>
    selectLocationSettingByKey(state, { key: 'max_campaigns_per_month' })
  );
  const alerts = useAlert();
  const { value, error, ...monthlyCampaignFieldProps } = useFormField(
    {
      type: 'number',
      value: setting?.value || '5',
      validator: isValidInput,
    },
    [setting]
  );

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();
    setSending(true);
    try {
      await CustomAxios.put(CAMPAIGNS_URL, { max: value });
      dispatch(updateLocationSettings({ max_campaigns_per_month: value }));
      alerts.success('Successfully update monthly campaign limit.');
    } catch (error: any) {
      console.error(error);
      alerts.error('Failed to update campaign limit. Please try again.');
    }
    setSending(false);
  };
  const trimValue = value.trim();
  const canSave = !error && !!trimValue && trimValue !== setting?.value;
  return (
    <Page title="Email Marketing" loading={loading}>
      <form onSubmit={handleSave}>
        <div
          css={css`
            max-width: 500px;
            display: flex;
          `}
        >
          <TextField
            name="monthly-campaign-limit"
            label="Monthly campaign limit"
            {...monthlyCampaignFieldProps}
            value={value}
            error={error}
          />
          {sending ? (
            <SpinningLoader />
          ) : (
            <PrimaryButton
              css={css`
                width: auto;
              `}
              type="submit"
              size="small"
              disabled={!canSave}
            >
              Save
            </PrimaryButton>
          )}
        </div>
      </form>
    </Page>
  );
};
