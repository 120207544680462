import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';

export const clipboardWrapper = css`
  position: relative;
  cursor: pointer;
`;

export const copiedStyles = css`
  position: absolute;
  top: ${theme.spacing(-3)};
  left: ${theme.spacing(5)};
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.gray400};
  padding: ${theme.spacing(1)};
  border-radius: ${theme.borderRadius.medium};
  color: ${theme.colors.weaveBlue};
`;
