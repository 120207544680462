import { css } from 'emotion';

export const orderNewNumberStyles: any = {};

orderNewNumberStyles.modalStyles = css`
  font-weight: bold;
  margin-bottom: 30px;
`;

orderNewNumberStyles.loaderStyles = css`
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

orderNewNumberStyles.typeaheadStyles = css`
  .rbt-input-hint {
    display: none;
  }
  input {
    height: 40px;
    margin-bottom: 16px;
    width: 100%;
    border-radius: 5px;
    padding-left: 15px;
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-size: 14px;
      color: #ced4d6;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      font-size: 14px;
      color: #ced4d6;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      font-size: 14px;
      color: #ced4d6;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      font-size: 14px;
      color: #ced4d6;
    }
  }
  .dropdown-item {
    font-size: 14px;
    padding: 5px 15px;
  }
`;
