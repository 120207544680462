import { FaxHistory } from '../../../components/phones/fax-settings-container/models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { createRequestSaga, createRequestStateSelector } from '@weave/alert-system';
import { Store } from '../../store/store.model';
import { createSelector } from 'reselect';
import { selectCurrentLocationId } from '../location/current-location';
import * as api from './api';

// actions
export enum FaxHistoryActionTypes {
  Fetch = 'GET_FAX_HISTORY',
  Set = 'SET_FAX_HISTORY',
}

export type FetchFaxHistoryPayload = {
  end: string;
  fromdid: string;
  order: string;
  start: string;
  todid: string;
};

type FetchFaxHistoryAction = {
  type: FaxHistoryActionTypes.Fetch;
  payload: FetchFaxHistoryPayload;
};

export type FaxHistoryActions =
  | { type: FaxHistoryActionTypes.Set; payload: FaxHistory[] }
  | FetchFaxHistoryAction;

export const fetchFaxHistory = (payload: FetchFaxHistoryPayload): FaxHistoryActions => ({
  type: FaxHistoryActionTypes.Fetch,
  payload,
});

export const setFaxHistory = (payload: FaxHistory[]): FaxHistoryActions => ({
  type: FaxHistoryActionTypes.Set,
  payload: payload,
});

// reducer
export type FaxHistoryState = FaxHistory[];

export const faxHistoryReducer = (
  state: FaxHistoryState = [],
  action: FaxHistoryActions
): FaxHistoryState => {
  switch (action.type) {
    case FaxHistoryActionTypes.Set:
      return action.payload;
    default:
      return state;
  }
};

// sagas
export const getFaxHistory = createRequestSaga<FetchFaxHistoryAction>({
  key: FaxHistoryActionTypes.Fetch,
  displayErrors: true,
  onError: () => 'Failed to load fax history.',
  saga: function* ({ payload }) {
    const yieldFaxHistory = yield call(api.requestFaxHistory, payload);
    yield put(setFaxHistory(yieldFaxHistory));
  },
});

export function* faxHistorySaga() {
  yield takeLatest(FaxHistoryActionTypes.Fetch, getFaxHistory);
}

// selectors
const selectFaxHistory = (state: Store) => state.faxHistory;

const selectFaxHistoryRequestState = createRequestStateSelector(
  FaxHistoryActionTypes.Fetch
);

export type FaxHistoryData = {
  faxHistory: FaxHistoryState;
  loading: boolean;
  locationId: string;
};

export const selectFaxHistoryData = createSelector(
  [selectFaxHistory, selectFaxHistoryRequestState, selectCurrentLocationId],
  (faxHistory, { loading }, locationId): FaxHistoryData => ({
    faxHistory,
    loading,
    locationId,
  })
);
