import alarmImage from '../../../../../images/wv-icon-alarm.png';
import bell2Image from '../../../../../images/wv-icon-bell-2.png';
import checkboxImage from '../../../../../images/icon-checkbox.png';
import birthdayImage from '../../../../../images/wv-icon-birthday.png';
import truckImage from '../../../../../images/icon-delivery-truck.png';
import {
  replaceTagsWithExample,
  templateTagExamples,
} from '../../auto-reminders-helpers/auto-reminder-helpers';
import { HtmlContent } from './edit-email-template';

export const generateContent = (DOM) => {
  const classes = [
    'emailImage',
    'emailHeader',
    'emailMessage',
    'emailButton',
    'emailFooter',
  ];
  const domContent = [...DOM.querySelector('#content').children];
  const content = domContent.map((item) => {
    const type = classes.find((type) => {
      return item.classList.contains(type) === true;
    });
    if (type !== 'emailImage') {
      return { type, content: item.innerText };
    } else {
      return {
        type,
        alt: item.firstElementChild.alt,
        src: item.firstElementChild.src,
      };
    }
  });
  return content;
};

export const cleanEditedText = (text) => {
  const urlRegex = new RegExp(
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
  );
  const emailRegex = new RegExp(/([\w.\-\pL]+@\w+\.\w+)/g);
  text = text.replace(/<[^>]+>/g, '');
  text = text.replace('{date and time}', '<strong>{date and time}</strong>');
  text = text.replace(emailRegex, '<a href=mailto:$1>$1</a>');
  text = text.replace(urlRegex, '<a href=$1>$1</a>');
  return text;
};

export const getLanguageTemplate = (templates, index) => {
  const templateIdx = Number(index);
  return templates[templateIdx]?.single ?? '';
};

export const generateHTMLString = (DOMParsed, text, template) => {
  const DOM = DOMParsed.parseFromString(template, 'text/html').documentElement;
  const domContent = DOM.querySelector('#content');
  let newContent = ``;
  if (domContent) {
    text.forEach((item: HtmlContent) => {
      const content = item.content ? cleanEditedText(item.content) : '';
      switch (item.type) {
        case 'emailHeader':
        case 'emailMessage':
        case 'emailFooter':
          newContent += `<div class='centerMargin widths ${item.type}'>${content}</div>`;
          break;
        case 'emailButton':
          newContent += `<div class='${item.type}'><a href='https://{url_external_url}/desktop/v1/unauthed/action/{url_location_id}/{nonce}'><button class='buttonStyle'>${item.content}</button></a></div>`;
          break;
        case 'emailImage':
          if (item.src) {
            const path = item.src.replace('cid:', '');
            newContent += `<div class='widths centerMargin ${item.type}'> <img src='${item.src}' alt='${item.alt}' onerror='"this.src=images/${path}"'> </div>`;
          }
          break;
      }
    });
    domContent.innerHTML = newContent;
    return DOM;
  }
};

export const getImage = (string) => {
  string = string.replace('cid:wv-icon-alarm.png', alarmImage);
  string = string.replace('cid:wv-icon-bell-2.png', bell2Image);
  string = string.replace('cid:icon-checkbox.png', checkboxImage);
  string = string.replace('cid:wv-icon-birthday.png', birthdayImage);
  string = string.replace('cid:icon-delivery-truck.png', truckImage);
  return string;
};

export const getHTMLRendering = (htmlString) => {
  const div = document.createElement('div');
  if (!htmlString) {
    return div;
  }
  const htmlStringWithImage = getImage(htmlString);
  const htmlStringWithTemplateTags = replaceTagsWithExample(
    htmlStringWithImage,
    templateTagExamples
  );
  div.innerHTML = htmlStringWithTemplateTags.trim();
  if (div.querySelector('img')) {
    // @ts-ignore ... typescript yelling about null object.
    div.querySelector('img').removeAttribute('onerror');
  }
  return div;
};

export const getButtonContent = (HTMLItems, type) => {
  let value = '';
  if (HTMLItems.length) {
    const btnExists = !HTMLItems.find((item: HtmlContent) => {
      return item.type === 'emailButton';
    });
    if (btnExists) {
      if (type === 'appointment') {
        value = 'Confirm';
      }
      if (type === 'recall') {
        value = 'Contact Me';
      }
    }
  }
  return value;
};
