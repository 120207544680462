import React, { useState } from 'react';
import { css } from 'emotion';
import { weaveBlue } from '@weave/platform-ui-components';

interface Props {
  actionText?: string;
  className?: string;
  text: string | React.ReactNode;
  expandedText?: string;
}

const expandStyles = css`
  color: ${weaveBlue};
  cursor: pointer;
  margin-left: 3px;

  label: expandStyles;
`;

export const ExpandText = ({ text, expandedText }: Props) => {
  const [showExpandedText, setShowExpandedText] = useState(false);

  return (
    <>
      {text}
      {!showExpandedText && expandedText && (
        <span className={expandStyles} onClick={() => setShowExpandedText(true)}>
          more...
        </span>
      )}
      {showExpandedText && (
        <>
          {expandedText}
          <span className={expandStyles} onClick={() => setShowExpandedText(false)}>
            ...less
          </span>
        </>
      )}
    </>
  );
};
