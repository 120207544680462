import { css } from 'emotion';

export const flexStyle = css`
  label: flexStyle;
  display: flex;
`;

export const flexwrap = css`
  label: flexwrap;
  flex-flow: wrap;
`;

export const rightStyle = css`
  label: rightStyle;
  text-align: right;
`;

export const searchPatientName = css`
  label: searchPatientName;
  position: relative;
  display: inline-block;
  width: 15%;
  min-width: 250px;
`;

export const isMobile = css`
  label: isMobile;
  color: green;
`;

export const dropDownContent = css`
  label: dropDownContent;
  display: block;
  width: 15%;
  position: absolute;
  background-color: #fff;
  min-width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  max-height: 50vh;
  overflow-y: auto;
`;

export const atag = css`
  label: atag;
  color: black;
  padding: 12px 16px;
  cursor: pointer;
  display: block;
  &:hover {
    color: black;
    background-color: #f6f6f6;
    text-decoration: none;
  }
`;

export const displayBlock = css`
  display: block;
  label: displayBlock;
`;

export const displayNone = css`
  display: none;
  label: displayNone;
`;

export const patientListStyle = css`
  width: 50%;
  padding: 0px;
  label: patientList;
`;

export const showPill = css`
  label: showPill;
  height: 32px;
  color: white;
  border: 1px solid #37cff1;
  border-radius: 3px;
  background-color: #37cff1;
  padding: 5px;
  margin: 5px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

export const hidePill = css`
  label: hidePill;
  height: 32px;
  color: #666;
  border: 1px solid #37cff1;
  border-radius: 3px;
  background-color: #eee;
  padding: 5px;
  margin: 5px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;
