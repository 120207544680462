import { css } from 'emotion';
import { weaveRed } from '@weave/platform-ui-components';

export const modal = css`
  label: modal;

  .react-modal-card {
    min-width: 360px;
    padding: 20px;
    text-align: center;
  }
`;

export const error = css`
  color: ${weaveRed};
`;

export const fileInputLabel = css`
  label: fileInputLabel;

  cursor: pointer;
  display: inline-block;
  margin: 15px 0;
  padding: 6px 12px;
`;

export const fileInput = css`
  label: fileInput;

  display: none;
`;

export const buttonContainer = css`
  label: buttonContainer;

  margin-top: 40px;
`;

export const button = css`
  label: button;

  display: inline-block;

  &:last-of-type {
    margin-left: 10px;
  }
`;
