import { uniq, without } from 'lodash';
import { Reducer } from 'redux';
import { LocationsState } from '../../locations/locations.types';

export enum LocationDevicesActionTypes {
  Add = 'LocationDevices:Add',
  Remove = 'LocationDevices:Remove',
}

export type AddLocationDevicesAction = {
  type: LocationDevicesActionTypes.Add;
  payload: { locationId: string; deviceIds: string[] };
};

export type RemoveLocationDevicesAction = {
  type: LocationDevicesActionTypes.Remove;
  payload: { locationId: string; deviceIds: string[] };
};

export type LocationDevicesActions =
  | AddLocationDevicesAction
  | RemoveLocationDevicesAction;

export const addDevicesToLocation = (
  locationId: string,
  deviceIds: string[]
): LocationDevicesActions => ({
  type: LocationDevicesActionTypes.Add,
  payload: { locationId, deviceIds },
});

export const removeDevicesFromLocation = (
  locationId: string,
  deviceIds: string[]
): LocationDevicesActions => ({
  type: LocationDevicesActionTypes.Remove,
  payload: { locationId, deviceIds },
});

export const locationDevicesReducer: Reducer<LocationsState, LocationDevicesActions> = (
  state = {},
  action
) => {
  if (!action.payload) {
    return state;
  }

  const location = state[action.payload.locationId];
  const prevDeviceIds = location?.deviceIds ?? [];
  if (!location) {
    return state;
  }

  const deviceIds =
    {
      [LocationDevicesActionTypes.Add]: () =>
        uniq([...prevDeviceIds, ...action.payload.deviceIds]),
      [LocationDevicesActionTypes.Remove]: () =>
        without(prevDeviceIds, ...action.payload.deviceIds),
    }[action.type]?.() ?? prevDeviceIds;

  return {
    ...state,
    [location.LocationID]: { ...location, deviceIds },
  };
};
