import { css } from 'emotion';
import { theme } from '@weave/theme-original';

export const flex = css`
  display: flex;
`;
export const marginRight5 = css`
  margin-right: 5px;
`;
export const columnWidth = css`
  width: 300px;
`;
export const columnSpacing = css`
  margin-left: 50px;
  margin-top: 25px;
  height: 1000px;
`;
export const inlineFormSpacer = css`
  margin-top: 5px;
`;
export const loader = css`
  margin: 20px auto;
  width: 40px;
`;
export const creationStatusContainer = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
export const creationStatusButton = css`
  display: inline-block !important;
  margin-top: 20px;
`;
export const creationStatusListItem = css`
  margin: 5px;
`;
export const warningText = css`
  color: ${theme.colors.error};
`;
export const locationForm = css`
  margin: 0 20px;
`;
export const formGroup = css`
  margin-bottom: 20px;
`;
export const validationError = css`
  color: ${theme.colors.error};
`;
