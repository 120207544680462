import { all } from 'redux-saga/effects';
import {
  addNewUserSaga,
  deleteUserSaga,
  getUsersSaga,
  saveUserProfileSaga,
  sendPasswordResetSaga,
  updateWeaveUserRolesSaga,
} from './users-list.action';
import { getAvailableRolesSaga } from './user-roles.action';
import { userSearchSaga, userSelectSaga } from './user-search.action';
import {
  getAvailableJobTitlesSaga,
  getJobTitlesSaga,
  updateJobTitlesSaga,
} from './user-job-titles.action';
import { getConnectedUsersSaga } from './connected-users.action';

export {
  getUsers,
  addNewUser,
  saveUserProfile,
  updateWeaveUserRoles,
  sendPasswordReset,
  deleteUser,
} from './users-list.action';
export {
  getAvailableJobTitles,
  updateJobTitles,
  getJobTitles,
} from './user-job-titles.action';
export { updateUserRoles, getAvailableRoles } from './user-roles.action';
export { userSearch, userSelect, setUserSearchText } from './user-search.action';
export { usersListReducer } from './users-list.reducer';
export { getConnectedUsers } from './connected-users.action';

export const usersSaga = function* () {
  yield all([
    getUsersSaga(),
    addNewUserSaga(),
    saveUserProfileSaga(),
    updateWeaveUserRolesSaga(),
    sendPasswordResetSaga(),
    userSelectSaga(),
    userSearchSaga(),
    getAvailableRolesSaga(),
    deleteUserSaga(),
    getAvailableJobTitlesSaga(),
    updateJobTitlesSaga(),
    getJobTitlesSaga(),
    getConnectedUsersSaga(),
  ]);
};
