import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import {
  NakedButton,
  SearchField,
  useControlledField,
  useOnClickOutside,
} from '@weave/design-system';
import { IndexedContent, useWamSearch } from './use-wam-search';
import { injectParams } from '../../../constants/paths';
import { useSelector } from 'react-redux';
import { selectCurrentLocationId } from '../../../redux/actions/location';

const mainContainer = css`
  position: relative;
  margin: auto ${theme.spacing(1)};
  width: 100%;
  @media only screen and (min-width: 1200px) {
    max-width: 250px;
  }
`;

const container = css`
  position: absolute;
  top: 45px;
  left: 0;
  width: inherit;
  background-color: ${theme.colors.white};
  border-radius: ${theme.borderRadius.medium};
  box-shadow: ${theme.shadows.hover};
  z-index: ${theme.zIndex.highest};
  color: black;
  max-height: 50vh;
  overflow-y: auto;
  button {
    text-transform: capitalize;
    text-align: left;
    outline: none;
    width: 100%;
    padding: ${theme.spacing(0.5, 2)};
    cursor: pointer;
    :hover {
      background-color: ${theme.colors.hoverBlue};
      color: ${theme.colors.white};
      font-weight: bold;
    }
    p {
      margin: auto;
    }
  }
`;

const searchStyle = css`
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
`;

export const WamSearch = () => {
  const history = useHistory();
  const ref = useRef(null);
  const locationID = useSelector(selectCurrentLocationId);
  const [search, setSearch] = useState<string>('');
  const searchProps = useControlledField({
    type: 'text',
    value: search,
    onChange: (value) => setSearch(value),
  });
  const results: IndexedContent[] = useWamSearch(searchProps.value, locationID);
  const [displayResults, setDisplayResults] = useState<boolean>(false);

  useOnClickOutside({ active: true, ref, handler: () => setDisplayResults(false) });

  const selectResult = (item) => {
    setSearch('');
    setDisplayResults(false);
    history.push(injectParams(item.route, { id: locationID }));
  };

  const updateDisplay = () => {
    const display = !!searchProps.value && !!results.length;
    setDisplayResults(display);
  };

  useEffect(() => {
    updateDisplay();
  }, [searchProps.value, results.length]);

  return (
    <div ref={ref} css={mainContainer}>
      <SearchField
        {...searchProps}
        name="power-search-wam"
        css={searchStyle}
        placeholder="Search Features"
        onClick={updateDisplay}
        disabled={!locationID}
        autoComplete="off"
      />
      {displayResults && (
        <div css={container}>
          {results.map((item: IndexedContent) => (
            <NakedButton key={item?.route} onClick={() => selectResult(item)}>
              <p>{item?.displayName}</p>
            </NakedButton>
          ))}
        </div>
      )}
    </div>
  );
};
