import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  getChildAccounts,
  getOpportunities,
  getPreProvisions,
  postQuestionData,
  postAccountData,
} from './multi-location.resource';
import { MultiQuestions } from './multi-questions.component';
import { MultiTable } from './multi-table.component';
import { MultiModal } from './multi-modal.component';
import { useAlert } from '@weave/alert-system';
import {
  CheckboxField,
  Text,
  useControlledField,
  SpinningLoader,
  PrimaryButton,
} from '@weave/design-system';
import { find, isEqual } from 'lodash';
import { AccountData, Opportunity } from './multi-location.types';
import { css } from '@emotion/core';

export const MultiLocation = () => {
  const alerts = useAlert();
  const [showModal, setShowModal] = useState(false);
  const [accountData, setAccountData] = useState<AccountData[]>([]);
  const [questionData, setQuestionData] = useState<Opportunity>({});
  const [originalQuestionData, setOriginalQuestionData] = useState<Opportunity>({});
  const [opportunities, setOpportunities] = useState<Opportunity[]>([]);
  const [showBlankErrorText, setShowBlankErrorText] = useState(false);
  const [showSaveErrorText, setShowSaveErrorText] = useState(false);
  const [showProvisionedError, setShowProvisionedError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const queryParams = new URLSearchParams(useLocation().search);
  const accountId = queryParams.get('account-id');
  const showSaveButton = !isEqual(questionData, originalQuestionData);
  const accountsWithErrors = accountData
    .filter((account) => !account.provisioned && account.selected)
    .reduce((acc, val, i, arr) => {
      return `${acc}${val.name}${arr.length - 1 === i ? '' : ', '}`;
    }, '');
  const selectAllCheckboxValue = !accountData.find((account) => {
    if (account.complete || account.type === 'Satellite') {
      return false;
    } else {
      return !account.selected;
    }
  });

  const selectAllCheckBoxField = useControlledField({
    type: 'checkbox',
    value: selectAllCheckboxValue,
    onChange: () => handleSelectAllCheckboxClick(),
  });

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (showBlankErrorText) {
      setShowBlankErrorText(isThereAnAnswerMissing());
    }
  }, [
    questionData.multiple_locations_sharing_one_database,
    questionData.multiple_databases_in_single_location,
    questionData.do_these_locations_share_phone_number,
    questionData.cross_schedules_10_of_appointments,
    questionData.needs_call_center,
  ]);
  let childAccounts: AccountData[] = [];
  const getData = async () => {
    if (accountId) {
      childAccounts = await getChildAccounts(accountId);
      const accountStrings = childAccounts.reduce((acc, account, i, arr) => {
        return `${acc}${account.id}${arr.length - 1 === i ? '' : ','}`;
      }, '');
      const opportunities = await getOpportunities(accountStrings);
      const preProvisions = await getPreProvisions(opportunities);
      setOpportunities(opportunities);
      setAccountData(organizeAccountData(childAccounts, opportunities, preProvisions));
      const questionOpportunity = getQuestionOpportunity(opportunities);
      setQuestionData(questionOpportunity);
      setOriginalQuestionData(questionOpportunity);
      setIsLoading(false);
      if (childAccounts.find((account) => account.record_type === 'Satellite')) {
        alerts.warning('This page does not support Satellite locations yet');
      }
    }
  };

  const getQuestionOpportunity = (opportunities) => {
    const questionOpportunity = opportunities.find((opportunity) => {
      return find(opportunity, (prop) => {
        return prop === 'Yes' || prop === 'No';
      });
    });
    /*
    Here we find the first opportunity with saved answers, or select the first one if none of them have a saved answer
    */
    return questionOpportunity || opportunities[0];
  };

  const organizeAccountData = (childAccounts, opportunities, preProvisions) => {
    return childAccounts.map((account) => {
      const preProvision = preProvisions.find(
        (preProv) => preProv.salesforce_account_id === account.id
      );
      const opportunity = opportunities.find((opp) => opp.account_id === account.id);
      return {
        id: opportunity.id,
        selected: false,
        name: account.name,
        type: account.record_type,
        opportunity: opportunity.record_type,
        provisioned: !!preProvision.id,
        complete: !!preProvision.provisioned_at,
        bookings: opportunity.bookings,
      };
    });
  };

  const handleCheckboxClick = (i) => {
    const newAccountData = [...accountData];
    const clickedAccount = {
      ...newAccountData[i],
      selected: !newAccountData[i].selected,
    };
    newAccountData.splice(i, 1, clickedAccount);
    if (showProvisionedError) {
      checkProvisionedError(newAccountData);
    }
    setAccountData(newAccountData);
  };

  const handleSelectAllCheckboxClick = () => {
    const newAccountData = accountData.map((account) => {
      if (account.complete || account.type === 'Satellite') {
        return account;
      } else {
        return { ...account, selected: !selectAllCheckboxValue };
      }
    });
    if (showProvisionedError) {
      checkProvisionedError(newAccountData);
    }
    setAccountData(newAccountData);
  };

  const checkProvisionedError = (accountData: AccountData[]) => {
    const isThereAnAccountWithError = !!accountData.find(
      (data) => !data.provisioned && data.selected
    );
    setShowProvisionedError(isThereAnAccountWithError);
  };

  const saveQuestionData = async () => {
    setIsLoading(true);
    await postQuestionData(opportunities, questionData);
    await postAccountData({ multi_location: true, id: questionData.account_id });
    if (questionData.account_id) {
      childAccounts = await getChildAccounts(questionData.account_id);
      await Promise.all(
        childAccounts.map((childAccount) => {
          return postAccountData({ multi_location: true, id: childAccount.id });
        })
      );
    }
    setShowSaveErrorText(false);
    setOriginalQuestionData(questionData);
    setIsLoading(false);
  };

  const handleButtonClick = () => {
    if (isThereAnAnswerMissing()) {
      setShowBlankErrorText(true);
    } else if (showSaveButton) {
      setShowSaveErrorText(true);
    } else if (
      accountData.filter((data) => data.selected).find((data) => !data.provisioned)
    ) {
      setShowProvisionedError(true);
    } else {
      setShowModal(true);
    }
  };

  const isThereAnAnswerMissing = () => {
    return (
      questionData.multiple_locations_sharing_one_database === '' ||
      questionData.multiple_databases_in_single_location === '' ||
      questionData.do_these_locations_share_phone_number === '' ||
      questionData.cross_schedules_10_of_appointments === '' ||
      questionData.needs_call_center === ''
    );
  };

  const handleAnswer = (prop, value) => {
    setQuestionData({ ...questionData, [prop]: value });
  };

  return isLoading ? (
    <div css={() => loadingContainer}>
      <SpinningLoader />
    </div>
  ) : (
    <div css={() => mainContainer}>
      <div css={() => searchContainer}>
        <Text css={() => titleText} weight="bold">
          Multi Location Tool
        </Text>
      </div>
      <div css={() => refreshContainer}>
        <Text css={() => parentAccountText} weight="bold">
          Parent Account
        </Text>
        <PrimaryButton css={() => refreshButton} onClick={() => window.location.reload()}>
          Refresh
        </PrimaryButton>
      </div>
      <div css={() => otherContainer}>
        <Text weight="bold" as="span">
          Multi-location Setup
        </Text>
        {showSaveButton && (
          <PrimaryButton size="small" css={() => saveButton} onClick={saveQuestionData}>
            Save
          </PrimaryButton>
        )}
      </div>

      <div css={() => errorContainer}>
        <Text as="span" color="error">
          {showSaveErrorText
            ? 'ERROR: Please save your changes before proceeding'
            : showBlankErrorText
            ? 'ERROR: Please answer each question below before proceeding'
            : ''}
        </Text>
      </div>
      <div css={() => questionnaireContainer}>
        <MultiQuestions
          handleAnswer={handleAnswer}
          showBlankErrorText={showBlankErrorText}
          questionData={questionData}
        />
      </div>
      <div css={() => otherContainer}>
        <Text weight="bold" as="span">
          Account Hierarchy
        </Text>
      </div>
      <div css={() => errorContainer}>
        <Text as="span" color="error">
          {showProvisionedError
            ? `ERROR: Please fix the errors for${accountsWithErrors} before proceeding`
            : ''}
        </Text>
      </div>
      <div css={() => questionnaireContainer}>
        <div>
          <CheckboxField
            {...selectAllCheckBoxField}
            label="Select All"
            name="select_all"
            onChange={handleSelectAllCheckboxClick}
            css={checkboxStyles}
          />
        </div>
        <MultiTable accountData={accountData} handleCheckboxClick={handleCheckboxClick} />
      </div>
      <div css={() => buttonContainer}>
        <PrimaryButton
          css={() => mainButtonStyles}
          size="large"
          onClick={handleButtonClick}
          disabled={!accountData.find((data) => data.selected)}
        >
          Next
        </PrimaryButton>
      </div>

      <MultiModal
        showModal={showModal}
        setShowModal={setShowModal}
        accountData={accountData}
        setIsLoading={setIsLoading}
        getData={getData}
      />
    </div>
  );
};

const mainContainer = css`
  padding: 20px;
  height: 100%;
  width: 1200px;
  margin: auto;
`;

const loadingContainer = css`
  margin: auto;
`;

const searchContainer = css`
  padding: 0 20px;
  border-bottom: 2px solid lightgrey;
`;

const questionnaireContainer = css`
  padding: 0 20px;
  width: 100%;
`;

const otherContainer = css`
  display: flex;
  padding: 0 20px;
  width: 100%;
  align-items: center;
`;

const titleText = css`
  font-size: 1.125rem;
`;

const parentAccountText = css`
  font-size: 1.25rem;
`;

const refreshButton = css`
  width: 150px;
  font-size: 1rem;
`;

const saveButton = css`
  margin-left: 20px;
  width: 80px;
`;

const refreshContainer = css`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  padding: 0 20px;
`;

const mainButtonStyles = css`
  width: 300px;
`;

const buttonContainer = css`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 50px 0 20px 0;
`;

const errorContainer = css`
  margin-top: 8px;
  padding: 0 20px;
  margin-bottom: 4px;
  height: 12px;
`;

const checkboxStyles = css`
  svg {
    height: 1.6em;
    width: 1.6em;
  }
  margin-right: 10px;
`;
