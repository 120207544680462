import { all } from 'redux-saga/effects';

import { createOnboardingRequestSaga } from './create-onboarding-request';
import { findOnboardingRequestSaga } from './find-onboarding-request';
import { updateOnboardingRequestSaga } from './update-onboarding-request';

export const analyticsOnboardingSaga = function* () {
  yield all([
    createOnboardingRequestSaga(),
    findOnboardingRequestSaga(),
    updateOnboardingRequestSaga(),
  ]);
};
