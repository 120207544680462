import { all } from 'redux-saga/effects';
import {
  addBlockedNumberSaga,
  deleteBlockedNumberSaga,
  getBlockedNumbersSaga,
} from './call-blocking.action';

export const callBlockingSaga = function* () {
  yield all([getBlockedNumbersSaga(), deleteBlockedNumberSaga(), addBlockedNumberSaga()]);
};

export {
  getBlockedNumbers,
  deleteBlockedNumber,
  addBlockedNumber,
} from './call-blocking.action';
export { callBlockingReducer } from './call-blocking.reducer';
