import React from 'react';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';
import { CustomAxios } from '../../../../redux/axios';
import { selectCurrentLocationId } from '../../../../redux/actions/location/current-location';
import * as Paths from '../../../../constants/paths';
import { useHistory } from 'react-router-dom';
import { ExtendedAccountStatus } from '../helpers';
import { useAlert } from '@weave/alert-system';
import { WeaveTheme } from '@weave/theme-original';
import { Heading, PrimaryButton, Text, WarningBadgeIcon } from '@weave/design-system';

type ActivateButtonProps = {
  onSetup: () => void;
  accountStatus: ExtendedAccountStatus;
  isReviewsFeatureOn: boolean;
};

export const Setup = ({
  onSetup,
  accountStatus,
  isReviewsFeatureOn,
}: ActivateButtonProps) => {
  let Screen: JSX.Element | null = null;

  if (!isReviewsFeatureOn) {
    Screen = <FeatureScreen />;
  } else if (accountStatus === ExtendedAccountStatus.Archived) {
    Screen = <EnableScreen onSetup={onSetup} />;
  } else if (accountStatus === ExtendedAccountStatus.NotSetup) {
    Screen = <CreateScreen onSetup={onSetup} />;
  } else if (accountStatus === ExtendedAccountStatus.Error) {
    Screen = <ErrorScreen />;
  }

  return (
    <div
      css={css`
        max-width: 600px;
      `}
    >
      {Screen}
    </div>
  );
};

const ErrorScreen = () => (
  <ErrorMessage title="An Error Occurred!">
    An error occurred while fetching the account status for this location. This could be a
    temporary issue with our backend data service. Try waiting and then reload this page.
    If the problem persists or this message appears frequently with other locations,
    contact the Marcomm team.
  </ErrorMessage>
);

const FeatureScreen = () => {
  const locationId = useSelector(selectCurrentLocationId);
  const history = useHistory();
  const customizationUrl = Paths.injectParams(Paths.OTHER.customization, {
    id: locationId,
  });

  return (
    <>
      <ErrorMessage title="Reviews Turned Off!">
        Reviews are turned off for this location. If they actually should be on, visit the
        Customization page and toggle the "Reviews Home Icon" customization to "Show".
      </ErrorMessage>
      <PrimaryButton onClick={() => history.push(customizationUrl)}>
        Go To Customizations Page
      </PrimaryButton>
    </>
  );
};

type ButtonProps = Pick<ActivateButtonProps, 'onSetup'>;

const CreateScreen = ({ onSetup }: ButtonProps) => {
  const alerts = useAlert();
  const createReviewsAccount = async () => {
    try {
      await CustomAxios.post('/support/v1/reviews/signup', { accepted: true });
      alerts.success('Review account created!');
      onSetup();
    } catch {
      alerts.error('Failed to create review account!');
    }
  };

  return (
    <>
      <ErrorMessage title="Uh Oh! Review Account Not Created.">
        A reviews account has not been created for this location. This is unusual and is
        probably a weird state for this location to be in. Would you like to try to create
        an account now?
      </ErrorMessage>
      <PrimaryButton onClick={createReviewsAccount}>Create Reviews Account</PrimaryButton>
    </>
  );
};

const EnableScreen = ({ onSetup }: ButtonProps) => {
  const alerts = useAlert();
  const enableReviews = async () => {
    try {
      await CustomAxios.put('support/v1/reviews/accountStatus', { active: true });
      alerts.success('Reviews enabled!');
      onSetup();
    } catch {
      alerts.error('Failed to enable reviews!');
    }
  };

  return (
    <>
      <ErrorMessage title="Uh Oh! Reviews are Disabled!">
        Reviews have been disabled for this location. THIS IS NOT THE SAME AS REVIEWS
        BEING TURNED OFF (To turn reviews on or off, visit the Customizations page).
        Disabled reviews is unusual and is probably a weird state for this location to be
        in. Would you like to try to enable reviews for this location now?
      </ErrorMessage>
      <PrimaryButton onClick={enableReviews}>Enable Reviews</PrimaryButton>
    </>
  );
};

type ErrorMessageProps = {
  title: string;
  children: React.ReactNode;
};

const ErrorMessage = ({ title, children }: ErrorMessageProps) => (
  <div
    css={(theme: WeaveTheme) => css`
      background: ${theme.colors.error};
      border-radius: ${theme.borderRadius.medium};
      padding: ${theme.spacing(2, 3)};
      margin-bottom: ${theme.spacing(2)};
    `}
  >
    <Heading color="white">{title}</Heading>
    <Text
      weight="bold"
      size="large"
      css={(theme: WeaveTheme) => css`
        color: ${theme.colors.white};
        display: flex;
        align-items: center;
      `}
    >
      <WarningBadgeIcon
        css={(theme: WeaveTheme) =>
          css`
            margin-right: ${theme.spacing(2)};
          `
        }
      />
      {children}
    </Text>
  </div>
);
