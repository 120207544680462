import React from 'react';
import ReactDOM from 'react-dom';
import { copyStyles } from '../../../helpers/utils';

interface Props {
  title: string;
  sourceId: string;
  closeSyncAppWindow: (val: string) => void;
  children: any;
}

export class SyncAppPopoutComponent extends React.Component<Props> {
  externalWindow: Window | null;
  containerEl: HTMLElement | null;
  constructor(props) {
    super(props);
    this.containerEl = document.createElement('div');
    this.externalWindow = window.open('', '', 'width=1150, height=500,left=200,top=150');
  }

  componentDidMount() {
    if (this.externalWindow) {
      this.containerEl = this.externalWindow.document.createElement('div');
      this.externalWindow.document.body.appendChild(this.containerEl);

      copyStyles(document, this.externalWindow.document);

      // update the state in the parent component if the user closes the new window
      this.externalWindow.addEventListener('beforeunload', () => {
        this.props.closeSyncAppWindow(this.props.sourceId);
      });
    }
  }

  componentWillUnmount() {
    if (this.externalWindow) {
      this.externalWindow.close();
    }
  }

  render() {
    if (this.externalWindow && this.containerEl) {
      this.externalWindow.document.title = this.props.title;
      const childWithExternalWindow = React.cloneElement(this.props.children, {
        externalWindowDoc: this.externalWindow.document,
      });
      return ReactDOM.createPortal(childWithExternalWindow, this.containerEl);
    }
    return null;
  }
}
