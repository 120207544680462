import { useSelector } from 'react-redux';
import { selectCurrentLocationId } from '../../../redux/actions/location';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  disconnectStripeAccountQuery,
  DisconnectStripeAccountResponse,
  TemporalWorkflowStatus,
  WorflowStatusQuery,
  WorkflowResponse,
} from '../queries/payment-settings.query';
import { useCallback, useEffect, useMemo } from 'react';
import { useAlert } from '@weave/alert-system';
import { useMerchant } from '../use-merchant.hook';
import { getPaymentsApolloClient } from '../payment-apollo-client';
import { exponentialBackoff } from '../../../helpers/exponential-backoff';
import { useSafeState } from 'components/shared/hooks/useSafeState';

const getErrorMessage = ({ responseError, requestError }): string => {
  const hasError = responseError || requestError;
  let errMessage = '';
  if (hasError) {
    const isResponseErrorString = responseError && typeof responseError === 'string';
    errMessage = isResponseErrorString
      ? responseError
      : 'Failed to disconnect stripe account. Please try again.';
    if (requestError && requestError.message) errMessage = requestError.message;
  }
  return errMessage;
};

export const useDisconnectStripe = () => {
  const { activeProcessor } = useMerchant();
  const locationId = useSelector(selectCurrentLocationId);
  const alert = useAlert();
  const [startedBackoffTimeout, setStartedBackoffTimeout] = useSafeState(false);
  const [completed, setCompleted] = useSafeState(false);
  const paymentClient = useMemo(() => getPaymentsApolloClient(), []);

  const [
    disconnectStripeAccountMutation,
    {
      data: disconnectStripeData,
      loading: disconnectStripeLoading,
      error: disconnectStripeResponseError,
    },
  ] = useMutation<DisconnectStripeAccountResponse>(disconnectStripeAccountQuery, {
    client: paymentClient,
  });

  const [
    workflowStatusQuery,
    { data: workflowStatusData, loading: workflowStatusLoading, refetch },
  ] = useLazyQuery<WorkflowResponse>(WorflowStatusQuery, { client: paymentClient });

  const disconnectStripeError = disconnectStripeData?.disconnectProcessor.error;
  const workflowId = disconnectStripeData?.disconnectProcessor.id;
  const workflowStatus = workflowStatusData?.workflow.status;
  const loading = disconnectStripeLoading || workflowStatusLoading;
  const hasWorkflowStopped =
    workflowStatus && workflowStatus !== TemporalWorkflowStatus.Running;
  const hasWorkflowCompleted = workflowStatus === TemporalWorkflowStatus.Completed;

  const disconnectStripeAccount = () => {
    disconnectStripeAccountMutation({
      variables: {
        input: { locationId, processorId: activeProcessor?.id },
      },
    });
  };

  const disconnectStripeErrorHandler = useCallback(() => {
    const disconnectStripeErrorMessage = getErrorMessage({
      requestError: disconnectStripeError,
      responseError: disconnectStripeResponseError,
    });
    if (disconnectStripeErrorMessage) {
      alert.error(disconnectStripeErrorMessage);
    }
  }, [disconnectStripeError, disconnectStripeResponseError]);

  const fetchWorkflowStatus = useCallback(async () => {
    if (refetch) {
      await refetch({ input: { ID: workflowId } });
    } else {
      workflowStatusQuery({ variables: { input: { ID: workflowId } } });
    }
    return hasWorkflowStopped;
  }, [workflowId, hasWorkflowStopped]);

  const runWorkflowStatusBackoff = useCallback(() => {
    const [executeBackoff, stopBackoffLoop] = exponentialBackoff(fetchWorkflowStatus, {
      numOfAttempts: 20,
      beforeEachRetry: () => setStartedBackoffTimeout(true),
    });
    executeBackoff().catch(() => setStartedBackoffTimeout(false));
    return stopBackoffLoop;
  }, [fetchWorkflowStatus]);

  useEffect(() => {
    if (workflowStatus) {
      if (hasWorkflowCompleted) {
        alert.success('Stripe account disconnected successfully');
        setCompleted(true);
      } else if (hasWorkflowStopped) {
        alert.error('Disconnecting stripe failed. Please try again!');
        setStartedBackoffTimeout(false);
      }
    }
  }, [workflowStatus]);

  useEffect(() => {
    disconnectStripeErrorHandler();
  }, [disconnectStripeErrorHandler]);

  useEffect(() => {
    if (workflowId) return runWorkflowStatusBackoff();
    return () => {};
  }, [workflowId, runWorkflowStatusBackoff]);

  return {
    disconnectStripeAccount,
    loading,
    disconnecting: startedBackoffTimeout,
    completed,
  };
};
