import { createAction } from 'redux-actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { showError, showSuccess } from '@weave/alert-system';
import { CustomAxios, getErrorMessage } from '../../axios';

export const postReviewsResync = createAction('POST_REVIEWS_RESYNC');
export const postReviewsResyncSuccess = createAction('POST_REVIEWS_RESYNC_SUCCESS');
export const postReviewsResyncFailure = createAction('POST_REVIEWS_RESYNC_FAILURE');

export const reviewsResyncUrl = '/support/v1/reviews/resync';

export const handlePostReviewsResync = function* () {
  try {
    yield put(showSuccess('Resync reviews has started!'));
    yield call(CustomAxios.post, reviewsResyncUrl, {});
    yield put(postReviewsResyncSuccess());
  } catch (error: any) {
    yield put(showError(getErrorMessage(error)));
    yield put(postReviewsResyncFailure({ error }));
  }
};

export const postReviewsResyncSaga = function* () {
  yield takeLatest(postReviewsResync.toString(), handlePostReviewsResync);
};
