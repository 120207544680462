import React from 'react';
import _ from 'lodash';

import { css, cx } from 'emotion';
import { Link } from 'react-router-dom';
import * as styles from './patient-details.styles.js';
import moment from 'moment-timezone';
import * as queryString from 'query-string';

import { PatientsModel } from '../../../../models/patient.model';
import { Loader } from '../../../shared/loader/loader.component';

import { PatientInfo } from '../patient-info/patient-info.component';
import { PatientAppointmentsContainer } from '../patient-history-lists/patient-appointments/patient-appointments.container';
import { PatientEmailsContainer } from '../patient-history-lists/patient-emails-container/patient-emails.container';
import { PatientRecallContainer } from '../patient-history-lists/patient-recall-container/patient-recall.container';
import { PatientsPayload } from '../../../../redux/actions/patients/patients.action.js';
import { PersonPayload } from '../../../../redux/actions/patients/person.action.js';
import { PatientSmsHistory } from '../patient-history-lists/patient-sms-history-container/patient-sms-history/patient-sms-history';
import { PatientOrderHistory } from '../patient-history-lists/patient-order-history-container/patient-order-history/patient-order-history.component';

interface Props {
  currentLocation: string;
  patientsList: PatientsModel[];
  person: PatientsModel;
  personLoading: boolean;
  personID: string;
  location: any;
  history: any;
  eyewearReady: string;
  hasOrders: boolean;
  getLocationFeatures: (locationId: string) => void;
  getPatients: (payload: PatientsPayload) => void;
  getPerson: (payload: PersonPayload) => void;
  personReset: () => void;
}

interface State {
  isShowing: boolean;
  patientIndex: number;
  search: string;
  autocompleteFocus: boolean;
  showAppointmentHistory: boolean;
  showSmsHistory: boolean;
  showEmailHistory: boolean;
  showRecallHistory: boolean;
  showOrderHistory: boolean;
}

export class PatientDetails extends React.Component<Props, State> {
  getPatients = _.debounce(
    (payload) => {
      this.props.getPatients(payload);
    },
    250,
    { leading: true }
  );
  constructor(props) {
    super(props);
    this.state = {
      isShowing: false,
      patientIndex: -1,
      search: '',
      autocompleteFocus: false,
      showAppointmentHistory: true,
      showSmsHistory: true,
      showEmailHistory: false,
      showRecallHistory: false,
      showOrderHistory: false,
    };
  }

  UNSAFE_componentWillMount() {
    moment.tz.setDefault('America/Denver');
    const searchExists = this.props.location.search !== '';
    if (searchExists) {
      this.setPatient();
    }
  }

  componentDidUpdate(prevProps) {
    const hasCurrentLocation = this.props.currentLocation !== '';
    const isLoadingPerson = this.props.personLoading;
    const searchChanged = this.props.location.search !== prevProps.location.search;
    const searchExists = this.props.location.search !== '';
    const personExists = this.props.person.PersonID;

    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.history.push(this.props.history.location.pathname);
    }

    if (prevProps.currentLocation !== this.props.currentLocation) {
      this.setState({ patientIndex: -1 });
      this.props.personReset();
    }

    if (hasCurrentLocation && !isLoadingPerson) {
      if (searchChanged || (searchExists && !personExists)) {
        this.setPatient();
      }
    }
  }

  componentWillUnmount() {
    this.props.personReset();
  }

  handleSearchChange = (e) => {
    if (e.target.value.length > 1 && e.target.value) {
      const payload = {
        locationId: this.props.currentLocation,
        criteria: {
          search: e.target.value,
          status: 'Active',
        },
      };
      this.getPatients(payload);
    }
    this.setState({ search: e.target.value });
    this.showHideAutocomplete(e, false);
  };

  showHideAutocomplete = (e, blur) => {
    let isShowing = false;
    if (e.target.value.length > 1) {
      isShowing = true;
    }
    if (blur && !this.state.autocompleteFocus) {
      isShowing = false;
    }
    this.setState({ isShowing: isShowing });
  };

  setPatient() {
    const personId: any = queryString.parse(this.props.location.search).personid;
    const payload = { locationId: this.props.currentLocation, personId: personId };
    this.props.getPerson(payload);
  }

  resetSearch() {
    this.setState({ isShowing: false, search: '' });
  }

  handleAutocomplete(focus) {
    this.setState({ autocompleteFocus: focus });
  }

  renderPills() {
    const apptStyle = this.state.showAppointmentHistory
      ? styles.showPill
      : styles.hidePill;
    const smsStyle = this.state.showSmsHistory ? styles.showPill : styles.hidePill;
    const emailStyle = this.state.showEmailHistory ? styles.showPill : styles.hidePill;
    const recallStyle = this.state.showRecallHistory ? styles.showPill : styles.hidePill;
    const orderStyle = this.state.showOrderHistory ? styles.showPill : styles.hidePill;

    return (
      <div className={cx(styles.flexStyle, styles.flexwrap)}>
        <div
          className={apptStyle}
          onClick={() =>
            this.setState({ showAppointmentHistory: !this.state.showAppointmentHistory })
          }
        >
          {' '}
          Appointments
        </div>

        <div
          className={smsStyle}
          onClick={() => this.setState({ showSmsHistory: !this.state.showSmsHistory })}
        >
          {' '}
          SMS
        </div>

        <div
          className={emailStyle}
          onClick={() =>
            this.setState({ showEmailHistory: !this.state.showEmailHistory })
          }
        >
          {' '}
          Email
        </div>

        <div
          className={recallStyle}
          onClick={() =>
            this.setState({ showRecallHistory: !this.state.showRecallHistory })
          }
        >
          {' '}
          Recall
        </div>

        {this.props.eyewearReady === 'active' && (
          <div
            className={orderStyle}
            onClick={() =>
              this.setState({ showOrderHistory: !this.state.showOrderHistory })
            }
          >
            {' '}
            Orders
          </div>
        )}
      </div>
    );
  }

  render() {
    const dropdownClassName = this.state.isShowing
      ? styles.displayBlock
      : styles.displayNone;
    return (
      <div>
        <div className="container-fluid">
          <div className="main">
            <div className="title-group">
              <div className="page-title">Person Details</div>
              {this.props.hasOrders && <Loader />}
            </div>
            <div>
              <input
                className={styles.searchPatientName}
                type="text"
                placeholder="Patient Name or ID"
                onClick={(e) => this.showHideAutocomplete(e, false)}
                onChange={(e) => this.handleSearchChange(e)}
                onBlur={(e) => this.showHideAutocomplete(e, true)}
                value={this.state.search}
              />
              <div
                className={cx(styles.dropDownContent, dropdownClassName)}
                onMouseEnter={() => this.handleAutocomplete(true)}
                onMouseLeave={() => this.handleAutocomplete(false)}
              >
                {this.props.patientsList.map((patient, index) => (
                  <Link
                    className={cx(
                      styles.atag,
                      styles.flexStyle,
                      css`
                        text-decoration: 'none';
                      `
                    )}
                    to={{ search: '?personid=' + patient.PersonID }}
                    key={patient.PersonID}
                    onClick={() => this.resetSearch()}
                  >
                    <p className={cx(styles.patientListStyle)}>{patient.patient_id}</p>
                    <p className={cx(styles.rightStyle, styles.patientListStyle)}>
                      {patient.status}
                    </p>
                  </Link>
                ))}
              </div>
            </div>
            <br />
            <PatientInfo
              person={this.props.person}
              locationID={this.props.currentLocation}
            />
            <br />
            <br />
            <hr />
            {this.renderPills()}
            <br />
            <div className={cx(styles.flexStyle, styles.flexwrap)}>
              {this.state.showAppointmentHistory && (
                <PatientAppointmentsContainer personID={this.props.person.PersonID} />
              )}

              {this.state.showSmsHistory && (
                <PatientSmsHistory
                  personID={this.props.person.PersonID}
                  locationID={this.props.currentLocation}
                />
              )}

              {this.state.showEmailHistory && (
                <PatientEmailsContainer personID={this.props.person.PersonID} />
              )}

              {this.state.showRecallHistory && (
                <PatientRecallContainer personID={this.props.person.PersonID} />
              )}

              {this.state.showOrderHistory && this.props.eyewearReady === 'active' && (
                <PatientOrderHistory personID={this.props.person.PersonID} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
