import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import { WeaveTheme } from '@weave/theme-original';
import { DropdownField, FormRow, genUID, Text, useFormField } from '@weave/design-system';
import { CustomAxios } from '../../../redux/axios';
import {
  fetchSyncAppMetadata,
  IntegrationFieldsModel,
  selectIntegrationsMetadata,
} from '../../../redux/actions';
import { SettingsCard } from './settings-card.component';
import { SettingsFields } from './settings-fields.component';
import { AlertProps } from './sync-app-settings-page.component';

export const formRowSingleChildMargin = (theme: WeaveTheme) => css`
  margin-right: calc(${theme.spacing(2)} - 1px);
`;

type Props = {
  showAlertModal: (obj: AlertProps) => void;
};

export const CreateCloudSyncApp = ({ showAlertModal }: Props) => {
  const [creatingSyncApp, setCreatingSyncApp] = useState<boolean>(false);
  const [formKey, setFormKey] = useState<string>('integrations');
  const [formFields, setFormFields] = useState<{ [key: string]: any }>({});
  const [selectedIntegrationMetadata, setSelectedIntegrationMetadata] = useState<
    IntegrationFieldsModel[]
  >([]);

  const integrationsMetadata = useSelector(selectIntegrationsMetadata);
  const integrations = Object.keys(integrationsMetadata);
  const integrationProps = useFormField({ type: 'dropdown', required: true });
  const dispatch = useDispatch();

  useEffect(() => {
    if (!integrations.length) {
      dispatch(fetchSyncAppMetadata());
    }
  }, [integrations.length]);

  useEffect(() => {
    setFormFields({});
    const integration = integrationProps.value;
    if (integration) {
      const fields = integrationsMetadata[integration].reduce(
        (obj, fieldMeta) => ({
          ...obj,
          [`${fieldMeta.name}`]: {
            type: fieldMeta.valueType !== 'PASSWORD' ? 'text' : 'password',
            required: true,
            ...(fieldMeta.valueType === 'PASSWORD' && { minChars: 2 }),
          },
        }),
        {}
      );
      setFormFields(fields);
      setSelectedIntegrationMetadata(integrationsMetadata[integration]);
      setFormKey(`${integrationProps.value}-${genUID()}`);
    }
  }, [integrationProps.value]);

  const handleSubmit = async (values: { [key: string]: any }) => {
    try {
      setCreatingSyncApp(true);
      const submitPayload = { integration: integrationProps.value, data: values };
      await CustomAxios.post('/support/v1/syncapp/provision/', submitPayload);
      showAlertModal({
        title: 'Success!',
        description:
          'The cloud sync app has been successfully created. Please allow a few hours for all the data to sync to the Weave client.',
      });
      setFormKey(`${integrationProps.value}-${genUID()}`);
    } catch (error: any) {
      console.error(error);
      showAlertModal({
        title: 'Error',
        description:
          error.response?.data?.error ?? 'Something went wrong. Try again later.',
      });
    } finally {
      setCreatingSyncApp(false);
    }
  };

  return (
    <SettingsCard title="Create Cloud Sync App">
      <>
        <Text
          color="light"
          css={(theme: WeaveTheme) => css`
            margin: ${theme.spacing(0, 0, 3)};
          `}
        >
          Create a new cloud sync app integration below
        </Text>

        <FormRow
          cols={[50]}
          css={css`
            width: 800px;
          `}
        >
          <DropdownField
            {...integrationProps}
            name="integration"
            label="Integration"
            css={formRowSingleChildMargin}
          >
            {integrations.sort().map((integration, index) => (
              <DropdownField.Option value={integration} key={index}>
                {integration}
              </DropdownField.Option>
            ))}
          </DropdownField>
        </FormRow>

        <hr
          css={(theme: WeaveTheme) => css`
            width: 100%;
            border-top: 1px solid ${theme.colors.gray[300]};
            padding-bottom: ${theme.spacing(3)};
            margin: 0;
          `}
        />

        <SettingsFields
          fields={formFields}
          fieldsMetadata={selectedIntegrationMetadata}
          formComplete={!creatingSyncApp && !!integrationProps.value}
          onClick={handleSubmit}
          key={formKey}
        />
      </>
    </SettingsCard>
  );
};
