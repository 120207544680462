import React from 'react';
import * as _ from 'lodash';
import { cx } from 'emotion';

import { UserModel } from '../../../../../models/user.model';
import { MsgDirectionType, SmsModel } from '../../../../../models/sms-history.model';
import * as styles from './../sms-details.styles.js';

import { SmsUserMessage } from './sms-user-message.component';
import { SmsPatientMessage } from './sms-patient-message.component';
import { SmsWeaveMessageContainer } from './sms-weave-message.container';

interface Props {
  locationID: string;
  smsDetails: SmsModel;
  users: UserModel[];
  getUsers: (limit: number) => void;
}

interface State {
  user: UserModel;
}

export class SmsNotificationSettings extends React.Component<Props, State> {
  UNSAFE_componentWillMount() {
    this.props.getUsers(25);
  }

  render() {
    const { users, smsDetails } = this.props;
    let username = 'No Data';
    let sentBy = 'Patient';
    const isOutbound = smsDetails.direction === MsgDirectionType.Outbound;
    const user = users.find((item) => {
      return item.UserID === smsDetails.createdBy;
    });
    if (isOutbound) {
      username = user ? user.Username : 'No Data';
      sentBy = smsDetails.autogeneratedBy ? 'Weave' : username;
    } else {
      username = 'Patient ' + smsDetails.personId;
    }
    return (
      <div className={styles.notificationSettingBox}>
        <p className={cx(styles.header, styles.uuid)}>Sent by {sentBy}</p>
        <hr />
        {sentBy !== 'Weave' && isOutbound && (
          <SmsUserMessage
            firstName={_.get(user, 'FirstName', 'No Data')}
            lastName={_.get(user, 'LastName', 'No Data')}
          />
        )}

        {sentBy !== 'Weave' && !isOutbound && smsDetails.apt_calculated_status && (
          <SmsPatientMessage smsDetails={smsDetails} />
        )}

        {sentBy === 'Weave' && (
          <SmsWeaveMessageContainer uuid={smsDetails.autogeneratedBy} />
        )}
      </div>
    );
  }
}
