import React, { useEffect, useState } from 'react';
import { Switch } from 'react-router';

export const CrawlSwitch = ({ children, ...rest }) => {
  const [paths, setPaths] = useState<string>('');
  useEffect(() => {
    const paths = children.map((item) => item?.props?.path).filter((item) => !!item);
    setPaths(paths.toString());
  }, [children.length]);

  return (
    <>
      <meta itemProp="routes-list" content={paths} />
      <Switch {...rest}>{children}</Switch>
    </>
  );
};
