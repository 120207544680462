import {
  ButtonBar,
  ContentLoader,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Text,
} from '@weave/design-system';
import React from 'react';
import { theme } from '@weave/theme-original';
import { css } from '@emotion/core';
interface DestructiveConfirmationModalProps {
  confirmLabel?: string;
  cancelLabel?: string;
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel?: () => void;
  loading?: boolean;
  loadingLabel?: string;
  show: boolean;
  onClose: () => void;
}

export const DestructiveConfirmationModal = ({
  onConfirm,
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  title,
  message,
  onCancel,
  onClose,
  show,
  loading = false,
  loadingLabel = 'Loading...',
  ...props
}: DestructiveConfirmationModalProps) => {
  const handleCancelDestruction = () => {
    if (onCancel) onCancel();
    onClose();
  };
  return (
    <Modal
      show={show}
      onClose={onClose}
      maxWidth={382}
      css={css`
        border-radius: ${theme.borderRadius.small};
      `}
      {...props}
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body
        css={css`
          overflow: visible;
        `}
      >
        <Text
          textAlign="center"
          css={css`
            margin-bottom: 1.5rem;
          `}
        >
          {message}
        </Text>
        <ButtonBar
          css={css`
            padding: ${theme.spacing(0, 1.5)};
          `}
        >
          <SecondaryButton onClick={handleCancelDestruction}>
            {cancelLabel}
          </SecondaryButton>
          <PrimaryButton destructive onClick={onConfirm}>
            {confirmLabel}
          </PrimaryButton>
        </ButtonBar>
      </Modal.Body>
      <ContentLoader show={loading} message={loadingLabel} backgroundOpacity={0} />
    </Modal>
  );
};
