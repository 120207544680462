import { handleActions } from 'redux-actions';
import {
  fetchDeliverySummary,
  fetchDeliverySummaryFailure,
  fetchDeliverySummarySuccess,
  fetchQueuedTime,
  fetchQueuedTimeSuccess,
  fetchQueuedTimeFailure,
} from './auto-msg-delivery-reports.action';

import {
  clearAutoMsgQueue,
  clearAutoMsgQueueFailure,
  clearAutoMsgQueueSuccess,
} from './clear-auto-msg-queue.action';
import {
  reloadAutoMsgQueue,
  reloadAutoMsgQueueFailure,
  reloadAutoMsgQueueSuccess,
} from './reload-auto-msg-queue.action';

import { QueuedAt } from './auto-msg.types';
import { NotificationReportDetails } from '../notifications-settings/notification-settings.types';
export type AutoMsgQueueState = {
  data?: any;
  deliverySummary: NotificationReportDetails[];
  loading: boolean;
  queueTimes: QueuedAt[];
};
export const initialAutoMsgQueueState: AutoMsgQueueState = {
  deliverySummary: [],
  queueTimes: [],
  loading: false,
};

const reducer = handleActions<AutoMsgQueueState, any>(
  {
    [clearAutoMsgQueue.toString()]: (state) => ({ ...state, loading: true }),
    [clearAutoMsgQueueSuccess.toString()]: (state, action) => ({
      ...state,
      data: action.payload,
      loading: false,
    }),
    [clearAutoMsgQueueFailure.toString()]: (state, action) => ({
      ...state,
      error: action.payload,
      loading: false,
    }),

    [reloadAutoMsgQueue.toString()]: (state) => ({ ...state, loading: true }),
    [reloadAutoMsgQueueSuccess.toString()]: (state, action) => ({
      ...state,
      data: action.payload,
      loading: false,
    }),
    [reloadAutoMsgQueueFailure.toString()]: (state, action) => ({
      ...state,
      error: action.payload,
      loading: false,
    }),

    [fetchDeliverySummary.toString()]: (state) => ({ ...state, loading: true }),
    [fetchDeliverySummarySuccess.toString()]: (state, action) => ({
      ...state,
      deliverySummary: action.payload,
      loading: false,
    }),
    [fetchDeliverySummaryFailure.toString()]: (state, action) => ({
      ...state,
      error: action.payload,
      loading: false,
    }),
    [fetchQueuedTime.toString()]: (state) => ({ ...state, loading: true }),
    [fetchQueuedTimeSuccess.toString()]: (state, action) => ({
      ...state,
      queueTimes: action.payload,
      loading: false,
    }),
    [fetchQueuedTimeFailure.toString()]: (state, action) => ({
      ...state,
      error: action.payload,
      loading: false,
    }),
  },
  initialAutoMsgQueueState
);

export default reducer;
