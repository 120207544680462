import { connect } from 'react-redux';

import { PatientAppointments } from './patient-appointments.component';
import {
  appointmentsByPatient,
  appointmentsByPatientReset,
} from '../../../../../redux/actions/appointments/appointments-by-patient.action';
import {
  selectCurrentLocationId,
  selectCurrentLocationTimeZone,
} from '../../../../../redux/actions/location/current-location';
import { Store } from '../../../../../redux/store/store.model';

interface OwnProps {
  personID: string;
}

export const PatientAppointmentsContainer = connect(
  (store: Store, ownProps: OwnProps) => ({
    currentLocation: selectCurrentLocationId(store),
    timezone: selectCurrentLocationTimeZone(store),
    appointments: store.appointmentsByPatient.data,
    personID: ownProps.personID,
  }),
  {
    appointmentsByPatient,
    appointmentsByPatientReset,
  }
)(PatientAppointments);
