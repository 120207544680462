import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './root-saga';
import { history } from './history';
import { rootReducerFactor } from './root-reducer';
import { Store } from './store.model';

export const configureStore = (defaultStore: Partial<Store> = {}) => {
  const sagaMiddleware = createSagaMiddleware();

  const middleware = [sagaMiddleware];

  let composeEnhancers = compose;
  if (process.env.NODE_ENV === `development`) {
    // uncomment these lines if you want redux actions logged in the console
    // const { logger } = require(`redux-logger`);
    // middleware.push(logger);

    composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }

  const configuredStore = createStore(
    // had to install history manually to be able to push from the action creator
    rootReducerFactor(history),
    defaultStore,
    composeEnhancers(applyMiddleware(...middleware))
  );

  sagaMiddleware.run(rootSaga);

  return configuredStore;
};

export const store = configureStore();
