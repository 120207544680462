import { css } from '@emotion/core';
import { useAlert } from '@weave/alert-system';
import {
  ContentLoader,
  Heading,
  IconButton,
  Modal,
  PlusIcon,
  Text,
  useModalControl,
} from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { noop } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentLocationId } from '../../../../redux/actions/location';
import { billingApi } from '../billing.api';
import { selectHasBillingWritePermission } from '../billing.helpers';
import { BillingAccount, PaymentMethod } from '../billing.types';
import { Card } from '../card';
import { PlaceholderSkeleton } from '../shared/skeleton/placeholder-skeleton.component';
import { TwoCpBillingForm } from './2cp-billing-form';
import { AccountDetails } from './account-details';
import { EditContactModal } from './edit-contact-modal';
import { PaymentMethodCard } from './payment-method-card';

enum LoadingKeys {
  billingAccount = 'billingAccount',
  paymentMethods = 'paymentMethods',
  paymentMethodAction = 'paymentMethodAction',
}

export const SubscriptionCard = () => {
  const [accountData, setAccountData] = useState<BillingAccount>();
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [loading, setLoading] = useState<string[]>([]);
  const alert = useAlert();
  const locationId = useSelector(selectCurrentLocationId);
  const userHasBillingWritePermission = useSelector(selectHasBillingWritePermission);

  const handleGetAccountDetails = async () => {
    const key = LoadingKeys.billingAccount;
    try {
      setLoading((prevLoading) => Array.from(new Set([...prevLoading, key])));
      const data = await billingApi.getAccountDetails();
      setAccountData(data);
    } catch (error: any) {
      console.error(error);
      alert.error('Error retrieving account details.');
    } finally {
      setLoading((prevLoading) => prevLoading.filter((curr) => curr !== key));
    }
  };

  const handleGetPaymentMethodsList = async () => {
    const key = LoadingKeys.paymentMethods;
    try {
      setLoading((prevLoading) => Array.from(new Set([...prevLoading, key])));
      const data = await billingApi.getPaymentMethodsList();
      setPaymentMethods(data);
    } catch (error: any) {
      console.error(error);
      alert.error('Error retrieving payment method data.');
    } finally {
      setLoading((prevLoading) => prevLoading.filter((curr) => curr !== key));
    }
  };

  useEffect(() => {
    if (locationId) {
      setAccountData(undefined);
      setPaymentMethods([]);

      handleGetAccountDetails();
      handleGetPaymentMethodsList();
    }
  }, [locationId]);

  const { modalProps: editContactModalProps, triggerProps: _editContactTriggerProps } =
    useModalControl();

  const { modalProps: addPayMethodModalProps, triggerProps: aAddPayMethodTriggerProps } =
    useModalControl();

  const handleDeletePaymentMethod = async (paymentMethod: PaymentMethod) => {
    if (paymentMethods.length < 2) {
      alert.warning('At least one payment method must exist.');
      return;
    } else if (paymentMethod.isDefault) {
      alert.warning('Can not delete default payment method.');
      return;
    }

    const key = LoadingKeys.paymentMethodAction;
    try {
      setLoading((prevLoading) => Array.from(new Set([...prevLoading, key])));
      await billingApi.deletePaymentMethod(paymentMethod.id);

      const updatedPaymentMethods = paymentMethods.filter(
        (method) => method.id !== paymentMethod.id
      );
      setPaymentMethods(updatedPaymentMethods);
    } catch (error: any) {
      console.error(error);
      alert.error('Error deleting payment method.');
    } finally {
      setLoading((prevLoading) => prevLoading.filter((curr) => curr !== key));
    }
  };

  const handleMakeDefault = async (paymentMethod: PaymentMethod) => {
    // Only allow setting as default if it is currently not the default.
    if (!paymentMethod.isDefault) {
      const key = LoadingKeys.paymentMethodAction;
      try {
        setLoading((prevLoading) => Array.from(new Set([...prevLoading, key])));
        await billingApi.updateDefaultPaymentMethod(paymentMethod.id);

        const updatedPaymentMethods = paymentMethods.map((method) => {
          if (paymentMethod.id === method.id) {
            return { ...method, isDefault: true };
          }
          return { ...method, isDefault: false };
        });
        setPaymentMethods(updatedPaymentMethods);
      } catch (error: any) {
        console.error(error);
        alert.error('Error setting payment method as default.');
      } finally {
        setLoading((prevLoading) => prevLoading.filter((curr) => curr !== key));
      }
    }
  };

  const handleUpdateBillingAccountContact = async (accountInfo: { email: string }) => {
    const key = 'billingAccount';
    try {
      if (!accountData) {
        console.error(
          'account data not populated - it should be populated before coming to this line. ID: 54V4CA1'
        );
        alert.error(
          'Error Updating Billing Account Information. Please contact support with the ID: 54V4CA1'
        );
        return;
      }

      setLoading((prevLoading) => Array.from(new Set([...prevLoading, key])));
      await billingApi.updateBillingAccountContact(accountInfo);

      const newAccountData = {
        ...accountData,
        primaryContact: {
          ...accountData?.primaryContact,
        },
      };
      newAccountData.primaryContact.email = accountInfo.email;

      setAccountData(newAccountData);

      editContactModalProps.onClose();
    } catch (error: any) {
      console.error(error);
      alert.error('Error updating billing account contact information.');
    } finally {
      setLoading((prevLoading) => prevLoading.filter((curr) => curr !== key));
    }
  };

  const handleCreatePaymentMethod = async (token: string, type: string) => {
    const key = LoadingKeys.paymentMethods;
    setLoading((prevLoading) => Array.from(new Set([...prevLoading, key])));
    try {
      await billingApi.createPaymentMethod(token, type);
      await handleGetPaymentMethodsList();
    } catch (error: any) {
      console.error(error);
      alert.error('Error saving payment method.');
      handleGetPaymentMethodsList();
    } finally {
      setLoading((prevLoading) => prevLoading.filter((curr) => curr !== key));
      addPayMethodModalProps.onClose();
    }
  };

  return (
    <Card as="section" css={cardStyle}>
      <ContentLoader show={loading.includes('billingAccount')} />
      <header css={headStyle}>
        <Heading level={2}>Subscription Information</Heading>
        {userHasBillingWritePermission && (
          <div
            css={css`
              display: flex;
            `}
          >
            <IconButton
              label="Add Payment Method"
              showLabelOnHover
              disabled={loading.includes(LoadingKeys.billingAccount)}
              {...aAddPayMethodTriggerProps}
            >
              <PlusIcon />
            </IconButton>
            {/*
              Commenting out until we get the endpoint to update account info fixed.
            */}
            {/* <IconButton
              label="Edit subscription info"
              showLabelOnHover
              {...editContactTriggerProps}
            >
              <EditIcon />
            </IconButton> */}
          </div>
        )}
      </header>

      <div css={sectionContentStyles}>
        <PlaceholderSkeleton
          loading={loading.includes(LoadingKeys.billingAccount)}
          as="span"
          height={'14rem'}
          margin={'0 0 24px 0'}
        >
          {accountData ? (
            <>
              <AccountDetails account={accountData} />
              <Modal {...editContactModalProps} maxWidth={544}>
                <EditContactModal
                  onCancel={editContactModalProps.onClose}
                  onSave={handleUpdateBillingAccountContact}
                  initialAccountData={accountData?.primaryContact}
                />
              </Modal>
            </>
          ) : (
            <Text
              weight="bold"
              css={css`
                color: ${theme.colors.warning};
                width: 65%;
              `}
            >
              Account Data Not Found
            </Text>
          )}
        </PlaceholderSkeleton>

        <PlaceholderSkeleton
          loading={loading.includes(LoadingKeys.paymentMethods)}
          as="span"
          height={'14rem'}
          margin={'0 0 24px 0'}
        >
          {paymentMethods.length > 0 ? (
            <div css={paymentMethodSection}>
              {paymentMethods.map((paymentMethod) => (
                <PaymentMethodCard
                  key={paymentMethod.id}
                  paymentMethod={paymentMethod}
                  onMakeDefault={handleMakeDefault}
                  allowDelete={paymentMethods.length > 1}
                  onDelete={handleDeletePaymentMethod}
                  loading={loading.includes(LoadingKeys.paymentMethodAction)}
                  userHasBillingWritePermission={userHasBillingWritePermission}
                />
              ))}
            </div>
          ) : (
            <Text
              size="large"
              weight="bold"
              css={css`
                width: 65%;
                color: ${theme.colors.warning};
                margin-top: ${theme.spacing(3)};
              `}
            >
              No Payment Method Information
            </Text>
          )}
        </PlaceholderSkeleton>
      </div>

      <Modal {...addPayMethodModalProps} onClose={noop}>
        <TwoCpBillingForm
          onClose={addPayMethodModalProps.onClose}
          onCreatePaymentMethod={handleCreatePaymentMethod}
          loading={loading.includes(LoadingKeys.paymentMethods)}
        />
      </Modal>
    </Card>
  );
};

const cardStyle = css`
  padding-bottom: ${theme.spacing(5)};

  & + & {
    margin-top: ${theme.spacing(3)};
  }
`;

const headStyle = css`
  border-bottom: 1px solid ${theme.colors.gray300};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 100%;
  margin-bottom: ${theme.spacing(2)};
  padding-bottom: ${theme.spacing(1)};

  h2 {
    margin: 0;
  }
`;

const sectionContentStyles = css`
  display: flex;
  flex-wrap: wrap;
`;

const paymentMethodSection = css`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
`;
