import React, { useCallback, useEffect, useRef, useState } from 'react';
import Fuse from 'fuse.js';
import { parseFullName } from 'parse-full-name';
import { css } from '@emotion/core';
import {
  DropdownField,
  FormRow,
  Heading,
  Info,
  Modal,
  ModalControlModalProps,
  TextField,
  TextareaField,
  useForm,
  useThemeValues,
  RadioField,
  IconButton,
  TrashIcon,
  BackIcon,
  XIcon,
  PrimaryButton,
} from '@weave/design-system';

import { schedulingQuery } from '../../../../apis/protos/scheduling/scheduling.proto-api';
import { Provider } from './providers.types';
import { providerApi, providersApi } from './providers-manager.component';
import { CustomAxios } from 'redux/axios';
import UserListModel from 'components/users/UserListModel';
import { DefaultProviderAvatar } from './default-provider-avatar.component';
import { avatarStyle } from './providers-table.component';

const useProviderAssets = schedulingQuery('/schedule/v1/assets'); // missing a v2 equivalent?

const modalStyles = (theme) => css`
  padding-left: ${theme.spacing(3)};
  padding-right: ${theme.spacing(3)};
  min-width: 750px;
  max-width: 100vw;
`;

const modalBodyStyles = (theme, assetIsSelected: boolean) => css`
  padding: ${theme.spacing(1)};
  overflow: ${assetIsSelected ? 'auto' : 'visible'};
`;

const dropdownFieldRowStyles = (theme) => css`
  padding-top: ${theme.spacing(1)};
`;

const dropdownFieldInfoStyles = (theme) => css`
  margin-top: ${theme.spacing(1)};
`;

const textAreadFieldInfoStyles = (theme) => css`
  margin-top: ${theme.spacing(3)};
`;

const textFieldStyles = (theme) => css`
  margin-top: ${theme.spacing(2)};
`;

const textAreaFieldStyles = (theme) => css`
  margin-top: ${theme.spacing(2)};
`;

const radioFieldStyle = (theme) => css`
  margin-top: ${theme.spacing(2)};
  div > label {
    margin-bottom: 0;
  }
`;

const sectionHeaderStyles = (theme) =>
  css`
    margin-top: ${theme.spacing(4)};
  `;

const avatarContainerStyles = (theme) => css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto auto ${theme.spacing(1)} auto;
  width: 240px;
  transition: all 0.5s ease;
  border-radius: 100%;
  img {
    border: ${theme.colors.weaveBlue} solid 3px;
  }
  &:focus {
    outline: none;
  }
`;

const imageControlsStyles = (theme) => css`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: ${theme.spacing(2, 0)};
  width: 100%;
`;

const imageInputStyles = css`
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
`;

const imageInputButtonStyles = (theme) => css`
  margin-left: ${theme.spacing(1)};
`;

interface ProviderFormProps {
  modalProps: ModalControlModalProps;
  refetchProviders: any; // missing type from the proto-gen
  selectedProvider: Provider | null;
  clearSelectedProvider: () => void;
  providers: Provider[];
}

export const ProviderForm = ({
  modalProps,
  refetchProviders,
  selectedProvider,
  clearSelectedProvider,
  providers,
}: ProviderFormProps) => {
  const theme = useThemeValues();
  const [availableUsers, setAvailableUsers] = useState<UserListModel[]>([]);
  const [filteredAvailableUsers, setFilteredAvailableUsers] = useState<
    Fuse.FuseResult<UserListModel>[]
  >([]);
  const [imageIcon, setImageIcon] = useState<string>('');
  const [readyToDeleteImage, setReadyToDeleteImage] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const { getFieldProps, seedValues, values } = useForm({
    fields: {
      firstName: { type: 'text', required: true },
      lastName: { type: 'text', required: true },
      displayName: { type: 'text' },
      displayDescription: { type: 'text' },
      selectedProviderAsset: { type: 'dropdown' },
      selectedUser: { type: 'radio', required: true, maxAllowed: 1 },
      imageURL: { type: 'text' },
    },
  });
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { data: importedProviderAssetsData } = useProviderAssets();
  const providersMethods = providersApi();
  const providerMethods = providerApi({ id: selectedProvider?.id ?? '' });

  const setInitialFormValues = useCallback(() => {
    if (selectedProvider !== null) {
      seedValues({
        firstName: selectedProvider?.firstName || '',
        lastName: selectedProvider?.lastName || '',
        displayName: selectedProvider?.publicDisplayName || '',
        displayDescription: selectedProvider?.publicDisplayDescription || '',
        selectedProviderAsset: '',
        selectedUser: selectedProvider?.personId || 'new',
        imageURL: selectedProvider?.publicDisplayImage || '',
      });
      if (selectedProvider?.publicDisplayImage) {
        if (imageIcon !== 'trash') {
          setImageIcon('trash');
        }
        if (readyToDeleteImage) {
          setReadyToDeleteImage(false);
        }
      }
    } else {
      seedValues({
        firstName: '',
        lastName: '',
        displayName: '',
        displayDescription: '',
        selectedProviderAsset: '',
        selectedUser: 'new',
        imageURL: '',
      });
    }
  }, [selectedProvider]);

  const fetchAvailableUsers = useCallback(async () => {
    let users: UserListModel[] = [];
    await CustomAxios.get('/support/v1/users')
      .then((res) => {
        if (res?.data?.data?.length > 0) {
          users = res.data.data;
        }
      })
      .then((res) => {
        if (providers.length > 0) {
          providers.forEach((provider) => {
            users = [...users.filter((user) => user.UserID !== provider.personId)];
          });
        }
      })
      .catch((err) => {
        console.error(err);
        users = [];
      })
      .finally(() => {
        setAvailableUsers(users);
      });
  }, [values.firstName, values.lastName]);

  const filterAvailableUsers = useCallback(() => {
    const fuse = new Fuse(availableUsers, {
      keys: ['FirstName', 'LastName'],
    });
    const filteredResults = fuse.search(`${values.firstName} ${values.lastName}`);
    if (JSON.stringify(filteredAvailableUsers) !== JSON.stringify(filteredResults)) {
      setFilteredAvailableUsers(filteredResults);
    }
  }, [values.firstName, values.lastName, JSON.stringify(availableUsers)]);

  const setImageURL = useCallback(
    async (newImageURL: string) => {
      const newValues = { ...values };
      newValues.imageURL = newImageURL;
      await seedValues({ ...newValues });
    },
    [JSON.stringify(values)]
  );

  const setAsExistingImage = useCallback(() => {
    setImageURL(selectedProvider?.publicDisplayImage || '');
    if (imageIcon !== 'trash') {
      setImageIcon('trash');
    }
    if (readyToDeleteImage) {
      setReadyToDeleteImage(false);
    }
  }, [selectedProvider?.publicDisplayImage, imageIcon, readyToDeleteImage]);

  const setToDeleteExistingImage = useCallback(() => {
    setImageURL('');
    if (imageIcon !== 'goBack') {
      setImageIcon('goBack');
    }
    if (!readyToDeleteImage) {
      setReadyToDeleteImage(true);
    }
  }, [imageIcon, readyToDeleteImage]);

  const setToDefaultAvatar = useCallback(
    (shouldDelete: boolean) => {
      setImageURL('');
      if (imageIcon !== '') {
        setImageIcon('');
      }
      if (readyToDeleteImage !== shouldDelete) {
        setReadyToDeleteImage(shouldDelete);
      }
    },
    [imageIcon, readyToDeleteImage]
  );

  const removeImage = useCallback(
    (e) => {
      e.preventDefault();
      if (!selectedProvider?.publicDisplayImage) {
        setToDefaultAvatar(false);
      } else {
        if (selectedProvider.publicDisplayImage === values.imageURL) {
          setToDeleteExistingImage();
        } else {
          setAsExistingImage();
        }
      }
    },
    [selectedProvider?.publicDisplayImage]
  );

  const setImageFromUpload = useCallback(
    async (newImage: File | '') => {
      if (newImage === '') {
        if (selectedProvider?.publicDisplayImage) {
          setAsExistingImage();
        } else {
          setToDefaultAvatar(values.imageURL !== '');
        }
      } else {
        const imageObjURL = await URL.createObjectURL(newImage as Blob);
        if (imageObjURL !== '') {
          seedValues({
            ...values,
            imageURL: imageObjURL,
          });
        }
        if (imageIcon !== 'cancel') {
          setImageIcon('cancel');
        }
        if (readyToDeleteImage) {
          setReadyToDeleteImage(false);
        }
      }
    },
    [JSON.stringify(values), imageIcon, readyToDeleteImage]
  );

  const primaryActionComplete = async () => {
    await refetchProviders();
    await clearSelectedProvider();
    await setInitialFormValues();
    await modalProps.onClose();
  };

  const createProvider = async () => {
    await providersMethods
      .post({
        firstName: values.firstName,
        lastName: values.lastName,
        personId:
          values.selectedUser === 'new' || values.selectedUser === ''
            ? undefined
            : values.selectedUser,
        // @ts-ignore - until proto-gen types are corrected
        publicDisplayImage: values.imageURL,
        publicDisplayDescription: values.displayDescription,
        publicDisplayName: values.displayName,
      })
      .then(() => primaryActionComplete())
      .catch((err) => console.error(err));
  };

  const editProvider = async () => {
    await providerMethods
      .put({
        firstName: values.firstName,
        lastName: values.lastName,
        personId:
          values.selectedUser === 'new' || values.selectedUser === ''
            ? undefined
            : values.selectedUser,
        // @ts-ignore - until proto-gen types are corrected
        publicDisplayImage: values.imageURL,
        publicDisplayDescription: values.displayDescription,
        publicDisplayName: values.displayName,
      })
      .then(() => primaryActionComplete())
      .catch((err) => console.error(err));
  };

  const handleSave = () => {
    selectedProvider === null ? createProvider() : editProvider();
  };

  useEffect(() => {
    setInitialFormValues();
  }, [selectedProvider, modalProps.show]);

  useEffect(() => {
    fetchAvailableUsers();
  }, [JSON.stringify(providers)]);

  useEffect(() => {
    if (values.selectedProviderAsset !== '') {
      const parseSelectedProviderAsset = parseFullName(
        values.selectedProviderAsset,
        'all',
        0,
        0,
        0
      );
      seedValues({
        ...values,
        firstName: parseSelectedProviderAsset.first,
        lastName: parseSelectedProviderAsset.last,
        displayName: values.selectedProviderAsset,
      });
    }
  }, [values.selectedProviderAsset]);

  useEffect(() => {
    filterAvailableUsers();
  }, [values.firstName, values.lastName]);

  useEffect(() => {
    if (values.firstName !== '' && values.lastName !== '') {
      if (selectedProvider === null) {
        setSaveDisabled(false);
      } else {
        if (
          values.firstName !== selectedProvider.firstName ||
          values.lastName !== selectedProvider.lastName ||
          values.displayDescription !== selectedProvider.publicDisplayDescription ||
          values.displayName !== selectedProvider.publicDisplayName ||
          values.imageURL !== selectedProvider.publicDisplayImage ||
          values.selectedUser !== selectedProvider.personId
        ) {
          setSaveDisabled(false);
        } else if (!saveDisabled) {
          setSaveDisabled(true);
        }
      }
    } else if (!saveDisabled) {
      setSaveDisabled(true);
    }
  }, [JSON.stringify(values), JSON.stringify(selectedProvider)]);

  return (
    <Modal {...modalProps} css={modalStyles(theme)}>
      <Modal.Header>
        <Heading textAlign="center">
          {selectedProvider ? 'Edit' : 'Create a'} Provider
        </Heading>
      </Modal.Header>
      <Modal.Body
        css={modalBodyStyles(
          theme,
          values?.selectedProviderAsset !== '' || selectedProvider !== null
        )}
      >
        {!selectedProvider &&
          importedProviderAssetsData?.data?.ProviderAssets &&
          importedProviderAssetsData?.data?.ProviderAssets?.length > 0 && (
            <>
              <Heading level={2}>Import Provider from PMS</Heading>
              <FormRow cols={[97, 3]} css={dropdownFieldRowStyles(theme)}>
                <DropdownField
                  label="Available Imported Providers"
                  {...getFieldProps('selectedProviderAsset')}
                >
                  <DropdownField.Option value="new" searchValue="new provider">
                    <span
                      css={css`
                        font-weight: ${theme.font.weight.semibold};
                      `}
                    >
                      Create a New Provider
                    </span>
                  </DropdownField.Option>
                  <>
                    {importedProviderAssetsData?.data?.ProviderAssets.filter(
                      (asset) => asset !== ''
                    )?.map((asset, i) => (
                      <DropdownField.Option
                        key={`${asset}-#${i}`}
                        value={asset}
                        searchValue={asset}
                      >
                        {asset}
                      </DropdownField.Option>
                    ))}
                  </>
                </DropdownField>
                <Info css={dropdownFieldInfoStyles(theme)}>
                  Providers imported from a PMS that have not had a Weave provider profile
                  created.
                </Info>
              </FormRow>
            </>
          )}

        {(selectedProvider ||
          (!selectedProvider && values.selectedProviderAsset !== '')) && (
          <>
            <Heading level={2} css={sectionHeaderStyles(theme)}>
              Provider Info
            </Heading>
            <FormRow cols={[95, 5]}>
              <TextField
                {...getFieldProps('firstName')}
                label="First Name"
                required
                css={textFieldStyles(theme)}
              />
            </FormRow>

            <FormRow cols={[95, 5]}>
              <TextField
                {...getFieldProps('lastName')}
                label="Last Name"
                required
                css={textFieldStyles(theme)}
              />
            </FormRow>

            <FormRow
              // correction for optical illusion making right side look misaligned with other fields
              cols={[97.25, 2.75]}
              // correction for spacing inconsistancy between last name and display name
              css={css`
                margin-top: ${theme.spacing(-2)};
              `}
            >
              <TextField
                {...getFieldProps('displayName')}
                label="Display Name"
                required
                css={textAreaFieldStyles(theme)}
              />
              <Info css={textAreadFieldInfoStyles(theme)}>
                If no display name is provided, display name will default to first and
                last name.
              </Info>
            </FormRow>

            <FormRow cols={[95, 5]}>
              <TextareaField
                {...getFieldProps('displayDescription')}
                label="Display Description"
                autoGrow={[3, 8]}
              />
            </FormRow>

            <Heading level={2} css={sectionHeaderStyles(theme)}>
              Link Provider to Weave User Profile
            </Heading>
            <FormRow cols={[95, 5]}>
              <RadioField
                {...getFieldProps('selectedUser')}
                label="Connect to existing Weave User"
                css={radioFieldStyle(theme)}
                labelPlacement="right"
              >
                <>
                  {filteredAvailableUsers?.slice(0, 4).map((user) => (
                    <RadioField.Radio key={user.item.UserID} value={user.item.UserID}>
                      {user.item.FirstName} {user.item.LastName}
                    </RadioField.Radio>
                  ))}
                </>
                <RadioField.Radio value="new">
                  <span
                    css={css`
                      font-weight: ${theme.font.weight.semibold};
                    `}
                  >
                    Create a New User
                  </span>
                </RadioField.Radio>
              </RadioField>
            </FormRow>

            <Heading level={2} css={sectionHeaderStyles(theme)}>
              Provider Image
            </Heading>
            <div css={avatarContainerStyles(theme)}>
              {!values.imageURL ? (
                <DefaultProviderAvatar
                  firstName={values.firstName || selectedProvider?.firstName || '!'}
                  lastName={values.lastName || selectedProvider?.lastName || '?'}
                  size={20}
                />
              ) : (
                <img
                  alt={`${values.firstName} ${values.lastName} avatar`}
                  title={`${values.firstName} ${values.lastName} avatar`}
                  css={avatarStyle(35, theme)}
                  src={values.imageURL}
                />
              )}
              <div css={imageControlsStyles(theme)}>
                <PrimaryButton
                  css={imageInputButtonStyles(theme)}
                  onClick={(e) => {
                    e.preventDefault();
                    inputFileRef?.current?.click();
                  }}
                >
                  Upload Image
                </PrimaryButton>

                <input
                  accept="image/*"
                  className="image-file"
                  css={imageInputStyles}
                  id="imageFile"
                  multiple={false}
                  onChange={(e) => {
                    setImageFromUpload(e.target?.files?.[0] || '');
                  }}
                  ref={inputFileRef}
                  type="file"
                />
                {imageIcon !== '' && (
                  <IconButton
                    id="control-image-button"
                    label={
                      imageIcon === 'trash'
                        ? 'Delete exisiting avatar image'
                        : imageIcon === 'goBack'
                        ? 'Restore previous avatar'
                        : imageIcon === 'cancel'
                        ? 'Cancel new avatar image'
                        : ''
                    }
                    onClick={(e) => removeImage(e)}
                    css={css`
                      margin-left: ${theme.spacing(2)};
                    `}
                  >
                    {imageIcon === 'trash' && <TrashIcon />}
                    {imageIcon === 'goBack' && <BackIcon />}
                    {imageIcon === 'cancel' && <XIcon />}
                  </IconButton>
                )}
              </div>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Actions
        onPrimaryClick={handleSave}
        primaryLabel={selectedProvider === null ? 'Create Provider' : 'Edit Provider'}
        disablePrimary={saveDisabled}
        onSecondaryClick={modalProps.onClose}
        secondaryLabel="Cancel"
      />
    </Modal>
  );
};
