import React from 'react';
import { css } from 'emotion';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';

import { locationDashboardStyles } from './location-dashboard.styles';
import { InteractiveCellText } from '../../interactive-cell-text.component';
import { dateTimeFormat } from '../../../../helpers/utils';
import { SMSMessages } from '../../../../redux/actions/smart-alerts/smart-alerts.action';
import { DotSvg } from './dot-svg';

interface Props {
  autoSMSMessageData: SMSMessages[];
}

export interface State {
  modalOpen: boolean;
  startDate: Date;
  endDate: Date;
}

const formatDate = (seconds: string | number): string => {
  if (!seconds) {
    return '';
  }

  return moment(seconds, ['X', 'YYYY-MM-DDTHH:mm:ssZ']).format(dateTimeFormat);
};

export default class SyncTableComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    moment.locale('en');
    momentLocalizer();

    this.state = {
      modalOpen: false,
      startDate: moment().subtract(1, 'week').toDate(),
      endDate: new Date(),
    };
  }

  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };

  render() {
    return (
      <tbody className={locationDashboardStyles.tableBody}>
        {!this.props.autoSMSMessageData
          ? 'Loading props sms data'
          : this.props.autoSMSMessageData.map((sms, smsIndex) => (
              <tr key={smsIndex}>
                <td> SMS </td>
                <td className={locationDashboardStyles.dotColumn}>
                  <DotSvg color="error" />
                  {formatDate(sms.createdAt)}
                </td>
                <td
                  className={css`
                    padding: 10px;
                  `}
                >
                  {sms.status}
                </td>
                <td>
                  <div className={locationDashboardStyles.tableActions}>
                    <InteractiveCellText
                      text={
                        sms.statusDetails === ''
                          ? 'There are no details for the alert'
                          : sms.statusDetails
                      }
                    />
                  </div>
                </td>
              </tr>
            ))}
      </tbody>
    );
  }
}
