import { CustomAxios } from '../../redux/axios';
import { REACT_APP_API_URL } from '../../config/app';
import { PublishDetails } from './models';

const isDev = (REACT_APP_API_URL as string).includes('dev');
export const DesktopReleaseUrl = `https://sum${
  isDev ? '-dev' : ''
}.weaveconnect.com/v1/releases`;

export const addLocationsToRelease = async (id: string, locations: string[]) => {
  return CustomAxios.put(`${DesktopReleaseUrl}/${id}/add-locations`, locations);
};

export const removeLocationsFromRelease = async (id: string, locations: string[]) => {
  return CustomAxios.put(`${DesktopReleaseUrl}/${id}/remove-locations`, locations);
};

export const enableRelease = async (id: string) => {
  return CustomAxios.put(`${DesktopReleaseUrl}/${id}/enable`);
};

export const disableRelease = async (id: string) => {
  return CustomAxios.put(`${DesktopReleaseUrl}/${id}/disable`);
};

export const deleteRelease = async (id: string) => {
  return CustomAxios.delete(`${DesktopReleaseUrl}/${id}`);
};

// This api actually functions as an 'upsert', so calling this endpoint for the same id will overwrite the existing release metadata.
export const insertNewRelease = async (jsonBody: any, file: File) => {
  const response = await CustomAxios.post(`${DesktopReleaseUrl}`, jsonBody);

  const data = new FormData();
  data.append('file', file);

  return await CustomAxios.post(`${DesktopReleaseUrl}/${response.data.ID}/upload`, data);
};

export const publishRelease = async (id: string, details: PublishDetails) => {
  return CustomAxios.put(`${DesktopReleaseUrl}/${id}/publish`, details);
};
