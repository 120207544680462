import { handleActions } from 'redux-actions';
import {
  getHistory,
  getHistorySuccess,
  getUnfilteredHistory,
  unsetLoading,
} from './history.action';

const historyReducerDefaultState = {
  data: [],
  loading: false,
}; // changed default state from an array to an object so we can pass loading

export const historyReducer = handleActions(
  {
    [getHistory.toString()]: (state) => Object.assign({}, state, { loading: true }),
    [getUnfilteredHistory.toString()]: (state) =>
      Object.assign({}, state, { loading: true }),
    [getHistorySuccess.toString()]: (state, action) =>
      Object.assign({}, state, { loading: false, data: action.payload }),
    [unsetLoading.toString()]: (state, action) =>
      Object.assign({}, state, { loading: false, data: [] }),
  },
  historyReducerDefaultState
);

export default historyReducer;
