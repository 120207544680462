import React from 'react';
import { css } from '@emotion/core';
import chunk from 'lodash/chunk';
import {
  FormRow,
  PasswordField,
  PrimaryButton,
  TextField,
  useForm,
} from '@weave/design-system';
import { IntegrationFieldsModel } from '../../../redux/actions/sync-app-settings';
import { formRowSingleChildMargin } from './create-cloud-sync-app.component';

type Props = {
  fields: any;
  fieldsMetadata: IntegrationFieldsModel[];
  formComplete: boolean;
  onClick: (values: { [key: string]: any }) => void;
};

export const SettingsFields = ({
  fields,
  fieldsMetadata,
  onClick,
  formComplete,
}: Props) => {
  const { getFieldProps, isComplete, values } = useForm({ fields });

  return (
    <>
      {chunk(fieldsMetadata, 2).map((rowFields, rowIndex) => (
        <FormRow
          cols={Array(rowFields.length).fill(50)}
          key={rowIndex}
          css={css`
            width: 800px;
          `}
        >
          {rowFields.map((field, fieldIndex) => {
            const Component = field.valueType !== 'PASSWORD' ? TextField : PasswordField;
            return (
              <Component
                {...getFieldProps(field.name)}
                label={field.displayName}
                key={`${rowIndex}-${fieldIndex}`}
                autoComplete="new-password"
                css={rowFields.length === 1 && formRowSingleChildMargin}
              />
            );
          })}
        </FormRow>
      ))}
      <PrimaryButton
        css={css`
          width: 160px;
        `}
        disabled={!formComplete || (!isComplete && Object.keys(fields).length > 0)}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          onClick(values);
        }}
      >
        Create
      </PrimaryButton>
    </>
  );
};
