import React, { useEffect } from 'react';
import {
  ContentLoader,
  FormRow,
  PrimaryButton,
  SecondaryButton,
  TextField,
  useForm,
} from '@weave/design-system';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteOnboarder,
  getOnboarderSchedulingLinks,
  updateOnboarderLinks,
} from '../../redux/actions/onboarding-tasks';

export const OnboardingConfig = () => {
  const schedulingLinksId = useSelector(
    (state: any) => state.onboarding.schedulingLinks.id
  );
  const deleteLoading = useSelector((state: any) => state.onboarding.deleteLoading);
  const loadingLinks = useSelector((state: any) => state.onboarding.loadingLinks);
  const userId = useSelector((state: any) => state.auth.user.user_id);
  const salesforceUserId = useSelector(
    (state: any) => state.onboarding.schedulingLinks.salesforceUserId
  );
  const dispatch = useDispatch();

  const { formProps, getFieldProps, isComplete, reset, seedValues } = useForm({
    fields: { salesforceUserId: { type: 'text', required: true } },
    onSubmit: (values) => {
      dispatch(
        updateOnboarderLinks({
          userId: userId,
          schedulingLinks: { salesforceUserID: values.salesforceUserId },
        })
      );
    },
  });

  useEffect(() => {
    dispatch(getOnboarderSchedulingLinks({ id: userId }));
    seedValues({ salesforceUserId: salesforceUserId || '' });
    return () => {
      reset();
    };
  }, []);

  return loadingLinks || deleteLoading ? (
    <ContentLoader show={true} />
  ) : (
    <form {...formProps}>
      <FormRow>
        <TextField
          {...getFieldProps('salesforceUserId')}
          label="Salesforce User Id"
          name="salesforceUserId"
        />
      </FormRow>
      <PrimaryButton disabled={!isComplete} type="submit">
        Save
      </PrimaryButton>

      {schedulingLinksId && (
        <SecondaryButton
          onClick={() => {
            dispatch(deleteOnboarder({ id: schedulingLinksId }));
          }}
        >
          Delete Onboarder
        </SecondaryButton>
      )}
    </form>
  );
};
