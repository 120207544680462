import { PhoneMediaActions, PhoneMediaActionTypes } from './phone-media.actions';
import { enforceArray } from '../../store/store-utils';
export interface PhoneMedia {
  MediaID: string;
  LocationID: string;
  FilePath: string;
  FileName: string;
  MediaType: string;
  ContentType: string;
  CreatedAt: string;
  DeletedAt: string;
}

export interface PhoneMediaState {
  [locationId: string]: string[];
}

export const phoneMediaReducer = (
  state: PhoneMediaState = {},
  action: PhoneMediaActions
): PhoneMediaState => {
  switch (action.type) {
    case PhoneMediaActionTypes.Add: {
      const { locationId, media } = action.payload;
      const newMediaIds: string[] = enforceArray<PhoneMedia>(media).reduce(
        (mediaIds: string[], phoneMedia) => [...mediaIds, phoneMedia.MediaID],
        []
      );

      return {
        ...state,
        [locationId]: Array.from(new Set([...(state[locationId] || []), ...newMediaIds])),
      };
    }
    case PhoneMediaActionTypes.Delete: {
      const { locationId, mediaId } = action.payload;
      return {
        ...state,
        [locationId]: state[locationId].filter(
          (currentMediaId) => currentMediaId !== mediaId
        ),
      };
    }
    default:
      return state;
  }
};
