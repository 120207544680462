import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { selectAuthToken } from '../../redux/actions/auth/auth.selectors';
import { selectCurrentLocationId } from '../../redux/actions/location';
import { selectPaymentUrl } from '../../redux/actions/merchant/merchant.action';

import { store as storeInstance } from '../../redux/store/store';

// singleton instance of ApolloClient
let client: ApolloClient<{ [key: string]: unknown }> | undefined;

// GQL url is paymentBaseUrl + gqlEndpoint
export const gqlEndpoint = '/v1/gql/q';

// We rely on paymentUrl, locationId, and token to all be loaded from the
// store before we can set up the client. This helper makes sure they are
// set.
const waitStateVariableSet = (
  store: typeof storeInstance,
  selector: (store) => any,
  options?: { timeout: number }
) => {
  let isPromisedResolved = false;

  return new Promise((resolve, reject) => {
    let currentState = selector(store.getState());

    if (currentState) {
      resolve(currentState);
      return;
    }

    // subscribe to store changes
    const subscribeLocationId = () => {
      const prevState = currentState;
      currentState = selector(store.getState());

      //TODO: should we check for changes or just value is set
      if (currentState && prevState !== currentState) {
        resolve(currentState);
        isPromisedResolved = true;
        unsubscribe();
      }

      // bailout if not resolved in time
      setTimeout(() => {
        if (!isPromisedResolved) {
          reject('Waiting for state to change timed out.');
          unsubscribe();
        }
      }, (options && options.timeout) || 2000);
    };
    const unsubscribe = store.subscribe(subscribeLocationId);
  });
};

const asyncLink = setContext(async () => {
  try {
    const token = await waitStateVariableSet(storeInstance, selectAuthToken);
    const locationId = await waitStateVariableSet(storeInstance, selectCurrentLocationId);
    const paymentBaseUrl = await waitStateVariableSet(storeInstance, selectPaymentUrl);
    const authHeader = token ? `Bearer ${token}` : null;

    return {
      uri: paymentBaseUrl + gqlEndpoint,
      headers: {
        Authorization: authHeader,
        'Location-Id': locationId,
      },
    };
  } catch (err: any) {
    console.error('GQL: error getting token', err);
    return {};
  }
});

export const getPaymentsApolloClient = () => {
  if (client) {
    return client;
  }

  const httpLink = createHttpLink();

  client = new ApolloClient({
    link: ApolloLink.from([asyncLink, httpLink]),
    cache: new InMemoryCache(),
  });

  return client;
};

export const clearPaymentsApolloClient: () => void = () => {
  client = undefined;
};

export const gqlClientModule = {
  waitStateVariableSet,
  asyncLink,
  getPaymentsApolloClient,
  clearPaymentsApolloClient,
};
