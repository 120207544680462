import React, { useState } from 'react';
import { css } from '@emotion/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { WeaveTheme } from '@weave/theme-original';
import { CopyIcon, Text } from '@weave/design-system';

const copyIconStyle = (theme: WeaveTheme) => css`
  cursor: pointer;
  padding-left: ${theme.spacing(1)};

  &:hover {
    fill: ${theme.colors.gray[500]};
  }
`;

const clipboardDiv = (theme: WeaveTheme) => css`
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-top: ${theme.spacing(1)};
`;

const mRight8 = (theme: WeaveTheme) => css`
  margin-right: ${theme.spacing(1)};
`;

interface Props {
  title: string;
  value: string;
  valueHidden?: boolean;
  titleHidden?: boolean;
}

export const CopyCredential = ({ title, value, valueHidden, titleHidden }: Props) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const copiedCredential = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  return (
    <>
      <CopyToClipboard text={value} onCopy={copiedCredential}>
        <div css={clipboardDiv}>
          {!titleHidden && (
            <Text as="span" weight="bold" css={mRight8}>
              {title}
              {!valueHidden && ':'}
            </Text>
          )}
          {!valueHidden && <Text as="span">{value} </Text>}
          <CopyIcon css={copyIconStyle} />
        </div>
      </CopyToClipboard>
      {isCopied && (
        <Text as="span" color="success" size="medium">
          Copied
        </Text>
      )}
    </>
  );
};
