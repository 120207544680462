import { paths, components } from '@weave/shared-proto-gateway/src/schedule-api';
import {
  paths as pathsV2,
  components as componentsV2,
} from '@weave/openapi-gen-ts/src/schedule_api_v2_api';
import { protosApiFactory, protosQueryFactory } from '../proto-utils';
import { protosHookFactory } from '../proto.hook-factory';

export const schedulingApi = protosApiFactory<paths>('/support/gateway');
export const schedulingApiV2 = protosApiFactory<pathsV2>('/schedule/api/v2');

export const schedulingHook = protosHookFactory<paths>('/support/gateway');

export const schedulingQuery = protosQueryFactory<paths>('/support/gateway');
export const schedulingQueryV2 = protosQueryFactory<pathsV2>('/schedule/api/v2');

export type SchedulingTypes = components['schemas'];
export type SchedulingV2Types = componentsV2['schemas'];
