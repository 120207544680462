import { createAction } from 'redux-actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { showError, showSuccess } from '@weave/alert-system';
import { CustomAxios } from '../../axios';

export const sendReviewInvitation = createAction('SEND_REVIEW_INVITATION');
export const sendReviewInvitationFailure = createAction('SEND_REVIEW_INVITATION_FAILURE');
export const sendReviewInvitationSuccess = createAction('SEND_REVIEW_INVITATION_SUCCESS');

export const handleSendReviewInvitation = function* (action) {
  try {
    yield call(CustomAxios.post, 'support/v1/reviews/invite', {
      phone_Number: action.payload.replace(/\D+/g, ''),
    });
    yield put(showSuccess('Reviews Invite Sent'));
  } catch {
    yield put(showError('Reviews Invite Error'));
  }
};

export const sendReviewInvitationSaga = function* () {
  yield takeEvery(sendReviewInvitation.toString(), handleSendReviewInvitation);
};
