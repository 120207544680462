import React from 'react';
import { useResources } from '../../../../../helpers/useResources';
import { useSelector } from 'react-redux';
import { selectCurrentLocationId } from '../../../../../redux/actions/location/current-location';
import { CustomAxios } from '../../../../../redux/axios';
import { ReviewsSettingsForm } from './reviews-settings-form.component';
import { useAlert } from '@weave/alert-system';
import { SpinningLoader } from '@weave/design-system';
import { Settings, SiteName } from '../../helpers';
import { Site } from '../../helpers';

export type ChangedValues = {
  template?: string;
  autoSend?: boolean;
  autoSendDelay?: number | string;
  otherUrl?: string;
  siteOrder?: SiteName[];
};

type ReviewsSettingsContainerProps = {
  settings: Settings;
};

export const ReviewsSettings = ({ settings }: ReviewsSettingsContainerProps) => {
  const alerts = useAlert();
  const locationId = useSelector(selectCurrentLocationId);

  const { data: siteUrlsData, loading: siteUrlsLoading } = useResources<Site[]>(
    'support/v1/reviews/urls',
    {
      deps: [locationId],
      suppressFetch: !locationId,
      resourceLabel: 'site urls',
    }
  );

  const { data: notificationsData, loading: notificationsLoading } = useResources<any>(
    'support/v1/notifications/settings',
    {
      deps: [locationId],
      suppressFetch: !locationId,
      resourceLabel: 'notifications',
    }
  );

  const loading = siteUrlsLoading || notificationsLoading;
  const notificationSettings = notificationsData.notificationSettings?.reviews?.[0];

  const handleUpdate = async (changedValues: ChangedValues) => {
    try {
      if (changedValues.autoSend !== undefined) {
        await CustomAxios.put('support/v1/reviews/settings/autosend', {
          AutoSend: changedValues.autoSend,
        });
      }

      if (
        changedValues.autoSendDelay !== undefined ||
        changedValues.template !== undefined
      ) {
        const payload = { ...notificationSettings };

        if (changedValues.autoSendDelay !== undefined) {
          payload.send_before.value = changedValues.autoSendDelay;
        }

        if (changedValues.template) {
          payload.template.text = changedValues.template;
        }

        await CustomAxios.put(`support/v1/notifications/settings/${payload.id}`, payload);
      }

      if (changedValues.otherUrl !== undefined) {
        const payload = [...siteUrlsData];
        const otherIndex = payload.findIndex((site) => site.Source === 'other');
        payload[otherIndex].URL = changedValues.otherUrl;
        await CustomAxios.put('support/v1/reviews/urls', payload);
      }

      if (changedValues.siteOrder !== undefined) {
        await CustomAxios.put('support/v1/reviews/order', {
          SiteOrder: changedValues.siteOrder,
        });
      }
      alerts.success('Successfully updated review settings!');
      return true;
    } catch {
      alerts.error('Failed to update review settings!');
      return false;
    }
  };

  const handleResync = async () => {
    try {
      const payload = { locationids: [locationId] };
      await CustomAxios.post('support/v1/reviews/sync', payload);
      alerts.success('Successfully resynced reviews!');
    } catch {
      alerts.error('Failed to resync reviews!');
    }
  };

  return loading ? (
    <SpinningLoader size="large" />
  ) : (
    <ReviewsSettingsForm
      settings={settings}
      onUpdate={handleUpdate}
      onResync={handleResync}
    />
  );
};
