import React from 'react';
import { css } from 'emotion';
import { connect } from 'react-redux';
import { isEmpty, cloneDeep } from 'lodash';
import { Input, Button } from '@weave/platform-ui-components';
import { Typeahead } from 'react-bootstrap-typeahead';

import { LocationModel, selectCurrentLocation } from '../../../../redux/actions/location';
import {
  AvailablePhoneNumbersModel,
  NewPhoneNumberModel,
} from '../twilio-models.interface';
import { StateAbbreviations } from '../../../../models/state-abbreviates.constants';
import {
  clearAvailablePhoneNumbers,
  getAvailablePhoneNumbers,
  purchasePhoneNumbers,
} from '../../../../redux/actions/twilio';
import AvailablePhoneNumbers from './available-phone-numbers/available-phone-numbers.component';
import { orderNewNumberStyles } from './order-new-number.styles';
import { Loader } from '../../../shared/loader/loader.component';
import { Page } from '../../../shared';
import { Store } from '../../../../redux/store/store.model';

interface Props {
  currentLocation: LocationModel | null;
  loading: boolean;
  availablePhoneNumbers: AvailablePhoneNumbersModel;
  getAvailablePhoneNumbers: (NewPhoneNumberModel) => void;
  clearAvailablePhoneNumbers: () => void;
  purchasePhoneNumbers: (PurchasePhoneNumberModel) => void;
  newNumberSuccessMessages: string[];
  newNumberFailureMessages: string[];
}

interface State {
  newNumber: NewPhoneNumberModel;
  invalidState: boolean;
  selectedNumbers: string[];
}

class OrderNewNumber extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = this.resetForm(true);
  }

  componentDidMount() {
    this.props.clearAvailablePhoneNumbers();
  }

  handleInputChange = (property, event) => {
    const val = event.target.value;
    this.setState((prevState) => {
      return {
        newNumber: Object.assign({}, prevState.newNumber, { [property]: val }),
      };
    });
  };

  handleStateBlur = (event) => {
    const val = event.target.value;
    const invalidState = !StateAbbreviations.find((state) => state.name === val);
    this.setState({ invalidState });
  };

  handleStateInputChange = (text) => {
    if (this.state.invalidState) {
      const invalidState = !StateAbbreviations.find((state) => state.name === text);
      this.setState({ invalidState });
    }
  };

  handleStateChange = (state) => {
    const region = state[0]?.abbreviation;
    this.setState((prevState) => ({
      newNumber: { ...prevState.newNumber, region },
      invalidState: false,
    }));
  };

  findAvailablePhoneNumbers = (event) => {
    event.preventDefault();
    this.setState({ selectedNumbers: [] });
    this.props.getAvailablePhoneNumbers(this.state.newNumber);
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      this.props?.currentLocation?.LocationID !== nextProps?.currentLocation?.LocationID
    ) {
      this.resetForm(false);
      this.props.clearAvailablePhoneNumbers();
    }
  }

  resetForm = (initial: boolean) => {
    const form = {
      newNumber: {
        areacode: '',
        locality: '',
        region: '',
        zipcode: '',
        street: '',
      },
      selectedNumbers: [],
      invalidState: false,
    };
    if (!initial) {
      this.setState(() => {
        return form;
      });
    }
    return form;
  };

  handlePurchaseChange = (e) => {
    const purchaseEnabled = e.target.checked;
    const phoneNumber = e.target.value;
    const selectedNumbers = cloneDeep(this.state.selectedNumbers);
    if (purchaseEnabled) {
      selectedNumbers.push(phoneNumber);
    } else {
      const index = selectedNumbers.indexOf(phoneNumber);
      selectedNumbers.splice(index, 1);
    }
    this.setState({ selectedNumbers });
  };

  purchaseSelectedNumbers = () => {
    this.props.purchasePhoneNumbers({
      selectedNumbers: this.state.selectedNumbers,
      locationSlug: this.props.currentLocation?.Slug,
    });
  };

  render() {
    if (!this.props.currentLocation) {
      return null;
    }
    return (
      <Page title="Order New Number">
        <form
          className={css`
            max-width: 500px;
          `}
          autoComplete="off"
          onSubmit={this.findAvailablePhoneNumbers}
        >
          <Input
            type="text"
            placeholder="Area Code"
            value={this.state.newNumber.areacode}
            onChange={(e) => this.handleInputChange('areacode', e)}
            required
            className={css`
              margin-bottom: 16px;
            `}
          />
          <Input
            type="text"
            placeholder="Street"
            value={this.state.newNumber.street}
            onChange={(e) => this.handleInputChange('street', e)}
            required
            className={css`
              margin-bottom: 16px;
            `}
          />
          <Input
            type="text"
            placeholder="City"
            value={this.state.newNumber.locality}
            onChange={(e) => this.handleInputChange('locality', e)}
            required
            className={css`
              margin-bottom: 16px;
            `}
          />
          <Typeahead
            id="twilio-number-state-dropdown"
            className={orderNewNumberStyles.typeaheadStyles}
            isInvalid={this.state.invalidState}
            onInputChange={this.handleStateInputChange}
            onBlur={this.handleStateBlur}
            options={StateAbbreviations}
            placeholder="State"
            highlightOnlyResult
            labelKey="name"
            onChange={this.handleStateChange}
          />
          <Input
            type="text"
            placeholder="Zip Code"
            value={this.state.newNumber.zipcode}
            onChange={(e) => this.handleInputChange('zipcode', e)}
            required
            className={css`
              margin-bottom: 16px;
            `}
          />
          <Button disabled={this.props.loading} type="submit">
            Search
          </Button>
        </form>
        {!isEmpty(this.props.availablePhoneNumbers.available_phone_numbers) &&
          this.props.availablePhoneNumbers.uri && (
            <AvailablePhoneNumbers
              selectedNumbers={this.state.selectedNumbers}
              purchaseSelectedNumbers={this.purchaseSelectedNumbers}
              handlePurchaseChange={this.handlePurchaseChange}
            />
          )}
        {this.props.loading && (
          <div className={orderNewNumberStyles.loaderStyles}>
            <Loader size="large" />
          </div>
        )}
      </Page>
    );
  }
}

const mapStateToProps = (state: Store) => {
  return {
    currentLocation: selectCurrentLocation(state),
    loading: state.twilio.loadingAvailableNumbers,
    availablePhoneNumbers: state.twilio.availablePhoneNumbers,
    newNumberSuccessMessages: state.twilio.newNumberSuccessMessages,
    newNumberFailureMessages: state.twilio.newNumberFailureMessages,
  };
};

export default connect(mapStateToProps, {
  getAvailablePhoneNumbers,
  clearAvailablePhoneNumbers,
  purchasePhoneNumbers,
})(OrderNewNumber);
