import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { createRequestSaga } from '@weave/alert-system';
import {
  DepartmentPhoneNumberModel,
  DepartmentPhoneNumbersActions,
  DepartmentPhoneNumbersActionTypes,
  RequestListDepartmentPhoneNumberAction,
  RequestGetDepartmentPhoneNumberAction,
  RequestUpdateDepartmentPhoneNumberAction,
  RequestDeleteDepartmentPhoneNumberAction,
  RequestCreateDepartmentPhoneNumberAction,
  mockDepartmentPhoneNumber,
} from './department-phone-numbers.types';
import { selectDepartmentPhoneNumber } from './department-phone-numbers.selectors';
import { OnCompleteFn } from '../../store/store-utils';

export const requestListDepartmentPhoneNumbers = (
  onComplete?: OnCompleteFn<DepartmentPhoneNumberModel[]>
): DepartmentPhoneNumbersActions => ({
  type: DepartmentPhoneNumbersActionTypes.RequestList,
  payload: undefined,
  onComplete,
});
export const requestGetDepartmentPhoneNumber = (
  id: string,
  onComplete?: OnCompleteFn<DepartmentPhoneNumberModel>
): DepartmentPhoneNumbersActions => ({
  type: DepartmentPhoneNumbersActionTypes.RequestGet,
  payload: id,
  onComplete,
});
export const requestUpdateDepartmentPhoneNumber = (
  id: string,
  update: Partial<DepartmentPhoneNumberModel>,
  onComplete?: OnCompleteFn<DepartmentPhoneNumberModel>
): DepartmentPhoneNumbersActions => ({
  type: DepartmentPhoneNumbersActionTypes.RequestUpdate,
  payload: { id, update },
  onComplete,
});
export const requestDeleteDepartmentPhoneNumber = (
  id: string,
  onComplete?: OnCompleteFn<string>
): DepartmentPhoneNumbersActions => ({
  type: DepartmentPhoneNumbersActionTypes.RequestDelete,
  payload: id,
  onComplete,
});
export const requestCreateDepartmentPhoneNumber = (
  departmentPhoneNumber: DepartmentPhoneNumberModel,
  onComplete?: OnCompleteFn<DepartmentPhoneNumberModel>
): DepartmentPhoneNumbersActions => ({
  type: DepartmentPhoneNumbersActionTypes.RequestCreate,
  payload: departmentPhoneNumber,
  onComplete,
});

export const addDepartmentPhoneNumbers = (
  departmentPhoneNumbers: DepartmentPhoneNumberModel[]
): DepartmentPhoneNumbersActions => ({
  type: DepartmentPhoneNumbersActionTypes.Add,
  payload: departmentPhoneNumbers,
});
export const removeDepartmentPhoneNumbers = (
  phoneNumberIds: string[]
): DepartmentPhoneNumbersActions => ({
  type: DepartmentPhoneNumbersActionTypes.Remove,
  payload: phoneNumberIds,
});
export const updateDepartmentPhoneNumber = (
  departmentPhoneNumber: DepartmentPhoneNumberModel
): DepartmentPhoneNumbersActions => ({
  type: DepartmentPhoneNumbersActionTypes.Update,
  payload: departmentPhoneNumber,
});
export const updateMultipleDepartmentPhoneNumbers = (
  departmentPhoneNumbers: DepartmentPhoneNumberModel[]
): DepartmentPhoneNumbersActions => ({
  type: DepartmentPhoneNumbersActionTypes.UpdateMultiple,
  payload: departmentPhoneNumbers,
});

// const api = {
//   //note, we don't select by department here, because we need them all, to know which ones are available to attach per department
//   listDepartmentPhoneNumbers: (): Promise<DepartmentPhoneNumberModel[]> =>
//      CustomAxios.get(`support/v1/departments/${departmentId}/departmentPhoneNumbers`).then(getResponseData)
//   getDepartmentPhoneNumber: (
//     phoneNumberId: string
//   ): Promise<DepartmentPhoneNumberModel> =>
//     CustomAxios.get(`support/v1/phone-number/${phoneNumberId}`).then(
//       getResponseData
//     ),
//   updateDepartmentPhoneNumber: (
//     departmentPhoneNumber: DepartmentPhoneNumberModel
//   ): Promise<DepartmentPhoneNumberModel> =>
//     CustomAxios.put(
//       `support/v1/phone-number/${departmentPhoneNumber.phoneNumberId}`,
//       departmentPhoneNumber
//     ).then(getResponseData),
//   deleteDepartmentPhoneNumber: (
//     phoneNumberId: string
//   ): Promise<DepartmentPhoneNumberModel> =>
//     CustomAxios.delete(`support/v1/phone-number/${phoneNumberId}`).then(
//       getResponseData
//     ),
//   createDepartmentPhoneNumber: (
//     departmentPhoneNumber: DepartmentPhoneNumberModel
//   ): Promise<DepartmentPhoneNumberModel> =>
//     CustomAxios.post(`support/v1/phone-number/`, departmentPhoneNumber).then(
//       getResponseData
//     ),
// };
const api = {
  listDepartmentPhoneNumbers: (
    departmentId?: string
  ): Promise<DepartmentPhoneNumberModel[]> => Promise.resolve([]),
  getDepartmentPhoneNumber: (
    phoneNumberId: string
  ): Promise<DepartmentPhoneNumberModel> =>
    Promise.resolve(mockDepartmentPhoneNumber({ phoneNumberId: phoneNumberId })),
  updateDepartmentPhoneNumber: (
    departmentPhoneNumber: DepartmentPhoneNumberModel
  ): Promise<DepartmentPhoneNumberModel> => Promise.resolve(departmentPhoneNumber),
  deleteDepartmentPhoneNumber: (phoneNumberId: string): Promise<string> =>
    Promise.resolve(phoneNumberId),
  createDepartmentPhoneNumber: (
    departmentPhoneNumber: DepartmentPhoneNumberModel
  ): Promise<DepartmentPhoneNumberModel> => Promise.resolve(departmentPhoneNumber),
};

const listDepartmentPhoneNumbersSaga =
  createRequestSaga<RequestListDepartmentPhoneNumberAction>({
    key: DepartmentPhoneNumbersActionTypes.RequestList,
    displayErrors: true,
    onError: (err) => `Failed to load departmentPhoneNumber: ${err.message}`,
    saga: function* ({ payload, onComplete }) {
      const departmentPhoneNumbers: DepartmentPhoneNumberModel[] = yield call(
        api.listDepartmentPhoneNumbers,
        payload
      );
      yield put(addDepartmentPhoneNumbers(departmentPhoneNumbers));
      yield onComplete?.(departmentPhoneNumbers);
    },
  });
const getDepartmentPhoneNumberSaga =
  createRequestSaga<RequestGetDepartmentPhoneNumberAction>({
    key: DepartmentPhoneNumbersActionTypes.RequestGet,
    displayErrors: true,
    onError: (err) => `Failed to load departmentPhoneNumber: ${err.message}`,
    saga: function* ({ payload, onComplete }) {
      const departmentPhoneNumber: DepartmentPhoneNumberModel = yield call(
        api.getDepartmentPhoneNumber,
        payload
      );
      yield put(addDepartmentPhoneNumbers([departmentPhoneNumber]));
      yield onComplete?.(departmentPhoneNumber);
    },
  });
const updateDepartmentPhoneNumberSaga =
  createRequestSaga<RequestUpdateDepartmentPhoneNumberAction>({
    key: DepartmentPhoneNumbersActionTypes.RequestUpdate,
    displayErrors: true,
    onError: (err) => `Failed to load departmentPhoneNumber: ${err.message}`,
    saga: function* ({ payload, onComplete }) {
      const {
        departmentPhoneNumber,
      }: ReturnType<ReturnType<typeof selectDepartmentPhoneNumber>> = yield select(
        selectDepartmentPhoneNumber(payload.id)
      );
      const newDepartmentPhoneNumber = { ...departmentPhoneNumber, ...payload.update };
      yield call(api.updateDepartmentPhoneNumber, newDepartmentPhoneNumber);
      yield put(updateDepartmentPhoneNumber(newDepartmentPhoneNumber));
      yield onComplete?.(newDepartmentPhoneNumber);
    },
  });
const deleteDepartmentPhoneNumberSaga =
  createRequestSaga<RequestDeleteDepartmentPhoneNumberAction>({
    key: DepartmentPhoneNumbersActionTypes.RequestDelete,
    displayErrors: true,
    onError: (err) => `Failed to load departmentPhoneNumber: ${err.message}`,
    saga: function* ({ payload, onComplete }) {
      yield call(api.deleteDepartmentPhoneNumber, payload);
      yield put(removeDepartmentPhoneNumbers([payload]));
      yield onComplete?.(payload);
    },
  });
const createDepartmentPhoneNumberSaga =
  createRequestSaga<RequestCreateDepartmentPhoneNumberAction>({
    key: DepartmentPhoneNumbersActionTypes.RequestCreate,
    displayErrors: true,
    onError: (err) => `Failed to load departmentPhoneNumber: ${err.message}`,
    saga: function* ({ payload, onComplete }) {
      const departmentPhoneNumber: DepartmentPhoneNumberModel = yield call(
        api.createDepartmentPhoneNumber,
        payload
      );
      yield put(addDepartmentPhoneNumbers([departmentPhoneNumber]));
      yield onComplete?.(departmentPhoneNumber);
    },
  });

export const departmentPhoneNumbersSaga = function* () {
  yield all([
    takeLatest(
      DepartmentPhoneNumbersActionTypes.RequestList,
      listDepartmentPhoneNumbersSaga
    ),
    takeLatest(
      DepartmentPhoneNumbersActionTypes.RequestGet,
      getDepartmentPhoneNumberSaga
    ),
    takeLatest(
      DepartmentPhoneNumbersActionTypes.RequestUpdate,
      updateDepartmentPhoneNumberSaga
    ),
    takeLatest(
      DepartmentPhoneNumbersActionTypes.RequestDelete,
      deleteDepartmentPhoneNumberSaga
    ),
    takeLatest(
      DepartmentPhoneNumbersActionTypes.RequestCreate,
      createDepartmentPhoneNumberSaga
    ),
  ]);
};
