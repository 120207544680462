import { useReducer } from 'react';

import {
  PhoneTreeModel,
  ExpandedPhoneTreeModel,
  PhoneTreeEntryModel,
  PhoneTreeInstruction,
} from './phone-tree.models';

export enum PhoneTreeActions {
  normalizePhoneTree = 'normalizePhoneTree',
  updateMediaId = 'updateMediaId',
  updateInstructionFwdNumber = 'updateInstructionFwdNumber',
}

export interface PhoneTreeEntriesMap {
  [id: string]: PhoneTreeEntryModel;
}

export interface PhoneTreeInstructionsMap {
  [id: string]: PhoneTreeInstruction;
}

interface PhoneTreeState {
  phoneTree: PhoneTreeModel;
  entries: PhoneTreeEntriesMap;
  instructions: PhoneTreeInstructionsMap;
  loading: boolean;
}

const initialState: PhoneTreeState = {
  phoneTree: {
    IVRMenuID: '',
    MenuName: '',
    Entries: [],
    MediaID: '',
  },
  entries: {},
  instructions: {},
  loading: true,
};

function reducer(state: PhoneTreeState, action) {
  switch (action.type) {
    case PhoneTreeActions.normalizePhoneTree: {
      const newPhoneTree: ExpandedPhoneTreeModel = action.payload;
      const updatedPhoneTree: PhoneTreeModel = {
        IVRMenuID: newPhoneTree.IVRMenuID,
        MenuName: newPhoneTree.MenuName,
        Entries: [],
        MediaID: newPhoneTree.MediaID,
      };
      const entryIds: string[] = [];
      const newEntries: PhoneTreeEntriesMap = {};
      const newInstructions: PhoneTreeInstructionsMap = {};

      if (newPhoneTree.Entries) {
        newPhoneTree.Entries.forEach((entry) => {
          entryIds.push(entry.ID);

          if (entry.Instructions) {
            const entryInstructionIds: string[] = [];

            entry.Instructions.forEach((instruction) => {
              entryInstructionIds.push(instruction.ID);
              newInstructions[instruction.ID] = instruction;
            });

            newEntries[entry.ID] = {
              ID: entry.ID,
              Type: entry.Type,
              Number: entry.Number,
              InstructionSetID: entry.InstructionSetID,
              Instructions: entryInstructionIds,
              MenuOptionID: entry.MenuOptionID,
            };
          } else {
            newEntries[entry.ID] = {
              ID: entry.ID,
              Type: entry.Type,
              Number: entry.Number,
              InstructionSetID: entry.InstructionSetID,
              Instructions: [],
              MenuOptionID: entry.MenuOptionID,
            };
          }
        });
      }

      updatedPhoneTree.Entries = entryIds;

      return {
        phoneTree: updatedPhoneTree,
        entries: newEntries,
        instructions: newInstructions,
        loading: false,
      };
    }
    case PhoneTreeActions.updateMediaId:
      return {
        ...state,
        phoneTree: {
          ...state.phoneTree,
          MediaID: action.payload,
        },
      };
    case PhoneTreeActions.updateInstructionFwdNumber:
      return {
        ...state,
        instructions: {
          ...state.instructions,
          [action.payload.ID]: action.payload,
        },
      };
    default:
      return { ...state };
  }
}

export const usePhoneTreeState = () => {
  const [phoneTreeState, phoneTreeDispatch] = useReducer(reducer, initialState);

  return {
    phoneTree: phoneTreeState.phoneTree,
    entries: phoneTreeState.entries,
    instructions: phoneTreeState.instructions,
    loadingPhoneTree: phoneTreeState.loading,
    phoneTreeDispatch,
  };
};
