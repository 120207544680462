import { EmailField, FormRow, Heading, Modal, Text, useForm } from '@weave/design-system';
import React, { useEffect } from 'react';
import { BillingAccount } from '../billing.types';

type Props = {
  initialAccountData?: Partial<BillingAccount['primaryContact']>;
  onCancel: () => void;
  onSave: (accountInfo: { email: string }) => void;
};

export const EditContactModal = ({ onCancel, onSave, initialAccountData }: Props) => {
  const { getFieldProps, formProps, seedValues } = useForm({
    fields: {
      email: { type: 'email' },
    },
    fieldStateReducer: (state, action) => {
      return state;
    },
    onSubmit: (values) =>
      onSave({
        email: values.email ?? '',
      }),
  });

  useEffect(() => {
    if (initialAccountData) {
      seedValues({
        email: initialAccountData.email,
      });
    }
  }, []);

  return (
    <form {...formProps}>
      <header>
        <Heading textAlign="center">Edit Contact Information</Heading>
        <Text color="light" textAlign="center">
          Edit and save your information below.
        </Text>
      </header>

      <Modal.Body>
        <Text weight="bold">Email Address</Text>
        <FormRow>
          <EmailField {...getFieldProps('email')} label="Email Address" />
        </FormRow>
      </Modal.Body>

      <Modal.Actions
        primaryLabel="Save"
        onPrimaryClick="submit"
        secondaryLabel="Cancel"
        onSecondaryClick={onCancel}
      />
    </form>
  );
};
