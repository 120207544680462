import React from 'react';
import { css } from '@emotion/core';
import { Text } from '@weave/design-system';
import { Product, SoftwareProduct } from '../sales-pricing.types';
import { SoftwareCard } from './software-card/software-card';

type Props = {
  softwareProductData: SoftwareProduct[];
  isItemInCart: (itemLabel: string) => boolean;
  getProductServiceFeeText: (product: Product) => string;
  onSoftwareProductClicked: (softwareProduct: SoftwareProduct) => void;
};

export const SoftwarePricing = ({
  softwareProductData,
  isItemInCart,
  getProductServiceFeeText,
  onSoftwareProductClicked,
}: Props) => {
  return (
    <div>
      <Text css={titleTextStyles} size="large" weight="bold">
        Select your Weave software bundles below
      </Text>
      <Text css={subtitleTextStyles} size="medium" color="light">
        Click a card to select or deselect an option.
      </Text>

      <div css={cardsSectionStyles}>
        {softwareProductData.map((softwareProduct) => (
          <SoftwareCard
            key={softwareProduct.label}
            productData={softwareProduct}
            isSelected={isItemInCart(softwareProduct.label)}
            getProductServiceFeeText={getProductServiceFeeText}
            onSoftwareProductClicked={onSoftwareProductClicked}
          />
        ))}
      </div>
    </div>
  );
};

const titleTextStyles = css`
  margin-bottom: 8px;
`;
const subtitleTextStyles = css`
  margin-bottom: 16px;
`;
const cardsSectionStyles = css`
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  width: 480px;

  @media only screen and (max-width: 520px) {
    width: 100%;
  }
`;
