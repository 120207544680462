import _ from 'lodash';
import { createAction } from 'redux-actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { showError, showSuccess } from '@weave/alert-system';
import { CustomAxios } from '../../axios';

import { reviewsOrderUrl, reviewsUrlsUrl } from './get-reviews-settings.action';

import { ReminderDetails } from '../../../redux/actions/notifications-settings/notification-settings.types';

export const updateReviewsSettings = createAction('UPDATE_REVIEWS_SETTINGS');
export const updateReviewsSettingsSuccess = createAction(
  'UPDATE_REVIEWS_SETTINGS_SUCCESS'
);
export const updateReviewsSettingsFailure = createAction(
  'UPDATE_REVIEWS_SETTINGS_FAILURE'
);

export const reviewsAccountStatusUrl = '/support/v1/reviews/accountStatus';
export const settingsAutosendUrl = '/support/v1/reviews/settings/autosend';
export const notificationsSettingsUrl = '/support/v1/notifications/settings';
export const reviewsScrapeUrl = '/support/v1/reviews/scrapeURLs';

export interface UpdateReviews {
  reviewsAccountStatusUrlParams: { active: string };
  settingsAutosendUrlParams: { AutoSend: boolean };
  notificationsSettingsUrlParams: ReminderDetails;
  reviewsUrlsUrlParams: { source: 'google' | 'facebook' | 'other'; url: string }[];
  reviewsOrderUrlParams: { SiteOrder: string[] };
  reviewsScrapeParams?: { Key: 'facebook_scrape' | 'google_scrape'; Value: string }[];
}

export const handleUpdateReviewsSettings = function* (action) {
  try {
    const reviewsAccountStatusUrlParams = action.payload.reviewsAccountStatusUrlParams;
    yield call(CustomAxios.put, reviewsAccountStatusUrl, reviewsAccountStatusUrlParams);

    const settingsAutosendUrlParams = action.payload.settingsAutosendUrlParams;
    yield call(CustomAxios.put, settingsAutosendUrl, settingsAutosendUrlParams);

    const notificationsSettingsUrlParams = action.payload.notificationsSettingsUrlParams;
    if (notificationsSettingsUrlParams.id) {
      yield call(
        CustomAxios.put,
        `${notificationsSettingsUrl}/${notificationsSettingsUrlParams.id}`,
        notificationsSettingsUrlParams
      );
    } else {
      notificationsSettingsUrlParams.template.type = 'all';
      notificationsSettingsUrlParams.send_before = {
        type: 'hours',
        value: 2,
      };
      notificationsSettingsUrlParams.delivery = {
        time: '10:00AM',
        method: 'sms',
      };
      notificationsSettingsUrlParams.type = 'review';
      notificationsSettingsUrlParams.name = 'Review Setting';
      yield call(
        CustomAxios.post,
        notificationsSettingsUrl,
        notificationsSettingsUrlParams
      );
    }

    const reviewsUrlsUrlParams = action.payload.reviewsUrlsUrlParams;
    yield call(CustomAxios.put, reviewsUrlsUrl, reviewsUrlsUrlParams);

    const reviewsOrderUrlParams = action.payload.reviewsOrderUrlParams;
    yield call(CustomAxios.put, reviewsOrderUrl, reviewsOrderUrlParams);

    // This call takes ~15 seconds, so be explicit if you want to call it.
    const reviewsScrapeParams = action.payload.reviewsScrapeParams;
    if (reviewsScrapeParams && !_.isEmpty(reviewsScrapeParams)) {
      yield call(CustomAxios.put, reviewsScrapeUrl, reviewsScrapeParams);
    }

    yield put(updateReviewsSettingsSuccess());
    yield put(showSuccess('Update succeeded!'));
  } catch (error: any) {
    yield put(updateReviewsSettingsFailure({ error }));
    yield put(showError('Update failed! Refresh your browser and try again.'));
  }
};

export const updateReviewsSettingsSaga = function* () {
  yield all([takeLatest(updateReviewsSettings.toString(), handleUpdateReviewsSettings)]);
};
