import React, { useEffect, useState } from 'react';
import { useRequestState } from '@weave/alert-system';
import {
  ButtonBar,
  ContentLoader,
  EmailField,
  FormRow,
  Heading,
  MultiselectField,
  PrimaryButton,
  SecondaryButton,
  TextField,
  useForm,
  ValidatorFieldState,
} from '@weave/design-system';
import { UserModel } from '../../models/user.model';
import { useDispatch, useSelector } from 'react-redux';
import { selectAvailableRoles } from '../../redux/actions/users/user-roles.action';
import {
  saveUserProfile,
  updateWeaveUserRoles,
} from '../../redux/actions/users/users-list.action';
import { formatAvailableOptions, FormattedOptions } from './users.helpers';

type Props = {
  handleEditClose: () => void;
  selectedUser: UserModel;
};

export const WeaveUsersEditForm = ({ selectedUser, handleEditClose }: Props) => {
  const dispatch = useDispatch();
  const roles = useSelector(selectAvailableRoles);
  const [roleOptions] = useState<string[]>(roles.map((r) => r.Name));
  const [originalRoles] = useState<string[]>(selectedUser.Roles.map((r) => r.Name));
  const [formattedRoles, setFormattedRoles] = useState<FormattedOptions[] | []>([]);
  const { loading } = useRequestState(saveUserProfile.toString());

  useEffect(() => {
    if (roles.length >= 1) setFormattedRoles(formatAvailableOptions(roles));
    return () => reset();
  }, [roles]);

  const handleSubmit = (TicketID: string, Roles: FormattedOptions[]) => {
    const payload = {
      addingNewUser: false,
      user: { ...selectedUser, TicketID, Roles },
    };
    dispatch(updateWeaveUserRoles(payload));
    handleEditClose();
  };

  /* Setting initial form values */
  useEffect(() => {
    if (selectedUser) {
      const startingValues = {
        Username: selectedUser.Username || '',
        roles: originalRoles,
        ticketId: '',
      };
      seedValues(startingValues);
    }
  }, [selectedUser]);

  const { formProps, getFieldProps, reset, isComplete, seedValues } = useForm({
    computeChangedValues: true,
    fields: {
      Username: {
        type: 'email',
        required: true,
      },
      roles: {
        type: 'multiselect',
        minRequired: 1,
        value: [],
        validator: ({ value }: ValidatorFieldState<'multiselect'>) =>
          value.length === originalRoles.length &&
          value.every((v, i) => v === originalRoles[i])
            ? 'Must add or change role.'
            : '',
      },
      ticketId: { type: 'text', required: true },
    },
    onSubmit: (values) => {
      const selectedRoles = formattedRoles.filter((role) =>
        values.roles?.includes(role.Name)
      );
      handleSubmit(values.ticketId!, selectedRoles);
    },
  });

  return (
    <>
      <ContentLoader show={loading || !selectedUser} />
      <Heading level={2}>User Roles</Heading>
      <form {...formProps}>
        <FormRow>
          <EmailField disabled={true} {...getFieldProps('Username')} label="Email" />
        </FormRow>
        <FormRow>
          <MultiselectField {...getFieldProps('roles')} label="Roles">
            {roleOptions?.map((role, i) => (
              <MultiselectField.Option key={i} value={role}>
                {role}
              </MultiselectField.Option>
            ))}
          </MultiselectField>
        </FormRow>
        <FormRow>
          <TextField {...getFieldProps('ticketId')} label="Ticket ID" />
        </FormRow>
        <ButtonBar>
          <SecondaryButton type="button" onClick={handleEditClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton disabled={!isComplete} type="submit">
            Submit
          </PrimaryButton>
        </ButtonBar>
      </form>
    </>
  );
};
