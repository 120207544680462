import { connect } from 'react-redux';
import { userSearch, userSelect } from '../../redux/actions/users';
import UserSearch from './user-search.component';

export const UserSearchContainer = connect(
  (state: any) => ({
    userSearchResults: state.userSearch.data,
    loading: state.userSearch.loading,
  }),
  { userSearch, userSelect }
)(UserSearch);
