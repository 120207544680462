import { css } from 'emotion';

export const container = css`
  label: flexStyle;
  display: flex;
  & .left {
    text-align: right;
    font-weight: bold;
    width: 30%;
    padding-right: 8px;
  }
`;

export const statusStyle = css`
  label: statusStyle;
  width: 30%;
`;

export const apptDate = css`
  label: apptDate;
  width: 65%;
  text-align: right;
`;
