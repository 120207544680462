import { handleActions } from 'redux-actions';

import {
  disableTracer,
  disableTracerSuccess,
  enableTracer,
  enableTracerSuccess,
  tracerError,
} from './internal-tools.action';

const defaultState = {
  tracerEnabled: false,
  tracerId: '',
  tracerError: false,
};

export const internalToolsReducer = handleActions(
  {
    [enableTracer.toString()](state) {
      return Object.assign({}, state, { tracerEnabled: true, tracerError: false });
    },
    [disableTracer.toString()](state) {
      return Object.assign({}, state, { tracerEnabled: false, tracerId: '' });
    },
    [enableTracerSuccess.toString()](state, action) {
      return Object.assign({}, state, {
        tracerEnabled: true,
        tracerId: action.payload,
        tracerError: false,
      });
    },
    [disableTracerSuccess.toString()](state) {
      return Object.assign({}, state, { tracerEnabled: false, tracerError: false });
    },
    [tracerError.toString()](state) {
      return Object.assign({}, state, {
        tracerEnabled: false,
        tracerId: '',
        tracerError: true,
      });
    },
  },
  defaultState
);
