import React from 'react';
import { css } from '@emotion/core';
import { WeaveTheme } from '@weave/theme-original';
import {
  IconButton,
  InfoRoundIconSmall,
  Text,
  useModalControl,
  KeyNames,
} from '@weave/design-system';
import { SoftwareInfoModal } from '../software-info-modal/software-info-modal';
import { Product, SoftwareProduct } from '../../sales-pricing.types';

interface Props {
  productData: SoftwareProduct;
  isSelected: boolean;
  getProductServiceFeeText: (product: Product) => string;
  onSoftwareProductClicked: (softwareProduct: SoftwareProduct) => void;
}

export const SoftwareCard = ({
  productData,
  isSelected,
  getProductServiceFeeText,
  onSoftwareProductClicked,
}: Props) => {
  const { modalProps, openModal } = useModalControl();
  const handleInfoButtonClicked = (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) => {
    event.stopPropagation();
    openModal();
  };

  const handleKeyDownEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const returnKeyWasPressed = event.key === KeyNames.Enter;
    if (returnKeyWasPressed) {
      onSoftwareProductClicked(productData);
    }
  };

  return (
    <>
      <div
        tabIndex={0}
        css={(theme) => [containerStyles(theme), isSelected && activeCardStyles(theme)]}
        onClick={() => onSoftwareProductClicked(productData)}
        onKeyDown={handleKeyDownEvent}
      >
        <div css={cardStyles}>
          <div css={imgContainerStyles}>
            <img src={productData.image} alt={`${productData.displayName}`} />
          </div>
          <div>
            <div css={titleAndIconContainerStyles}>
              <div>
                <Text css={titleStyles} size="large">
                  {productData.displayName}
                </Text>
                <Text css={priceTextStyles} size="small" color="light">
                  {getProductServiceFeeText(productData)}
                </Text>
              </div>
              <IconButton
                label={`${productData.displayName} Information`}
                size="small"
                onClick={handleInfoButtonClicked}
                color="light"
                css={infoButtonStyles}
              >
                <InfoRoundIconSmall />
              </IconButton>
            </div>
            <Text size="small">{productData.description}</Text>
          </div>
        </div>
      </div>

      <SoftwareInfoModal
        modalProps={modalProps}
        title={productData.displayName}
        description={productData.description}
        image={productData.image}
        features={productData.features}
      />
    </>
  );
};

const containerStyles = (theme: WeaveTheme) => css`
  margin-right: 24px;
  margin-bottom: 24px;
  border-radius: 10px;
  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: 0 0 10px ${theme.colors.weaveBlue};
  }

  @media only screen and (max-width: 450px) {
    margin-right: 0;
  }
`;
const cardStyles = css`
  width: 402px;
  min-height: 152px;
  padding: 16px;
  display: flex;
  margin: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;

  @media only screen and (max-width: 450px) {
    width: 100%;
  }
`;
const activeCardStyles = (theme: WeaveTheme) => css`
  border: 2px solid ${theme.colors.weaveBlue};
`;
const titleAndIconContainerStyles = css`
  display: flex;
  margin-bottom: 16px;
`;
const titleStyles = css`
  margin-bottom: 4px;
`;
const priceTextStyles = css`
  margin-bottom: 0;
`;
const infoButtonStyles = css`
  position: relative;
  bottom: 5px;
`;
const imgContainerStyles = css`
  margin-right: 16px;
`;
