import { handleActions } from 'redux-actions';
import { OfficeHoursApi } from './office-hours-api.model';

import {
  getOfficeHours,
  getOfficeHoursFailure,
  getOfficeHoursSuccess,
  updaateOfficeHoursFailure,
  updateOfficeHours,
  updateOfficeHoursSuccess,
  putOfficeHoursSuccess,
} from './office-hours.action';

const defaultState = {
  loading: false,
  data: {},
};

export type OfficeHoursState = {
  loading: boolean;
  data: OfficeHoursApi;
};

export const reducer = handleActions(
  {
    [getOfficeHours.toString()]: (state) => Object.assign({}, state, { loading: true }),
    [getOfficeHoursSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { data: action.payload, loading: false }),
    [getOfficeHoursFailure.toString()]: (state, action) =>
      Object.assign({}, state, { loading: false, error: action.payload }),
    [updateOfficeHours.toString()]: (state) =>
      Object.assign({}, state, { loading: true }),
    [updateOfficeHoursSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { data: action.payload, loading: false }),
    [updaateOfficeHoursFailure.toString()]: (state, action) =>
      Object.assign({}, state, { error: action.payload, loading: false }),
    [putOfficeHoursSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { data: action.payload, loading: false }),
  },
  defaultState
);
