import React, { useEffect } from 'react';
import { css } from '@emotion/core';
import {
  PrimaryButton,
  Text,
  SearchIcon,
  TextField,
  useFormField,
} from '@weave/design-system';
import { DateTimePicker } from 'react-widgets';

import { dateFormat } from '../../../helpers/utils';

const SearchText = (props) => {
  const { searchText, handleSearchChange } = props;

  const searchFieldProps = useFormField({ type: 'text', value: searchText }, [
    searchText,
  ]);

  useEffect(() => {
    handleSearchChange(searchFieldProps.value);
  }, [searchFieldProps.value]);

  return (
    <>
      <SearchIcon />
      <TextField
        name="search"
        css={css`
          margin: 0 24px 0 16px;
        `}
        label="Search"
        {...searchFieldProps}
      />
    </>
  );
};

const SearchDate = (props) => {
  const { startDate, endDate, handleStartDateChange, handleEndDateChange } = props;
  return (
    <>
      <Text as="span" size="small" weight="bold">
        Date Range
      </Text>
      <DateTimePicker
        css={css`
          margin: 0 16px;
        `}
        format={dateFormat}
        time={false}
        value={startDate}
        onChange={(newStartDate: Date) => handleStartDateChange(newStartDate)}
      />
      <DateTimePicker
        css={css`
          margin: 0 16px 0 0;
        `}
        format={dateFormat}
        time={false}
        value={endDate}
        onChange={(newEndDate: Date) => handleEndDateChange(newEndDate)}
      />
    </>
  );
};

export const SearchForm = (props) => {
  const {
    configOptions,
    searchText,
    handleSearchChange,
    handleStartDateChange,
    handleEndDateChange,
    startDate,
    endDate,
    refresh,
  } = props;
  return (
    <form
      onSubmit={refresh}
      css={css`
        display: flex;
        align-items: center;
        margin: 24px 0;
        padding-left: 32px;
      `}
    >
      {!!configOptions.searchFields.length && (
        <SearchText searchText={searchText} handleSearchChange={handleSearchChange} />
      )}

      {configOptions.showDateRange && (
        <SearchDate
          startDate={startDate}
          endDate={endDate}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
        />
      )}

      <PrimaryButton type="submit" size="tiny" onClick={refresh}>
        Filter
      </PrimaryButton>
    </form>
  );
};
