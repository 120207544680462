import React from 'react';
import { cloneDeep } from 'lodash';
import { CustomAxios, getResponseData } from '../../../../redux/axios';
import {
  multiSyncAppContainer,
  multiSyncAppName,
  multiSyncAppColor,
  accessContainer,
  containerMargin,
  pmContainer,
  containerAccess,
  containerMarginStyle,
  dataContainer,
  radioGroup,
} from './integrations.styles';
import { Access } from './access.component';
import { SyncAppAccess } from './sync-app-access.component';
import { RadioField, SpinningLoader, useFormField } from '@weave/design-system';
import { tabLoading } from '../../sync-app-card/sync-app-card.styles';
import { SyncAppAccessCard } from './sync-app-access/sync-app-access-card';
import { useSelector } from 'react-redux';
import { selectHasFeatureFlagEnabled } from '../../../../redux/actions/feature-flags';
import {
  AccessType,
  ClientLocationType,
  MultiSyncAppType,
  SourceType,
  SyncAppCount,
  SyncAppType,
} from './sync-app-access/sync-app-access.types';
import { LocationModel } from '../../../../redux/actions/location';
import { Store } from 'redux/store/store.model';

// Component for showing loader in center
export const TabLoader = () => (
  <div css={tabLoading}>
    <SpinningLoader />
  </div>
);

type Props = {
  currentLocation: LocationModel;
};

export const MultiSyncAppAccess = ({ currentLocation }: Props) => {
  const accessTypeRadioFieldProps = useFormField({ type: 'radio' });
  const [accessData, setAccessData] = React.useState<MultiSyncAppType[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  const [loadingLocations, setLoadingLocations] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any>([]);

  const [loadingDataSource, setLoadingDataSource] = React.useState<boolean>(true);
  const [dataSource, setDataSource] = React.useState<any>([]);

  const [loadingAccess, setLoadingAccess] = React.useState<boolean>(true);
  const [dataAccess, setDataAccess] = React.useState<any>([]);

  const [loadingChildrenLocations, setLoadingChildrenLocations] =
    React.useState<boolean>(true);
  const [dataChildren, setDataChildren] = React.useState<any>([]);
  const hasUnifyDataAccessMappingEnabled = useSelector((store: Store) =>
    selectHasFeatureFlagEnabled(store, 'unify-data-access-mapping')
  );

  React.useEffect(() => {
    CustomAxios.get('support/v1/client-location')
      .then(getResponseData)
      .then((response) => {
        setLoadingLocations(false);
        setData(response);
      });

    CustomAxios.get('support/v1/datasource')
      .then(getResponseData)
      .then((response) => {
        setLoadingDataSource(false);
        setDataSource(response);
      });

    CustomAxios.get('support/v1/access')
      .then(getResponseData)
      .then((response) => {
        setLoadingAccess(false);
        setDataAccess(response);
      });

    CustomAxios.get(`support/v1/location/${currentLocation.LocationID}/children`)
      .then(getResponseData)
      .then((response) => {
        setLoadingChildrenLocations(false);
        setDataChildren(response);
      });
  }, [currentLocation?.LocationID]);

  React.useEffect(() => {
    if (
      !loadingAccess &&
      !loadingDataSource &&
      !loadingLocations &&
      !loadingChildrenLocations
    ) {
      const fullData: any = [];
      for (const location of dataChildren || []) {
        const syncApps: any = [];
        for (const syncApp of dataSource?.SyncApps || []) {
          const clientLocations: any = [];
          const syncAppCopy = cloneDeep(syncApp);
          syncAppCopy.accessEnabled = false;
          syncAppCopy.accessType = 'all';

          for (const clientLocation of data || []) {
            if (clientLocation.SourceID === syncApp.SourceID) {
              const clientLocationCopy = cloneDeep(clientLocation);
              clientLocationCopy.accessEnabled = false;
              clientLocationCopy.accessType = 'all';
              for (const accessObj of dataAccess || []) {
                if (
                  accessObj.ClientLocationID === clientLocation.ClientLocationID &&
                  syncApp.SourceID === accessObj.SourceID &&
                  location.LocationID === accessObj.LocationID
                ) {
                  clientLocationCopy.accessEnabled = true;
                  clientLocationCopy.accessType = accessObj.Type;
                  clientLocationCopy.accessRules = accessObj;
                }
              }
              clientLocations.push(clientLocationCopy);
            }
          }

          for (const accessObj of dataAccess || []) {
            if (
              accessObj.ClientLocationID === null &&
              syncApp.SourceID === accessObj.SourceID &&
              location.LocationID === accessObj.LocationID
            ) {
              syncAppCopy.accessEnabled = true;
              syncAppCopy.accessType = accessObj.Type;
              syncAppCopy.accessRules = accessObj;
            }
          }
          syncAppCopy.clientLocations = clientLocations;

          syncApps.push(syncAppCopy);
        }
        location.syncApps = syncApps;
        fullData.push(location);
      }
      setAccessData(fullData);
      setLoading(false);
    }
  }, [dataAccess, data, dataSource, dataChildren]);

  const customChecked = (syncApp) => {
    if (syncApp.accessEnabled) {
      return false;
    }
    for (const clientLocation of syncApp.clientLocations) {
      if (clientLocation.accessEnabled) {
        return true;
      }
    }

    return false;
  };

  const getAccessValue = (syncApp: SyncAppType) => {
    const isCustom = syncApp && customChecked(syncApp);
    const isContactAndScheudle =
      syncApp.accessType === AccessType.ContactAndSchedule && syncApp.accessEnabled;
    const isContact =
      syncApp.accessType !== AccessType.ContactAndSchedule && syncApp.accessEnabled;
    const isNone = !syncApp.accessEnabled && !customChecked(syncApp);
    if (isCustom) return AccessType.Custom;
    if (isContactAndScheudle) return AccessType.ContactAndSchedule;
    if (isContact) return AccessType.Contact;
    if (isNone) return AccessType.None;
    return '';
  };

  const getLocationAnalyticsData = (syncApps: SyncAppType[]): SyncAppCount => {
    const enabledPmsDataTypeCounts = {
      contactsEnabled: 0,
      scheduleEnabled: 0,
    };
    syncApps.forEach((syncApp) => {
      countSingleTenentSyncApp(enabledPmsDataTypeCounts, syncApp);
      syncApp.clientLocations.length > 0 &&
        countEnabledClientLocations(enabledPmsDataTypeCounts, syncApp.clientLocations);
    });
    return enabledPmsDataTypeCounts;
  };

  const countSingleTenentSyncApp = (
    enabledPmsDataTypeCounts: SyncAppCount,
    syncApp: SyncAppType
  ) => {
    if (
      syncApp.accessEnabled &&
      syncApp.accessRules?.Type &&
      !syncApp.clientLocations.length
    ) {
      const isScheduleEnabled =
        syncApp.accessRules.Type === AccessType.ContactAndSchedule &&
        syncApp.SourceType !== SourceType.CSV &&
        syncApp.SourceType !== SourceType.CUSTOM_CONTACT;

      isScheduleEnabled
        ? enabledPmsDataTypeCounts.scheduleEnabled++
        : enabledPmsDataTypeCounts.contactsEnabled++;
    }
  };

  const countEnabledClientLocations = (
    enabledPmsDataTypeCounts: SyncAppCount,
    clientLocations: ClientLocationType[]
  ) => {
    clientLocations.forEach((clientLocation) => {
      if (clientLocation.accessEnabled && clientLocation.accessRules?.Type) {
        clientLocation.accessRules.Type === AccessType.ContactAndSchedule
          ? enabledPmsDataTypeCounts.scheduleEnabled++
          : enabledPmsDataTypeCounts.contactsEnabled++;
      }
    });
  };

  const getLocationFormattedAddress = (clientLocation: ClientLocationType): string => {
    return `${clientLocation?.Address?.value ?? ''} ${
      clientLocation?.Address2?.value ? `, ${clientLocation?.Address2?.value}` : ''
    }`;
  };

  const getSourceTypeName = (syncApp: SyncAppType): string => {
    switch (syncApp.SourceType) {
      case SourceType.CSV:
        return 'CSV';
      case SourceType.CUSTOM_CONTACT:
        return 'CUSTOM_CONTACT';
      default:
        return 'No name';
    }
  };

  if (loading) return <TabLoader />;

  return (
    <>
      <div className={multiSyncAppContainer}>
        <span className={multiSyncAppName}>{currentLocation.Name}:</span>
        <span className={multiSyncAppColor}>{accessData.length} Locations</span>
      </div>

      {accessData.map((location, index) => {
        return (
          <div key={index} className={accessContainer}>
            <Access
              name={location.Name}
              analyticsData={
                hasUnifyDataAccessMappingEnabled
                  ? getLocationAnalyticsData(location.syncApps)
                  : null
              }
            >
              <hr />
              {hasUnifyDataAccessMappingEnabled ? (
                <SyncAppAccessCard
                  loadingAccess={loadingAccess}
                  syncApps={location.syncApps}
                  getLocationAddress={getLocationFormattedAddress}
                  getSourceTypeName={getSourceTypeName}
                />
              ) : (
                <>
                  {loadingAccess && <TabLoader />}
                  {!loadingAccess &&
                    location.syncApps.map((syncApp, idx) => {
                      return (
                        <div key={idx} className={containerMarginStyle}>
                          <div className={containerMargin}>
                            <div className={pmContainer}>
                              {syncApp.SourceName} -{' '}
                              {syncApp.PM || getSourceTypeName(syncApp)}
                            </div>

                            <div className={dataContainer}>
                              <RadioField
                                {...accessTypeRadioFieldProps}
                                disabled
                                name="accessType"
                                className={radioGroup}
                                value={getAccessValue(syncApp)}
                              >
                                <RadioField.Radio value="custom">Custom</RadioField.Radio>
                                <RadioField.Radio value="all">All</RadioField.Radio>
                                <RadioField.Radio value="contact">
                                  Contact
                                </RadioField.Radio>
                                <RadioField.Radio value="none">None</RadioField.Radio>
                              </RadioField>
                            </div>
                          </div>

                          {syncApp.clientLocations.map((clientLocation) => {
                            return (
                              <div
                                className={containerAccess}
                                key={clientLocation.ClientLocationID}
                              >
                                <SyncAppAccess
                                  isClientLocation={true}
                                  locationAccess={clientLocation}
                                  location={location}
                                  syncApp={syncApp}
                                />
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                </>
              )}
            </Access>
          </div>
        );
      })}
    </>
  );
};
