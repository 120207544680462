import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { Heading, Text } from '@weave/design-system';
import { copiedStyles, clipboardWrapper } from './set-up.styles';
export const SetUp = ({ locationId }) => {
  const [copied, setCopied] = useState(false);
  const timeoutRef = useRef<number | null>(null);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        window.clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const copy = useCallback(() => {
    setCopied(true);
    timeoutRef.current = window.setTimeout(() => {
      setCopied(false);
    }, 1500);
  }, []);

  const codeSnippet = `<script defer="defer" src="https://book.getweave.com/schedule/${locationId}/widget.js"></script>`;

  return (
    <>
      <Heading
        css={css`
          margin-top: ${theme.spacing(1)};
        `}
      >
        Set Up
      </Heading>
      <Text>
        Copy the script tag and embed it on each page you want the Weave schedule widget
        to appear
      </Text>

      <CopyToClipboard
        text={codeSnippet}
        onCopy={copy}
        css={css`
          margin-top: ${theme.spacing(2)};
        `}
      >
        <div css={clipboardWrapper}>
          {copied && <span css={copiedStyles}>Copied!</span>}
          <code>{codeSnippet}</code>
        </div>
      </CopyToClipboard>
    </>
  );
};
