import React, { useState } from 'react';
import { theme } from '@weave/theme-original';
import { Tabs } from '@weave/design-system';
import { useSelector } from 'react-redux';
import { css } from '@emotion/core';

import { AppointmentTypes } from './appointment-types/appointment-types.component';
import { ProvidersManager } from './providers/providers-manager.component';
import { SetUp } from './set-up/set-up.component';
import { AppointmentRequestLog } from './appointment-request-log/appointment-request-log.component';
import { Page } from '../../shared';
import { selectCurrentLocationId } from '../../../redux/actions/location';
import { OfficeHours } from './office-hours/office-hours.component';

const widgetBufferStyle = css`
  padding-top: ${theme.spacing(1)};
  padding-bottom: ${theme.spacing(5)};
`;

export const OnlineScheduling = () => {
  const locationId = useSelector(selectCurrentLocationId);
  const [currentTab, setCurrentTab] = useState<string>('appointment-requests-log');

  return (
    <Page title="Online Scheduling">
      <Tabs initialTab="appointment-requests-log" onChange={setCurrentTab}>
        <Tabs.Bar>
          <Tabs.Tab id="appointment-requests-log" controls="appointment-requests-log">
            Appointments Request Log
          </Tabs.Tab>
          <Tabs.Tab id="office-hours" controls="office-hours">
            Office Hours
          </Tabs.Tab>
          <Tabs.Tab id="providers-manager" controls="providers-manager">
            Providers
          </Tabs.Tab>
          <Tabs.Tab id="setup" controls="setup">
            Set Up
          </Tabs.Tab>
        </Tabs.Bar>

        {currentTab === 'appointment-requests-log' && (
          <Tabs.Panel
            id="appointment-requests-log"
            controller="appointment-requests-log"
            css={widgetBufferStyle}
          >
            <AppointmentRequestLog locationId={locationId} />
          </Tabs.Panel>
        )}

        {currentTab === 'office-hours' && (
          <Tabs.Panel id="office-hours" controller="office-hours" css={widgetBufferStyle}>
            <OfficeHours />
          </Tabs.Panel>
        )}

        {currentTab === 'providers-manager' && (
          <Tabs.Panel
            id="providers-manager"
            controller="providers-manager"
            css={widgetBufferStyle}
          >
            <ProvidersManager setCurrentTab={setCurrentTab} />
          </Tabs.Panel>
        )}

        {currentTab === 'setup' && (
          <Tabs.Panel id="setup" controller="setup" css={widgetBufferStyle}>
            <SetUp locationId={locationId} />
            <AppointmentTypes />
          </Tabs.Panel>
        )}
      </Tabs>
    </Page>
  );
};
