import { css } from 'emotion';
import { weaveBlue } from '../../../shared/styles/colors';

export const component = css`
  label: component;
  width: 100%;
`;

export const box = css`
  label: associatedEvents;
  width: 25%;
  margin-left: 25px;
  margin-right: 25px;
`;

export const queueComponent = css`
  label: box1;
  width: 35%;
  margin-left: 25px;
  margin-right: 0px;
`;

export const eventBox = css`
  label: eventBox;
  height: 350px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #d4dbdf;
`;

export const selectable = css`
  label: selectedBox;
  cursor: pointer;
`;

export const dataItem = css`
  label: dataItem;
  border: 1px solid #d4dbdf;
  border-radius: 3px;
  background-color: #fafcfc;
  margin: 5px;
  padding: 5px;
  padding-left: 10px;
  width: 95%;
  margin-right: 5%;
  p {
    padding: 0px;
    margin: 0px;
  }
`;

export const writebackItem = css`
  label: writebackItem;
  border: 1px solid #d4dbdf;
  border-radius: 3px;
  background-color: #fafcfc;
  margin: 5px;
  padding: 5px;
  padding-left: 10px;
  p {
    padding: 0px;
    margin: 0px;
  }
`;

export const queueItem = css`
  label: queueItem;
  border: 1px solid #d4dbdf;
  border-radius: 3px;
  background-color: #fafcfc;
  margin: 5px;
  padding: 5px;
  padding-left: 10px;
  p {
    padding: 0px;
    margin: 0px;
  }
`;

export const selectedItem = css`
  label: selectedItem;
  border: 1px solid ${weaveBlue};
`;

export const flexStyle = css`
  label: flexStyle;
  display: flex;
`;

export const type = css`
  label: type;
  padding-right: 15px;
  text-transform: capitalize;
`;

export const clickable = css`
  label: clickable;
  color: ${weaveBlue};
  cursor: pointer;
`;

export const copied = css`
  margin-left: 5px;
  label: copied;
  color: #28a745;
`;

export const blur = css`
  label: blur;
  color: #aaa;
  margin: 10px;
`;

export const pSelected = css`
  label: test;
  width: 100%;
  text-align: right;
`;

export const left = css`
  label: left;
  width: 30%;
  text-align: left;
`;

export const right = css`
  label: left;
  width: 70%;
  text-align: right;
`;

export const smsAppt = css`
  label: smsAppt;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 2px solid #d4dbdf;
  width: 96%;
  margin-left: 5px;
`;

export const life = css`
  label: life;
  text-align: center;
  color: #aaa;
`;

export const metaDetail = css`
  label: metaDetail;
  width: 100%;
  border: 1px solid;
  background-color: white;
`;

export const smsWriteback = css`
  display: flex;
  .left {
    width: 30%;
    padding-right: 8px;
    font-weight: bold;
    text-align: right;
  }
`;
