import React from 'react';
import { useEffect } from 'react';
import { cx } from 'emotion';

import { PatientAppointmentsModel } from './patient-appointments.model';
import { AppointmentsByPatientPayload } from '../../../../../redux/actions/appointments/appointments-by-patient.action';
import { PatientAppointmentListItem } from './patient-appointment-list-item.component';
import * as styles from '../patient-list.style';

interface Props {
  currentLocation: string;
  timezone: string;
  appointments: PatientAppointmentsModel[];
  personID: string;
  appointmentsByPatient: (payload: AppointmentsByPatientPayload) => void;
  appointmentsByPatientReset: () => void;
}

export const PatientAppointments = (props: Props) => {
  const {
    currentLocation,
    timezone,
    appointments,
    personID,
    appointmentsByPatient,
    appointmentsByPatientReset,
  } = props;

  useEffect(() => {
    appointmentsByPatientReset();
    return appointmentsByPatientReset;
  }, [currentLocation]);

  useEffect(() => {
    if (personID) {
      appointmentsByPatient({ personID });
    }
  }, [personID]);

  return (
    <div className={styles.cardComponent}>
      <div className={styles.flexDisplay}>
        <p className={cx(styles.listHeader, styles.leftListStyle)}>Appointment History</p>
      </div>
      <div className={styles.listBox}>
        {!appointments.length && <p className={styles.noData}>No Data</p>}
        {appointments.map((appt) => (
          <PatientAppointmentListItem
            key={appt.AppointmentID}
            appt={appt}
            timezone={timezone}
            currentLocation={currentLocation}
          />
        ))}
      </div>
    </div>
  );
};
