import { createAction, handleActions } from 'redux-actions';
import { get } from 'lodash';
import { call, put, takeEvery } from 'redux-saga/effects';
import { CustomAxios } from '../../../axios';

export type AnalyticsSummaryState = {
  syncAppVersion: string;
  lastSyncedAt: string;
  lastReconciledAt: string;
  analyticsEtlEnabled: boolean;
  missingSnapshots: any[];
};

export const analyticsSummaryInitialState = {
  syncAppVersion: '',
  lastSyncedAt: '',
  lastReconciledAt: '',
  analyticsEtlEnabled: false,
  missingSnapshots: [],
};

export const getAnalyticsSummary = createAction('GET_ANALYTICS_SUMMARY');
export const getAnalyticsSummarySuccess = createAction<AnalyticsSummaryState>(
  'GET_ANALYTICS_SUMMARY_SUCCESS'
);
export const getAnalyticsSummaryFailure = createAction<Error>(
  'GET_ANALYTICS_SUMMARY_FAILURE'
);

export const getAnalyticsSummaryReducer = handleActions<AnalyticsSummaryState>(
  {
    [getAnalyticsSummary.toString()]: (state, action) => ({ ...state, loading: true }),
    [getAnalyticsSummarySuccess.toString()]: (state, action) => ({
      ...state,
      loading: false,
      data: action.payload,
    }),
    [getAnalyticsSummaryFailure.toString()]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
  },
  analyticsSummaryInitialState
);

export const selectAnalyticsSummary = (state) => get(state, 'analytics.summary.data', {});
export const selectAnalyticsSummaryLoading = (state) =>
  get(state, 'analytics.summary.loading', true);

export const handleGetAnalyticsSummary = function* (action) {
  try {
    const response = yield call(
      CustomAxios.get,
      '/support/v1/analytics-admin/location/summary'
    );
    yield put(getAnalyticsSummarySuccess(response.data.data));
  } catch (error: any) {
    yield put(getAnalyticsSummaryFailure(error));
  }
};

export const getAnalyticsSummarySaga = function* () {
  yield takeEvery(getAnalyticsSummary.toString(), handleGetAnalyticsSummary);
};
