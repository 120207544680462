import { all } from 'redux-saga/effects';
import {
  getOnboarderLinksSaga,
  getOnboardingTasksSaga,
  markTaskAsCompleteSaga,
  saveExplanationOnTaskSaga,
  saveOnboarderLinksSaga,
  getOnboardersSaga,
  deleteOnboarderSaga,
  updateOnboarderLinksSaga,
} from './onboarding-tasks.action';

export const onboardingTasksSaga = function* () {
  yield all([
    markTaskAsCompleteSaga(),
    getOnboardingTasksSaga(),
    saveExplanationOnTaskSaga(),
    getOnboarderLinksSaga(),
    saveOnboarderLinksSaga(),
    getOnboardersSaga(),
    deleteOnboarderSaga(),
    updateOnboarderLinksSaga(),
  ]);
};

export {
  onboardingGetTasks,
  markTaskAsComplete,
  onboardingSetCurrentTask,
  saveExplanationOnTask,
  getOnboarderSchedulingLinks,
  saveOnboarderSchedulingLinks,
  getOnboarders,
  deleteOnboarder,
  updateOnboarderLinks,
} from './onboarding-tasks.action';
export { reducer as onboardingTasksReducer } from './onboarding-tasks.reducer';
