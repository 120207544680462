import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getFirebaseTokenFirebase } from './firebase-token.api';
import firebase from '../../../config/outage-alerts-firebase-config';
import { setFirebaseToken } from './firebase-token.actions';
import {
  FirebaseActionTypes,
  GetAndAuthenticateFirebaseTokenAction,
  LogoutFireBaseAction,
} from './firebase-token.types';
import { createRequestSaga } from '@weave/alert-system';
import { AuthStorage } from '../auth/auth.types';

//Firebase Saga
export const getAndVerifyFirebaseToken =
  createRequestSaga<GetAndAuthenticateFirebaseTokenAction>({
    key: FirebaseActionTypes.GET_AND_AUTHENTICATE_FIREBASE_TOKEN,
    onError: (error) => error.message,
    saga: function* () {
      try {
        const firebaseToken = yield call(getFirebaseTokenFirebase);
        if (firebaseToken) {
          try {
            if (firebaseToken) {
              const auth = firebase.auth();
              const result = yield call(
                [auth, auth.signInWithCustomToken],
                firebaseToken
              );
              if (result?.user?.uid) {
                localStorage.setItem(AuthStorage.firebase_token, firebaseToken);
                yield put(
                  setFirebaseToken({
                    firebaseToken: firebaseToken,
                  })
                );
              } else {
                onErrorDispatch();
                throw new Error('Unauthorized to Access Live Alerts');
              }
            }
          } catch (error: any) {
            console.error(error);
            onErrorDispatch();
            throw new Error('Unauthorized to Access Live Alerts');
          }
        }
      } catch (error: any) {
        console.error(error);
        onErrorDispatch();
        throw new Error('Something went wrong while Accessing Alerts');
      }
    },
  });

//Firebase Logout Saga
export const logoutFirebaseSaga = createRequestSaga<LogoutFireBaseAction>({
  key: FirebaseActionTypes.LOGOUT_FIREBASE,
  onError: (error) => error.message,
  saga: function* () {
    try {
      const auth = firebase.auth();
      yield call([auth, auth.signOut]); //firebase signout has no response
      localStorage.removeItem(AuthStorage.firebase_token);
      yield put(setFirebaseToken({ firebaseToken: '' }));
    } catch (error: any) {
      console.error(error);
      onErrorDispatch();
      throw new Error('Opps! Something went wrong');
    }
  },
});

/**
 * @desc : Function is defined to set Firebase token empty on error
 */
const onErrorDispatch = () => {
  setFirebaseToken({
    firebaseToken: '',
  });
};

export const firebaseTokenSaga = function* () {
  yield all([
    takeEvery(
      FirebaseActionTypes.GET_AND_AUTHENTICATE_FIREBASE_TOKEN,
      getAndVerifyFirebaseToken
    ),
    takeEvery(FirebaseActionTypes.LOGOUT_FIREBASE, logoutFirebaseSaga),
  ]);
};
