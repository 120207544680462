import React from 'react';
import { css } from '@emotion/core';
import { ReviewsOrderItemContainer } from './reviews-order-item.container';
import { WeaveTheme } from '@weave/theme-original';

const arrayMove = (arr: any[], fromIndex: number, toIndex: number): any[] => {
  const newArray = [...arr];
  const element = newArray[fromIndex];
  newArray.splice(fromIndex, 1);
  newArray.splice(toIndex, 0, element);
  return newArray;
};

interface Props {
  items: string[];
  onChange: (event: any) => void;
}

interface State {
  items: string[];
}

export class ReviewsOrderList extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.moveItem = this.moveItem.bind(this);

    this.state = {
      items: [...props.items],
    };
  }

  moveItem = (dragIndex, hoverIndex) => {
    const { items } = this.state;
    const reordered = arrayMove(items, dragIndex, hoverIndex);
    this.setState({ items: reordered });
    this.props.onChange(reordered);
  };

  render() {
    return (
      <div
        css={(theme: WeaveTheme) => css`
          background: ${theme.colors.gray[100]};
          border: solid 1px ${theme.colors.gray[300]};
          margin: ${theme.spacing(1, 0)};
        `}
      >
        {this.state.items.map((text, index) => {
          const args = {
            text,
            index,
            moveItem: this.moveItem,
          };

          return <ReviewsOrderItemContainer {...args} key={`${text}-${index}`} />;
        })}
      </div>
    );
  }
}
