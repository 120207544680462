import { createRequestSaga } from '@weave/alert-system';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  CreateDepartmentDto,
  DepartmentActions,
  DepartmentActionTypes,
  RequestListAction,
  RequestGetAction,
  RequestCreateAction,
  RequestDeleteAction,
  RequestUpdateAction,
} from './department.types';

import { selectDepartment } from './department.selectors';
import { OnCompleteFn, ResolvedPromise } from '../../store/store-utils';
import {
  addDepartmentsToLocation,
  removeDepartmentsFromLocation,
} from '../location/location-entities/location-departments/location-departments.actions';
import { selectCurrentLocationId } from '../location/current-location';
import { createCachedRequestSaga } from '../cached-actions';
import { DepartmentModel } from '../../../apis/department/department.types';
import departmentApi from '../../../apis/department/department.api';

export const requestListDepartments = (
  onComplete?: OnCompleteFn<DepartmentModel[]>
): DepartmentActions => ({
  type: DepartmentActionTypes.RequestList,
  payload: undefined,
  onComplete,
});
export const requestGetDepartment = (
  departmentId: string,
  onComplete?: OnCompleteFn<DepartmentModel>
): DepartmentActions => ({
  type: DepartmentActionTypes.RequestGet,
  payload: departmentId,
  onComplete,
});
export const requestCreateDepartment = (
  department: CreateDepartmentDto,
  onComplete?: OnCompleteFn<DepartmentModel>
): DepartmentActions => ({
  type: DepartmentActionTypes.RequestCreate,
  payload: department,
  onComplete,
});
export const requestDeleteDepartment = (
  id: string,
  onComplete?: OnCompleteFn<string>
): DepartmentActions => ({
  type: DepartmentActionTypes.RequestDelete,
  payload: id,
  onComplete,
});
export const requestUpdateDepartment = (
  id: string,
  update: Partial<DepartmentModel>,
  onComplete?: OnCompleteFn<DepartmentModel>
): DepartmentActions => ({
  type: DepartmentActionTypes.RequestUpdate,
  payload: { id, update },
  onComplete,
});

export const addDepartment = (departments: DepartmentModel[]): DepartmentActions => ({
  type: DepartmentActionTypes.Add,
  payload: departments,
});
export const removeDepartment = (id: string): DepartmentActions => ({
  type: DepartmentActionTypes.Remove,
  payload: id,
});
export const updateDepartment = (department: DepartmentModel): DepartmentActions => ({
  type: DepartmentActionTypes.Update,
  payload: department,
});

const listDepartmentsSaga = createCachedRequestSaga<RequestListAction>({
  key: DepartmentActionTypes.RequestList,
  displayErrors: true,
  onError: (err) => `Failed to load departments: ${err.message}`,
  saga: function* () {
    const departments: ResolvedPromise<ReturnType<typeof departmentApi.listDepartments>> =
      yield call(departmentApi.listDepartments);
    const locationId: ReturnType<typeof selectCurrentLocationId> = yield select(
      selectCurrentLocationId
    );
    yield put(addDepartment(departments));
    yield put(
      addDepartmentsToLocation(locationId, departments?.map((dep) => dep.ID) ?? [])
    );
  },
});

const getDepartmentSaga = createCachedRequestSaga<RequestGetAction>({
  key: DepartmentActionTypes.RequestGet,
  displayErrors: true,
  onError: (err) => `Failed to load department: ${err.message}`,
  saga: function* ({ payload, onComplete }) {
    const locationId: ReturnType<typeof selectCurrentLocationId> = yield select(
      selectCurrentLocationId
    );
    const department: ResolvedPromise<ReturnType<typeof departmentApi.getDepartment>> =
      yield call(departmentApi.getDepartment, payload);
    if (!department) {
      return;
    }
    yield put(addDepartment([department]));
    yield put(addDepartmentsToLocation(locationId, [department.ID]));
    if (onComplete) {
      yield call(onComplete, department);
    }
  },
});

const addDepartmentSaga = createRequestSaga<RequestCreateAction>({
  key: DepartmentActionTypes.RequestCreate,
  displayErrors: true,
  onError: (err) => `Failed to create department: ${err.message}`,
  saga: function* ({ payload, onComplete }) {
    const department: ResolvedPromise<ReturnType<typeof departmentApi.createDepartment>> =
      yield call(departmentApi.createDepartment, payload);
    const locationId: ReturnType<typeof selectCurrentLocationId> = yield select(
      selectCurrentLocationId
    );
    yield put(addDepartment([department]));
    yield put(addDepartmentsToLocation(locationId, [department.ID]));
    if (onComplete) {
      yield call(onComplete, department);
    }
  },
});

const updateDepartmentSaga = createRequestSaga<RequestUpdateAction>({
  key: DepartmentActionTypes.RequestUpdate,
  displayErrors: true,
  onError: (err) => `Failed to update department: ${err.message}`,
  saga: function* ({ payload, onComplete }) {
    const { department }: ReturnType<ReturnType<typeof selectDepartment>> = yield select(
      selectDepartment(payload.id)
    );
    if (!department) {
      return;
    }
    const newDepartment = { ...department, ...payload.update };
    yield call(departmentApi.updateDepartment, payload.id, newDepartment);
    yield put(updateDepartment(newDepartment));
    if (onComplete) {
      yield call(onComplete, newDepartment);
    }
  },
});

const deleteDepartmentSaga = createRequestSaga<RequestDeleteAction>({
  key: DepartmentActionTypes.RequestDelete,
  displayErrors: true,
  onError: (err) => `Failed to delete department: ${err.message}`,
  saga: function* ({ payload, onComplete }) {
    const locationId: ReturnType<typeof selectCurrentLocationId> = yield select(
      selectCurrentLocationId
    );
    yield call(departmentApi.deleteDepartment, payload);
    yield put(removeDepartment(payload));
    yield put(removeDepartmentsFromLocation(locationId, [payload]));
    if (onComplete) {
      yield call(onComplete, payload);
    }
  },
});

const rootDepartmentsSaga = function* () {
  yield all([
    takeLatest(DepartmentActionTypes.RequestList, listDepartmentsSaga),
    takeLatest(DepartmentActionTypes.RequestGet, getDepartmentSaga),
    takeLatest(DepartmentActionTypes.RequestUpdate, updateDepartmentSaga),
    takeLatest(DepartmentActionTypes.RequestCreate, addDepartmentSaga),
    takeLatest(DepartmentActionTypes.RequestDelete, deleteDepartmentSaga),
  ]);
};

export const departmentsSaga = function* () {
  yield all([rootDepartmentsSaga()]);
};
