import React, { ReactNode } from 'react';
import { css } from '@emotion/core';
import { WeaveTheme } from '@weave/theme-original';
import { Text } from '@weave/design-system';
import { currencyFormatter } from '../pricing-calculator.helper';

interface Props {
  title: string;
  total: number;
  children?: ReactNode;
  testId?: string;
}

export const ChargeList = ({ title, total, children, testId }: Props) => {
  return (
    <div css={listSectionStyles}>
      <div css={lineItem}>
        <Text size="medium" weight="bold" css={marginZero}>
          {title}
        </Text>
        <Text size="medium" weight="bold" css={marginZero} data-testid={`${testId}Total`}>
          {currencyFormatter(total)}
        </Text>
      </div>
      <hr css={horizontalLineStyles} />

      {children}
    </div>
  );
};

interface ListItemProps {
  name: string;
  amount: string;
}

export const ListItem = ({ name, amount }: ListItemProps) => {
  return (
    <div css={lineItem}>
      <Text size="small">{name}</Text>
      <Text size="small" weight="bold" css={(theme) => listItemMoneyStyles(theme)}>
        {amount}
      </Text>
    </div>
  );
};

ChargeList.ListItem = ListItem;

const listSectionStyles = css`
  margin-bottom: 24px;
`;
const lineItem = css`
  display: flex;
  justify-content: space-between;
`;
const horizontalLineStyles = css`
  margin-top: 2px;
`;
const marginZero = css`
  margin: 0;
`;
const listItemMoneyStyles = (theme: WeaveTheme) => css`
  color: ${theme.colors.weaveBlue};
`;
