import { createAction } from 'redux-actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { CustomAxios } from '../../axios';
import { showError } from '@weave/alert-system';
import UserListModel from '../../../components/users/UserListModel';
import { selectCurrentLocationId } from '../location/current-location';

export const getConnectedUsers = createAction('GET_CONNECTED_USERS');
export const getConnectedUsersSuccess = createAction('GET_CONNECTED_USERS_SUCCESS');
export const getConnectedUsersFailure = createAction('GET_CONNECTED_USERS_FAILURE');

export const handleGetConnectedUsers = function* () {
  try {
    const currentLocationId: ReturnType<typeof selectCurrentLocationId> = yield select(
      selectCurrentLocationId
    );
    // get current desktop client connections
    const response = yield call(
      CustomAxios.get,
      `support/v1/connections/${currentLocationId}/desktopclient`
    );
    const desktopClients = response.data.data;

    // remove duplicate users that are connected
    const clientUserIds: string[] = [];
    for (const client of desktopClients) {
      if (client.UserID && clientUserIds.indexOf(client.UserID) === -1) {
        clientUserIds.push(client.UserID);
      }
    }

    // get list of users
    const userResponse = yield call(CustomAxios.get, `support/v1/locations/users`);
    const userList = userResponse.data.data;
    const users: UserListModel[] = [];
    // match the user info with the desktop client UserID
    for (const clientUserId of clientUserIds) {
      for (const user of userList) {
        if (clientUserId === user.UserID) {
          users.push(user);
        }
      }
    }
    yield put(getConnectedUsersSuccess(users));
  } catch {
    yield put(showError('Could not get connected users. Please try again.'));
    yield put(getConnectedUsersFailure());
  }
};

export const getConnectedUsersSaga = function* () {
  yield takeLatest(getConnectedUsers.toString(), handleGetConnectedUsers);
};
