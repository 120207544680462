export interface DesktopReleaseModel extends PublishDetails {
  ID: string;
  Disabled: boolean;
  Information: Information;
  Version: Version;
  Meta: Meta;
  Filters: { location: string[]; '-location': string[] }; // also has other fields
  DeletedAt: string;
  UpdatedLocations: number;
}

interface Information {
  name: string;
  update_id_suffix: string;
  architecture: string;
  os: string;
  filename: string;
  signature: string;
  release_notes: string;
  created_at: string;
  position: string;
}

interface Version {
  release_channel: number;
  major: number;
  minor: number;
  maintenance: number;
}

interface Meta {
  force?: string;
  message?: string;
  ziptype?: string;
  required?: string;
}

export enum ReleaseTarget {
  web = 'web',
  windows = 'win32',
  osx = 'osx',
}

export interface OSFilters {
  os: string;
  opsys: string[];
  excludedVersions: string[];
}

export interface PublishDetails {
  ReleaseTime: string;
  ReleaseBatchSize: number;
  ReleaseInterval: string; // This field corresponds to the golang Duration type. (example: 6h)
}
