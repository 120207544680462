import React from 'react';
import { CheckboxField, useForm } from '@weave/design-system';
import { checkBoxFieldContainer, mRightSpacing } from '../integrations.styles';
import { CheckFieldValuesType } from './sync-app-access.types';

type Props = {
  fieldValues: CheckFieldValuesType;
  sourceType?: number | undefined;
  accessEnabled: boolean;
  hasMultipleClientLocations?: boolean;
  isChildLocation?: boolean;
};

export const SyncAppAccessCheckBoxField = ({
  fieldValues,
  sourceType,
  accessEnabled,
  hasMultipleClientLocations,
  isChildLocation,
}: Props) => {
  const { getFieldProps } = useForm({
    fields: {
      contactField: {
        type: 'checkbox',
        value: accessEnabled && fieldValues?.contacts,
      },
      scheduleField: {
        type: 'checkbox',
        value: accessEnabled && fieldValues?.schedule,
      },
    },
  });

  return (
    <div className={checkBoxFieldContainer(!!isChildLocation)}>
      <CheckboxField
        {...getFieldProps('contactField')}
        label={hasMultipleClientLocations ? 'Select All Contacts' : 'Contacts'}
        css={mRightSpacing}
        disabled
      />
      {!sourceType ? (
        <CheckboxField
          {...getFieldProps('scheduleField')}
          label={hasMultipleClientLocations ? 'Select All Schedule' : 'Schedule'}
          disabled
        ></CheckboxField>
      ) : null}
    </div>
  );
};
