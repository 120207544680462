import React from 'react';

import { BlacklistModal } from './blacklist-modal.component';
import { PrimaryButton } from '@weave/design-system';

interface Props {
  removeEmailFromBlacklist: (emailAddress: string) => void;
}

interface State {
  modalOpen: boolean;
}

export class BlacklistModalButton extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { modalOpen: false };
  }

  openBlacklistModal = () => {
    this.setState({ modalOpen: true });
  };

  closeBlacklistModal = () => {
    this.setState({ modalOpen: false });
  };

  removeEmailFromBlacklist = (emailAddress: string) => {
    this.props.removeEmailFromBlacklist(emailAddress);
    this.closeBlacklistModal();
  };

  render() {
    return (
      <span>
        <PrimaryButton onClick={this.openBlacklistModal}>
          Remove Email From Denylist
        </PrimaryButton>
        <BlacklistModal
          isOpen={this.state.modalOpen}
          onClose={this.closeBlacklistModal}
          removeEmailFromBlacklist={this.removeEmailFromBlacklist}
        />
      </span>
    );
  }
}
