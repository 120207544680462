import React from 'react';
import { cx } from 'emotion';
import moment from 'moment-timezone';

import * as styles from '../../patient-list.style.js';
import { RecallModel } from '../../../../../../models/recall-history.model';
import { dateTimeZoneFormat } from '../../../../../../helpers/utils';

interface PatientRecallProps {
  currentLocation: string;
  timezone: string;
  personID: string;
  recallHistory: RecallModel[];
  getRecallHistory: (payload: string) => void;
  recallHistoryReset: () => void;
}

export class PatientRecall extends React.Component<PatientRecallProps, {}> {
  componentDidMount() {
    if (this.props.personID) {
      this.props.getRecallHistory('?personID=' + this.props.personID);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.personID !== this.props.personID) {
      this.props.getRecallHistory('?personID=' + this.props.personID);
    }
  }

  componentWillUnmount() {
    this.props.recallHistoryReset();
  }

  render() {
    return (
      <div className={styles.cardComponent}>
        <div className={styles.flexDisplay}>
          <p className={cx(styles.listHeader, styles.leftListStyle)}>Recall History</p>
          <p className={cx(styles.listHeader, styles.rightListStyle)}>
            {this.props.recallHistory.length}
          </p>
        </div>
        <div className={styles.listBox}>
          {!this.props.recallHistory.length && <p className={styles.noData}>No Data</p>}
          {this.props.recallHistory.map((recall) => (
            <div key={recall.EventID} className={styles.listStyle}>
              <div className={styles.flexDisplay}>
                <div className={styles.leftListStyle}>
                  <p>{recall.Description}</p>
                </div>
                <div className={styles.rightListStyle}>
                  <p>
                    <strong>
                      {moment(recall.EventTime.seconds * 1000)
                        .tz(this.props.timezone)
                        .format(dateTimeZoneFormat)}
                    </strong>
                  </p>
                  <p>Event ID: {recall.EventID}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
