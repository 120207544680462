import { createSelector } from 'reselect';
import { selectLocations } from '../../locations/locations.selectors';
import { selectCurrentLocationId } from '../../current-location';

export const selectLocationDeviceIds = (locationId: string) =>
  createSelector(selectLocations, (locations) => locations[locationId]?.deviceIds);

export const selectCurrentLocationDeviceIds = createSelector(
  selectLocations,
  selectCurrentLocationId,
  (locations, currentLocationId) => locations[currentLocationId]?.deviceIds
);
