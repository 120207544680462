import { connect } from 'react-redux';

import { LocationDashboard } from './location-dashboard/location-dashboard.component';
import { removeEmailFromBlacklist } from '../../../redux/actions/blacklist/blacklist.action';
import {
  getWritebacks,
  getSyncs,
  getAutoSMSMessages,
  getAutoEmailMessages,
} from '../../../redux/actions/smart-alerts/smart-alerts.action';
import { selectCurrentLocationId } from '../../../redux/actions/location/current-location';

export const LocationDashboardContainer = connect(
  (state: any, ownProps: any) => ({
    autoEmailMessageData: state.smartAlerts.autoEmailMessages,
    autoSMSMessageData: state.smartAlerts.autoSMSMessages,
    writebacksData: state.smartAlerts.writebacks,
    loading: state.smartAlerts.loading,
    locationID: selectCurrentLocationId(state),
    syncData: state.smartAlerts.syncs,
  }),
  (dispatch) => ({
    removeEmailFromBlacklist: (email) => dispatch(removeEmailFromBlacklist(email)),
    getWritebacksAlertData: (locationID: string, searchParams: any) =>
      dispatch(
        getWritebacks({
          locationID,
          searchParams,
        })
      ),
    getSyncsAlertData: (locationID: string, searchParams: any) =>
      dispatch(
        getSyncs({
          locationID,
          searchParams,
        })
      ),
    getAutoSMSMessagesAlertData: (locationID: string, searchParams: any) =>
      dispatch(
        getAutoSMSMessages({
          locationID,
          searchParams,
        })
      ),
    getAutoEmailMessagesAlertData: (locationID: string, searchParams: any) =>
      dispatch(
        getAutoEmailMessages({
          locationID,
          searchParams,
        })
      ),
  })
)(LocationDashboard);
