import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAlert } from '@weave/alert-system';
import { WeaveTheme } from '@weave/theme-original';
import {
  useModalControl,
  Heading,
  PrimaryButton,
  TrashIcon,
  SyncIcon,
  ButtonBar,
} from '@weave/design-system';
import { css } from '@emotion/core';

import { Mailbox } from '../../../redux/actions/voice-mailboxes/voice-mailboxes.types';
import { ConditionalLoader } from '../../shared/loader/conditional-loader.component';
import { SettingsTextField } from './settings-text-field.component';
import { CustomAxios } from '../../../redux/axios';
import { MailboxUpdates } from './voice-mailboxes.component';
import { SettingsToggle } from './settings-toggle.component';
import { ConfirmationModal } from '../../shared/confirmation-modal/confirmation-modal.component';
import { selectHasWeaveAcl } from '../../../redux/actions/auth/auth.selectors';
import { Store } from '../../../redux/store/store.model';
import { CoreACLs } from '../../../redux/actions/auth/auth.types';

interface Props {
  mailbox?: Mailbox;
  updateMailbox: (mailox: Mailbox, type: MailboxUpdates) => void;
  updatingName: boolean;
  updatingPin: boolean;
  updatingEmail: boolean;
  updatingMobile: boolean;
  updatingPlayMessageDate: boolean;
  updatingReceiveNotifications: boolean;
}

const commaSepartedStringToArray = (s: string) => {
  // Get rid of spaces
  s = s.replace(/\s+/g, '');

  // Split into array on commas
  const sArray = s.split(',');

  if (!sArray[0]) {
    // Get rid of empty string
    sArray.pop();
  }

  return sArray;
};

export const MailboxSettingsSection = ({
  mailbox,
  updateMailbox,
  updatingName,
  updatingPin,
  updatingEmail,
  updatingMobile,
  updatingPlayMessageDate,
  updatingReceiveNotifications,
}: Props) => {
  const [editingName, setEditingName] = useState(false);
  const [editingEmail, setEditingEmail] = useState(false);
  const [editingPin, setEditingPin] = useState(false);
  const [editingMobile, setEditingMobile] = useState(false);
  const alerts = useAlert();
  const { modalProps, triggerProps } = useModalControl();
  const canPurgeVoicemails = useSelector(
    (store: Store) => selectHasWeaveAcl(store, CoreACLs.SIPSTATUSWRITE) // Need to switch to a VOICEMAILWRITE ACL once it exists
  );

  const onNameSave = (name: string) => {
    if (!name) {
      return;
    }

    updateMailbox(
      {
        ...mailbox!,
        name,
      },
      MailboxUpdates.NameUpdate
    );

    setEditingName(false);
  };

  const onPinSave = (pin: string) => {
    if (+pin < 1000) {
      alerts.error('PIN must be a number greater than or equal to 1000');
      return;
    }

    updateMailbox(
      {
        ...mailbox!,
        pin,
      },
      MailboxUpdates.PinUpdate
    );

    setEditingPin(false);
  };

  const onEmailSave = (email: string) => {
    const newEmails = commaSepartedStringToArray(email);

    updateMailbox(
      {
        ...mailbox!,
        email: newEmails,
      },
      MailboxUpdates.EmailUpdate
    );

    setEditingEmail(false);
  };

  const onMobileSave = (mobile: string) => {
    const newMobileNumbers = commaSepartedStringToArray(mobile);

    updateMailbox(
      {
        ...mailbox!,
        mobileNumber: newMobileNumbers,
      },
      MailboxUpdates.MobileUpdate
    );

    setEditingMobile(false);
  };

  const onPurgeVoicemails = async () => {
    try {
      await CustomAxios.delete(
        `/support/v1/voicemail/mailbox/${mailbox?.mailboxID}/purge`,
        {
          data: {
            new: true,
            saved: true,
          },
        }
      );
      alerts.success('Mailbox purged successfully!');
    } catch {
      alerts.error('Mailbox purge failed');
    }

    modalProps.onClose();
  };

  const onResyncMWIClick = async () => {
    try {
      await CustomAxios.post(
        `/support/v1/voicemail/mailbox/${mailbox?.mailboxID}/updatemwi`
      );
      alerts.success('MWI resync was successfull!');
    } catch {
      alerts.error('MWI resync failed');
    }
  };

  return (
    <>
      <ConfirmationModal
        title={`Are you sure you want to delete all voicemails in the ${
          mailbox?.name ?? 'selected'
        } mailbox?`}
        onConfirmClick={onPurgeVoicemails}
        onRequestClose={modalProps.onClose}
        confirmBtnColor="red"
        confirmBtnTitle="Delete Voicemails"
        isOpen={modalProps.show}
      />
      <Heading
        level={2}
        css={(theme: WeaveTheme) => css`
          margin-top: ${theme.spacing(4)};
        `}
      >
        Mailbox Settings
      </Heading>
      <hr />
      <ConditionalLoader loading={!mailbox}>
        <ButtonBar
          css={(theme: WeaveTheme) => css`
            margin-top: ${theme.spacing(1)};
            padding-right: 30px;
            width: ${canPurgeVoicemails ? 450 : 225}px;
            margin-left: auto;
          `}
        >
          {canPurgeVoicemails && (
            <PrimaryButton
              size="small"
              onClick={triggerProps.onClick}
              css={(theme: WeaveTheme) =>
                css`
                  margin-right: ${theme.spacing(1)};
                `
              }
            >
              Purge Voicemails
              <TrashIcon></TrashIcon>
            </PrimaryButton>
          )}
          <PrimaryButton size="small" onClick={onResyncMWIClick}>
            Resync MWI
            <SyncIcon></SyncIcon>
          </PrimaryButton>
        </ButtonBar>
        <div
          css={(theme: WeaveTheme) => css`
            margin: ${theme.spacing(2, 0)};
          `}
        >
          <SettingsTextField
            editing={editingName}
            label="Name:"
            isUpdating={updatingName}
            defaultValue={mailbox?.name ?? ''}
            onCancelClick={() => {
              setEditingName(false);
            }}
            onEditClick={() => {
              setEditingName(true);
            }}
            onSaveClick={onNameSave}
          />
          <SettingsTextField
            editing={editingPin}
            label="PIN:"
            isUpdating={updatingPin}
            type="number"
            defaultValue={mailbox?.pin ?? ''}
            onCancelClick={() => {
              setEditingPin(false);
            }}
            onEditClick={() => {
              setEditingPin(true);
            }}
            onSaveClick={onPinSave}
          />
          <SettingsTextField
            editing={editingEmail}
            label="Notification Email(s):"
            isUpdating={updatingEmail}
            defaultValue={(mailbox?.email ?? []).join(', ')}
            onCancelClick={() => {
              setEditingEmail(false);
            }}
            onEditClick={() => {
              setEditingEmail(true);
            }}
            onSaveClick={onEmailSave}
          />
          <SettingsTextField
            editing={editingMobile}
            label="Notification SMS Number(s):"
            isUpdating={updatingMobile}
            defaultValue={(mailbox?.mobileNumber ?? []).join(', ')}
            onCancelClick={() => {
              setEditingMobile(false);
            }}
            onEditClick={() => {
              setEditingMobile(true);
            }}
            onSaveClick={onMobileSave}
          />
          <div
            css={(theme: WeaveTheme) => css`
              margin-top: ${theme.spacing(1)};
            `}
          >
            <SettingsToggle
              label="Play Message Date"
              isChecked={mailbox?.playMsgDate ?? false}
              isUpdating={updatingPlayMessageDate}
              onChange={(isSelected: boolean) => {
                updateMailbox(
                  {
                    ...mailbox!,
                    playMsgDate: isSelected,
                  },
                  MailboxUpdates.PlayMessageDateUpdate
                );
              }}
            />
          </div>
          <div>
            <SettingsToggle
              label="Receive Notifications"
              isChecked={mailbox?.sendNotification ?? false}
              isUpdating={updatingReceiveNotifications}
              onChange={(isSelected: boolean) => {
                updateMailbox(
                  {
                    ...mailbox!,
                    sendNotification: isSelected,
                  },
                  MailboxUpdates.ReceiveNotificationsUpdate
                );
              }}
            />
          </div>
        </div>
      </ConditionalLoader>
    </>
  );
};
