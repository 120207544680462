import { createSelector } from 'reselect';
import { LocationsActionTypes } from './locations.types';
import { Store } from '../../../store/store.model';
import { createRequestStateSelector } from '@weave/alert-system';

export const selectLocationsCreating = createRequestStateSelector(
  LocationsActionTypes.RequestCreateLocation
);
export const selectLocationsUpdating = createRequestStateSelector(
  LocationsActionTypes.RequestUpdateLocation
);

export const selectLocations = (state: Store) => state.locations;

export const selectLocationsArray = createSelector(selectLocations, (locations) =>
  Object.values(locations)
);
export const selectLocation = (locationId: string) =>
  createSelector(selectLocations, (locations) => locations[locationId]);
