import { handleActions } from 'redux-actions';
import { sortBy } from 'lodash';
import { createSelector } from 'reselect';

import {
  createFeatureFlagSuccess,
  getFeatureFlags,
  getFeatureFlagsSuccess,
  saveFeatureFlagsSuccess,
} from './feature-flags.action';
import FeatureFlagModel from '../../../components/settings/feature-flags-container/feature-flag.model';
import { Store } from '../../../redux/store/store.model';

export interface FeatureFlagsState {
  locationId?: string;
  data: FeatureFlagModel[];
  loading: boolean;
}

const featureFlagDefaultState: FeatureFlagsState = {
  data: [],
  loading: false,
};

export const selectFeatureFlags = ({ featureFlags }: Store): FeatureFlagModel[] =>
  featureFlags.data;

export const selectFeatureFlagLoaded = createSelector(
  selectFeatureFlags,
  (state, featureFlagName: string) => featureFlagName,
  (featureFlags, flag): boolean => featureFlags.hasOwnProperty(flag)
);

export const selectHasFeatureFlagEnabled = createSelector(
  selectFeatureFlags,
  (state, featureFlagName: string) => featureFlagName,
  (featureFlags, featureFlagName): boolean => {
    const flag = featureFlags.find(
      (currentFeatureFlag) => currentFeatureFlag.Name === featureFlagName
    );
    return !!flag?.Value;
  }
);

const featureFlagsSort = (f: FeatureFlagModel) => (f.DisplayName || f.Name).toLowerCase();
const getFlagsMap = (flags: FeatureFlagModel[] = []) =>
  flags.reduce((agg, f) => {
    agg[f.Name] = f;
    return agg;
  }, {});

export const featureFlagsReducer = handleActions<FeatureFlagsState, FeatureFlagModel[]>(
  {
    [getFeatureFlags.toString()]: (state) =>
      Object.assign({}, state, { loading: true, locationId: undefined }),
    [getFeatureFlagsSuccess.toString()]: (state, action: any) => {
      const { data, locationId } = action.payload;
      const sortedData = sortBy(data, featureFlagsSort);
      return Object.assign({}, state, { loading: false, data: sortedData, locationId });
    },
    [saveFeatureFlagsSuccess.toString()]: (state, action) => {
      const newFlags = Object.assign(
        {},
        ...[state.data, action.payload].map(getFlagsMap)
      );
      const data = sortBy(Object.values(newFlags), featureFlagsSort);
      return Object.assign({}, state, { data });
    },
    [createFeatureFlagSuccess.toString()]: (state, action) => {
      return Object.assign({}, state, {
        data: sortBy([...state.data, ...action.payload], featureFlagsSort),
      });
    },
  },
  featureFlagDefaultState
);
