import React from 'react';
import _ from 'lodash';
import { WeaveTheme } from '@weave/theme-original';
import { Text, ReorderIcon } from '@weave/design-system';
import { css } from '@emotion/core';
import googleIcon from '../google-review.svg';
import facebookIcon from '../facebook-review.svg';

interface Props {
  text: string;
  index: number;
}

export class ReviewsOrderItem extends React.Component<Props> {
  render() {
    const { text } = this.props;
    const label = _.upperFirst(text);
    const src = text === 'google' ? googleIcon : facebookIcon;

    return (
      <>
        <ReorderIcon
          color="light"
          css={(theme: WeaveTheme) =>
            css`
              margin-right: ${theme.spacing(0.5)};
            `
          }
        />
        <img
          src={src}
          css={css`
            width: 36px;
          `}
          alt={label}
        />
        <Text
          css={(theme: WeaveTheme) =>
            css`
              flex-grow: 1;
              margin: 0 ${theme.spacing(1)};
            `
          }
        >
          {label}
        </Text>
      </>
    );
  }
}
