import { CustomAxios, getResponseData } from '../../axios';
import { FetchSmsHistoryPayload } from './sms-history.action';

export const OLD_MESSAGES_URL = '/sms/v2/sms/list';
export const MESSAGES_URL = '/sms/data/v3/sms/list';

const buildQS = (params: FetchSmsHistoryPayload) =>
  Object.entries(params).reduce((str, [key, value], idx) => {
    if (!value) return str;
    // for handling numeric values simply
    const strValue = `${value}`;
    if (!strValue.trim()) return str;
    return `${str}${idx > 0 ? '&' : ''}${key}=${strValue.trim()}`;
  }, '');

export const requestSmsHistory = (params?: FetchSmsHistoryPayload) => {
  const baseUrl = params?.commxMigrationFlag ? MESSAGES_URL : OLD_MESSAGES_URL;
  return CustomAxios.get(`${baseUrl}${params ? `?${buildQS(params)}` : ''}`).then(
    getResponseData
  );
};
