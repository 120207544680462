import { createAction, handleActions } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { PatientAppointmentsModel } from '../../../components/history-list-container/patient-details-container/patient-history-lists/patient-appointments/patient-appointments.model';
import { CustomAxios } from '../../axios';

export type AppointmentsByPatientPayload = {
  personID: string;
};

export const appointmentsByPatient = createAction('APPOINTMENTS_BY_PATIENT');
export const appointmentsByPatientSuccess = createAction(
  'APPOINTMENTS_BY_PATIENT_SUCCESS'
);
export const appointmentsByPatientFailure = createAction<Error>(
  'APPOINTMENTS_BY_PATIENT_FAILURE'
);
export const appointmentsByPatientReset = createAction('APPOINTMENTS_BY_PATIENT_RESET');

export const handleAppointmentsByPatient = function* (action) {
  try {
    const personID = action.payload.personID;
    const response = yield call(
      CustomAxios.get,
      `/support/v2/appointments?person_id=${personID}`
    );
    yield put(appointmentsByPatientSuccess(response.data.data));
  } catch (error: any) {
    yield put(appointmentsByPatientFailure(error));
  }
};

export const appointmentsByPatientSaga = function* () {
  yield all([takeEvery(appointmentsByPatient.toString(), handleAppointmentsByPatient)]);
};

const defaultState = {
  data: [],
  loading: false,
  error: '',
};

export type AppointmentsByPatientState = {
  data: PatientAppointmentsModel[];
  loading: boolean;
  error: string | null;
};

export const appointmentsByPatientReducer = handleActions(
  {
    [appointmentsByPatient.toString()]: (state) =>
      Object.assign({}, state, { loading: true }),
    [appointmentsByPatientSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { data: action.payload, loading: false }),
    [appointmentsByPatientFailure.toString()]: (state, action) =>
      Object.assign({}, state, { error: action.payload, loading: false }),
    [appointmentsByPatientReset.toString()]: () => defaultState,
  },
  defaultState
);
