import React from 'react';
import { cx } from 'emotion';
import moment from 'moment-timezone';

import * as styles from '../../patient-list.style.js';
import { EmailModel } from '../../../../../../models/email-history.model';
import { dateTimeZoneFormat } from '../../../../../../helpers/utils';

interface PatientEmailsProps {
  currentLocation: string;
  emailHistory: EmailModel[];
  personID: string;
  timezone: string;
  getEmailHistory: (payload) => void;
  emailHistoryReset: () => void;
}

export class PatientEmails extends React.Component<PatientEmailsProps, {}> {
  componentDidMount() {
    if (this.props.personID) {
      this.emailHistory();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentLocation !== this.props.currentLocation) {
      this.props.emailHistoryReset();
    }
    if (prevProps.personID !== this.props.personID) {
      this.emailHistory();
    }
  }

  emailHistory() {
    if (this.props.personID) {
      const payload = {
        params: '?person_id=' + this.props.personID + '&start=2017-01-01',
        locationID: this.props.currentLocation,
      };
      this.props.getEmailHistory(payload);
    }
  }

  componentWillUnmount() {
    this.props.emailHistoryReset();
  }

  render() {
    return (
      <div className={styles.cardComponent}>
        <div className={styles.flexDisplay}>
          <p className={cx(styles.listHeader, styles.leftListStyle)}>Email History</p>
          <p className={cx(styles.listHeader, styles.rightListStyle)}>
            {this.props.emailHistory.length}
          </p>
        </div>
        <div className={styles.listBox}>
          {!this.props.emailHistory.length && <p className={styles.noData}>No Data</p>}
          {this.props.emailHistory.map((email) => (
            <div
              key={email.EmailID}
              className={cx(styles.listStyle, 'email-history-item')}
            >
              <div className={styles.flexDisplay}>
                <div className={styles.leftListStyle}>
                  <p>{email.Status}</p>
                  <p>Type: {email.Type}</p>
                  <p>Provider: {email.Provider}</p>
                </div>
                <div className={styles.rightListStyle}>
                  <p>
                    Created:{' '}
                    <strong>
                      {moment(email.CreatedAt)
                        .tz(this.props.timezone)
                        .format(dateTimeZoneFormat)}
                    </strong>
                  </p>
                  <p>
                    Modified:{' '}
                    {moment(email.ModifiedAt)
                      .tz(this.props.timezone)
                      .format(dateTimeZoneFormat)}
                  </p>
                  <p>Subject: {email.Subject}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
