import React from 'react';
import { css } from 'emotion';
import {
  sourceName,
  dataContainer,
  radioGrouping,
  radioGroup,
} from './integrations.styles';
import { RadioField, useFormField, Text } from '@weave/design-system';
import {
  AccessType,
  ClientLocationType,
  MultiSyncAppType,
  SyncAppType,
} from './sync-app-access/sync-app-access.types';

type Props = {
  isClientLocation: boolean;
  locationAccess: ClientLocationType;
  location: MultiSyncAppType;
  syncApp: SyncAppType;
};

export const SyncAppAccess = ({
  isClientLocation,
  locationAccess,
  location,
  syncApp,
}: Props) => {
  const allAccessTypeRadioFieldProps = useFormField({
    type: 'radio',
  });

  const getSyncAppAccessValue = (): string => {
    const isContactAndScheduleType =
      locationAccess.accessType === AccessType.ContactAndSchedule &&
      locationAccess.accessEnabled;
    const isContactType =
      locationAccess.accessType === AccessType.Contact && locationAccess.accessEnabled;
    if (isContactAndScheduleType) return AccessType.ContactAndSchedule;
    else if (isContactType) return AccessType.Contact;
    else return '';
  };

  const getlocationName = (locationAccess: ClientLocationType): string =>
    locationAccess
      ? locationAccess.ShortName?.value ||
        locationAccess.SourceName ||
        locationAccess.Name
      : '-';

  const getLocationAddress = (locationAccess: ClientLocationType): string =>
    `${locationAccess?.Address?.value ?? ''} ${
      locationAccess?.Address2?.value ? ', ' + locationAccess?.Address2?.value : ''
    }`;

  return (
    <React.Fragment>
      {isClientLocation ? (
        <div
          className={css`
            margin-left: 73px;
          `}
        ></div>
      ) : null}
      <div css={sourceName}>
        <div
          className={css`
            width: 245px;
            & p {
              margin: 0%;
            }
          `}
        >
          <Text size="medium">{getlocationName(locationAccess)}</Text>
          <Text size="small" color="light">
            {getLocationAddress(locationAccess)}
          </Text>
        </div>
        <div css={dataContainer}>
          <div css={radioGrouping}>
            <RadioField
              {...allAccessTypeRadioFieldProps}
              name="allAccess"
              disabled
              css={radioGroup}
              value={getSyncAppAccessValue()}
            >
              <RadioField.Radio value="all">All</RadioField.Radio>
              <RadioField.Radio value="contact">Contact</RadioField.Radio>
            </RadioField>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
