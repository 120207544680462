import React from 'react';
import { css } from '@emotion/core';
import { Text, IconButton, ExternalLinkIcon } from '@weave/design-system';
import { DSMCheckbox, Svg } from '@weave/platform-ui-components';
import { startCase, capitalize } from 'lodash';
import { AccountData } from './multi-location.types';

interface Props {
  accountData: AccountData[];
  handleCheckboxClick: (i: number) => void;
}

export const MultiTable = (props: Props) => {
  const handleSFClick = (oppId: string) => {
    window.open(`http://weave.my.salesforce.com/${oppId}`, '_blank');
  };

  return (
    <table css={() => tableStyles}>
      <tbody>
        <tr css={() => tableRowHeader}>
          <th></th>
          <th>
            <Text as="span" color="light" weight="regular">
              Locations ({props.accountData.length})
            </Text>
          </th>
          <th>
            <Text as="span" color="light" weight="regular">
              Record Type
            </Text>
          </th>
          <th>
            <Text as="span" color="light" weight="regular">
              Opportunity
            </Text>
          </th>
          <th>
            <Text as="span" color="light" weight="regular">
              Provisioned
            </Text>
          </th>
          <th>
            <Text as="span" color="light" weight="regular">
              WC Form
            </Text>
          </th>
          <th>
            <Text as="span" color="light" weight="regular">
              SF Link
            </Text>
          </th>
        </tr>
        {props.accountData.map((data, i) => (
          <tr key={data.name} css={() => tableRow}>
            <td css={() => checkboxStyles}>
              <DSMCheckbox
                color="primary"
                checked={data.complete || data.selected}
                onChange={() => props.handleCheckboxClick(i)}
                disabled={data.complete || data.type === 'Satellite'}
              />
            </td>
            <td>
              <Text as="span" weight={data.provisioned ? 'regular' : 'bold'}>
                {data.name}
              </Text>
            </td>
            <td>
              <Text as="span" weight={data.provisioned ? 'regular' : 'bold'}>
                {capitalize(data.type)}
              </Text>
            </td>
            <td>
              <Text as="span" weight={data.provisioned ? 'regular' : 'bold'}>
                {startCase(data.opportunity)}
              </Text>
            </td>
            <td>
              {data.provisioned ? (
                <Svg icon="Check" color="green" />
              ) : (
                <Svg icon="X" color="red" />
              )}
            </td>
            <td>
              <Text as="span">{data.complete ? 'Complete' : '- -'}</Text>
            </td>
            <td>
              <IconButton
                css={sfIconStyles}
                size="small"
                label="Link to salesforce"
                onClick={() => handleSFClick(data.id)}
              >
                <ExternalLinkIcon />
              </IconButton>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const tableStyles = css`
  width: 100%;
  margin-bottom: 20px;
`;

const tableRow = css`
  th,
  td {
    padding: 5px 0;
  }
`;

const tableRowHeader = css`
  border-bottom: 1px solid lightgrey;
  th,
  td {
    padding: 10px 0;
  }
`;

const checkboxStyles = css`
  svg {
    height: 1.6em;
    width: 1.6em;
  }
`;

const sfIconStyles = css`
  svg {
    height: 0.8em;
    width: 0.8em;
  }
`;
