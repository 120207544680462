import React from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { Text } from '@weave/design-system';

interface DefaultProviderAvatarProps {
  firstName: string;
  lastName: string;
  index?: number;
  size?: number;
}

const backgroundColor = (index) => {
  switch (index % 6) {
    case 1:
      return theme.colors.weaveBlue;
    case 2:
      return theme.colors.pizazz;
    case 3:
      return theme.colors.success;
    case 4:
      return theme.colors.error;
    case 5:
      return theme.colors.studio;
    case 0:
      return theme.colors.strawberry;
    default:
      return theme.colors.weaveBlue;
  }
};

const defaultProviderAvatarStyles = (index: number, size: number) => css`
  display: flex;
  align-items: center;
  justify-contents: center;
  height: ${theme.spacing(size)};
  width: ${theme.spacing(size)};
  border-radius: 50%;
  background-color: ${backgroundColor(index)};
`;

export const DefaultProviderAvatar = ({
  firstName,
  lastName,
  index,
  size,
}: DefaultProviderAvatarProps) => {
  return (
    <div
      className="default-provider-avatar"
      css={defaultProviderAvatarStyles(index || 0, size || 5)}
    >
      <Text
        textAlign="center"
        weight="bold"
        color="white"
        css={css`
          font-size: ${theme.fontSize((size || 5) * 4)};
          letter-spacing: ${(size || 5) * -0.6}px;
          width: 100%;
          margin: 18% auto auto ${(size || 5) * -0.3}px;
        `}
      >
        {firstName?.[0]}
        {lastName?.[0]}
      </Text>
    </div>
  );
};
