import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  ContentLoader,
  FormFieldState,
  FormRow,
  PasswordField,
  PrimaryButton,
  useForm,
  ValidatorFieldState,
} from '@weave/design-system';
import { updateUser } from '../../redux/actions/auth/user/user.action';

export const ChangePasswordForm = ({ user }) => {
  const dispatch = useDispatch();
  const handleSubmit = async (values) => {
    const payload = {
      password: values.password,
      userId: user.UserID,
      username: user.Username,
      type: user.Type,
      firstName: user.FirstName,
      lastName: user.LastName,
    };

    dispatch(updateUser(payload));
  };

  const { formProps, getFieldProps, isComplete, reset } = useForm({
    fields: {
      password: { type: 'password', required: true },
      passwordVerification: {
        type: 'password',
        required: true,
        validator: (
          { value }: ValidatorFieldState<'password'>,
          allFields: { password: FormFieldState<'password'> }
        ) => (value !== allFields?.password?.value ? 'Passwords must match' : ''),
      },
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    return () => {
      reset();
    };
  }, [user]);

  return !user ? (
    <ContentLoader show={true} />
  ) : (
    <form {...formProps}>
      <FormRow>
        <PasswordField {...getFieldProps('password')} label="Password" name="password" />
      </FormRow>
      <FormRow>
        <PasswordField
          {...getFieldProps('passwordVerification')}
          label="Password Verification"
          name="passwordVerification"
        />
      </FormRow>
      <br />
      <FormRow>
        <PrimaryButton type="submit" disabled={!isComplete}>
          Change Password
        </PrimaryButton>
      </FormRow>
    </form>
  );
};
