import { createAction, handleActions } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { PatientsModel } from '../../../models/patient.model';
import { CustomAxios } from '../../axios';

export type PersonPayload = {
  locationId: string;
  personId: string;
};

export type PersonResultPayload = any;

export const person = createAction<PersonPayload>('PERSON');
export const personSuccess = createAction<PersonResultPayload>('PERSON_SUCCESS');
export const personFailure = createAction<Error>('PERSON_FAILURE');
export const personReset = createAction('PERSON_RESET');

export const handlePerson = function* (action) {
  try {
    const locationId = action.payload.locationId;
    const personId = action.payload.personId;
    const response = yield call(
      CustomAxios.get,
      `/support/v1/locations/${locationId}/persons/${personId}`
    );
    yield put(personSuccess(response.data.data));
  } catch (error: any) {
    yield put(personFailure(error));
  }
};

export const personSaga = function* () {
  yield all([takeEvery(person.toString(), handlePerson)]);
};

export type PersonState = {
  data: PatientsModel;
  loading: boolean;
};

const defaultState: PersonState = {
  data: {} as PatientsModel,
  loading: false,
};

export const personReducer = handleActions(
  {
    [person.toString()]: (state) => Object.assign({}, state, { loading: true }),
    [personSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { data: action.payload, loading: false }),
    [personFailure.toString()]: (state, action) =>
      Object.assign({}, state, { error: action.payload, loading: false }),
    [personReset.toString()]: () => defaultState,
  },
  defaultState
);
