import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';

export const footerContainer = css`
  position: absolute;
  bottom: 0;
  width: 100%;
`;
export const footer = css`
  padding: ${theme.spacing(2)};
`;

export const footerContent = css`
  padding: 0 ${theme.spacing(2)};
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const header = css`
  padding: ${theme.spacing(3)};
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.colors.gray200};
  margin-bottom: ${theme.spacing(2)};
  :before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background-repeat: repeat;
    height: 10px;
    background-size: 20px 20px;
    background-image: radial-gradient(circle at 10px -5px, transparent 12px, white 13px);
  }
  :after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background-repeat: repeat;
    height: 15px;
    background-size: 40px 20px;
    background-image: radial-gradient(circle at 10px 15px, white 12px, transparent 13px);
  }
`;
export const headerContent = css`
  display: block;
`;
export const dateContainer = css`
  text-align: right;
`;
export const fontSize = css`
  font-size: 25px;
`;
export const logo = css`
  width: 150px;
  label: logo;
`;
export const table = css`
  width: 100%;
`;
export const pageBody = css`
  padding: ${theme.spacing(4)};
`;
export const billingDetails = css`
  display: flex;
  justify-content: space-between;
`;
export const billingContact = css`
  padding: ${theme.spacing(8)};
`;
export const disclaimer = css`
  text-align: center;
  padding: 0 ${theme.spacing(12)};
`;
export const totalTextStyles = css`
  margin-left: auto;
  font-size: ${theme.fontSize(21)};
  font-weight: bold;
`;
export const textAlignRight = css`
  text-align: right;
`;

export const tableStyles = css`
  width: 100%;
  margin: ${theme.spacing(3)} 0;

  tr > *:not(:first-child) {
    font-size: 16px;
    border: 1px solid ${theme.colors.gray500};
  }
  th {
    height: 40px;
    padding: ${theme.spacing(2)};
    background-color: ${theme.colors.gray200};
    font-weight: bold;
    border: 1px solid ${theme.colors.gray500};
  }
  th:first-child {
    border-radius: 10px 0 0 10px;
  }
  th:last-child {
    border-radius: 0 10px 10px 0;
  }
  td {
    padding: ${theme.spacing(2)};
    color: ${theme.colors.gray600};
    border: 1px solid ${theme.colors.gray500};
  }
`;

export const textGreeting = css`
  text-align: right;
  font-size: 25px;
`;

export const textColor = css`
  color: #19295e;
`;

export const noMargin = css`
  margin: 0;
`;

export const pdfErrorMessage = css`
  color: #b95454;
`;

export const pageFooter = css`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 120px;
`;

export const pageHeader = css`
  position: fixed;
  top: 0mm;
  width: 100%;
`;

export const page = css`
  page-break-after: always;
`;

export const pageHeaderSpace = css`
  height: 180px;
  .pageHeader {
    height: 180px;
  }
`;

export const pageFooterSpace = css`
  height: 120px;
`;

export const containerStyle = css`
  @media print {
    thead {
      display: table-header-group;
    }
    tfoot {
      display: table-footer-group;
    }
    body {
      margin: 0;
    }
  }
`;
