import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

interface PrivateRouteProps extends RouteProps {
  isAuthenticated?: boolean;
}

export const PrivateRoute: React.StatelessComponent<PrivateRouteProps> = ({
  component,
  exact = true,
  isAuthenticated,
  path,
  ...rest
}: PrivateRouteProps) => {
  const renderFn = (Component) => (props: PrivateRouteProps) => {
    if (!Component) {
      return null;
    }

    if (isAuthenticated) {
      return <Component exact={exact} {...props} />;
    }

    return <Redirect to="/" />;
  };

  return <Route path={path} exact={exact} {...rest} render={renderFn(component)} />;
};
