import { connect } from 'react-redux';
import { FaxHistory } from './fax-history/fax-history.component';
import {
  selectFaxHistoryData,
  fetchFaxHistory,
} from '../../../../redux/actions/fax-history';

export const FaxHistoryContainer = connect(selectFaxHistoryData, { fetchFaxHistory })(
  FaxHistory
);
