import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { CustomAxios, getResponseData } from '../../axios/axios';
import { Ringtone, RingtonesActionTypes } from './ringtones.types';
import { ResolvedPromise } from '../../store/store-utils';
import { selectCurrentLocationId } from '../location/current-location';
import { addRingtonesToLocation } from '../location/location-entities/location-ringtones';
import { createCachedRequestSaga } from '../cached-actions';

export const requestListRingtones = () => ({
  type: RingtonesActionTypes.RequestList,
});

export const addRingtones = (ringtones: Ringtone[]) => ({
  type: RingtonesActionTypes.AddRingtones,
  payload: ringtones,
});

const api = {
  listRingtones: (): Promise<Ringtone[]> =>
    CustomAxios.get(`support/v1/phone/callgroups/options/ringtones`).then(
      getResponseData
    ),
};

export const requestListRingtonesSaga = createCachedRequestSaga({
  key: RingtonesActionTypes.RequestList,
  displayErrors: true,
  onError: (err) => `Failed to retrieve ringtones: ${err.message}`,
  saga: function* () {
    const ringtones: ResolvedPromise<ReturnType<typeof api.listRingtones>> = yield call(
      api.listRingtones
    );
    const currentLocationId: ReturnType<typeof selectCurrentLocationId> = yield select(
      selectCurrentLocationId
    );
    yield put(addRingtones(ringtones));
    yield put(
      addRingtonesToLocation(
        currentLocationId,
        ringtones.map((tone) => tone.value)
      )
    );
  },
});

export const ringtonesSaga = function* () {
  yield all([takeLatest(RingtonesActionTypes.RequestList, requestListRingtonesSaga)]);
};
