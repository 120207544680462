import React, { useEffect } from 'react';
import startCase from 'lodash/startCase';
import { SecondaryButton, SpinningLoader, Text } from '@weave/design-system';

import {
  lastSyncInfoContainer,
  lastSyncInfoResult,
  refreshButton,
  tabLoading,
} from '../sync-app-card.styles';
import { GetSyncAppInfoPayload } from '../../sync-app.types';
import { copyStyles, formatDateFromUnixTimestamp } from '../../../../helpers/utils';

interface LastSyncInfoTabProps {
  sourceId: string;
  loading: boolean;
  externalWindowDoc?: Document;
  lastSyncInfo: {
    [key: string]: string;
  };
  getSyncAppInfo: (payload: GetSyncAppInfoPayload) => void;
}

export const LastSyncInfoTab = ({
  sourceId,
  loading,
  externalWindowDoc,
  lastSyncInfo = {},
  getSyncAppInfo,
}: LastSyncInfoTabProps) => {
  const getTabInfo = () => {
    getSyncAppInfo({
      sourceId,
      rpcMethod: 'RPCAdapter.LastSyncInfo',
      rpcParams: [],
      syncAppInfoKey: 'lastSyncInfo',
    });
  };

  useEffect(() => {
    getTabInfo();
    if (externalWindowDoc) {
      externalWindowDoc.head.innerHTML = '';
      copyStyles(document, externalWindowDoc);
    }
  }, []);

  const formatInfo = (info, key) => {
    const dateKeys = ['LastSyncTime', 'LastDiffSent', 'NextSyncTime'];
    if (dateKeys.includes(key)) {
      return info ? formatDateFromUnixTimestamp(info, 'MMMM dd, yyyy h:mm a') : '';
    }
    if (key === 'SyncRunning') {
      return info ? 'Yes' : 'No';
    }
    return info;
  };

  return (
    <div css={lastSyncInfoContainer}>
      {loading && (
        <div css={tabLoading}>
          <SpinningLoader />
        </div>
      )}
      {!loading && (
        <div css={lastSyncInfoResult}>
          {Object.entries(lastSyncInfo).map(([key, info], index) => (
            <div key={index}>
              <Text weight="bold">{startCase(key)}</Text>
              <Text color="light">{formatInfo(info, key)}</Text>
            </div>
          ))}
        </div>
      )}
      <SecondaryButton css={refreshButton} onClick={getTabInfo} disabled={loading}>
        Refresh Data
      </SecondaryButton>
    </div>
  );
};
