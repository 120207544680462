import React from 'react';
import moment from 'moment-timezone';

import * as styles from './metadata-details.style';
import { dateTimeZoneFormat } from '../../../../../helpers/utils';
import { AppointmentModel } from '../../../../../models/appointment.model';
import { EventModel } from '../../../../../models/event.model';

interface Props {
  appointment: AppointmentModel;
  event: EventModel;
  timezone: string;
  metadata: {
    relatedDataType: string;
    relatedDataID: string;
  };
  getAppointment: (payload: string) => void;
  getEvent: (payload: string) => void;
}

export class MetadataDetails extends React.Component<Props> {
  componentDidMount() {
    switch (this.props.metadata.relatedDataType.toLowerCase()) {
      case 'appointment':
        this.props.getAppointment(this.props.metadata.relatedDataID);
        break;
      case 'recall':
      case 'order':
        this.props.getEvent(`/${this.props.metadata.relatedDataID}`);
        break;
    }
  }

  getDetails = () => {
    switch (this.props.metadata.relatedDataType.toLowerCase()) {
      case 'appointment':
        return {
          status: this.props.appointment.StatusPM,
          date: this.props.appointment.AppointmentDate,
          labelID: 'PMID',
          id: this.props.appointment.AppointmentPMID,
        };
      case 'recall':
      case 'order':
        return {
          status: this.props.event.Description,
          date: this.props.event.EventTime,
          labelID: 'PMID',
          id: this.props.event.PMID,
        };
      default:
        return {};
    }
  };

  render() {
    const details = this.getDetails();
    let formattedDate;
    if (this.props.timezone && details.date) {
      formattedDate = moment(details.date)
        .tz(this.props.timezone)
        .format(dateTimeZoneFormat);
    }
    return (
      <div>
        {details.status && (
          <div>
            <div className={styles.container}>
              <p className="left">Status</p>
              <p>{details.status}</p>
            </div>

            <div className={styles.container}>
              <p className="left">Date</p>
              <p>{formattedDate}</p>
            </div>

            <div className={styles.container}>
              <p className="left">{details.labelID}</p>
              <p>{details.id}</p>
            </div>
          </div>
        )}
      </div>
    );
  }
}
