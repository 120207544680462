import { createAction } from 'redux-actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import _ from 'lodash';
import { CustomAxios, getErrorMessage } from '../../axios';
import { showError } from '@weave/alert-system';

export const setLoading = createAction('SET_USER_SEARCH_LOADING');
export const userSearch = createAction('USER_SEARCH');
export const userSearchSuccess = createAction('USER_SEARCH_SUCCESS');
export const userSelect = createAction('USER_SELECT');
export const userSelectSuccess = createAction('USER_SELECT_SUCCESS');
export const setUserFound = createAction('SET_USER_FOUND');
export const setUserSearchText = createAction('SET_USER_SEARCH_TEXT');

export const handleUserSearch = function* (action) {
  const searchQuery = action.payload.state;
  yield put(setUserSearchText(searchQuery));
  try {
    const response = yield call(
      CustomAxios.get,
      `/support/v1/users/?search=${searchQuery}`
    );
    const data = response.data.data;
    yield put(userSearchSuccess(data || []));
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    yield put(showError(getErrorMessage(error)));
  }
};

export const userSearchSaga = function* () {
  yield takeEvery(userSearch.toString(), handleUserSearch);
};

export const handleUserSelect = function* (action) {
  const data = action.payload.state;
  const addedExistingUser = action.payload.isExistingUser;
  if (!_.isEmpty(data) && addedExistingUser) {
    yield put(setUserFound(true));
  } else {
    yield put(setUserFound(false));
  }
  yield put(userSelectSuccess(data || {}));
};

export const userSelectSaga = function* () {
  yield takeEvery(userSelect.toString(), handleUserSelect);
};
