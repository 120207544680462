import { handleActions } from 'redux-actions';
import {
  addBlockedNumber,
  addBlockedNumberFailure,
  addBlockedNumberSuccess,
  deleteBlockedNumber,
  deleteBlockedNumberFailure,
  deleteBlockedNumberSuccess,
  getBlockedNumbers,
  getBlockedNumbersFailure,
  getBlockedNumbersSuccess,
} from './call-blocking.action';

const initalState = {
  blockedNumbers: [],
  loading: false,
};

export const callBlockingReducer = handleActions<any, any>(
  {
    [getBlockedNumbers.toString()]: (state) => ({ ...state, loading: true }),
    [getBlockedNumbersSuccess.toString()]: (state, action) => ({
      ...state,
      blockedNumbers: action.payload,
      loading: false,
    }),
    [getBlockedNumbersFailure.toString()]: (state) => ({
      ...state,
      loading: false,
      blockedNumbers: [],
    }),
    [deleteBlockedNumber.toString()]: (state) => ({ ...state, loading: true }),
    [deleteBlockedNumberSuccess.toString()]: (state, action) => ({
      ...state,
      blockedNumbers: state.blockedNumbers.filter(
        (blockedNumber) => blockedNumber.id !== action.payload
      ),
      loading: false,
    }),
    [deleteBlockedNumberFailure.toString()]: (state) => ({ ...state, loading: false }),
    [addBlockedNumber.toString()]: (state) => ({ ...state, loading: true }),
    [addBlockedNumberSuccess.toString()]: (state, action) => ({
      ...state,
      loading: false,
      blockedNumbers: [...state.blockedNumbers, action.payload],
    }),
    [addBlockedNumberFailure.toString()]: (state) => ({ ...state, loading: false }),
  },
  initalState
);
