import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/core';
import { useAlert } from '@weave/alert-system';
import { WeaveTheme } from '@weave/theme-original';
import { Navigation } from './navigation/navigation';
import { Footer } from './footer/footer';
import { PhonesPricing } from './phones-pricing/phones-pricing';
import { SoftwarePricing } from './software-pricing/software-pricing';
import { AddonsPricing } from './addons-pricing/addons-pricing';
import WeaveHeaderBackground from '../../images/sales-pricing/wv-header-background.png';
import { ReactComponent as WeaveLogo } from '../../images/sales-pricing/weave-logo.svg';
import {
  phoneProductData,
  WEAVE_IMPLEMENTATION_PRICING,
  softwareProductData,
  addonsProductData,
  WEAVE_COMMUNICATE_LABEL,
} from './pricing-data';
import {
  CartItem,
  CalculationType,
  SoftwareBundleDiscount,
  Product,
  SoftwareProduct,
} from './sales-pricing.types';
import { PricingCalculator } from './pricing-calculator/pricing-calculator';
import { currencyFormatter } from './pricing-calculator/pricing-calculator.helper';

export const SalesPricing = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [willUsePhones, setWillUsePhones] = useState(false);
  const [cartItems, setCartItems] = useState<CartItem[]>([WEAVE_IMPLEMENTATION_PRICING]);
  const [calculationType, setCalculationType] = useState<CalculationType>('monthly');
  const [softwareBundleDiscount, setSoftwareBundleDiscount] =
    useState<SoftwareBundleDiscount>('');
  const alerts = useAlert();

  // Handles adding/removing the Weave Communicate software product to the cart whenever
  // the user changes the value of `willUsePhones`. If the office will be using phones,
  // then WeaveCommunicate should be added by default to the cart and not allowed to be
  // removed. Likewise, if the office is not using phones, then WeaveCommunicate should
  // be removed from the cart and not allowed to be added.
  useEffect(() => {
    if (!willUsePhones) {
      const filterWeaveCommunicate = (cartItem: CartItem) =>
        cartItem.label !== WEAVE_COMMUNICATE_LABEL;
      const newCartItems: CartItem[] = [...cartItems.filter(filterWeaveCommunicate)];

      setCartItems(newCartItems);
    } else {
      const weaveCommunicateProduct = softwareProductData.find(
        (product) => product.label === WEAVE_COMMUNICATE_LABEL
      );

      if (!weaveCommunicateProduct) {
        return;
      }

      const { label, displayName, recurringPrice, type } = weaveCommunicateProduct;
      const weaveCommunicateCartItem: CartItem = {
        label,
        displayName,
        recurringPrice,
        type,
        quantity: 1,
      };

      setCartItems([weaveCommunicateCartItem, ...cartItems]);
    }
  }, [willUsePhones]);

  // Handles setting the softwareBundlesDiscount state value.
  // CONTEXT: When selecting multiple software packages, a discount should be added to all
  // recurring charges.
  // 2 packages = 10% discount
  // 3 packages = 20% discount
  useEffect(() => {
    const filterSoftwareCartItems = (cartItem: CartItem) => cartItem.type === 'software';
    const softwareCartItems = cartItems.filter(filterSoftwareCartItems);

    if (softwareCartItems.length > 2) {
      setSoftwareBundleDiscount('20%');
    } else if (softwareCartItems.length > 1) {
      setSoftwareBundleDiscount('10%');
    } else {
      if (softwareBundleDiscount) {
        setSoftwareBundleDiscount('');
      }
    }
  }, [cartItems]);

  const handleNavigateToStep = (stepNumber: number) => {
    setCurrentStep(stepNumber);
  };

  const handleForwardButtonClicked = () => {
    if (nextStepIsAvailable) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBackButtonClicked = () => {
    if (prevStepIsAvailable) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleCalculationTypeChanged = (type: CalculationType) => {
    setCalculationType(type);
  };

  const handleWillUsePhonesChanged = (willUse: boolean) => {
    setWillUsePhones(willUse);

    if (!willUse) {
      removePhoneProductsFromCart();
    }
  };

  const handlePhoneProductQuantityChanged = (phoneProduct: Product, quantity: number) => {
    const { label, displayName, price, recurringPrice } = phoneProduct;

    const filterOldPhoneCartItem = (cartItem: CartItem) => cartItem.label !== label;
    const newCartItems: CartItem[] = [...cartItems.filter(filterOldPhoneCartItem)];

    if (quantity > 0) {
      const newItem: CartItem = {
        label,
        displayName,
        price,
        recurringPrice,
        type: 'phone',
        quantity,
      };

      newCartItems.push(newItem);
    }

    setCartItems(newCartItems);
  };

  const handleWeaveCommunicateProductClicked = () => {
    if (willUsePhones) {
      alerts.warning('Weave Communicate required when using phones.');
    } else {
      alerts.warning('Business will not be using phones.');
    }
  };

  const handleProductClicked = (product: Product, type: 'software' | 'addon') => {
    const { label, displayName, price, recurringPrice } = product;

    // If the product that was clicked is WeaveCommunicate then just return. There is
    // special logic that handles removing/adding WeaveCommunicate to the cart in a
    // useEffect hook.
    if (label === WEAVE_COMMUNICATE_LABEL) {
      handleWeaveCommunicateProductClicked();

      return;
    }

    const isInCart = isItemInCart(product.label);

    if (isInCart) {
      const filterOldCartItem = (cartItem: CartItem) => cartItem.label !== label;
      const newCartItems: CartItem[] = [...cartItems.filter(filterOldCartItem)];

      setCartItems(newCartItems);
    } else {
      const newItem: CartItem = {
        label,
        displayName,
        price,
        recurringPrice,
        type,
        quantity: 1,
      };

      setCartItems([...cartItems, newItem]);
    }
  };

  const handleSoftwareProductClicked = (softwareProduct: SoftwareProduct) => {
    handleProductClicked(softwareProduct, 'software');
  };

  const handleAddonProductClicked = (addonProduct: Product) => {
    handleProductClicked(addonProduct, 'addon');
  };

  const removePhoneProductsFromCart = () => {
    const filterPhoneCartItems = (cartItem: CartItem) => cartItem.type !== 'phone';
    setCartItems([...cartItems.filter(filterPhoneCartItems)]);
  };

  const isItemInCart = (itemLabel: string) =>
    !!cartItems.find((cartItem) => cartItem.label === itemLabel);

  const getCartItem = (itemLabel: string) =>
    cartItems.find((cartItem) => cartItem.label === itemLabel);

  const getProductServiceFeeAmount = (product: Product) => {
    const { recurringPrice = 0 } = product;

    const isAnnual = calculationType === 'annual';

    return isAnnual ? recurringPrice * 12 : recurringPrice;
  };

  const getProductServiceFeeText = (product: Product) => {
    const feeAmount = getProductServiceFeeAmount(product);
    const recurringTypeText = calculationType === 'annual' ? 'yr' : 'mo';

    return `${currencyFormatter(feeAmount)}/${recurringTypeText}`;
  };

  const getPhoneProductServiceFeeText = (product: Product) => {
    const feeAmount = getProductServiceFeeAmount(product);

    return `${currencyFormatter(feeAmount)} service fee/phone`;
  };

  const getProductActivationFeeText = (product: Product) => {
    if (product.price) {
      return `${currencyFormatter(product.price)} activation fee + `;
    } else {
      return null;
    }
  };

  const stepComponents = [
    {
      name: 'Phones',
      component: (
        <PhonesPricing
          phoneProductData={phoneProductData}
          willUsePhones={willUsePhones}
          getProductServiceFeeText={getPhoneProductServiceFeeText}
          getCartItem={getCartItem}
          onWillUsePhonesClicked={handleWillUsePhonesChanged}
          onPhoneProductQuantityChanged={handlePhoneProductQuantityChanged}
        />
      ),
    },
    {
      name: 'Software',
      component: (
        <SoftwarePricing
          softwareProductData={softwareProductData}
          isItemInCart={isItemInCart}
          getProductServiceFeeText={getProductServiceFeeText}
          onSoftwareProductClicked={handleSoftwareProductClicked}
        />
      ),
    },
    {
      name: 'Add-ons',
      component: (
        <AddonsPricing
          addonsProductData={addonsProductData}
          isItemInCart={isItemInCart}
          getProductServiceFeeText={getProductServiceFeeText}
          onAddonProductClicked={handleAddonProductClicked}
          getProductActivationFeeText={getProductActivationFeeText}
        />
      ),
    },
  ];

  const stepNames = useMemo(
    () => stepComponents.map((step) => step.name),
    [stepComponents]
  );

  const nextStepIsAvailable = currentStep + 1 < stepComponents.length;
  const prevStepIsAvailable = currentStep - 1 >= 0;

  return (
    <div css={containerStyles}>
      <div css={headerStyles}>
        <WeaveLogo css={weaveLogoStyles} />
        <div css={titleAndNavContainerStyles}>
          <h1 css={titleStyles}>Weave Pricing &amp; Bundling</h1>
          <Navigation
            stepNames={stepNames}
            currentStep={currentStep}
            onNavigateToStep={handleNavigateToStep}
          />
        </div>
      </div>

      <div css={mainBodyContainerStyles}>
        {stepComponents[currentStep].component}

        <PricingCalculator
          cartItems={cartItems}
          calculationType={calculationType}
          softwareBundleDiscount={softwareBundleDiscount}
          onCalculationTypeChanged={handleCalculationTypeChanged}
        />
      </div>

      <Footer
        stepNames={stepNames}
        currentStep={currentStep}
        onForwardButtonClicked={handleForwardButtonClicked}
        onBackButtonClicked={handleBackButtonClicked}
        nextStepIsAvailable={nextStepIsAvailable}
        prevStepIsAvailable={prevStepIsAvailable}
      />
    </div>
  );
};

const containerStyles = css`
  width: 1024px;
  margin: 0 auto;
`;
const headerStyles = (theme: WeaveTheme) => css`
  background-image: url(${WeaveHeaderBackground});
  background-size: contain;
  background-repeat: no-repeat;
  height: 128px;
  color: ${theme.colors.white};
  display: grid;
  grid-template-columns: 25% 50% 25%;

  @media only screen and (max-width: 850px) {
    grid-template-columns: none;
    background-size: auto;
  }
`;
const weaveLogoStyles = css`
  margin: 24px;

  @media only screen and (max-width: 850px) {
    display: none;
  }
`;
const titleAndNavContainerStyles = css`
  margin-top: 24px;
  text-align: center;
`;
const titleStyles = css`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 24px;
`;
const mainBodyContainerStyles = css`
  min-height: 800px;
  padding: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media only screen and (max-width: 680px) {
    padding: 16px;
  }
`;
