import React from 'react';
import { css } from '@emotion/core';
import { WeaveTheme } from '@weave/theme-original';
import { Text } from '@weave/design-system';

type Props = {
  stepNames: string[];
  currentStep: number;
  onNavigateToStep: (stepNumber: number) => void;
};

export const Navigation = ({ stepNames, currentStep, onNavigateToStep }: Props) => {
  const isCurrentStep = (idx: number) => {
    return currentStep === idx;
  };

  return (
    <div css={navBarStyles}>
      {stepNames.map((name, idx) => (
        <button css={buttonStyles} key={idx} onClick={() => onNavigateToStep(idx)}>
          <div
            css={(theme) => [
              stepCircleStyles,
              isCurrentStep(idx) && activeStepCircleStyles(theme),
            ]}
          >
            {isCurrentStep(idx) && idx + 1}
          </div>
          <Text css={navOptionTextStyles}>{name}</Text>
        </button>
      ))}
    </div>
  );
};

const navBarStyles = css`
  display: flex;
  justify-content: space-evenly;
  font-size: 16px;
`;
const buttonStyles = (theme: WeaveTheme) => css`
  display: flex;
  align-items: center;
  color: ${theme.colors.white};
  &:focus {
    outline: 0;
  }
`;
const stepCircleStyles = css`
  border: 1px solid;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 8px;
`;
const activeStepCircleStyles = (theme: WeaveTheme) => css`
  border-color: ${theme.colors.white};
  background-color: ${theme.colors.white};
  color: #28caf4;
`;
const navOptionTextStyles = (theme: WeaveTheme) => css`
  color: ${theme.colors.white};
  margin: 0;
`;
