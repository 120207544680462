import { connect } from 'react-redux';
import { SmsNotificationQueue } from './sms-notification-queue.component';
import { SmsMetadataModel } from '../../../../../models/sms-metadata.model';
import {
  notificationQueue,
  resetNotificationQueueFailure,
} from '../../../../../redux/actions/notification-queue/notification-queue.action';
import {
  selectCurrentLocationId,
  selectCurrentLocationTimeZone,
} from '../../../../../redux/actions/location/current-location';
import { Store } from '../../../../../redux/store/store.model';
interface OwnProps {
  relatedData: SmsMetadataModel;
}

export const SmsNotificationQueueContainer = connect(
  (store: Store, ownProps: OwnProps) => ({
    locationID: selectCurrentLocationId(store),
    notificationID: store.smsDetails.data.autogeneratedBy,
    apptID: store.smsDetails.data.apt_id,
    queue: store.notificationQueue.data,
    timezone: selectCurrentLocationTimeZone(store),
    relatedData: ownProps.relatedData,
  }),
  {
    getNotificationQueue: notificationQueue,
    resetNotificationQueueFailure: resetNotificationQueueFailure,
  }
)(SmsNotificationQueue);
