import { css } from 'emotion';

export const modalMainSectionHeight = '322px';

export const modalCard = css`
  .react-modal-card {
    height: 593px;
    max-height: none;
    padding: 20px 50px;
    text-align: center;
    width: 700px;
  }
`;

export const containNav = css`
  margin: 16px 0 10px;
  display: inline-block;
  width: 616px;
  height: 550px;
`;
