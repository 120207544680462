import { handleActions } from 'redux-actions';
import { UserModel } from '../../../models/user.model';

import {
  addNewUserSuccess,
  deleteUser,
  deleteUserSuccess,
  getUsers,
  getUsersSuccess,
  saveUserProfile,
  saveUserProfileSuccess,
  sendPasswordReset,
  sendPasswordResetSuccess,
  setLoading,
} from './users-list.action';

export type UsersListState = {
  data: UserModel[];
  loading: boolean;
};

const initialState: UsersListState = {
  data: [],
  loading: false,
};

export const usersListReducer = handleActions<UsersListState, any>(
  {
    [getUsers.toString()]: (state, action) => Object.assign({}, state, { loading: true }),
    [getUsersSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { data: action.payload }),
    [setLoading.toString()]: (state, action) =>
      Object.assign({}, state, { loading: action.payload }),
    [saveUserProfile.toString()]: (state, action) =>
      Object.assign({}, state, { loading: true }),
    [addNewUserSuccess.toString()]: (state, action) => {
      const updatedItems = [...state.data, action.payload];
      return Object.assign({}, state, { loading: false, data: updatedItems });
    },
    [saveUserProfileSuccess.toString()]: (state, action) => {
      const updatedItems = state.data.map((item) => {
        if (item.UserID === action.payload.UserID) {
          return { ...item, ...action.payload };
        }
        return item;
      });
      return Object.assign({}, state, { loading: false, data: updatedItems });
    },
    [sendPasswordReset.toString()]: (state, action) =>
      Object.assign({}, state, { loading: true }),
    [sendPasswordResetSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { loading: false }),
    [deleteUser.toString()]: (state, action) =>
      Object.assign({}, state, { loading: true }),
    [deleteUserSuccess.toString()]: (state, action) => {
      const updatedItems = state.data.filter((item) => {
        return item.UserID !== action.payload.UserID;
      });
      return Object.assign({}, state, { loading: false, data: updatedItems });
    },
  },
  initialState
);
