import React, { useContext, useEffect, useReducer } from 'react';
import {
  differenceInMinutes,
  formatDuration,
  isAfter,
  isBefore,
  isEqual,
} from 'date-fns';
import ReactTable from 'react-table';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import {
  Alert,
  AlertType,
  Modal,
  ModalControlModalProps,
  Text,
  TimeField,
  useForm,
} from '@weave/design-system';
import {
  fieldStyle,
  formatDateFromTime,
  formatEndTime,
  formatStartTime,
  tableStyle,
  tableHeaderStyle,
  tableHeaderTextStyle,
} from '../schedule-display-utils';
import { OfficeHoursContext } from './office-hours.provider';
import { reactTableStyle } from '../../../shared/server-data-table/react-table.style';
import { isBoolean } from 'lodash';

const modalOverflowStyle = css`
  overflow: visible;
`;

interface AlertMessage {
  message: string;
  messageType: AlertType;
  isAvailable?: boolean;
}

interface UpdateTimeBlockPayload {
  start?: string;
  end?: string;
  alertMessage?: AlertMessage;
  disableSave?: boolean;
}

interface TimeBlock {
  start: string;
  end: string;
}

interface OfficeHoursAddTimeState {
  availableTimes: TimeBlock[] | [];
  saveDisabled: boolean;
  alertMessage: AlertMessage;
  start: string;
  end: string;
}

type OfficeHoursAddTimeActions =
  | {
      type: 'UPDATE_TIMES';
      payload: UpdateTimeBlockPayload;
    }
  | {
      type: 'SET_AVAILABLE_TIMES';
      payload: TimeBlock[];
    };

const initialOfficeHourAddTimeState: OfficeHoursAddTimeState = {
  availableTimes: [],
  saveDisabled: true,
  alertMessage: {
    message: '',
    messageType: 'success',
  },
  start: '00:00:00',
  end: '24:00:00',
};

const OfficeHourAddTimeReducer = (
  state: OfficeHoursAddTimeState,
  action: OfficeHoursAddTimeActions
) => {
  const newState = { ...state };
  switch (action.type) {
    case 'UPDATE_TIMES': {
      const { start, end, alertMessage, disableSave } = action.payload;

      if (
        isEqual(
          formatDateFromTime(action?.payload?.start || '00:00:00'),
          formatDateFromTime(newState.start || '00:00:00')
        )
      ) {
        newState.start = start || '00:00:00';
      }

      if (action?.payload?.end !== newState?.end) {
        newState.end = end || '24:00:00';
      }

      if (alertMessage && alertMessage.message !== newState.alertMessage.message) {
        newState.alertMessage.message = alertMessage.message;

        if (alertMessage?.messageType !== newState.alertMessage.messageType) {
          newState.alertMessage.messageType = alertMessage.messageType || 'error';
        }
      }

      if (
        isBoolean(action.payload?.disableSave) &&
        action.payload?.disableSave !== state.saveDisabled
      ) {
        newState.saveDisabled = disableSave || false;
      }

      return newState;
    }

    case 'SET_AVAILABLE_TIMES':
      newState.availableTimes = action.payload;
      return newState;

    default:
      return newState;
  }
};

interface OfficeHoursAddTimeBlockProps {
  modalProps: ModalControlModalProps;
  handlePost: any;
}

const columns = [
  {
    Header: 'Start',
    id: 'start',
    accessor: (rowData) => rowData.start,
  },
  {
    Header: 'End',
    id: 'end',
    accessor: (rowData) => rowData.end,
  },
];

export const OfficeHoursAddTimeBlock = ({
  modalProps,
  handlePost,
}: OfficeHoursAddTimeBlockProps) => {
  const context = useContext(OfficeHoursContext);
  const { state, dispatch } = context;
  const { selectedDay } = state;
  const { getFieldProps, seedValues, values } = useForm({
    fields: {
      start: {
        type: 'time',
        minTime: '00:00:00',
        maxTime: '24:00:00',
        required: true,
      },
      end: {
        type: 'time',
        minTime: '00:00:00',
        maxTime: '24:00:00',
        required: true,
      },
    },
  });
  const [formState, formDispatch] = useReducer(
    OfficeHourAddTimeReducer,
    initialOfficeHourAddTimeState
  );
  const { alertMessage, availableTimes, saveDisabled } = formState;

  const duration = () => {
    const newDuration = differenceInMinutes(
      formatDateFromTime(values.end === '00:00:00' ? '24:00:00' : values.end),
      formatDateFromTime(values.start || '00:00:00')
    );

    return formatDuration({
      hours: newDuration > 60 ? Math.round(newDuration / 60) : 0,
      minutes: newDuration % 60,
    });
  };

  const isSelectionValid = () =>
    (values.end !== '00:00:00' &&
      isAfter(
        formatDateFromTime(values.start),
        formatDateFromTime(values.end === '00:00:00' ? '24:00:00' : values.end)
      )) ||
    isEqual(
      formatDateFromTime(values.start),
      formatDateFromTime(values.end === '00:00:00' ? '24:00:00' : values.end)
    )
      ? false
      : true;

  const isSelectionAvailable = (): AlertMessage => {
    if (availableTimes.length > 0) {
      const results = availableTimes.map((timeBlock) => {
        const isStartBefore = isBefore(
          formatDateFromTime(values.start),
          formatDateFromTime(timeBlock.start)
        );
        const isStartEqual = isEqual(
          formatDateFromTime(values.start),
          formatDateFromTime(timeBlock.start)
        );
        const isEndAfter = isAfter(
          formatDateFromTime(values.end),
          formatDateFromTime(timeBlock.end)
        );
        const isEndEqual = isEqual(
          formatDateFromTime(values.end),
          formatDateFromTime(timeBlock.end)
        );

        if (isStartBefore === true || isEndAfter === true) {
          return 'error';
        } else if (isStartEqual === true || isEndEqual === true) {
          return 'warning';
        } else {
          return 'success';
        }
      });

      if (results.find((result) => result === 'success')) {
        return {
          isAvailable: true,
          message: '',
          messageType: 'success',
        };
      } else if (results.find((result) => result === 'warning')) {
        return {
          isAvailable: true,
          message:
            'This time block is back to back with one of the existing unavailable times. Consider editting the existing time block instead.',
          messageType: 'warning',
        };
      } else {
        return {
          isAvailable: false,
          message: 'Selected time must be within one of the available time blocks.',
          messageType: 'error',
        };
      }
    } else {
      return {
        isAvailable: true,
        message: '',
        messageType: 'success',
      };
    }
  };

  const isSelectionChanged = (message, messageType, saveDisabled) => {
    if (
      (values.start !== '' &&
        isEqual(formatDateFromTime(values.start), formatDateFromTime(formState.start)) ===
          false) ||
      isEqual(formatDateFromTime(values.end), formatDateFromTime(formState.end)) ===
        false ||
      message !== formState.alertMessage.message ||
      messageType !== formState.alertMessage.messageType ||
      saveDisabled !== formState.saveDisabled
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (selectedDay?.length > 0) {
      const unavailableTimes =
        state.week[selectedDay]?.length > 0
          ? state.week[selectedDay].map((timeBlock) => {
              return {
                start: formatStartTime(
                  timeBlock.TimeOfDay.Hours,
                  timeBlock.TimeOfDay.Minutes
                ),
                end: formatEndTime(
                  timeBlock.TimeOfDay.Hours,
                  timeBlock.TimeOfDay.Minutes,
                  timeBlock.DurationMinutes
                ),
              };
            })
          : [];

      if (unavailableTimes.length > 0) {
        const newAvailableTimes: TimeBlock[] = [];
        unavailableTimes.forEach((timeBlock, i) => {
          if (i === 0) {
            if (timeBlock.start !== '0:00') {
              newAvailableTimes.push({
                start: '00:00:00',
                end: timeBlock.start,
              });
              return;
            }
            newAvailableTimes.push({
              start: timeBlock.end,
              end:
                i === unavailableTimes.length - 1
                  ? '24:00:00'
                  : unavailableTimes[i + 1].start,
            });
          } else if (i === unavailableTimes.length - 1) {
            if (timeBlock.end !== '24:00') {
              newAvailableTimes.push({
                start: timeBlock.end,
                end: '24:00:00',
              });
            }
            formDispatch({
              type: 'SET_AVAILABLE_TIMES',
              payload: newAvailableTimes,
            });
          } else {
            if (timeBlock.end !== unavailableTimes[i + 1].start) {
              newAvailableTimes.push({
                start: timeBlock.end,
                end: unavailableTimes[i + 1].start,
              });
            }
          }
        });
      }
    }
  }, [selectedDay]);

  useEffect(() => {
    seedValues({
      start: availableTimes.length > 0 ? availableTimes[0].start : '00:00:00',
      end:
        availableTimes.length > 0
          ? availableTimes[availableTimes.length - 1].end
          : '24:00:00',
    });
  }, [availableTimes]);

  useEffect(() => {
    const isValid = isSelectionValid();
    const availability = isSelectionAvailable();
    const disable =
      isValid === false || availability.messageType === 'error' ? true : false;
    const isChanged = isSelectionChanged(
      availability.message,
      availability.messageType,
      disable
    );

    if (isChanged === true) {
      formDispatch({
        type: 'UPDATE_TIMES',
        payload: {
          start: values.start ? values.start : '00:00:00',
          end: values.end === '00:00:00' ? '24:00:00' : values.end,
          alertMessage: {
            message:
              isValid === false
                ? 'Selected start time must be before selected end time'
                : availability.message,
            messageType: isValid === false ? 'error' : availability.messageType,
          },
          disableSave: disable,
        },
      });
    }
  }, [values.start, values.end]);

  const handleAddTimeBlock = () => {
    dispatch({
      type: 'CREATE_TIMEBLOCK',
      payload: {
        hours: parseInt(values.start?.slice(0, 2) || '0'),
        minutes: parseInt(values.start?.slice(3, 5) || '0'),
        duration: differenceInMinutes(
          formatDateFromTime(values.end === '00:00:00' ? '24:00:00' : values.end),
          formatDateFromTime(values.start)
        ),
        dayLabel: state.selectedDay,
      },
    });
    handlePost();
    modalProps.onClose();
  };

  return (
    <Modal {...modalProps}>
      <Modal.Header>Add a time block to {selectedDay}</Modal.Header>
      <Modal.Body css={modalOverflowStyle}>
        {alertMessage?.message !== '' && (
          <Alert type={alertMessage?.messageType || 'success'}>
            {alertMessage.message}
          </Alert>
        )}
        <form>
          <TimeField css={fieldStyle} label="Start" {...getFieldProps('start')} />
          <TimeField css={fieldStyle} label="End" {...getFieldProps('end')} />
        </form>
        <Text textAlign="center">Duration: {duration()}</Text>
      </Modal.Body>
      <Modal.Actions
        onPrimaryClick={handleAddTimeBlock}
        primaryLabel="Save"
        disablePrimary={saveDisabled}
        onSecondaryClick={modalProps.onClose}
        secondaryLabel="Cancel"
      />
      <Modal.Body style={{ marginTop: theme.spacing(2) }}>
        {availableTimes?.length > 0 && (
          <>
            <div css={tableHeaderStyle(false)}>
              <Text
                size="medium"
                weight="bold"
                textAlign="center"
                css={tableHeaderTextStyle}
              >
                {selectedDay}'s Available Times
              </Text>
            </div>
            <ReactTable
              columns={columns}
              data={availableTimes}
              pageSize={availableTimes.length || 0}
              showPagination={false}
              css={[reactTableStyle, tableStyle]}
            />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
