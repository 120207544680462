import React from 'react';
import { format, parseISO } from 'date-fns';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { Heading, IconButton, SyncIcon, Text } from '@weave/design-system';

interface OnlineSchedulingTabHeaderProps {
  children?: JSX.Element[] | JSX.Element;
  createdAtDate?: string;
  refresh?: () => void;
  refreshLabel?: string;
  title: string;
}

export const OnlineSchedulingTabHeader = ({
  children,
  createdAtDate,
  title,
  refresh,
  refreshLabel,
}: OnlineSchedulingTabHeaderProps) => {
  return (
    <Heading
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: ${theme.spacing(1)};
      `}
    >
      {title}
      <div
        css={css`
          display: flex;
          align-content: center;
          justify-content: center;
        `}
      >
        {createdAtDate && (
          <Text
            css={css`
              padding-top: ${theme.spacing(1.35)};
              margin: ${theme.spacing(0, 1, 0, 0)};
            `}
          >
            Created At: {format(parseISO(createdAtDate), 'MM/dd/yy hh:mm a')}
          </Text>
        )}

        {children}

        {refresh && refreshLabel && (
          <IconButton onClick={refresh} label={refreshLabel} showLabelOnHover>
            <SyncIcon />
          </IconButton>
        )}
      </div>
    </Heading>
  );
};
