import React from 'react';
import { css } from '@emotion/core';
import { styles, Text } from '@weave/design-system';
import { Mapping } from '../../helpers/types';

type ReviewSiteInfoProps = {
  info: Mapping;
};

export const ReviewsSiteInfo = ({ info, ...rest }: ReviewSiteInfoProps) => {
  let area = '';

  if (info.city) {
    area += `${info.city} `;
  }

  if (info.state) {
    area += info.state;
  }

  if (area !== '' && info.zip) {
    area += `, ${info.zip}`;
  }

  return (
    <div css={styles.truncate} {...rest}>
      <Text
        size="large"
        weight="bold"
        css={css`
          ${styles.truncate};
          margin: 0;
        `}
        title={info.name}
      >
        {info.name ? info.name : 'Unnamed Location'}
      </Text>
      {!info.address && !area && (
        <Text size="medium" color="light">
          No Address
        </Text>
      )}
      {info.address && (
        <Text
          size="medium"
          color="light"
          css={css`
            ${styles.truncate};
            margin: 0;
          `}
          title={info.address}
        >
          {info.address}
        </Text>
      )}
      {area && (
        <Text
          size="medium"
          color="light"
          css={css`
            ${styles.truncate};
            margin: 0;
          `}
          title={area}
        >
          {area}
        </Text>
      )}
    </div>
  );
};
