import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';

export const resultsContainer = css`
  width: 100%;
  position: relative;

  .loader {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    margin: ${theme.spacing(1)};
  }

  .clickable {
    cursor: pointer;
    transition: ease-in 0.3s;
  }

  .clickable:hover {
    color: black;
  }

  table {
    width: 100%;
  }

  th,
  td {
    text-align: left;
  }

  th {
    line-height: ${theme.spacing(3)};
  }

  tbody tr td {
    max-width: 350px;
  }

  td p {
    padding: 0;
    margin: 0;
    font-weight: 400;
    color: ${theme.colors.gray500};
    font-size: ${theme.fontSize(14)};
    line-height: ${theme.spacing(2)};
  }
`;
