import React from 'react';
import { useResource } from '../../../shared/hooks';
import { theme } from '@weave/theme-original';
import { Text } from '@weave/design-system';
import { css } from '@emotion/core';

type Preferences = {
  preferences: {
    language: string;
  };
};

const container = css`
  display: flex;
  margin: ${theme.spacing(2, 0)};
  p {
    margin: auto 0;
    :first-of-type {
      min-width: 150px;
      width: 30%;
      padding-left: ${theme.spacing(2)};
      padding-right: theme.spacing(2);
      text-align: right;
    }
    :last-of-type {
      width: 50%;
      min-width: 250px;
      padding-left: 0;
    }
  }
`;

export const PatientPrefences = ({ personID, locationID }) => {
  const preferencesURL = `https://api.weaveconnect.com/support/v1/locations/${locationID}/persons/${personID}`;
  const { data } = useResource<Preferences>({
    url: preferencesURL,
    deps: [preferencesURL],
    suppressFetch: !locationID || !personID,
  });
  const { preferences } = data;
  const language =
    preferences?.language === 'Unknown' ? 'English (Default)' : preferences?.language;

  return (
    <div css={container}>
      <Text weight="bold">Language</Text>
      <Text>{language}</Text>
    </div>
  );
};
