import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';

export const faxStyles = css`
  --title-background-color: #f3f7fc;
  --title-border-color: #e2e7f1;

  .fax-info-title {
    font-size: 1rem;
    color: black;
    background-color: var(--title-background-color);
    margin-right: 24px;
    text-align: center;
    border: 1px solid var(--title-border-color);
    padding: 0.5rem 0.75rem;
    display: inline-block;
    width: 250px;
  }

  .round-circle {
    border-radius: 50%;
    height: 14px;
    width: 14px;
  }

  .send-test-fax-circle {
    border-radius: 50%;
    height: 150px;
    width: 150px;
    background-color: ${theme.colors.error};
    box-shadow: 1px 1px 1px #b5b5b5;
    cursor: pointer;
    margin-bottom: 10px;
    color: ${theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fax-circle-success {
    background-color: ${theme.colors.success};
  }

  .fax-circle-failure {
    background-color: ${theme.colors.error};
  }

  .fax-circle-inProgress {
    background-color: ${theme.colors.warning};
  }

  .fax-info-text {
    font-size: 1rem;
    margin-left: ${theme.spacing(1)};
  }

  .box-row {
    display: flex;
    align-items: center;
  }
  .box-row .success {
    color: ${theme.colors.success};
  }
  .box-row .failure {
    color: ${theme.colors.error};
  }
  .box-row .inProgress {
    color: ${theme.colors.warning};
  }

  .box-column {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-transform: capitalize;
  }
  .box-column .success {
    color: ${theme.colors.success};
  }
  .box-column .failure {
    color: ${theme.colors.error};
  }
  .box-column .info {
    color: ${theme.colors.weaveBlue};
  }

  .twilio-title {
    font-size: 1.125rem;
    color: ${theme.colors.pressedBlue};
    margin-top: 10px;
  }

  .tooltip-inner {
    max-width: 350px;
  }
`;

export const twilioStyles = css`
  p {
    font-size: 0.875rem;
  }
  p span {
    font-weight: bold;
    font-size: 1rem;
  }
`;
