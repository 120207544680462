import { createAction, handleActions } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { CustomAxios } from '../../axios';

export type OrderHistoryPayload = string;

export const orderHistory = createAction<OrderHistoryPayload>('ORDER_HISTORY');
export const orderHistorySuccess = createAction('ORDER_HISTORY_SUCCESS');
export const orderHistoryFailure = createAction<Error>('ORDER_HISTORY_FAILURE');
export const orderHistoryReset = createAction('ORDER_HISTORY_RESET');

export const handleOrderHistory = function* (action) {
  try {
    const params = action.payload;
    const response = yield call(CustomAxios.get, `/support/v1/order${params}`);
    yield put(orderHistorySuccess(response.data.data));
  } catch (error: any) {
    yield put(orderHistoryFailure(error));
  }
};

export const orderHistorySaga = function* () {
  yield all([takeEvery(orderHistory.toString(), handleOrderHistory)]);
};

const defaultState = {
  orders: [],
  loading: false,
};

export const orderHistoryReducer = handleActions<any, any>(
  {
    [orderHistory.toString()]: (state) => Object.assign({}, state, { loading: true }),
    [orderHistorySuccess.toString()]: (state, action) => ({
      ...state,
      orders: action.payload,
      loading: false,
    }),
    [orderHistoryFailure.toString()]: (state, action) => ({
      ...state,
      error: action.payload,
      loading: false,
    }),
    [orderHistoryReset.toString()]: () => defaultState,
  },
  defaultState
);
