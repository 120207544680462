import React from 'react';
import ReactTable from 'react-table';
import { css } from 'emotion';
import { theme } from '@weave/theme-original';
import { Text } from '@weave/design-system';
import { Search, Button } from '@weave/platform-ui-components';

import { TableStyles } from '../../styles/table-style';
import { Page } from '../shared';
import { LocationModel } from '../../redux/actions/location';
import UserListModel from '../users/UserListModel';

interface DesktopClientModel {
  ConnectionID: string;
  LocationID: string;
  UserID: string;
  LastPing: string;
  ConnectedAt: string;
  ClientVersion: string;
  Metadata: {
    client_version: string;
    location: string;
    location_id: string;
    os: string;
    os_version: string;
    practice: string;
    shell_version: string;
    workstation: string;
  };
}

interface DesktopClientUser extends DesktopClientModel {
  userData?: UserListModel;
}

interface Props {
  desktopClients: DesktopClientModel[];
  users: UserListModel[];
  getDesktopClients: (locationId: string) => void;
  getUsers: (limit?: number) => void;
  loading: boolean;
  location: LocationModel;
}

interface State {
  filteredDesktopClients: DesktopClientUser[];
  searchText: string;
  filteredDesktopClientsTotal: number;
}

export class DesktopClients extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      filteredDesktopClients: this.groupClientsWithUsers(
        props.desktopClients,
        props.users
      ),
      filteredDesktopClientsTotal: 0,
    };
  }

  componentDidMount() {
    if (this.props.location.LocationID) {
      this.props.getDesktopClients(this.props.location.LocationID);
      this.props.getUsers();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.location.LocationID !== nextProps.location.LocationID) {
      this.props.getDesktopClients(nextProps.location.LocationID);
      this.props.getUsers();
    } else if (
      this.props.desktopClients !== nextProps.desktopClients ||
      this.props.users !== nextProps.users
    ) {
      const filteredDesktopClients = this.filter(
        this.state.searchText,
        nextProps.desktopClients
      );
      const filteredDesktopClientsTotal =
        this.getFilteredDesktopClientsTotal(filteredDesktopClients);

      this.setState({
        filteredDesktopClients: this.groupClientsWithUsers(
          filteredDesktopClients,
          nextProps.users
        ),
        filteredDesktopClientsTotal,
      });
    }
  }

  groupClientsWithUsers(desktopClientList: DesktopClientModel[], users: UserListModel[]) {
    if (!users.length) {
      return desktopClientList;
    }

    // Group the user data with the desktop client data.
    const clientUserData = desktopClientList.reduce(
      (acc: DesktopClientUser[], client) => {
        const user = users.find((currUser) => currUser.UserID === client.UserID);
        if (user) {
          return [...acc, { ...client, userData: user }];
        } else {
          return acc;
        }
      },
      []
    );

    return clientUserData;
  }

  filter = (searchText: string, desktopClientList: DesktopClientModel[]) => {
    if (searchText) {
      searchText = searchText.toLowerCase();
      return desktopClientList.filter((client) => {
        return (
          client.Metadata.workstation.toLowerCase().includes(searchText) ||
          client.Metadata.shell_version.toLowerCase().includes(searchText) ||
          client.ClientVersion.toLowerCase().includes(searchText) ||
          client.Metadata.os.toLowerCase().includes(searchText) ||
          client.Metadata.os_version.toLowerCase().includes(searchText) ||
          client.ConnectedAt.toLowerCase().includes(searchText)
        );
      });
    } else {
      return desktopClientList;
    }
  };

  getFilteredDesktopClientsTotal = (filteredDesktopClientList: DesktopClientModel[]) => {
    const uniqs = filteredDesktopClientList.reduce(
      (acc: Set<string>, client: DesktopClientModel) => {
        return acc.add(client.Metadata.workstation);
      },
      new Set()
    );

    return uniqs.size;
  };

  handleSearchChange = (value: string) => {
    const searchText = value.trim();
    const filteredDesktopClients = this.filter(searchText, this.props.desktopClients);
    const filteredDesktopClientsTotal =
      this.getFilteredDesktopClientsTotal(filteredDesktopClients);

    this.setState({
      searchText,
      filteredDesktopClients: this.groupClientsWithUsers(
        filteredDesktopClients,
        this.props.users
      ),
      filteredDesktopClientsTotal,
    });
  };

  refresh = () => {
    this.props.getDesktopClients(this.props.location.LocationID);
    this.props.getUsers();
  };

  render() {
    return (
      <Page
        title="Desktop Clients"
        headerActions={<Button onClick={this.refresh}>Refresh Data</Button>}
      >
        <form
          onSubmit={this.refresh}
          className={css`
            margin: ${theme.spacing(3, 0)};
          `}
        >
          <Search
            name="client-search"
            value={this.state.searchText}
            onChange={this.handleSearchChange}
            placeholder="Search"
            className={css`
              display: inline-block;
              width: 200px;
            `}
          />
        </form>
        <Text size="medium">Total: {this.state.filteredDesktopClientsTotal}</Text>
        <ReactTable
          columns={[
            {
              Header: 'Email',
              accessor: 'userData.Username',
            },
            {
              Header: 'First Name',
              accessor: 'userData.FirstName',
            },
            {
              Header: 'Last Name',
              accessor: 'userData.LastName',
            },
            {
              Header: 'SIP Account',
              accessor: 'Metadata.workstation',
            },
            {
              Header: 'Shell Version',
              accessor: 'Metadata.shell_version',
            },
            {
              Header: 'Client Version',
              accessor: 'Metadata.client_version',
            },
            {
              Header: 'OS',
              accessor: 'Metadata.os',
            },
            {
              Header: 'OS Version',
              accessor: 'Metadata.os_version',
            },
            {
              Header: 'Connected',
              accessor: 'ConnectedAt',
            },
          ]}
          data={this.state.filteredDesktopClients}
          loading={this.props.loading}
          defaultPageSize={Math.min(this.state.filteredDesktopClients.length || 5, 25)}
          css={TableStyles}
        />
      </Page>
    );
  }
}
