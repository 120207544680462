export enum PortalDetails {
  desktop = 'Desktop Client',
  portal = 'Admin Portal',
  wam = 'WAM',
}

export type OutageAlert = {
  application: string;
  isOutageActive: boolean;
  message: string;
  outageName: string;
  path: Array<string>;
  outageId: string;
  createdBy: string;
  updatedBy: string;
  resolvedBy: string;
  createdOn: string;
  updatedOn: string;
  resolvedOn: string;
  isDismissed: boolean;
  isDeleted: boolean;
  deletedBy: string;
  deletedOn: string;
};

export type OutageAlertExtended = OutageAlert & {
  docId: string;
};

export enum applicationName {
  wam = 'WAM',
}
