import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';

export const filtersContainer = css`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  font-size: ${theme.fontSize(16)};
  line-height: ${theme.spacing(2)};
  p {
    margin: ${theme.spacing(0, 0, 1, 0)};
  }

  .filters {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
  }

  .filter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    margin-right: ${theme.spacing(1)};
  }

  .filter-key {
    font-size: ${theme.fontSize(16)};
    font-weight: 600;
    margin-right: ${theme.spacing(1)};
  }

  .filter-value {
    cursor: pointer;
    display: inline-flex;
    -webkit-box-align: center;
    height: ${theme.spacing(3)};
    padding: ${theme.spacing(0, 1)};
    margin: ${theme.spacing(0, 0.5)};
    border: 1px solid ${theme.colors.gray400};
    border-radius: 4px;
    align-items: center;
    font-size: ${theme.fontSize(12)};
    font-weight: inherit;
    color: ${theme.colors.gray600};
    white-space: nowrap;
    line-height: 0;
    outline: none;
    svg {
      margin-left: ${theme.spacing(1)};
    }
  }
`;
