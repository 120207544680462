import validator from 'validator';

export const isPhoneNumber = (phoneNumber): boolean => {
  return validator.isMobilePhone(phoneNumber);
};

export const isEmailValid = (email: string): boolean => {
  if (!email) {
    return false;
  }

  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(email);
};

type ValidationResponse = boolean;

export const validMinLength = (str: string, len = 8): ValidationResponse => {
  return str.length >= len;
};

export const validMaxLength = (str: string, len = 20): ValidationResponse => {
  return str.length <= len;
};

export const validPresence = (str: string): ValidationResponse => {
  return Boolean(str.trim());
};

export const excludesProhibited = (str: string, invalid: string): ValidationResponse => {
  return !str.includes(invalid);
};

export const validMatch = (str: string, comp: string): ValidationResponse => {
  return str === comp;
};
