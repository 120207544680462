import React from 'react';
import { DeliveryReportChart } from '../../../shared/charts/delivery-reports-chart';

import {
  NotificationReportDetails,
  ReminderDetails,
} from '../../../../redux/actions/notifications-settings/notification-settings.types';

interface Props {
  deliveryReports: NotificationReportDetails[];
  selectedAutoReminder: ReminderDetails;
  getNotificationDeliverySummary: (id: string) => void;
}

export class LogWrapper extends React.Component<Props> {
  componentDidMount() {
    this.props.getNotificationDeliverySummary(this.props.selectedAutoReminder.id);
  }

  render() {
    const { deliveryReports } = this.props;

    if (!deliveryReports.length) {
      return null;
    }

    return (
      <div>
        <DeliveryReportChart deliverySummary={this.props.deliveryReports} />
      </div>
    );
  }
}
