import React, { FC } from 'react';
import { css } from 'emotion';
import { Text } from '@weave/design-system';
import { Input } from '@weave/platform-ui-components';

interface Props {
  error?: boolean;
  helpText?: string;
  name: string;
  onChange: (value: string) => void;
  value: string;
}

export const DeviceRowText: FC<Props> = ({
  error = false,
  helpText,
  name,
  value,
  onChange,
}) => {
  return (
    <li
      className={css`
        margin-top: 24px;
      `}
    >
      <Text
        size="medium"
        weight="bold"
        className={css`
          margin-bottom: 8px;
        `}
      >
        {name}
      </Text>
      <Input
        className={css`
          max-width: 300px;
        `}
        error={error}
        value={value}
        onChange={(event) => onChange(event.target.value)}
      />
      {helpText && error && <Text color="error">{helpText}</Text>}
    </li>
  );
};
