import { createAction, handleActions } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { CustomAxios, getErrorMessage } from '../../axios';
import { showError } from '@weave/alert-system';

export type DesktopClientsPayload = {
  locationId: string;
};
export type DesktopClientsResultPayload = any;

export const getDesktopClients = createAction<DesktopClientsPayload>('DESKTOP_CLIENTS');
export const desktopClientsSuccess = createAction<DesktopClientsResultPayload>(
  'DESKTOP_CLIENTS_SUCCESS'
);
export const desktopClientsFailure = createAction<Error>('DESKTOP_CLIENTS_FAILURE');

export const handleDesktopClients = function* (action) {
  const locationId: string = action.payload.locationId;

  try {
    const response = yield call(
      CustomAxios.get,
      `support/v1/connections/${locationId}/desktopclient`
    );
    yield put(desktopClientsSuccess(response.data.data));
  } catch (error: any) {
    yield put(desktopClientsFailure(error));
    yield put(showError(getErrorMessage(error)));
  }
};

export const desktopClientsSaga = function* () {
  yield all([takeEvery(getDesktopClients.toString(), handleDesktopClients)]);
};

const defaultState = {
  loading: false,
  data: [],
};

export const desktopClientsReducer = handleActions(
  {
    [getDesktopClients.toString()]: (state) =>
      Object.assign({}, state, { loading: true }),
    [desktopClientsSuccess.toString()]: (state, action) =>
      Object.assign({}, state, {
        data: action.payload,
        loading: false,
      }),
    [desktopClientsFailure.toString()]: (state, action) =>
      Object.assign({}, state, {
        error: action.payload,
        loading: false,
      }),
  },
  defaultState
);
