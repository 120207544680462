import React from 'react';
import { Text, Modal } from '@weave/design-system';
import { css } from '@emotion/core';

const container = css`
  width: 400px;
  padding: 15px;
`;

export const SmsMessage = ({ sms, modalProps }) => {
  return (
    <Modal {...modalProps}>
      <div css={container}>
        <Text weight="bold">Message</Text>
        <Text>{sms.body}</Text>
      </div>
    </Modal>
  );
};
