import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { css } from 'emotion';

const typeaheadContainer = css`
  > .form-control {
    font-size: 10px;
  }
  input.rbt-input-hint {
    display: none;
  }
`;

const states = [
  { country: 'US', id: 'AL', label: 'Alabama' },
  { country: 'US', id: 'AK', label: 'Alaska' },
  { country: 'US', id: 'AZ', label: 'Arizona' },
  { country: 'US', id: 'AR', label: 'Arkansas' },
  { country: 'US', id: 'CA', label: 'California' },
  { country: 'US', id: 'CO', label: 'Colorado' },
  { country: 'US', id: 'CT', label: 'Connecticut' },
  { country: 'US', id: 'DE', label: 'Delaware' },
  { country: 'US', id: 'DC', label: 'District of Columbia' },
  { country: 'US', id: 'FL', label: 'Florida' },
  { country: 'US', id: 'GA', label: 'Georgia' },
  { country: 'US', id: 'HI', label: 'Hawaii' },
  { country: 'US', id: 'ID', label: 'Idaho' },
  { country: 'US', id: 'IL', label: 'Illinois' },
  { country: 'US', id: 'IN', label: 'Indiana' },
  { country: 'US', id: 'IA', label: 'Iowa' },
  { country: 'US', id: 'KS', label: 'Kansas' },
  { country: 'US', id: 'KY', label: 'Kentucky' },
  { country: 'US', id: 'LA', label: 'Louisiana' },
  { country: 'US', id: 'ME', label: 'Maine' },
  { country: 'US', id: 'MD', label: 'Maryland' },
  { country: 'US', id: 'MA', label: 'Massachusetts' },
  { country: 'US', id: 'MI', label: 'Michigan' },
  { country: 'US', id: 'MN', label: 'Minnesota' },
  { country: 'US', id: 'MS', label: 'Mississippi' },
  { country: 'US', id: 'MO', label: 'Missouri' },
  { country: 'US', id: 'MT', label: 'Montana' },
  { country: 'US', id: 'NE', label: 'Nebraska' },
  { country: 'US', id: 'NV', label: 'Nevada' },
  { country: 'US', id: 'NH', label: 'New Hampshire' },
  { country: 'US', id: 'NJ', label: 'New Jersey' },
  { country: 'US', id: 'NM', label: 'New Mexico' },
  { country: 'US', id: 'NY', label: 'New York' },
  { country: 'US', id: 'NC', label: 'North Carolina' },
  { country: 'US', id: 'ND', label: 'North Dakota' },
  { country: 'US', id: 'OH', label: 'Ohio' },
  { country: 'US', id: 'OK', label: 'Oklahoma' },
  { country: 'US', id: 'OR', label: 'Oregon' },
  { country: 'US', id: 'PA', label: 'Pennsylvania' },
  { country: 'US', id: 'PR', label: 'Puerto Rico' },
  { country: 'US', id: 'RI', label: 'Rhode Island' },
  { country: 'US', id: 'SC', label: 'South Carolina' },
  { country: 'US', id: 'SD', label: 'South Dakota' },
  { country: 'US', id: 'TN', label: 'Tennessee' },
  { country: 'US', id: 'TX', label: 'Texas' },
  { country: 'US', id: 'UT', label: 'Utah' },
  { country: 'US', id: 'VT', label: 'Vermont' },
  { country: 'US', id: 'VA', label: 'Virginia' },
  { country: 'US', id: 'WA', label: 'Washington' },
  { country: 'US', id: 'WV', label: 'West Virginia' },
  { country: 'US', id: 'WI', label: 'Wisconsin' },
  { country: 'US', id: 'WY', label: 'Wyoming' },
  { country: 'CA', id: 'AB', label: 'Alberta' },
  { country: 'CA', id: 'BC', label: 'British Columbia' },
  { country: 'CA', id: 'MB', label: 'Manitoba' },
  { country: 'CA', id: 'NB', label: 'New Brunswick' },
  { country: 'CA', id: 'NL', label: 'Newfoundland and Labrador' },
  { country: 'CA', id: 'NT', label: 'Northwest Territories' },
  { country: 'CA', id: 'NS', label: 'Nova Scotia' },
  { country: 'CA', id: 'NU', label: 'Nunavut' },
  { country: 'CA', id: 'ON', label: 'Ontario' },
  { country: 'CA', id: 'PE', label: 'Prince Edward Island' },
  { country: 'CA', id: 'QC', label: 'Quebec' },
  { country: 'CA', id: 'SK', label: 'Saskatchewan' },
  { country: 'CA', id: 'YT', label: 'Yukon' },
];

interface Props {
  onChange: (selectedValue) => void;
}

export const StatesTypeahead = (props: Props) => {
  return (
    <Typeahead
      className={typeaheadContainer}
      onChange={(selected) => {
        props.onChange(selected);
      }}
      options={states}
      highlightOnlyResult={true}
    />
  );
};
