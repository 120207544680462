import { connect } from 'react-redux';
import { SmsWritebacks } from './sms-writebacks.component';
import {
  resetWritebacksByAppt,
  writebacksByAppt,
} from '../../../../../redux/actions/writebacks/writebacks-by-appt/writebacks-by-appt.action';
import {
  selectCurrentLocationId,
  selectCurrentLocationTimeZone,
} from '../../../../../redux/actions/location/current-location';
import { Store } from '../../../../../redux/store/store.model';
interface OwnProps {
  apptID: string;
}

export const SmsWritebacksContainer = connect(
  (store: Store, ownProps: OwnProps) => ({
    locationID: selectCurrentLocationId(store),
    smsDetails: store.smsDetails.data,
    writebacks: store.writebacksByAppt.data,
    apptID: ownProps.apptID,
    timezone: selectCurrentLocationTimeZone(store),
  }),
  {
    getWritebacksByAppt: writebacksByAppt,
    resetWritebacksByAppt: resetWritebacksByAppt,
  }
)(SmsWritebacks);
