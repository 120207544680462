import React from 'react';
import { cx } from 'emotion';
import moment from 'moment-timezone';

import * as styles from '../../patient-list.style.js';
import { dateTimeZoneFormat } from '../../../../../../helpers/utils';
import { UuidCopier } from '../../../uuid-copier';
import { injectParams, OTHER } from '../../../../../../constants/paths';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  orderHistory,
  orderHistoryReset,
} from '../../../../../../redux/actions/orders/order-history.action';
import {
  selectCurrentLocationId,
  selectCurrentLocationTimeZone,
} from '../../../../../../redux/actions/location';

export const PatientOrderHistory = ({ personID }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentLocation = useSelector(selectCurrentLocationId);
  const orderList = useSelector((store: any) => store.orderHistory.orders);
  const timezone = useSelector(selectCurrentLocationTimeZone);

  useEffect(() => {
    return () => {
      dispatch(orderHistoryReset());
    };
  }, []);

  useEffect(() => {
    if (personID) dispatch(orderHistory('?personID=' + personID));
  }, [personID]);

  const routeToDebug = (order) => {
    dispatch(orderHistoryReset());
    history.push(
      injectParams(
        OTHER.autoMessageDubugger,
        { id: currentLocation },
        {
          type: 'order',
          personId: order.PersonID,
          id: order.EventID,
        }
      )
    );
  };

  return (
    <div className={styles.cardComponent}>
      <div className={styles.flexDisplay}>
        <p className={cx(styles.listHeader, styles.leftListStyle)}>Order History</p>
        <p className={cx(styles.listHeader, styles.rightListStyle)}>{orderList.length}</p>
      </div>
      <div className={styles.listBox}>
        {!orderList.length && <p className={styles.noData}>No Data</p>}
        {orderList.map((order) => (
          <div key={order.EventID} className={styles.listStyle}>
            <div className={styles.container}>
              <p className="left">Event Date</p>
              <p>
                {moment(order.EventTime.seconds * 1000)
                  .tz(timezone)
                  .format(dateTimeZoneFormat)}
              </p>
            </div>
            <div className={styles.container}>
              <p className="left">Description</p>
              <p>{order.Description}</p>
            </div>
            <div className={styles.container}>
              <p className="left">Sub Type</p>
              <p>{order.EventSubType}</p>
            </div>
            <div className={styles.container}>
              <p className="left">PMID</p>
              <p>{order.EventPMID}</p>
            </div>
            <div className={styles.container}>
              <p className="left">ID</p>
              <UuidCopier uuid={order.EventID} />
            </div>
            <button className={styles.actionBtn} onClick={() => routeToDebug(order)}>
              Debug
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
