import { createRequestStateSelector } from '@weave/alert-system';
import { omitBy } from 'lodash';
import { createSelector } from 'reselect';
import { Store } from '../../store/store.model';
import { selectDepartment } from '../department/department.selectors';
import { selectPhoneNumbersArray } from '../phone-numbers';
import { DepartmentPhoneNumbersActionTypes } from './department-phone-numbers.types';

export const selectDepartmentPhoneNumbersFetchingAll = createRequestStateSelector(
  DepartmentPhoneNumbersActionTypes.RequestList
);
export const selectDepartmentPhoneNumbersFetching = createRequestStateSelector(
  DepartmentPhoneNumbersActionTypes.RequestGet
);
export const selectDepartmentPhoneNumbersUpdating = createRequestStateSelector(
  DepartmentPhoneNumbersActionTypes.RequestUpdate
);
export const selectDepartmentPhoneNumbersCreating = createRequestStateSelector(
  DepartmentPhoneNumbersActionTypes.RequestCreate
);
export const selectDepartmentPhoneNumbersDeleting = createRequestStateSelector(
  DepartmentPhoneNumbersActionTypes.RequestDelete
);

const selector = (state: Store) => state.departmentPhoneNumbers;

export const selectAllDepartmentPhoneNumbers = createSelector(
  selector,
  selectDepartmentPhoneNumbersFetchingAll,
  (state, reqState) => ({
    ...reqState,
    departmentPhoneNumbers: state,
  })
);

export const selectDepartmentPhoneNumbers = (departmentId: string) =>
  createSelector(
    selectAllDepartmentPhoneNumbers,
    ({ departmentPhoneNumbers, ...rest }) => ({
      ...rest,
      departmentPhoneNumbers: omitBy(
        departmentPhoneNumbers,
        (num) => num.departmentId !== departmentId
      ),
    })
  );

export const selectDepartmentPhoneNumbersArray = (departmentId: string) =>
  createSelector(
    selectDepartmentPhoneNumbers(departmentId),
    ({ departmentPhoneNumbers, ...rest }) => ({
      ...rest,
      departmentPhoneNumbers: Object.values(departmentPhoneNumbers).sort((a, b) =>
        a.label.localeCompare(b.label)
      ),
    })
  );

export const selectDepartmentPhoneNumber = (departmentPhoneNumberId: string) =>
  createSelector(selectAllDepartmentPhoneNumbers, (state) => ({
    ...state,
    departmentPhoneNumber: state.departmentPhoneNumbers[departmentPhoneNumberId],
  }));

/**
 * Selects phone numbers that have not been assigned to a department
 */
export const selectAvailablePhoneNumbers = createSelector(
  selectPhoneNumbersArray,
  selectAllDepartmentPhoneNumbers,
  ({ phoneNumbers, ...phoneReqState }, { departmentPhoneNumbers, ...depReqState }) => {
    const usedPhoneNumberIds = Object.values(departmentPhoneNumbers).map(
      (num) => num.phoneNumberId
    );
    return {
      error: phoneReqState.error || depReqState.error,
      loading: phoneReqState.loading || depReqState.loading,
      availablePhoneNumbers: phoneNumbers.filter(
        (phoneNumber) => !usedPhoneNumberIds.includes(phoneNumber.id)
      ),
    };
  }
);

/**
 * Selects phone number ids that have not been assigned to a department
 */
export const selectAvailablePhoneNumberIds = createSelector(
  selectAvailablePhoneNumbers,
  ({ availablePhoneNumbers, ...rest }) => ({
    ...rest,
    availablePhoneNumberIds: availablePhoneNumbers.map((num) => num.id),
  })
);

/**
 * Aggregate selector for optimizing department phone numbers section
 */
export const selectDepartmentPhoneNumbersAggregate = (departmentId) =>
  createSelector(
    selectPhoneNumbersArray,
    selectDepartmentPhoneNumbersArray(departmentId),
    selectAvailablePhoneNumberIds,
    selectDepartment(departmentId),
    (
      { phoneNumbers, ...reqState },
      { departmentPhoneNumbers, ...deptPhoneNumsReqState },
      { availablePhoneNumberIds, ...availReqState },
      { department, ...departmentReqState }
    ) => ({
      loading:
        reqState.loading ||
        deptPhoneNumsReqState.loading ||
        availReqState.loading ||
        departmentReqState.loading,
      error:
        reqState.error ||
        deptPhoneNumsReqState.error ||
        availReqState.error ||
        departmentReqState.error,
      phoneNumbers,
      departmentPhoneNumbers,
      availablePhoneNumberIds,
      department,
    })
  );
