import React from 'react';
import { Modal, Text, ModalControlModalProps } from '@weave/design-system';

interface Props {
  header: string;
  body: string;
  actionLabel: string;
  messageModalProps: ModalControlModalProps;
  closeMessageModal: () => void;
  onActionLabelClick: () => void;
}

export const MessageDialogue = ({
  header,
  body,
  actionLabel,
  messageModalProps,
  closeMessageModal,
  onActionLabelClick,
}: Props) => {
  return (
    <Modal {...messageModalProps} onClose={closeMessageModal}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Body>
        <Text textAlign="center">{body}</Text>
      </Modal.Body>
      <Modal.Actions primaryLabel={actionLabel} onPrimaryClick={onActionLabelClick} />
    </Modal>
  );
};
