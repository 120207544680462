import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { routeChange } from '../../../redux/actions/location';

export const LocationUpdater = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(routeChange(location.pathname));
  }, [location.pathname]);

  return children;
};
