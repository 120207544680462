import React from 'react';

import { Modal } from '../../../shared/modal/modal.component';
import { ChangeLogWrapper } from '../auto-reminder-changelog/auto-reminder-changelog.component';

import {
  ReminderDetails,
  ReminderModalContent,
  ReminderUpdate,
  NotificationToggle,
} from '../../../../redux/actions/notifications-settings/notification-settings.types';
import { LogWrapperContainer } from '../auto-reminder-log-info/auto-reminder-log-info.container';
import { OptionsContainer } from '../auto-reminder-options/auto-reminder-options.container';
import { AutoReminderModalTitle } from './auto-reminder-modal-title.component';
import { AutoReminderTemplates } from '../auto-reminder-template/auto-reminder-templates';
import { EmailTemplate } from '../auto-reminder-template/email-template/email-template';
import * as styles from './auto-reminders-modal.styles';

interface Props {
  content: ReminderModalContent;
  reminderId: string;
  selectedAutoReminder: ReminderDetails;
  selectedNotificationHistory: ReminderDetails[];
  history: any;
  loading: boolean;
  onRequestClose: (event: React.MouseEvent<HTMLElement>) => void;
  getNotificationHistory: (reminderId: string) => void;
  getNotificationsSettings: () => void;
  updateNotificationSettings: (statusMapSettings: ReminderUpdate) => void;
  updateNotificationToggle: (statusMapSettings: NotificationToggle) => void;
  success?: string;
  error?: Error;
}

export class AutoReminderModal extends React.Component<Props> {
  state = {
    content: this.props.content || 'options',
    historicalSettings: '',
    changeLogDetails: null,
  };

  goToReview = () => {
    this.props.history.push(
      this.props.history.location.pathname.replace('auto-reminders', 'reviews')
    );
  };

  ModalTitle = () => {
    const { id, name, type, enabled } = this.props.selectedAutoReminder;
    return (
      <AutoReminderModalTitle
        id={id}
        name={name}
        type={type}
        enabled={enabled}
        updateNotificationSettings={this.props.updateNotificationSettings}
        actionRouter={this.actionRouter}
        handleToggleSwitch={this.handleToggleSwitch}
      />
    );
  };

  SwitchContent = () => {
    const selectedAutoReminder = { ...this.props.selectedAutoReminder };
    const changeLog = this.props.selectedNotificationHistory;
    const changeLogDetails = this.state.changeLogDetails || { template: {} };
    const helperProps = {
      selectedAutoReminder,
      changeLogDetails,
      clearChangeLogSelection: this.clearChangeLogSelection,
    };
    switch (this.state.content) {
      case 'historic-template':
        return (
          <AutoReminderTemplates
            id={this.props.selectedAutoReminder.id}
            settings={this.props.selectedAutoReminder}
            historicTemplates={changeLogDetails.template}
          />
        );
      case 'template':
        return this.props.selectedAutoReminder.delivery_type === 'sms' ? (
          <AutoReminderTemplates
            id={this.props.selectedAutoReminder.id}
            settings={this.props.selectedAutoReminder}
          />
        ) : (
          <EmailTemplate settings={this.props.selectedAutoReminder} />
        );
      case 'options':
        return <OptionsContainer {...helperProps} />;
      case 'logs':
        return <LogWrapperContainer {...helperProps} />;
      case 'changeLog':
        return ChangeLogWrapper(selectedAutoReminder, changeLog, this.actionRouter);
      default:
        return null;
    }
  };

  actionRouter =
    (changeLogDetails = null) =>
    (action) =>
    (e: Event) => {
      e.stopPropagation();
      switch (action) {
        case 'historic-template':
          this.setState({ content: 'historic-template' });
          break;
        case 'advanced_options':
          this.setState({ content: 'options' });
          break;
        case 'logs':
          this.setState({ content: 'logs' });
          break;
        case 'template':
          this.setState({ content: 'template' });
          break;
        case 'changeLog':
          this.setState({ content: 'changeLog' });
          break;
        case 'goToReview':
          this.goToReview();
          break;
        default:
          break;
      }
      if (changeLogDetails) {
        this.setState({ changeLogDetails });
      }
    };

  clearChangeLogSelection = () => {
    this.setState({ changeLogDetails: null });
  };

  handleToggleSwitch = () => {
    const updateSettings = {
      id: this.props.selectedAutoReminder.id,
      enabled: !this.props.selectedAutoReminder.enabled,
    };
    this.props.updateNotificationToggle(updateSettings);
  };

  componentDidMount() {
    this.props.getNotificationHistory(this.props.reminderId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reminderId !== this.props.reminderId) {
      this.props.getNotificationHistory(this.props.reminderId);
      this.props.getNotificationsSettings();
    }
    if (prevProps.content !== this.props.content) {
      this.setState({ content: this.props.content });
    }
  }

  render() {
    if (!this.props.selectedAutoReminder) {
      return null;
    }
    return (
      <Modal
        onRequestClose={this.props.onRequestClose}
        title={this.ModalTitle()}
        className={styles.modalStyle}
      >
        {this.SwitchContent()}
      </Modal>
    );
  }
}
