import React from 'react';
import { TextLink } from '@weave/design-system';
import { css } from '@emotion/core';
import UserListModel from './UserListModel';

type Props = {
  user: UserListModel;
  handleEditOpen: (user: UserListModel) => void;
  deleteUser: (user: UserListModel) => void;
};

export const UserActionButtons = (props: Props) => {
  const { handleEditOpen, deleteUser, user } = props;
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        width: 225px;
      `}
    >
      <TextLink onClick={() => handleEditOpen(user)}>Edit</TextLink>
      <TextLink color="error" onClick={() => deleteUser(user)}>
        Delete
      </TextLink>
    </div>
  );
};
