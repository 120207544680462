import firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyDMWJ9UX8htkr_NXkQhrsP1__XxXjg9dps',
  authDomain: 'weave-outage-alerts.firebaseapp.com',
  projectId: 'weave-outage-alerts',
  storageBucket: 'weave-outage-alerts.appspot.com',
  messagingSenderId: '955124579830',
  appId: '1:955124579830:web:e8d63d95370975c3f81bd7',
  measurementId: 'G-Y1M4WCXKS3',
};

const outageAlertFirebase = firebase.initializeApp(firebaseConfig, 'desktop-client');

export default outageAlertFirebase;
