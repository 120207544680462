import React from 'react';
import { Page } from '../../shared';
import { MultiSyncAppAccess } from './integrations/multi-sync-app-access.component';
import { useSelector } from 'react-redux';

import { selectCurrentLocation } from '../../../redux/actions/location/current-location';

export type Props = {};

export const MultiSyncAppSettings = () => {
  const currentLocation = useSelector(selectCurrentLocation);

  if (!currentLocation?.LocationID) {
    return null;
  }

  return (
    <Page
      title="Multi Location Data Settings"
      subtitle="View only - Instruct the customer to make updates in the Portal"
    >
      <MultiSyncAppAccess currentLocation={currentLocation} />
    </Page>
  );
};
