import React from 'react';
import { css } from '@emotion/core';
import { Mapping } from '../../helpers/types';
import { ReviewsSiteInfo } from './reviews-site-info.component';
import { SetupState } from '../../helpers';
import { SiteErrors } from './site-errors.component';
import googleIcon from '../google-review.svg';
import facebookIcon from '../facebook-review.svg';
import { WeaveTheme } from '@weave/theme-original';
import { CheckIconSmall, Text, WarningIconSmall, XIconSmall } from '@weave/design-system';

export enum SiteName {
  Google = 'google',
  Facebook = 'facebook',
}

type AuthenticationStatusDetailProps = {
  site: SiteName;
  mapping: Mapping | null;
  errors: string[];
};

const getStateText = (site: SiteName, setupState?: SetupState) => {
  const isGoogle = site === SiteName.Google;
  switch (setupState) {
    case SetupState.AuthSuccess:
      return {
        label: 'Integration Abandoned',
        description: `Authentication was successful. However the customer didn’t pick a ${
          isGoogle ? 'Location' : 'Page'
        } to integrate with and abandoned the process.`,
      };
    case SetupState.AuthFailure:
      return {
        label: 'Authentication Failed',
        description: 'The customer attempted and failed to authenticate.',
      };
    case SetupState.IntegrationSuccess:
      return { label: 'Integration Successful', description: '' };
    case SetupState.IntegrationFailure:
      return {
        label: 'Account Cannot Integrate',
        description: `Authentication was successful. However the customer authenticated with an account that doesn’t have access to any ${
          isGoogle ? 'Locations' : 'Pages'
        }.`,
      };
    case SetupState.HasUnIntegrated:
      return {
        label: 'Logged Out',
        description: `The customer has previously authenticated and integrated a ${
          isGoogle ? 'Location' : 'Page'
        }, but has since ${isGoogle ? 'signed' : 'logged'} out.`,
      };
    default:
      return {
        label: 'Never Authenticated',
        description: `The customer has never attempted to authenticate and integrate a ${
          isGoogle ? 'Google Location' : 'Facebook Page'
        }.`,
      };
  }
};

export const AuthenticationStatusDetail = ({
  site,
  mapping,
  errors,
}: AuthenticationStatusDetailProps) => {
  const siteIcon = site === SiteName.Google ? googleIcon : facebookIcon;
  const successfulIntegration = mapping?.setupState === 'IntegrationSuccess';
  const stateText = getStateText(site, mapping?.setupState);

  return (
    <div
      css={(theme: WeaveTheme) => css`
        margin: ${theme.spacing(1)};
        background: ${theme.colors.white};
        border: solid 1px ${theme.colors.gray[300]};
        padding: ${theme.spacing(1)};
        border-radius: ${theme.borderRadius.small};
        max-width: 400px;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <img
          alt={site === SiteName.Google ? 'Google' : 'Facebook'}
          src={siteIcon}
          css={css`
            min-width: 40px;
            width: 40px;
          `}
        />
        {successfulIntegration && !!mapping && (
          <ReviewsSiteInfo
            info={mapping}
            css={(theme: WeaveTheme) => css`
              flex-grow: 1;
              margin: ${theme.spacing(0, 1)};
            `}
          />
        )}
        {!successfulIntegration && (
          <div
            css={(theme: WeaveTheme) => css`
              flex-grow: 1;
              margin: ${theme.spacing(0, 1)};
            `}
          >
            <Text
              size="large"
              weight="bold"
              color="error"
              css={css`
                display: flex;
                align-items: center;
                margin: 0;
              `}
            >
              {stateText.label}
            </Text>
            <Text
              size="small"
              color="error"
              css={css`
                margin: 0;
              `}
            >
              {stateText.description}
            </Text>
          </div>
        )}
        <span
          css={(theme: WeaveTheme) => css`
            display: flex;
            background: ${successfulIntegration
              ? errors.length > 0
                ? theme.colors.warning
                : theme.colors.success
              : theme.colors.error};
            border-radius: 50%;
            min-width: 32px;
            width: 32px;
            height: 32px;
            align-items: center;
            justify-content: center;
          `}
        >
          {successfulIntegration ? (
            errors.length > 0 ? (
              <WarningIconSmall color="white" />
            ) : (
              <CheckIconSmall color="white" />
            )
          ) : (
            <XIconSmall color="white" />
          )}
        </span>
      </div>
      <SiteErrors site={site} errors={errors} />
    </div>
  );
};
