import { all } from 'redux-saga/effects';
import { getHistorySaga } from './history.action';

export { getUnfilteredHistory } from './history.action';

export { default as historyReducer } from './history.reducer';

export const historySaga = function* () {
  yield all([getHistorySaga()]);
};
