import { handleActions } from 'redux-actions';
import { getJobTitles, getJobTitlesSuccess, setLoading } from './user-job-titles.action';

const initialState = {
  data: [],
  loading: false,
};

export const userJobTitlesReducer = handleActions(
  {
    [getJobTitles.toString()]: (state, action) =>
      Object.assign({}, state, { loading: true }),
    [getJobTitlesSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { data: action.payload }),
    [setLoading.toString()]: (state, action) =>
      Object.assign({}, state, { loading: action.payload }),
  },
  initialState
);
