import { createAction, handleActions, Action } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { CustomAxios, getErrorMessage } from '../../axios';
import { showError, showSuccess } from '@weave/alert-system';

export interface ClientApiSetting {
  set: string;
  key: string;
  value: string;
}

export const fetchClientApi = createAction('FETCH_CLIENT_API');
export const fetchClientApiSuccess = createAction<ClientApiSetting[]>(
  'FETCH_CLIENT_API_SUCCESS'
);
export const fetchClientApiFailure = createAction<Error>('FETCH_CLIENT_API_FAILURE');

export const updateClientApi = createAction<ClientApiSetting>('UPDATE_CLIENT_API');
export const updateClientApiSuccess = createAction('UPDATE_CLIENT_API_SUCCESS');
export const updateClientApiFailure = createAction<Error>('UPDATE_CLIENT_API_FAILURE');

export const selectClientApi = (state) => (key: string) => {
  const setting = state.clientApi.data.find((setting) => setting.key === key);
  if (!setting) {
    console.warn("Woops that setting doesn't exist");
    return null;
  }
  return setting;
};

export const handleFetchClientApi = function* () {
  try {
    const response = yield call(CustomAxios.get, 'client-api/settings');
    yield put(fetchClientApiSuccess(response.data));
  } catch (error: any) {
    yield put(fetchClientApiFailure(error));
  }
};

export const handleUpdateClientApi = function* (action) {
  const payload: ClientApiSetting = action.payload;

  try {
    yield call(CustomAxios.put, 'client-api/settings', payload);
    yield put(fetchClientApi());
    yield put(updateClientApiSuccess());
    yield put(showSuccess('updated'));
  } catch (error: any) {
    yield put(showError(getErrorMessage(error)));
    yield put(updateClientApiFailure(error));
  }
};

export const clientApiSaga = function* () {
  yield all([
    takeEvery(fetchClientApi.toString(), handleFetchClientApi),
    takeEvery(updateClientApi.toString(), handleUpdateClientApi),
  ]);
};

export interface ClientApiStateModel {
  loading: boolean;
  data: ClientApiSetting[];
  error?: Error;
}

const defaultState: ClientApiStateModel = {
  loading: false,
  data: [],
};

export const clientApiReducer = handleActions<ClientApiStateModel, any>(
  {
    [fetchClientApi.toString()]: (state) => ({ ...state, loading: true }),
    [fetchClientApiSuccess.toString()]: (state, action: Action<ClientApiSetting[]>) => ({
      ...state,
      data: action.payload || [],
      loading: false,
    }),
    [fetchClientApiFailure.toString()]: (state, action: Action<Error>) => ({
      ...state,
      error: action.payload,
      loading: false,
    }),

    [updateClientApi.toString()]: (state) => ({ ...state, loading: true }),
    [updateClientApiSuccess.toString()]: (state) => ({ ...state, loading: false }),
    [updateClientApiFailure.toString()]: (state) => ({
      ...state,
      loading: false,
    }),
  },
  defaultState
);
