import { OutageAlert } from '../../../components/outage-alerts/outage-alerts.types';

export type OutageAlertState = OutageAlert[];

export enum OutageAlertActionTypes {
  SET_ACTIVE_OUTAGE_ALERTS = 'SET_ACTIVE_OUTAGE_ALERTS',
  GET_ACTIVE_OUTAGE_ALERTS = 'GET_ACTIVE_OUTAGE_ALERTS',
}

export type GetActiveOutageAlertsAction = {
  type: typeof OutageAlertActionTypes.GET_ACTIVE_OUTAGE_ALERTS;
  payload: string | null;
};

export type SetActiveOutageAction = {
  type: typeof OutageAlertActionTypes.SET_ACTIVE_OUTAGE_ALERTS;
  payload: OutageAlert[];
};
