import { connect } from 'react-redux';

import { PortingHub } from './porting-hub/porting-hub.component';
import {
  downloadBill,
  downloadLOA,
  getPortingData,
  getPortingStatus,
  clearPortingStatus,
  submitPorting,
  updatePortingData,
  setPortingDetails,
  clearSubmitPortingDetails,
  checkPortability,
  resetPortabilityCheck,
  submitSplitPortingRequests,
  deletePortingRequest,
} from '../../redux/actions/porting';
import { selectCurrentLocation } from '../../redux/actions/location/current-location';
import { Store } from '../../redux/store/store.model';

export const PortingHubContainer = connect(
  (state: Store) => ({
    loading: state.porting.loading,
    ports: state.porting.ports,
    currentLocation: selectCurrentLocation(state),
    downloadLOALoading: state.porting.downloadLOALoading,
    downloadBillLoading: state.porting.downloadBillLoading,
    currentPortStatus: state.porting.currentPortStatus,
    portStatusLoading: state.porting.portStatusLoading,
    submitPortingLoading: state.porting.submitPortingLoading,
    portingDetails: state.porting.portingDetails,
    updatePortingDetailsLoading: state.porting.updatePortingDetailsLoading,
    submitPortingDetails: state.porting.submitPortingDetails,
    splitPortingRequest: state.porting.splitPortingRequest,
    splitPortingRequestsResults: state.porting.splitPortingRequestsResults,
    deletePortingRequestLoading: state.porting.deletePortingRequestLoading,
  }),
  {
    getPortingData,
    downloadBill,
    downloadLOA,
    getPortingStatus,
    clearPortingStatus,
    submitPorting,
    updatePortingData,
    setPortingDetails,
    clearSubmitPortingDetails,
    checkPortability,
    resetPortabilityCheck,
    submitSplitPortingRequests,
    deletePortingRequest,
  }
)(PortingHub);
