import React from 'react';

import { useResources } from '../../../helpers/useResources';
import { ConditionalLoader } from '../../shared/loader/conditional-loader.component';
import { ExpandedPhoneTreeModel, PhoneTreeModel } from './phone-tree.models';
import { PhoneTreeGreetingSection } from './phone-tree-greeting-section/phone-tree-greeting-section.component';
import { PhoneTreeMenuSection } from './phone-tree-menu-section/phone-tree-menu-section.component';
import { usePhoneTreeState, PhoneTreeActions } from './use-phone-tree-state';
import { PhoneMedia } from '../../../redux/actions';

interface Props {
  activePhoneTreeId: string;
  phoneMedia: PhoneMedia[];
  onPhoneTreeClick: (phoneTreeId: string) => void;
  getPhoneTree: (phoneTreeId: string) => PhoneTreeModel | undefined;
}

export const PhoneTree = ({
  activePhoneTreeId,
  phoneMedia,
  onPhoneTreeClick,
  getPhoneTree,
}: Props) => {
  const { phoneTree, phoneTreeDispatch, entries, instructions, loadingPhoneTree } =
    usePhoneTreeState();

  const { loading: loadingIVRMenu } = useResources<ExpandedPhoneTreeModel>(
    `support/v1/ivrmenus/${activePhoneTreeId}?expand=entries`,
    {
      deps: [activePhoneTreeId],
      resourceLabel: 'phone tree',
      onFetch: (data) =>
        phoneTreeDispatch({
          type: PhoneTreeActions.normalizePhoneTree,
          payload: data,
        }),
    }
  );

  return (
    <ConditionalLoader loading={loadingIVRMenu || loadingPhoneTree}>
      <div>
        <PhoneTreeGreetingSection
          updatePhoneTreeMediaId={(mediaId: string) =>
            phoneTreeDispatch({
              type: PhoneTreeActions.updateMediaId,
              payload: mediaId,
            })
          }
          phoneTree={phoneTree}
          phoneMedia={phoneMedia}
        />
        <PhoneTreeMenuSection
          onPhoneTreeClick={onPhoneTreeClick}
          entries={entries}
          instructions={instructions}
          phoneTreeDispatch={phoneTreeDispatch}
          getPhoneTree={getPhoneTree}
        />
      </div>
    </ConditionalLoader>
  );
};
