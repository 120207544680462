import { css } from '@emotion/core';
import { Heading } from '@weave/design-system';
import { format } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { dateTimeDateFns } from '../../../helpers/utils';

/**
 * Css Configuration for the component
 */
const tableFrame = css`
  border-collapse: collapse;
  & th,
  td {
    border: 1px solid #999;
    padding: 0.5rem;
    word-wrap: break-word;
    max-width: 150px;
    text-align: center;
  }
`;
/** */

type OutageAlertReportsProps = {
  reportsData: Array<any>;
  applicationName: string;
};

/**
 * @desc : Function is defined to get the local date and time from iso format date
 * @param date : string (ISO format string)
 * @returns : string
 */
const getLocalDate = (date) => {
  return format(new Date(date), dateTimeDateFns);
};

export const OutageAlertReports = React.memo(
  ({ reportsData, applicationName }: OutageAlertReportsProps) => {
    const [dataToShowInTable, setDataToShowInTable] = useState<any[]>();
    const [showTable, setShowTable] = useState(false);

    const tableColumns = [
      'Outage Name',
      'Is Active',
      'Created By',
      'Created On',
      'Updated By',
      'Updated On',
      'Resolved By',
      'Resolved On',
      'Deleted By',
      'Deleted On',
    ];

    /**useMemo to segregate active and inactive alerts */
    const [activeAlerts, inActiveAlerts, deletedAlerts] = useMemo(() => {
      const activeAlerts: any = [];
      const inActiveAlerts: any = [];
      const deletedAlerts: any = [];
      reportsData.forEach((reportData) => {
        reportData.isDeleted
          ? deletedAlerts.push(reportData)
          : !reportData.isDeleted && reportData.isOutageActive
          ? activeAlerts.push(reportData)
          : inActiveAlerts.push(reportData);
      });
      return [activeAlerts, inActiveAlerts, deletedAlerts];
    }, [reportsData]);

    /**
     * @desc : Function is defined to set active | inactive alerts data in table based on click event of graph
     * @param element : OnClick it provide us the element of the donut chart where the click event was taken place
     */
    const handleChartClick = (event, element) => {
      if (element && element.length) {
        if (element[0]._index === 1) {
          setDataToShowInTable(activeAlerts);
        } else if (element[0]._index === 2) {
          setDataToShowInTable(deletedAlerts);
        } else {
          setDataToShowInTable(inActiveAlerts);
        }
      }
      setShowTable(true);
    };

    /**
     * Configuration of Chart options for Donut Chart
     */
    const getChartOptions = {
      onClick: handleChartClick,
    };

    /**
     * Configuration Data for Donut Chart
     */
    const prepareReportsData = {
      labels: ['Inactive', 'Active', 'Deleted'],
      datasets: [
        {
          label: `Outages in ${applicationName}`,
          data: [inActiveAlerts.length, activeAlerts.length, deletedAlerts.length],
          backgroundColor: ['#EC6B56', '#47B39C', '#EEEEEE'],
          hoverOffset: 4,
        },
      ],
    };

    return (
      <>
        <Doughnut data={prepareReportsData} options={getChartOptions} />
        <div>
          {showTable ? (
            <>
              <Heading>Outage Details</Heading>
              <table css={tableFrame}>
                <thead>
                  <tr>
                    {tableColumns.map((columns, index) => (
                      <th key={index}>{columns}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {dataToShowInTable &&
                    dataToShowInTable.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{data.outageName}</td>
                          <td>{data.isOutageActive ? 'True' : 'False'}</td>
                          <td>{data.createdBy ? data.createdBy : '-'}</td>
                          <td>{data.createdOn ? getLocalDate(data.createdOn) : '-'}</td>
                          <td>{data.updatedBy ? data.updatedBy : '-'}</td>
                          <td>{data.updatedOn ? getLocalDate(data.updatedOn) : '-'}</td>
                          <td>{data.resolvedBy ? data.resolvedBy : '-'}</td>
                          <td>{data.resolvedOn ? getLocalDate(data.resolvedOn) : '-'}</td>
                          <td>{data.deletedBy ? data.deletedBy : '-'}</td>
                          <td>{data.deletedOn ? getLocalDate(data.deletedOn) : '-'}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </>
          ) : null}
        </div>
      </>
    );
  }
);
