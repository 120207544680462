import { css } from '@emotion/core';
import { WeaveTheme } from '@weave/theme-original';
import backgroundImg from '../../images/banner_login.jpeg';

export const container = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-image: url(${backgroundImg});
  background-size: cover;
`;

export const innerContainer = (theme: WeaveTheme) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${theme.spacing(3, 1)};
  width: 350px;
  border-radius: ${theme.borderRadius.small};
  background-color: ${theme.colors.white};
  box-shadow: ${theme.shadows.hover};
  button {
    width: 80%;
  }
  svg {
    margin-bottom: ${theme.spacing(1)};
  }
`;

export const mainLogo = (theme: WeaveTheme) => css`
  & polygon,
  & path {
    fill: ${theme.colors.gray600};
  }
  width: 80%;
  height: 87px;
`;

export const divider = (theme: WeaveTheme) => css`
  height: 1px;
  background: ${theme.colors.gray400};
  width: 85%;
  margin-bottom: ${theme.spacing(3)};
`;
