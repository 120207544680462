import { createAction } from 'redux-actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { CustomAxios } from '../../axios';
import { showError, showSuccess } from '@weave/alert-system';

export const runPendoGuide = createAction<any>('RUN_PENDO_GUIDE');
export const runCustomerSatisfactionSurvey = createAction<any>(
  'RUN_CUSTOMER_SATISFACTION_SURVEY'
);

const handleRunPendoGuide = function* (action) {
  try {
    const userId = action.payload.userId;
    const guideName = action.payload.guideName;

    yield call(CustomAxios.post, `/support/v1/connections/send/${userId}`, {
      method: 'RunPendoGuide',
      params: guideName,
    });

    yield put(showSuccess('Start Pendo guide command sent to client.'));
  } catch {
    yield put(showError('Pendo guide did not start. Please try again.'));
  }
};

const handleRunCustomerSatisfactionSurvey = function* (action) {
  yield call(handleRunPendoGuide, {
    ...action,
    payload: {
      userId: action.payload,
      guideName: 'Post Onboarding Survey WAM',
    },
  });
};

export const runPendoGuideSaga = function* () {
  yield takeLatest(runPendoGuide.toString(), handleRunPendoGuide);
  yield takeLatest(
    runCustomerSatisfactionSurvey.toString(),
    handleRunCustomerSatisfactionSurvey
  );
};
