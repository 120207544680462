import React, { Component } from 'react';
import _ from 'lodash';
import { Tooltip } from 'reactstrap';
import { InfoRoundIconSmall } from '@weave/design-system';
import { css } from '@emotion/core';

interface Props {
  body: any;
  tooltipPlacement: 'bottom' | 'left' | 'right' | 'auto' | 'top';
  className?: string;
}

interface State {
  tooltipOpen: boolean;
  id: string;
}

export class InfoTooltip extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      tooltipOpen: false,
      id: _.uniqueId('info-tooltip-'),
    };
  }

  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };

  render() {
    return (
      <div className={this.props.className}>
        <div
          css={css`
            cursor: pointer;
          `}
          id={this.state.id}
        >
          <InfoRoundIconSmall color="primary" />
        </div>
        <Tooltip
          placement={this.props.tooltipPlacement}
          isOpen={this.state.tooltipOpen}
          target={this.state.id}
          toggle={this.toggle}
        >
          {this.props.body}
        </Tooltip>
      </div>
    );
  }
}
