import { all } from 'redux-saga/effects';

import { saga as clearAutoMsgQueueSaga } from './clear-auto-msg-queue.action';
import { saga as reloadAutoMsgQueueSaga } from './reload-auto-msg-queue.action';
import { saga as fetchDeliveryReports } from './auto-msg-delivery-reports.action';

export { default as autoMsgQueueReducer } from './auto-msg-queue.reducer';
export { clearAutoMsgQueue } from './clear-auto-msg-queue.action';
export { reloadAutoMsgQueue } from './reload-auto-msg-queue.action';
export { fetchDeliverySummary } from './auto-msg-delivery-reports.action';

export const autoMsgQueueSaga = function* () {
  yield all([clearAutoMsgQueueSaga(), reloadAutoMsgQueueSaga(), fetchDeliveryReports()]);
};
