import { all } from 'redux-saga/effects';

import { getAvailablePhoneNumbersSaga, purchasePhoneNumbersSaga } from './twilio.action';

export {
  getAvailablePhoneNumbers,
  clearAvailablePhoneNumbers,
  purchasePhoneNumbers,
} from './twilio.action';

export const twilioSaga = function* () {
  yield all([getAvailablePhoneNumbersSaga(), purchasePhoneNumbersSaga()]);
};

export { default as twilioReducer } from './twilio.reducer';
