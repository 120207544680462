import React, { useState } from 'react';
import { CheckIcon, EditIcon, NakedButton } from '@weave/design-system';

import { ReminderActionButtons } from './auto-reminders-action-buttons';
import { EnabledToggle } from '../auto-reminders-helpers/auto-reminder-helpers';
import * as styles from './auto-reminders-modal.styles';
import { ReminderUpdate } from '../../../../redux/actions/notifications-settings/notification-settings.types';

interface Props {
  id: string;
  name: string;
  type: string;
  enabled: boolean;
  actionRouter: Function;
  updateNotificationSettings: (statusMapSettings: ReminderUpdate) => void;
  handleToggleSwitch: () => void;
}

export const AutoReminderModalTitle = ({
  id,
  type,
  enabled,
  actionRouter,
  handleToggleSwitch,
  ...props
}: Props) => {
  const [name, setName] = useState<string>(props.name);
  const [editing, setEditing] = useState<boolean>(false);

  const handleEdit = () => {
    setEditing(true);
  };

  const handleSave = () => {
    setEditing(false);
    if (name !== props.name) {
      props.updateNotificationSettings({ id, name });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  return (
    <div className={styles.modalHeader}>
      <div className={styles.modalHeaderOptions}>
        <span className="changedAt">&nbsp;</span>
        <span className={styles.title}>
          {!editing ? (
            <>
              <NakedButton className={styles.editIcon} onClick={handleEdit}>
                <EditIcon color="white" />
              </NakedButton>
              <span>{name}</span>
            </>
          ) : (
            <>
              <NakedButton className={styles.editIcon} onClick={handleSave}>
                <CheckIcon color="white" />
              </NakedButton>
              <input
                className={styles.titleInput}
                type="text"
                value={name}
                onChange={handleChange}
              />
            </>
          )}
        </span>
        <span>
          {type !== 'review' && (
            <EnabledToggle enabled={enabled} handleToggleSwitch={handleToggleSwitch} />
          )}
        </span>
      </div>
      <div>
        <ReminderActionButtons
          className={styles.modalActionButtons}
          id={id}
          type={type}
          actionRouter={actionRouter()}
        />
      </div>
    </div>
  );
};
