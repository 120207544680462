import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { HomeComponent } from './home/home.component';
import { setCurrentLocationId as setLocation } from '../../redux/actions/location/current-location';
import {
  getLocationHistory,
  removeLocationFromHistory,
} from '../../redux/actions/location/location-history/location-history.action';
import { selectHasWeaveAcl } from '../../redux/actions/auth/auth.selectors';
import { CoreACLs } from '../../redux/actions/auth/auth.types';

export const HomeContainer = withRouter(
  connect(
    (state: any, ownProps: any) => ({
      canCreateLocations: selectHasWeaveAcl(state, CoreACLs.WRITELOCATIONS),
      history: ownProps.history,
      locationHistoryList: state.locationHistory.history,
      loading: state.locationHistory.loading,
    }),
    {
      loadLocationHistory: getLocationHistory,
      removeLocationFromHistory,
      setLocation,
    }
  )(HomeComponent)
);
