import { OSFilters } from './models';

export const webFilters: OSFilters = {
  os: '',
  opsys: ['windows', 'darwin', 'linux'],
  excludedVersions: [],
};

export const windowsFilters: OSFilters = {
  os: 'windows',
  opsys: ['windows'],
  excludedVersions: ['2000', 'XP', 'Server 2003', 'Vista'],
};

export const macFilters: OSFilters = {
  os: 'darwin',
  opsys: ['darwin'],
  excludedVersions: [
    '10_0_0',
    '10_0_1',
    '10_0_2',
    '10_0_3',
    '10_0_4',
    '10_1_0',
    '10_1_1',
    '10_1_2',
    '10_1_3',
    '10_1_4',
    '10_1_5',
    '10_2_0',
    '10_2_1',
    '10_2_2',
    '10_2_3',
    '10_2_4',
    '10_2_5',
    '10_2_6',
    '10_2_7',
    '10_2_8',
    '10_3_0',
    '10_3_1',
    '10_3_2',
    '10_3_3',
    '10_3_4',
    '10_3_5',
    '10_3_6',
    '10_3_7',
    '10_3_8',
    '10_3_9',
    '10_4_0',
    '10_4_1',
    '10_4_2',
    '10_4_3',
    '10_4_4',
    '10_4_5',
    '10_4_6',
    '10_4_7',
    '10_4_8',
    '10_4_9',
    '10_4_10',
    '10_4_11',
    '10_5_0',
    '10_5_1',
    '10_5_2',
    '10_5_3',
    '10_5_4',
    '10_5_5',
    '10_5_6',
    '10_5_7',
    '10_5_8',
    '10_6_0',
    '10_6_1',
    '10_6_2',
    '10_6_3',
    '10_6_4',
    '10_6_5',
    '10_6_6',
    '10_6_7',
    '10_6_8',
    '10_7_0',
    '10_7_1',
    '10_7_2',
    '10_7_3',
    '10_7_4',
    '10_7_5',
    '10_8_0',
    '10_8_1',
    '10_8_2',
    '10_8_3',
    '10_8_4',
    '10_8_5',
    '10_9_0',
    '10_9_1',
    '10_9_2',
    '10_9_3',
    '10_9_4',
    '10_9_5',
  ],
};
