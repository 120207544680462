import moment from 'moment';

export function prettifyTimestamp(timestamp?: string | number) {
  if (!timestamp) {
    return '';
  }

  return moment.unix(+timestamp).format('MMM D, YYYY h:mm a');
}

export function prettifyDatestring(datestring: string) {
  if (!datestring) {
    return '';
  }
  return moment(datestring).format('MMM D, YYYY h:mm a');
}
