import { gql } from '@apollo/client';

export interface LocationMissedCallSMSResponse {
  location: {
    missedCallSMS: {
      totals: {
        sent: number;
        responded: number;
        percentResponded: number;
      };
    };
    historicalData: {
      timestamp: string;
      value: number;
      label: string;
    }[];
  };
}

export const locationMissedCallSMSQuery = gql`
  query locationMissedCallSMS($start: Int!, $end: Int!, $step: TimeStep!) {
    location {
      missedCallSMS {
        totals(start: $start, end: $end, step: $step) {
          sent
          responded
          percentResponded
        }
        historicalData(start: $start, end: $end, step: $step) {
          timestamp
          value
          label
        }
      }
    }
  }
`;
