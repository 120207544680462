import React from 'react';
import { Modal } from '../../shared/modal/modal.component';
import { css } from 'emotion';

export type InteractiveCellTextProps = {
  text: string;
};

// Functional component meant to be used as a cell in react-table. Provides
// horizontal scrolling and click to display full text in modal.
export const InteractiveCellText = ({ text }: InteractiveCellTextProps) => {
  const [modalOpen, setModalOpen] = React.useState(false);

  const openConfirmationModal = () => {
    setModalOpen(true);
  };

  const handleCloseConfirmation = () => {
    setModalOpen(false);
  };

  const outerDivStyles = css`
    overflow-x: scroll;
  `;

  const innerDivStyles = css`
    cursor: pointer;
    padding: 4px 0px;
  `;

  return (
    <>
      <div className={outerDivStyles} onClick={openConfirmationModal}>
        <div className={innerDivStyles}>{text}</div>
      </div>

      <Modal
        size={'small'}
        title={null}
        isOpen={modalOpen}
        onRequestClose={handleCloseConfirmation}
      >
        {text}
      </Modal>
    </>
  );
};
