import { createRequestStateSelector } from '@weave/alert-system';
import { find, pick } from 'lodash';
import { createSelector } from 'reselect';
import { Store } from '../../store/store.model';
import { selectCurrentLocationDepartmentIds } from '../location/location-entities/location-departments/location-departments.selectors';
import { DepartmentActionTypes } from './department.types';

export const selectDepartmentFetching = createRequestStateSelector(
  DepartmentActionTypes.RequestList
);
export const selectDepartmentUpdating = createRequestStateSelector(
  DepartmentActionTypes.RequestUpdate
);
export const selectDepartmentCreating = createRequestStateSelector(
  DepartmentActionTypes.RequestCreate
);
export const selectDepartmentDeleting = createRequestStateSelector(
  DepartmentActionTypes.RequestDelete
);

const selector = (state: Store) => state.departments;

export const selectAllDepartments = createSelector(
  selector,
  selectDepartmentFetching,
  (state, reqState) => ({
    ...reqState,
    departments: state,
  })
);

export const selectDepartments = createSelector(
  selectAllDepartments,
  selectCurrentLocationDepartmentIds,
  ({ departments, ...reqState }, departmentIds) => ({
    ...reqState,
    departments: departmentIds ? pick(departments, departmentIds) : {},
  })
);

export const selectDepartmentsArray = createSelector(selectDepartments, (state) => ({
  ...state,
  departments: Object.values(state.departments),
}));

export const selectDepartment = (departmentId: string) =>
  createSelector(selectDepartments, ({ departments, ...rest }) => ({
    ...rest,
    department:
      departmentId === 'main-line'
        ? find(departments, (dep) => dep.name === 'Main Line')
        : departments?.[departmentId],
  }));

// Nate's suggested way of of dynamic selectors
// export const selectDepartment =
//   createSelector(
//     selectDepartments,
//     (_, id: string) => id,
//     ({departments, ...rest}, id) => ({
//     ...rest,
//     department: departments[id],
//   }));

// export const selectDepartmentWithAllData =
//   createSelector([
//     selectDepartments,
//     selectDepartmentDevices(id),
//     selectSchedules,
//     selectFwdNumer,
//     (_, id: string) => id,
//   ], ({departments, ...rest}, id) => (
//     return {
//       ...rest,
//       department: departments[id],
//       }
//   ));
