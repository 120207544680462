import React from 'react';

import * as HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContextProvider } from 'react-dnd';
import { css } from 'emotion';
import { Route } from 'react-router';

import { Navigation } from '../../components/navigation-container/navigation/navigation.component';
import { LocationSidebarContainer } from '../../components/location-sidebar-container/location-sidebar.container';
import { Routes } from './routes.component';
import { SignIn } from '../../components/SignIn/SignIn.component';
import { MultiLocation } from '../../components/multi-location/multi-location.component';
import { ContextualDashboard } from '../../components/contextual-dashboard';
import { SalesPricing } from '../../components/sales-pricing';
import { Header } from '../../components/header-container/header/header.component';
import { useSelector } from 'react-redux';
import { selectCurrentLocationId } from '../../redux/actions/location/current-location';
import { CrawlSwitch } from '../crawl-switch';
import { UniversalSearch } from '../../components/universal-search/universal-search';
import { OutageAlertsBannerContainer } from '../../components/outage-alerts/outage-alerts.container';
import { OutageAlerts } from '../../components/outage-alerts/outage-alerts';
import { selectIsAuthenticated } from '../../redux/actions/auth/auth.selectors';
import { theme } from '@weave/theme-original';

const fullPageContainer = css`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const contentContainer = css`
  display: flex;
  margin: ${theme.spacing(6, 0, 0, 0)};
  min-height: calc(100vh - 48px);
`;

const contentGreyBackground = css`
  ${contentContainer}
  background-color: ${theme.colors.gray100};
`;

const contentWhiteBackground = css`
  ${contentContainer}
  background-color: ${theme.colors.white};
`;

const flexStyle = css`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;

export const AppRouter = () => {
  const locationId = useSelector(selectCurrentLocationId);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  return !isAuthenticated ? (
    <SignIn />
  ) : (
    <DragDropContextProvider backend={HTML5Backend}>
      <main className={fullPageContainer}>
        <Header />
        <div className={locationId ? contentGreyBackground : contentWhiteBackground}>
          {isAuthenticated && (
            <CrawlSwitch>
              <Route
                path="/universal-search"
                render={() => (
                  <>
                    <OutageAlertsBannerContainer /> <UniversalSearch />
                  </>
                )}
              />
              <Route
                path="/outage-alerts"
                render={() => (
                  <>
                    <OutageAlertsBannerContainer /> <OutageAlerts />
                  </>
                )}
              />
              <Route
                path="/sales-pricing"
                render={() => (
                  <>
                    <OutageAlertsBannerContainer /> <SalesPricing />
                  </>
                )}
              />
              <Route
                path="/multi-location"
                render={() => (
                  <>
                    <OutageAlertsBannerContainer /> <MultiLocation />
                  </>
                )}
              />
              <Route
                path="/location/:id/contextual-dashboard"
                render={() => (
                  <>
                    <OutageAlertsBannerContainer /> <ContextualDashboard />
                  </>
                )}
              />
              <Route
                render={(props) => (
                  <>
                    <LocationSidebarContainer />
                    <div className={flexStyle} data-crawl-id="content-container">
                      <OutageAlertsBannerContainer />
                      <Navigation />
                      <Routes isAuthenticated={isAuthenticated} />
                    </div>
                  </>
                )}
              />
            </CrawlSwitch>
          )}
        </div>
      </main>
    </DragDropContextProvider>
  );
};
