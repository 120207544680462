import { createSelector } from 'reselect';
import { createRequestStateSelector } from '@weave/alert-system';

import { Store } from '../../store/store.model';
import { PhoneNumbersActionTypes } from './phone-numbers.actions';
import { PhoneNumbersState } from './phone-numbers.types';
import { pick } from 'lodash';
import { selectCurrentLocationPhoneNumberIds } from '../location/location-entities/';

export const selectPhoneNumbersFetchingAll = createRequestStateSelector(
  PhoneNumbersActionTypes.RequestList
);

export const selectAllPhoneNumbers = (store: Store): PhoneNumbersState =>
  store.phoneNumbers;

/**
 * Select PhoneNumbers filtered by current location
 */
export const selectPhoneNumbers = createSelector(
  selectAllPhoneNumbers,
  selectCurrentLocationPhoneNumberIds,
  selectPhoneNumbersFetchingAll,
  (phoneNumbers, phoneNumberIds, reqState) => ({
    ...reqState,
    phoneNumbers: phoneNumberIds ? pick(phoneNumbers, phoneNumberIds) : {},
  })
);

/**
 * Select PhoneNumbers as array filtered by current location
 */
export const selectPhoneNumbersArray = createSelector(selectPhoneNumbers, (state) => ({
  ...state,
  phoneNumbers: Object.values(state.phoneNumbers).sort((a, b) =>
    a.name.localeCompare(b.name)
  ),
}));
