import React, { useEffect, useState } from 'react';
import { Page } from '../../shared';
import * as queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { AppointmentDebugger } from './appointment-debugger/appointment-debugger';
import { OrderDebugger } from './order-debugger/order-debugger';

type Types = '' | 'appointment' | 'order';

interface Query {
  type: Types;
  id: string;
  personId?: string;
}

export const AutoMessageDebugger = () => {
  const location = useLocation();
  const [query, setQuery] = useState<Query>({ type: '', id: '' });

  useEffect(() => {
    const parsed: unknown = queryString.parseUrl(location.search).query;
    setQuery(parsed as Query);
  }, []);
  return (
    <Page title="Auto Message Debugger">
      {query?.type === 'appointment' && <AppointmentDebugger query={query} />}
      {query?.type === 'order' && <OrderDebugger query={query} />}
    </Page>
  );
};
