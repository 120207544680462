import React from 'react';
import * as moment from 'moment-timezone';
import * as queryString from 'query-string';
import { css } from 'emotion';

import { SmsInfoContainer } from './sms-info/sms-info.container';
import { SmsNotificationSettingsContainer } from './sms-notification-settings/sms-notification-settings.container';
import { SmsMetadataContainer } from './sms-metadata/sms-metadata.container';

import { SmsModel } from '../../../../models/sms-history.model';
import { PersonPayload } from '../../../../redux/actions/patients/person.action';

interface Props {
  locationID: string;
  history: any;
  personDetails: any;
  smsDetails: {
    data: SmsModel;
  };
  timezone: string;
  commxMigrationFlag: boolean;
  getSmsDetails: (payload: any) => void;
  getPersonDetails: (payload: PersonPayload) => void;
  resetSmsDetails: () => void;
  resetPersonDetails: () => void;
}

export class SmsDetails extends React.Component<Props, { phoneNumber: string }> {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: '',
    };
  }

  componentDidMount() {
    this.setPerson();
    this.setPhoneNumber();
  }

  async setPerson() {
    const personId: any = this.props.smsDetails.data.personId;

    // Fetch the person details only when a person ID available
    // Otherwise this will end-up in an infinite loop causing unintentional UI re-renders with each call
    if (personId) {
      const payload = { locationId: this.props.locationID, personId: personId };
      await this.props.getPersonDetails(payload);
    }
  }

  async setPhoneNumber() {
    if (this.state.phoneNumber === '' && !Array.isArray(this.props.personDetails)) {
      const contactInfo = this.props.personDetails?.contactInfo;
      if (contactInfo && (contactInfo['PhoneMobile'] || contactInfo['PhoneHome'])) {
        const phoneNumber =
          contactInfo['PhoneMobile'][0].destination ||
          contactInfo['PhoneHome'][0].destination;
        await this.setState({
          phoneNumber,
        });
      }
    }
  }

  UNSAFE_componentWillMount() {
    moment.tz.setDefault('America/Denver');
    if (this.props.history.location.search) {
      this.setSMS();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.locationID === '' &&
      prevProps.locationID !== this.props.locationID &&
      this.props.locationID.length > 0
    ) {
      this.setSMS();
    }
    if (this.state.phoneNumber === '') {
      this.setPerson();
      this.setPhoneNumber();
    }
  }

  componentWillUnmount() {
    this.props.resetSmsDetails();
    this.props.resetPersonDetails();
    this.setState({
      phoneNumber: '',
    });
  }

  setSMS() {
    const uuid = queryString.parse(this.props.history.location.search).uuid;
    const locationId: string = this.props.locationID;
    const { commxMigrationFlag } = this.props;
    this.props.getSmsDetails({ uuid, locationId, commxMigrationFlag });
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="main">
            <div className="title-group">
              <div className="page-title">SMS Details</div>
            </div>
            <br />
            <div
              className={css`
                display: flex;
              `}
            >
              <SmsInfoContainer hashedNumber={this.state.phoneNumber} />
              <SmsNotificationSettingsContainer />
            </div>
            <hr />
            {this.props.smsDetails.data && <SmsMetadataContainer />}
          </div>
        </div>
      </div>
    );
  }
}
