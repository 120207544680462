import { createAction, handleActions } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import _ from 'lodash';
import { LocationHistoryModel } from '../../../../models/location-history.model';
import { showError } from '@weave/alert-system';
import { REACT_APP_API_URL } from '../../../../config/app.js';

export interface LocationHistoryState {
  history: LocationHistoryModel[];
  loading: boolean;
}

export const getLocationHistory = createAction('GET_LOCATION_HISTORY');
export const getLocationHistorySuccess = createAction<LocationHistoryModel[]>(
  'GET_LOCATION_HISTORY_SUCCESS'
);
export const getLocationHistoryFailure = createAction<Error>(
  'GET_LOCATION_HISTORY_FAILURE'
);
export const addLocationToHistory = createAction<LocationHistoryModel>(
  'ADD_LOCATION_TO_HISTORY'
);
export const removeLocationFromHistory = createAction<LocationHistoryModel>(
  'REMOVE_LOCATION_FROM_HISTORY'
);

const isProd = REACT_APP_API_URL.startsWith('https://api');
const locationHistoryKey = isProd ? 'location-history' : 'dev-location-history';

const getHistoryFromLocalStorage = (): LocationHistoryModel[] => {
  const history = window.localStorage.getItem(locationHistoryKey);
  return history ? JSON.parse(history) : [];
};

const saveHistoryToLocalStorage = (historyList: LocationHistoryModel[]) => {
  window.localStorage.setItem(locationHistoryKey, JSON.stringify(historyList));
};

export const handleGetLocationHistory = function* () {
  try {
    const historyList: LocationHistoryModel[] = yield call(getHistoryFromLocalStorage);
    yield put(getLocationHistorySuccess(historyList));
  } catch (error: any) {
    yield put(getLocationHistoryFailure(error));
    yield put(showError('Error attempting to read location history list'));
  }
};

export const handleAddLocation = function* (action) {
  try {
    const location: LocationHistoryModel = action.payload;
    const historyList: LocationHistoryModel[] = yield call(getHistoryFromLocalStorage);

    const existingLocation = _.find(historyList, { id: location.id });
    if (existingLocation) {
      existingLocation.created = Date.now();
    } else {
      location.created = Date.now();
      historyList.push(location);
    }

    saveHistoryToLocalStorage(historyList);
    yield put(getLocationHistorySuccess(historyList));
  } catch {
    yield put(showError('Error adding location to history list'));
  }
};

export const handleRemoveLocation = function* (action) {
  try {
    const location: LocationHistoryModel = action.payload;
    const historyList: LocationHistoryModel[] = yield call(getHistoryFromLocalStorage);
    _.remove(historyList, { id: location.id });

    saveHistoryToLocalStorage(historyList);
    yield put(getLocationHistorySuccess(historyList));
  } catch {
    yield put(showError('Error removing location from history list'));
  }
};

export const locationHistorySaga = function* () {
  yield all([
    takeEvery(getLocationHistory.toString(), handleGetLocationHistory),
    takeEvery(addLocationToHistory.toString(), handleAddLocation),
    takeEvery(removeLocationFromHistory.toString(), handleRemoveLocation),
  ]);
};

const defaultState: LocationHistoryState = {
  history: [],
  loading: true,
};

export const locationHistoryReducer = handleActions<LocationHistoryState, any>(
  {
    [getLocationHistory.toString()]: (state) =>
      Object.assign({}, state, { loading: true }),
    [getLocationHistorySuccess.toString()]: (state, action) =>
      Object.assign({}, state, {
        history:
          typeof action.payload !== 'undefined'
            ? action.payload.sort((a, b) => b.created - a.created)
            : state.history,
        loading: false,
      }),
    [getLocationHistoryFailure.toString()]: (state, action) =>
      Object.assign({}, state, {
        error: action.payload,
        loading: false,
      }),
  },
  defaultState
);
