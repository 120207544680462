import React from 'react';
import { PrimaryButton } from '@weave/design-system';
import { ConfirmModalType, SyncAppButtonInterface } from '../../../sync-app.types';

interface TerminalButtonProps extends SyncAppButtonInterface {
  loading: boolean;
  sendRequest: (rpcMethod: string, rpcParams: any, id?: string) => void;
  showModal?: (type: ConfirmModalType) => void;
}

export const TerminalButton = (props: TerminalButtonProps) => {
  const {
    rpcMethod,
    rpcParams = [],
    id,
    showModal,
    confirmModalType,
    showButton = true,
  } = props;

  const onButtonClick = () => {
    if (rpcMethod) {
      props.sendRequest(rpcMethod, rpcParams, id);
    } else if (showModal && confirmModalType) {
      showModal(confirmModalType);
    }
  };

  return showButton ? (
    <PrimaryButton
      onClick={onButtonClick}
      disabled={props.loading}
      destructive={!!confirmModalType}
    >
      {props.title}
    </PrimaryButton>
  ) : null;
};
