import React from 'react';
import { addMinutes, format } from 'date-fns';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { Text } from '@weave/design-system';

export interface ErrorMessage {
  message: string;
  messageType: 'error' | 'warning' | undefined;
  show?: boolean;
}

export interface DayErrors {
  end: ErrorMessage;
  start: ErrorMessage;
}

export const textCellStyle = css`
  line-height: ${theme.spacing(5)}
  margin: '0'
`;

export const centerH = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const fieldStyle = css`
  margin: ${theme.spacing(2, 0)};
`;

export const tableStyle = css`
  border-bottom-right-radius: ${theme.borderRadius.medium}
  border-bottom-left-radius: ${theme.borderRadius.medium}
  text-align: center
  & .rt-td {
    padding-top: ${theme.spacing(1.5)}
  }
  & .rt-td,
  & .rt-th,
  & .rt-table,
  & .rt-tbody {
    overflow: visible
  }
`;

export const tableHeaderStyle = (cornerBottom: boolean) => css`
  ${centerH}
  min-height: ${theme.spacing(4)}
  margin: ${theme.spacing(2, 0, 0, 0)}
  padding: ${theme.spacing(0, 0.25, 0, 1.5)}
  background-color: ${theme.colors.gray600}
  border-top-left-radius: ${theme.borderRadius.medium}
  border-top-right-radius: ${theme.borderRadius.medium}
  border-bottom-left-radius: ${cornerBottom === false ? theme.borderRadius.medium : '0'}
  border-bottom-right-radius: ${cornerBottom === false ? theme.borderRadius.medium : '0'}
  p {
    margin-top: ${theme.spacing(1)};
  }
`;

export const tableHeaderTextStyle = css`
  display: 'flex'
  align-items: 'center'
  margin-bottom: ${theme.spacing(0)}
  min-height: ${theme.spacing(4)}
  color: ${theme.colors.white}
`;

export const weekdays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const hours: number[] = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
];

export const errorMessageStart = (timeType: string, index: number) =>
  `Selected ${timeType} time for #${index + 1} cannot be used. `;

export const warningMessageStart = (timeType: string, index: number) =>
  `See ${timeType} time #${index + 1}: `;

export const formatDateFromTime = (time) =>
  new Date(
    0,
    0,
    0,
    parseInt([...time].slice(0, 2).join('')),
    parseInt([...time].slice(3, 5).join(''))
  );

export const formatTimeFromHoursAndMinutes = (hours: number, minutes: number) =>
  new Date(0, 0, 0, hours ?? 0, minutes ?? 0);

export const formatStartTime = (hours: number, minutes: number) =>
  format(formatTimeFromHoursAndMinutes(hours ?? 0, minutes ?? 0), 'H:mm');

export const formatEndTime = (hours: number, minutes: number, duration: number) => {
  const time = format(
    addMinutes(formatTimeFromHoursAndMinutes(hours ?? 0, minutes ?? 0), duration || 0),
    'H:mm'
  );
  return time === '0:00' ? '24:00' : time;
};

export const formatEndTimeForField = (
  hours: number,
  minutes: number,
  duration: number
) => {
  const time = format(
    addMinutes(formatTimeFromHoursAndMinutes(hours ?? 0, minutes ?? 0), duration),
    'HH:mm:ss'
  );
  return time === '00:00:00' ? '24:00:00' : time;
};

export const formatStartTimeForField = (hours: number, minutes: number) =>
  format(formatTimeFromHoursAndMinutes(hours ?? 0, minutes ?? 0), 'HH:mm:ss');

export const timeBlockIndex = {
  Header: '',
  id: 'index',
  accessor: (rowData) => rowData,
  Cell: (props) => <Text css={textCellStyle}>#{props.index + 1}</Text>,
  width: 50,
};
