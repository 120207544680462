import { CartItem, SoftwareProduct } from '../sales-pricing.types';
import PhonesAndFaxIcon from '../../../images/sales-pricing/icon-phones-and-fax.svg';
import AutoRemindersIcon from '../../../images/sales-pricing/icon-automated-reminders.svg';
import ReviewsIcon from '../../../images/sales-pricing/icon-reviews.svg';

export const WEAVE_IMPLEMENTATION_PRICING: CartItem = {
  label: 'implementationOfWeave',
  displayName: 'Implementation of Weave',
  price: 1950,
  recurringPrice: undefined,
  type: 'other',
  quantity: 1,
};

export const WEAVE_COMMUNICATE_LABEL = 'weaveCommunicate';

export const softwareProductData: SoftwareProduct[] = [
  {
    label: WEAVE_COMMUNICATE_LABEL,
    displayName: 'Weave Communicate',
    type: 'software',
    price: undefined,
    recurringPrice: 258,
    image: PhonesAndFaxIcon,
    description: `
      All of the essentials for HIPPA compliant communication externally and internally.
      You’ll have robust tools enabling you to respond to every patient and lead.
    `,
    features: [
      'Weave Pop',
      'Missed Call Text',
      'Call Analytics',
      'Unified Inbox',
      'Phone Tree',
      'Follow-up Notes',
      'Outbound VM Recording',
      'Unlimited Local and Long Distance',
      'Call Recording',
      'Wellness Forms',
      'Email Marketing',
      'Email Marketing Analytics',
      'Email Templates',
    ],
  },
  {
    label: 'weaveEngage',
    displayName: 'Weave Engage',
    type: 'software',
    price: undefined,
    recurringPrice: 198,
    image: AutoRemindersIcon,
    description: `
      Deliver a white gloved patient experience with automated reminders and messages,
      data syncs, quick and easy lists, and more.
    `,
    features: [
      'Birthday Greetings',
      'Confirmation Write-back',
      'Recall Reminders',
      'Appointment Reminders',
      'Quick-fill',
      'Collections (Lists)',
      'Outstanding Balances',
      'Eye-wear ready/dispense Reminders (Optometry)',
      'Recall Analytics',
      'Online Scheduling',
      'Schedule Messaging',
      'Schedule',
    ],
  },
  {
    label: 'weaveAttract',
    displayName: 'Weave Attract',
    type: 'software',
    price: undefined,
    recurringPrice: 203,
    image: ReviewsIcon,
    description: `
      Attract more patients with tools that make it easy and convenient for them to
      contact you, no matter where they try to find you.
    `,
    features: [
      'Review Invites',
      'Review Management',
      'Web chat',
      'Appointment Requests',
      'Phone Tracking',
    ],
  },
];
