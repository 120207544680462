import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/core';
import { WeaveTheme } from '@weave/theme-original';

import { selectCurrentLocation } from '../../../redux/actions/location';
import { Page } from '../../shared/page/page.component';
import {
  getOfficeHours,
  selectOfficeHours,
} from '../../../redux/actions/office-hours/office-hours.action';
import { OfficeHoursCard } from './office-hours-card/office-hours-card.component';
import { OfficeHoursModel } from './models';

const wrapperStyles = (theme: WeaveTheme) => css`
  display: flex;
  flex-wrap: wrap;
  padding: ${theme.spacing(1)};
`;

export const OfficeHours = () => {
  const dispatch = useDispatch();
  const officeHours = useSelector(selectOfficeHours)?.data;
  const location = useSelector(selectCurrentLocation);

  useEffect(() => {
    dispatch(getOfficeHours());
  }, [location.LocationID]);

  // Gets rid of the funky API naming and trims it down to fit the OfficeHoursModel key names
  const generateKeyName = (key: string, dayName: string) => {
    const temp = key.replace(dayName, '').replace('Hours', '');
    return temp.charAt(0).toLowerCase() + temp.slice(1);
  };

  const getHoursByDay = (day: string) => {
    const lower = day.toLowerCase();
    const hours = {};
    Object.entries(officeHours).forEach(([key, value]) => {
      if (key.toLowerCase().startsWith(lower)) {
        hours[generateKeyName(key, lower)] = value;
      }
    });

    return hours as OfficeHoursModel;
  };

  const dayList = [
    { label: 'Sunday', abbreviation: 'SUN' },
    { label: 'Monday', abbreviation: 'MON' },
    { label: 'Tuesday', abbreviation: 'TUE' },
    { label: 'Wednesday', abbreviation: 'WED' },
    { label: 'Thursday', abbreviation: 'THU' },
    { label: 'Friday', abbreviation: 'FRI' },
    { label: 'Saturday', abbreviation: 'SAT' },
  ];

  return (
    <Page loading={false} title="Office Hours">
      <div css={wrapperStyles}>
        {dayList.map((day) => (
          <OfficeHoursCard
            key={day.label}
            title={day.abbreviation}
            day={day.label}
            hours={getHoursByDay(day.label.toLowerCase())}
          />
        ))}
      </div>
    </Page>
  );
};
