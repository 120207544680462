import { connect } from 'react-redux';

import { FeatureFlags } from './feature-flags/feature-flags.component';
import {
  saveFeatureFlags,
  selectFeatureFlags,
} from '../../../redux/actions/feature-flags';
import { selectHasWeaveAcl } from '../../../redux/actions/auth/auth.selectors';
import FeatureFlagModel from './feature-flag.model';
import { selectCurrentLocationId } from '../../../redux/actions/location/current-location';
import { CoreACLs } from '../../../redux/actions/auth/auth.types';

const analyticsFilter = (ff?: FeatureFlagModel) => ff && !ff.Name.startsWith('analytics');
export const FeatureFlagsContainer = connect(
  (state: any) => ({
    canEditFeatureFlags: selectHasWeaveAcl(state, CoreACLs.FEATUREFLAGWRITE),
    canCreateFeatureFlags: selectHasWeaveAcl(state, CoreACLs.FEATUREFLAGCREATE),
    featureFlags: selectFeatureFlags(state).filter(analyticsFilter),
    loading: state.featureFlags.loading,
    locationId: selectCurrentLocationId(state),
  }),
  {
    saveFeatureFlags,
  }
)(FeatureFlags);
