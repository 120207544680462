import React from 'react';
import moment from 'moment-timezone';

import * as styles from '../sms-list.styles';
import { SmsMetadataModel } from '../../../../../models/sms-metadata.model';
import { NotificationQueueModel } from '../../../../../models/notification-queue.model';
import { dateTimeZoneFormat } from '../../../../../helpers/utils';
import { NotificationQueuePayload } from '../../../../../redux/actions/notification-queue/notification-queue.action';
import { SmsModel } from '../../../../../models/sms-history.model';

interface Props {
  locationID: string;
  timezone: string;
  relatedData: SmsMetadataModel;
  notificationID: string;
  apptID: SmsModel['apt_id'];
  queue: NotificationQueueModel[];
  getNotificationQueue: (payload: NotificationQueuePayload) => void;
  resetNotificationQueueFailure: () => void;
}

export class SmsNotificationQueue extends React.Component<Props, {}> {
  componentDidUpdate(prev) {
    if (this.props.relatedData !== prev.relatedData) {
      const payload = {
        typeID: this.props.relatedData.relatedDataID,
        notificationSettingID: this.props.notificationID,
      };
      this.props.getNotificationQueue(payload);
    }
  }

  componentWillUnmount() {
    this.props.resetNotificationQueueFailure();
  }

  getDelay = (item) => {
    if (item.delivered_at && item.deliver_at) {
      const diff = moment(item.delivered_at)
        .diff(moment(item.deliver_at), 'hours', true)
        .toFixed(2);
      const diffMins = diff.split('.');
      const minutes = parseInt(((diff - diffMins[0]) * 60).toFixed());
      let delay = '';
      if (diff[0] > 0) {
        delay += `${diff[0]} hr `;
      }
      if (minutes > 0) {
        delay += `${minutes} min `;
      }
      if (delay) {
        delay += 'Delay';
      }
      return delay;
    } else {
      return 'Invalid Data';
    }
  };

  getExperation(creationDate) {
    const experationDate = moment(creationDate).endOf('day').add(2, 'weeks');
    const today = moment();
    const duration = moment(experationDate).diff(today, 'milliseconds');
    const experation = moment.duration(duration).humanize(true);
    return experation;
  }

  formatDate(date) {
    const momentized = moment(date);
    return this.props.timezone
      ? momentized.tz(this.props.timezone).format(dateTimeZoneFormat)
      : momentized.format(dateTimeZoneFormat);
  }

  render() {
    return (
      <div className={styles.queueComponent}>
        <p>
          <strong>Notification Queue </strong>
        </p>
        <div className={styles.eventBox}>
          {!this.props.queue.length && <p className={styles.blur}>No Data</p>}
          {!!this.props.queue.length &&
            this.props.queue.map((item) => {
              const delay = this.getDelay(item);
              const experation = this.getExperation(item.deliver_at);
              return (
                <div className={styles.queueItem} key={item.id}>
                  <div className={styles.flexStyle}>
                    <div className={styles.left}>
                      <p className={styles.type}>{item.notifiable_type}</p>
                      <p className={styles.type}>{item.status}</p>
                      <p className={styles.type}>{item.delivery_method}</p>
                    </div>
                    <div className={styles.right}>
                      <p className={styles.type}>{delay}</p>
                      <p className={styles.type}>
                        Deliver At: {item.deliver_at && this.formatDate(item.deliver_at)}
                      </p>
                      <p className={styles.type}>
                        Delivered:{' '}
                        {item.delivered_at && this.formatDate(item.delivered_at)}
                      </p>
                    </div>
                  </div>
                  <p className={styles.life}>Expiration {experation}</p>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
