import { useEffect, useRef, useState } from 'react';

interface Props {
  url: string;
  protocols?: string[];
  dependencies?: any[];
  condition?: boolean;
  onmessage?: (event: MessageEvent) => void;
  onopen?: () => void;
  onerror?: (event: Event) => void;
}

export const useWebSocket = ({
  url,
  protocols = [],
  dependencies = [],
  condition,
  onmessage,
  onopen,
  onerror,
}: Props) => {
  const [data, setData] = useState(null);
  const socket = useRef<WebSocket | null>(null);

  const sendMessage = (message: string | Blob | ArrayBuffer) => {
    if (socket.current) {
      socket.current.send(message);
    }
  };

  const createWebSocket = async () => {
    const connectCondition = condition !== undefined ? condition : !socket.current;
    if (connectCondition) {
      const ws = new WebSocket(url, protocols);
      if (onopen) {
        ws.onopen = onopen;
      }
      if (onerror) {
        ws.onerror = onerror;
      }
      if (onmessage) {
        ws.onmessage = onmessage;
      } else {
        ws.onmessage = (evt: MessageEvent) => {
          setData(evt.data);
        };
      }
      socket.current = ws;
    }
  };

  useEffect(() => {
    createWebSocket();

    return () => {
      if (socket.current) {
        socket.current.close();
      }
    };
  }, dependencies);

  return { data, sendMessage };
};
