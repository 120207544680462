import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentLocationId } from '../../../redux/actions/location';
import {
  getPricings,
  selectPaymentUrl,
  selectPricings,
} from '../../../redux/actions/merchant/merchant.action';

export const usePricingsState = () => {
  const paymentsUrl = useSelector(selectPaymentUrl);
  const locationId = useSelector(selectCurrentLocationId);
  const { list, loading, error } = useSelector(selectPricings);
  const dispatch = useDispatch();
  useEffect(() => {
    if (paymentsUrl && locationId) {
      dispatch(getPricings());
    }
  }, [paymentsUrl, locationId]);
  return { list, loading, error };
};
