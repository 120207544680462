import { css } from '@emotion/core';
import { WeaveTheme } from '@weave/theme-original';

export const ActionButtons = css`
  width: 250px;
`;

export const EditUserModal = (theme: WeaveTheme) => css`
  .react-modal-card {
    padding: ${theme.spacing(3, 4)};
    max-height: 90vh;
    width: 500px;
  }

  h2 {
    font-size: ${theme.fontSize(24)};
    font-weight: 400;
    color: ${theme.colors.gray600};
  }
`;
export const WeaveEditUserModal = (theme: WeaveTheme) => css`
  .react-modal-card {
    padding: ${theme.spacing(3, 4)};
    max-height: 90vh;
    width: 550px;
    overflow: visible;
  }
  .react-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  form {
    width: 85%;
  }

  form > div {
    /* Added this to account for spacing when error */
    height: 60px;
    margin-bottom: ${theme.spacing(0.5)};
  }

  h2 {
    font-size: ${theme.fontSize(24)};
    font-weight: 400;
    color: ${theme.colors.gray600};
    align-self: flex-start;
    margin-left: ${theme.spacing(4.5)};
  }
`;

export const container = (theme: WeaveTheme) => css`
  .ReactTable * {
    box-sizing: border-box;
  }
  .ReactTable {
    font-size: ${theme.fontSize(14)};
    color: ${theme.colors.gray600};
    line-height: 1.375;
    font-family: 'Proxima Nova', sans-serif;
    text-align: left;
    font-weight: normal;
    border: 0;
    .rt-thead .rt-th.-sort-asc,
    .rt-thead .rt-td.-sort-asc {
      box-shadow: ${theme.shadows.none};
    }
    .rt-table {
      display: flex;
      flex: auto 1;
      flex-direction: column;
      align-items: stretch;
      width: 100%;
      border-collapse: collapse;
      overflow: auto;
      .rt-thead.-header {
        box-shadow: ${theme.shadows.none};
        border: 0;
      }

      .rt-thead .rt-tr .rt-th div {
        font-size: ${theme.fontSize(14)};
        color: ${theme.colors.gray500};
        text-align: left;
        line-height: 1.375;
      }
      .rt-thead .rt-tr .rt-th {
        padding: ${theme.spacing(2, 0)};
        border-bottom: 1px solid ${theme.colors.gray300};
        border-right: 0px;
      }
      .rt-thead.-filters {
        border: 0;
      }
    }
    .rt-thead.-filters input,
    .rt-thead.-filters select {
      background: ${theme.colors.white};
      padding: ${theme.spacing(0.5, 1)};
      border-radius: ${theme.borderRadius.small};
      border: 1px solid rgba(0, 0, 0, 0.1);
      font-size: inherit;
      font-weight: 400;
      outline: none;
    }
    .rt-tbody {
      flex: 99999 1 auto;
      display: flex;
      flex-direction: column;
      overflow: auto;
      .rt-tr-group {
        border-bottom: solid 1px ${theme.colors.gray300};
      }
      .rt-tr-group .rt-tr .rt-td {
        font-size: ${theme.fontSize(16)};
        padding: ${theme.spacing(2, 0)};
        color: ${theme.colors.gray600};
        line-height: 1.375;
        align-items: center;
        border-right: 0;
        display: flex;
      }

      .rt-tr {
        flex: 1 0 auto;
        display: inline-flex;
      }
    }
    .-loading {
      background: ${theme.colors.white};
      z-index: ${theme.zIndex.background};
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      transition: all 0.3s ease;
      opacity: 0;
      pointer-events: none;
    }
    .-pagination {
      z-index: ${theme.zIndex.low};
      padding: ${theme.spacing(0.5)};
      box-shadow: ${theme.shadows.light};
      border-top: 2px solid ${theme.colors.gray600};
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap;
      .-center {
        margin-bottom: ${theme.spacing(0)};
        flex: 1.5 1;
        text-align: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
      }
      .-pageInfo {
        margin: ${theme.spacing(0.5, 1)};
        display: inline-block;
        white-space: nowrap;
      }
      .-pageJump {
        display: inline-block;
      }
      .-pageSizeOptions {
        margin: ${theme.spacing(0.5, 1)};
      }
      .-next,
      .-previous {
        flex: 1 1;
        text-align: center;
      }
    }

    .pagination-bottom .-pagination {
      border-top: 0;
      box-shadow: ${theme.shadows.none};
      padding: ${theme.spacing(2, 0)};
      width: 100%;
      display: inline-block;
      text-align: right;
      .-previous,
      .-center,
      .-next {
        display: inline-block;
      }
      .-center .-pageInfo .-pageJump input[type='number'] {
        width: 35px;
      }
      .-center .-pageSizeOptions {
        display: none;
      }
    }
  }
`;
