import { createAction, handleActions } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { CustomAxios } from '../../axios';
import { showError, showSuccess } from '@weave/alert-system';

export const getMissedCallTextTemplate = createAction('GET_MISSED_CALL_TEXT_TEMPLATE');
export const missedCallTextTemplateSuccess = createAction(
  'MISSED_CALL_TEXT_TEMPLATE_SUCCESS'
);
export const missedCallTextTemplateFailure = createAction<Error>(
  'GET_MISSED_CALL_TEXT_TEMPLATE_FAILURE'
);
export const updateMissedCallTextTemplate = createAction(
  'UPDATE_MISSED_CALL_TEXT_TEMPLATE'
);
export const updateMissedCallTextTemplateSuccess = createAction(
  'UPDATE_MISSED_CALL_TEXT_TEMPLATE_SUCCESS'
);
export const updateMissedCallTextTemplateFailure = createAction(
  'UPDATE_MISSED_CALL_TEXT_TEMPLATE_FAILURE'
);

export const handleMissedCallText = function* () {
  try {
    const response = yield call(CustomAxios.get, 'support/v1/notifications/settings');
    if (response.data.data.notificationSettings.missed_calls) {
      put(
        missedCallTextTemplateSuccess(
          response.data.data.notificationSettings.missed_calls[0]
        )
      );
    } else {
      put(missedCallTextTemplateSuccess({}));
    }
  } catch (error: any) {
    yield put(missedCallTextTemplateFailure(error));
    yield put(showError('Failed to get Missed Call text template'));
  }
};

const handlePostMissedCallTextTemplate = function* (action) {
  try {
    yield call(
      CustomAxios.put,
      `/support/v1/notifications/settings/${action.payload.id}`,
      action.payload
    );
    yield put(showSuccess('Save Successful'));
    yield put(updateMissedCallTextTemplateSuccess(action.payload));
  } catch (error: any) {
    yield put(updateMissedCallTextTemplateFailure(error));
    yield put(showError('Failed to save Missed Call text template'));
  }
};

export const missedCallTextSaga = function* () {
  yield all([
    takeEvery(getMissedCallTextTemplate.toString(), handleMissedCallText),
    takeEvery(updateMissedCallTextTemplate.toString(), handlePostMissedCallTextTemplate),
  ]);
};

const defaultState = {
  loading: false,
  data: {
    template: {
      text: '',
    },
    name: '',
  },
};

export const missedCallTextReducer = handleActions(
  {
    [getMissedCallTextTemplate.toString()]: (state) =>
      Object.assign({}, state, { loading: true }),
    [missedCallTextTemplateSuccess.toString()]: (state, action) =>
      Object.assign({}, state, {
        data: action.payload,
        loading: false,
      }),
    [missedCallTextTemplateFailure.toString()]: (state, action) =>
      Object.assign({}, state, {
        error: action.payload,
        loading: false,
      }),
    [updateMissedCallTextTemplate.toString()]: (state, action) =>
      Object.assign({}, state, { loading: true }),
    [updateMissedCallTextTemplateSuccess.toString()]: (state, action) =>
      Object.assign({}, state, {
        data: action.payload,
        loading: false,
      }),
  },
  defaultState
);
