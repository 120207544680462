import { connect } from 'react-redux';
import { OfficeHours } from './office-hours/office-hours.component';
import { getOfficeHours } from '../../../../redux/actions/office-hours/office-hours.action';
import { selectCurrentLocation } from '../../../../redux/actions/location/current-location';

export const OfficeHoursContainer = connect(
  (store: any) => ({
    officeHours: store.officeHours.data,
    loading: store.officeHours.loading,
    location: selectCurrentLocation(store),
  }),
  { getOfficeHours }
)(OfficeHours);
