import { handleActions } from 'redux-actions';
import {
  getConnectedUsers,
  getConnectedUsersFailure,
  getConnectedUsersSuccess,
} from './connected-users.action';
import UserListModel from '../../../components/users/UserListModel';

export type ConnectedUsersState = {
  loading: boolean;
  users: UserListModel[];
};

const initialState: ConnectedUsersState = {
  loading: false,
  users: [],
};

export const connectedUsersReducer = handleActions(
  {
    [getConnectedUsers.toString()]: (state, action) =>
      Object.assign({}, state, { loading: true }),
    [getConnectedUsersSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { users: action.payload, loading: false }),
    [getConnectedUsersFailure.toString()]: (state, action) =>
      Object.assign({}, state, { loading: false, users: [] }),
  },
  initialState
);
