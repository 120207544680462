import { createAction } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { CustomAxios, getErrorMessage } from '../../axios';
import { showError } from '@weave/alert-system';

export const reloadAutoMsgQueue = createAction('RELOAD_AUTO_MESSAGE_QUEUE');
export const reloadAutoMsgQueueSuccess = createAction(
  'RELOAD_AUTO_MESSAGE_QUEUE_SUCCESS'
);
export const reloadAutoMsgQueueFailure = createAction<Error>(
  'RELOAD_AUTO_MESSAGE_QUEUE_FAILURE'
);

export const handleReloadAutoMsgQueue = function* (action) {
  try {
    const response = yield call(
      CustomAxios.post,
      '/support/v1/notifications/requeue',
      {},
      {}
    );
    yield put(reloadAutoMsgQueueSuccess(response.data.data));
  } catch (error: any) {
    yield put(reloadAutoMsgQueueFailure(error));
    yield put(showError(getErrorMessage(error)));
  }
};

export const saga = function* () {
  yield all([takeEvery(reloadAutoMsgQueue.toString(), handleReloadAutoMsgQueue)]);
};
