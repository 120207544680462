import React, { useState } from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import {
  ContentLoader,
  FormRow,
  Heading,
  Modal,
  NumberField,
  PlusIcon,
  TextButton,
  TextField,
  useForm,
  useModalControl,
} from '@weave/design-system';

import { AppointmentType } from './appointment-type.component';
import {
  AppointmentTypeInterface,
  useAppointmentTypesResource,
} from './use-appointment-type.hook';

const fieldNames = { Name: 'Name', DurationMinutes: 'DurationMinutes' } as const;

export const AppointmentTypes = () => {
  const { appointmentTypes, create, loading, update, remove } =
    useAppointmentTypesResource();
  const { modalProps, triggerProps } = useModalControl();
  const [appointmentType, setAppointmentType] = useState<AppointmentTypeInterface | null>(
    null
  );

  const { formProps, getFieldProps, isComplete, reset, seedValues, values } = useForm({
    fields: {
      [fieldNames.Name]: { type: 'text', required: true },
      [fieldNames.DurationMinutes]: { type: 'number', required: true },
    },
  });

  const openModal = (updateDate?: AppointmentTypeInterface) => {
    if (updateDate) {
      const formValues = {
        Name: updateDate.Name,
        DurationMinutes: updateDate.DurationMinutes.toString() ?? '',
      };
      setAppointmentType(updateDate);
      seedValues(formValues);
    }
    triggerProps.onClick();
  };

  const resetAndCloseModal = () => {
    reset();
    setAppointmentType(null);
    modalProps.onClose();
  };

  const saveAppointmentType = async () => {
    if (isComplete) {
      const newValues = {
        Name: values.Name ?? '',
        DurationMinutes: Number(values.DurationMinutes) ?? 0,
      };
      if (!appointmentType) {
        await create(newValues);
      } else {
        const updatedValues = { ...appointmentType, ...newValues };
        await update(updatedValues);
      }
      resetAndCloseModal();
    }
  };

  return (
    <>
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          max-width: 640px;
          height: 40px;
          margin: ${theme.spacing(3, 0, 2)};
        `}
      >
        <Heading level={2}>Appointment Types</Heading>
        <TextButton
          icon={() => <PlusIcon color="primary" />}
          onClick={() => openModal()}
          css={css`
            margin-left: ${theme.spacing(2)};
          `}
        >
          Create
        </TextButton>
      </div>
      {!!appointmentTypes.length &&
        appointmentTypes.map((appointmentType) => (
          <AppointmentType
            key={appointmentType.ID}
            openEditModal={openModal}
            remove={remove}
            appointmentType={appointmentType}
          />
        ))}
      <Modal {...modalProps}>
        <>
          <ContentLoader show={loading} />
          <Modal.Header>
            {!appointmentType ? 'Create' : 'Edit'} Appointment Type
          </Modal.Header>
          <Modal.Body>
            <form
              {...formProps}
              css={css`
                padding-top: ${theme.spacing(2)};
              `}
            >
              <FormRow>
                <TextField {...getFieldProps(fieldNames.Name)} label="Appointment Name" />
              </FormRow>
              <FormRow>
                <NumberField
                  {...getFieldProps(fieldNames.DurationMinutes)}
                  label="Duration Minutes"
                  helperText="Field accepts only numbers"
                />
              </FormRow>
            </form>
          </Modal.Body>
          <Modal.Actions
            onSecondaryClick={resetAndCloseModal}
            onPrimaryClick={saveAppointmentType}
            disablePrimary={!isComplete}
          />
        </>
      </Modal>
    </>
  );
};
