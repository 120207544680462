import React from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';

interface Props {
  disabled?: boolean;
  label: string;
  on: boolean;
  onChange: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export function ToggleSwitch({ on, label, onChange, disabled = false }: Props) {
  function onToggleChange(event: React.MouseEvent<HTMLDivElement>) {
    if (!disabled) {
      onChange(event);
    }
  }

  return (
    <div
      css={
        disabled
          ? css`
              cursor: not-allowed;
              opacity: 0.5;
            `
          : ''
      }
    >
      <div
        css={css`
          background-color: ${on ? '#1fc717' : '#98a7b0'};
          border: 1px solid #cccccc;
          border-radius: 10px;
          box-sizing: border-box;
          cursor: ${disabled ? 'not-allowed' : 'pointer'};
          display: inline-block;
          height: 20px;
          margin-right: 15px;
          position: relative;
          transition: background-color 0.2s ease-in-out;
          width: 40px;

          :hover {
            background-color: ${on ? '#189912' : '#7b8e9a'};
          }

          &:focus {
            outline: 0;
          }
        `}
        onClick={onToggleChange}
      >
        <div
          aria-label={on ? 'on' : 'off'}
          css={css`
            background: ${theme.colors.white};
            border: 1px solid #ccc;
            border-radius: 9px;
            box-sizing: border-box;
            display: block;
            height: ${theme.spacing(2)};
            left: ${on ? '20px' : '1px'};
            position: absolute;
            top: 1px;
            transition: left 0.2s ease-in-out;
            width: ${theme.spacing(2)};
          `}
        />
      </div>
      <span>{label}</span>
    </div>
  );
}
