import { uniq, without } from 'lodash';
import { Reducer } from 'redux';
import { LocationsState } from '../../locations/locations.types';

export enum LocationDepartmentsActionTypes {
  Add = 'LocationDepartments:Add',
  Remove = 'LocationDepartments:Remove',
}

export type AddLocationDepartmentsAction = {
  type: LocationDepartmentsActionTypes.Add;
  payload: { locationId: string; departmentIds: string[] };
};

export type RemoveLocationDepartmentsAction = {
  type: LocationDepartmentsActionTypes.Remove;
  payload: { locationId: string; departmentIds: string[] };
};

export type LocationDepartmentsActions =
  | AddLocationDepartmentsAction
  | RemoveLocationDepartmentsAction;

export const addDepartmentsToLocation = (
  locationId: string,
  departmentIds: string[]
): LocationDepartmentsActions => ({
  type: LocationDepartmentsActionTypes.Add,
  payload: { locationId, departmentIds },
});

export const removeDepartmentsFromLocation = (
  locationId: string,
  departmentIds: string[]
): LocationDepartmentsActions => ({
  type: LocationDepartmentsActionTypes.Remove,
  payload: { locationId, departmentIds },
});

export const locationDepartmentsReducer: Reducer<
  LocationsState,
  LocationDepartmentsActions
> = (state = {}, action) => {
  if (!action.payload) {
    return state;
  }

  const location = state[action.payload.locationId];
  const prevDepartmentIds = location?.departmentIds ?? [];
  if (!location) {
    return state;
  }

  const departmentIds =
    {
      [LocationDepartmentsActionTypes.Add]: () =>
        uniq([...prevDepartmentIds, ...action.payload.departmentIds]),
      [LocationDepartmentsActionTypes.Remove]: () =>
        without(prevDepartmentIds, ...action.payload.departmentIds),
    }[action.type]?.() ?? prevDepartmentIds;

  return {
    ...state,
    [location.LocationID]: { ...location, departmentIds },
  };
};
