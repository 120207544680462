import React, { useState } from 'react';
import { cx } from 'emotion';
import {
  Button,
  Modal,
  Select,
  Option,
  Subtitle,
  Loader,
} from '@weave/platform-ui-components';
import { useAlert } from '@weave/alert-system';
import { useResources } from '../../../../helpers/useResources';
import { CustomAxios } from '../../../../redux/axios';
import * as styles from './data-center-mover.styles';
import { DataCenters, requestUpdateLocation } from '../../../../redux/actions/location';
import { useDispatch } from 'react-redux';

interface Props {
  canEdit: boolean;
  currentDataCenter: string;
  locationId: string;
  slug: string;
}

export const DataCenterMover = ({
  canEdit,
  currentDataCenter,
  locationId,
  slug,
}: Props) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedDataCenter, setSelectedDataCenter] = useState<DataCenters>();
  const [updatingDataCenter, setUpdatingDataCenter] = useState<boolean>(false);
  const alerts = useAlert();

  const { data: dataCenters } = useResources<string[]>(
    'support/v1/phone/repoint/datacenters',
    {
      deps: [locationId],
      resourceLabel: 'data centers',
      suppressFetch: !locationId ? true : false,
    }
  );

  const closeModal = () => {
    setIsOpen(false);
    setSelectedDataCenter(undefined);
  };

  const updateDataCenter = async (workstationSlug: string, destination: string) => {
    if (updatingDataCenter) {
      return;
    }

    setUpdatingDataCenter(true);

    try {
      await CustomAxios.post('support/v1/phone/repoint', {
        slug: workstationSlug,
        destination,
      });

      if (selectedDataCenter) {
        dispatch(requestUpdateLocation(locationId, { DataCenter: selectedDataCenter }));
      }
      closeModal();
      alerts.success(`switched to ${selectedDataCenter} data center!`);
    } catch {
      closeModal();
      alerts.error('Failed to switch data centers');
    }

    setUpdatingDataCenter(false);
  };

  const onChangeClick = () => {
    if (selectedDataCenter) {
      updateDataCenter(slug, selectedDataCenter);
    }
  };

  const renderDataCenters = (values: string[]) => {
    return values.reduce((arr: any, value) => {
      if (value === currentDataCenter) {
        return arr;
      }

      const option = (
        <Option key={value} value={value}>
          {value}
        </Option>
      );

      return [...arr, option];
    }, []);
  };

  if (!canEdit || dataCenters.length < 1) {
    return null;
  }

  return (
    <>
      <Modal
        className={styles.modalStyles}
        onRequestClose={closeModal}
        isOpen={isOpen}
        title={
          updatingDataCenter
            ? `Switching to ${selectedDataCenter} data center...`
            : 'Set the New Data Center'
        }
      >
        {updatingDataCenter && <Loader />}
        {!updatingDataCenter && (
          <>
            <Subtitle>Current: {currentDataCenter}</Subtitle>
            <Select value={selectedDataCenter} onChange={setSelectedDataCenter}>
              {renderDataCenters(dataCenters)}
            </Select>
            <div className={styles.btnContainer}>
              <Button
                color="gray"
                fill="outline"
                className={cx(styles.btnStyles, styles.btnMargin)}
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button color="blue" className={styles.btnStyles} onClick={onChangeClick}>
                Change
              </Button>
            </div>
          </>
        )}
      </Modal>
      <Button className={styles.btnStyles} onClick={() => setIsOpen(true)}>
        Change Data Centers
      </Button>
    </>
  );
};
