export type Ringtone = {
  value: string; // id
  label: string; // name
};

export type RingtonesState = { [id: string]: Ringtone };

export enum RingtonesActionTypes {
  RequestList = 'REQUEST_LIST_RINGTONES',
  AddRingtones = 'ADD_RINGTONES',
}

export type RequestListRingtonesAction = {
  type: RingtonesActionTypes.RequestList;
};
export type AddRingtonesAction = {
  type: RingtonesActionTypes.AddRingtones;
  payload: Ringtone[];
};

export type RingtonesActions = RequestListRingtonesAction | AddRingtonesAction;
