import {
  CreateDepartmentDto,
  DepartmentModel,
  DepartmentWithSchedules,
} from './department.types';
import { useQueryWithSetter } from '../helpers/useQueryWithSetter';
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { CustomAxios, getResponseData } from '../../redux/axios';
import { Mailbox } from '../voice-mailboxes/voice-mailboxes.types';
import { scheduleApi } from '../schedule/schedule.api';
import { omit } from 'lodash';
import { useSelector } from 'react-redux';
import { selectCurrentLocationId } from '../../redux/actions/location';

export const departmentApi = {
  baseUrl: 'support/v1/phone/departments',
  listDepartments: () =>
    CustomAxios.get(departmentApi.baseUrl).then<DepartmentModel[]>(getResponseData),
  getDepartment: (departmentId: string) =>
    CustomAxios.get(`${departmentApi.baseUrl}/${departmentId}`).then<DepartmentModel>(
      getResponseData
    ),
  createDepartment: (department: CreateDepartmentDto) =>
    CustomAxios.post(departmentApi.baseUrl, department).then<DepartmentModel>(
      getResponseData
    ),
  updateDepartment: (departmentId: string, department: Partial<DepartmentModel>) =>
    CustomAxios.put(`${departmentApi.baseUrl}/${departmentId}`, department).then<unknown>(
      getResponseData
    ),
  deleteDepartment: (departmentId: string) =>
    CustomAxios.delete(`${departmentApi.baseUrl}/${departmentId}`).then<unknown>(
      getResponseData
    ),

  getMailbox: (departmentId: string) =>
    CustomAxios.get(
      `${departmentApi.baseUrl}/${departmentId}/voicemailbox`
    ).then<Mailbox>(getResponseData),
  updateMailbox: (departmentId: string, mailbox: Mailbox) =>
    CustomAxios.put(
      `${departmentApi.baseUrl}/${departmentId}/voicemailbox`,
      mailbox
    ).then<Mailbox>(getResponseData),
};

export const useListDepartments = (options: UseQueryOptions<DepartmentModel[]> = {}) => {
  return useQueryWithSetter<DepartmentModel[]>(
    'listDepartments',
    departmentApi.listDepartments,
    options
  );
};

export const useDeleteDepartment = (
  departmentId: string,
  options?: UseMutationOptions
) => {
  const key = [useSelector(selectCurrentLocationId)];
  const queryClient = useQueryClient();
  return useMutation(
    () => departmentApi.deleteDepartment(departmentId),

    {
      onSuccess: async (...args) => {
        const departmentKey = [...key, 'department'];
        const departmentsKey = [...key, 'listDepartments'];
        const departmentsWithSchedulesKey = [...key, 'listDepartmentsWithSchedules'];

        queryClient.removeQueries(departmentKey);
        queryClient.setQueryData<DepartmentWithSchedules[]>(
          departmentsWithSchedulesKey,
          (data) => data?.filter((dep) => dep.ID !== departmentId) ?? []
        );
        queryClient.setQueryData<DepartmentModel[]>(
          departmentsKey,
          (data) => data?.filter((dep) => dep.ID !== departmentId) ?? []
        );

        if (options?.onSuccess) {
          options.onSuccess(...args);
        }
      },
      ...omit(options, ['onSuccess']),
    }
  );
};

export const useUpdateDepartment = (
  departmentId: string,
  options?: UseMutationOptions<unknown, unknown, DepartmentModel>
) => {
  const key = [useSelector(selectCurrentLocationId)];
  const queryClient = useQueryClient();

  return useMutation(
    (department: DepartmentModel) =>
      departmentApi.updateDepartment(departmentId, department),
    {
      onSuccess: (response, department, context) => {
        const departmentKey = [...key, 'department', departmentId];
        const departmentsKey = [...key, 'listDepartments'];
        const departmentsWithSchedulesKey = [...key, 'listDepartmentsWithSchedules'];

        queryClient.setQueryData<DepartmentModel | undefined>(departmentKey, (data) =>
          data ? { ...data, ...department } : undefined
        );
        queryClient.setQueryData<DepartmentModel[] | undefined>(departmentsKey, (data) =>
          data?.map((dep) => (dep.ID === departmentId ? { ...dep, ...department } : dep))
        );
        queryClient.setQueryData<DepartmentWithSchedules[] | undefined>(
          departmentsWithSchedulesKey,
          (data) =>
            data?.map((dep) =>
              dep.ID === departmentId ? { ...dep, ...department } : dep
            )
        );

        if (options?.onSuccess) {
          options.onSuccess(response, department, context);
        }
      },
      ...omit(options, ['onSuccess']),
    }
  );
};

export const useListDepartmentsWithSchedules = (
  options: UseQueryOptions<DepartmentWithSchedules[]> = {}
) => {
  return useQueryWithSetter<DepartmentWithSchedules[]>(
    'listDepartmentsWithSchedules',
    async () => {
      const departments = await departmentApi.listDepartments();
      return Promise.all(
        departments.map((department) =>
          scheduleApi
            .listSchedules(department.ID)
            .then<DepartmentWithSchedules>((schedules) => ({ ...department, schedules }))
        )
      );
    },
    options
  );
};

export const useGetDepartmentMailbox = (
  departmentId: string,
  options: UseQueryOptions<Mailbox> = {}
) => {
  return useQueryWithSetter<Mailbox>(
    ['getDepartmentMailbox', departmentId],
    () => departmentApi.getMailbox(departmentId),
    options
  );
};

export const useGetDepartment = (
  departmentId: string,
  options: UseQueryOptions<DepartmentModel> = {}
) => {
  return useQueryWithSetter<DepartmentModel>(
    ['department', departmentId],
    () => departmentApi.getDepartment(departmentId),
    options
  );
};

export const useUpdateDepartmentMailbox = (
  departmentId: string,
  options?: UseMutationOptions<unknown, unknown, Mailbox>
) => {
  const key = [useSelector(selectCurrentLocationId)];
  const queryClient = useQueryClient();

  return useMutation(
    (mailbox: Mailbox) => departmentApi.updateMailbox(departmentId, mailbox),
    {
      onSuccess: (response, mailbox, context) => {
        const departmentMailboxKey = [...key, 'getDepartmentMailbox', departmentId];

        queryClient.setQueryData<Mailbox | undefined>(
          departmentMailboxKey,
          (cachedData) => (cachedData ? { ...cachedData, ...mailbox } : undefined)
        );

        if (options?.onSuccess) {
          options.onSuccess(response, mailbox, context);
        }
      },
      ...omit(options, ['onSuccess']),
    }
  );
};

export default departmentApi;
