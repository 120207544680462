import { FacebookIntegrationStatus, GoogleIntegrationStatus } from './types';

type ErrorDescriptor = {
  message: string;
  hash: string;
  errorState: boolean;
  badge: string;
};

type ErrorMap = {
  [key: string]: ErrorDescriptor;
};

type ErrorObject = {
  [key: string]: any;
};

const googleErrorMap: ErrorMap = {
  validToken: {
    message: 'Invalid token - Sign in again',
    hash: 'invalid-token',
    errorState: false,
    badge: 'Bad Token',
  },
  hasPendingVerification: {
    message: 'Account is pending verification',
    hash: 'google-pending-verification',
    errorState: true,
    badge: 'Pending Verification',
  },
  needsReverification: {
    message: 'Account needs reverification',
    hash: 'google-reverification',
    errorState: true,
    badge: 'Needs Reverification',
  },
  isVerified: {
    message: 'Account is unverified',
    hash: 'google-unverified',
    errorState: false,
    badge: 'Unverified',
  },
  isPendingReview: {
    message: 'Account is pending review',
    hash: 'google-pending-review',
    errorState: true,
    badge: 'Pending Review',
  },
  isPublished: {
    message: 'Account is not published',
    hash: 'google-unpublished',
    errorState: false,
    badge: 'Unpublished',
  },
  isDuplicate: {
    message: 'Duplicate business account',
    hash: 'google-duplicate',
    errorState: true,
    badge: 'Duplicate',
  },
  isSuspended: {
    message: 'Account suspended',
    hash: 'google-suspended',
    errorState: true,
    badge: 'Suspended',
  },
  isDisabled: {
    message: 'Account disabled',
    hash: 'google-disabled',
    errorState: true,
    badge: 'Disabled',
  },
  isDisconnected: {
    message: 'Account disconnected',
    hash: 'google-disconnected',
    errorState: true,
    badge: 'Disconnected',
  },
};

const facebookErrorMap: ErrorMap = {
  validToken: {
    message: 'Invalid token - Log in again',
    hash: 'invalid-token',
    errorState: false,
    badge: 'Bad Token',
  },
  reviewsEnabled: {
    message: 'Recommendations disabled',
    hash: 'fb-disabled',
    errorState: false,
    badge: 'Disabled',
  },
};

const getErrorDetails = (
  errors: ErrorObject,
  errorMap: ErrorMap,
  type: 'message' | 'hash' | 'badge'
) => {
  return Object.entries(errors).reduce((arr: string[], [key, value]) => {
    if (errorMap[key]?.errorState === value) {
      return [...arr, errorMap[key][type]];
    }
    return arr;
  }, []);
};

export const getFacebookErrors = (
  status?: FacebookIntegrationStatus | null,
  type: 'message' | 'hash' | 'badge' = 'message'
) => {
  if (status === null || status === undefined || !status.needsAttention) {
    return [];
  }

  if (!status.validToken) {
    return [facebookErrorMap.validToken[type]];
  }

  return getErrorDetails(status, facebookErrorMap, type);
};

const flattenGoogleStatus = ({ locationState, ...rest }: GoogleIntegrationStatus) => ({
  ...locationState,
  ...rest,
});

export const getGoogleErrors = (
  status?: GoogleIntegrationStatus | null,
  type: 'message' | 'hash' | 'badge' = 'message'
) => {
  if (status === null || status === undefined || !status.needsAttention) {
    return [];
  }

  if (!status.validToken) {
    return [googleErrorMap.validToken[type]];
  }

  const errorObject = flattenGoogleStatus(status);
  return getErrorDetails(errorObject, googleErrorMap, type);
};
