import { css } from '@emotion/core';
import { Heading, Text } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import React from 'react';
import { InvoiceHistoryCard } from './invoice-history-card/invoice-history-card';
import { SubscriptionCard } from './subscription-card/subscription-card';

const containerStyle = css`
  padding: ${theme.spacing(4)};

  & + & {
    margin-bottom: ${theme.spacing(1)};
  }
`;

const headerStyle = css`
  border-bottom: 1px solid ${theme.colors.gray400};
  margin-bottom: ${theme.spacing(3)};

  h1 {
    margin: 0;
  }

  p {
    margin: ${theme.spacing(0, 0, 1)};
  }
`;

export const BillingDashboard = () => {
  return (
    <main>
      <div
        css={[
          containerStyle,
          css`
            background: ${theme.colors.gray300};
          `,
        ]}
      >
        <header css={headerStyle}>
          <Heading level={1}>Billing</Heading>
          <Text>Update your payment information and see past invoices</Text>
        </header>
        <SubscriptionCard />
      </div>

      <InvoiceHistoryCard />
    </main>
  );
};
