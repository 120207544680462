import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Useful for when you are in a page / section specific to a resource in a certain location
 * And you need to leave that page (probably back to the list page) when the location changes
 */
export const useLocationChangeRedirect = (locationId: string, path: string) => {
  const history = useHistory();
  const [originalLocationId, setOriginalLocationId] = useState('');

  useEffect(() => {
    if (originalLocationId && locationId !== originalLocationId) {
      history.push(path);
    } else if (locationId) {
      setOriginalLocationId(locationId);
    }
  }, [originalLocationId, locationId, history, setOriginalLocationId]);
};
