import { all, call, put, takeLatest } from 'redux-saga/effects';
import { CustomAxios, getResponseData } from '../../../axios';
import { createSelector } from 'reselect';
import { createRequestSaga } from '@weave/alert-system';

export interface LocationSettingsModel {
  key: string;
  set: string;
  value: string;
  source_id?: string;
}

export type LocationSettingsState = {
  [key: string]: LocationSettingsModel;
};

// action types + actions
export enum LocationSettingsActionTypes {
  Set = 'SET_LOCATION_SETTINGS',
  Fetch = 'FETCH_LOCATION_SETTINGS',
  Update = 'UPDATE_LOCATION_SETTINGS',
}

type UpdateLocationSettingsPayload = { [key: string]: string };

type FetchLocationsSettingsAction = {
  type: LocationSettingsActionTypes.Fetch;
  payload: string;
};

export type LocationSettingsActions =
  | FetchLocationsSettingsAction
  | { type: LocationSettingsActionTypes.Set; payload: LocationSettingsModel[] }
  | { type: LocationSettingsActionTypes.Update; payload: UpdateLocationSettingsPayload };

export const getLocationSettings = (locationId: string): LocationSettingsActions => ({
  type: LocationSettingsActionTypes.Fetch,
  payload: locationId,
});

export const setLocationSettings = (
  payload: LocationSettingsModel[]
): LocationSettingsActions => ({ type: LocationSettingsActionTypes.Set, payload });

export const updateLocationSettings = (
  payload: UpdateLocationSettingsPayload
): LocationSettingsActions => ({
  type: LocationSettingsActionTypes.Update,
  payload,
});

// Reducer
export const locationSettingsReducer = (
  state: LocationSettingsState = {},
  action: LocationSettingsActions
): LocationSettingsState => {
  switch (action.type) {
    case LocationSettingsActionTypes.Set:
      return action.payload.reduce(
        (obj, setting) => ({
          ...obj,
          [`${setting.key}${setting.source_id ? `-${setting.source_id}` : ''}`]: setting,
        }),
        {}
      );
    case LocationSettingsActionTypes.Update:
      return Object.entries(action.payload).reduce(
        (obj, [key, value]) => ({
          ...obj,
          [key]: {
            ...(obj[key] ?? {}),
            value,
          },
        }),
        state
      );
    default:
      return state;
  }
};

// Sagas
const requestSettings = (locationId: string) =>
  CustomAxios.get(`support/v1/locations/${locationId}/settings`).then(getResponseData);

export const handleLocationSettings = createRequestSaga<FetchLocationsSettingsAction>({
  key: LocationSettingsActionTypes.Fetch,
  onError: () => 'Failed to load location settings',
  saga: function* (action) {
    const settings = yield call(requestSettings, action.payload);
    yield put(setLocationSettings(settings));
  },
});

export const locationSettingsSaga = function* () {
  yield all([takeLatest(LocationSettingsActionTypes.Fetch, handleLocationSettings)]);
};

// Selectors
const selectSettingsData = (state): LocationSettingsState => state.locationSettings;

export const selectLocationSettings = createSelector(selectSettingsData, (settings) =>
  Object.values(settings)
);

export const selectLocationSettingByKey = createSelector(
  selectSettingsData,
  (state, { key }: { key: string }): string => key,
  (settings, key): LocationSettingsModel | undefined => settings[key]
);
