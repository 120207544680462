export enum FwdNumbersActionTypes {
  RequestList = 'REQUEST_LIST_FWD_NUMBERS',
  RequestGet = 'REQUEST_GET_FWD_NUMBER',
  RequestUpdate = 'REQUEST_UPDATE_FWD_NUMBERS',
  RequestDelete = 'REQUEST_DELETE_FWD_NUMBERS',
  RequestCreate = 'REQUEST_CREATE_FWD_NUMBERS',
  Add = 'ADD_FWD_NUMBER',
  Remove = 'REMOVE_FWD_NUMBER',
  Update = 'UPDATE_FWD_NUMBER',
  UpdateMultiple = 'UPDATE_FWD_NUMBERS',
}
export interface FwdNumberModel {
  id: string;
  name: string;
  number: string;
  tenantId?: string;
}

export type FwdNumberModelNoId = Omit<FwdNumberModel, 'id'>;
export type PartialFwdNumber = { id: string } & Partial<FwdNumberModelNoId>;
