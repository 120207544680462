import { showError, showSuccess } from '@weave/alert-system';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { CustomAxios } from '../../../axios';

import {
  getUser,
  getUserFailure,
  getUserSuccess,
  updateUser,
  updateUserFailure,
  updateUserSuccess,
} from './user.action';

const url = 'support/v1/users/:user_id';

const handleUpdateUser = function* (action) {
  try {
    const { password, userId, username, type, firstName, lastName } = action.payload;
    const params = {
      Password: password,
      Username: username,
      Type: type,
      FirstName: firstName,
      LastName: lastName,
    };
    const userUrl = url.replace(':user_id', userId);
    // this is a hack. The update user shouldn't require a location id. Send arbitrary uuid as a location id if it is not set
    let customHeaders = {};
    const headers = CustomAxios.getHeaders();
    if (!headers.common['Location-Id']) {
      customHeaders = { headers: { 'Location-Id': userId } };
    }
    yield call(CustomAxios.put, userUrl, params, customHeaders);

    yield put(updateUserSuccess());
    yield put(showSuccess('Successfully changed password.'));
  } catch (error: any) {
    yield put(updateUserFailure(error));
    yield put(
      showError(error.message || 'Error with form. Please, refresh and try again.')
    );
  }
};

const handleGetUser = function* (action) {
  try {
    const { username } = action.payload;

    let userUrl = url.replace(':user_id', '');
    userUrl += `?search=${username}`;
    const response = yield call(CustomAxios.get, userUrl);
    yield put(getUserSuccess(response.data.data[0] || {}));
  } catch (error: any) {
    yield put(getUserFailure(error));
    yield put(
      showError(
        error.message ||
          'Error fetching user associated with request. Please, refresh and try again.'
      )
    );
  }
};

export const userSaga = function* () {
  yield all([
    takeEvery(getUser.toString(), handleGetUser),
    takeEvery(updateUser.toString(), handleUpdateUser),
  ]);
};
