import { OnCompleteFn } from '../../store/store-utils';

export enum DepartmentPhoneNumbersActionTypes {
  RequestList = 'REQUEST_LIST_DEPARTMENT_PHONE_NUMBERS',
  RequestGet = 'REQUEST_GET_DEPARTMENT_PHONE_NUMBER',
  RequestUpdate = 'REQUEST_UPDATE_DEPARTMENT_PHONE_NUMBERS',
  RequestDelete = 'REQUEST_DELETE_DEPARTMENT_PHONE_NUMBERS',
  RequestCreate = 'REQUEST_CREATE_DEPARTMENT_PHONE_NUMBERS',
  Add = 'ADD_DEPARTMENT_PHONE_NUMBER',
  Remove = 'REMOVE_DEPARTMENT_PHONE_NUMBER',
  Update = 'UPDATE_DEPARTMENT_PHONE_NUMBER',
  UpdateMultiple = 'UPDATE_DEPARTMENT_PHONE_NUMBERS',
}

type Action<
  ActionType extends DepartmentPhoneNumbersActionTypes,
  Payload = undefined,
  onCompleteRes = undefined
> = {
  type: ActionType;
  payload: Payload;
  onComplete?: OnCompleteFn<onCompleteRes>;
};
export type RequestListDepartmentPhoneNumberAction = Action<
  DepartmentPhoneNumbersActionTypes.RequestList,
  undefined,
  DepartmentPhoneNumberModel[]
>;
export type RequestGetDepartmentPhoneNumberAction = Action<
  DepartmentPhoneNumbersActionTypes.RequestGet,
  string,
  DepartmentPhoneNumberModel
>;
export type RequestUpdateDepartmentPhoneNumberAction = Action<
  DepartmentPhoneNumbersActionTypes.RequestUpdate,
  { id: string; update: Partial<DepartmentPhoneNumberModel> },
  DepartmentPhoneNumberModel
>;
export type RequestDeleteDepartmentPhoneNumberAction = Action<
  DepartmentPhoneNumbersActionTypes.RequestDelete,
  string,
  string
>;
export type RequestCreateDepartmentPhoneNumberAction = Action<
  DepartmentPhoneNumbersActionTypes.RequestCreate,
  DepartmentPhoneNumberModel,
  DepartmentPhoneNumberModel
>;
export type AddDepartmentPhoneNumberAction = Action<
  DepartmentPhoneNumbersActionTypes.Add,
  DepartmentPhoneNumberModel[]
>;
export type RemoveDepartmentPhoneNumberAction = Action<
  DepartmentPhoneNumbersActionTypes.Remove,
  string[]
>;
export type UpdateDepartmentPhoneNumberAction = Action<
  DepartmentPhoneNumbersActionTypes.Update,
  DepartmentPhoneNumberModel
>;
export type UpdateMultipleDepartmentPhoneNumberAction = Action<
  DepartmentPhoneNumbersActionTypes.UpdateMultiple,
  DepartmentPhoneNumberModel[]
>;

export type DepartmentPhoneNumbersActions =
  | RequestListDepartmentPhoneNumberAction
  | RequestGetDepartmentPhoneNumberAction
  | RequestUpdateDepartmentPhoneNumberAction
  | RequestDeleteDepartmentPhoneNumberAction
  | RequestCreateDepartmentPhoneNumberAction
  | AddDepartmentPhoneNumberAction
  | RemoveDepartmentPhoneNumberAction
  | UpdateDepartmentPhoneNumberAction
  | UpdateMultipleDepartmentPhoneNumberAction;

export type DepartmentPhoneNumberModel = {
  phoneNumberId: string;
  departmentId: string;
  number: string;
  label: string;
  type: string;
  externalCallerName: string;
};

export const mockDepartmentPhoneNumber = (
  data: Partial<DepartmentPhoneNumberModel> = {}
) => ({
  phoneNumberId: 'asdf',
  departmentId: 'asdf',
  number: '1234567890',
  label: 'Test Number',
  type: 'test',
  externalCallerName: 'test external name',
  ...data,
});
