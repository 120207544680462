import { all, takeEvery } from 'redux-saga/effects';
import {
  getNotificationAdvancedOptionsList,
  getNotificationDeliverySummary,
  getNotificationHistory,
  getNotificationsSettings,
  handleGetDeliverySummary,
  handleGetNotificationAdvancedOptionsList,
  handleGetNotificationHistory,
  handleGetNotificationsSettings,
  handleUpdateNotificationSettings,
  handleUpdateNotificationToggle,
  updateNotificationSettings,
  updateNotificationToggle,
} from './notifications-settings.action';

export const notificationsSettingsSaga = function* () {
  yield all([
    yield takeEvery(getNotificationDeliverySummary.toString(), handleGetDeliverySummary),
    yield takeEvery(getNotificationHistory.toString(), handleGetNotificationHistory),
    yield takeEvery(
      getNotificationAdvancedOptionsList.toString(),
      handleGetNotificationAdvancedOptionsList
    ),
    yield takeEvery(getNotificationsSettings.toString(), handleGetNotificationsSettings),
    yield takeEvery(updateNotificationToggle.toString(), handleUpdateNotificationToggle),
    yield takeEvery(
      updateNotificationSettings.toString(),
      handleUpdateNotificationSettings
    ),
  ]);
};
