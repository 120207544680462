import { createAction } from 'redux-actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { CustomAxios } from '../../../../axios';

export interface AnalyticsOnboardingRequest {
  id: string;
  locationId: string;
  requestedBy: string;
  requestDate: string;
  status: string;
  demoDate?: string;
  saleDate?: string;
  syncAppUpgradedAt?: string;
  etlEnabledAt?: string;
  uiEnabledAt?: string;
  note?: string;
}

export const findAnalyticsOnboardingRequest = createAction(
  'FIND_ANALYTICS_ONBOARDING_REQUEST'
);
export const findAnalyticsOnboardingRequestNonexistant = createAction<void>(
  'FIND_ANALYTICS_ONBOARDING_REQUEST_NONEXSITANT'
);
export const findAnalyticsOnboardingRequestSuccess =
  createAction<AnalyticsOnboardingRequest>('FIND_ANALYTICS_ONBOARDING_REQUEST_SUCCESS');
export const findAnalyticsOnboardingRequestFailure = createAction<Error>(
  'FIND_ANALYTICS_ONBOARDING_REQUEST_FAILURE'
);

export const handleFindOnboardingRequest = function* (action) {
  try {
    const response = yield call(
      CustomAxios.get,
      '/support/v1/analytics-admin/onboarding/request'
    );
    if (response.statusCode === 204) {
      yield put(findAnalyticsOnboardingRequestNonexistant());
      return;
    }
    const onboardingRequest: AnalyticsOnboardingRequest = response.data.data;
    yield put(findAnalyticsOnboardingRequestSuccess(onboardingRequest));
  } catch (e: any) {
    yield put(findAnalyticsOnboardingRequestFailure(e));
  }
};

export const findOnboardingRequestSaga = function* () {
  yield takeEvery(findAnalyticsOnboardingRequest.toString(), handleFindOnboardingRequest);
};

export const mockAnalyticsOnboardingRequest = (
  props: Partial<AnalyticsOnboardingRequest> = {}
) => ({
  id: '',
  locationId: '',
  requestedBy: '',
  requestDate: '',
  status: '',
  ...props,
});
