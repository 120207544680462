import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';

export const auditFilterMenu = css`
  position: fixed;
  top: 48px;
  bottom: 0;
  right: 0;
  width: 316px;
  height: auto;
  z-index: ${theme.zIndex.higher};
  box-shadow: ${theme.shadows.heavy};
  border: 1px solid ${theme.colors.gray400};
  overflow-y: scroll;
  .divider {
    padding-bottom: ${theme.spacing(0)};
  }

  .sub-title-action {
    font-weight: 600;
    font-size: ${theme.fontSize(16)};
    color: ${theme.colors.weaveBlue};
    cursor: pointer;
    transition: ease-in 0.3s;
  }
  .sub-title-action:hover {
    color: ${theme.colors.gray400};
  }

  .filter-header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    min-height: 50px;
    h2 {
      margin: 0;
    }
  }

  .filter-body {
    display: flex;
    align-items: center;
    flex-flow: column wrap;
    margin-bottom: ${theme.spacing(1)};

    > div {
      width: 100%;
      position: relative;
      max-height: 75px;
      padding: ${theme.spacing(0, 2)};
      margin: ${theme.spacing(0, 0, 1, 0)};
    }

    .date-picker:first-child {
      margin-bottom: ${theme.spacing(1)};
    }
    .date-picker > div {
      padding: 0;
    }

    .checklist-field {
      max-height: 75px;
      overflow-y: auto;
    }
  }

  .filter-form-buttons {
    width: 100%;
    margin: ${theme.spacing(1, 0)};
    padding: ${theme.spacing(1, 2)};
    button {
      margin: ${theme.spacing(0, 1)};
      padding: ${theme.spacing(0)};
    }
  }
  .user-list,
  .location-list {
    max-height: 100px;
    overflow: scroll;
    flex-flow: row wrap;
  }
  .user-list div,
  .location-list div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    font-weight: 400;
    padding-right: 0;
    font-size: ${theme.fontSize(16)};
  }
  .location-search-box {
    margin-bottom: ${theme.spacing(3)};
  }
  .remove-x {
    cursor: pointer;
  }
`;
