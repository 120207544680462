import React from 'react';
import { IconButton, useModalControl, EditSimpleIcon } from '@weave/design-system';

import { OfficeHoursModel } from '../models';
import { OfficeHoursEditModal } from './edit/office-hours-edit-modal.component';
import * as styles from './styles';

interface Props {
  title: string;
  day: string;
  hours: OfficeHoursModel;
}

export const OfficeHoursCard = ({ title, day, hours }: Props) => {
  const { modalProps, triggerProps } = useModalControl();

  const hoursDisplay = (title: string, timeBlock: string) => {
    return (
      <div className="office-hours-row">
        <div className="title">{title}:</div>
        <div className="hour">{timeBlock === '00:00:00' ? 'N/A' : timeBlock}</div>
      </div>
    );
  };

  return (
    <div css={styles.officeHoursCard}>
      <div className="ribbon-container">
        {hours.start !== hours.stop && <div className="ribbon" />}
      </div>
      <div className="office-hours-title">
        {title}
        <IconButton {...triggerProps} label="Edit">
          <EditSimpleIcon />
        </IconButton>
      </div>
      <div>
        <div className="open-hours office-hours-section">
          {hoursDisplay('Open', hours.start)}
          {hoursDisplay('Close', hours.stop)}
        </div>
        <div className="lunch-hours office-hours-section">
          {hoursDisplay('Lunch Start:', hours.lunchStart)}
          {hoursDisplay('Lunch Stop:', hours.lunchStop)}
        </div>
        <div className="break-hours office-hours-section">
          {hoursDisplay('Break Start', hours.breakStart)}
          {hoursDisplay('Break Stop', hours.breakStop)}
        </div>
      </div>
      <OfficeHoursEditModal
        modalProps={modalProps}
        officeHours={hours}
        dayOfWeek={day}
        onClose={modalProps.onClose}
      />
    </div>
  );
};
