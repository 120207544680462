import React from 'react';
import {
  accessHeader,
  cardContainer,
  flexBetween,
  locationDetailsText,
  mRightSpacing,
  noPaddingMargin,
} from './integrations.styles';
import { CaretUpIcon, CaretDownIcon, Text, Heading } from '@weave/design-system';
import { SyncAppCount } from './sync-app-access/sync-app-access.types';

type AccessProps = {
  name: string;
  children: React.ReactNode;
  analyticsData?: SyncAppCount | null;
  numberOfLocations?: number | string;
  isSyncApps?: boolean;
};

export const Access = ({
  name,
  children,
  analyticsData,
  numberOfLocations,
  isSyncApps,
}: AccessProps) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const getHeader = (): JSX.Element => {
    return (
      <div className={accessHeader}>
        <Heading as="span" level={2}>
          {name}
        </Heading>
        {!!numberOfLocations && (
          <Text className={noPaddingMargin}>
            Number Of Locations: <b>{numberOfLocations}</b>
          </Text>
        )}
        {analyticsData && (
          <div css={locationDetailsText}>
            <Text as="span" className={mRightSpacing(3)}>
              Contacts enabled:{' '}
              <b>
                {analyticsData.contactsEnabled}{' '}
                {analyticsData.contactsEnabled > 1 ? 'Locations' : 'Location'}
              </b>
            </Text>
            <Text as="span">
              Contacts and Schedule enabled:{' '}
              <b>
                {analyticsData.scheduleEnabled}{' '}
                {analyticsData.scheduleEnabled > 1 ? 'Locations' : 'Location'}
              </b>
            </Text>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={cardContainer(isSyncApps, open)}>
      <div
        className={flexBetween(isSyncApps)}
        onClick={() => {
          setOpen(!open);
        }}
      >
        {getHeader()}
        {open ? <CaretUpIcon /> : <CaretDownIcon />}
      </div>
      {open && children}
    </div>
  );
};
