import { useState, useEffect } from 'react';
import { firebaseDB } from './firebase';

type Props = {
  ref: string;
  deps?: any[];
  suppressListen?: false;
  emptyState?: any;
};

export const useListener = ({
  ref,
  deps = [],
  suppressListen = false,
  emptyState = {},
}: Props) => {
  const dbRef = firebaseDB.ref(ref);
  const [data, setData] = useState(emptyState);

  useEffect(() => {
    let listener;
    if (!suppressListen) {
      listener = dbRef.on('value', (response) => {
        const data = response.val();
        if (typeof data === 'boolean') {
          setData(response.val());
        } else {
          setData(response.val() || emptyState);
        }
      });
    }
    return () => dbRef.off('value', listener);
  }, [...deps]);

  return data;
};
