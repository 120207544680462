import React from 'react';
import { Text } from '@weave/design-system';
import { addressText, integrationLocation, topBorder } from '../integrations.styles';
import { SyncAppAccessCheckBoxField } from './sync-app-access-checkbox';
import { CheckFieldValuesType } from './sync-app-access.types';

type Props = {
  locationName: string;
  sourceName?: string;
  locationAddress?: string;
  index: number;
  fieldValues: CheckFieldValuesType;
  sourceType?: number | undefined;
  hasMultipleClientLocations?: boolean;
  accessEnabled: boolean;
  isChildLocation?: boolean;
};

export const SyncAppAccessCardRow = ({
  locationName,
  locationAddress = '',
  sourceName,
  index,
  fieldValues,
  sourceType,
  hasMultipleClientLocations,
  accessEnabled,
  isChildLocation,
}: Props) => {
  return (
    <div className={integrationLocation(!!locationAddress.trim())}>
      <Text as="span">{sourceName}</Text>
      <div className={isChildLocation ? topBorder : ''}>
        <Text as="span">{locationName}</Text>
        {!!locationAddress.trim() && <p className={addressText}>{locationAddress}</p>}
      </div>

      <SyncAppAccessCheckBoxField
        fieldValues={fieldValues}
        accessEnabled={accessEnabled}
        sourceType={sourceType}
        hasMultipleClientLocations={hasMultipleClientLocations}
        isChildLocation={isChildLocation}
      />
    </div>
  );
};
