import React, { useEffect, useState } from 'react';
import { cx } from 'emotion';
import { orderBy, get, find } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { useResources } from '../../../helpers/useResources';
import { ConditionalLoader } from '../../shared/loader/conditional-loader.component';
import * as styles from './phone-tree-list.styles';
import { PhoneTreeModel } from './phone-tree.models';
import { PhoneTree } from './phone-tree.component';
import { Subtitle } from '@weave/platform-ui-components';
import { Page } from '../../shared/page/page.component';
import {
  getCallForwardingNumbers,
  callForwardingSelector,
} from '../../../redux/actions/call-forwarding';

export const PhoneTreeList = () => {
  const dispatch = useDispatch();
  const { loadingCallForwardingNumbers, loadingPhoneMedia, locationId, phoneMedia } =
    useSelector(callForwardingSelector);
  const [activePhoneTreeId, setActivePhoneTreeId] = useState('');

  const { data: phoneTrees, loading: loadingIVRMenus } = useResources<PhoneTreeModel[]>(
    'support/v1/ivrmenus',
    {
      deps: [locationId],
      resourceLabel: 'phone trees',
      emptyState: [],
      suppressFetch: !locationId ? true : false,
      sort: (data) => orderBy(data, ['MenuName'], ['asc']),
    }
  );

  useEffect(() => {
    dispatch(getCallForwardingNumbers());
    setActivePhoneTreeId(get(phoneTrees, '[0].IVRMenuID', ''));
  }, [locationId, get(phoneTrees, '[0].IVRMenuID', '')]);

  const onPhoneTreeClick = (phoneTreeId: string) => {
    setActivePhoneTreeId(phoneTreeId);
  };

  const getPhoneTree = (id: string) => {
    return find(phoneTrees, { IVRMenuID: id });
  };

  return (
    <Page title="Phone Tree">
      <ConditionalLoader
        loading={
          loadingIVRMenus ||
          loadingPhoneMedia ||
          loadingCallForwardingNumbers ||
          !locationId
        }
      >
        {phoneTrees.length < 1 && (
          <div>
            <Subtitle>No Phone Trees configured for this location.</Subtitle>
          </div>
        )}
        {phoneTrees.length > 0 && (
          <>
            <div className={styles.selectPhoneTree}>Select Phone Tree</div>
            <div className={styles.phoneTreeCardContainer}>
              {phoneTrees.map((phoneTree) => {
                const isActive = phoneTree.IVRMenuID === activePhoneTreeId;

                return (
                  <div
                    className={cx(styles.phoneTreeCard, `${isActive ? 'active' : ''}`)}
                    key={phoneTree.IVRMenuID}
                    onClick={() => onPhoneTreeClick(phoneTree.IVRMenuID)}
                  >
                    {phoneTree.MenuName}
                  </div>
                );
              })}
            </div>
            {!loadingIVRMenus && phoneTrees.length > 0 && !loadingPhoneMedia && (
              <PhoneTree
                activePhoneTreeId={activePhoneTreeId}
                phoneMedia={phoneMedia}
                onPhoneTreeClick={onPhoneTreeClick}
                getPhoneTree={getPhoneTree}
              />
            )}
          </>
        )}
      </ConditionalLoader>
    </Page>
  );
};
