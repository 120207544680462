import React, { useState } from 'react';
import { css } from '@emotion/core';
import {
  ConfirmationModal,
  Heading,
  IconButton,
  Modal,
  ModalControlModalProps,
  PrimaryButton,
  SyncIcon,
  Text,
  useModalControl,
  useThemeValues,
} from '@weave/design-system';

import {
  schedulingApiV2,
  schedulingQueryV2,
} from '../../../../apis/protos/scheduling/scheduling.proto-api';
import {
  centerH,
  formatEndTimeForField,
  formatStartTimeForField,
} from '../schedule-display-utils';
import { OfficeHours, Provider, SelectedTimeblock } from './providers.types';
import { ProviderTimeblockFormModal } from './provider-timeblock-form-modal.component';
import { ScheduleCalendar } from './schedule-calendar.component';
import { OfficeHoursTimeblockModal } from './office-hours-timeblock-modal.component';

const modalStyles = (theme) => css`
  padding-left: ${theme.spacing(3)};
  padding-right: ${theme.spacing(3)};
  min-width: 85vw;
  max-width: 100vw;
`;

const modalHeaderStyles = css`
  ${centerH}
  flex-direction: row;
`;

const headingStyles = css`
  width: 80%;
`;

const closeButtonStyles = css`
  width: 80px;
`;

const scheduleKeyContainerStyles = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const scheduleKeyItemStyles = (color, theme) => css`
  ${centerH}
  .schedule-color {
    height: 16px;
    width: 16px;
    margin-right: ${theme.spacing(1)};
    background-color: ${color};
    border: solid 2px ${theme.colors.gray600};
  }
  p {
    margin-top: ${theme.spacing(2.25)};
    margin-right: ${theme.spacing(3)};
  }
`;

const scheduleInfoContainerStyles = css`
  ${centerH}
  justify-content: space-between;
`;

export const scheduleKey = (theme) => [
  {
    type: 'providerHours',
    name: 'Provider Closed Hours',
    color: theme.colors.weaveBlue,
    startCol: 2,
    endCol: 5,
  },
  {
    type: 'officeHours',
    name: 'Office Closed Hours',
    color: theme.colors.gray500,
    startCol: 1,
    endCol: 2,
  },
];

const useProviderClosedTimesQuery = schedulingQueryV2(
  '/providers/{closedTime.providerId}/closed-times'
);
const useProviderApi = schedulingApiV2(
  '/providers/{providerId}/closed-times/{closedTimeId}'
);
interface ProviderScheduleModalProps {
  modalProps: ModalControlModalProps;
  selectedProvider: Provider | null;
  clearSelectedProvider: () => void;
  officeHours: OfficeHours | undefined;
  setCurrentTab: (tab: string) => void;
}

export const ProviderScheduleModal = ({
  modalProps,
  selectedProvider,
  clearSelectedProvider,
  officeHours,
  setCurrentTab,
}: ProviderScheduleModalProps) => {
  const theme = useThemeValues();
  const [selectedDay, setSelectedDay] = useState<string>('');
  const [selectedTimeblock, setSelectedTimeblock] = useState<SelectedTimeblock | null>(
    null
  );
  const { modalProps: timeblockFormModalProps, triggerProps: timeblockFormTriggerProps } =
    useModalControl();
  const {
    modalProps: officeHoursModalProps,
    triggerProps: officeHoursModalTriggerProps,
  } = useModalControl();
  const {
    modalProps: deleteTimeblockModalProps,
    triggerProps: deleteTimeblockTriggerProps,
  } = useModalControl();
  const { data: providerClosedTimesData, refetch: refetchProviderClosedTimes } =
    useProviderClosedTimesQuery({
      'closedTime.providerId': selectedProvider?.id || '',
    });
  const { delete: deleteTimeblock } = useProviderApi({
    closedTimeId: selectedTimeblock?.timeblock?.closedTimeId ?? '',
    providerId: selectedProvider?.id ?? '',
  });

  const clearSelectedTimeblock = () => {
    setSelectedTimeblock(null);
  };

  return (
    <>
      <Modal {...modalProps} css={modalStyles(theme)}>
        <Modal.Header css={modalHeaderStyles}>
          <Heading css={headingStyles}>
            {selectedProvider?.firstName} {selectedProvider?.lastName}'s Unavailablity
          </Heading>
          <PrimaryButton
            css={closeButtonStyles}
            onClick={async () => {
              await clearSelectedProvider();
              modalProps.onClose();
            }}
          >
            Close
          </PrimaryButton>
        </Modal.Header>
        <Modal.Body>
          <section css={scheduleInfoContainerStyles}>
            <div css={scheduleKeyContainerStyles}>
              {scheduleKey(theme).map((keyItem) => (
                <div
                  key={keyItem.type}
                  className="schedule-key-item"
                  css={scheduleKeyItemStyles(keyItem.color, theme)}
                >
                  <div className="schedule-color" />
                  <Text>{keyItem.name}</Text>
                </div>
              ))}
            </div>
            <IconButton
              onClick={() => refetchProviderClosedTimes()}
              label={'Refresh provider schedule'}
              showLabelOnHover
            >
              <SyncIcon />
            </IconButton>
          </section>

          <ScheduleCalendar
            clearSelectedTimeblock={clearSelectedTimeblock}
            officeHours={officeHours}
            officeHoursModalTriggerProps={officeHoursModalTriggerProps}
            providerClosedTimes={providerClosedTimesData?.data}
            setSelectedDay={setSelectedDay}
            setSelectedTimeblock={setSelectedTimeblock}
            timeblockFormTriggerProps={timeblockFormTriggerProps}
          />
        </Modal.Body>
      </Modal>

      <ProviderTimeblockFormModal
        actionType={selectedTimeblock ? 'edit' : 'create'}
        clearSelectedTimeblock={clearSelectedTimeblock}
        deleteTimeblockTriggerProps={deleteTimeblockTriggerProps}
        modalProps={timeblockFormModalProps}
        refetchProviderClosedTimes={refetchProviderClosedTimes}
        selectedDay={selectedDay}
        selectedProvider={selectedProvider}
        selectedTimeblock={selectedTimeblock}
      />

      <OfficeHoursTimeblockModal
        modalProps={officeHoursModalProps}
        setCurrentTab={setCurrentTab}
        clearSelectedProvider={clearSelectedProvider}
        clearSelectedTimeblock={clearSelectedTimeblock}
      />

      <ConfirmationModal
        {...deleteTimeblockModalProps}
        title="Delete Timeblock"
        message={`Are you sure you want to delete ${selectedDay} from ${formatStartTimeForField(
          selectedTimeblock?.timeblock?.time?.timeOfDay?.hours || 0,
          selectedTimeblock?.timeblock?.time?.timeOfDay.minutes || 0
        )} to ${formatEndTimeForField(
          selectedTimeblock?.timeblock?.time?.timeOfDay?.hours || 0,
          selectedTimeblock?.timeblock?.time?.timeOfDay.minutes || 0,
          selectedTimeblock?.timeblock?.time?.durationMinutes || 0
        )}`}
        onCancel={async () => {
          await clearSelectedTimeblock();
          deleteTimeblockModalProps.onClose();
        }}
        onConfirm={() => {
          deleteTimeblock()
            .then(() => refetchProviderClosedTimes())
            .then(() => clearSelectedTimeblock())
            .then(() => deleteTimeblockModalProps.onClose())
            .then(() => timeblockFormModalProps.onClose())
            .catch((err) => console.error(err));
        }}
      />
    </>
  );
};
