import { connect } from 'react-redux';
import { Endpoint } from '../../redux/actions/history/endpoints';
import { getHistory } from '../../redux/actions/history/history.action';
import { HistoryList } from './history-list/history-list.component';
import { selectCurrentLocationId } from '../../redux/actions/location/current-location';

export const HistoryListContainer = connect(
  (state: any, ownProps: any) => ({
    loading: state.history.loading,
    locationId: selectCurrentLocationId(state),
    title: ownProps.match.params.title,
    data: state.history.data,
    commxMigrationFlag:
      state.featureFlags.data.find((flag) => flag.Name === 'commx-migration')?.Value ??
      false,
  }),
  (dispatch) => ({
    getData: (
      locationId: string,
      title: Endpoint,
      searchParams: any,
      formatter: (data: any) => any[]
    ) =>
      dispatch(
        getHistory({
          locationId,
          title,
          searchParams,
          formatter,
        })
      ),
  })
)(HistoryList);
