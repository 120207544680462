import { handleActions, combineActions } from 'redux-actions';
import { get } from 'lodash';
import { findAnalyticsOnboardingRequest } from './find-onboarding-request';
import {
  findAnalyticsOnboardingRequestFailure,
  findAnalyticsOnboardingRequestNonexistant,
  findAnalyticsOnboardingRequestSuccess,
} from './find-onboarding-request/find-onboarding-request.action';
import { updateAnalyticsOnboardingRequest } from './update-onboarding-request';
import {
  updateAnalyticsOnboardingRequestFailure,
  updateAnalyticsOnboardingRequestSuccess,
} from './update-onboarding-request/update-onboarding-request.action';
import { createAnalyticsOnboardingRequest } from './create-onboarding-request';
import {
  createAnalyticsOnboardingRequestFailure,
  createAnalyticsOnboardingRequestSuccess,
} from './create-onboarding-request/create-onboarding-request.action';

export type AnalyticsOnboardingState = {
  loading: boolean;
  data: any;
  createdAt?: Date;
};
export const analyticsOnboardingInitialState = {
  loading: false,
  data: null,
};

export const selectAnalyticsOnboardingRequest = (state) =>
  get(state, 'analytics.onboarding.data', null);
export const selectAnalyticsOnboardingRequestSubmitted = (state) =>
  get(state, 'analytics.onboarding.createdAt', null) != null;
export const selectAnalyticsOnboardingLoading = (state) =>
  get(state, 'analytics.onboarding.loading', true);

export const analyticsOnboardingReducer = handleActions<AnalyticsOnboardingState>(
  {
    [combineActions(
      findAnalyticsOnboardingRequest,
      updateAnalyticsOnboardingRequest,
      createAnalyticsOnboardingRequest
    ).toString()]: (state, action) => Object.assign({}, state, { loading: true }),
    [combineActions(
      findAnalyticsOnboardingRequestSuccess,
      updateAnalyticsOnboardingRequestSuccess
    ).toString()]: (state, action) =>
      Object.assign({}, state, { loading: false, data: action.payload }),
    [combineActions(
      findAnalyticsOnboardingRequestFailure,
      updateAnalyticsOnboardingRequestFailure,
      createAnalyticsOnboardingRequestFailure
    ).toString()]: (state, action) =>
      Object.assign({}, state, { loading: false, error: action.payload }),
    [findAnalyticsOnboardingRequestNonexistant.toString()]: (state, action) =>
      Object.assign({}, state, { loading: false, data: null }),
    [createAnalyticsOnboardingRequestSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { loading: false, createdAt: new Date() }),
  },
  analyticsOnboardingInitialState
);
