import { handleActions } from 'redux-actions';
import { getUserPhotoSuccess, setLoading } from './user-photo.action';

const initialState = {
  data: null,
  loading: false,
};

const userPhotoReducer = handleActions(
  {
    [getUserPhotoSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { data: action.payload }),
    [setLoading.toString()]: (state, action) =>
      Object.assign({}, state, { loading: action.payload }),
  },
  initialState
);

export default userPhotoReducer;
