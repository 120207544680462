import React from 'react';
import { Button, Loader } from '@weave/platform-ui-components';
import { css, cx } from 'emotion';
import FeatureFlagModel from '../../settings/feature-flags-container/feature-flag.model';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { FeatureFlagsEdit } from '../../settings/feature-flags-container/feature-flags/feature-flags-edit.component';
import { SaveFeatureFlagModel } from '../../../redux/actions/feature-flags/feature-flags.action';
import { AnalyticsSummaryState } from '../../../redux/actions/analytics/get-analytics-summary/get-analytics-summary.action';
import moment from 'moment';

export type AnalyticsSettingsPageProps = {
  locationId: string;
  isAnalyticsAdmin: boolean;
  featureFlags: FeatureFlagModel[];
  loading: boolean;
  summary: AnalyticsSummaryState;
  saveFeatureFlags: (m: SaveFeatureFlagModel) => void;
  enableAnalyticsEtl: () => void;
  enableAnalyticsUi: () => void;
  upgradeSyncApp: () => void;
  getAnalyticsSummary: () => void;
};

const rowStyle = css`
  margin: 5px;
  border-bottom: 1px solid #efefef;

  label {
    width: 200px;
    font-weight: bold;
    margin-right: 5px;
  }
`;
export const AnalyticsSettingsPage = ({
  locationId,
  isAnalyticsAdmin,
  featureFlags = [],
  loading,
  summary,
  saveFeatureFlags,
  upgradeSyncApp,
  enableAnalyticsEtl,
  enableAnalyticsUi,
  getAnalyticsSummary,
}: AnalyticsSettingsPageProps) => {
  useEffect(() => {
    getAnalyticsSummary();
  }, [locationId]);
  const [modalOpen, setModalOpen] = useState(false);
  const onSaveFeatureFlags = useCallback(
    (save, flagsToSave) => {
      if (save) {
        saveFeatureFlags({
          updatedFlags: flagsToSave,
        });
      }
      setModalOpen(false);
    },
    [saveFeatureFlags, setModalOpen]
  );

  const lastSyncedMoment =
    summary && summary.lastSyncedAt ? moment(summary.lastSyncedAt) : undefined;
  const lastReconciledMoment =
    summary && summary.lastReconciledAt ? moment(summary.lastReconciledAt) : undefined;
  return (
    <div className="container-fluid">
      <div className="main">
        <div className="title-group">
          <div className="page-title">Analytics Settings</div>
          <div
            className={css`
              display: flex;
              * {
                margin: 0 10px;
              }
            `}
          >
            {isAnalyticsAdmin && (
              <Fragment>
                <Button
                  color="blue"
                  variant="square"
                  size="small"
                  onClick={upgradeSyncApp}
                >
                  Upgrade Sync App
                </Button>
                <Button
                  color="blue"
                  variant="square"
                  size="small"
                  onClick={enableAnalyticsEtl}
                >
                  Enable Analytics ETL
                </Button>
                <Button
                  color="blue"
                  variant="square"
                  size="small"
                  onClick={enableAnalyticsUi}
                >
                  Enable Analytics UI
                </Button>
                <Button
                  color="blue"
                  variant="square"
                  size="small"
                  onClick={() => setModalOpen(true)}
                >
                  Edit Feature Flags
                </Button>
              </Fragment>
            )}
          </div>
        </div>
        <div
          className={css`
            margin: 10px;
          `}
        >
          <div
            className={css`
              font-size: 1.25rem;
              margin: 2rem 0;
            `}
          >
            Feature Flag Values
          </div>
          {featureFlags &&
            featureFlags.map((ff) => (
              <div key={ff.Name} className={cx('row', rowStyle)}>
                <label>{ff.DisplayName}</label>
                {typeof ff.Value === 'boolean' ? (ff.Value ? 'On' : 'Off') : ff.Value}
              </div>
            ))}
          <div
            className={css`
              font-size: 1.25rem;
              margin: 2rem 0;
            `}
          >
            Location Summary
          </div>
          {loading && <Loader />}
          {!loading && (
            <React.Fragment>
              <div className={cx('row', rowStyle)}>
                <label>Analytics Compatible:</label> Coming soon!
              </div>
              <div className={cx('row', rowStyle)}>
                <label>Sync App Verion:</label> {summary.syncAppVersion || 'Unknown'}
              </div>
              <div className={cx('row', rowStyle)}>
                <label>Syncing Analytics Data:</label>{' '}
                {summary.analyticsEtlEnabled ? 'Yes' : 'No'}
              </div>
              <div className={cx('row', rowStyle)}>
                <label>Last Sync:</label>
                {lastSyncedMoment &&
                  `${lastSyncedMoment.fromNow()} // ${lastSyncedMoment.format(
                    'ddd, MMM D YYYY, h:mm:ss a'
                  )}`}
              </div>
              <div className={cx('row', rowStyle)}>
                <label>Last Reconcile:</label>
                {lastReconciledMoment &&
                  `${lastReconciledMoment.fromNow()} // ${lastReconciledMoment.format(
                    'ddd, MMM D YYYY, h:mm:ss a'
                  )}`}
              </div>
              <div className={cx('row', rowStyle)}>
                <label>Missing Snapshots:</label>
                {summary &&
                summary.missingSnapshots &&
                summary.missingSnapshots.length > 0
                  ? 'Yes'
                  : 'No'}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
      <FeatureFlagsEdit
        modalOpen={modalOpen}
        handleCloseModal={onSaveFeatureFlags}
        featureFlags={featureFlags}
      />
    </div>
  );
};
