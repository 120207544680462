import React, { useCallback, useEffect } from 'react';
import { differenceInMinutes } from 'date-fns';
import { css } from '@emotion/core';
import {
  CheckboxField,
  FormRow,
  IconButton,
  Modal,
  ModalControlModalProps,
  ModalControlTriggerProps,
  Text,
  TimeField,
  TrashIcon,
  useForm,
  useThemeValues,
} from '@weave/design-system';

import {
  centerH,
  fieldStyle,
  formatDateFromTime,
  formatEndTimeForField,
  formatStartTimeForField,
} from '../schedule-display-utils';
import {
  schedulingApiV2,
  SchedulingV2Types,
} from '../../../../apis/protos/scheduling/scheduling.proto-api';
import { Provider, SelectedTimeblock } from './providers.types';

const modalBodyStyles = css`
  overflow: visible;
`;

const trashIconStyles = (theme) => css`
  position: absolute;
  top: ${theme.spacing(1.5)};
  right: ${theme.spacing(1.5)};
`;

const useProviderClosedTimesAPI = schedulingApiV2(
  '/providers/{closedTime.providerId}/closed-times'
);
const useProviderClosedTimeAPI = schedulingApiV2(
  '/providers/{closedTime.providerId}/closed-times/{closedTime.time.closedTimeId}'
);

interface ProviderTimeblockFormModalProps {
  actionType: string;
  clearSelectedTimeblock: () => void;
  deleteTimeblockTriggerProps: ModalControlTriggerProps;
  modalProps: ModalControlModalProps;
  refetchProviderClosedTimes: any; // type missing from proto-gen
  selectedDay: string;
  selectedProvider: Provider | null;
  selectedTimeblock: SelectedTimeblock | null;
}

export const ProviderTimeblockFormModal = ({
  actionType,
  clearSelectedTimeblock,
  deleteTimeblockTriggerProps,
  modalProps,
  refetchProviderClosedTimes,
  selectedDay,
  selectedProvider,
  selectedTimeblock,
}: ProviderTimeblockFormModalProps) => {
  const theme = useThemeValues();
  const { getFieldProps, reset, seedValues, values } = useForm({
    fields: {
      startTime: {
        type: 'time',
        required: true,
      },
      endTime: {
        type: 'time',
        required: true,
      },
      fullDay: {
        type: 'checkbox',
        required: false,
      },
    },
  });
  const { post: createTimeblock } = useProviderClosedTimesAPI({
    '/closed-times': 'closedTime.providerId',
    'closedTime.providerId': selectedProvider?.id ?? '',
  });
  const { put: editTimeblock } = useProviderClosedTimeAPI({
    'closedTime.providerId': selectedProvider?.id ?? '',
    'closedTime.time.closedTimeId': selectedTimeblock?.timeblock?.closedTimeId ?? '',
  });

  const setupTimeblockForm = useCallback(() => {
    if (selectedTimeblock) {
      const { timeOfDay, durationMinutes } = selectedTimeblock.timeblock.time;
      const newStartTime = formatStartTimeForField(timeOfDay.hours, timeOfDay.minutes);
      const newEndTime = formatEndTimeForField(
        timeOfDay.hours,
        timeOfDay.minutes,
        durationMinutes
      );
      seedValues({
        startTime: newStartTime,
        endTime: newEndTime,
        fullDay: false,
      });
    } else {
      reset();
    }
  }, [selectedTimeblock]);

  const handleSaveTimeblock = useCallback(async () => {
    const hours = values.fullDay ? 0 : parseInt(values.startTime?.split(':')[0] || '0');
    const minutes = values.fullDay ? 0 : parseInt(values.startTime?.split(':')[1] || '0');
    const convertedStartTimeDate =
      values.startTime === ''
        ? formatDateFromTime('00:00:00')
        : formatDateFromTime(values.startTime);
    const convertedEndTimeDate =
      parseInt(values.endTime?.split(':')[0] || '0') === 0 &&
      parseInt(values.endTime?.split(':')[1] || '0') === 0
        ? new Date(0, 0, 1, 0, 0)
        : formatDateFromTime(values.endTime);
    const duration = values.fullDay
      ? 1440
      : differenceInMinutes(convertedEndTimeDate, convertedStartTimeDate);
    const dow = selectedDay.toUpperCase() as SchedulingV2Types['scheduleDayOfWeek'];

    if (selectedTimeblock && actionType === 'edit') {
      editTimeblock({
        closedTimeId: selectedTimeblock?.timeblock?.closedTimeId,
        closedTime: {
          providerId: selectedProvider?.id,
          time: {
            closedTimeId: selectedTimeblock?.timeblock?.closedTimeId,
            createdByLocationId: selectedTimeblock?.timeblock?.createdByLocationId,
            sourceId: 'wam',
            dayOfWeek: dow,
            time: {
              timeOfDay: {
                hours: hours ?? 0,
                minutes: minutes ?? 0,
                seconds: 0,
                nanos: 0,
              },
              durationMinutes: `${duration}`,
            },
          },
        },
      })
        .then(() => refetchProviderClosedTimes())
        .then(() => clearSelectedTimeblock())
        .catch((err) => console.error(err));
    }

    if (actionType === 'create') {
      createTimeblock({
        closedTime: {
          providerId: selectedProvider?.id ?? '',
          time: {
            sourceId: 'wam',
            dayOfWeek: dow,
            time: {
              timeOfDay: {
                hours: hours,
                minutes: minutes,
                seconds: 0,
                nanos: 0,
              },
              durationMinutes: `${duration}`,
            },
          },
        },
      })
        .then(() => refetchProviderClosedTimes())
        .catch((err) => console.error(err));
    }
  }, [
    actionType,
    values.endTime,
    values.fullDay,
    values.startTime,
    selectedDay,
    JSON.stringify(selectedProvider),
    createTimeblock,
    editTimeblock,
  ]);

  useEffect(() => {
    setupTimeblockForm();
  }, [JSON.stringify(selectedTimeblock)]);

  return (
    <Modal {...modalProps}>
      <Modal.Header>
        <Text
          css={css`
            text-transform: capitalize;
          `}
        >
          {actionType} {selectedDay} Timeblock
        </Text>
        {actionType === 'edit' && (
          <IconButton
            css={trashIconStyles(theme)}
            label="delete timeblock"
            onClick={() => deleteTimeblockTriggerProps.onClick()}
          >
            <TrashIcon />
          </IconButton>
        )}
      </Modal.Header>

      <Modal.Body css={modalBodyStyles}>
        <FormRow
          css={css`
            ${centerH}
            justify-content: center;
          `}
        >
          <CheckboxField {...getFieldProps('fullDay')} label="Out of office all day" />
        </FormRow>
        <TimeField
          {...getFieldProps('startTime')}
          label="Start Time"
          interval={15}
          css={fieldStyle}
          disabled={values.fullDay}
        />
        <TimeField
          {...getFieldProps('endTime')}
          label="End Time"
          interval={15}
          css={fieldStyle}
          disabled={values.fullDay}
        />
      </Modal.Body>

      <Modal.Actions
        onPrimaryClick={async () => {
          await handleSaveTimeblock();
          await reset();
          modalProps.onClose();
        }}
        primaryLabel={'Save'}
        onSecondaryClick={async () => {
          await clearSelectedTimeblock();
          await reset();
          modalProps.onClose();
        }}
      />
    </Modal>
  );
};
