import { useAlert } from '@weave/alert-system';
import { WeaveTheme } from '@weave/theme-original';
import {
  PlusIcon,
  SearchField,
  TextButton,
  useFormField,
  SpinningLoader,
  ReportsIcon,
  useModalControl,
  Modal,
} from '@weave/design-system';
import React, { useMemo, useCallback, useEffect } from 'react';
import { db } from '../outage-alerts.api';
import { OutageAlert, OutageAlertExtended, PortalDetails } from '../outage-alerts.types';
import { css } from '@emotion/core';
import { OutageAlertsForm } from '../outage-alerts-form/outage-alerts-form';
import { firebaseDB } from '../../universal-search/indexed-firebase/firebase';
import { IndexedContent } from '../../header-container/wam-search/use-wam-search';
import { OutageAlertReports } from '../outage-alert-reports/outage-alert-reports';
import { useSelector } from 'react-redux';
import { firebaseTokenSelector } from '../../../redux/actions/firebase-token/firebase-token.selector';
import { ListView } from './list-view';
import { NoDataFound } from './no-data-found';
import { useReducer } from 'react';
import {
  outageAlertsDetailsStatesReducer,
  OutageAlertsDetailsStatesReducerState,
  setDisableAdd,
  setShowOutageAlertForm,
  postRouteFetch,
  preOutageFetch,
  postOutageAlertFetch,
  setAndShowOutageAlertForm,
} from './outage-alerts-details-reducer';

/**
 * Css Configuration for the component
 */
const filterRow = (theme: WeaveTheme) => css`
  display: flex;
  align-items: center;
  padding: ${theme.spacing(2)} 0;
`;

const outagesList = (theme: WeaveTheme) => css`
    width: 100%;
    height: 65vh;
    overflow-y: scroll;
    > ul {
        list-style-type: none;
        overflow-y: scroll;
        height: 100%;
        margin-left: 0;
        padding-left: 0;
        > div {
          margin: ${theme.spacing(2)};
        }
        li {
            display: flex;
            justify-content: space-between;
            padding: ${theme.spacing(2)};
            margin: ${theme.spacing(1.5)};
            box-shadow: ${theme.shadows.light};
            cursor: pointer;
            p {
              margin: 0;
            }
          }
        li.disabled {
          cursor: default;
          p , span {
            color: ${theme.colors.gray400}
          }
        }
`;

const editFormContainer = (theme: WeaveTheme) => css`
  width: 100%;
  border-right: ;
`;

const outagesContainer = (theme: WeaveTheme) => css`
  display: flex;
`;

const container = (theme: WeaveTheme) => css`
  margin: ${theme.spacing(2)};
  h2,
  span {
    text-transform: capitalize;
  }
`;

const modal = css`
  max-height: 90%;
`;
/** */

type OutageAlertDetailsProps = {
  application: string;
};

const initialOutageAlertsState: OutageAlertsDetailsStatesReducerState = {
  outageAlertsData: [],
  isLoading: false,
  currentOutageAlertDetails: {},
  showOutageAlertForm: false,
  routesData: [],
  disableAdd: false,
};

/**
 * @desc: Function is define to sort the outages data by outageName
 * @param outageAlerts : OutageAlertExtended[]
 * @returns OutageAlertExtended[] (sorted outage alert)
 */
const sortData = (
  outageAlerts: OutageAlertExtended[],
  propertyName: keyof OutageAlertExtended,
  order: 'asc' | 'desc'
): OutageAlertExtended[] => {
  if (outageAlerts) {
    const sortedNonDeletedOutageAlerts = outageAlerts
      .filter((ele) => !ele.isDeleted)
      .sort((a, b) => {
        return order === 'asc'
          ? a[propertyName].toString().localeCompare(b[propertyName].toString())
          : b[propertyName].toString().localeCompare(a[propertyName].toString());
      });
    const deletedOutageAlerts = outageAlerts.filter((ele) => ele.isDeleted);
    return [...sortedNonDeletedOutageAlerts, ...deletedOutageAlerts];
  }
  return outageAlerts;
};

export const OutageAlertsDetails = ({ application }: OutageAlertDetailsProps) => {
  const { modalProps, triggerProps } = useModalControl();
  const alert = useAlert();
  const searchProps = useFormField({ type: 'text' });
  const firebaseToken = useSelector(firebaseTokenSelector);
  const [outageAlertsStates, dispatch] = useReducer(
    outageAlertsDetailsStatesReducer,
    initialOutageAlertsState
  );

  /**
   * @desc : Function is defined to fetch all routes specific to the application
   * @note : Here application indicates as application name
   */
  const fetch = useCallback(async () => {
    const indexedRes = await firebaseDB.ref(`${application}/indexed`).once('value');
    const indexedData = indexedRes.val();
    const additionalRes = await firebaseDB
      .ref(`${application}/additional-routes`)
      .once('value');
    const additionalData = Object.entries(additionalRes.val() ?? {}).map(
      ([id, value]) => ({ ...(value as IndexedContent), id, isCustom: true })
    );
    const sortedRoutes = [...indexedData, ...additionalData].sort((a, b) =>
      a.displayName.localeCompare(b.displayName)
    );
    dispatch(postRouteFetch({ routesData: sortedRoutes, isLoading: false }));
  }, [application]);

  /**
   * useEffect to fetch application specific routes on application name change
   * Also it manages the loading and showForm boolean value with outageAlert data update
   */
  useEffect(() => {
    let unsubscribe;
    if (application && firebaseToken?.firebaseToken) {
      dispatch(
        preOutageFetch({
          showOutageAlertForm: false,
          isLoading: true,
          outageAlertsData: [],
        })
      );

      fetch();
      unsubscribe = db.where('application', '==', PortalDetails[application]).onSnapshot(
        (querySnapshot) => {
          const outageAlerts: any[] = [];
          querySnapshot.forEach((doc) => {
            outageAlerts.push({
              ...doc.data(),
              docId: doc.id,
            });
          });
          dispatch(
            postOutageAlertFetch({
              outageAlertsData: sortData(outageAlerts, 'createdOn', 'desc'),
              isLoading: false,
            })
          );
        },
        (error) => {
          if (error) {
            alert.error('UnAuthorized! Access Denied');
            dispatch(setDisableAdd(true));
          }
        }
      );
    }
    return () => (unsubscribe ? unsubscribe() : null);
  }, [application, firebaseToken, fetch]);

  /**
   * useMemo to filter data based on search value
   */
  const filteredData = useMemo(() => {
    if (searchProps.value) {
      dispatch(setShowOutageAlertForm(false));
    }
    return searchProps.value
      ? outageAlertsStates.outageAlertsData.filter(
          (item: any) =>
            item.outageName.toLowerCase().includes(searchProps.value.toLowerCase()) ||
            item.path.some((v) => v.includes(searchProps.value.toLowerCase())) ||
            item.message.toLowerCase().includes(searchProps.value.toLowerCase()) ||
            item.createdBy.toLowerCase().includes(searchProps.value.toLowerCase()) ||
            item.updatedBy.toLowerCase().includes(searchProps.value.toLowerCase())
        )
      : outageAlertsStates.outageAlertsData;
  }, [searchProps.value, outageAlertsStates.outageAlertsData]);

  /**
   * @desc: Function is defined to set the current outage data selected and to showcase the outage alert form
   * @param currentOutageAlertDetails: OutageAlert
   */
  const setAndShowOutageAlertFormDataHandler = (
    currentOutageAlertDetails: OutageAlert | {}
  ) => {
    dispatch(
      setAndShowOutageAlertForm({
        currentOutageAlertDetails: currentOutageAlertDetails,
        showOutageAlertForm: true,
      })
    );
  };

  const setShowOutageAlertFormHandler = (showOutageAlertForm: boolean) => {
    dispatch(setShowOutageAlertForm(showOutageAlertForm));
  };

  return (
    <div css={container}>
      <div css={filterRow}>
        <SearchField
          style={{ marginLeft: '30px' }}
          size={95}
          {...searchProps}
          name="filter-outages"
          label="Filter Outages"
          placeholder=""
        />

        <div style={{ marginLeft: 'auto', display: 'flex' }}>
          <TextButton
            {...triggerProps}
            icon={ReportsIcon}
            disabled={
              outageAlertsStates.disableAdd ||
              outageAlertsStates.outageAlertsData.length < 1
            }
          >
            Reports
          </TextButton>
          <TextButton
            onClick={() => {
              setAndShowOutageAlertFormDataHandler({});
            }}
            disabled={outageAlertsStates.disableAdd}
            icon={PlusIcon}
          >
            Add Outage Alert
          </TextButton>
        </div>
      </div>
      <div css={outagesContainer}>
        <div css={outagesList}>
          {outageAlertsStates.isLoading ? (
            <SpinningLoader size="xl" style={{ marginLeft: '50%' }} />
          ) : (
            <ul>
              {filteredData.length > 0 ? (
                filteredData.map((item: OutageAlertExtended, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => setAndShowOutageAlertFormDataHandler(item)}
                      className={item.isDeleted ? 'disabled' : ''}
                    >
                      <ListView index={index} item={item} />
                    </li>
                  );
                })
              ) : (
                <NoDataFound data="No Data Found" />
              )}
            </ul>
          )}
        </div>
        <div css={editFormContainer}>
          {outageAlertsStates.showOutageAlertForm ? (
            <OutageAlertsForm
              outageAlertsData={outageAlertsStates.outageAlertsData}
              currentOutageAlertDetails={outageAlertsStates.currentOutageAlertDetails}
              routesData={outageAlertsStates.routesData}
              application={application}
              setShowOutageAlertFormHandler={setShowOutageAlertFormHandler}
            />
          ) : null}
        </div>
      </div>
      <Modal {...modalProps} maxWidth={1200} css={modal}>
        <Modal.Header>Outages Report For {PortalDetails[application]}</Modal.Header>
        <Modal.Body>
          <p style={{ textAlign: 'center' }}>
            *Click on the graph to get the detailed information of each section
          </p>
          <OutageAlertReports
            reportsData={outageAlertsStates.outageAlertsData}
            applicationName={PortalDetails[application]}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};
