import React from 'react';
import { differenceInMinutes } from 'date-fns';
import { components } from '@weave/shared-proto-gateway/src/schedule-api';
import { DayErrors, formatDateFromTime, weekdays } from '../schedule-display-utils';

export interface Errors {
  Monday: Partial<DayErrors>[];
  Tuesday: Partial<DayErrors>[];
  Wednesday: Partial<DayErrors>[];
  Thursday: Partial<DayErrors>[];
  Friday: Partial<DayErrors>[];
  Saturday: Partial<DayErrors>[];
  Sunday: Partial<DayErrors>[];
}

export interface EditFlags {
  Monday: boolean[] | [];
  Tuesday: boolean[] | [];
  Wednesday: boolean[] | [];
  Thursday: boolean[] | [];
  Friday: boolean[] | [];
  Saturday: boolean[] | [];
  Sunday: boolean[] | [];
}

interface EditFlagPayload {
  dayLabel: string;
  index: number;
  prevState?: components['schemas']['scheduleReservedTime'];
}

interface ErrorsPayload {
  dayLabel: string;
  index: number;
  message?: string;
  messageType?: 'error' | 'warning';
  prevState?: components['schemas']['scheduleReservedTime'];
  timeType: 'start' | 'end';
}

interface StartTimePayload {
  canClearErrors: boolean;
  dayLabel: string;
  endTime: string;
  index: number;
  value: string;
}

interface EndTimePayload {
  canClearErrors: boolean;
  dayLabel: string;
  index: number;
  startTime: string;
  value: string;
}

interface NewTimePayload {
  hours: number;
  minutes: number;
  duration: number;
  dayLabel: string;
}

interface DeleteTimePayload {
  dayLabel: string;
  index: number;
}

interface OfficeHoursState {
  week: components['schemas']['scheduleCreateOfficeClosedTimesWeeklyViewRequest'];
  errors: Partial<Errors>;
  editFlags: Partial<EditFlags>;
  isLoading: boolean;
  selectedDay: string;
}

type OfficeHoursActions =
  | {
      type: 'REFRESH_DATA';
      payload: components['schemas']['scheduleCreateOfficeClosedTimesWeeklyViewRequest'];
    }
  | {
      type: 'TOGGLE_EDIT';
      payload: EditFlagPayload;
    }
  | {
      type: 'UPDATE_ERRORS';
      payload: ErrorsPayload;
    }
  | {
      type: 'CLEAR_ERROR';
      payload: ErrorsPayload;
    }
  | {
      type: 'TOGGLE_ERROR_ALERT';
      payload: ErrorsPayload;
    }
  | {
      type: 'EDIT_START_TIMEBLOCK';
      payload: StartTimePayload;
    }
  | {
      type: 'EDIT_END_TIMEBLOCK';
      payload: EndTimePayload;
    }
  | {
      type: 'CREATE_TIMEBLOCK';
      payload: NewTimePayload;
    }
  | {
      type: 'DELETE_TIMEBLOCK';
      payload: DeleteTimePayload;
    }
  | {
      type: 'SELECTED_DAY';
      payload: string;
    };

export const initialOfficeHoursState = {
  week: {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  },
  errors: {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  },
  editFlags: {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  },
  isLoading: true,
  selectedDay: '',
};

export const OfficeHoursReducer = (
  state: OfficeHoursState,
  action: OfficeHoursActions
) => {
  const newState = { ...state };
  const clearErrors = (dayLabel: string, index: number, timeType: 'start' | 'end') => {
    newState.errors[dayLabel][index][timeType].message = '';
    newState.errors[dayLabel][index][timeType].messageType = '';
    newState.errors[dayLabel][index][timeType].show = false;
  };
  const resetToPrevState = (
    dayLabel: string,
    index: number,
    prevState: components['schemas']['scheduleReservedTime']
  ) => {
    newState.week[dayLabel][index].TimeOfDay.Hours = prevState?.TimeOfDay?.Hours || 0;
    newState.week[dayLabel][index].TimeOfDay.Minutes = prevState?.TimeOfDay?.Minutes || 0;
    newState.week[dayLabel][index].DurationMintues = prevState?.DurationMinutes || '0';
  };

  switch (action.type) {
    case 'REFRESH_DATA': {
      newState.week = action.payload;
      weekdays.forEach((weekday) => {
        newState.editFlags[weekday] = action.payload?.[weekday]?.map(() => false);
        newState.errors[weekday] = action.payload?.[weekday]?.map(() => ({
          start: {
            message: '',
            messageType: '',
            show: false,
          },
          end: {
            message: '',
            messageType: '',
            show: false,
          },
        }));
      });
      newState.isLoading = false;
      return newState;
    }

    case 'TOGGLE_EDIT': {
      const { dayLabel, index, prevState } = action.payload;
      if (newState?.editFlags?.[dayLabel][index] === true) {
        clearErrors(dayLabel, index, 'start');
        clearErrors(dayLabel, index, 'end');
        if (prevState?.TimeOfDay) {
          resetToPrevState(dayLabel, index, prevState);
        }
      }
      newState.editFlags[dayLabel][index] = !newState.editFlags[dayLabel][index];
      return newState;
    }

    case 'UPDATE_ERRORS': {
      const { dayLabel, index, message, messageType, prevState, timeType } =
        action.payload;
      newState.errors[dayLabel][index][timeType].message = message || '';
      newState.errors[dayLabel][index][timeType].messageType = messageType;
      newState.errors[dayLabel][index][timeType].show = true;
      if (prevState && messageType === 'error') {
        resetToPrevState(dayLabel, index, prevState);
      }
      return newState;
    }

    case 'CLEAR_ERROR': {
      const { dayLabel, index, timeType } = action.payload;
      clearErrors(dayLabel, index, timeType);
      return newState;
    }

    case 'TOGGLE_ERROR_ALERT': {
      const { dayLabel, index, timeType } = action.payload;
      newState.errors[dayLabel][index][timeType].show =
        !newState.errors[dayLabel][index][timeType].show;
      return newState;
    }

    case 'EDIT_START_TIMEBLOCK': {
      const { canClearErrors, dayLabel, endTime, index, value } = action.payload;
      const hours = parseInt([...value].slice(0, 2).join(''));
      const minutes = parseInt([...value].slice(3, 5).join(''));
      newState.week[dayLabel][index].DurationMinutes = `${differenceInMinutes(
        formatDateFromTime(endTime),
        formatDateFromTime(value)
      )}`;
      newState.week[dayLabel][index].TimeOfDay.Hours = hours;
      newState.week[dayLabel][index].TimeOfDay.Minutes = minutes;
      canClearErrors && clearErrors(dayLabel, index, 'start');
      return newState;
    }

    case 'EDIT_END_TIMEBLOCK': {
      const { canClearErrors, dayLabel, index, startTime, value } = action.payload;
      newState.week[dayLabel][index].DurationMinutes = `${differenceInMinutes(
        formatDateFromTime(value),
        formatDateFromTime(startTime)
      )}`;
      canClearErrors && clearErrors(dayLabel, index, 'end');
      return newState;
    }

    case 'CREATE_TIMEBLOCK': {
      const { hours, minutes, duration, dayLabel } = action.payload;
      const newTimeBlock = {
        TimeOfDay: {
          Hours: hours,
          Minutes: minutes,
          Seconds: 0,
          Nanos: 0,
        },
        DurationMinutes: `${duration}`,
        DayOfWeek: dayLabel.toUpperCase(),
      };
      newState.week[dayLabel] = [...newState.week[dayLabel], newTimeBlock].sort((a, b) =>
        a.TimeOfDay.Hours === b.TimeOfDay.Hours
          ? a.TimeOfDay.Minutes - b.TimeOfDay.Minutes
          : a.TimeOfDay.Hours - b.TimeOfDay.Hours
      );
      newState.selectedDay = '';
      return newState;
    }

    case 'DELETE_TIMEBLOCK': {
      const { index, dayLabel } = action.payload;
      newState.week[dayLabel] = newState.week[dayLabel].filter(
        (timeBlock, i) => i !== index
      );
      return newState;
    }

    case 'SELECTED_DAY': {
      newState.selectedDay = action.payload;
      return newState;
    }

    default:
      return newState;
  }
};

export const OfficeHoursContext = React.createContext<{
  state: OfficeHoursState;
  dispatch: React.Dispatch<any>;
}>({
  state: initialOfficeHoursState,
  dispatch: () => null,
});
