import React, { useState } from 'react';
import { css } from 'emotion';
import { useSelector } from 'react-redux';
import { Text, Heading } from '@weave/design-system';
import { Button, Select, Option, black } from '@weave/platform-ui-components';

import { selectCurrentLocationId } from '../../../redux/actions/location';
import { ChartLoader } from './chart-loader/chart-loader.component';
import { DateRangeValue, dateRangeOptions } from './date-filter';
import { Page } from '../../shared';
import { missedCallSMSMetrics } from './metrics';

export const AnalyticsROIScorecardPage = () => {
  const locationId = useSelector(selectCurrentLocationId);
  const [selectedDateRange, setSelectedDateRange] =
    useState<DateRangeValue>('last4Weeks');
  const [cardsLoading, setCardsLoading] = useState(0);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  /**
   * Called by child components whenever they are in a loading state to keep track if any
   * scorecards that are loading. Increment count when loading, decrement otherwise.
   *
   * @param loading
   */
  const updateCardsLoading = (loading: boolean) => {
    const amount = loading ? 1 : -1;
    setCardsLoading((prevCount) => prevCount + amount);
  };

  return (
    <Page
      title=""
      loading={false}
      className={pageStyles}
      headerActions={
        <div className={pageHeaderContainer}>
          {/* Title */}
          <Heading className={pageHeaderTitle}>ROI ScoreCard</Heading>

          {/* Date Range Select */}
          <Select
            placeholder="Date Range"
            value={selectedDateRange}
            onChange={(value) => setSelectedDateRange(value)}
            name="dateRange"
            className={dateRangeSelect}
          >
            {dateRangeOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>

          {/* Refresh Button */}
          <div className={refreshButtonWrapper}>
            <Button
              disabled={!!cardsLoading}
              onClick={async () => {
                await setShouldRefresh(true);
                setShouldRefresh(false);
              }}
            >
              Refresh
            </Button>
          </div>
        </div>
      }
    >
      {/* Subtitle */}
      <Text className={subtitle} size="large">
        Feature Impact
      </Text>

      {/* Chart Loader */}
      {missedCallSMSMetrics.map((metric) => (
        <ChartLoader
          key={metric.label}
          metric={metric}
          dateRange={selectedDateRange}
          locationId={locationId}
          updateCardsLoading={updateCardsLoading}
          shouldRefresh={shouldRefresh}
        />
      ))}
    </Page>
  );
};

const pageStyles = css`
  font-weight: 300;
`;

const pageHeaderContainer = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 0px 8px;
`;

const pageHeaderTitle = css`
  color: #333b3d;
  font-size: 24px;
  line-height: 32px;
`;

const dateRangeSelect = css`
  margin: 0 8px;
  width: 318px;
`;

const refreshButtonWrapper = css`
  display: inline-block;
  width: 160px;
  height: 40px;
  font-size: 14px;
  line-height: 19px;
`;

const subtitle = css`
  color: ${black};
  font-size: 20px;
  font-weight: bold;
  line-height: 27px;
  margin-left: 8px;
`;
