import { createAction } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { CustomAxios, getErrorMessage } from '../../axios';
import { showError } from '@weave/alert-system';

export const clearAutoMsgQueue = createAction('CLEAR_AUTO_MESSAGE_QUEUE');
export const clearAutoMsgQueueSuccess = createAction('CLEAR_AUTO_MESSAGE_QUEUE_SUCCESS');
export const clearAutoMsgQueueFailure = createAction<Error>(
  'CLEAR_AUTO_MESSAGE_QUEUE_FAILURE'
);

export const handleClearAutoMsgQueue = function* (action) {
  try {
    const response = yield call(
      CustomAxios.delete,
      '/support/v1/notifications/queue',
      {}
    );
    yield put(clearAutoMsgQueueSuccess(response.data.data));
  } catch (error: any) {
    yield put(clearAutoMsgQueueFailure(error));
    yield put(showError(getErrorMessage(error)));
  }
};

export const saga = function* () {
  yield all([takeEvery(clearAutoMsgQueue.toString(), handleClearAutoMsgQueue)]);
};
