import React, { useState } from 'react';
import { useAlert } from '@weave/alert-system';
import { WeaveTheme } from '@weave/theme-original';
import {
  Text,
  Heading,
  useForm,
  FormRow,
  TextField,
  SwitchField,
  TextareaField,
  ButtonBar,
  PrimaryButton,
  SelectListField,
  SecondaryButton,
  useModalControl,
  ConfirmationModal,
  SpinningLoader,
} from '@weave/design-system';
import { OutageAlert, OutageAlertExtended, PortalDetails } from '../outage-alerts.types';
import { useEffect } from 'react';
import { css } from '@emotion/core';
import { addDocumentData, updatedDocumentData } from '../outage-alerts.api';
import { AuthStorage } from '../../../redux/actions/auth/auth.types';

const formContainer = (theme: WeaveTheme) => css`
  padding: ${theme.spacing(2)};
`;

const optionSelect = css`
  > ul {
    overflow: scroll;
    max-height: 10rem;
  }
`;

type OutageAlertFormProps = {
  outageAlertsData: OutageAlertExtended[] | [];
  currentOutageAlertDetails: OutageAlertExtended | any;
  routesData: any;
  application: string;
  setShowOutageAlertFormHandler;
};

export const OutageAlertsForm = ({
  outageAlertsData,
  currentOutageAlertDetails,
  routesData,
  application,
  setShowOutageAlertFormHandler,
}: OutageAlertFormProps) => {
  const alert = useAlert();
  const { modalProps, triggerProps } = useModalControl();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //Form configuration
  const { formProps, getFieldProps, seedValues, reset, isComplete, values } = useForm({
    fields: {
      outageName: {
        type: 'text',
        required: true,
        value: currentOutageAlertDetails.outageName ?? '',
        disabled: currentOutageAlertDetails.isDeleted ?? false,
      },
      message: {
        type: 'text',
        required: true,
        value: currentOutageAlertDetails.message ?? '',
        disabled: currentOutageAlertDetails.isDeleted ?? false,
      },
      path: {
        type: 'selectlist',
        required: true,
        disabled: currentOutageAlertDetails.isDeleted ?? false,
      },
      isOutageActive: {
        type: 'switch',
        value: currentOutageAlertDetails.isOutageActive ?? true,
        disabled: currentOutageAlertDetails.isDeleted ?? false,
      },
    },
  });

  /**
   * useEffect to reset and seedValues on change of currentOutageAlertDetails
   */
  useEffect(() => {
    reset();
    if (Object.keys(currentOutageAlertDetails).length > 0) {
      seedValues({
        outageName: currentOutageAlertDetails.outageName ?? '',
        message: currentOutageAlertDetails.message ?? '',
        path: currentOutageAlertDetails.path ?? [],
        isOutageActive: currentOutageAlertDetails.isOutageActive ?? true,
      });
    }
  }, [currentOutageAlertDetails]);

  /**
   * @desc : Function is defined to manage onSumbit task of the form
   * @param formValues : form values
   * @note : The function manages both add and update api call's by checking the documentId (docId)
   */
  const onFormSubmitSubmit = (formValues) => {
    if (currentOutageAlertDetails.docId) {
      const dataToUpdate = getUpdateOrResolvedFormData(
        {
          ...currentOutageAlertDetails,
          ...formValues,
        },
        true
      );
      delete dataToUpdate.docId;
      checkDuplicateAndUpdate(dataToUpdate);
    } else {
      checkDuplicateAndAdd(formValues);
    }
  };

  /**
   * @desc: Function is defined to soft delete the outage
   * @param formValues : form values
   */
  const onFormDelete = (formValues) => {
    if (currentOutageAlertDetails.docId) {
      const dataToDelete = getUpdateOrResolvedFormData(
        {
          ...currentOutageAlertDetails,
          ...formValues,
          isOutageActive: false,
        },
        false
      );
      delete dataToDelete.docId;
      deleteApiCall(dataToDelete);
    }
  };

  /**
   * @desc: Function is defined to check whether the outage path is already present or not and to update it if no duplicate route
   * @param dataToUpdate : Object : OutageAlert
   */
  const checkDuplicateAndUpdate = (dataToUpdate) => {
    const duplicateAlert = checkDuplicateEntries(dataToUpdate);
    duplicateAlert
      ? alert.error(
          `The Path Configured is already present in ${duplicateAlert.outageName}`
        )
      : updateApiCall(dataToUpdate);
  };

  /**
   * @desc: Function is defined to check whether the outage path is already present or not and to add it if no duplicate route
   * @param formValues : form values
   */
  const checkDuplicateAndAdd = (formValues) => {
    const duplicateAlert = checkDuplicateEntries({
      ...currentOutageAlertDetails,
      ...formValues,
    });
    duplicateAlert
      ? alert.error(
          `The Path Configured is already present in ${duplicateAlert.outageName}`
        )
      : addApiCall(getCreateFormData({ ...currentOutageAlertDetails, ...formValues }));
  };

  /**
   * @desc: Function is defined to check whether the routes added is already present or not
   * @param dataToUpdate )bject : OutageAlert
   * @returns : OutageAlert
   */
  const checkDuplicateEntries = (dataToUpdate) => {
    return outageAlertsData.find((outageAlerts) => {
      return (
        outageAlerts.path.some((r) => dataToUpdate.path.includes(r)) &&
        outageAlerts.outageId !== dataToUpdate.outageId &&
        !outageAlerts.isDeleted
      );
    });
  };

  /**
   * @desc : Function is defined to add data in firestore collection using addDocumentData function
   * @param dataToAdd : Object : OutageAlert
   */
  const addApiCall = (dataToAdd: OutageAlert) => {
    setIsLoading(true);
    addDocumentData(dataToAdd)
      .then((res) => {
        setIsLoading(false);
        alert.success('Outage Added Successfully');
        //Reset's add form on success
        reset();
      })
      .catch((error) => {
        setIsLoading(false);
        alert.error('Error Creating Outage ');
      });
  };

  /**
   * @desc: Function is defined to update data in firestore collection by documentId (docId)
   * @param dataToUpdate : Object : OutageAlert
   */
  const updateApiCall = (dataToUpdate: OutageAlert) => {
    setIsLoading(true);
    updatedDocumentData(dataToUpdate, currentOutageAlertDetails.docId)
      .then(() => {
        setIsLoading(false);
        alert.success('Outage Data Updated Successfully');
      })
      .catch((error) => {
        setIsLoading(false);
        alert.error('Error Updating Outage Data');
      });
  };

  /**
   * @desc: Function is defined to delete document from firestore db by documentId
   * @param documentId : string
   */
  const deleteApiCall = (document: OutageAlert) => {
    modalProps.onClose();
    setIsLoading(true);
    updatedDocumentData(document, currentOutageAlertDetails.docId)
      .then(() => {
        setIsLoading(false);
        alert.success('Outage Alert deleted Sucessfully');
        setShowOutageAlertFormHandler(false);
      })
      .catch((error) => {
        setIsLoading(false);
        alert.error('Error while deleting Outage Alert');
      });
  };

  /**
   * @desc : Function is defined to prepare update object for API
   * @param formData : Object
   * @returns Object
   */
  const getUpdateOrResolvedFormData = (formData, isUpdate) => {
    return {
      ...formData,
      updatedOn: isUpdate ? new Date().toISOString() : '',
      updatedBy: isUpdate ? localStorage.getItem(AuthStorage.user_email) : '',
      resolvedOn: !formData.isOutageActive ? new Date().toISOString() : '',
      resolvedBy: !formData.isOutageActive
        ? localStorage.getItem(AuthStorage.user_email)
        : '',
      deletedBy: !isUpdate ? localStorage.getItem(AuthStorage.user_email) : '',
      deletedOn: !isUpdate ? new Date().toISOString() : '',
      isDeleted: !isUpdate ? true : false,
    };
  };

  /**
   * @desc : Function is defined to prepare create object for API
   * @param formData
   * @returns
   */
  const getCreateFormData = (formData) => {
    return {
      ...formData,
      application: `${PortalDetails[application]}`,
      outageId: `${application}-${new Date().valueOf()}`,
      createdOn: new Date().toISOString(),
      createdBy: localStorage.getItem(AuthStorage.user_email),
      updatedOn: '',
      updatedBy: '',
      resolvedOn: '',
      resolvedBy: '',
      deletedBy: '',
      deletedOn: '',
      isDeleted: false,
    };
  };

  return (
    <>
      <Heading level={1} style={{ textAlign: 'center' }}>
        {currentOutageAlertDetails.isDeleted
          ? 'Deleted Outage Alert'
          : currentOutageAlertDetails.docId
          ? 'Edit Outage Alert'
          : 'Add New Outage Alert'}
      </Heading>
      {isLoading ? (
        <SpinningLoader size="xl" style={{ marginLeft: '50%' }} />
      ) : (
        <div css={formContainer}>
          <form {...formProps}>
            <FormRow>
              <TextField
                {...getFieldProps('outageName')}
                label="Outage Alert Name / Title"
              />
            </FormRow>
            <FormRow>
              <SelectListField
                {...getFieldProps('path')}
                label="Service Path And Name"
                css={optionSelect}
              >
                {routesData.map((data, i) => {
                  return (
                    <SelectListField.Option key={i} value={data.route}>
                      {data.displayName} - {data.route}
                    </SelectListField.Option>
                  );
                })}
              </SelectListField>
            </FormRow>
            <FormRow>
              <TextareaField {...getFieldProps('message')} label="Outage Alert Message" />
            </FormRow>
            <FormRow cols={[50]}>
              <SwitchField
                {...getFieldProps('isOutageActive')}
                label="Activate Outage"
              ></SwitchField>
            </FormRow>
            <Text weight="bold">Note</Text>
            <Text>
              The Outage Alert configured will be visible in{' '}
              <b>{PortalDetails[application]}</b>
            </Text>
            <ButtonBar>
              <PrimaryButton
                disabled={!isComplete || currentOutageAlertDetails.isDeleted}
                onClick={() => onFormSubmitSubmit(values)}
              >
                {currentOutageAlertDetails.docId
                  ? 'Save Outage Alert'
                  : 'Add Outage Alert'}
              </PrimaryButton>
              {currentOutageAlertDetails.docId ? (
                <SecondaryButton
                  style={
                    !currentOutageAlertDetails.isDeleted
                      ? { background: 'red', color: 'white' }
                      : {}
                  }
                  {...triggerProps}
                  disabled={currentOutageAlertDetails.isDeleted}
                >
                  Delete Outage
                </SecondaryButton>
              ) : null}
            </ButtonBar>
          </form>
        </div>
      )}
      <ConfirmationModal
        {...modalProps}
        title="Confirm this action"
        message="Are you sure you want to delete the Outage Alert ?"
        onConfirm={() => onFormDelete(values)}
      />
    </>
  );
};
