import React from 'react';
import { css } from '@emotion/core';
import { InlineLoader } from './inline-loader.component';
import { WeaveTheme } from '@weave/theme-original';
import { SwitchField, Text, useControlledField } from '@weave/design-system';

interface Props {
  isChecked: boolean;
  isUpdating: boolean;
  label: string;
  onChange: (isSelected: boolean) => void;
}

export const SettingsToggle = ({ isChecked, isUpdating, label, onChange }: Props) => {
  const field = useControlledField({ type: 'switch', value: isChecked, onChange });
  return (
    <SwitchField
      css={(theme: WeaveTheme) =>
        css`
          margin-bottom: ${theme.spacing(1)};
        `
      }
      {...field}
      label={
        <>
          <Text weight="bold">{label}</Text>
          {isUpdating && <InlineLoader />}
        </>
      }
      name={label}
    />
  );
};
