import React, { useContext } from 'react';
import moment from 'moment';
import { AuditContext } from './audit.context';
import { useAlert } from '@weave/alert-system';
import { resultsContainer } from './styles/results';
import { Heading, SpinningLoader } from '@weave/design-system';
import {
  ActionFilter,
  AuditEvent,
  LocationFilter,
  ResourceFilter,
  UserFilter,
} from './audit.types';

export const AuditResults = () => {
  const alerts = useAlert();
  const { filters, eventQuery, isGlobalView, setFilters } = useContext(AuditContext);

  const addToFilters = (
    key: string,
    filter: UserFilter | ActionFilter | ResourceFilter
  ) => {
    switch (key) {
      case 'user':
        setFilters({ ...filters, User: [filter as UserFilter] });
        break;
      case 'action':
        setFilters({ ...filters, Action: [filter as ActionFilter] });
        break;
      case 'resource':
        setFilters({ ...filters, Resource: [filter as ResourceFilter] });
        break;
      case 'location':
        setFilters({ ...filters, Location: [filter as LocationFilter] });
        break;
      default:
        break;
    }
  };

  return (
    <section css={resultsContainer}>
      {eventQuery.isLoading ? (
        <SpinningLoader className="loader" size="medium" />
      ) : eventQuery.status === 'error' || !eventQuery.data || !eventQuery.data.pages ? (
        <Heading className="mt-3" level={2}>
          No matches found . . .
        </Heading>
      ) : (
        <table>
          <thead className="divider">
            <tr>
              <th className="py-4 pl-2">Date/Time</th>
              <th className="py-4">User</th>
              <th className="py-4">Action</th>
              <th className="py-4">Resource</th>
              {isGlobalView && <th className="py-4">Location</th>}
              <th className="py-4">Description</th>
            </tr>
          </thead>
          {eventQuery.data.pages.map((page, i) => (
            <tbody key={i}>
              {page?.event?.map((event: AuditEvent, j: number) => (
                <tr className="divider" key={j}>
                  <td className="pt-3 pb-4 pl-2">
                    <p>{moment(event.timestamp).format('LLLL')}</p>
                  </td>
                  {page.displayMap && (
                    <td className="pt-3 pb-4">
                      <p
                        className="clickable"
                        onClick={() => {
                          eventQuery.isFetchingNextPage
                            ? alerts.info('query in progress . . .')
                            : addToFilters('user', {
                                id: event.userId ?? '0000',
                                display: page.displayMap[event.userId] ?? 'John Doe',
                              });
                        }}
                      >
                        {page.displayMap[event.userId] ?? 'John Doe'}
                      </p>
                    </td>
                  )}

                  <td className="pt-3 pb-4">
                    <p
                      className="clickable"
                      onClick={() => {
                        eventQuery.isFetchingNextPage
                          ? alerts.info('query in progress . . .')
                          : addToFilters('action', { display: event.action });
                      }}
                    >
                      {event.action}
                    </p>
                  </td>
                  <td className="pt-3 pb-4">
                    <p
                      className="clickable"
                      onClick={() => {
                        eventQuery.isFetchingNextPage
                          ? alerts.info('query in progress . . .')
                          : addToFilters('resource', { display: event.resource });
                      }}
                    >
                      {event.resource}
                    </p>
                  </td>
                  {isGlobalView && page.displayMap && (
                    <td className="pt-3 pb-4">
                      <p
                        className="clickable"
                        onClick={() => {
                          eventQuery.isFetchingNextPage
                            ? alerts.info('query in progress . . .')
                            : addToFilters('location', {
                                id: event.locationId ?? '0',
                                display:
                                  page.displayMap[event.locationId] ?? 'Planet Earth',
                              });
                        }}
                      >
                        {page.displayMap[event.locationId] ?? 'Planet Earth'}
                      </p>
                    </td>
                  )}
                  <td className="pt-3 pb-4">
                    <p>{event.description}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          ))}
        </table>
      )}
      {eventQuery.isFetchingNextPage && (
        <SpinningLoader className="loader" size="small" />
      )}
    </section>
  );
};
