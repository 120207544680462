import _ from 'lodash';
import { createAction } from 'redux-actions';
import { call, put, takeLatest } from 'redux-saga/effects';

import { CustomAxios } from '../../axios';
import { NotificationsSettings } from '../../../redux/actions/notifications-settings/notification-settings.types';
import { LegacySettings } from '../../../components/settings/reviews/helpers';

export const reviewsSettingsUrl = '/support/v1/reviews/settings';
export const reviewsUrlsUrl = '/support/v1/reviews/urls';
export const reviewsOrderUrl = '/support/v1/reviews/order';
export const reviewsMappingUrl = '/reviews/mappings';
export const notificationsSettingsUrl = '/notifications/settings';
export const notificationsTemplatesUrl = '/notifications/templates';

export const getReviewsSettings = createAction('GET_REVIEWS_SETTINGS');
export const getReviewsSettingsFailure = createAction('GET_REVIEWS_SETTINGS_FAILURE');
export const getReviewsSettingsSuccess = createAction('GET_REVIEWS_SETTINGS_SUCCESS');

export interface ListUrls {
  data: ListUrl[];
}

export interface ListUrl {
  source: string;
  url: string;
}

export interface ReviewsOrder {
  data: string[];
}

export interface ReviewsMappings {
  facebookMapping: Mapping | null;
  googleMapping: Mapping | null;
  showUpdateNotification: boolean;
}

export interface Mapping {
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  needsAttention: boolean;
}

export interface DefaultTemplate {
  data: { templates: { email: object; sms: object } };
}

export interface ReviewSettingsGroup {
  reviewsSettings: { data: LegacySettings };
  reviewsTemplate: any | { notificationSettings: NotificationsSettings };
  listUrls: ListUrls;
  reviewsOrder: ReviewsOrder;
  reviewsMappings: ReviewsMappings;
  defaultTemplate: DefaultTemplate | {};
}

export const handleReviewSettings = function* () {
  try {
    const reviewsSettingsResponse = yield call(CustomAxios.get, reviewsSettingsUrl);
    const reviewsSettings = reviewsSettingsResponse.data;

    const listUrlsResponse = yield call(CustomAxios.get, reviewsUrlsUrl);
    const listUrls = listUrlsResponse.data;

    const reviewsOrderUrlResponse = yield call(CustomAxios.get, reviewsOrderUrl);
    const reviewsOrder = reviewsOrderUrlResponse.data;

    const reviewsMappingsResponse = yield call(CustomAxios.get, reviewsMappingUrl);
    const reviewsMappings = reviewsMappingsResponse.data;

    const reviewsTemplateResponse = yield call(CustomAxios.get, notificationsSettingsUrl);
    const reviewsTemplate = reviewsTemplateResponse.data;

    let defaultTemplate: DefaultTemplate | {} = {};
    const reviewsCount = _.get(
      reviewsTemplate,
      'data.notificationSettings.reviews.length',
      0
    );
    if (!reviewsCount) {
      const notificationsTemplatesResponse = yield call(
        CustomAxios.get,
        notificationsTemplatesUrl
      );
      defaultTemplate = notificationsTemplatesResponse.data;
    }

    const data: ReviewSettingsGroup = {
      reviewsSettings,
      listUrls,
      reviewsOrder,
      reviewsMappings,
      reviewsTemplate,
      defaultTemplate,
    };

    yield put(getReviewsSettingsSuccess(data));
  } catch (error: any) {
    yield put(getReviewsSettingsFailure({ error }));
  }
};

export const getReviewsSettingsSaga = function* () {
  yield takeLatest(getReviewsSettings.toString(), handleReviewSettings);
};
