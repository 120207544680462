import React, { useEffect } from 'react';
import { useDocuments } from './documents.hook';
import { DocumentType } from './auto-reminder-templates';
import { DropdownField, Text, useFormField } from '@weave/design-system';

type Props = {
  documentId?: string;
  templateLanguageIndex: number;
  updateTemplateDocument: (
    templateIndex: number,
    documentId: string,
    documentType: DocumentType
  ) => void;
};

export function DocumentSelect({
  documentId,
  templateLanguageIndex,
  updateTemplateDocument,
}: Props) {
  const { documentLinksEnabled, documents } = useDocuments();
  const documentFieldProps = useFormField({ type: 'dropdown', value: documentId }, [
    documentId,
    templateLanguageIndex,
  ]);

  // Needs any to avoid an issue with the design system's types
  // Actual type is { name: string, value: string }
  // Can't use onChange with this field, have to use useEffect so it doesn't cache
  useEffect(() => {
    if (documentFieldProps.value !== documentId) {
      const selectedDocument = documents.find(
        (document) => document.id === documentFieldProps.value
      );
      const documentType = selectedDocument?.type ?? DocumentType.unknown;

      updateTemplateDocument(
        templateLanguageIndex,
        documentFieldProps.value,
        documentType
      );
    }
  }, [documentFieldProps.value]);

  if (!documentLinksEnabled) {
    return null;
  }

  if (!documents.length) {
    return <Text color="warn">No forms/packets found for this location.</Text>;
  }

  return (
    <DropdownField {...documentFieldProps} label="Form/Packet" name="document">
      <DropdownField.Option value="">None</DropdownField.Option>
      {documents.map((document) => (
        <DropdownField.Option key={document.id} value={document.id}>
          {document.name}
        </DropdownField.Option>
      ))}
    </DropdownField>
  );
}
