import { uniq, without } from 'lodash';
import { Reducer } from 'redux';
import { LocationsState } from '../../locations/locations.types';

export enum LocationRingtonesActionTypes {
  Add = 'LocationRingtones:Add',
  Remove = 'LocationRingtones:Remove',
}

export type AddLocationRingtonesAction = {
  type: LocationRingtonesActionTypes.Add;
  payload: { locationId: string; ringtoneIds: string[] };
};

export type RemoveLocationRingtonesAction = {
  type: LocationRingtonesActionTypes.Remove;
  payload: { locationId: string; ringtoneIds: string[] };
};

export type LocationRingtonesActions =
  | AddLocationRingtonesAction
  | RemoveLocationRingtonesAction;

export const addRingtonesToLocation = (
  locationId: string,
  ringtoneIds: string[]
): LocationRingtonesActions => ({
  type: LocationRingtonesActionTypes.Add,
  payload: { locationId, ringtoneIds },
});

export const removeRingtonesFromLocation = (
  locationId: string,
  ringtoneIds: string[]
): LocationRingtonesActions => ({
  type: LocationRingtonesActionTypes.Remove,
  payload: { locationId, ringtoneIds },
});

export const locationRingtonesReducer: Reducer<LocationsState, LocationRingtonesActions> =
  (state = {}, action) => {
    if (!action.payload) {
      return state;
    }

    const location = state[action.payload.locationId];
    const prevRingtoneIds = location?.ringtoneIds ?? [];
    if (!location) {
      return state;
    }

    const ringtoneIds =
      {
        [LocationRingtonesActionTypes.Add]: () =>
          uniq([...prevRingtoneIds, ...action.payload.ringtoneIds]),
        [LocationRingtonesActionTypes.Remove]: () =>
          without(prevRingtoneIds, ...action.payload.ringtoneIds),
      }[action.type]?.() ?? prevRingtoneIds;

    return {
      ...state,
      [location.LocationID]: { ...location, ringtoneIds },
    };
  };
