import { css } from 'emotion';
import { weaveBlue, weaveWarning } from '../../shared/styles/colors';

export const minWidth = css`
  min-width: 1100px;
`;

export const tableNames = css`
  label: tableNames;
  text-transform: capitalize;
`;

export const alignRight = css`
  text-align: left;
  span {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: relative;
    top: 5px;
    padding: 5px 5px 0 5px;
    background: rgba(0, 0, 0, 0.03);
  }
`;

export const toggle = css`
  label: toggle;
  display: flex;
  flex: 1;
  & > div {
    align-items: center;
    display: flex;
  }

  & > div > .toggle-switch {
    top: 0;
  }

  & > div > span {
    color: #37cff1;
    font-size: 120%;
  }
`;

export const warning = css`
  color: ${weaveWarning};
`;

export const splitRow = css`
  display: flex;
  flex-direction: row;
  & div {
    position: relative;
    flex: 1;
  }
  & .dull {
    label: dull;
    opacity: 0.7;
  }
  & .clearSelection {
    position: absolute;
    top: -10px;
    left: -10px;
    :hover {
      cursor: pointer !important;
    }
  }
`;

export const settingContainer = css`
  display: flex;
  div {
    margin: 8px 16px;
  }
`;

export const autoReminderLinkStyles = css`
  color: ${weaveBlue};
  font-weight: bold;
  margin-top: 16px;

  svg {
    margin-left: 8px;
  }
`;

export const refreshButtonStyles = css`
  display: inline-block !important;
`;
