import { css } from 'emotion';

export const modalStyles = css`
  .react-modal-card {
    margin: 0;
    padding: 32px;
    text-align: center;
  }

  label: modalStyles;
`;

export const btnContainer = css`
  margin: 32px 0 16px;

  label: btnContainer;
`;

export const btnStyles = css`
  display: inline-block !important;

  label: btnStyles;
`;

export const btnMargin = css`
  margin-right: 16px;

  label: btnMargin;
`;
