import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { createAction } from 'redux-actions';
import _ from 'lodash';
import { showError, showSuccess } from '@weave/alert-system';

import { REACT_APP_FF_V0_URL } from '../../../config/app';
import { CustomAxios, getErrorMessage } from '../../axios';
import FeatureFlagModel from '../../../components/settings/feature-flags-container/feature-flag.model';
import { selectCurrentLocationId } from '../location/current-location';
import { FeatureFlags } from './feature-flags.types';

export const getFeatureFlags = createAction<string>('GET_FEATURE_FLAGS');
export const getFeatureFlagsSuccess = createAction<{
  data: FeatureFlagModel[];
  locationId: string;
}>('GET_FEATURE_FLAGS_SUCCESS');
export const saveFeatureFlags = createAction<SaveFeatureFlagModel>('SAVE_FEATURE_FLAGS');
export const saveFeatureFlagsSuccess = createAction('SAVE_FEATURE_FLAGS_SUCCESS');

export const createFeatureFlag = createAction('CREATE_FEATURE_FLAG');
export const createFeatureFlagSuccess = createAction('CREATE_FEATURE_FLAG_SUCCESS');

export interface SaveFeatureFlagModel {
  updatedFlags: FeatureFlagModel[];
}

export const handleGetFeatureFlags = function* (action) {
  const locationId: string = action.payload;

  if (locationId) {
    try {
      const response = yield call(
        CustomAxios.get,
        `support/v1/featureflags/${locationId}`
      );
      yield put(getFeatureFlagsSuccess({ data: response.data.data, locationId }));
    } catch (error: any) {
      yield put(showError(getErrorMessage(error)));
    }
  }
};

const handleSaveFeatureFlags = function* (action) {
  const { updatedFlags } = action.payload;
  const locationId: ReturnType<typeof selectCurrentLocationId> = yield select(
    selectCurrentLocationId
  );
  const featureSaveCalls: any[] = [];
  _.forEach(updatedFlags, (flag) => {
    const body = { flagName: flag.Name, value: flag.Value };
    featureSaveCalls.push(
      call(CustomAxios.post, `support/v1/featureflags/${locationId}`, body)
    );
  });

  try {
    yield all(featureSaveCalls);

    yield put(showSuccess('Save Successful'));
    yield put(saveFeatureFlagsSuccess(updatedFlags));
  } catch (error: any) {
    yield put(showError(getErrorMessage(error)));
  }
};

const handleCreateFeatureFlag = function* (action) {
  const { enabledByDefault, displayName, name } = action.payload;

  try {
    yield call(
      CustomAxios.post,
      `flags/${name}`,
      {
        DisplayName: displayName,
        DefaultValue: enabledByDefault,
      },
      {
        baseURL: REACT_APP_FF_V0_URL,
      }
    );
    yield put(
      createFeatureFlagSuccess({
        DisplayName: displayName,
        Name: name,
        Value: enabledByDefault,
      })
    );
  } catch (error: any) {
    yield put(showError(getErrorMessage(error)));
  }
};

export const featureFlagsSaga = function* () {
  yield all([
    takeEvery(getFeatureFlags.toString(), handleGetFeatureFlags),
    takeEvery(saveFeatureFlags.toString(), handleSaveFeatureFlags),
    takeEvery(createFeatureFlag.toString(), handleCreateFeatureFlag),
  ]);
};

export const selectFeatureFlag = (state: FeatureFlags, flagName: string) =>
  state.featureFlags?.data.find(({ Name }) => Name === flagName)?.Value;
