import { ScheduleWithRouting } from '../schedule/schedule.types';

export type DepartmentState = { [id: string]: DepartmentModel };

export enum DepartmentScheduleTypes {
  Open = 'Open',
  Closed = 'Closed',
  Break = 'Break',
}
export type DepartmentModel = {
  ID: string;
  name: string;
  externalCallerIDName: string; //limit of 15 characters
  extension: string | null;
  extensionEnabled: boolean;
};

export type DepartmentWithSchedules = DepartmentModel & {
  schedules: ScheduleWithRouting[];
};

export type CreateDepartmentDto = {
  name: string;
};
