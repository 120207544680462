import React from 'react';
import { css } from '@emotion/core';
import { WeaveTheme } from '@weave/theme-original';
import { Text, KeyNames } from '@weave/design-system';
import { Product } from '../../sales-pricing.types';

type Props = {
  productData: Product;
  isSelected: boolean;
  getProductServiceFeeText: (product: Product) => string;
  getProductActivationFeeText: (product: Product) => string | null;
  onAddonProductClicked: (softwareProduct: Product) => void;
};

export const AddonFeatureCard = ({
  productData,
  isSelected,
  getProductServiceFeeText,
  getProductActivationFeeText,
  onAddonProductClicked,
}: Props) => {
  const handleKeyDownEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const returnKeyWasPressed = event.key === KeyNames.Enter;
    if (returnKeyWasPressed) {
      onAddonProductClicked(productData);
    }
  };

  return (
    <div
      tabIndex={0}
      css={(theme) => [containerStyles(theme), isSelected && activeCardStyles(theme)]}
      onClick={() => onAddonProductClicked(productData)}
      onKeyDown={handleKeyDownEvent}
    >
      <div css={cardStyles}>
        <div css={imgContainerStyles}>
          <img src={productData.image} alt="Addon" />
        </div>
        <div css={titleAndIconContainerStyles}>
          <div>
            <Text css={titleStyles}>{productData.displayName}</Text>
            <Text css={priceTextStyles} size="small" color="light">
              {getProductActivationFeeText(productData)}
              {getProductServiceFeeText(productData)}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

const containerStyles = (theme: WeaveTheme) => css`
  margin-right: 24px;
  margin-bottom: 24px;
  border-radius: 10px;
  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: 0 0 10px ${theme.colors.weaveBlue};
  }

  @media only screen and (max-width: 450px) {
    width: 100%;
    margin-right: 0;
  }
`;
const cardStyles = css`
  max-width: 100%;
  width: 274px;
  height: 70px;
  padding: 14px 16px;
  display: flex;
  margin: 0;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;

  @media only screen and (max-width: 450px) {
    width: 100%;
  }
`;
const activeCardStyles = (theme: WeaveTheme) => css`
  border: 2px solid ${theme.colors.weaveBlue};
`;
const titleAndIconContainerStyles = css`
  display: flex;
`;
const titleStyles = css`
  margin-bottom: 4px;
`;
const priceTextStyles = css`
  margin-bottom: 0;
`;
const imgContainerStyles = css`
  width: 64px;
`;
