import { applicationName } from '../../../components/outage-alerts/outage-alerts.types';
import firebase from '../../../config/outage-alerts-firebase-config';
import { AuthStorage } from '../auth/auth.types';

/**
 * @desc : Function is defined to prepare firestore query for fetching data
 * @returns : Promise
 */
export const getOutageAlertQuery = () => {
  return firebase
    .firestore()
    .collection('service_outages')
    .where('application', '==', applicationName.wam)
    .where('isOutageActive', '==', true)
    .where('isDeleted', '==', false);
};

/**
 * @desc : Function is defined to logout from firebase service
 */
export const firebaseLogout = () => {
  firebase
    .auth()
    .signOut()
    .then(
      () => {
        localStorage.removeItem(AuthStorage.firebase_token);
      },
      function (error) {
        console.error('Sign Out Error', error);
      }
    );
};
