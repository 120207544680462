import { createAction, handleActions } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { CustomAxios } from '../../axios';

export type RecallHistoryPayload = string;

export const recallHistory = createAction<RecallHistoryPayload>('RECALL_HISTORY');
export const recallHistorySuccess = createAction('RECALL_HISTORY_SUCCESS');
export const recallHistoryFailure = createAction<Error>('RECALL_HISTORY_FAILURE');
export const recallHistoryReset = createAction('RECALL_HISTORY_RESET');

export const handleRecallHistory = function* (action) {
  try {
    const params = action.payload;
    const response = yield call(CustomAxios.get, `/support/v1/recall${params}`);
    yield put(recallHistorySuccess(response.data.data));
  } catch (error: any) {
    yield put(recallHistoryFailure(error));
  }
};

export const recallHistorySaga = function* () {
  yield all([takeEvery(recallHistory.toString(), handleRecallHistory)]);
};

const defaultState = {
  recall: [],
};
export const recallHistoryReducer = handleActions(
  {
    [recallHistory.toString()]: (state) => Object.assign({}, state, { loading: true }),
    [recallHistorySuccess.toString()]: (state, action) =>
      Object.assign({}, state, { recall: action.payload, loading: false }),
    [recallHistoryFailure.toString()]: (state, action) =>
      Object.assign({}, state, { error: action.payload, loading: false }),
    [recallHistoryReset.toString()]: () => defaultState,
  },
  defaultState
);
