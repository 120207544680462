import { connect } from 'react-redux';
import { get, find, matchesProperty } from 'lodash';

import { PatientDetails } from './patient-details/patient-details.component';
import { patients } from '../../../redux/actions/patients/patients.action';
import { person, personReset } from '../../../redux/actions/patients/person.action';
import { getLocationFeatures } from '../../../redux/actions/customization/customization.action';
import { selectCurrentLocationId } from '../../../redux/actions/location/current-location';
import { Store } from '../../../redux/store/store.model';

export const PatientDetailsContainer = connect(
  (store: Store, ownProps: any) => ({
    currentLocation: selectCurrentLocationId(store),
    patientsList: store.patients.data,
    person: store.person.data,
    personLoading: store.person.loading,
    personID: ownProps.match.params.personID,
    eyewearReady: get(
      find(store.customization.features, matchesProperty('name', 'Eyewear Ready')),
      'state',
      'n/a'
    ),
    hasOrders: store.customization.loading,
    history: ownProps.history,
  }),

  {
    getPatients: patients,
    getPerson: person,
    personReset,
    getLocationFeatures,
  }
)(PatientDetails);
