export type FirebaseObject = {
  firebaseToken: string | null;
};

export type FirebaseTokenState = FirebaseObject;

export enum FirebaseActionTypes {
  GET_AND_AUTHENTICATE_FIREBASE_TOKEN = 'GET_AND_AUTHENTICATE_FIREBASE_TOKEN',
  SET_FIREBASE_TOKEN = 'SET_FIREBASE_TOKEN',
  LOGOUT_FIREBASE = 'LOGOUT_FIREBASE',
}

export type GetAndAuthenticateFirebaseTokenAction = {
  type: typeof FirebaseActionTypes.GET_AND_AUTHENTICATE_FIREBASE_TOKEN;
  payload: string | null;
};

export type SetFireBaseTokenAction = {
  type: typeof FirebaseActionTypes.SET_FIREBASE_TOKEN;
  payload: FirebaseObject;
};

export type LogoutFireBaseAction = {
  type: typeof FirebaseActionTypes.LOGOUT_FIREBASE;
  payload: string | null;
};
