import { call } from 'redux-saga/effects';
import { LegacySettings } from '../../../../components/settings/reviews/helpers';
import { CustomAxios, getResponseData } from '../../../axios';
import { FactorStatus, FactorTest } from '../handoff-hub.reducer';
import { ResolvedPromise } from '../../../store/store-utils';

export interface INotificationSettings {
  appointments: any[];
  birthdays: any[];
  recall: any[];
  reviews: any[];
  missed_calls: any[];
}

// checkReviewSolicitations
export const notificationSettingsErrorMessage = `Error retrieving notification settings. {{actual}}`;
export const reviewsEnabledSuccessMessage = `PASS: Reviews are set up and automated.`;
export const reviewsEnabledFailureMessage = `FAIL: Reviews are not set up.`;
export const reviewsEnabledOffMessage = `OFF: Automated solicitation is not turned on.`;
// checkMissedCallSMS
export const missedCallTextCriteria = `Missed call texts should be active`;

export const premiumFeaturesApi = {
  getNotificationSettings: (): Promise<INotificationSettings> =>
    CustomAxios.get('/support/v1/notifications/settings')
      .then(getResponseData)
      .then((data) => data.notificationSettings ?? {}),
  getReviewSettings: (): Promise<LegacySettings> =>
    CustomAxios.get('support/v1/reviews/settings')
      .then(getResponseData)
      .then((data) => data ?? {}),
};

export const checkReviewSolicitations = function* () {
  const tests: FactorTest[] = [];
  let factorStatus: FactorStatus = 'pass';
  const reviewSettings: ResolvedPromise<
    ReturnType<typeof premiumFeaturesApi.getReviewSettings>
  > = yield call(premiumFeaturesApi.getReviewSettings);
  if (!reviewSettings.ReviewSites.length) {
    // This location has no reviews
    factorStatus = 'fail';
    tests.push({
      status: 'fail',
      name: `Reviews should be set up and automated`,
      actual: 'not set up',
    });
  } else {
    if (!reviewSettings.AutoSend) {
      // Reviews are not automated for this location
      factorStatus = 'off';
      tests.push({
        status: 'off',
        name: `Reviews should be set up and automated`,
        actual: 'off',
      });
    } else {
      // reviews are set up and automated
      factorStatus = 'pass';
      tests.push({
        status: 'pass',
        name: `Reviews should be set up and automated`,
        actual: 'automated',
      });
    }
  }

  return { status: factorStatus, tests };
};

export const checkMissedCallSMS = function* () {
  const tests: FactorTest[] = [];
  let factorStatus: FactorStatus = 'pass';
  const notificationSettings: ResolvedPromise<
    ReturnType<typeof premiumFeaturesApi.getNotificationSettings>
  > = yield call(premiumFeaturesApi.getNotificationSettings);

  let missedCallTextsActive = false;
  if (notificationSettings.missed_calls) {
    for (const setting of notificationSettings.missed_calls) {
      if (setting.enabled) {
        missedCallTextsActive = true;
        break;
      }
    }
  }

  if (missedCallTextsActive) {
    factorStatus = 'pass';
    tests.push({ status: 'pass', name: missedCallTextCriteria, actual: 'active' });
  } else {
    factorStatus = 'off';
    tests.push({ status: 'off', name: missedCallTextCriteria, actual: 'off' });
  }

  return { status: factorStatus, tests };
};
