import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CustomAxios, getResponseData } from '../../redux/axios';
import { Page } from '../shared/page/page.component';
import { WeaveTheme } from '@weave/theme-original';
import {
  Heading,
  Text,
  SwitchField,
  useFormField,
  useControlledField,
  DropdownField,
} from '@weave/design-system';
import { TableStyles } from '../../styles/table-style';
import { OTHER, injectParams } from '../../constants/paths';
import { find, uniqBy } from 'lodash';
import { css } from '@emotion/core';
import { selectUser } from '../../redux/actions/users/users.selectors';
import { updateUserRoles } from '../../redux/actions/users';
import { UserModel } from '../../models/user.model';
import { getUser } from '../../redux/actions/users/users-list.action';
import {
  addDenyMobileAccessRole,
  hasMobileAccess,
  removeDenyMobileAccessRole,
} from './users.helpers';

interface IDeviceData {
  appVersion: string;
  osVersion: string;
  type: string;
  id?: string;
  name: string;
  employee: { firstName: string; lastName: string };
}

interface IErrorData {
  endpoint: string;
  errorCode: string;
  time: string;
  model: string;
}

const DeviceDropdown: React.FC<{
  devices: IDeviceData[];
  value: string;
  onChange: any;
}> = ({ devices, value, onChange }) => {
  const deviceField = useControlledField({
    type: 'dropdown',
    value,
    required: false,
    onChange: (value) => onChange(value),
  });

  return (
    <DropdownField
      {...deviceField}
      label="Select a device"
      name="device-name"
      css={css`
        max-width: 300px;
        margin-bottom: 40px;
      `}
    >
      {devices.map((item, index) => {
        return (
          <DropdownField.Option
            value={item.name}
            searchValue={item.name}
            key={item.name + index}
          >
            {item.name}
          </DropdownField.Option>
        );
      })}
    </DropdownField>
  );
};

export const UserMobileSupport: React.FC<
  RouteComponentProps<{
    id: string;
    userId: string;
  }>
> = (props) => {
  const dispatch = useDispatch();

  const { userId, id: locationId } = props.match.params;
  const user = useSelector(selectUser(userId));
  const userHasMobileAccess = hasMobileAccess(user?.Roles);

  const [deviceData, setDeviceData] = useState<IDeviceData[]>([]);
  const [errorData, setErrorData] = useState<IErrorData[]>([]);
  const [selectedDevice, setSelectedDevice] = useState('');

  const switchFieldProps = useFormField({ type: 'switch', value: userHasMobileAccess }, [
    userHasMobileAccess,
  ]);

  const toggleMobileAccess = (enabled: boolean) => {
    if (!user) return;

    const updatedUser: UserModel = {
      ...user,
      Roles: !enabled
        ? addDenyMobileAccessRole(user.Roles)
        : removeDenyMobileAccessRole(user.Roles),
    };
    dispatch(updateUserRoles(updatedUser));
  };

  useEffect(() => {
    if (userId && !user) {
      dispatch(getUser(userId));
    }
  }, []);

  useEffect(() => {
    if (switchFieldProps.value !== userHasMobileAccess) {
      toggleMobileAccess(switchFieldProps.value);
    }
  }, [switchFieldProps.value]);

  useEffect(() => {
    CustomAxios.get(`/mobile/v1/mobile-devices/${userId}`)
      .then<IDeviceData[]>(getResponseData)
      .then((data) => {
        if (!data.length) return;

        const filteredDeviceData = uniqBy(data, 'id');
        setDeviceData(filteredDeviceData);
        setSelectedDevice(filteredDeviceData[0].name);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (selectedDevice) {
      const currentDevice = find(deviceData, (item) => item.name === selectedDevice);

      if (!currentDevice) return;

      CustomAxios.get(`/bigquery/v1/deviceErrors/${currentDevice.id}`)
        .then<IErrorData[]>(getResponseData)
        .then((data) => {
          setErrorData(data);
        })
        .catch((err) => console.error(err));
    }
  }, [selectedDevice]);

  const userPageUrl = injectParams(OTHER.users, {
    id: locationId,
  });

  const breadcrumbs = [
    { url: userPageUrl, title: 'User' },
    { url: '', title: 'Mobile' },
  ];

  const firstName = user?.FirstName || deviceData[0]?.employee.firstName;
  const lastName = user?.LastName || deviceData[0]?.employee.lastName;
  const currentDevice = !selectedDevice
    ? []
    : deviceData.filter((item) => item.name === selectedDevice);
  const currentErrors = !selectedDevice
    ? []
    : errorData.filter((item) => item.model === selectedDevice);

  return (
    <Page title={`Mobile Support for ${firstName} ${lastName}`} breadcrumbs={breadcrumbs}>
      <SwitchField
        {...switchFieldProps}
        name="enable-mobile-access"
        css={css`
          align-items: flex-start;
          max-width: 300px;
        `}
        label={
          <Text
            css={(theme: WeaveTheme) => [
              css`
                margin-bottom: ${theme.spacing(1)};
              `,
            ]}
            weight="bold"
          >
            Enable Mobile App Access
          </Text>
        }
        labelPlacement="left"
      />
      <Heading
        level={2}
        css={css`
          margin-bottom: 24px;
        `}
      >
        Device
      </Heading>
      <DeviceDropdown
        devices={deviceData}
        value={selectedDevice}
        onChange={setSelectedDevice}
      />
      <ReactTable
        columns={[
          {
            Header: 'App (Android or iOS)',
            accessor: 'type',
            width: 186,
          },
          {
            Header: 'App Version',
            accessor: 'appVersion',
            width: 285,
          },
          {
            Header: 'OS Version',
            accessor: 'osVersion',
          },
        ]}
        data={currentDevice}
        pageSize={currentDevice.length || 3}
        showPagination={false}
        css={(theme: WeaveTheme) => [
          TableStyles,
          css`
            margin-bottom: ${theme.spacing(4)};
          `,
        ]}
      />
      <Heading level={2}>Errors</Heading>
      <ReactTable
        columns={[
          {
            Header: 'Type of Error',
            accessor: 'errorCode',
            width: 292,
          },
          {
            Header: 'Description',
            accessor: 'endpoint',
            width: 569,
          },
          {
            Header: 'Time of Error',
            accessor: 'time',
          },
        ]}
        data={currentErrors}
        pageSize={currentErrors.length || 3}
        showPagination={false}
        css={(theme: WeaveTheme) => [
          TableStyles,
          css`
            margin: ${theme.spacing(2)} 0;
          `,
        ]}
      />
    </Page>
  );
};
