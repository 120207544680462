import { handleActions } from 'redux-actions';
import {
  getWritebacks,
  getWritebacksSuccess,
  getWritebacksFailure,
  getSyncs,
  getSyncsSuccess,
  getSyncsFailure,
  getAutoSMSMessages,
  getAutoSMSMessagesSuccess,
  getAutoSMSMessagesFailure,
  getAutoEmailMessages,
  getAutoEmailMessagesSuccess,
  getAutoEmailMessagesFailure,
  Writebacks,
  EmailMessages,
  SMSMessages,
  Syncs,
} from './smart-alerts.action';

interface DefaultSmartAlertState {
  autoEmailMessages: EmailMessages[];
  autoSMSMessages: SMSMessages[];
  writebacks: Writebacks[];
  loading: boolean;
  syncs: Syncs[];
}

const defaultState = {
  autoEmailMessages: [],
  autoSMSMessages: [],
  writebacks: [],
  loading: false,
  syncs: [],
};

export const smartAlertsReducer = handleActions<DefaultSmartAlertState, any>(
  {
    [getWritebacks.toString()]: (state) => Object.assign({}, state, { loading: true }),
    [getWritebacksSuccess.toString()]: (state, action) => {
      return Object.assign({}, state, {
        writebacks: action.payload && action.payload.writebacks,
        loading: false,
      });
    },
    [getWritebacksFailure.toString()]: (state) =>
      Object.assign({}, state, {
        writebacks: [],
        loading: false,
      }),

    [getSyncs.toString()]: (state) => Object.assign({}, state, { loading: true }),
    [getSyncsSuccess.toString()]: (state, action) => {
      return Object.assign({}, state, {
        syncs: action.payload && action.payload.syncs,
        loading: false,
      });
    },
    [getSyncsFailure.toString()]: (state) =>
      Object.assign({}, state, {
        syncs: [],
        loading: false,
      }),

    [getAutoSMSMessages.toString()]: (state) =>
      Object.assign({}, state, { loading: true }),
    [getAutoSMSMessagesSuccess.toString()]: (state, action) => {
      return Object.assign({}, state, {
        autoSMSMessages: action.payload && action.payload.autoSMSMessages,
        loading: false,
      });
    },
    [getAutoSMSMessagesFailure.toString()]: (state) =>
      Object.assign({}, state, {
        autoSMSMessages: [],
        loading: false,
      }),

    [getAutoEmailMessages.toString()]: (state) =>
      Object.assign({}, state, { loading: true }),
    [getAutoEmailMessagesSuccess.toString()]: (state, action) => {
      return Object.assign({}, state, {
        autoEmailMessages: action.payload && action.payload.autoEmailMessages,
        loading: false,
      });
    },
    [getAutoEmailMessagesFailure.toString()]: (state) =>
      Object.assign({}, state, {
        autoEmailMessages: [],
        loading: false,
      }),
  },
  defaultState
);

export default smartAlertsReducer;
