import { createAction } from 'redux-actions';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { CustomAxios } from '../../axios';
import { showError } from '@weave/alert-system';
import { Endpoint, getEndpoint } from './endpoints';
import { handleGetFeatureFlags } from '../feature-flags/feature-flags.action';
import {
  selectFeatureFlagLoaded,
  selectHasFeatureFlagEnabled,
} from '../feature-flags/feature-flags.reducer';

export const getWritebacks = createAction('GET_ALERT_WRITEBACKS');
export const getWritebacksSuccess = createAction<Writebacks>(
  'GET_ALERT_WRITEBACKS_SUCCESS'
);
export const getWritebacksFailure = createAction('GET_ALERT_WRITEBACKS_FAILURE');

export const getSyncs = createAction('GET_ALERT_SYNCS');
export const getSyncsSuccess = createAction<Syncs>('GET_ALERT_SYNCS_SUCCESS');
export const getSyncsFailure = createAction('GET_ALERT_SYNCS_FAILURE');

export const getAutoSMSMessages = createAction('GET_ALERT_AUTO_SMS_MESSAGES');
export const getAutoSMSMessagesSuccess = createAction<SMSMessages>(
  'GET_ALERT_AUTO_SMS_MESSAGES_SUCCESS'
);
export const getAutoSMSMessagesFailure = createAction(
  'GET_ALERT_AUTO_SMS_MESSAGES_FAILURE'
);

export const getAutoEmailMessages = createAction('GET_ALERT_AUTO_EMAIL_MESSAGES');
export const getAutoEmailMessagesSuccess = createAction<EmailMessages>(
  'GET_ALERT_AUTO_EMAIL_MESSAGES_SUCCESS'
);
export const getAutoEmailMessagesFailure = createAction(
  'GET_ALERT_AUTO_EMAIL_MESSAGES_FAILURE'
);

export interface Writebacks {
  writeback_status_description: string;
  writeback_modified_at: string;
  source_type: string;
  source_id: string;
  id: string;
}

export interface EmailMessages {
  CreatedAt: string;
  Status: string;
}

export interface SMSMessages {
  status: string;
  statusDetails: string;
  createdAt: string;
}

export interface Syncs {
  Reasons: string[];
}

export const handleGetWritebacks = function* (action) {
  try {
    const { locationID, searchParams } = action.payload;
    const endpointWithLocation = getEndpoint(
      Endpoint.writebacks,
      locationID,
      searchParams.params['start'],
      searchParams.params['end'],
      searchParams.params['limit'],
      searchParams.params['skip'],
      searchParams.params['order']
    );

    const response = yield call(CustomAxios.get, endpointWithLocation);
    let writebacks = response.data.data || [];

    const failedWritebacks: any = [];
    const writebacksWithinDateRange: any = [];

    for (let index = 0; index < writebacks.length; index++) {
      if (writebacks[index]['writeback_status'] !== 'Completed') {
        failedWritebacks.push(writebacks[index]);
      }
    }

    // Filter out any failed writebacks that fall outside the date range
    for (let index = 0; index < failedWritebacks.length; index++) {
      if (
        Date.parse(failedWritebacks[index]['writeback_modified_at']) >
        Date.parse(searchParams.params['start'])
      ) {
        writebacksWithinDateRange.push(failedWritebacks[index]);
      }
    }

    writebacks = writebacksWithinDateRange;
    yield put(getWritebacksSuccess({ writebacks } as unknown as Writebacks));
  } catch (error: any) {
    yield put(getWritebacksFailure(error));
    yield put(showError('Failed to load writebacks list'));
  }
};

export const handleGetSyncs = function* (action) {
  try {
    const { searchParams } = action.payload;
    const response = yield call(CustomAxios.get, getEndpoint(Endpoint.syncs));
    let syncs = response.data.data || [];
    const failedSyncs: any = [];
    const syncsWithinDateRange: any = [];

    for (let index = 0; index < syncs['SyncApps'].length; index++) {
      if (syncs['SyncApps'][index]['SyncAppHealth'] !== 'Healthy') {
        failedSyncs.push(syncs['SyncApps'][index]);
        continue;
      }

      if (syncs['SyncApps'][index]['Reasons'] === null) {
        continue;
      }

      // Find the last sync time of those that are healthy
      if (syncs['SyncApps'][index]['Reasons'][0].includes('synced: ')) {
        try {
          const lastSyncMatch =
            syncs['SyncApps'][index]['Reasons'][0].split('synced:')[1];
          if (
            lastSyncMatch != null &&
            Date.parse(lastSyncMatch) < Date.parse(searchParams.params['start'])
          ) {
            syncsWithinDateRange.push(syncs['SyncApps'][index]);
          }
        } catch {
          console.error('Invalid sync time format');
        }
      }
    }

    for (let failedIndex = 0; failedIndex < failedSyncs.length; failedIndex++) {
      if (failedSyncs[failedIndex]['Reasons'] === null) {
        continue;
      }
      try {
        // Capture if the reported time is out of tolerance
        if (failedSyncs[failedIndex]['Reasons'][2].includes('Reported at:')) {
          // Pull the date of the "Reported at: " within the Reasons array
          const reportTime = failedSyncs[failedIndex]['Reasons'][2]
            .split('Reported at:')[1]
            .split('UTC')[0];
          if (Date.parse(reportTime) > Date.parse(searchParams.params['start'])) {
            syncsWithinDateRange.push(failedSyncs[failedIndex]);
          }
        } else {
          console.log('No report time');
        }
      } catch {
        console.error('Failed to get report time.');
      }
    }

    syncs = syncsWithinDateRange;
    yield put(getSyncsSuccess({ syncs } as unknown as Syncs));
  } catch (error: any) {
    yield put(getSyncsFailure(error));
    yield put(showError('Failed to load the list of sync app failures'));
  }
};

export const handleGetAutoSMSMessages = function* (action) {
  try {
    const { locationID, searchParams } = action.payload;

    if (locationID) {
      const commxMigrationFlagLoaded = yield select((store) =>
        selectFeatureFlagLoaded(store, 'commx-migration')
      );
      if (!commxMigrationFlagLoaded) {
        yield handleGetFeatureFlags({
          type: 'GET_FEATURE_FLAGS',
          payload: locationID,
        });
      }
      const commxMigrationFlag = yield select((store) =>
        selectHasFeatureFlagEnabled(store, 'commx-migration')
      );
      const endpointWithLocation = yield getEndpoint(
        commxMigrationFlag ? Endpoint.smsHistory : Endpoint.oldSmsHistory,
        locationID,
        searchParams.params['start'],
        searchParams.params['end'],
        200,
        searchParams.params['skip'],
        searchParams.params['order']
      );

      const responses = yield call(CustomAxios.get, endpointWithLocation);
      let autoSMSMessages = responses.data.smsList.map((item) => ({ ...item })) || [];

      const failedSMS: any = [];

      for (let index = 0; index < autoSMSMessages.length; index++) {
        const status = autoSMSMessages[index]['status'];
        if (status === 'STATUS_ERROR' || status === 'STATUS_NOT_SENT') {
          failedSMS.push(autoSMSMessages[index]);
        }
      }

      autoSMSMessages = failedSMS;
      yield put(getAutoSMSMessagesSuccess({ autoSMSMessages } as unknown as SMSMessages));
    }
  } catch (error: any) {
    yield put(getAutoSMSMessagesFailure(error));
    yield put(showError('Failed to load the list of SMS auto-messaging failures'));
  }
};

export const handleGetAutoEmailMessages = function* (action) {
  try {
    const { locationID, searchParams } = action.payload;
    if (locationID === '') return;
    const endpointWithLocation = getEndpoint(
      Endpoint.emailHistory,
      locationID,
      searchParams.params['start'],
      searchParams.params['end']
    );
    const response = yield call(CustomAxios.get, endpointWithLocation);
    let autoEmailMessages = response.data.data || [];
    const failedEmail: any = [];

    for (let index = 0; index < autoEmailMessages.length; index++) {
      if (autoEmailMessages[index]['Status'].includes('failed')) {
        failedEmail.push(autoEmailMessages[index]);
      }
    }

    autoEmailMessages = failedEmail;
    yield put(
      getAutoEmailMessagesSuccess({ autoEmailMessages } as unknown as EmailMessages)
    );
  } catch (error: any) {
    yield put(getAutoEmailMessagesFailure(error));
    yield put(showError('Failed to load the list of Email auto-messaging failures'));
  }
};

export const smartAlertSaga = function* () {
  yield all([takeEvery(getWritebacks.toString(), handleGetWritebacks)]);
  yield all([takeEvery(getSyncs.toString(), handleGetSyncs)]);
  yield all([takeEvery(getAutoSMSMessages.toString(), handleGetAutoSMSMessages)]);
  yield all([takeEvery(getAutoEmailMessages.toString(), handleGetAutoEmailMessages)]);
};
