import { createAction, handleActions, Action } from 'redux-actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { CustomAxios } from '../../axios';

export interface DayOfWeekRemindersModel {
  Sunday: boolean;
  Monday: boolean;
  Tuesday: boolean;
  Wednesday: boolean;
  Thursday: boolean;
  Friday: boolean;
  Saturday: boolean;
}

export type DayOfWeekRemindersResultPayload = DayOfWeekRemindersModel;

export const getDayOfWeekReminders = createAction('DAY_OF_WEEK_REMINDERS');
export const getDayOfWeekRemindersSuccess = createAction<DayOfWeekRemindersResultPayload>(
  'DAY_OF_WEEK_REMINDERS_SUCCESS'
);
export const getDayOfWeekRemindersFailure = createAction<Error>(
  'DAY_OF_WEEK_REMINDERS_FAILURE'
);
export const dayOfWeekRemindersReset = createAction('DAY_OF_WEEK_REMINDERS_RESET');

export const updateDayOfWeekReminders = createAction('UPDATE_DAY_OF_WEEK_REMINDERS');
export const updateDayOfWeekRemindersSuccess = createAction(
  'UPDATE_DAY_OF_WEEK_REMINDERS_SUCCESS'
);
export const updateDayOfWeekRemindersFailure = createAction(
  'UPDATE_DAY_OF_WEEK_REMINDERS_FAILURE'
);

export const dayOfWeekURL = '/support/v1/auto/dayofweek';

export const handleUpdateDayOfWeekReminders = function* (action) {
  try {
    yield call(CustomAxios.put, dayOfWeekURL, action.payload);
    yield put(updateDayOfWeekRemindersSuccess());
  } catch {
    yield put(updateDayOfWeekRemindersFailure());
  }
};

export const handleGetDayOfWeekReminders = function* () {
  try {
    const response = yield call(CustomAxios.get, dayOfWeekURL);
    yield put(getDayOfWeekRemindersSuccess(response.data.data));
  } catch (error: any) {
    yield put(getDayOfWeekRemindersFailure(error));
  }
};

export const dayOfWeekRemindersSaga = function* () {
  yield all([
    takeLatest(getDayOfWeekReminders.toString(), handleGetDayOfWeekReminders),
    takeLatest(updateDayOfWeekReminders.toString(), handleUpdateDayOfWeekReminders),
  ]);
};

export interface DayOfWeekRemindersStateModel {
  loading: boolean;
  settings: DayOfWeekRemindersModel | null;
  error?: Error;
}

const defaultState: DayOfWeekRemindersStateModel = {
  loading: false,
  settings: null,
};

export const dayOfWeekRemindersReducer = handleActions<DayOfWeekRemindersStateModel, any>(
  {
    [getDayOfWeekReminders.toString()]: (state) => ({ ...state, loading: true }),
    [getDayOfWeekRemindersSuccess.toString()]: (
      state,
      action: Action<DayOfWeekRemindersModel>
    ) => ({
      ...state,
      settings: action.payload || null,
      loading: false,
    }),
    [getDayOfWeekRemindersFailure.toString()]: (state, action: Action<Error>) => ({
      ...state,
      settings: null,
      error: action.payload,
      loading: false,
    }),
    [dayOfWeekRemindersReset.toString()]: () => defaultState,

    [updateDayOfWeekReminders.toString()]: (state) => ({ ...state, loading: true }),
    [updateDayOfWeekRemindersSuccess.toString()]: (state) => ({
      ...state,
      loading: false,
    }),
    [updateDayOfWeekRemindersFailure.toString()]: (state) => ({
      ...state,
      loading: false,
    }),
  },
  defaultState
);
