import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';

export const settingContainer = css`
  display: flex;
  justify-content: space-between;
  margin-top: ${theme.spacing(2)};
`;

export const selectedTab = css`
  background-color: ${theme.colors.weaveBlue};
  color: ${theme.colors.white};
`;

export const langContainer = css`
  width: 25%;
`;

export const langOpts = css`
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.weaveBlue};
  border-radius: ${theme.borderRadius.small};
  color: ${theme.colors.weaveBlue};
  cursor: pointer;
  margin: ${theme.spacing(0.5)};
  padding: ${theme.spacing(0.5)};
  text-align: center;
  width: 100%;
`;

export const templateContainer = css`
  width: 70%;
`;

export const templateStyle = css`
  user-select: none;
`;

export const noChanges = css`
  font-weight: bold;
  margin-left: ${theme.spacing(2)};
`;

export const removed = css`
  background-color: rgba(255, 0, 0, 0.1);
`;
export const added = css`
  background-color: rgba(0, 255, 0, 0.1);
`;
