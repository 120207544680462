import React, { useMemo } from 'react';
import moment from 'moment';
import { css } from '@emotion/core';
import { Loader, Card, Dot } from '@weave/platform-ui-components';
import { NotificationsSettings } from '../../../../redux/actions/notifications-settings/notification-settings.types';
import { SmsModel } from '../../../../models/sms-history.model';
import { NotificationQueueModel } from '../../../../models/notification-queue.model';
import { MerchantModel } from '../../../../models/merchant.model';
import { AutoReminderDetails } from './auto-reminder-details';
import { LegacySettings } from '../../../settings/reviews/helpers';

interface DeliverabilityCounts {
  errorCount: number;
  successCount: number;
  errors: [string, number][];
}

export interface Deliverability {
  today: DeliverabilityCounts;
  yesterday: DeliverabilityCounts;
  overall: DeliverabilityCounts;
}

interface Props {
  notificationSettings: NotificationsSettings;
  smsHistory: SmsModel[];
  reviewSettings: LegacySettings;
  merchant?: MerchantModel;
  notificationQueueData: NotificationQueueModel[];
  loading: boolean;
}

const tableConfig = [
  {
    colGroupName: undefined,
    color: '#fff',
    columns: [{ name: 'Name' }],
  },
  {
    colGroupName: 'Deliverability - Today',
    color: '#d7d9f2',
    columns: [{ name: 'Error' }, { name: 'Success' }, { name: 'Total' }],
  },
  {
    colGroupName: 'Deliverability - Yesterday',
    color: '#ffe28e',
    columns: [{ name: 'Error' }, { name: 'Success' }, { name: 'Total' }],
  },
  {
    colGroupName: 'Deliverability - 2 Week',
    color: '#f5aca5',
    columns: [
      { name: 'Error' },
      { name: 'Success' },
      { name: 'Total' },
      { name: 'Daily Avg' },
    ],
  },
  {
    colGroupName: 'Queued Today',
    color: '#d5f1e4',
    columns: [{ name: 'Not Sent' }, { name: 'Sent' }, { name: 'Total' }],
  },
];

export const totalColumns = tableConfig.reduce(
  (acc, curr) => (acc += curr.columns.length),
  0
);

export const AutoMessagingModule = (props: Props) => {
  const { smsHistory, notificationSettings, reviewSettings, notificationQueueData } =
    props;
  const { appointments, orders, recall } = notificationSettings;

  const remindersSortFunction = (setting1, setting2) =>
    setting1.name.toLowerCase() < setting2.name.toLowerCase()
      ? -1
      : setting1.name.toLowerCase() > setting2.name.toLowerCase()
      ? 1
      : 0;

  // Get all appointment, recall and order reminders that are turned on.
  const enabledAppointmentSettings = useMemo(
    () => appointments?.filter((setting) => setting.enabled).sort(remindersSortFunction),
    [appointments]
  );
  const enabledOrderSettings = useMemo(
    () => orders?.filter((setting) => setting.enabled).sort(remindersSortFunction),
    [orders]
  );
  const enabledRecallSettings = useMemo(
    () => recall?.filter((setting) => setting.enabled).sort(remindersSortFunction),
    [recall]
  );

  const smsGroupedByAutoReminderId = useMemo(() => {
    return smsHistory.reduce((acc, sms) => {
      const { autogeneratedBy } = sms;

      if (autogeneratedBy) {
        return acc[autogeneratedBy]
          ? { ...acc, [autogeneratedBy]: [...acc[autogeneratedBy], sms] }
          : { ...acc, [autogeneratedBy]: [sms] };
      }

      return acc;
    }, {});
  }, [smsHistory]);

  const reviewAutoMessages = useMemo(
    () =>
      smsGroupedByAutoReminderId['00000000-0000-0000-0000-000000000000']?.filter(
        (review) => review.type === 'review'
      ) ?? [],
    [smsGroupedByAutoReminderId]
  );

  const getDailyAverage = (autoMessages: SmsModel[]): string => {
    if (!autoMessages) {
      return '0';
    }

    const twoWeekDailyAvg = autoMessages.length / 14;

    if (twoWeekDailyAvg < 1 && twoWeekDailyAvg > 0) {
      return 'less than 1';
    }

    return twoWeekDailyAvg.toFixed(1);
  };

  const getDeliverability = (autoMessages: SmsModel[]) => {
    const deliverability: Deliverability = {
      overall: {
        errorCount: 0,
        successCount: 0,
        errors: [],
      },
      yesterday: {
        errorCount: 0,
        successCount: 0,
        errors: [],
      },
      today: {
        errorCount: 0,
        successCount: 0,
        errors: [],
      },
    };

    if (!autoMessages) {
      return deliverability;
    }

    const errorMessageCountOverall: { [key: string]: number } = {};
    const errorMessageCountYesterday: { [key: string]: number } = {};
    const errorMessageCountToday: { [key: string]: number } = {};

    autoMessages.forEach((sms) => {
      const isToday = sms.createdAt.slice(0, 10) === moment().format('YYYY-MM-DD');
      const isYesterday =
        sms.createdAt.slice(0, 10) === moment().subtract(1, 'days').format('YYYY-MM-DD');

      if (!(sms.status === 'error')) {
        if (isToday) {
          deliverability.today.successCount++;
        } else if (isYesterday) {
          deliverability.yesterday.successCount++;
        }

        deliverability.overall.successCount++;

        return;
      }

      deliverability.overall.errorCount++;
      const errorMessage = sms.statusDetails ? sms.statusDetails : 'unknown';
      errorMessageCountOverall[errorMessage]
        ? errorMessageCountOverall[errorMessage]++
        : (errorMessageCountOverall[errorMessage] = 1);

      if (isToday) {
        deliverability.today.errorCount++;
        errorMessageCountToday[errorMessage]
          ? errorMessageCountToday[errorMessage]++
          : (errorMessageCountToday[errorMessage] = 1);
      } else if (isYesterday) {
        deliverability.yesterday.errorCount++;
        errorMessageCountYesterday[errorMessage]
          ? errorMessageCountYesterday[errorMessage]++
          : (errorMessageCountYesterday[errorMessage] = 1);
      }
    });

    deliverability.overall.errors = Object.entries(errorMessageCountOverall)
      .map((error) => error)
      .sort((err1, err2) => err2[1] - err1[1]);

    deliverability.today.errors = Object.entries(errorMessageCountToday)
      .map((error) => error)
      .sort((err1, err2) => err2[1] - err1[1]);

    deliverability.yesterday.errors = Object.entries(errorMessageCountYesterday)
      .map((error) => error)
      .sort((err1, err2) => err2[1] - err1[1]);

    return deliverability;
  };

  const getNumberOfMessagesQueuedForToday = (
    id: string
  ): { sent: number; notSent: number; total: number } => {
    const queueData = notificationQueueData;

    const queuedForToday = queueData.filter(
      (data) =>
        data.deliver_at.slice(0, 10) === moment().format('YYYY-MM-DD') &&
        data.notification_setting_id === id
    );

    const queuedNotSent = queuedForToday.filter((data) => !data.delivered_at);

    return {
      total: queuedForToday.length,
      notSent: queuedNotSent.length,
      sent: queuedForToday.length - queuedNotSent.length,
    };
  };

  return (
    <Card css={cardStyles}>
      <h1>Auto Messaging</h1>
      {props.loading && <Loader />}
      <hr />
      <div css={tableWrapperStyles}>
        <table>
          {tableConfig.map((colGroup, index) => (
            <colgroup
              key={index}
              span={colGroup.columns.length}
              css={css`
                background-color: ${colGroup.color};
              `}
            />
          ))}
          <tbody>
            <tr>
              {tableConfig.map((colGroup, index) => (
                <th
                  key={index}
                  colSpan={colGroup.columns.length}
                  scope="colgroup"
                  css={css`
                    color: ${colGroup.color};
                    ${colGroupHeader}
                  `}
                >
                  {colGroup.colGroupName}
                </th>
              ))}
            </tr>

            <tr>
              {tableConfig.map((colGroup, i) => (
                <React.Fragment key={i}>
                  {colGroup.columns.map((col, j) => (
                    <th
                      key={i + j}
                      scope="col"
                      css={css`
                        color: ${colGroup.color};
                        ${colHeader}
                      `}
                    >
                      {col.name}
                    </th>
                  ))}
                </React.Fragment>
              ))}
            </tr>

            <tr css={reminderTypeRow}>
              <td colSpan={totalColumns}>
                <h3 css={reminderTypeHeader}>Appointment</h3>
              </td>
            </tr>
            {enabledAppointmentSettings?.map((setting) => (
              <AutoReminderDetails
                key={setting.id}
                reminder={setting}
                autoMessages={smsGroupedByAutoReminderId[setting.id] ?? []}
                getDailyAverage={getDailyAverage}
                getDeliverability={getDeliverability}
                getNumberOfMessagesQueuedForToday={getNumberOfMessagesQueuedForToday}
              />
            ))}
            {!enabledAppointmentSettings?.length && (
              <tr>
                <td>No reminders enabled.</td>
              </tr>
            )}

            <tr css={reminderTypeRow}>
              <td colSpan={totalColumns}>
                <h3 css={reminderTypeHeader}>Order</h3>
              </td>
            </tr>
            {enabledOrderSettings?.map((setting) => (
              <AutoReminderDetails
                key={setting.id}
                reminder={setting}
                autoMessages={smsGroupedByAutoReminderId[setting.id] ?? []}
                getDailyAverage={getDailyAverage}
                getDeliverability={getDeliverability}
                getNumberOfMessagesQueuedForToday={getNumberOfMessagesQueuedForToday}
              />
            ))}
            {!enabledOrderSettings?.length && (
              <tr>
                <td>No reminders enabled.</td>
              </tr>
            )}

            <tr css={reminderTypeRow}>
              <td colSpan={totalColumns}>
                <h3 css={reminderTypeHeader}>Recall</h3>
              </td>
            </tr>
            {enabledRecallSettings?.map((setting) => (
              <AutoReminderDetails
                key={setting.id}
                reminder={setting}
                autoMessages={smsGroupedByAutoReminderId[setting.id] ?? []}
                getDailyAverage={getDailyAverage}
                getDeliverability={getDeliverability}
                getNumberOfMessagesQueuedForToday={getNumberOfMessagesQueuedForToday}
              />
            ))}
            {!enabledRecallSettings?.length && (
              <tr>
                <td>No reminders enabled.</td>
              </tr>
            )}

            <tr css={reminderTypeRow}>
              <td colSpan={totalColumns}>
                <h3 css={reminderTypeHeader}>Review</h3>
              </td>
            </tr>
            {reviewSettings.AutoSend && (
              <AutoReminderDetails
                name={'Reviews'}
                autoMessages={reviewAutoMessages}
                getDailyAverage={getDailyAverage}
                getDeliverability={getDeliverability}
                getNumberOfMessagesQueuedForToday={getNumberOfMessagesQueuedForToday}
                dropdownComponent={
                  <>
                    <br />
                    <h3>Review Sites</h3>
                    {reviewSettings.ReviewSites?.map((site) => (
                      <div key={site.Site}>
                        <strong>{site.Site}</strong> : {site.SiteURL}
                      </div>
                    ))}
                  </>
                }
              />
            )}
            {!reviewSettings.AutoSend && (
              <tr>
                <td>Reviews are not enabled</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <hr />

      <h3 css={reminderTypeHeader}>Payments</h3>
      <div css={paymentsStatusDiv}>
        <Dot color={!props.merchant ? 'red' : 'green'} />
        <div>Payments is {!props.merchant ? 'not ' : ''} set up</div>
      </div>
    </Card>
  );
};

const cardStyles = css`
  height: 500px;
  min-width: 500px;
  width: 100%;
  letter-spacing: 0.01071em;
  color: #454d52;
`;
const tableWrapperStyles = css`
  border: 1px solid #ccc;
  height: 350px;
  width: 100%;
  overflow-y: auto;
  & table {
    width: 100%;
  }
  & table tbody tr th {
    position: sticky;
    top: 0px;
    background-color: #6c757e;
  }
`;
const colGroupHeader = css`
  text-align: center;
  padding: 0px 32px;
`;
const colHeader = css`
  padding: 8px;
  text-align: center;
`;
const reminderTypeRow = css`
  background-color: #f5f2f2;
`;
const reminderTypeHeader = css`
  font-weight: bold;
  color: #6c757e;
`;
const paymentsStatusDiv = css`
  display: flex;
  align-items: center;
`;
