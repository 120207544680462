import React from 'react';
import { Modal, Text } from '@weave/design-system';
import { noop } from 'lodash';
import { Loader } from '../../shared/loader/loader.component';
import { portOrderStyles } from '../port-order/port-order.styles';

interface Props {
  showLoading: boolean;
  message: string;
}

export const LoaderModal = ({ showLoading, message }: Props) => {
  return (
    <Modal onClose={noop} show={showLoading} css={portOrderStyles.divWidth}>
      <div>
        <div css={portOrderStyles.loading}>
          <Loader size="large" />
        </div>
        <Text css={portOrderStyles.modalStyle}>{message}</Text>
      </div>
    </Modal>
  );
};
