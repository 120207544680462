export const allCapsAlpha = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'];
export const allLowerAlpha = [...'abcdefghijklmnopqrstuvwxyz'];
export const allNumbers = [...'0123456789'];

const defaultBase = [...allCapsAlpha, ...allNumbers, ...allLowerAlpha];

export const randomStringGenerator = ({
  base,
  length,
}: {
  base?: string[];
  length: number;
}) =>
  [...Array(length)]
    .map((i) => (base || defaultBase)[(Math.random() * (base || defaultBase).length) | 0])
    .join('');
