import { all, call, cancelled, put, take, takeLatest } from 'redux-saga/effects';
import { createRequestSaga } from '@weave/alert-system';
import { eventChannel } from 'redux-saga';
import { getOutageAlertQuery } from './outage-alerts.api';
import {
  GetActiveOutageAlertsAction,
  OutageAlertActionTypes,
} from './outage-alerts.types';
import { setActiveOutageAlerts } from './outage-alerts.action';
import { OutageAlert } from '../../../components/outage-alerts/outage-alerts.types';
import { AuthStorage } from '../auth/auth.types';

function subscribeToOutageAlerts() {
  return eventChannel((emmiter: any) => {
    const query = getOutageAlertQuery().onSnapshot(
      { includeMetadataChanges: true },
      (querySnapshot: any) => {
        const outageAlerts: any[] = [];
        querySnapshot.forEach((doc) => {
          outageAlerts.push({
            ...doc.data(),
            docId: doc.id,
          });
        });
        if (
          querySnapshot.length !== 0 &&
          localStorage.getItem(AuthStorage.firebase_token)
        ) {
          emmiter(outageAlerts);
        }
      },
      (error) => {
        emmiter(
          error.code === 'permission-denied'
            ? { error: 'Unauthorized to Access Alerts' }
            : { error: 'Something went wrong while Accessing Alerts' }
        );
      }
    );
    return () => query();
  });
}

//saga
export const fetchOutageAlerts = createRequestSaga<GetActiveOutageAlertsAction>({
  key: OutageAlertActionTypes.GET_ACTIVE_OUTAGE_ALERTS,
  displayErrors: true,
  onError: (error) => error.message,
  saga: function* () {
    subscribeToOutageAlerts().close();
    const channel = yield call(subscribeToOutageAlerts);
    try {
      while (true) {
        const outageAlerts = yield take(channel);
        if (outageAlerts.error) {
          throw new Error(outageAlerts.error);
        } else {
          const updatedOutageAlerts: OutageAlert[] = outageAlerts.map((ele) => {
            ele.isDismissed = false;
            return ele;
          });
          yield put(setActiveOutageAlerts(updatedOutageAlerts));
        }
      }
    } catch (error: any) {
      //This error only need to be showcased on login
      console.error(error);
      if (localStorage.getItem(AuthStorage.firebase_token)) {
        throw new Error('Opps! Something Went Wrong.');
      }
    } finally {
      if (yield cancelled()) {
        subscribeToOutageAlerts().close();
      }
    }
  },
});

export const outageAlertsSaga = function* () {
  yield all([
    takeLatest(OutageAlertActionTypes.GET_ACTIVE_OUTAGE_ALERTS, fetchOutageAlerts),
  ]);
};
