import React, { useEffect, useState } from 'react';
import { differenceInMinutes, format, parseISO } from 'date-fns';
import ReactTable from 'react-table';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import {
  ContentLoader,
  EditIcon,
  IconButton,
  InfoIcon,
  Text,
  useModalControl,
} from '@weave/design-system';
import { TableStyles } from '../../../../styles/table-style';
import { AppointmentRequestInfoModal } from './appointment-request-info-modal.component';
import { AppointmentRequestEditForm } from './appointment-request-edit-form.component';
import { OnlineSchedulingTabHeader } from '../online-scheduling-tab-header.component';
import { reactTableStyle } from '../../../shared/server-data-table/react-table.style';
import { CopyCredential } from '../../../location-sidebar-container/sidebar-sync-app/sync-app-create/copy-credential.component';
import { fnsDateTimeFormat } from '../../../../helpers/utils';
import { REACT_APP_API_URL } from '../../../../config/app';
import {
  schedulingQuery,
  SchedulingTypes,
} from '../../../../apis/protos/scheduling/scheduling.proto-api';

export const today = new Date();

export const appointmentLogStyle = css`
  & .rt-td,
  & .rt-th {
    align-content: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: auto;
  }

  .-sort-desc,
  .-sort-asc {
    &:before {
      padding: ${theme.spacing(0.25, 1, 0, 0)};
      text-align: right;
      float: left;
    }
  }

  .-sort-desc {
    &:before {
      content: '▼';
    }
  }

  .-sort-asc {
    &:before {
      content: '▲';
    }
  }

  label: appointmentLogStyle;
`;

const pillStyle = (color: string) => css`
  background-color: ${color};
  border-radius: ${theme.borderRadius.medium};
  padding: ${theme.spacing(0, 1)};
`;

const columns = [
  {
    Header: 'Request ID',
    id: 'requestID',
    accessor: (rowData) => {
      return (
        <CopyCredential
          title={rowData?.ID?.uuid.slice(0, 6) || 'Not Found'}
          value={rowData?.ID?.uuid || ''}
          valueHidden={true}
        />
      );
    },
    width: 130,
  },
  {
    Header: 'Status',
    id: 'status',
    accessor: (rowData) => rowData.Status,
    Cell: (props) => {
      const status = props.value;
      const statusColor = () => {
        switch (status) {
          case 'UnknownStatus':
            return theme.colors.weaveBlue;
          case 'Pending':
            return theme.colors.warning;
          case 'Accepted':
            return theme.colors.success;
          case 'Rejected':
            return theme.colors.error;
          default:
            return theme.colors.gray300;
        }
      };
      return <Text css={pillStyle(statusColor())}>{status}</Text>;
    },
    width: 120,
  },
  {
    Header: 'Selected Appointment',
    id: 'selectedAppointment',
    accessor: (rowData) => rowData.DateTime,
    Cell: (props) => <Text>{format(parseISO(props.value), fnsDateTimeFormat)}</Text>,
    width: 220,
  },
  {
    Header: 'Requested Appointment(s)',
    id: 'requestedAppointments',
    accessor: (rowData) => rowData.RequestedOpenings,
    Cell: (props) => {
      return props.value?.length > 0 ? (
        props.value.map((request, i) => {
          const formatedRequest = format(parseISO(request.DateTime), fnsDateTimeFormat);
          const difference = differenceInMinutes(parseISO(request.DateTime), today);
          return (
            <Text
              key={`request-${i}`}
              css={
                difference > 0
                  ? pillStyle(theme.colors.gray300)
                  : pillStyle(theme.colors.error)
              }
            >
              {formatedRequest}
            </Text>
          );
        })
      ) : (
        <Text>None Selected</Text>
      );
    },
    width: 220,
  },
  {
    Header: 'Appointment Type',
    id: 'appointmentType',
    accessor: (rowData) => rowData.AppointmentType,
    width: 180,
  },
  {
    Header: 'Duration (Min)',
    id: 'duration',
    accessor: (rowData) => parseInt(rowData.Duration) / 60,
    width: 120,
  },
  {
    Header: 'Reviewed By',
    id: 'reviewed_by',
    accessor: (rowData) => {
      return rowData.ReviewedBy ? (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-contents: start;
            align-items: start;
          `}
        >
          <CopyCredential
            title={rowData.ReviewedBy.uuid}
            value={rowData.ReviewedBy.uuid}
            valueHidden={true}
          />
          <Text textAlign="left" size="small">
            ({format(parseISO(rowData.ReviewedAt), fnsDateTimeFormat)})
          </Text>
        </div>
      ) : (
        <Text css={pillStyle(theme.colors.warning)}>Not Reviewed</Text>
      );
    },
  },
];

const useScheduleRequests = schedulingQuery('/schedule/v1/requests');

interface AppointmentRequestLogProps {
  locationId: string;
}

export const AppointmentRequestLog = ({ locationId }: AppointmentRequestLogProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedAppointment, setSelectedAppointment] = useState<
    Partial<SchedulingTypes['scheduleScheduleRequest']>
  >({});
  const { modalProps: editModalProps, triggerProps: triggerEditProps } =
    useModalControl();
  const { modalProps: infoModalProps, triggerProps: triggerInfoProps } =
    useModalControl();
  const { data, refetch } = useScheduleRequests({});
  useEffect(() => {
    const widgetScript = document.createElement('script');
    const uriPrefix = (REACT_APP_API_URL as string).includes('dev') ? 'dev-' : '';
    widgetScript.src = `https://${uriPrefix}book.getweave.com/schedule/${locationId}/widget.js`;
    widgetScript.async = true;

    document.body.appendChild(widgetScript);

    return () => {
      document.body.removeChild(widgetScript);
      document.getElementById('iframe-weave-widget-container')?.remove();
      document.getElementById('iframe-weave-widget-button')?.remove();
    };
  }, [locationId]);

  useEffect(() => {
    if (data?.data?.Requests) {
      setLoading(false);
    }
  }, [data]);

  const editButton = {
    Header: '',
    id: 'editRequest',
    accessor: (rowData) => {
      return (
        <IconButton
          label="edit appointment"
          onClick={() => {
            setSelectedAppointment(rowData);
            triggerEditProps.onClick();
          }}
          size="small"
          aria-label={`Edit appointment request ${rowData.ID.uuid.slice(0, 6)}`}
        >
          <EditIcon />
        </IconButton>
      );
    },
    width: 60,
  };

  const infoButton = {
    Header: '',
    id: 'infoDisplay',
    accessor: (rowData) => {
      return (
        <IconButton
          label="additional info"
          onClick={() => {
            setSelectedAppointment(rowData);
            triggerInfoProps.onClick();
          }}
          size="small"
          aria-label={`Additional appointment request info for ${rowData.ID.uuid.slice(
            0,
            6
          )}`}
        >
          <InfoIcon />
        </IconButton>
      );
    },
    width: 60,
  };

  return (
    <>
      <OnlineSchedulingTabHeader
        title="Appointment Request Log"
        refresh={refetch}
        refreshLabel="Refresh Log"
      />

      {loading && <ContentLoader show message={'Loading...'} backgroundOpacity={0} />}

      {!data?.data?.Requests?.length && !loading ? (
        <p>No requests found</p>
      ) : (
        <ReactTable
          columns={[...columns, infoButton, editButton]}
          data={data?.data?.Requests}
          loading={loading}
          defaultPageSize={20}
          css={[css(reactTableStyle), css(appointmentLogStyle), TableStyles]}
          defaultSorted={[
            {
              id: 'selectedAppointment',
              desc: true,
            },
          ]}
        />
      )}

      <AppointmentRequestEditForm
        selectedAppointment={selectedAppointment}
        modalProps={editModalProps}
        refreshAppointmentRequests={refetch}
      />

      <AppointmentRequestInfoModal
        selectedAppointment={selectedAppointment}
        modalProps={infoModalProps}
      />
    </>
  );
};
