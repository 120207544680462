import React from 'react';
import * as styles from '../sms-details.styles';

export type SmsUserMessageProps = {
  firstName: string;
  lastName: string;
};

export const SmsUserMessage = (props: SmsUserMessageProps) => {
  return (
    <div>
      <div className={styles.infoBox}>
        <label className={styles.infoBoxLeft}>First Name:</label>
        <p className={styles.infoBoxRight}>{props.firstName}</p>
      </div>
      <div className={styles.infoBox}>
        <label className={styles.infoBoxLeft}>Last Name:</label>
        <p className={styles.infoBoxRight}>{props.lastName}</p>
      </div>
    </div>
  );
};
