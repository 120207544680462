import { formatPhoneNumber } from '../../../helpers/utils';

export class ForwardingNumber {
  id = '';
  name = '';
  number = '';
  tenantId = '';
}

export class ForwardingStatus {
  enabled = false;
  id = '';
  name = '';
  number = '';
  tenantId = '';
}

export class SMSNumber {
  Active = false;
  ID = '';
  Name = '';
  Number = '';
  Provider = '';

  static formattedDisplay(smsNumber: SMSNumber): string {
    const formattedNumber = formatPhoneNumber(smsNumber.Number);
    return `${formattedNumber} - ${smsNumber.Name} - ${smsNumber.Provider}`;
  }
}

export class VoicemailOverride {
  enabled = false;

  // tslint:disable-next-line
  media_present = false;
}

export interface CallSettingsSaveModel {
  callRecordingStatus?: boolean;
  forwardingStatus?: { id: string; enabled: boolean };
  smsNumber?: SMSNumber;
}

export interface CallSettingsState {
  forwardingNumbers: ForwardingNumber[];
  forwardingStatus: ForwardingStatus;
  callRecordingStatus: boolean;
  activeSmsNumber: SMSNumber;
  smsNumberList: SMSNumber[];
  loading: boolean;
}
