import React, { useEffect, useMemo } from 'react';
import { matchPath, withRouter } from 'react-router-dom';
import { OutageAlertsBanner } from './outage-alerts-banner/outage-alerts-banner.component';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAndAuthenticateFirebaseToken,
  setFirebaseToken,
} from '../../redux/actions/firebase-token';
import { firebaseTokenSelector } from '../../redux/actions/firebase-token';
import { selectAuthToken } from '../../redux/actions/auth/auth.selectors';
import {
  getActiveOutageAlerts,
  selectActiveOutageAlerts,
} from '../../redux/actions/outage-alerts';
import { AuthStorage } from '../../redux/actions/auth/auth.types';

export const OutageAlertsBannerContainer = withRouter(({ history, children }) => {
  const dispatch = useDispatch();
  const activeOutageAlerts = useSelector(selectActiveOutageAlerts);
  const firebaseToken = useSelector(firebaseTokenSelector);
  const token = useSelector(selectAuthToken);

  /**
   * useEffect to setback id token in redux on refresh
   */
  useEffect(() => {
    if (localStorage.getItem(AuthStorage.firebase_token)) {
      dispatch(
        setFirebaseToken({
          firebaseToken: localStorage.getItem(AuthStorage.firebase_token),
        })
      );
    }
  }, []);

  //useEffect to get firebase token from weave token
  useEffect(() => {
    if (getConditionForFirebaseTokenAuthentication()) {
      dispatch(getAndAuthenticateFirebaseToken());
    }
  }, [token]);

  /**
   * useEffect to fetch realtime data from firestore
   */
  useEffect(() => {
    if (getConditionForFetchingActiveOutageAlerts()) {
      dispatch(getActiveOutageAlerts());
    }
  }, [firebaseToken]);

  /**
   * @desc : Function is defined to verify the condition whether to dispatch action
   *         to get and validate Firebase Authentication Token
   * @returns boolean
   */
  const getConditionForFirebaseTokenAuthentication = () => {
    return (
      localStorage.getItem(AuthStorage.weave_token) &&
      !localStorage.getItem(AuthStorage.firebase_token) &&
      token
    );
  };

  /**
   * @desc : Function is defined to verify the condition whether to dispatch action
   *         to get active outage Alerts
   * @returns : boolean
   */
  const getConditionForFetchingActiveOutageAlerts = () => {
    return (
      firebaseToken?.firebaseToken &&
      localStorage.getItem(AuthStorage.firebase_token) &&
      activeOutageAlerts &&
      activeOutageAlerts.length < 1
    );
  };

  /**
   * useMemo to find outage alert whose paths configured are matching with the routes
   */
  const mappedOutageAlert = useMemo(() => {
    return (
      activeOutageAlerts &&
      activeOutageAlerts.length > 0 &&
      activeOutageAlerts.find((serviceOutage) => {
        return serviceOutage.path.find((route) => {
          return matchPath(history.location.pathname + history.location.search ?? '', {
            path: route,
            exact: true,
          });
        })
          ? true
          : false;
      })
    );
  }, [activeOutageAlerts, history.location]);

  return (
    <>
      {activeOutageAlerts && mappedOutageAlert ? (
        <OutageAlertsBanner
          show={!mappedOutageAlert.isDeleted || !mappedOutageAlert.isDismissed}
          message={mappedOutageAlert?.message}
          outageId={mappedOutageAlert?.outageId}
        />
      ) : null}
    </>
  );
});
