import { useEffect, useState } from 'react';
import Fuse from 'fuse.js';
import axios from 'axios';

export type IndexedContent =
  | {
      terms: string[];
      route: string;
      displayName: string;
      id?: string;
      isCustom?: boolean;
    }
  | undefined;

const url =
  'https://storage.googleapis.com/desktop-client-assets/indexed-collections/wam-indexed.json';

export const useWamSearch = (search: string, locationID: string) => {
  const [fuse, setFuse] = useState<Fuse<IndexedContent>>();
  const [results, setResults] = useState<IndexedContent[]>([]);

  const fetchWamSearch = async () => {
    try {
      const { data } = await axios.get(url);
      const fuse: Fuse<IndexedContent> = new Fuse(data, {
        keys: [
          { name: 'terms', weight: 1 },
          { name: 'route', weight: 2 },
          { name: 'displayName', weight: 3 },
        ],
      });
      setFuse(fuse);
    } catch (err: any) {
      console.warn(err);
    }
  };

  useEffect(() => {
    fetchWamSearch();
  }, []);

  useEffect(() => {
    if (search && fuse) {
      const data = fuse.search(search);
      const results = data.map((item) => ({
        ...item.item,
        route: item?.item?.route,
      }));
      setResults(results as IndexedContent[]);
    }
  }, [locationID, search, fuse]);

  return results;
};
