import React, { useState } from 'react';
import ReactTable from 'react-table';
import { useSelector } from 'react-redux';
import { css } from '@emotion/core';
import { useAlert } from '@weave/alert-system';
import { WeaveTheme } from '@weave/theme-original';
import { PrimaryButton, PlusIconSmall } from '@weave/design-system';
import { TableStyles } from '../../../styles/table-style';
import { useHistory } from 'react-router-dom';
import { Page } from '../../shared/page/page.component';
import { useResources } from '../../../helpers/useResources';
import { selectCurrentLocationId } from '../../../redux/actions/location/current-location';
import { Store } from '../../../redux/store/store.model';
import { CallGroup } from './call-groups.types';
import { CustomAxios, getResponseData } from '../../../redux/axios';
import { injectParams, PHONES } from '../../../constants/paths';

export const CallGroups = () => {
  const history = useHistory();
  const [creating, setCreating] = useState<boolean>(false);
  const { locationId } = useSelector((store: Store) => ({
    locationId: selectCurrentLocationId(store),
  }));
  const alerts = useAlert();

  const { loading: loadingCallGroups, data: callGroups } = useResources<CallGroup[]>(
    'support/v1/phone/callgroups',
    {
      deps: [locationId],
      suppressFetch: !locationId,
      resourceLabel: 'call groups',
    }
  );

  const onCreateCallGroupClick = async () => {
    if (creating) {
      return;
    }

    setCreating(true);

    // Create the call group with a default name
    const newCallGroup: CallGroup = await CustomAxios.post(
      'support/v1/phone/callgroups',
      {
        name: 'Untitled Call Group',
      }
    )
      .then(getResponseData)
      .catch((error) => {
        alerts.error('Server error creating call group');
        setCreating(false);
      });

    // Redirect to the edit call group page for the newly created call group
    if (newCallGroup?.ID) {
      history.push(
        injectParams(PHONES.callGroup, { id: locationId, callGroupId: newCallGroup.ID })
      );
    }
  };

  return (
    <Page
      title="Call Groups"
      subtitle="Assign devices to a group, have them ring simultaneously or in a cascading order, and set each device's ring duration and ringtone."
      loading={creating}
      headerActions={
        <span>
          <PrimaryButton onClick={onCreateCallGroupClick}>
            Create Call Group
            <PlusIconSmall />
          </PrimaryButton>
        </span>
      }
    >
      <ReactTable
        columns={[
          {
            Header: 'Name',
            id: 'name',
            accessor: (callGroup) => callGroup.name || '-',
          },
          {
            Header: 'Devices',
            id: 'devices',
            accessor: (callGroup) =>
              `${callGroup.callLegs?.length ?? '0'} Device${
                callGroup.callLegs?.length === 1 ? '' : 's'
              }`,
          },
        ]}
        data={callGroups}
        showPagination={false}
        loading={loadingCallGroups}
        pageSize={callGroups.length || 5}
        defaultSorted={[{ id: 'name', desc: false }]}
        css={(theme: WeaveTheme) => [
          TableStyles,
          css`
            margin: 24px 0;

            .rt-th {
              padding-left: 8px !important;

              .rt-resizable-header-content {
                padding-left: 0 !important;
              }
            }

            .rt-tr-group {
              cursor: pointer;

              :hover {
                background: ${theme.colors.gray[100]};
              }

              .rt-td {
                padding-left: 8px !important;
              }
            }
          `,
        ]}
        getTdProps={(state, row) => {
          if (row?.original?.ID) {
            return {
              onClick: (e) => {
                // Redirect to the edit call group page on row clicks
                history.push(
                  injectParams(PHONES.callGroup, {
                    id: locationId,
                    callGroupId: row?.original?.ID,
                  })
                );
              },
            };
          }

          return {};
        }}
      />
    </Page>
  );
};
