import { css } from 'emotion';
import { gray1 } from '@weave/platform-ui-components';

export const nameInput = css`
  label: nameInput;

  border: 1px solid ${gray1};
  border-radius: 4px;
  margin-right: 15px;
  padding: 10px;

  &:focus,
  &:active {
    outline: 0;
  }
`;

export const customTableStyles = css`
  .rt-table {
    overflow: visible !important;
  }

  .rt-tbody {
    overflow: visible !important;
  }

  .rt-td {
    overflow: visible !important;
  }
`;
