import React from 'react';
import { css } from '@emotion/core';
import {
  IconButton,
  ModalControlTriggerProps,
  PlusIconSmall,
  Text,
  useThemeValues,
} from '@weave/design-system';

import { centerH, hours, weekdays } from '../schedule-display-utils';
import { OfficeHours, ProviderClosedHours, SelectedTimeblock } from './providers.types';
import { scheduleKey } from './provider-schedule-modal.component';
import { CalendarTimeblock } from './calendar-timeblock.component';

const calendarBorder = (theme) => css`
  border: ${theme.colors.gray300} 1px solid;
`;

const calendarContainterStyles = css`
  display: grid;
  grid-template-columns: 60px repeat(7, 1fr);
  width: 100%;
`;

const hoursSideStyles = css`
  display: grid;
  gap: 0;
  grid-template-rows: 32px repeat(24, 40px);
`;

const hourCellStyles = ({ start, end, theme }) => css`
  grid-row: ${start} / ${end};
  margin: 0;
  height: 100%;
  width: 100%;
  line-height: ${theme.spacing(5)};
  text-align: center;
  background-color: ${theme.colors.gray200};
  ${calendarBorder(theme)}
  & :first-child {
    border-top-left-radius: ${theme.borderRadius.medium};
  }
  & :last-child {
    border-bottom-left-radius: ${theme.borderRadius.medium};
  }
`;

const calendarDayCardStyles = (theme) => css`
  display: grid;
  gap: 0;
  grid-template-rows: 32px repeat(24, 40px);
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  text-align: center;
  ${calendarBorder(theme)}
  & :nth-child(2) {
    border-top-left-radius: ${theme.borderRadius.medium};
  }
  & :last-child {
    border-top-right-radius: ${theme.borderRadius.medium};
    border-bottom-right-radius: ${theme.borderRadius.medium};
  }
`;

const calendarDayCardHeaderStyles = (theme) => css`
  grid-column: 1/5;
  ${centerH}
  justify-content: space-between;
  height: 32px;
  line-height: 32px;
  border-bottom: ${theme.colors.gray300} 1px solid;
  background-color: ${theme.colors.gray200};
  margin: 0;
  padding-left: ${theme.spacing(2)};
  padding-right: ${theme.spacing(0.5)};

  p {
    padding-top: ${theme.spacing(2.5)};
  }
`;

interface ScheduleCalendarProps {
  clearSelectedTimeblock: () => void;
  officeHours: OfficeHours | undefined;
  officeHoursModalTriggerProps: ModalControlTriggerProps;
  providerClosedTimes: ProviderClosedHours | undefined;
  setSelectedDay: (day: string) => void;
  setSelectedTimeblock: (timeblock: SelectedTimeblock) => void;
  timeblockFormTriggerProps: ModalControlTriggerProps;
}

export const ScheduleCalendar = ({
  clearSelectedTimeblock,
  officeHours,
  officeHoursModalTriggerProps,
  providerClosedTimes,
  setSelectedDay,
  setSelectedTimeblock,
  timeblockFormTriggerProps,
}: ScheduleCalendarProps) => {
  const theme = useThemeValues();

  return (
    <section css={calendarContainterStyles}>
      <section css={hoursSideStyles}>
        {hours.map((hour) => {
          const start = hour + 2;
          const end = start + 1;
          return (
            <Text
              key={`hour-${hour}`}
              className="hour"
              css={hourCellStyles({
                start: start,
                end: end,
                theme,
              })}
            >
              {hour}:00
            </Text>
          );
        })}
      </section>

      {weekdays.map((day) => (
        <section key={`${day}-column`} css={calendarDayCardStyles(theme)}>
          <div css={calendarDayCardHeaderStyles(theme)}>
            <Text textAlign="center">{day}</Text>
            <IconButton
              label="Add timeblock"
              onClick={async () => {
                await setSelectedDay(day);
                await clearSelectedTimeblock();
                timeblockFormTriggerProps.onClick();
              }}
              size="small"
            >
              <PlusIconSmall />
            </IconButton>
          </div>

          {officeHours?.[day.toLowerCase()]?.map((timeblock, i) => {
            const keyItem = scheduleKey(theme).filter(
              (item) => item.type === 'officeHours'
            )[0];
            return (
              <CalendarTimeblock
                key={`${day}-officeHours-${i}`}
                background={keyItem.color}
                startCol={keyItem.startCol}
                endCol={keyItem.endCol}
                startHour={timeblock?.time?.timeOfDay?.hours ?? 0}
                startMinute={timeblock?.time?.timeOfDay?.minutes ?? 0}
                duration={timeblock?.time?.durationMinutes ?? 0}
                onClickAction={async () => {
                  await setSelectedTimeblock({
                    weekday: day,
                    timeblock: timeblock,
                    type: 'officeHours',
                  });
                  officeHoursModalTriggerProps.onClick();
                }}
              />
            );
          })}

          {providerClosedTimes &&
            providerClosedTimes?.[day.toLowerCase()]?.map((timeblock, i) => {
              const keyItem = scheduleKey(theme).filter(
                (item) => item.type === 'providerHours'
              )[0];
              return (
                <CalendarTimeblock
                  key={`${day}-providerHours-${i}`}
                  background={keyItem.color}
                  startCol={keyItem.startCol}
                  endCol={keyItem.endCol}
                  startHour={timeblock?.time?.timeOfDay?.hours ?? 0}
                  startMinute={timeblock?.time?.timeOfDay?.minutes ?? 0}
                  duration={timeblock?.time?.durationMinutes ?? 0}
                  onClickAction={async () => {
                    await setSelectedDay(day);
                    await setSelectedTimeblock({
                      weekday: day,
                      timeblock: timeblock,
                      type: 'providerHours',
                    });
                    timeblockFormTriggerProps.onClick();
                  }}
                />
              );
            })}
        </section>
      ))}
    </section>
  );
};
