import React, { useEffect } from 'react';
import { css } from '@emotion/core';
import { CheckboxField, FormFieldActionTypes, useForm } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { CheckFieldValuesType } from './sync-app-access.types';

type CheckBoxFieldComponentProps = {
  fieldValues: CheckFieldValuesType;
  hideContactsAndSchedule: boolean;
};

export const AllLocationAccessCheckField = ({
  fieldValues,
  hideContactsAndSchedule,
}: CheckBoxFieldComponentProps) => {
  const { getFieldProps, seedValues } = useForm({
    fields: {
      contactField: {
        type: 'checkbox',
        value: fieldValues.contacts,
      },
      scheduleField: {
        type: 'checkbox',
        value: fieldValues?.schedule,
      },
    },
    fieldStateReducer: (state, action) => {
      if (
        action.type === FormFieldActionTypes.Update &&
        action.payload.name === 'contactField' &&
        state.contactField.value === false
      ) {
        return {
          ...state,
          scheduleField: { ...state.scheduleField, value: false },
        };
      }
      return state;
    },
  });

  useEffect(() => {
    seedValues({
      contactField: !!fieldValues.contacts,
      scheduleField: !!fieldValues.schedule,
    });
  }, [fieldValues]);

  return (
    <div css={checkBoxFieldContainer}>
      <CheckboxField
        {...getFieldProps('contactField')}
        label="Contacts for all data source"
        css={mRightSpacing}
        disabled
      />
      {!hideContactsAndSchedule && (
        <CheckboxField
          {...getFieldProps('scheduleField')}
          label="Schedule for all data source"
          disabled
        ></CheckboxField>
      )}
    </div>
  );
};

const checkBoxFieldContainer = css`
  display: flex;
  align-items: center;
  margin-left: 50%;
  & label {
    font-size: ${theme.fontSize(14)};
  }
  > div {
    width: 200px;
  }
`;

const mRightSpacing = (spacing = 2) => css`
  margin-right: ${theme.spacing(spacing)};
`;
