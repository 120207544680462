import React from 'react'
import { 
  Modal,
  ModalControlModalProps,
  Text, 
} from '@weave/design-system'

interface OfficeHoursTimeblockModalProps {
  modalProps: ModalControlModalProps;
  setCurrentTab: (tab: string) => void;
  clearSelectedProvider: () => void;
  clearSelectedTimeblock: () => void;  
}

export const OfficeHoursTimeblockModal = ({
  modalProps,
  setCurrentTab,
  clearSelectedProvider,
  clearSelectedTimeblock,
}: OfficeHoursTimeblockModalProps) => {
  return (
    <Modal {...modalProps}>
      <Modal.Body>
        <Text>Go to office hours tab to edit the current office closed times?</Text>
      </Modal.Body>
      <Modal.Actions
        onPrimaryClick={async () => {
          await clearSelectedTimeblock()
          await clearSelectedProvider()
          await setCurrentTab('office-hours')
          modalProps.onClose();
        }}
        primaryLabel="Go to Office Hours"
        onSecondaryClick={() => {
          clearSelectedTimeblock()
          modalProps.onClose();
        }}
        secondaryLabel="Cancel"
      />
    </Modal>
  )
}
