import React from 'react';
import { css } from 'emotion';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyIcon, IconButton, Modal } from '@weave/design-system';

export type InteractiveCellTextProps = {
  text: string;
};

// Functional component meant to be used as a cell in react-table. Provides
// horizontal scrolling and click to display full text in modal.
export const InteractiveCellText = ({ text }: InteractiveCellTextProps) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [detailsCopied, setDetailsCopied] = React.useState(false);

  const copiedDetailsText = () => {
    setDetailsCopied(true);
    setTimeout(() => {
      setDetailsCopied(false);
    }, 3000);
  };

  const openConfirmationModal = () => {
    setModalOpen(true);
  };

  const handleCloseConfirmation = () => {
    setModalOpen(false);
  };

  const innerDivStyles = css`
    cursor: pointer;
    padding: 4px 0px;
  `;

  const copyToClipboard = css`
    font-size: 12px;
    cursor: pointer;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  `;

  const copied = css`
    margin-left: 5px;
    color: #28a745;
  `;

  return (
    <>
      <div onClick={openConfirmationModal}>
        <div className={innerDivStyles}>{text.substring(0, 30)}...</div>
      </div>
      <Modal show={modalOpen} onClose={handleCloseConfirmation}>
        <Modal.Header>Details</Modal.Header>
        <Modal.Body>
          {text}
          <CopyToClipboard text={text} onCopy={copiedDetailsText}>
            <div className={copyToClipboard} title="Copy to clipboard">
              <IconButton label="Copy">
                <CopyIcon />
              </IconButton>
              {detailsCopied && <span className={copied}> Copied! </span>}
            </div>
          </CopyToClipboard>
        </Modal.Body>
      </Modal>
    </>
  );
};
