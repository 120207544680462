import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRequestState, useAlert } from '@weave/alert-system';
import { WeaveTheme } from '@weave/theme-original';
import { NakedUl, useModalControl, Heading, PrimaryButton } from '@weave/design-system';
import { css } from '@emotion/core';

import { FaxHistoryContainer } from '../fax-history-container/fax-history.container';
import { Endpoint } from '../../../../redux/actions/history/endpoints';
import { Loader } from '../../../shared/loader/loader.component';
import {
  FaxDisplayInfo,
  FaxInfoFlag,
} from './fax-display-info/fax-display-info.component';
import { Page } from '../../../shared';
import { ConfirmationModal } from '../../../shared/confirmation-modal/confirmation-modal.component';
import { fetchFaxSettings, selectFaxSettings } from '../../../../redux/actions';
import { selectCustomizationFeature } from '../../../../redux/actions/customization/customization.action';
import { selectCurrentLocationId } from '../../../../redux/actions/location';
import { LocationSettingsActionTypes } from '../../../../redux/actions/location/location-settings/location-settings.action';
import { CustomAxios } from '../../../../redux/axios';
import { Store } from '../../../../redux/store/store.model';
import { faxStyles, twilioStyles } from './fax-settings.styles';

const faxTipStyles = (theme: WeaveTheme) => css`
  padding: ${theme.spacing(1)};
  font-size: 14px;
  background-color: ${theme.colors.gray['200']};
  border: 1px solid ${theme.colors.gray['300']};
  margin-right: ${theme.spacing(2)};
  max-width: 400px;
  width: 100%;
`;

export const FaxSettings = () => {
  const { loading } = useRequestState(LocationSettingsActionTypes.Fetch);
  const {
    number,
    status,
    status_callback,
    voice_url,
    voice_fallback_url,
    voice_method,
    voice_fallback_method,
    status_callback_method,
    provider,
  } = useSelector(selectFaxSettings);
  const customization = useSelector((store: Store) =>
    selectCustomizationFeature(store, 'Fax')
  );
  const currentLocationId = useSelector(selectCurrentLocationId);
  const dispatch = useDispatch();
  const alerts = useAlert();

  useEffect(() => {
    if (currentLocationId) {
      dispatch(fetchFaxSettings());
    }
  }, [currentLocationId]);

  const { modalProps, triggerProps } = useModalControl();
  const [testSendFaxInfo, setTestSendFaxInfo] = useState({ loading: false, result: '' });

  const sendTestFax = async () => {
    if (testSendFaxInfo.loading) {
      return setTestSendFaxInfo({ loading: true, result: '' });
    }
    try {
      await CustomAxios.post('/support/v1/fax/sendtest', { To: number });
      setTestSendFaxInfo({ loading: false, result: 'success' });
      alerts.success('Test fax successfully sent!');
    } catch {
      setTestSendFaxInfo({ loading: false, result: 'failure' });
      alerts.error('Test fax failed!');
    }
  };

  let faxDIDMessage = 'No Fax # provisioned to tenant check phone data service';
  let faxDIDStatusFlag = FaxInfoFlag.failure;
  if (number) {
    faxDIDMessage = number;
    faxDIDStatusFlag = FaxInfoFlag.success;
  }

  let faxCustomizationMessage = 'Customization Flag is "off"';
  let faxCustomizationFlag = FaxInfoFlag.failure;
  if (customization) {
    faxCustomizationMessage = 'Customization Flag is turned to "show"';
    faxCustomizationFlag = FaxInfoFlag.success;
  }

  let twilioCallbackURLMessage =
    status_callback || 'This location has no fax number tied to it';
  let twilioCallbackURLFlag = FaxInfoFlag.failure;
  let tooltipBody;
  let showInfo = false;

  if (voice_url && voice_fallback_url) {
    if (
      voice_url === 'https://api.weaveconnect.com/v1/twilio/fax/receive' &&
      voice_method === 'GET' &&
      voice_fallback_url === 'https://api.weaveconnect.com/v1/twilio/fax/receive' &&
      voice_fallback_method === 'GET' &&
      status_callback === 'https://api.weaveconnect.com/v1/twilio/fax/status' &&
      status_callback_method === 'POST'
    ) {
      twilioCallbackURLMessage = 'URLs are configured correctly';
      twilioCallbackURLFlag = FaxInfoFlag.success;
      showInfo = true;
    } else {
      twilioCallbackURLMessage =
        'URLs are configured but they are not correct, contact tier 2 support';
      twilioCallbackURLFlag = FaxInfoFlag.failure;
      showInfo = true;
    }

    tooltipBody = (
      <div className="twilio-urls-tooltip" css={twilioStyles}>
        <p>
          <span>Voice URL:</span> <br /> {voice_url} <br /> (<b>Method:</b> {voice_method}
          )
        </p>
        <p>
          <span>Voice Fallback URL:</span> <br /> {voice_fallback_url} <br /> (
          <b>Method:</b> {voice_fallback_method})
        </p>
        <p>
          <span>Status Callback:</span> <br /> {status_callback} <br /> (<b>Method:</b>{' '}
          {status_callback_method})
        </p>
      </div>
    );
  }

  let twilioPortStatusMessage = '';
  let twilioPortStatusFlag = FaxInfoFlag.neutral;
  if (status === 'in-use') {
    twilioPortStatusMessage = 'In Use';
    twilioPortStatusFlag = FaxInfoFlag.success;
  } else if (status === 'porting-in') {
    twilioPortStatusMessage = 'In Progress';
    twilioPortStatusFlag = FaxInfoFlag.inProgress;
  }

  return (
    <Page
      title="Fax Dashboard"
      headerActions={
        <div className="actions">
          <PrimaryButton
            onClick={() => {
              dispatch(fetchFaxSettings());
            }}
          >
            Refresh Data
          </PrimaryButton>
        </div>
      }
    >
      <div className="fax" css={faxStyles}>
        {loading && <Loader />}

        {!loading && (
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: space-between;
            `}
          >
            <div>
              <FaxDisplayInfo
                title="DID In Phone Service"
                infoMessage={faxDIDMessage}
                statusFlag={faxDIDStatusFlag}
              />
              <FaxDisplayInfo
                title="Customization"
                infoMessage={faxCustomizationMessage}
                statusFlag={faxCustomizationFlag}
              />

              <FaxDisplayInfo
                title="Fax Service Provider"
                infoMessage={provider || 'No provider'}
              />

              {provider === 'twilio' && (
                <>
                  <FaxDisplayInfo
                    title="Twilio Callback URLs"
                    infoMessage={twilioCallbackURLMessage}
                    statusFlag={twilioCallbackURLFlag}
                    tooltipInfo={tooltipBody}
                    showInfo={showInfo}
                  />
                  <FaxDisplayInfo
                    title="Port Status"
                    infoMessage={twilioPortStatusMessage}
                    statusFlag={twilioPortStatusFlag}
                  />
                </>
              )}
            </div>
            {number && (
              <div
                css={(theme: WeaveTheme) => css`
                  margin-right: ${theme.spacing(4)};
                  display: flex;
                `}
              >
                <div>
                  <div
                    className="send-test-fax-circle"
                    id="sendFax"
                    onClick={triggerProps.onClick}
                  >
                    Send Test Fax
                  </div>
                  {testSendFaxInfo && testSendFaxInfo.loading ? (
                    <Loader />
                  ) : (
                    <>
                      <div
                        css={css`
                          text-align: center;
                        `}
                      >
                        <span className="fax-info-text info">Result:</span> &nbsp;
                        {testSendFaxInfo && !testSendFaxInfo.loading && (
                          <span className={`${testSendFaxInfo.result}`}>
                            {testSendFaxInfo.result}
                          </span>
                        )}
                      </div>
                      <div
                        css={css`
                          text-align: center;
                        `}
                      >
                        <span className="fax-info-text info">Status:</span> &nbsp;
                      </div>
                    </>
                  )}
                </div>
                <ConfirmationModal
                  title="Confirm to send a test fax"
                  onRequestClose={modalProps.onClose}
                  onConfirmClick={sendTestFax}
                  isOpen={modalProps.show}
                  confirmBtnTitle="Send Fax"
                />
              </div>
            )}
          </div>
        )}
        {number && (
          <div
            css={css`
              display: flex;
              margin-top: 20px;
            `}
          >
            <div css={faxTipStyles}>
              <Heading level={2}>Tips for fax issues</Heading>
              <NakedUl>
                <li>Call the recieving fax number to verify if its a fax line</li>
                <li>Make sure the sending file is a PDF</li>
                <li>
                  Is the Fax in <b>portrait mode</b>
                </li>
                <li>
                  The size must be <b>8.5 x 11 inches</b>.
                </li>
                <li>
                  Is it larger than <b>25 MB</b>
                </li>
                <li>
                  Have you sent a test fax to <b>dev-scott 385-881-0035</b>
                </li>
                <li>
                  Have you tried <b>FaxZero</b>?
                </li>
              </NakedUl>
            </div>
            <div css={faxTipStyles}>
              <Heading level={2}>Known fax issues</Heading>
              <NakedUl>
                <li>
                  <b>Unable to print from the Print/preview page.</b>
                </li>
                <li>- Have the office download the file and print from there</li>
                <li>
                  <b>Error 4003: No loop current or A/B signaling bits</b>
                </li>
                <li>- Make sure the dialed number is a fax number</li>
                <li>
                  - If they are, provide the numbers to Tier 2 to check the transmitting
                  status
                </li>
                <li>
                  <b>They cannot delete faxes</b>
                </li>
                <li>
                  - Kindly show them that it has changed to Archive and explain that they
                  can unarchive a fax in the Admin Portal if they need
                </li>
              </NakedUl>
            </div>
          </div>
        )}
      </div>
      <FaxHistoryContainer title={Endpoint.faxHistory} />
    </Page>
  );
};
