import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';

export const container = css`
  display: block;
  flex: auto;
  -webkit-flex: auto;
  -ms-flex: auto;
  margin: ${theme.spacing(0, 2, 2, 2)};
  padding: ${theme.spacing(5)};
  border-radius: ${theme.borderRadius.medium};
  box-shadow: ${theme.shadows.heavy};
  background-color: ${theme.colors.white};
  max-height: 1300px;
  overflow-y: auto;

  /* Global */
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: column wrap;
    padding: ${theme.spacing(1, 2)};
  }

  .divider {
    border-bottom: 1px solid ${theme.colors.gray400};
  }
  .py-1 {
    padding: ${theme.spacing(1, 0)};
  }
  .py-2 {
    padding: ${theme.spacing(2, 0)};
  }
  .py-3 {
    padding: ${theme.spacing(3, 0)};
  }
  .py-4 {
    padding: ${theme.spacing(4, 0)};
  }
  .px-1 {
    padding: ${theme.spacing(0, 1)};
  }
  .px-2 {
    padding: ${theme.spacing(0, 2)};
  }
  .px-3 {
    padding: ${theme.spacing(0, 3)};
  }
  .px-4 {
    padding: ${theme.spacing(0, 4)};
  }
  .pt-1 {
    padding-top: ${theme.spacing(1)};
  }
  .pt-2 {
    padding-top: ${theme.spacing(2)};
  }
  .pt-3 {
    padding-top: ${theme.spacing(3)};
  }
  .pt-4 {
    padding-top: ${theme.spacing(4)};
  }
  .pb-1 {
    padding-bottom: ${theme.spacing(1)};
  }
  .pb-2 {
    padding-bottom: ${theme.spacing(2)};
  }
  .pb-3 {
    padding-bottom: ${theme.spacing(3)};
  }
  .pb-4 {
    padding-bottom: ${theme.spacing(4)};
  }
  .pl-1 {
    padding-left: ${theme.spacing(1)};
  }
  .pl-2 {
    padding-left: ${theme.spacing(2)};
  }
  .pl-3 {
    padding-left: ${theme.spacing(3)};
  }
  .pl-4 {
    padding-left: ${theme.spacing(4)};
  }
  .pr-1 {
    padding-right: ${theme.spacing(1)};
  }
  .pr-2 {
    padding-right: ${theme.spacing(2)};
  }
  .pr-3 {
    padding-right: ${theme.spacing(3)};
  }
  .pr-4 {
    padding-right: ${theme.spacing(4)};
  }
  .mt-1 {
    margin-top: ${theme.spacing(1)};
  }
  .mt-2 {
    margin-top: ${theme.spacing(2)};
  }
  .mt-3 {
    margin-top: ${theme.spacing(3)};
  }
  .mt-4 {
    margin-top: ${theme.spacing(4)};
  }

  /* Index */
  .action-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.spacing(2, 0)};
    p {
      margin: 0;
    }
  }

  .action-items {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .action-icon {
      margin: ${theme.spacing(0, 2, 0, 0)};
      cursor: pointer;
      transition: ease-in-out 0.3s;
      :hover {
        color: ${theme.colors.hoverBlue};
      }
    }

    .csv-button {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 172px;
    }
  }

  .active-dot {
    position: absolute;
    right: 15px;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background: ${theme.colors.weaveBlue};
  }
`;
