import React from 'react';
import { css } from '@emotion/core';
import { Text } from '@weave/design-system';
import { Product } from '../sales-pricing.types';
import { AddonFeatureCard } from './addon-feature-card/addon-feature-card';

type Props = {
  addonsProductData: Product[];
  isItemInCart: (itemLabel: string) => boolean;
  getProductServiceFeeText: (product: Product) => string;
  getProductActivationFeeText: (product: Product) => string | null;
  onAddonProductClicked: (softwareProduct: Product) => void;
};

export const AddonsPricing = ({
  addonsProductData,
  isItemInCart,
  getProductServiceFeeText,
  getProductActivationFeeText,
  onAddonProductClicked,
}: Props) => {
  return (
    <div>
      <Text css={titleTextStyles} size="large" weight="bold">
        Select any additional feature bundles
      </Text>
      <Text css={subtitleTextStyles} size="medium" color="light">
        Click a card to select or deselect an option.
      </Text>

      <div css={cardsSectionStyles}>
        {addonsProductData.map((addonProduct) => (
          <AddonFeatureCard
            key={addonProduct.label}
            productData={addonProduct}
            isSelected={isItemInCart(addonProduct.label)}
            getProductServiceFeeText={getProductServiceFeeText}
            getProductActivationFeeText={getProductActivationFeeText}
            onAddonProductClicked={onAddonProductClicked}
          />
        ))}
      </div>
    </div>
  );
};

const titleTextStyles = css`
  margin-bottom: 8px;
`;
const subtitleTextStyles = css`
  margin-bottom: 16px;
`;
const cardsSectionStyles = css`
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  width: 610px;

  @media only screen and (max-width: 970px) {
    width: 100%;
  }
`;
