import { createAction } from 'redux-actions';
import { AuthorizationPayload, GoogleAuthPayload, OktaAuthPayload } from './auth.types';

export const signIn = createAction<AuthorizationPayload>('SIGN_IN');
export const signOut = createAction('SIGN_OUT');
export const signInSuccess = createAction('SIGNIN_SUCCESS');
export const signInRefresh = createAction('SIGNIN_REFRESH');
export const updateAuthToken = createAction('TOKEN_REFRESH');
export const handleOktaAuth = createAction<OktaAuthPayload>('HANDLE_OKTA_AUTH');
export const handleGoogleAuth = createAction<GoogleAuthPayload>('HANDLE_GOOGLE_AUTH');
