import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { css } from '@emotion/core';
import 'react-datepicker/dist/react-datepicker.css';

import { ToggleSwitch } from '../../../../../../shared/toggle-switch/toggle-switch.component';

interface TimeRangePickerProps {
  title: string;
  description: string;
  open: boolean;
  start: moment.Moment;
  stop: moment.Moment;

  onChange: (open: boolean, start: moment.Moment, stop: moment.Moment) => void;
}

export class TimeRangePicker extends React.Component<TimeRangePickerProps> {
  render() {
    const { title, description, open, start, stop, onChange } = this.props;
    return (
      <div
        className="hours-container"
        css={css`
          display: flex;
          margin-bottom: 10px;
          background-color: #fafcfc;

          .open-hours {
            border: 1px solid #d4dbdf;
            width: 100%;
            padding: 15px;
          }

          .title {
            display: flex;
            align-items: baseline;
            margin-bottom: 10px;

            h4 {
              margin-right: 15px;
            }
          }
          .hours {
            display: flex;
            margin-bottom: 10px;
          }
          .start,
          .stop {
            display: flex;
            align-items: baseline;

            h5 {
              margin-right: 10px;
            }
          }
          .stop {
            h5 {
              margin-left: 10px;
            }
          }

          ul.react-datepicker__time-list {
            padding-left: 0;
          }
        `}
      >
        <div className="open-hours">
          <div className="title">
            <h4>{title}</h4>
            <ToggleSwitch
              label={description}
              on={open}
              onChange={() => onChange(!open, start, stop)}
            />
          </div>
          <div className="hours">
            <div className="start">
              <h5>Open</h5>
              <DatePicker
                selected={start}
                onChange={(v) => onChange(open, v, stop)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={5}
                dateFormat="LT"
                timeCaption="Time"
                disabled={!open}
                popperPlacement="top"
              />
            </div>
            <div className="stop">
              <h5>Close</h5>
              <DatePicker
                selected={stop}
                onChange={(v) => onChange(open, start, v)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={5}
                dateFormat="LT"
                timeCaption="Time"
                disabled={!open}
                popperPlacement="top"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
