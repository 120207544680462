import { useState } from 'react';
import { useAlert } from '@weave/alert-system';
import { useResources } from '../../../../helpers/useResources';
import { CustomAxios } from '../../../../redux/axios';

export interface AppointmentTypeInterface {
  ID: string;
  LocationID: string;
  Name: string;
  DurationMinutes: number;
  OperatoryAssets: Operatory[];
  ProviderAssets: Provider[];
}

export type CreateAppointmentType = {
  Name: string;
  DurationMinutes: number;
};

interface Operatory {
  ID: string;
  OperatoryName: string;
  Available: boolean;
}

interface Provider {
  ID: string;
  OperatoryName: string;
  Available: boolean;
}

const url = '/support/v1/online-scheduling/appointment-types';

export const appointmentTypeApi = {
  update: ({ ID, ...updateData }: AppointmentTypeInterface) =>
    CustomAxios.put(`${url}/${ID}`, updateData).then((res) => true),
  remove: (id: string) => CustomAxios.delete(`${url}/${id}`).then((res) => true),
  create: (createData: CreateAppointmentType) =>
    CustomAxios.post(url, createData).then((res) => true),
};

export const useAppointmentTypesResource = () => {
  const { data, refresh } = useResources<AppointmentTypeInterface[]>(url, {});
  const alerts = useAlert();
  const [loading, setLoading] = useState<boolean>(false);

  const update = async (appointmentTypeData: AppointmentTypeInterface) => {
    try {
      setLoading(true);
      await appointmentTypeApi.update(appointmentTypeData);
      alerts.success('Updated Appointment Type');
      refresh();
    } catch {
      alerts.error('Failed to update appointment type.');
    } finally {
      setLoading(false);
    }
  };

  const remove = async (id: string) => {
    try {
      await appointmentTypeApi.remove(id);
      alerts.success('Deleted Appointment Type');
      refresh();
    } catch {
      alerts.error('Failed to delete appointment type.');
    }
  };

  const create = async (createData: CreateAppointmentType) => {
    try {
      setLoading(true);
      await appointmentTypeApi.create(createData);
      alerts.success('Created Appointment Type');
      refresh();
    } catch {
      alerts.error('Failed to create appointment type.');
    } finally {
      setLoading(false);
    }
  };
  const appointmentTypes: AppointmentTypeInterface[] = data?.length ? data : [];
  return { appointmentTypes, loading, create, update, remove };
};
