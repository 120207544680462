import React from 'react';
import { Button } from '@weave/platform-ui-components';
import { AlertsContext } from '@weave/alert-system';
import { css } from '@emotion/core';

import { LocationModel } from '../../../../../redux/actions/location';
import { OfficeHoursApi } from '../interface';
import { OfficeHoursCard } from './office-hours-card/office-hours-card/office-hours-card.component';
import { Page } from '../../../../shared';

interface Props {
  officeHours: OfficeHoursApi;
  locationId: string;
  getOfficeHours: () => void;
  loading: boolean;
  location: LocationModel;
}

export class OfficeHours extends React.Component<Props> {
  componentDidMount() {
    if (this.props.location?.LocationID) {
      this.props.getOfficeHours();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.location?.LocationID !== nextProps.location?.LocationID) {
      this.props.getOfficeHours();
    }
  }

  refresh = () => {
    this.props.getOfficeHours();
    this.context.success('Office hours refreshed');
  };

  render() {
    const { officeHours } = this.props;
    return (
      <Page
        loading={false}
        title="Office Hours"
        headerActions={<Button onClick={this.refresh}>Refresh Data</Button>}
      >
        <div
          className="office-hours"
          css={css`
            display: flex;
            flex-wrap: wrap;
            padding: 10px;
          `}
        >
          <OfficeHoursCard
            title="SUN"
            open={officeHours?.sundayHoursStart}
            close={officeHours?.sundayHoursStop}
            lunchStart={officeHours?.sundayLunchHoursStart}
            lunchStop={officeHours?.sundayLunchHoursStop}
            breakStart={officeHours?.sundayBreakHoursStart}
            breakStop={officeHours?.sundayBreakHoursStop}
            day="Sunday"
          />
          <OfficeHoursCard
            title="MON"
            open={officeHours?.mondayHoursStart}
            close={officeHours?.mondayHoursStop}
            lunchStart={officeHours?.mondayLunchHoursStart}
            lunchStop={officeHours?.mondayLunchHoursStop}
            breakStart={officeHours?.mondayBreakHoursStart}
            breakStop={officeHours?.mondayBreakHoursStop}
            day="Monday"
          />
          <OfficeHoursCard
            title="TUE"
            open={officeHours?.tuesdayHoursStart}
            close={officeHours?.tuesdayHoursStop}
            lunchStart={officeHours?.tuesdayLunchHoursStart}
            lunchStop={officeHours?.tuesdayLunchHoursStop}
            breakStart={officeHours?.tuesdayBreakHoursStart}
            breakStop={officeHours?.tuesdayBreakHoursStop}
            day="Tuesday"
          />
          <OfficeHoursCard
            title="WED"
            open={officeHours?.wednesdayHoursStart}
            close={officeHours?.wednesdayHoursStop}
            lunchStart={officeHours?.wednesdayLunchHoursStart}
            lunchStop={officeHours?.wednesdayLunchHoursStop}
            breakStart={officeHours?.wednesdayBreakHoursStart}
            breakStop={officeHours?.wednesdayBreakHoursStop}
            day="Wednesday"
          />
          <OfficeHoursCard
            title="THU"
            open={officeHours?.thursdayHoursStart}
            close={officeHours?.thursdayHoursStop}
            lunchStart={officeHours?.thursdayLunchHoursStart}
            lunchStop={officeHours?.thursdayLunchHoursStop}
            breakStart={officeHours?.thursdayBreakHoursStart}
            breakStop={officeHours?.thursdayBreakHoursStop}
            day="Thursday"
          />
          <OfficeHoursCard
            title="FRI"
            open={officeHours?.fridayHoursStart}
            close={officeHours?.fridayHoursStop}
            lunchStart={officeHours?.fridayLunchHoursStart}
            lunchStop={officeHours?.fridayLunchHoursStop}
            breakStart={officeHours?.fridayBreakHoursStart}
            breakStop={officeHours?.fridayBreakHoursStop}
            day="Friday"
          />
          <OfficeHoursCard
            title="SAT"
            open={officeHours?.saturdayHoursStart}
            close={officeHours?.saturdayHoursStop}
            lunchStart={officeHours?.saturdayLunchHoursStart}
            lunchStop={officeHours?.saturdayLunchHoursStop}
            breakStart={officeHours?.saturdayBreakHoursStart}
            breakStop={officeHours?.saturdayBreakHoursStop}
            day="Saturday"
          />
        </div>
      </Page>
    );
  }
}

OfficeHours.contextType = AlertsContext;
