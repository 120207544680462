import React from 'react';
import { css } from 'emotion';
import { locationDashboardStyles } from './location-dashboard.styles';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import { InteractiveCellText } from '../../interactive-cell-text.component';
import { dateTimeFormat } from '../../../../helpers/utils';
import { EmailMessages } from '../../../../redux/actions/smart-alerts/smart-alerts.action';
import { DotSvg } from './dot-svg';

interface Props {
  autoEmailMessageData: EmailMessages[];
}

export interface State {
  modalOpen: boolean;
  startDate: Date;
  endDate: Date;
}

const formatDate = (seconds: string | number): string => {
  if (!seconds) {
    return '';
  }

  return moment(seconds, ['X', 'YYYY-MM-DDTHH:mm:ssZ']).format(dateTimeFormat);
};

export default class SyncTableComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    moment.locale('en');
    momentLocalizer();

    this.state = {
      modalOpen: false,
      startDate: moment().subtract(1, 'week').toDate(),
      endDate: new Date(),
    };
  }

  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };

  render() {
    return (
      <tbody className={locationDashboardStyles.tableBody}>
        {!this.props.autoEmailMessageData
          ? 'Loading props email data'
          : this.props.autoEmailMessageData.map((email, emailIndex) => (
              <tr key={emailIndex}>
                <td> Email </td>
                <td className={locationDashboardStyles.dotColumn}>
                  <DotSvg color="error" />
                  {formatDate(email.CreatedAt)}
                </td>
                <td
                  className={css`
                    padding: 10px;
                  `}
                >
                  Failed
                </td>
                <td>
                  <div className={locationDashboardStyles.tableActions}>
                    <InteractiveCellText
                      text={
                        email.Status === ''
                          ? 'There are no details for the alert'
                          : email.Status
                      }
                    />
                  </div>
                </td>
              </tr>
            ))}
      </tbody>
    );
  }
}
