import { Action, createAction } from 'redux-actions';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { CustomAxios } from '../../axios';
import { showError } from '@weave/alert-system';
import { Store } from '../../store/store.model';
import { OfficeHoursModel } from '../../../components/phones/office-hours/models';

interface OfficeHoursPayload {
  officeHours: OfficeHoursModel;
  day: string;
}

export const getOfficeHours = createAction('GET_OFFICE_HOURS');
export const getOfficeHoursSuccess = createAction('GET_OFFICE_HOURS_SUCCESS');
export const getOfficeHoursFailure = createAction('GET_OFFICE_HOURS_FAILURE');

export const updateOfficeHours = createAction<any>('UPDATE_OFFICE_HOURS');
export const updateOfficeHoursSuccess = createAction<any>('UPDATE_OFFICE_HOURS_SUCCESS');
export const updaateOfficeHoursFailure = createAction<any>('UPDATE_OFFICE_HOURS_FAILURE');

export const putOfficeHours = createAction<OfficeHoursPayload>('PUT_OFFICE_HOURS');
export const putOfficeHoursSuccess = createAction<any>('PUT_OFFICE_HOURS_SUCCESS');

export const selectOfficeHours = (state: Store) => state.officeHours;

export const handleOfficeHours = function* () {
  try {
    const response = yield call(CustomAxios.get, 'support/v1/officehours');
    yield put(getOfficeHoursSuccess(response.data.data));
  } catch {
    yield put(getOfficeHoursFailure());
    yield put(showError('Failed to load office hours'));
  }
};

// TODO: Delete this when we delete the legacy code branch
export const handleUpdateOfficeHours = function* (action) {
  try {
    // eslint-disable-next-line prefer-const
    let { officeHours, day } = action.payload;
    day = day.toLowerCase();

    const currentOfficeHours = yield select(selectOfficeHours);
    const officeHoursData = Object.assign({}, currentOfficeHours.data);
    officeHoursData[`${day}HoursStart`] = officeHours.officeStart.format('HH:mm:ss');
    officeHoursData[`${day}HoursStop`] = officeHours.officeStop.format('HH:mm:ss');
    officeHoursData[`${day}LunchHoursStart`] = officeHours.lunchStart.format('HH:mm:ss');
    officeHoursData[`${day}LunchHoursStop`] = officeHours.lunchStop.format('HH:mm:ss');
    officeHoursData[`${day}BreakHoursStart`] = officeHours.breakStart.format('HH:mm:ss');
    officeHoursData[`${day}BreakHoursStop`] = officeHours.breakStop.format('HH:mm:ss');
    yield call(CustomAxios.put, 'support/v1/officehours', officeHoursData);
    yield put(updateOfficeHoursSuccess(officeHoursData));
  } catch (error: any) {
    yield put(updaateOfficeHoursFailure(error));
    yield put(showError('Failed to update office hours'));
  }
};

export const handlePutOfficeHours = function* (action: Action<OfficeHoursPayload>) {
  try {
    // eslint-disable-next-line prefer-const
    let { officeHours, day } = action.payload;
    day = day.toLowerCase();

    const currentOfficeHours = yield select(selectOfficeHours);
    const officeHoursData = Object.assign({}, currentOfficeHours.data);
    officeHoursData[`${day}HoursStart`] = officeHours.start;
    officeHoursData[`${day}HoursStop`] = officeHours.stop;
    officeHoursData[`${day}LunchHoursStart`] = officeHours.lunchStart;
    officeHoursData[`${day}LunchHoursStop`] = officeHours.lunchStop;
    officeHoursData[`${day}BreakHoursStart`] = officeHours.breakStart;
    officeHoursData[`${day}BreakHoursStop`] = officeHours.breakStop;
    yield call(CustomAxios.put, 'support/v1/officehours', officeHoursData);
    yield put(putOfficeHoursSuccess(officeHoursData));
  } catch (error: any) {
    console.error(error);
    yield put(showError('Failed to update office hours'));
  }
};

export const getOfficeHoursSaga = function* () {
  yield all([
    takeEvery(getOfficeHours.toString(), handleOfficeHours),
    takeEvery(updateOfficeHours.toString(), handleUpdateOfficeHours),
    takeEvery(putOfficeHours.toString(), handlePutOfficeHours),
  ]);
};
