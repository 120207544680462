import React from 'react';
import { Headline, Svg, Body } from '@weave/platform-ui-components';
import { forIn, get } from 'lodash';

import * as styles from './phone-tree-menu-section.styles';
import { PhoneTreeEntry } from '../phone-tree-entry/phone-tree-entry.component';
import { PhoneTreeEntriesMap, PhoneTreeInstructionsMap } from '../use-phone-tree-state';
import { PhoneTreeModel } from '../phone-tree.models';

interface Props {
  entries: PhoneTreeEntriesMap;
  instructions: PhoneTreeInstructionsMap;
  onPhoneTreeClick: (menuId: string) => void;
  phoneTreeDispatch: (action: any) => void;
  getPhoneTree: (phoneTreeId: string) => PhoneTreeModel | undefined;
}

export const PhoneTreeMenuSection = ({
  onPhoneTreeClick,
  instructions,
  entries,
  phoneTreeDispatch,
  getPhoneTree,
}: Props) => {
  const renderMenuOptions = () => {
    const dialEntries = {
      0: null,
      1: null,
      2: null,
      3: null,
      4: null,
      5: null,
      6: null,
      7: null,
      8: null,
      9: null,
    };
    const entryRows: any[] = [];

    forIn(entries, (entry) => {
      dialEntries[entry.Number] = entry;
    });

    forIn(dialEntries, (entry, dialNumber) => {
      entryRows.push(
        <PhoneTreeEntry
          key={get(entry, 'ID') || dialNumber}
          dialNumber={dialNumber}
          entry={entry}
          instructions={instructions}
          onPhoneTreeClick={onPhoneTreeClick}
          phoneTreeDispatch={phoneTreeDispatch}
          getPhoneTree={getPhoneTree}
        />
      );
    });

    return entryRows;
  };

  return (
    <>
      <div className={styles.section}>
        <Headline className={styles.subtitleContainer}>
          <Svg icon="Dial" color="gray9" className={styles.subtitleIcon} />
          Menu Options
        </Headline>
        <Body className={styles.subtitleHelpText}>
          Currently configured menu options for your Phone Tree. To change these options,
          please contact Weave support.
        </Body>
      </div>
      <div className={styles.entryContainer}>{renderMenuOptions()}</div>
    </>
  );
};
