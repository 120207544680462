import React from 'react';
import { useRequestState } from '@weave/alert-system';
import { ContentLoader, PrimaryButton } from '@weave/design-system';
import { ReactComponent as LogoIcon } from '../../images/wv-icon-logo2.svg';
import { useAuth } from '../../providers/auth.provider';
import { AuthStorage, SignInMode } from '../../redux/actions/auth/auth.types';
import { useHotkeys } from 'react-hotkeys-hook';
import { handleLocalStorage } from '../../redux/actions/auth/auth.helpers';
import * as styles from './SignIn.styles';

const CHANGE_LOGIN_HOT_KEY = 'ctrl+shift+l';

export const SignIn = () => {
  const { signInMode, setSignInMode, googleAuth, oktaAuth } = useAuth();
  const { loading } = useRequestState('SIGN_IN');

  useHotkeys(
    CHANGE_LOGIN_HOT_KEY,
    () => {
      if (signInMode === SignInMode.google) {
        handleLocalStorage.create(AuthStorage.midway, 'true');
        setSignInMode(SignInMode.okta);
      } else {
        handleLocalStorage.delete(AuthStorage.midway);
        setSignInMode(SignInMode.google);
      }
    },
    [signInMode]
  );

  const handleOktaAuth = async () => {
    const redirectPath = handleLocalStorage.get(AuthStorage.redirect_path);
    oktaAuth.signInWithRedirect({ originalUri: redirectPath || '/' });
  };

  const handleGoogleAuth = () => {
    googleAuth.authorize();
  };

  return (
    <div css={styles.container}>
      <ContentLoader show={loading} />
      <div css={styles.innerContainer}>
        <LogoIcon css={styles.mainLogo} />
        <div css={styles.divider} />
        <PrimaryButton
          disabled={loading}
          onClick={signInMode === SignInMode.okta ? handleOktaAuth : handleGoogleAuth}
        >
          {signInMode === SignInMode.okta ? 'Sign In' : 'Sign In With Google'}
        </PrimaryButton>
      </div>
    </div>
  );
};
