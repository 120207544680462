import { uniq, without } from 'lodash';
import { Reducer } from 'redux';
import { LocationsState } from '../../locations/locations.types';

export enum LocationFwdNumbersActionTypes {
  Add = 'LocationFwdNumbers:Add',
  Remove = 'LocationFwdNumbers:Remove',
}

export type AddLocationFwdNumbersAction = {
  type: LocationFwdNumbersActionTypes.Add;
  payload: { locationId: string; fwdNumberIds: string[] };
};

export type RemoveLocationFwdNumbersAction = {
  type: LocationFwdNumbersActionTypes.Remove;
  payload: { locationId: string; fwdNumberIds: string[] };
};

export type LocationFwdNumbersActions =
  | AddLocationFwdNumbersAction
  | RemoveLocationFwdNumbersAction;

export const addFwdNumbersToLocation = (
  locationId: string,
  fwdNumberIds: string[]
): LocationFwdNumbersActions => ({
  type: LocationFwdNumbersActionTypes.Add,
  payload: { locationId, fwdNumberIds },
});

export const removeFwdNumbersFromLocation = (
  locationId: string,
  fwdNumberIds: string[]
): LocationFwdNumbersActions => ({
  type: LocationFwdNumbersActionTypes.Remove,
  payload: { locationId, fwdNumberIds },
});

export const locationFwdNumbersReducer: Reducer<
  LocationsState,
  LocationFwdNumbersActions
> = (state = {}, action) => {
  if (!action.payload) {
    return state;
  }

  const location = state[action.payload.locationId];
  const prevFwdNumberIds = location?.fwdNumberIds ?? [];
  if (!location) {
    return state;
  }

  const fwdNumberIds =
    {
      [LocationFwdNumbersActionTypes.Add]: () =>
        uniq([...prevFwdNumberIds, ...action.payload.fwdNumberIds]),
      [LocationFwdNumbersActionTypes.Remove]: () =>
        without(prevFwdNumberIds, ...action.payload.fwdNumberIds),
    }[action.type]?.() ?? prevFwdNumberIds;

  return {
    ...state,
    [location.LocationID]: { ...location, fwdNumberIds },
  };
};
