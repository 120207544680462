import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { orderBy } from 'lodash';
import { createRequestSaga } from '@weave/alert-system';

import { CustomAxios, getResponseData } from '../../axios';
import { selectCurrentLocationId } from '../location/current-location';
import { addMedia, deleteMedia, updateMedia } from '../media';
import { PhoneMedia } from './phone-media.reducer';

export enum PhoneMediaActionTypes {
  Add = 'ADD_PHONE_MEDIA',
  RequestGet = 'REQUEST_GET_PHONE_MEDIA',
  RequestDelete = 'REQUEST_DELETE_PHONE_MEDIA',
  Delete = 'DELETE_PHONE_MEDIA',
  RequestUpdate = 'REQUEST_UPDATE_PHONE_MEDIA',
  RequestUpload = 'REQUEST_UPLOAD_PHONE_MEDIA',
}

type AddPayload = {
  locationId: string;
  media: PhoneMedia | PhoneMedia[];
};

type DeletePayload = {
  locationId: string;
  mediaId: string;
};

export type RequestUpdatePhoneMediaPayload = {
  mediaId: string;
  fileName: string;
};

export type RequestUploadPhoneMediaPayload = {
  phoneMedia: File;
  fileName: string;
};

type AddAction = {
  type: PhoneMediaActionTypes.Add;
  payload: AddPayload;
};

export type RequestGetPhoneMediaAction = {
  type: PhoneMediaActionTypes.RequestGet;
};

export type RequestDeletePhoneMediaAction = {
  type: PhoneMediaActionTypes.RequestDelete;
  payload: string;
};

type DeleteAction = {
  type: PhoneMediaActionTypes.Delete;
  payload: DeletePayload;
};

export type RequestUpdatePhoneMediaAction = {
  type: PhoneMediaActionTypes.RequestUpdate;
  payload: RequestUpdatePhoneMediaPayload;
};

export type RequestUploadPhoneMediaAction = {
  type: PhoneMediaActionTypes.RequestUpload;
  payload: RequestUploadPhoneMediaPayload;
};

export type PhoneMediaActions =
  | AddAction
  | RequestGetPhoneMediaAction
  | RequestDeletePhoneMediaAction
  | DeleteAction
  | RequestUpdatePhoneMediaAction
  | RequestUploadPhoneMediaAction;

export const requestGetPhoneMedia = () => ({
  type: PhoneMediaActionTypes.RequestGet,
});

export const addPhoneMedia = (payload: AddPayload): AddAction => ({
  type: PhoneMediaActionTypes.Add,
  payload,
});

const serverGet = () =>
  CustomAxios.get('support/v1/phonemedia/list').then(getResponseData);

const requestGetPhoneMediaSaga = createRequestSaga({
  key: PhoneMediaActionTypes.RequestGet,
  onError: () => 'Failed to get media files. Please refresh the page.',
  displayErrors: true,
  saga: function* () {
    let media = yield call(serverGet);
    media = orderBy(media, ['CreatedAt'], 'desc');
    const locationId: ReturnType<typeof selectCurrentLocationId> = yield select(
      selectCurrentLocationId
    );
    yield all([
      put(addMedia({ key: 'MediaID', media })),
      put(addPhoneMedia({ locationId, media })),
    ]);
  },
});

export const getPhoneMedia = (): PhoneMediaActions => ({
  type: PhoneMediaActionTypes.RequestGet,
});

export const deletePhoneMedia = (payload: string): RequestDeletePhoneMediaAction => ({
  type: PhoneMediaActionTypes.RequestDelete,
  payload,
});

export const removePhoneMedia = (payload: DeletePayload): DeleteAction => ({
  type: PhoneMediaActionTypes.Delete,
  payload,
});

const serverDelete = (id: string) =>
  CustomAxios.delete(`support/v1/phonemedia/delete/${id}`);

const requestDeletePhoneMedia = createRequestSaga<RequestDeletePhoneMediaAction>({
  key: PhoneMediaActionTypes.RequestDelete,
  onError: () => 'Failed to delete media file. Please refresh the page.',
  displayErrors: true,
  saga: function* ({ payload }) {
    yield call(serverDelete, payload);
    const locationId: ReturnType<typeof selectCurrentLocationId> = yield select(
      selectCurrentLocationId
    );
    yield all([
      put(deleteMedia(payload)),
      put(removePhoneMedia({ locationId, mediaId: payload })),
    ]);
  },
});

export const updatePhoneMedia = (
  payload: RequestUpdatePhoneMediaPayload
): RequestUpdatePhoneMediaAction => ({
  type: PhoneMediaActionTypes.RequestUpdate,
  payload,
});

const serverUpdate = ({ mediaId, fileName }: RequestUpdatePhoneMediaPayload) =>
  CustomAxios.put(`support/v1/phonemedia/${mediaId}`, { fileName });

const requestUpdatePhoneMedia = createRequestSaga<RequestUpdatePhoneMediaAction>({
  key: PhoneMediaActionTypes.RequestDelete,
  onError: () => 'File failed to update. Please refresh the page and try again.',
  displayErrors: true,
  saga: function* ({ payload }) {
    yield call(serverUpdate, payload);
    yield put(
      updateMedia({
        id: payload.mediaId,
        updates: { FileName: payload.fileName },
      })
    );
  },
});

export const uploadPhoneMedia = (
  payload: RequestUploadPhoneMediaPayload
): RequestUploadPhoneMediaAction => ({
  type: PhoneMediaActionTypes.RequestUpload,
  payload,
});

const serverUpload = ({ phoneMedia, fileName }: RequestUploadPhoneMediaPayload) => {
  const data = new FormData();

  data.append('media-file', phoneMedia);

  if (fileName) {
    data.append('file-name', fileName);
  }

  return CustomAxios.post('support/v1/phonemedia/upload', data).then(getResponseData);
};

const requestUploadPhoneMedia = createRequestSaga<RequestUploadPhoneMediaAction>({
  key: PhoneMediaActionTypes.RequestUpload,
  onError: () => 'Failed to upload media file. Please refresh the page and try again.',
  displayErrors: true,
  saga: function* ({ payload }) {
    const response = yield call(serverUpload, payload);
    const locationId: ReturnType<typeof selectCurrentLocationId> = yield select(
      selectCurrentLocationId
    );
    const media: PhoneMedia = {
      MediaID: response.ID,
      FileName: payload.fileName || payload.phoneMedia.name,
      FilePath: response.HTTPPath,
      LocationID: locationId,
      MediaType: '',
      ContentType: '',
      CreatedAt: new Date().toISOString(),
      DeletedAt: '',
    };
    yield all([
      put(addMedia({ key: 'MediaID', media })),
      put(addPhoneMedia({ locationId, media })),
    ]);
  },
});

export const phoneMediaSaga = function* () {
  yield all([
    takeLatest(PhoneMediaActionTypes.RequestGet, requestGetPhoneMediaSaga),
    takeLatest(PhoneMediaActionTypes.RequestDelete, requestDeletePhoneMedia),
    takeLatest(PhoneMediaActionTypes.RequestUpdate, requestUpdatePhoneMedia),
    takeLatest(PhoneMediaActionTypes.RequestUpload, requestUploadPhoneMedia),
  ]);
};
