import React from 'react';
import { get } from 'lodash';
import { css } from 'emotion';
import { AnalyticsOnboardingRequest } from '../../../redux/actions/analytics/onboarding/find-onboarding-request/find-onboarding-request.action';
import {
  Button,
  DateTimeInput,
  Option,
  Loader,
  Select,
  Textarea,
} from '@weave/platform-ui-components';
import { useEffect, useState } from 'react';
import { SelectionType } from '@weave/platform-ui-components/dist/modules/calendar/calendar.component';
import moment, { Moment } from 'moment';
import { OnboardingStatusIndicator } from '../onboarding-status-indicator/onboarding-status-indicator.component';

export type AnalyticsOnboardingPageProps = {
  locationId: string;
  isAnalyticsAdmin: boolean;
  onboardingRequest?: AnalyticsOnboardingRequest;
  loading: boolean;
  requestSubmitted: boolean;
  createAnalyticsOnboardingRequest: (a: { demoDate?: Moment; note?: string }) => void;
  findAnalyticsOnboardingRequest: () => void;
  updateAnalyticsOnboardingRequest: (a: {
    demoDate?: Moment;
    saleDate?: Moment;
    status?: string;
    note?: string;
  }) => void;
};

export const AnalyticsOnboardingPage = ({
  locationId,
  isAnalyticsAdmin,
  onboardingRequest,
  loading,
  requestSubmitted,
  createAnalyticsOnboardingRequest,
  findAnalyticsOnboardingRequest,
  updateAnalyticsOnboardingRequest,
}: AnalyticsOnboardingPageProps) => {
  const daysOut = moment().hours() > 17 ? 4 : 3; // after 5pm add an extra day
  const [demoDate, setDemoDate] = useState<Moment | undefined>();
  const [saleDate, setSaleDate] = useState<Moment | undefined>();
  const [status, setStatus] = useState<string>();
  const [minDemoDate, setMinDemoDate] = useState<Moment | undefined>(
    moment().add(daysOut, 'days')
  );
  const [maxDemoDate, setMaxDemoDate] = useState<Moment | undefined>(
    moment().add(21, 'days')
  );
  const [note, setNote] = useState('');

  useEffect(() => {
    findAnalyticsOnboardingRequest();
  }, [locationId]);

  useEffect(() => {
    if (!onboardingRequest) {
      return;
    }

    if (!onboardingRequest.demoDate) {
      setDemoDate(undefined);
    } else {
      setDemoDate(moment(onboardingRequest.demoDate));
    }
    if (!onboardingRequest.saleDate) {
      setSaleDate(undefined);
    } else {
      setSaleDate(moment(onboardingRequest.saleDate));
    }
    setNote(onboardingRequest.note || '');
    setStatus(onboardingRequest.status);

    if (isAnalyticsAdmin) {
      setMinDemoDate(undefined);
      setMaxDemoDate(undefined);
    }
  }, [onboardingRequest]);

  return (
    <div className="container-fluid">
      <div className="main">
        <div className="title-group">
          <div className="page-title">Analytics Onboarding</div>
          <div
            className={css`
              display: flex;
              * {
                margin: 0 10px;
              }
            `}
          />
          {!loading && (
            <OnboardingStatusIndicator
              status={get(onboardingRequest, 'status', 'none')}
            />
          )}
        </div>
        <section
          className={css`
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          {loading && <Loader />}
          {!loading && requestSubmitted && (
            <section>
              <p
                className={css`
                  font-size: 18px;
                  font-weight: 'bold';
                `}
              >
                Your request has been submitted
              </p>
            </section>
          )}
          {!loading && !requestSubmitted && (
            <section>
              <div
                className={css`
                  font-size: 18px;
                  font-weight: 'bold';
                `}
              >
                Request to Onboard Analytics
              </div>
              <DateTimeInput
                className={css`
                  margin: 20px 0;
                `}
                placeholder="Demo Date"
                selectionType={SelectionType.DateTime}
                minDate={minDemoDate}
                maxDate={maxDemoDate}
                value={demoDate}
                selectYearMonth
                onChange={(v) => setDemoDate(v as Moment)}
              />
              {onboardingRequest && (
                <DateTimeInput
                  className={css`
                    margin: 20px 0;
                  `}
                  placeholder="Sale Date"
                  selectionType={SelectionType.Date}
                  value={saleDate}
                  selectYearMonth
                  onChange={(v) => setSaleDate(v as Moment)}
                />
              )}
              <Textarea
                className={css`
                  margin: 20px 0;
                `}
                value={note}
                onChange={(e) => setNote(e.target.value)}
                placeholder="Notes"
                label="Notes"
              />
              {isAnalyticsAdmin && onboardingRequest && (
                <Select
                  placeholder="Status"
                  value={status}
                  onChange={(v) => setStatus(v)}
                >
                  <Option value="requested">Requested</Option>
                  <Option value="pending_sync"> Pending Sync</Option>
                  <Option value="etl_enabled">ETL Enabled</Option>
                  <Option value="demo_ready">Demo Ready</Option>
                  <Option value="complete">Complete</Option>
                </Select>
              )}
              <Button
                className={css`
                  margin: 20px 0;
                  width: 100%;
                `}
                variant="rounded"
                onClick={() =>
                  onboardingRequest
                    ? updateAnalyticsOnboardingRequest({
                        status,
                        demoDate,
                        note,
                        saleDate,
                      })
                    : createAnalyticsOnboardingRequest({ demoDate, note })
                }
              >
                {onboardingRequest ? 'Update' : 'Submit'} Request
              </Button>
            </section>
          )}
        </section>
      </div>
    </div>
  );
};
