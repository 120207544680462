import { Filters, FiltersRequestResponse } from './audit.types';
import { CustomAxios, getResponseData } from '../../redux/axios';
import {
  auditActionsMockData,
  auditResourcesMockData,
  getAuditQueryMockData,
} from './mockData';

const queryEndpoint = '/audit-query/v1/query';
const actionEndpoint = '/audit-query/v1/action';
const usersEndpoint = '/support/v1/users/?search=';
const resourceEndpoint = '/audit-query/v1/resource';
const locationEndpoint = '/support/v1/locations/search/';
const locationUsersEndpoint = '/support/v1/locations/users';

export const AuditQueries = {
  queries: {
    events: async (params) => {
      const filters = params.queryKey[1] as Filters;
      const { pageParam } = params;
      const pagination_key = pageParam?.paginationKey ?? '';

      const reqFilters: FiltersRequestResponse = {
        startTime: filters?.Time ? filters.Time[0].start : '',
        endTime: filters?.Time ? filters.Time[0].end : '',
        actions:
          filters?.Action &&
          filters.Action.map((a) => {
            return a.display;
          }),
        userIds:
          filters?.User &&
          filters.User.map((u) => {
            return u.id;
          }),
        resources:
          filters?.Resource &&
          filters.Resource.map((r) => {
            return r.display;
          }),
        locationIds:
          filters?.Location &&
          filters.Location.map((l) => {
            return l.id;
          }),
      };
      return await CustomAxios.post(queryEndpoint, {
        page_size: 25,
        pagination_key: pagination_key ?? '',
        filters: { ...reqFilters },
      }).then(getResponseData);
    },

    actions: async (): Promise<string[]> =>
      await CustomAxios.post(actionEndpoint).then(getResponseData),

    resources: async (): Promise<string[]> =>
      await CustomAxios.post(resourceEndpoint).then(getResponseData),

    locations: async (locationSearch) => {
      const query = locationSearch.queryKey[1];
      if (!query) {
        return;
      }
      return await CustomAxios.get(`${locationEndpoint}${query}`).then(getResponseData);
    },

    childLocations: async (locationId: string) => {
      return await CustomAxios.get(`/support/v1/locations/${locationId}/children`).then(
        getResponseData
      );
    },

    locationUsers: async () =>
      await CustomAxios.get(locationUsersEndpoint).then(getResponseData),

    users: async (userSearch) => {
      const query = userSearch.queryKey[1];
      if (!query) return;
      return await CustomAxios.get(`${usersEndpoint}${query}`).then(getResponseData);
    },
  },
};

export const MockAuditApi = {
  queries: {
    events: async (params) => {
      const filters = params.queryKey[1] as Filters;
      const { pageParam } = params;
      const pagination_key = pageParam?.paginationKey ?? '';

      const reqFilters: FiltersRequestResponse = {
        startTime: filters?.Time ? filters.Time[0].start : '',
        endTime: filters?.Time ? filters.Time[0].end : '',
        actions:
          filters?.Action &&
          filters.Action.map((a) => {
            return a.display;
          }),
        userIds:
          filters?.User &&
          filters.User.map((u) => {
            return u.id;
          }),
        resources:
          filters?.Resource &&
          filters.Resource.map((r) => {
            return r.display;
          }),
        locationIds:
          filters?.Location &&
          filters.Location.map((l) => {
            return l.id;
          }),
      };
      return await getAuditQueryMockData(reqFilters, pagination_key).then((res) => {
        return res.data;
      });
    },
    actions: () => auditActionsMockData,
    resources: () => auditResourcesMockData,
  },
};
