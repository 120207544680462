import { connect } from 'react-redux';
import {
  deleteUser,
  getAvailableJobTitles,
  getAvailableRoles,
  getUsers,
  setUserSearchText,
  userSelect,
} from '../../redux/actions/users';
import UsersList from './users-list.component';

export const UsersListContainer = connect(
  (state: any, ownProps: any) => ({
    locationId: ownProps.match.params.id,
    users: state.users.data,
    loading: state.users.loading,
    availableRoles: state.availableRoles.data,
    selectedUser: state.userSelect.data,
    userFound: state.userSearch.userFound,
    userSearchText: state.userSearch.userSearchText,
    availableJobTitles: state.availableJobTitles.data,
    getJobTitles: state.jobTitles.data,
  }),
  {
    getUsers,
    deleteUser,
    userSelect,
    getAvailableRoles,
    setUserSearchText,
    getAvailableJobTitles,
  }
)(UsersList);
