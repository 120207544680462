import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { CustomAxios } from '../../../redux/axios';

export interface ResourceProps {
  url: string;
  deps?: Array<any>;
  emptyState?: any;
  suppressFetch?: boolean;
  transformData?: (response: any) => void;
}

const fetchResource = async (
  url: string,
  setResources: Dispatch<any>,
  setError: Dispatch<SetStateAction<Error | null>>,
  setLoading: Dispatch<SetStateAction<boolean>>,
  transformData?: (response: any) => void
) => {
  try {
    setError(null);
    setLoading(true);
    const response = await CustomAxios.get(url);
    if (!transformData) {
      if (response && response.data && typeof response.data.data !== 'undefined') {
        setResources(response.data.data);
      } else {
        setResources(response.data);
      }
    } else {
      setResources(transformData(response));
    }
  } catch (error: any) {
    setError(error);
  }

  setLoading(false);
};

export const useResource = <T extends any>({
  url,
  deps = [],
  emptyState = [],
  suppressFetch = false,
  transformData,
}: ResourceProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setResources] = useState<T>(emptyState);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!suppressFetch) {
      fetchResource(url, setResources, setError, setLoading, transformData);
    }
  }, [url, ...deps]);

  const refresh = () => {
    fetchResource(url, setResources, setError, setLoading, transformData);
  };

  return { data, loading, error, setResources, setLoading, setError, refresh };
};
