import { Product } from '../sales-pricing.types';
import ListsIcon from '../../../images/sales-pricing/icon-lists.svg';
import FaxIcon from '../../../images/sales-pricing/icon-fax.svg';
import PaymentsIcon from '../../../images/sales-pricing/icon-payments.svg';
import AnalyticsIcon from '../../../images/sales-pricing/icon-analytics.svg';
import TerminalIcon from '../../../images/sales-pricing/icon-terminal.svg';

export const addonsProductData: Product[] = [
  {
    label: 'forms',
    displayName: 'Forms',
    type: 'addon',
    price: 300,
    recurringPrice: 199,
    image: ListsIcon,
  },
  {
    label: 'fax',
    displayName: 'Fax',
    type: 'addon',
    price: undefined,
    recurringPrice: 15,
    image: FaxIcon,
  },
  {
    label: 'textToPay',
    displayName: 'Text to Pay',
    type: 'addon',
    price: undefined,
    recurringPrice: 50,
    image: PaymentsIcon,
  },
  {
    label: 'practiceAnalytics',
    displayName: 'Practice Analytics',
    type: 'addon',
    price: undefined,
    recurringPrice: 175,
    image: AnalyticsIcon,
  },
  {
    label: 'extraPaymentTerminal',
    displayName: 'Extra Payment Terminal',
    type: 'addon',
    price: undefined,
    recurringPrice: 0,
    image: TerminalIcon,
  },
];
