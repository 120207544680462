import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import { enableAnalyticsEtl, enableAnalyticsEtlSaga } from './enable-analytics-etl';
import { enableAnalyticsUi, enableAnalyticsUiSaga } from './enable-analytics-ui';
import { upgradeSyncApp, upgradeSyncAppSaga } from './upgrade-sync-app';
import {
  getAnalyticsSummarySaga,
  getAnalyticsSummaryReducer,
  getAnalyticsSummary,
} from './get-analytics-summary';
import {
  analyticsOnboardingReducer,
  analyticsOnboardingSaga,
  createAnalyticsOnboardingRequest,
  updateAnalyticsOnboardingRequest,
  findAnalyticsOnboardingRequest,
  selectAnalyticsOnboardingRequestSubmitted,
  selectAnalyticsOnboardingLoading,
  selectAnalyticsOnboardingRequest,
} from './onboarding';
import {
  analyticsOnboardingInitialState,
  AnalyticsOnboardingState,
} from './onboarding/reducer';
import {
  analyticsSummaryInitialState,
  AnalyticsSummaryState,
} from './get-analytics-summary/get-analytics-summary.action';

export {
  enableAnalyticsEtl,
  enableAnalyticsUi,
  upgradeSyncApp,
  getAnalyticsSummary,
  createAnalyticsOnboardingRequest,
  updateAnalyticsOnboardingRequest,
  findAnalyticsOnboardingRequest,
  selectAnalyticsOnboardingRequest,
  selectAnalyticsOnboardingLoading,
  selectAnalyticsOnboardingRequestSubmitted,
};

export type AnalyticsState = {
  onboarding: AnalyticsOnboardingState;
  summary: AnalyticsSummaryState;
};

export const analyticsInitialState = {
  onboarding: { ...analyticsOnboardingInitialState },
  summary: { ...analyticsSummaryInitialState },
};

export const analyticsReducer = combineReducers<AnalyticsState, any>({
  onboarding: analyticsOnboardingReducer,
  summary: getAnalyticsSummaryReducer,
});

export const analyticsSaga = function* () {
  yield all([
    enableAnalyticsUiSaga(),
    enableAnalyticsEtlSaga(),
    upgradeSyncAppSaga(),
    getAnalyticsSummarySaga(),
    analyticsOnboardingSaga(),
  ]);
};
