import { css } from 'emotion';

export const section = css`
  margin-top: 32px;
`;

export const subtitleContainer = css`
  align-items: center;
  display: flex;
`;

export const subtitleIcon = css`
  margin-right: 16px;
`;

export const subtitleHelpText = css`
  display: block;
  margin-top: 8px;

  label: subtitleHelpText;
`;

export const greetingContainer = css`
  align-items: center;
  display: flex;
  margin-top: 24px;
`;

export const mediaDropdown = css`
  display: inline-block;
  width: 300px;

  div {
    margin-top: 0;
  }
`;

export const audio = css`
  height: 40px;
  margin-left: 70px;
`;
