import React from 'react';
import { Route } from 'react-router-dom';
import { AnalyticsOnboardingPageContainer } from './analytics-onboarding-page';
import { AnalyticsROIScorecardPage } from './analytics-roi-scorecard-page';
import { AnalyticsSettingsPageContainer } from './analytics-settings-page';
import { ANALYTICS } from '../../constants/paths';
import { CrawlSwitch } from '../../routers/crawl-switch';

export const AnalyticsPage = () => (
  <CrawlSwitch>
    <Route
      path={ANALYTICS.onboarding}
      component={() => <AnalyticsOnboardingPageContainer />}
    />
    <Route
      path={ANALYTICS.roiScorecard}
      component={() => <AnalyticsROIScorecardPage />}
    />
    <Route
      path={ANALYTICS.settings}
      component={() => <AnalyticsSettingsPageContainer />}
    />
  </CrawlSwitch>
);
