import React from 'react';
import { Bar } from 'react-chartjs-2';
import { css } from 'emotion';
import { MessageQueueSummary } from './message-queue-summary.component';
import {
  labels,
  formatBarChart,
  formatSummaryTotals,
} from './delivery-summary-formatters';

const chartContainer = css`
  label: chartContainer;
  display: flex;
  width: calc(70% + 280px);
  margin: auto;
`;

const chartStyle = css`
  label: chartStyle;
  width: 70%;
  height: 440px;
  margin: auto;
`;

const options = {
  legend: { display: false },
  responsive: true,
  maintainAspectRatio: false,
};

interface DeliverySummary {
  Count: number;
  Date: string;
  DeliveryMethod: string;
  Status: string;
}

interface DeliverySummaryChart {
  deliverySummary: DeliverySummary[];
}

export const DeliveryReportChart = ({ deliverySummary }: DeliverySummaryChart) => {
  const summaryTotals = formatSummaryTotals(deliverySummary);
  const barChartDataSet = {
    labels,
    datasets: formatBarChart(deliverySummary),
  };

  return (
    <div className={chartContainer}>
      <div className={chartStyle}>
        <Bar height={100} width={100} data={barChartDataSet} options={options} />
      </div>
      <MessageQueueSummary summaryTotals={summaryTotals} />
    </div>
  );
};
