import { handleActions } from 'redux-actions';
import {
  signIn,
  signInSuccess,
  signOut,
  signInRefresh,
  updateAuthToken,
} from './auth.action';
import { AuthReducerState } from './auth.types';
import { mockAuthedUser } from './auth.helpers';

export { mockAuthedUser };

export const authReducerInitialState: AuthReducerState = {
  token: null,
  isRefreshing: false,
  user: mockAuthedUser(),
  isLoading: false,
};

export const authReducer = handleActions<AuthReducerState, any>(
  {
    [signIn.toString()]: (state, action) => {
      return Object.assign({}, state, { isLoading: true, isRefreshing: false });
    },
    [updateAuthToken.toString()]: (state, action) =>
      Object.assign({}, state, action.payload),

    [signInSuccess.toString()]: (state, action) => {
      const token = (action.payload && action.payload.token) || '';
      try {
        const user = JSON.parse(atob(token.split('.')[1]));
        return {
          ...authReducerInitialState,
          token,
          user,
          isRefreshing: false,
          isLoading: false,
        };
      } catch {
        return authReducerInitialState;
      }
    },
    [signInRefresh.toString()]: (state) => {
      return Object.assign({}, state, { isRefreshing: true });
    },
    [signOut.toString()]: () => {
      return authReducerInitialState;
    },
  },
  authReducerInitialState
);
