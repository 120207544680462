import React from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { ContentLoader } from '@weave/design-system';

export const InlineLoader = () => {
  return (
    <ContentLoader
      css={css`
        display: inline-flex;
        margin-left: ${theme.spacing(1)};

        .lds-ring {
          height: 24px;
          width: 24px;

          div {
            margin: 0 !important;
          }
        }
      `}
    />
  );
};
