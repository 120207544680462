import { createAction, handleActions } from 'redux-actions';
import { call, put } from 'redux-saga/effects';
import { CustomAxios } from '../../axios';
import {
  StatusMap,
  OptoSettings,
  StatusMapSettings,
  OptoStatusMapping,
} from './status-mappings.types';

export const statusOptions = {
  '': '',
  Confirmed: 'Confirmed',
  Unconfirmed: 'Unconfirmed',
  Attempted: 'Attempted Contact',
  'In Office': 'In Office',
  Canceled: 'Canceled',
  Complete: 'Complete',
};

export const requestError = createAction<Error>('SETTINGS_FAILURE');
export const clearLocationError = createAction('CLEAR_LOCATION_ERROR');
export const clearLocationSuccess = createAction('CLEAR_LOCATION_SUCCESS');

export const setDefaultSettings = createAction('DEFAULT_SETTINGS');

export const fetchSettings = createAction('SETTINGS');
export const fetchSettingsSuccess = createAction('SETTINGS_SUCCESS');

const validateAndParseJson = (text) => {
  try {
    return JSON.parse(text);
  } catch {
    return false;
  }
};

export const handleFetchSettings = function* () {
  try {
    const response = yield call(CustomAxios.get, 'client-api/settings/?set=adapter');

    // `value` key holds a string that needs to be converted to JSON
    const data = response.data.map((setting) => {
      let value = validateAndParseJson(setting.value);
      if (!value) {
        value = validateAndParseJson(setting.value.replace(/'/g, '"'));
      }
      return { ...setting, value };
    });
    yield put(fetchSettingsSuccess(data));
  } catch (error: any) {
    yield put(requestError(error));
  }
};

export const updateSettings = createAction<StatusMap>('UPDATE_SETTINGS');
export const updateSettingsSuccess = createAction('UPDATE_SETTINGS_SUCCESS');

export const handleUpdateSettings = function* (action) {
  try {
    const postBody = { ...action.payload };
    Object.keys(postBody.value).forEach((key) => {
      if (postBody.value[key] === '') {
        delete postBody.value[key];
      }
    });
    postBody.value = JSON.stringify(postBody.value);
    const response = yield call(
      CustomAxios.post,
      'client-api/settings/?set=adapter',
      postBody
    );
    yield put(updateSettingsSuccess(response.data));
    yield put(fetchSettings());
  } catch (error: any) {
    yield put(requestError(error));
  }
};

export const fetchOptoSettings = createAction('OPTO_SETTINGS');
export const fetchOptoSettingsSuccess = createAction('OPTO_SETTINGS_SUCCESS');
export const fetchOptoSettingsFailure = createAction('OPTO_SETTINGS_FAILURE');

export const handleFetchOptoSettings = function* () {
  try {
    const statusList = yield call(
      CustomAxios.get,
      'support/v1/events/descriptions/?eventType=order'
    );
    const optoOrderStatuses = yield call(
      CustomAxios.get,
      'support/v1/settings/order-statuses'
    );
    const optoStatusMapping = yield call(
      CustomAxios.get,
      'support/v1/settings/order-status-mappings'
    );
    const data = {
      statusList: statusList.data.data,
      optoOrderStatuses: optoOrderStatuses.data.data,
      optoStatusMapping: optoStatusMapping.data.data,
    };
    yield put(fetchOptoSettingsSuccess(data));
  } catch {
    yield put(fetchOptoSettingsFailure());
  }
};

export const updateOptoSettings =
  createAction<OptoStatusMapping[]>('UPDATE_OPTO_SETTINGS');
export const updateOptoSettingsSuccess = createAction('UPDATE_OPTO_SETTINGS_SUCCESS');

export const handleUpdateOptoSettings = function* (action) {
  try {
    const response = yield call(
      CustomAxios.post,
      'support/v1/settings/order-status-mappings/',
      action.payload
    );
    yield put(updateOptoSettingsSuccess(response.data));
    yield put(fetchOptoSettings());
  } catch (error: any) {
    yield put(requestError(error));
  }
};

export type StatusMappingState = {
  optoSettings: OptoSettings;
  settings: StatusMapSettings;
  loading: boolean;
  error?: Error | string;
  success?: string;
};
export const initialStatusMappingState: StatusMappingState = {
  optoSettings: {
    statusList: [],
    optoOrderStatuses: [],
    optoStatusMapping: [],
  },
  settings: [],
  loading: true,
};

const isLoading = (state) => ({ ...state, loading: true, success: null });
const handleError = (state, action) => ({
  ...state,
  error: action.payload,
  loading: false,
  success: null,
});

export const statusMappingSettingsReducer = handleActions<StatusMappingState, any>(
  {
    [setDefaultSettings.toString()]: () => ({ ...initialStatusMappingState }),
    [fetchSettings.toString()]: (state) => isLoading(state),
    [fetchSettingsSuccess.toString()]: (state, action) => ({
      ...state,
      optoSettings: null,
      settings: action.payload,
      loading: false,
      error: null,
    }),
    [fetchOptoSettingsFailure.toString()]: (state, action) => ({
      ...state,
      optoSettings: {
        statusList: [],
        optoOrderStatuses: [],
        optoStatusMapping: [],
      },
      loading: false,
    }),
    [updateSettings.toString()]: (state) => isLoading(state),
    [updateSettingsSuccess.toString()]: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      success: 'updated settings',
    }),
    [fetchOptoSettings.toString()]: (state) => isLoading(state),
    [fetchOptoSettingsSuccess.toString()]: (state, action) => ({
      ...state,
      optoSettings: action.payload || initialStatusMappingState.optoSettings,
      loading: false,
      error: null,
    }),

    [updateOptoSettings.toString()]: (state) => isLoading(state),
    [updateOptoSettingsSuccess.toString()]: (state, action) => ({
      ...state,
      updateResponse: action.payload,
      loading: false,
      error: null,
      success: 'updated opto settings',
    }),

    [requestError.toString()]: (state, action) => handleError(state, action),
    [clearLocationSuccess.toString()]: (state) => ({ ...state, success: null }),
    [clearLocationError.toString()]: (state) => ({ ...state, error: null }),
  },
  { ...initialStatusMappingState }
);
