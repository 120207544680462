import { connect } from 'react-redux';

import { selectWeaveUserAcls } from '../../../redux/actions/auth/auth.selectors';
import { selectCurrentLocationId } from '../../../redux/actions/location/current-location';
import { AnalyticsOnboardingPage } from './analytics-onboarding-page.component';
import {
  createAnalyticsOnboardingRequest,
  findAnalyticsOnboardingRequest,
  selectAnalyticsOnboardingLoading,
  selectAnalyticsOnboardingRequest,
  selectAnalyticsOnboardingRequestSubmitted,
  updateAnalyticsOnboardingRequest,
} from '../../../redux/actions/analytics';
import { CoreACLs } from '../../../redux/actions/auth/auth.types';

export const AnalyticsOnboardingPageContainer = connect(
  (state: any) => ({
    isAnalyticsAdmin: selectWeaveUserAcls(state).indexOf(CoreACLs.ANAlYTICSADMIN) >= 0,
    locationId: selectCurrentLocationId(state),
    loading: selectAnalyticsOnboardingLoading(state),
    requestSubmitted: selectAnalyticsOnboardingRequestSubmitted(state),
    onboardingRequest: selectAnalyticsOnboardingRequest(state),
  }),
  {
    createAnalyticsOnboardingRequest,
    findAnalyticsOnboardingRequest,
    updateAnalyticsOnboardingRequest,
  }
)(AnalyticsOnboardingPage);
