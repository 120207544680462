import React, { useState } from 'react';
import { css } from 'emotion';
import { Doughnut as DoughnutChart } from 'react-chartjs-2';

import { MetricOption } from '../metrics';
import {
  chartAndTotalWrapper,
  totalCenterText,
  chartWrapper,
  doughnutCenterTitle,
  doughnutCenterValue,
  legendWrapper,
  legendStyles,
  tooltipStyles,
} from './styles';

interface CustomTooltip {
  show: boolean;
  component: JSX.Element | null;
  dataPoints:
    | {
        xLabel: string;
        yLabel: string;
        index: number;
        datasetIndex: number;
        x: number;
        y: number;
      }[]
    | null;
}

interface DoughnutProps {
  data: any;
  metric: MetricOption;
}

export const Doughnut = ({ data, metric }: DoughnutProps) => {
  const [tooltip, setTooltip] = useState<CustomTooltip>({
    show: false,
    component: null,
    dataPoints: null,
  });

  if (!data) {
    return null;
  }

  const chartData = metric.graphConfig.assembleChartData(data);

  if (!chartData) {
    return null;
  }

  const getTooltip = () => {
    if (tooltip.show) {
      return tooltip.component;
    } else {
      return null;
    }
  };

  const chartJsOptions = {
    cutoutPercentage: 65,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
      // Use Chartjs' custom tooltip function to get relevant data needed to display our
      // own custom tooltip.
      custom: function (tooltipModel) {
        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          setTooltip({
            ...tooltip,
            show: false,
          });

          return;
        }

        const position = (this as any)._chart.canvas.getBoundingClientRect();

        if (tooltipModel.body) {
          const tooltips = chartData.chartTooltipData;
          const tooltipData = tooltips[tooltipModel.dataPoints[0].index];

          if (
            !tooltip.show ||
            (tooltip.dataPoints &&
              tooltipModel.dataPoints[0].index !== tooltip.dataPoints[0].index)
          ) {
            const styles = css`
              position: absolute;
              left: ${position.left + window.pageXOffset + tooltipModel.caretX}px;
              top: ${position.top + window.pageYOffset + tooltipModel.caretY}px;
              ${tooltipStyles}
            `;

            setTooltip({
              show: true,
              dataPoints: tooltipModel.dataPoints,
              component: (
                <div className={styles}>
                  {tooltipData.data.map((item, index) => (
                    <div key={index}>{item}</div>
                  ))}
                </div>
              ),
            });
          }
        }
      },
    },
  };

  return (
    <div>
      {/* Doughnut Center Text */}
      <div className={chartAndTotalWrapper}>
        <div className={totalCenterText}>
          <span className={doughnutCenterTitle}>Total</span>
          <span className={doughnutCenterValue}>{chartData.total}</span>
        </div>

        {/* Doughnut Chart */}
        <div className={chartWrapper}>
          <DoughnutChart
            height={180}
            data={chartData.chartDataProp}
            options={chartJsOptions}
          />
        </div>
      </div>

      {/* Chart Legend */}
      <div className={legendWrapper}>
        {chartData.chartLegend.map((legendRow, i) => (
          <div
            key={i}
            className={css`
              display: grid;
              grid-template-columns: repeat(${legendRow.data.length + 1}, 1fr);
              ${legendStyles}
            `}
          >
            <div
              className={css`
                height: 4px;
                width: 32px;
                border-radius: 2px;
                background-color: ${legendRow.color};
              `}
            />
            {legendRow.data.map((legendItem, j) => (
              <div key={i + j}>{legendItem}</div>
            ))}
          </div>
        ))}

        {/* Tooltip */}
        {getTooltip()}
      </div>
    </div>
  );
};
