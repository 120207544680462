import { css } from 'emotion';
import { weaveBlue } from '../../../shared/styles/colors';

export const flexStyle = css`
  label: felxStyle;
  display: flex;
`;

export const infoBox = css`
  label: infoBox;
  display: flex;
`;

export const infoBoxLeft = css`
  label: infoBoxLeft;
  text-align: right;
  font-weight: bold;
  width: 30%;
  margin: 2px;
`;

export const infoBoxRight = css`
  label: infoBoxRight;
  width: 70%;
  margin: 3px;
  margin-left: 15px;
`;

export const smsInfoComponent = css`
  text-transform: capitalize;
  label: smsInfoComponent;
  width: 40%;
  height: 300px;
`;

export const smsInfoBox = css`
  label: smsInfoBox;
  width: 100%;
  margin: 5px;
`;

export const notificationSettingBox = css`
  text-transform: capitalize;
  label: notificationSettingBox;
  width: 60%;
  margin: 5px;
  padding-right: 10%;
  overflow-y: auto;
`;

export const header = css`
  label: header;
  text-align: center;
  font-weight: bold;
`;

export const blurAutoSetting = css`
  label: blurAutoSetting;
  color: #aaa;
`;

export const clickable = css`
  label: clickable;
  color: ${weaveBlue};
  cursor: pointer;
`;

export const linkBtn = css`
  label: detailsLink;
  width: 25%;
  border: 1px solid ${weaveBlue};
  border-radius: 25px;
  cursor: pointer;
  color: white;
  background-color: ${weaveBlue};
  text-align: center;
  &:hover {
    text-decoration: none;
    color: white;
  }
`;

export const copied = css`
  margin-left: 5px;
  label: copied;
  color: #28a745;
`;

export const error = css`
  label: error;
  color: #dc3545;
`;

export const uuid = css`
  label: uuid;
  text-transform: none;
`;
