import { connect } from 'react-redux';
import { SmsDetails } from './sms-details/sms-details.component';
import {
  resetSmsDetails,
  smsDetails,
} from '../../../redux/actions/sms/sms-details/sms-details.action';
import { selectCurrentLocationId } from '../../../redux/actions/location/current-location';
import { person, personReset } from '../../../redux/actions/patients/person.action';
import { Store } from '../../../redux/store/store.model';

interface OwnProps {
  history: any;
}

export const SmsDetailsContainer = connect(
  (store: Store, ownProps: OwnProps) => ({
    locationID: selectCurrentLocationId(store),
    smsDetails: store.smsDetails,
    personDetails: store.person.data,
    history: ownProps.history,
    commxMigrationFlag:
      store.featureFlags.data.find((flag) => flag.Name === 'commx-migration')?.Value ??
      false,
  }),
  {
    getSmsDetails: smsDetails,
    getPersonDetails: person,
    resetSmsDetails: resetSmsDetails,
    resetPersonDetails: personReset,
  }
)(SmsDetails);
