import React, { FC } from 'react';
import { css } from 'emotion';
import { Heading } from '@weave/design-system';
import { gray1, Button } from '@weave/platform-ui-components';

interface Props {
  hasRowChanges?: boolean;
  name: string;
  onSaveClick?: () => void;
  showSaveButton?: boolean;
}

export const DeviceSection: FC<Props> = ({
  hasRowChanges,
  showSaveButton = false,
  name,
  onSaveClick,
  children,
}) => {
  return (
    <section
      className={css`
        border-bottom: 1px solid ${gray1};
        padding: 24px 0 40px;

        :first-of-type {
          padding-top: 16px;
        }
      `}
    >
      <header
        className={css`
          align-items: center;
          display: flex;
          justify-content: space-between;
          margin-bottom: 24px;
        `}
      >
        <Heading
          level={2}
          className={css`
            display: inline-block;
            margin: 0;
          `}
        >
          {name}
        </Heading>
        {showSaveButton && (
          <Button
            className={css`
              display: inline-block !important;
            `}
            disabled={!hasRowChanges}
            onClick={onSaveClick}
          >
            Save
          </Button>
        )}
      </header>
      {children}
    </section>
  );
};
