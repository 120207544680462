import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectSmsHistoryData } from '../../../redux/actions/sms/sms-history.action';
import { getNotificationsSettings } from '../../../redux/actions/notifications-settings/notifications-settings.action';

const typeMap = {
  appointment: 'appointments',
  order: 'orders',
};

export const useReminderSettings = (type) => {
  const dispatch = useDispatch();
  const { notificationSettings } = useSelector(selectSmsHistoryData);

  useEffect(() => {
    if (!Object.keys(notificationSettings).length) {
      dispatch(getNotificationsSettings());
    }
  }, []);

  const cleanType = typeMap?.[type] ?? '';
  return notificationSettings?.[cleanType] ?? [];
};
