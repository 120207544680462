import { createAction } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { CustomAxios } from '../../axios';
import { showError, showSuccess } from '@weave/alert-system';

export const removeEmailFromBlacklist = createAction<string>('BLACKLIST');

export const handleRemoveEmailFromBlacklist = function* (action) {
  const emailAddress = action.payload;
  try {
    yield call(
      CustomAxios.delete,
      `support/v1/emails/blacklist/${encodeURIComponent(emailAddress)}`
    );
    yield put(showSuccess('Successfully removed email from blacklist'));
  } catch {
    yield put(showError('Failed to remove email from blacklist'));
  }
};

export const blackListSaga = function* () {
  yield all([
    takeEvery(removeEmailFromBlacklist.toString(), handleRemoveEmailFromBlacklist),
  ]);
};
