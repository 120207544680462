import { createAction, handleActions } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { CustomAxios } from '../../../../redux/axios';
import {
  SmsMetadataModel,
  SmsMetadataPayload,
} from '../../../../models/sms-metadata.model';

export type SmsMetadataResultPayload = SmsMetadataModel[];

export const smsMetadata = createAction<SmsMetadataPayload>('SMS_METADATA');
export const smsMetadataSuccess =
  createAction<SmsMetadataResultPayload>('SMS_METADATA_SUCCESS');
export const smsMetadataFailure = createAction<Error>('SMS_METADATA_FAILURE');
export const resetSmsMetadata = createAction('SMS_METADATA_RESET');

export const selectSmsMetadata = (state) => state.smsMetadata;

const relatedIdsMapping = {
  RELATED_TYPE_UNSPECIFIED: 'Unspecified',
  RELATED_TYPE_APPOINTMENT: 'Appointment',
  RELATED_TYPE_ORDER: 'Order',
  RELATED_TYPE_RECALL: 'Recall',
  RELATED_TYPE_PERSON: 'Person',
  RELATED_TYPE_MEDIA: 'Media',
  RELATED_TYPE_GROUP_QUICK_FILL: 'Group Quick Fill',
  RELATED_TYPE_GROUP_SCHEDULE: 'Group Schedule',
  RELATED_TYPE_REVIEW: 'Review',
  RELATED_TYPE_MISSED_CALL: 'Missed Call',
  RELATED_TYPE_BIRTHDAY: 'Birthday',
  RELATED_TYPE_ONLINE_SCHEDULE_REQUEST: 'Online Schedule Request',
  RELATED_TYPE_MMS_CONSENT_REQUEST: 'MMS Consent Request',
  RELATED_TYPE_SOURCE_SYSTEM: 'Source System',
};

export const handleSmsMetadata = function* (action) {
  try {
    const { smsUUID, locationId, commxMigrationFlag } = action.payload;
    const baseUrl = commxMigrationFlag ? '/sms/data/v3/sms' : '/sms/v2/sms';
    const response = yield call(
      CustomAxios.get,
      `${baseUrl}?sms_id=${smsUUID}&location_id=${locationId}`
    );

    const relatedIds = response.data.sms.relatedIds.map((item) => {
      return {
        relatedDataID: item.id,
        relatedDataType: relatedIdsMapping[item.type],
        smsID: smsUUID,
      };
    });

    yield put(smsMetadataSuccess(relatedIds));
  } catch (error: any) {
    yield put(smsMetadataFailure(error));
  }
};

export const smsMetadataSaga = function* () {
  yield all([takeEvery(smsMetadata.toString(), handleSmsMetadata)]);
};

export interface SmsMetadataStateModel {
  loading: boolean;
  data: SmsMetadataModel[];
  error?: Error;
}

const defaultState: SmsMetadataStateModel = {
  loading: false,
  data: [],
};

export const smsMetadataReducer = handleActions(
  {
    [smsMetadata.toString()]: (state) => Object.assign({}, state, { loading: true }),
    [smsMetadataSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { data: action.payload, loading: false }),
    [smsMetadataFailure.toString()]: (state, action) =>
      Object.assign({}, state, { error: action.payload, loading: false }),
    [resetSmsMetadata.toString()]: () => defaultState,
  },
  defaultState
);
