import { createSelector } from 'reselect';
import { Store } from '../../store/store.model';

export const selectPorting = (state: Store) => state.porting;

export const selectSplitPortingRequests = createSelector(
  selectPorting,
  (porting) => porting.splitPortingRequests
);

export const selectPortsList = createSelector(selectPorting, (porting) => porting.ports);
