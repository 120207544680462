import { CustomAxios, getResponseData } from '../../axios';
import { FaxProviderDIDInfo } from './fax-settings.action';

const FAX_URL = '/support/v1/fax';

export const fetchDIDInfo = (): Promise<{ DID: string }> =>
  CustomAxios.get(`${FAX_URL}/did`).then(getResponseData);

export const fetchProviderDIDInfo = (did: string): Promise<FaxProviderDIDInfo> =>
  CustomAxios.get(`${FAX_URL}/providerdidinfo/${did}`).then(getResponseData);
