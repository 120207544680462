import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { Text, NakedButton, useModalControl } from '@weave/design-system';

import {
  fetchSmsHistory,
  selectSmsHistory,
  SmsHistoryState,
} from '../../../../../../redux/actions';
import { getNotificationsSettings } from '../../../../../../redux/actions/notifications-settings/notifications-settings.action';
import { SmsModel } from '../../../../../../models/sms-history.model';
import { SmsHistoryModal } from '../sms-history.modal';
import { useDedupedData, useFilter } from './filter.hook';
import { SmsHistoryCard } from './sms-history.card';
import * as styles from '../../patient-list.style';
import { selectHasFeatureFlagEnabled } from '../../../../../../redux/actions/feature-flags';
import { Store } from '../../../../../../redux/store/store.model';

const removeButton = css`
  cursor: pointer;
  color: ${theme.colors.error};
`;

const container = css`
  width: 400px;
  overflow-y: auto;
  height: 400px;
  margin: 5px;
  margin-bottom: 26px;
  background-color: #fff;
  color: #222;
  border: 1px solid #d4dbdf;
  border-radius: 5px;
  border-bottom: none;
`;

const titleContainer = css`
  display: flex;
  justify-content: space-between;
  padding: 8px;
  p {
    font-weight: bold;
  }
`;

const duplicateContainer = css`
  margin-bottom: ${theme.spacing(2)};
  border: 2px solid ${theme.colors.gray};
  & > div {
    background-color: ${theme.colors.error}22;
    margin: 0px ${theme.spacing(1)};
  }
`;

const findDuplicateMessageBody = (smsHistoryList: SmsHistoryState) => {
  const duplicates = new Map<string, SmsHistoryState>();
  for (const message of smsHistoryList) {
    const body = message?.body ?? '';
    if (!duplicates.has(body)) {
      duplicates.set(body, [message]);
    } else {
      duplicates.set(body, [...(duplicates?.get(body) ?? []), message]);
    }
  }
  // Array of Arrays
  const dupes: SmsHistoryState[] = [];
  for (const [_, value] of duplicates) {
    if (value.length > 1) {
      dupes.push(value);
    }
  }
  return dupes;
};

export const PatientSmsHistory = (props) => {
  const { personID, locationID } = props;
  const commxMigrationFlag = useSelector((state: Store) =>
    selectHasFeatureFlagEnabled(state, 'commx-migration')
  );
  const dispatch = useDispatch();

  const [copied] = useState(false);
  const [duplicates, setDuplicates] = useState<SmsHistoryState[]>([]);
  const [displayDuplicates, setDisplayDuplicates] = useState(false);

  const smsHistoryList = useSelector(selectSmsHistory);

  const dedupedLists = useDedupedData(smsHistoryList, ['typeId', 'reminderId']);
  const {
    filters,
    filteredList: smsHistory,
    clearFilters,
    addFilter,
    removeFilter,
  } = useFilter<SmsModel>(smsHistoryList);

  const { modalProps, triggerProps } = useModalControl();

  useEffect(() => {
    dispatch(getNotificationsSettings());
  }, []);

  useEffect(() => {
    if (personID && locationID) {
      if (filters) {
        for (const key in filters) {
          removeFilter(key);
        }
      }
      dispatch(
        fetchSmsHistory({
          limit: 50,
          skip: 0,
          location_id: locationID,
          person_ids: personID,
          commxMigrationFlag,
        })
      );
    }
  }, [personID, locationID]);

  useEffect(() => {
    setDuplicates(findDuplicateMessageBody(smsHistoryList));
  }, [smsHistoryList.map((sms) => sms.createdAt).join('')]);

  const MappedSms = (smsList: SmsModel[]) =>
    smsList.map((sms: SmsModel) => <SmsHistoryCard key={sms.id} sms={sms} />);
  return (
    <div css={container}>
      <SmsHistoryModal
        modalProps={modalProps}
        filters={filters}
        dedupedLists={dedupedLists}
        addFilter={addFilter}
        removeFilter={removeFilter}
      />
      <div css={titleContainer}>
        <Text>
          SMS History
          {!!copied && (
            <Text className={styles.copied} as="span">
              Reminder ID Copied
            </Text>
          )}
        </Text>
        <div>
          {!!duplicates.length && (
            <NakedButton
              className={styles.actionBtn}
              onClick={() => setDisplayDuplicates((visible) => !visible)}
              css={css`
                color: ${theme.colors.error};
              `}
            >
              {displayDuplicates ? 'Hide' : 'Show'} Duplicates
            </NakedButton>
          )}
          {!!Object.keys(filters).length && (
            <NakedButton css={removeButton} onClick={clearFilters}>
              Remove
            </NakedButton>
          )}
          <NakedButton className={styles.actionBtn} {...triggerProps}>
            Filter
          </NakedButton>
        </div>
      </div>
      {!smsHistory.length && <p className={styles.noData}>No Data</p>}
      {!!smsHistory.length && !displayDuplicates && MappedSms(smsHistory)}
      {!!duplicates.length &&
        displayDuplicates &&
        duplicates.map((smsDuplicates: SmsModel[], index) => (
          <div key={`duplicate_${index}`} css={duplicateContainer}>
            {MappedSms(smsDuplicates)}
          </div>
        ))}
    </div>
  );
};
