export const PortType = new Map<number, string>([
  [0, 'Unkown Type'],
  [1, 'Automated'],
  [2, 'Automated Offnet'],
  [3, 'Internal'],
  [4, 'Manual On Net'],
  [5, 'Manual Off Net'],
  [6, 'Manual Toll Free'],
  [7, 'Mixed'],
]);

const debugTraceHeader = 'X-Weave-Debug-Id';
const wamPortingTrace = 'wam-porting';

export { debugTraceHeader, wamPortingTrace };
