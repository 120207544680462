type CreatedAtType = {
  seconds: number;
  nanos: number;
};

export type CheckFieldValuesType = {
  contacts: boolean;
  schedule: boolean;
};

export type AccessRuleType = {
  ID: string;
  LocationID: string;
  SourceID: string;
  ClientLocationID: string | null;
  Type: string;
};

export type ClientLocationType = {
  ClientLocationID: string;
  ExternalID: string;
  Name: string;
  SourceName?: string;
  ShortName?: ValueType;
  Address?: ValueType;
  Address2?: ValueType;
  City?: ValueType;
  PostCode?: ValueType;
  State?: ValueType;
  SourceID: string;
  CreatedAt: CreatedAtType;
  ModifiedAt: CreatedAtType;
  accessEnabled: boolean;
  accessType: string;
  accessRules?: AccessRuleType;
};

export type MultiSyncAppType = {
  Active: boolean;
  LocationID: string;
  Name: string;
  ParentID: string | null;
  PhoneTenantID: string | null;
  Slug: string;
  Type: number;
  Vertical: number;
  VerticalID: number;
  VerticalTextID: number;
  syncApps: SyncAppType[];
};

export type SyncAppType = {
  LocationID: string;
  SourceID: string;
  PM?: string;
  SourceName?: string;
  SourceType?: number;
  CreatedAt?: CreatedAtType;
  accessEnabled: boolean;
  accessType: string;
  accessRules?: AccessRuleType;
  clientLocations: ClientLocationType[];
};

export type ValueType = {
  value: string;
};

export enum AccessType {
  ContactAndSchedule = 'all',
  Contact = 'contact',
  Custom = 'custom',
  None = 'none',
}

export type SyncAppCount = {
  contactsEnabled: number;
  scheduleEnabled: number;
};

export enum SourceType {
  CSV = 1,
  CUSTOM_CONTACT = 2,
}
