import { SyncAppInterface } from '../../../components/sync-app/sync-app.types';

const falseSyncApps = ['CSV', 'CUSTOM_CONTACT'];

export const selectIntegrationsSyncApps = (state: any): SyncAppInterface[] => {
  const syncApps = state.syncApp?.data?.SyncApps;
  if (syncApps) {
    return syncApps.filter(({ PracticeManagementSystem }) => {
      return !falseSyncApps.includes(PracticeManagementSystem);
    });
  }
  return [];
};

export const selectSidebarSyncApps = (state: any): SyncAppInterface[] =>
  state.syncApp?.data?.SyncApps ?? [];

export const selectLocationHealth = (state: any): string =>
  state.syncApp?.data?.LocationHealth ?? '';

export const selectSyncAppsDOVStatus = (state: any): string =>
  state.syncApp?.dovStatuses ?? {};

export const selectSyncAppReports = (
  state: any
): { [key: string]: { [key: string]: string }[] } => state.syncApp?.reports ?? {};

export const selectLoginCredentialsUsername = (state: any): string =>
  state.syncApp?.loginCredentials?.Username ?? '';

export const selectLoginCredentialsPassword = (state: any): string =>
  state.syncApp?.loginCredentials?.Password ?? '';

export const selectIntegrationsSyncAppCount = (state: any): number =>
  selectIntegrationsSyncApps(state)?.length;

export const selectLoadingSyncApps = (state: any): boolean =>
  state.syncApp?.loading ?? false;
