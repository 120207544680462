import React from 'react';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';
import { useAlert } from '@weave/alert-system';
import { theme } from '@weave/theme-original';
import {
  ConfirmationModal,
  FormRow,
  PrimaryButton,
  Text,
  TextField,
  useForm,
  useModalControl,
} from '@weave/design-system';
import { CustomAxios } from '../../../redux/axios';
import { selectCurrentLocationId } from '../../../redux/actions/location';
import { SettingsCard } from './settings-card.component';

export const MigrateSyncApp = () => {
  const alert = useAlert();
  const { modalProps, triggerProps } = useModalControl();
  const currentLocationId = useSelector(selectCurrentLocationId);

  const {
    getFieldProps,
    isComplete,
    reset,
    values: formPropValues,
  } = useForm({
    fields: {
      parentLocationId: { type: 'text', required: true },
    },
  });

  const handleSubmit = async () => {
    try {
      await CustomAxios.post('/support/v1/multi-office/migrate', {
        ChildLocationID: currentLocationId,
        ParentLocationID: formPropValues.parentLocationId,
      });
      alert.success(
        'Migration has successfully started. Please allow up to 15 minutes for the sync-apps to fully migrate to the new parent location.'
      );
      reset();
    } catch {
      alert.error('Something went wrong. Try again later.');
    }
  };

  return (
    <SettingsCard title="Migrate Sync Apps to New Location">
      <>
        <FormRow
          cols={[50]}
          css={css`
            width: 800px;
            margin-top: ${theme.spacing(3)};
          `}
        >
          <TextField
            {...getFieldProps('parentLocationId')}
            label="Parent Location ID"
            helperText="Please input the valid location id of the new Parent Location"
          />
        </FormRow>
        <PrimaryButton
          disabled={!isComplete}
          type="button"
          onClick={() => {
            triggerProps.onClick();
          }}
          css={css`
            width: 160px;
          `}
        >
          Migrate
        </PrimaryButton>
        <ConfirmationModal
          {...modalProps}
          title="Warning"
          message="This will remove all sync apps, their data and settings, and make this location a child to a new parent."
          onConfirm={handleSubmit}
        >
          <Text
            color="error"
            textAlign="center"
            css={css`
              margin: ${theme.spacing(-1, 0, 3)};
            `}
          >
            Do not proceed unless you know what you are doing.
          </Text>
        </ConfirmationModal>
      </>
    </SettingsCard>
  );
};
