import { connect } from 'react-redux';

import { AnalyticsSettingsPage } from './analytics-settings-page.component';
import FeatureFlagModel from '../../settings/feature-flags-container/feature-flag.model';
import {
  saveFeatureFlags,
  selectFeatureFlags,
} from '../../../redux/actions/feature-flags';
import { selectWeaveUserAcls } from '../../../redux/actions/auth/auth.selectors';
import {
  upgradeSyncApp,
  enableAnalyticsEtl,
  enableAnalyticsUi,
  getAnalyticsSummary,
} from '../../../redux/actions/analytics';
import { selectAnalyticsSummary } from '../../../redux/actions/analytics/get-analytics-summary';
import { selectCurrentLocationId } from '../../../redux/actions/location/current-location';
import { selectAnalyticsSummaryLoading } from '../../../redux/actions/analytics/get-analytics-summary/get-analytics-summary.action';
import { CoreACLs } from '../../../redux/actions/auth/auth.types';

const analyticsFilter = (ff: FeatureFlagModel) => ff.Name.indexOf('analytics') >= 0;

export const AnalyticsSettingsPageContainer = connect(
  (state: any) => ({
    featureFlags: selectFeatureFlags(state).filter(analyticsFilter),
    isAnalyticsAdmin: selectWeaveUserAcls(state).indexOf(CoreACLs.ANAlYTICSADMIN) >= 0,
    locationId: selectCurrentLocationId(state),
    loading: selectAnalyticsSummaryLoading(state),
    summary: selectAnalyticsSummary(state),
  }),
  {
    saveFeatureFlags,
    upgradeSyncApp,
    enableAnalyticsEtl,
    enableAnalyticsUi,
    getAnalyticsSummary,
  }
)(AnalyticsSettingsPage);
