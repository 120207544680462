import React from 'react';
import { Access } from '../access.component';
import { accessContainer, cardContent } from '../integrations.styles';
import { SyncAppAccessCardRow } from './sync-app-access-card-row';
import {
  CheckFieldValuesType,
  ClientLocationType,
  SyncAppType,
} from './sync-app-access.types';

type Props = {
  loadingAccess: boolean;
  syncApps: SyncAppType[];
  getClientLocationName: (clientLocation: ClientLocationType) => string;
  getLocationAddress: (clientLocation: ClientLocationType) => string;
  getFieldsValue: (accessRuleType: string | undefined) => CheckFieldValuesType;
  getClientLocationHeader: (clientLocations: ClientLocationType[]) => string;
  checkForSelectAll: (clientLocations: ClientLocationType[]) => CheckFieldValuesType;
  getSourceTypeName: (syncApp: SyncAppType) => string;
};

export const SyncAppAccessCardContent = ({
  loadingAccess,
  syncApps,
  getClientLocationName,
  getFieldsValue,
  getClientLocationHeader,
  getLocationAddress,
  checkForSelectAll,
  getSourceTypeName,
}: Props) => {
  return (
    <>
      {!loadingAccess &&
        syncApps.map((syncApp, idx) => {
          return (
            <div key={idx} className={cardContent}>
              <div key={idx} className={accessContainer}>
                <Access
                  name={`${syncApp?.SourceName} (${
                    syncApp?.PM || getSourceTypeName(syncApp)
                  })`}
                  numberOfLocations={syncApp.clientLocations.length}
                  isSyncApps
                >
                  <hr />
                  <div className={cardContent}>
                    <SyncAppAccessCardRow
                      sourceName={`${syncApp?.SourceName} (${
                        syncApp?.PM || getSourceTypeName(syncApp)
                      })`}
                      locationName={getClientLocationHeader(syncApp.clientLocations)}
                      index={idx}
                      fieldValues={
                        syncApp.clientLocations.length > 0
                          ? checkForSelectAll(syncApp.clientLocations)
                          : getFieldsValue(syncApp.accessRules?.Type)
                      }
                      accessEnabled={syncApp.accessEnabled}
                      sourceType={syncApp.SourceType}
                      hasMultipleClientLocations={
                        syncApp.clientLocations.length > 0 ? true : false
                      }
                    />

                    {syncApp.clientLocations.length > 0 &&
                      syncApp.clientLocations.map((clientLocation, index) => (
                        <SyncAppAccessCardRow
                          key={clientLocation.ClientLocationID}
                          locationName={getClientLocationName(clientLocation)}
                          locationAddress={getLocationAddress(clientLocation)}
                          index={idx}
                          fieldValues={getFieldsValue(clientLocation.accessRules?.Type)}
                          accessEnabled={clientLocation.accessEnabled}
                          sourceType={syncApp.SourceType}
                          isChildLocation
                        />
                      ))}
                  </div>
                </Access>
              </div>
            </div>
          );
        })}
    </>
  );
};
