import { css } from '@emotion/core';
import { theme, WeaveTheme } from '@weave/theme-original';

export const syncAppCard = css`
  label: sync-app-card;
  background: white;
  margin: ${theme.spacing(1, 0.5, 3)};
  padding: 0;
  border-radius: ${theme.borderRadius.medium};
  box-shadow: ${theme.shadows.light};
  min-width: 768px;
  overflow: hidden;
  word-wrap: break-word;
`;

export const cardHeaderContainer = css`
  padding: ${theme.spacing(3)};
  > div {
    display: flex;
    flex-wrap: wrap;
  }
  > div:first-of-type {
    margin-bottom: ${theme.spacing(2)};
    align-items: center;
  }
`;

export const cardHeaderTitle = css`
  margin: 0 auto 0 16px;
  overflow-wrap: break-word;
`;

export const externalLinkStyle = (theme: WeaveTheme) => css`
  margin-left: ${theme.spacing(2)};
  cursor: pointer;
  color: ${theme.colors.gray[500]};
  &:hover {
    color: ${theme.colors.gray[600]};
  }
`;

export const connectionDiv = css`
  background-color: #31353d;
  border-radius: ${theme.borderRadius.small};
  padding: ${theme.spacing(0.25, 1)};
  margin-left: ${theme.spacing(2)};
  color: ${theme.colors.white};
`;

export const cardTabsHeaderContainer = (theme: WeaveTheme) => css`
  label: sync-app-tabs-header;
  display: flex;
  border-top: 1px solid ${theme.colors.gray[300]};
  > div:not(:last-of-type) {
    border-right: 1px solid ${theme.colors.gray[300]};
  }
`;

export const tabHeader = (theme: WeaveTheme) => css`
  cursor: pointer;
  border-bottom: 1px solid ${theme.colors.gray[300]};
  display: flex;
  flex: 1;
  height: 64px;
  align-items: center;
  justify-content: center;
  > svg {
    margin-right: ${theme.spacing(1)};
    height: 20px;
    width: 20px;
  }
`;

export const activeTabHeader = (theme: WeaveTheme) => css`
  > span {
    font-size: 18px;
    color: ${theme.colors.weaveBlue};
  }
  border-bottom: 3px solid ${theme.colors.weaveBlue};
`;

export const lastSyncInfoContainer = css`
  label: last-sync-info-section;
  padding: ${theme.spacing(3)};
  min-height: 160px;
`;

export const lastSyncInfoResult = css`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${theme.spacing(2)};

  > div {
    display: flex;
    flex: 0 50%;
    margin-bottom: ${theme.spacing(2)};

    p {
      flex: 1;
      margin: 0;
    }
  }
  > div:nth-of-type(odd) {
    p:nth-of-type(2) {
      flex: 2;
    }
  }
`;

export const syncStatusContainer = css`
  label: sync-status-section;
  padding: ${theme.spacing(3)};
  font-size: 16px;
  min-height: 160px;

  table {
    margin-bottom: ${theme.spacing(2)};
  }

  th {
    padding-bottom: ${theme.spacing(4)};
    padding-right: ${theme.spacing(4)};
  }

  tbody td {
    padding-bottom: ${theme.spacing(2)};
  }

  tbody tr td:nth-of-type(1) {
    padding-right: ${theme.spacing(4)};
  }
`;

export const refreshButton = (theme: WeaveTheme) => css`
  width: auto;
  padding-left: ${theme.spacing(3)};
  padding-right: ${theme.spacing(3)};
`;

export const terminalTabCommandsContainer = css`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(3, 3, 1)};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
`;

export const terminalActionsSection = css`
  label: terminal-actions-section;
  display: flex;
  align-items: center;
  min-height: 60px;
`;

export const terminalButtonsSection = css`
  display: flex;
  flex-wrap: wrap;
  margin-right: auto;

  button {
    min-width: 136px;
    margin-right: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(2)};
    padding: ${theme.spacing(0, 2)};
    width: max-content;
  }
`;

export const xIconStyle = css`
  margin-left: ${theme.spacing(2)};

  svg {
    width: 32px;
    height: 32px;
  }
`;

export const terminalTabText = css`
  font-family: 'PT Mono';
  padding: ${theme.spacing(2, 3, 3)};
  margin: 0;
  max-height: 400px;
  min-height: 160px;
  overflow: auto;
  box-shadow: none;
  white-space: pre-wrap;
`;

export const tabLoading = css`
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const alignCenter = css`
  align-items: center;
`;

export const mRightSpacing = (spacing) => css`
  margin-right: ${theme.spacing(spacing)};
`;

export const mBottomSpacing = (spacing) => css`
  margin-bottom: ${theme.spacing(spacing)};
`;

export const accessCardContainer = css`
  padding: ${theme.spacing(1)};
`;
