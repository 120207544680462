import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { orderBy } from 'lodash';

import { Store } from '../../store/store.model';
import { CallForwardingState } from './call-forwarding.types';
import {
  createCallForwardingNumber,
  getCallForwardingNumbers,
  getCallForwardingNumbersFailure,
  getCallForwardingNumbersSuccess,
} from './call-forwarding.action';
import { phoneMediaSelector } from '../phone-media/phone-media.selectors';

const defaultState: CallForwardingState = {
  forwardingNumbers: [],
  loading: false,
};

export const callForwardingReducer = handleActions<CallForwardingState, any>(
  {
    [createCallForwardingNumber.toString()]: (state: CallForwardingState, action) => ({
      ...state,
      forwardingNumbers: orderBy(
        [...state.forwardingNumbers, action.payload],
        ['name'],
        ['asc']
      ),
    }),
    [getCallForwardingNumbers.toString()]: (state: CallForwardingState) => ({
      ...state,
      loading: true,
    }),
    [getCallForwardingNumbersSuccess.toString()]: (
      state: CallForwardingState,
      action
    ) => ({
      ...state,
      forwardingNumbers: orderBy(action.payload, ['name'], ['asc']),
      loading: false,
    }),
    [getCallForwardingNumbersFailure.toString()]: (state: CallForwardingState) => ({
      ...state,
      loading: false,
    }),
  },
  defaultState
);

export const selectCallForwardingNumbers = (store: Store) =>
  store.callForwarding.forwardingNumbers;

export const selectCallForwardingLoading = (store: Store) => store.callForwarding.loading;

export const callForwardingSelector = createSelector(
  selectCallForwardingLoading,
  phoneMediaSelector,
  (loadingCallForwardingNumbers, phoneMediaState) => ({
    loadingCallForwardingNumbers,
    loadingPhoneMedia: phoneMediaState.loadingPhoneMedia,
    locationId: phoneMediaState.locationId,
    phoneMedia: phoneMediaState.phoneMedia,
  })
);
