import { useEffect, useRef } from 'react';

type callbackState = (...args: any[]) => any;

export const useInterval = (callback, delay = 60000) => {
  const savedCallback = useRef<callbackState | null>(null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const tick = () => {
    if (savedCallback.current) {
      savedCallback.current();
    }
  };

  useEffect(() => {
    const listener = setInterval(tick, delay);
    return () => clearInterval(listener);
  }, [delay]);
};
