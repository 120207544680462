import {
  ActionsResponse,
  AuditEvent,
  FiltersRequestResponse,
  QueryResponse,
  ResourceResponse,
} from './audit.types';

const mockActions: string[] = [
  'login',
  'changePassword',
  'changeRoles',
  'verifiedMobileNumber',
  'updateUser',
  'deleteAllRoles',
  'createUser',
  'deleteUser',
  'deleteRoles',
  'download-media',
  'delete-media',
  'upload-media',
  'view',
  'delete call recording',
  'download call-recording media',
  'Download call recording',
  'view-location-full',
  'create-with-new-media',
  'download',
  'create-with-existing-media',
  'disabled',
  'enabled',
  'false',
  'query',
  'update',
  'create',
  'change',
  'UpdateCallQueue',
  'CreateCallQueue',
  'IVRMenuUpdate',
  'SetHoldMusic',
  'Delete MediaFile',
  'SetOutboundSMSNumber',
  'Upload',
  'UpdateCallLeg',
  'create forwarding number',
  'MailboxGreetingDelete',
  'IVRMenuList',
  'Delete blocked number',
  'update forwarding number',
  'Delete',
  'Listing-Trees',
  'LegacySet turn on call forwarding',
  'VMOverrideSettingsUpdate',
  'delete forwarding number',
  'Updating Office Hours',
  'UpdateVMOByInstructionSetID',
  'UpdateSipDevice',
  'CreateCallGroup',
  'SetOnInstructionSet',
  'LegacyUpdate',
  'A greeting was added to a Voicemailbox',
  'Updating-Trees',
  'LegacySet',
  'voicemailbox updated',
  'UploadMedia',
  'CreateCallLeg',
  'DeleteCallLeg',
  'Updating Officehour',
  'create blocked number',
  'DeviceInCallGroup',
  'CallGroup',
  'DeviceFromCallGroup',
  'Upload Media',
  'UpdateDevice',
  'AddDevice',
  'CREATE',
  'DeleteDevice',
  'Update',
  'Add',
  'Set',
  'UPDATE',
  'Delete Media',
  'DELETE',
  'LegacyUpdateMultiLocation',
  'DOWNLOAD',
  'Create',
  'ChangeEmail',
  'UpdateSchedule',
  'CreateDepartment',
  'UpdateCallRoute',
  'AddPhoneNumber',
  'CreateBreakSchedule',
  'DeleteSchedule',
  'DeleteDepartment',
  'UpdateVoicemailBox',
  'RemovePhoneNumber',
];

const mockResources: string[] = [
  'voicemail-greeting',
  'Download call recording',
  'IVRMenuUpdate',
  'wam',
  'Forms',
  'A greeting was added to a Voicemailbox',
  'ForwardingNumber',
  'SetHoldMusic',
  'SetOutboundSMSNumber',
  'location-details',
  'user/user-id',
  'UPDATE',
  'delete',
  'Weave 2.0',
  'UpdateSipDevice',
  'Network Audit',
  'Mobile',
  'create blocked number',
  'Analytics Email Service',
  'Delete Media',
  '<nil>',
  'Delete MediaFile',
  'Service Account',
  'update',
  'Delete',
  'Delete blocked number',
  'Upload',
  'Desktop Client',
  'LegacyUpdate',
  'voicemail-override-setting',
  'CREATE',
  'Update',
  'Upload Media',
  'Analytics Onboarding Automation',
  'voicemailbox updated',
  'Admin Portal',
  'user-id',
  'audit-log',
  'unknown',
  'LegacyUpdateMultiLocation',
  'DELETE',
  'user',
  'media',
  'Create',
  'LegacySet',
  'CallGroup',
  'Browser',
  'MailboxGreetingDelete',
  'DOWNLOAD',
  'Mobile App',
  'feature-flag',
  'location',
  'Add',
  'admin',
  'phone-media',
  'UpdateVMOByInstructionSetID',
  'cli',
  'Desktop',
  'VMOverrideSettingsUpdate',
  'Updating Office Hours',
];

const mockUserIds: string[] = [
  '9a9f95bl-5fbd-4371-8aa6-b192400af9c7',
  '9a9f95bj-5fbd-4371-8aa6-b192400af9c5',
  '9a9f95bk-5fbd-4371-8aa6-b192400af9c6',
  '9a9f95bi-5fbd-4371-8aa6-b192400af9c4',
  '9a9f95bg-5fbd-4371-8aa6-b192400af9c2',
  '9a9f95bh-5fbd-4371-8aa6-b192400af9c3',
  '9a9f95bf-5fbd-4371-8aa6-b192400af9c1',
];

const mockDescriptions: string[] = [
  "I'm angry at myself!",
  'It’s the quenchiest!',
  'My first girlfriend turned into the moon.',
  "It's easy to do nothing, it's hard to forgive.",
  'Pride is not the opposite of shame, but its source.',
  'Life happens wherever you are, whether you make it or not.',
  'Sometimes life is like this tunnel. You can’t always see the light at the end of the tunnel, but if you keep moving, you will come to a better place.',
];

const getRandomArrayValue = (arr: string[]): string => {
  return arr[Math.floor(Math.random() * (arr.length + 1 - 1) + 1) - 1];
};

const createMockEvents = (pageSize: number): AuditEvent[] => {
  const events: AuditEvent[] = [];
  for (let i = 0; i < pageSize; i++) {
    events.push({
      timestamp: '2022-03-01T11:19:59.752719Z',
      userId: getRandomArrayValue(mockUserIds),
      action: getRandomArrayValue(mockActions),
      resource: getRandomArrayValue(mockResources),
      description: getRandomArrayValue(mockDescriptions),
      locationId: 'skjskks=-s=sdsada==s',
    });
  }
  return events;
};

export const getAuditQueryMockData = (
  reqFilters?: FiltersRequestResponse,
  paginationKey?: string
): Promise<{ status: number; data: QueryResponse }> => {
  const pageSize = 25;
  const data = {
    pageSize: paginationKey ? parseInt(paginationKey) : pageSize,
    event: createMockEvents(paginationKey ? parseInt(paginationKey) : pageSize),
    paginationKey: paginationKey
      ? parseInt(paginationKey) > 50
        ? ''
        : (parseInt(paginationKey) + pageSize).toString()
      : pageSize.toString(),
    filters: {
      startTime: reqFilters?.startTime ?? '',
      endTime: reqFilters?.endTime ?? '',
      ...(reqFilters?.userIds && { userIds: reqFilters.userIds }),
      ...(reqFilters?.actions && { actions: reqFilters.actions }),
      ...(reqFilters?.resources && { resources: reqFilters.resources }),
      ...(reqFilters?.locationIds && { locationIds: reqFilters.locationIds }),
    },
    displayMap: {
      '9a9f95bl-5fbd-4371-8aa6-b192400af9c7': 'Appa Air',
      '9a9f95bj-5fbd-4371-8aa6-b192400af9c5': 'Iro Fire',
      '9a9f95bk-5fbd-4371-8aa6-b192400af9c6': 'Zuko Fire',
      '9a9f95bi-5fbd-4371-8aa6-b192400af9c4': 'Toph Earth',
      '9a9f95bg-5fbd-4371-8aa6-b192400af9c2': 'Aang Avatar',
      '9a9f95bh-5fbd-4371-8aa6-b192400af9c3': 'Katara Water',
      '9a9f95bf-5fbd-4371-8aa6-b192400af9c1': 'Keaton Gallagher',
    },
  };

  return new Promise((resolve) => {
    setTimeout(() => {
      if (reqFilters) {
        resolve({ status: 200, data });
      }
    }, 300);
  });
};

export const auditActionsMockData: ActionsResponse = { actionIds: mockActions };

export const auditResourcesMockData: ResourceResponse = { resources: mockResources };
