import React from 'react';
import ReactTable from 'react-table';
import { css } from '@emotion/core';
import {
  CalendarIcon,
  EditIcon,
  IconButton,
  ModalControlTriggerProps,
  Text,
  TrashIcon,
  useThemeValues,
} from '@weave/design-system';

import { TableStyles } from '../../../../styles/table-style';
import { CopyCredential } from '../../../location-sidebar-container/sidebar-sync-app/sync-app-create/copy-credential.component';
import { Provider } from './providers.types';
import { DefaultProviderAvatar } from './default-provider-avatar.component';

export const avatarStyle = (size: number, theme) => css`
  height: ${theme.spacing(size || 5)};
  width: ${theme.spacing(size || 5)};
  border-radius: 50%;
`;

const sideBorderCell = (theme) => css`
  border-left: ${theme.colors.gray300} 1px solid;
  border-right: ${theme.colors.gray300} 1px solid;
  padding-left: ${theme.spacing(2)};
  padding-right: ${theme.spacing(2)};
`;

interface ProviderTableColumnProps {
  theme: any;
  setSelectedProvider: (provider: Provider) => void;
  confirmationModalTriggerProps: ModalControlTriggerProps;
  formTriggerProps: ModalControlTriggerProps;
  scheduleTriggerProps: ModalControlTriggerProps;
}

const columns = ({
  theme,
  setSelectedProvider,
  confirmationModalTriggerProps,
  formTriggerProps,
  scheduleTriggerProps,
}: ProviderTableColumnProps) => [
  {
    Header: '',
    id: 'providerImage',
    accessor: (rowData) => rowData,
    Cell: (props) => {
      const { firstName, lastName, publicDisplayImage } = props.value;
      return publicDisplayImage ? (
        <img
          alt={`${firstName} ${lastName}`}
          title={`${firstName} ${lastName}`}
          src={publicDisplayImage}
          css={avatarStyle(5, theme)}
        />
      ) : (
        <DefaultProviderAvatar
          firstName={firstName}
          lastName={lastName}
          index={props.index}
        />
      );
    },
    width: 60,
  },
  {
    Header: 'Name',
    id: 'providerName',
    accessor: (rowData) => `${rowData.lastName}, ${rowData.firstName}`,
  },
  {
    Header: 'Display Name',
    id: 'providerDisplayName',
    accessor: (rowData) => rowData.publicDisplayName,
  },
  {
    Header: 'Provider ID',
    id: 'providerID',
    accessor: (rowData) => rowData.id,
    Cell: (props) => (
      <CopyCredential
        title={props.value?.slice(0, 6) || 'Not Found'}
        value={props.value}
        valueHidden={true}
      />
    ),
  },
  {
    Header: 'Source',
    id: 'resource',
    accessor: (rowData) => rowData.resourceName,
  },
  {
    Header: 'Schedule',
    id: 'providerScheduleButton',
    accessor: (rowData) => rowData,
    Cell: (props) => {
      return (
        <div css={sideBorderCell(theme)}>
          <IconButton
            label="provider schedule"
            onClick={async () => {
              await setSelectedProvider(props.value);
              scheduleTriggerProps.onClick();
            }}
          >
            <CalendarIcon />
          </IconButton>
        </div>
      );
    },
    width: 74,
  },
  {
    Header: '',
    id: 'editProviderButton',
    accessor: (rowData) => rowData,
    Cell: (props) => (
      <IconButton
        label="edit provider"
        onClick={async () => {
          await setSelectedProvider(props.value);
          formTriggerProps.onClick();
        }}
        css={css`
          margin: auto;
        `}
      >
        <EditIcon />
      </IconButton>
    ),
    width: 60,
  },
  {
    Header: '',
    id: 'deleteProviderButton',
    accessor: (rowData) => rowData,
    Cell: (props) => (
      <IconButton
        label="delete provider"
        onClick={() => {
          setSelectedProvider(props.value);
          confirmationModalTriggerProps.onClick();
        }}
        css={css`
          margin: auto;
        `}
      >
        <TrashIcon />
      </IconButton>
    ),
    width: 60,
  },
];

interface ProvidersTableProps {
  providers: Provider[];
  refetch: any; // TODO: fix type
  setSelectedProvider: (provider: Provider) => void;
  confirmationModalTriggerProps: ModalControlTriggerProps;
  formTriggerProps: ModalControlTriggerProps;
  scheduleTriggerProps: ModalControlTriggerProps;
}

export const ProvidersTable = ({
  providers,
  setSelectedProvider,
  confirmationModalTriggerProps,
  formTriggerProps,
  scheduleTriggerProps,
}: ProvidersTableProps) => {
  const theme = useThemeValues();

  return (
    <>
      {providers?.length ? (
        <ReactTable
          columns={columns({
            theme,
            setSelectedProvider,
            confirmationModalTriggerProps,
            formTriggerProps,
            scheduleTriggerProps,
          })}
          data={providers}
          pageSize={providers.length < 20 ? providers.length : 20}
          showPagination={providers.length > 20}
          css={TableStyles}
        />
      ) : (
        <Text>No provider data available.</Text>
      )}
    </>
  );
};
