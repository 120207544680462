import React, { useEffect, useState } from 'react';
import { TabLoader } from '../multi-sync-app-access.component';
import { SyncAppAccessCardHeader } from './sync-app-access-card-header';
import {
  AccessType,
  CheckFieldValuesType,
  ClientLocationType,
  SourceType,
  SyncAppType,
} from './sync-app-access.types';
import { SyncAppAccessCardContent } from './sync-app-access-card-content';
import { AllLocationAccessCheckField } from './sync-app-access-all-location-checkbox';
import { accessCardContainer } from '../../../sync-app-card/sync-app-card.styles';

type SyncAppAccessCardProps = {
  loadingAccess: boolean;
  syncApps: SyncAppType[];
  getLocationAddress: (clientLocation: ClientLocationType) => string;
  getSourceTypeName: (syncApp: SyncAppType) => string;
};

export const SyncAppAccessCard = ({
  loadingAccess,
  syncApps,
  getLocationAddress,
  getSourceTypeName,
}: SyncAppAccessCardProps) => {
  const [globalFieldValues, setGlobalFieldValues] = useState<CheckFieldValuesType>({
    contacts: false,
    schedule: false,
  });

  useEffect(() => {
    const isAllContact = syncApps.every((syncApp) => isAllContactType(syncApp));
    const isAllContactSchedule = syncApps.every((syncApp) =>
      isAllContactAndScheduleType(syncApp)
    );
    setGlobalFieldValues({
      contacts: isAllContact,
      schedule: isAllContactSchedule,
    });
  }, []);

  const isAllContactType = (syncApp: SyncAppType): boolean => {
    const isParentLocationContactType = isContactType(syncApp.accessRules?.Type);
    const isAllClientLocationContactType = syncApp.clientLocations.every(
      (clientLocation) => isContactType(clientLocation.accessRules?.Type)
    );
    return isParentLocationContactType && isAllClientLocationContactType;
  };

  const isAllContactAndScheduleType = (syncApp: SyncAppType): boolean => {
    const isParentSourceTypeCsvOrCustomContact = isSourceTypeCsvOrCustomContact(
      syncApp.SourceType
    );
    const isParentLocationContactAndScheudleType = isContactsAndScheduleType(
      syncApp.accessRules?.Type
    );
    const isAllClientLocationContactAndScheduleType = syncApp.clientLocations.every(
      (clientLocation) => isContactsAndScheduleType(clientLocation.accessRules?.Type)
    );
    return (
      isParentSourceTypeCsvOrCustomContact ||
      (isParentLocationContactAndScheudleType &&
        isAllClientLocationContactAndScheduleType)
    );
  };

  const getFieldsValue = (accessType: string | undefined): CheckFieldValuesType => {
    return {
      contacts: isContactType(accessType),
      schedule: isContactsAndScheduleType(accessType),
    };
  };

  const isContactType = (type: string | undefined): boolean => {
    return (
      !!type && (type === AccessType.Contact || type === AccessType.ContactAndSchedule)
    );
  };

  const isContactsAndScheduleType = (type: string | undefined): boolean => {
    return !!type && type === AccessType.ContactAndSchedule;
  };

  const isSourceTypeCsvOrCustomContact = (sourceType: number | undefined): boolean => {
    return (
      !!sourceType &&
      (sourceType === SourceType.CSV || sourceType === SourceType.CUSTOM_CONTACT)
    );
  };

  const checkForSelectAll = (
    clientLocations: ClientLocationType[]
  ): CheckFieldValuesType => {
    const isAllContact = clientLocations.every(
      (ele) => ele.accessRules?.Type === AccessType.Contact
    );
    const isAllContactAndSchedule = clientLocations.every(
      (ele) => ele.accessRules?.Type === AccessType.ContactAndSchedule
    );
    return {
      contacts: isAllContact || isAllContactAndSchedule,
      schedule: isAllContactAndSchedule,
    };
  };

  const getClientLocationHeader = (clientLocations: ClientLocationType[]): string => {
    return clientLocations.length ? 'Locations' : '';
  };

  const getClientLocationName = (clientLocation: ClientLocationType): string => {
    const shortName = clientLocation?.ShortName?.value ?? '';
    const name = clientLocation?.Name ?? '';
    const sourceName = clientLocation?.SourceName ?? '';
    return shortName || name || sourceName;
  };

  const hideContactsAndSchedule = (): boolean => {
    return syncApps.every((syncApp) =>
      isSourceTypeCsvOrCustomContact(syncApp.SourceType)
    );
  };

  const getCardContent = (): JSX.Element => {
    return (
      <div css={accessCardContainer}>
        <AllLocationAccessCheckField
          fieldValues={globalFieldValues}
          hideContactsAndSchedule={hideContactsAndSchedule()}
        />
        <SyncAppAccessCardContent
          loadingAccess={loadingAccess}
          syncApps={syncApps}
          getClientLocationName={getClientLocationName}
          checkForSelectAll={checkForSelectAll}
          getFieldsValue={getFieldsValue}
          getClientLocationHeader={getClientLocationHeader}
          getLocationAddress={getLocationAddress}
          getSourceTypeName={getSourceTypeName}
        />
      </div>
    );
  };

  return (
    <>
      <SyncAppAccessCardHeader />
      {loadingAccess ? <TabLoader /> : getCardContent()}
    </>
  );
};
