import React from 'react';
import { theme } from '@weave/theme-original';
import { Text, TextField, useFormField } from '@weave/design-system';
import { css } from '@emotion/core';
import { termStyle } from './details';
import { IndexedContent } from '../header-container/wam-search/use-wam-search';
import { Modifier, ModifierKeys } from './edit-search';

const container = css`
  margin: ${theme.spacing(2, 0)};
  border-bottom: 1px solid ${theme.colors.gray300};
`;

type Props = {
  selected: IndexedContent;
  modifier: Modifier;
  updateModifier: (key: ModifierKeys, value: any) => void;
};

export const SearchableTerms = ({ selected, modifier, updateModifier }: Props) => {
  const termProps = useFormField({ type: 'text' });

  const toggleTerm = (term) => {
    const oldHiddenTerms = modifier?.[selected?.id as string]?.hiddenTerms ?? [];
    const hiddenTerms = oldHiddenTerms.includes(term)
      ? oldHiddenTerms.filter((item) => item !== term)
      : [...oldHiddenTerms, term];
    updateModifier('hiddenTerms', hiddenTerms);
  };

  const updateAdditionalTerms = (type = '', term = '') => {
    let additionalTerms = modifier?.[selected?.id as string]?.additionalTerms ?? [];
    if (!type) {
      const { value } = termProps;
      additionalTerms = [...additionalTerms, value];
    } else if (type === 'remove') {
      additionalTerms = additionalTerms.filter((item) => item !== term);
    }
    updateModifier('additionalTerms', additionalTerms);
  };

  return (
    <div css={container}>
      <Text weight="bold">Searchable Terms</Text>
      <TextField
        {...termProps}
        label="Add Searchable Term"
        name="terms"
        actionText="Add"
        onActionClick={updateAdditionalTerms}
      />
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          margin: ${theme.spacing(1)};
        `}
      >
        {!selected?.terms?.length &&
          !modifier?.[selected?.id as string]?.additionalTerms?.length && (
            <Text color="light">No Special Search Terms</Text>
          )}
        {selected?.terms?.map((item, idx) => {
          const isHidden = (
            modifier?.[selected?.id as string]?.hiddenTerms ?? []
          ).includes(item);
          return (
            <Text
              key={item + idx}
              css={termStyle(true, isHidden)}
              onClick={() => toggleTerm(item)}
            >
              {item}
            </Text>
          );
        })}
        {modifier?.[selected?.id as string]?.additionalTerms?.map((item) => (
          <Text
            key={item}
            css={termStyle()}
            onClick={() => updateAdditionalTerms('remove', item)}
          >
            {item}
          </Text>
        ))}
      </div>
    </div>
  );
};
