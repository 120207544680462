import { handleActions } from 'redux-actions';

import {
  resetNotificationSettings,
  getNotificationAdvancedOptionsList,
  getNotificationAdvancedOptionsListFailure,
  getNotificationAdvancedOptionsListSuccess,
  getNotificationDeliverySummary,
  getNotificationDeliverySummaryFailure,
  getNotificationDeliverySummarySuccess,
  getNotificationHistory,
  getNotificationHistoryFailure,
  getNotificationHistorySuccess,
  getNotificationsSettings,
  getNotificationsSettingsFailure,
  getNotificationsSettingsSuccess,
  notificationsSettingsTypeMap,
  updateNotificationToggle,
  updateNotificationSettings,
  updateNotificationSettingsFailure,
  updateNotificationSettingsSuccess,
} from './notifications-settings.action';

import { NotificationSettingsStore } from './notification-settings.types';

const defaultState: NotificationSettingsStore = {
  categories: {},
  deliverySummary: [],
  notificationsHistory: {},
  deliveryReports: {},
  notificationAdvancedOptions: {
    'appointment-type': [],
    'recall-type': [],
    location: [],
    operatory: [],
    practitioner: [],
    'data-source': [],
  },
  loading: false,
};

const handleError = (state, action) => ({
  ...state,
  error: action.payload,
  loading: false,
  success: null,
});

const updateDataItem = (categorySettings, newSetting) => {
  const categories = { ...categorySettings };
  Object.keys(categorySettings).forEach((type) => {
    if (type === notificationsSettingsTypeMap[newSetting.type]) {
      categories[type] = categories[type].map((oldSetting) =>
        oldSetting.id === newSetting.id ? newSetting : oldSetting
      );
    }
  });
  return categories;
};

export const notificationsSettingsReducer = handleActions<NotificationSettingsStore, any>(
  {
    [getNotificationHistory.toString()]: (state) => ({ ...state, loading: true }),
    [getNotificationHistorySuccess.toString()]: (state, action) => ({
      ...state,
      loading: false,
      notificationsHistory: {
        ...state.notificationsHistory,
        [action.payload.id]: action.payload.data,
      },
    }),
    [getNotificationHistoryFailure.toString()]: (state, action) =>
      handleError(state, action),

    [resetNotificationSettings.toString()]: (state, action) => ({
      ...defaultState,
    }),
    [getNotificationAdvancedOptionsList.toString()]: (state) => ({
      ...state,
      loading: true,
    }),
    [getNotificationAdvancedOptionsListSuccess.toString()]: (state, action) => ({
      ...state,
      notificationAdvancedOptions: {
        ...state.notificationAdvancedOptions,
        ...action.payload,
      },
    }),
    [getNotificationAdvancedOptionsListFailure.toString()]: (state, action) =>
      handleError(state, action),

    [getNotificationsSettings.toString()]: (state) => ({ ...state, loading: true }),
    [getNotificationsSettingsSuccess.toString()]: (state, action) => ({
      ...state,
      loading: false,
      categories: action.payload,
    }),
    [getNotificationsSettingsFailure.toString()]: (state, action) =>
      handleError(state, action),

    [updateNotificationToggle.toString()]: (state) => ({ ...state, loading: true }),

    [updateNotificationSettings.toString()]: (state) => ({ ...state, loading: true }),
    [updateNotificationSettingsSuccess.toString()]: (state, action) => ({
      ...state,
      loading: false,
      categories: updateDataItem(state.categories, action.payload),
    }),
    [updateNotificationSettingsFailure.toString()]: (state, action) =>
      handleError(state, action),
    [getNotificationDeliverySummary.toString()]: (state) => ({ ...state, loading: true }),
    [getNotificationDeliverySummarySuccess.toString()]: (state, action) => ({
      ...state,
      loading: false,
      deliveryReports: {
        ...state.deliveryReports,
        [action.payload.id]: action.payload.data,
      },
    }),
    [getNotificationDeliverySummaryFailure.toString()]: (state, action) =>
      handleError(state, action),
  },
  defaultState
);
