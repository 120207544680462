import { css } from 'emotion';

export const section = css`
  margin-top: 32px;

  label: section;
`;

export const subtitleContainer = css`
  align-items: center;
  display: flex;
`;

export const subtitleIcon = css`
  margin-right: 16px;
`;

export const subtitleHelpText = css`
  display: block;
  margin-top: 8px;

  label: subtitleHelpText;
`;

export const entryContainer = css`
  margin-top: 24px;
`;
