import React from 'react';

interface Props {
  canNext;
  page: number;
  showPageSizeOptions;
  pageSizeOptions;
  pageSize;
  onPageSizeChange;
  className;
  PreviousComponent: any;
  NextComponent: any;
  onPageChange: Function;
  style: React.CSSProperties;
  pageText: string;
  previousText: string;
  nextText: string;
  rowsText: string;

  // Unused props
  pages: number;
  showPageJump;
  canPrevious;
  ofText: string;
}

const defaultButton = (props) => (
  <button type="button" {...props} className="-btn">
    {props.children}
  </button>
);

export default class PaginationWithUnknownTotal extends React.Component<Props> {
  changePage = (page) => {
    if (this.props.page !== page) {
      this.props.onPageChange(page);
    }
  };

  render() {
    const {
      page,
      showPageSizeOptions,
      pageSizeOptions,
      pageSize,
      onPageSizeChange,
      className,
      canNext,
      PreviousComponent = defaultButton,
      NextComponent = defaultButton,
    } = this.props;

    const canPrevious = page > 0;
    const classList = [className, '-pagination'].filter((name) => !!name).join(' ');

    return (
      <div className={classList} style={this.props.style}>
        <div className="-previous">
          <PreviousComponent
            onClick={() => {
              if (!canPrevious) {
                return;
              }
              this.changePage(page - 1);
            }}
            disabled={!canPrevious}
          >
            {this.props.previousText}
          </PreviousComponent>
        </div>
        <div className="-center">
          <span className="-pageInfo">
            {this.props.pageText} <span className="-currentPage">{page + 1}</span>{' '}
          </span>
          {showPageSizeOptions && (
            <span className="select-wrap -pageSizeOptions">
              <select
                onChange={(e) => onPageSizeChange(Number(e.target.value))}
                value={pageSize}
              >
                {pageSizeOptions.map((option, i) => (
                  <option key={i} value={option}>
                    {option} {this.props.rowsText}
                  </option>
                ))}
              </select>
            </span>
          )}
        </div>
        <div className="-next">
          <NextComponent
            onClick={() => {
              if (!canNext) {
                return;
              }
              this.changePage(page + 1);
            }}
            disabled={!canNext}
          >
            {this.props.nextText}
          </NextComponent>
        </div>
      </div>
    );
  }
}
