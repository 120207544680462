import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { REACT_APP_ANALYTICS_URL } from '../../config/app';
import { store } from './store';
import { selectAuthToken } from '../actions/auth/auth.selectors';
import { selectCurrentLocationId } from '../actions/location/current-location';
import { getLocationUUID } from '../../helpers/utils';

const authHandler = (operation, forward) => {
  const state: any = store.getState();
  const token = selectAuthToken(state);
  let locationId = selectCurrentLocationId(state);
  const authHeader = token ? `Bearer ${token}` : null;

  if (!locationId) {
    // Grab the locationId from the url if the current location is not yet set when using
    // the apollo client which can occur after reloading the app and immediately mounting
    // a component that utilizes the useQuery hook.
    locationId = getLocationUUID(window.location.pathname);
  }

  operation.setContext({
    headers: {
      Authorization: authHeader,
      'Location-Id': locationId,
    },
  });
  return forward(operation);
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.error('server error:', graphQLErrors);
  }

  if (networkError) {
    console.error('network error:', networkError);
  }
});

const authLink = new ApolloLink(authHandler);
const httpLink = new HttpLink({ uri: REACT_APP_ANALYTICS_URL });
const link = errorLink.concat(authLink).concat(httpLink);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});
