import React from 'react';
import { css } from 'emotion';
import { Modal, PrimaryButton, useForm, EmailField, FormRow } from '@weave/design-system';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  removeEmailFromBlacklist: (emailAddress: string) => void;
}

export const BlacklistModal = (props: Props) => {
  const { formProps, getFieldProps, reset, values } = useForm({
    fields: {
      email: { type: 'email', required: true, value: '' },
    },
    onSubmit: (values) => {
      if (values.email) {
        props.removeEmailFromBlacklist(values.email);
        reset();
      }
    },
  });

  return (
    <Modal show={props.isOpen} onClose={props.onClose}>
      <Modal.Header>Email to Remove from Blacklist</Modal.Header>
      <Modal.Body>
        <div
          className={css`
            margin-top: 2%;
          `}
        >
          <form {...formProps}>
            <FormRow>
              <EmailField
                {...getFieldProps('email')}
                label="Email Address"
                name="email"
                placeholder="Email"
              />
            </FormRow>
            <FormRow>
              <PrimaryButton type="submit" disabled={!values.email}>
                Submit
              </PrimaryButton>
            </FormRow>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};
