import queryString from 'query-string';
import { all, call, put } from 'redux-saga/effects';
import { CustomAxios } from '../../axios';
import { SyncAppInterface } from '../../../components/sync-app/sync-app.types';
import {
  addSyncAppReport,
  clearSyncAppReports,
  setSyncAppsDOVStatus,
} from './sync-app.actions';

const getSyncAppsReports = function* (syncApps) {
  return yield all(
    syncApps.map(function* (syncApp) {
      try {
        const { SourceID: sourceID, PracticeManagementSystem: integration } = syncApp;
        const queryParams = queryString.stringify({ sourceID, integration });
        return yield call(
          CustomAxios.get,
          `/support/v1/sync-app-reporting/notifications?${queryParams}`
        );
      } catch {
        return null;
      }
    })
  );
};

export const handleGetSyncAppReports = function* (syncApps: SyncAppInterface[]) {
  try {
    yield put(clearSyncAppReports());

    const filteredSyncApps = syncApps.filter(
      (syncApp) => syncApp.PracticeManagementSystem !== ''
    );

    const reportResponses = yield call(getSyncAppsReports, filteredSyncApps);

    for (const response of reportResponses) {
      const report = response?.data?.data ?? [];
      const filteredReport: { [key: string]: string }[] = [];
      report.forEach((item) => {
        if (item) {
          filteredReport.push(JSON.parse(item));
        }
      });
      if (filteredReport.length > 0) {
        const sourceId = filteredReport[0]?.sourceID ?? '';
        yield put(
          addSyncAppReport({
            sourceId,
            report: filteredReport,
          })
        );
      }
    }
  } catch {
    // Ignore errors.
  }
};

export const handleGetSyncAppsDOVStatus = function* () {
  try {
    const { data } = yield call(CustomAxios.get, '/support/v1/syncapp/dov/statuses');
    yield put(setSyncAppsDOVStatus(data?.data?.Statuses ?? {}));
  } catch {
    // Ignore errors
  }
};
