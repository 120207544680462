import { css } from 'emotion';
import { gray1, gray3, weaveBlue, white } from '@weave/platform-ui-components';

export const phoneTreeCardContainer = css`
  height: 120px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  width: calc(100vw - 352px);

  label: phoneTreeCardContainer;
`;

export const selectPhoneTree = css`
  margin-bottom: 5px;
`;

export const phoneTreeCard = css`
  align-items: center;
  background-color: ${white};
  border: 1px solid ${gray3};
  border-radius: 10px;
  cursor: pointer;
  display: inline-flex;
  height: 100px;
  justify-content: center;
  margin: 3px 10px;
  padding: 20px;
  text-align: center;
  transition: background-color 500ms, border-color 500ms;
  vertical-align: top;
  white-space: normal;
  width: 180px;

  &:first-of-type {
    margin-left: 0;
  }

  &:hover {
    background-color: ${gray1};
    border: 1px solid ${gray1};
  }

  &.active {
    background-color: ${weaveBlue};
    border-color: ${weaveBlue};
    color: ${white};
  }

  label: phoneTreeCard;
`;
