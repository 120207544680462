import { connect } from 'react-redux';
import { selectHasWeaveAcl } from '../../../../redux/actions/auth/auth.selectors';
import { AutoMsgQueueControl } from './auto-msg-queue-control.component';
import {
  clearAutoMsgQueue,
  reloadAutoMsgQueue,
} from '../../../../redux/actions/auto-msg-queue';
import { CoreACLs } from '../../../../redux/actions/auth/auth.types';

const getStoreData = (store) => ({
  canControlAutoMsgQueue: selectHasWeaveAcl(
    store,
    CoreACLs.AUTOMATEDNOTIFICATIONQUEUEWRITE
  ),
  loading: store.autoMsgQueue.loading,
});

export const AutoMsgQueueControlContainer = connect(getStoreData, {
  clearAutoMsgQueue,
  reloadAutoMsgQueue,
})(AutoMsgQueueControl);
