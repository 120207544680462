import { createRequestSaga } from '@weave/alert-system';
import { call, put, takeEvery } from 'redux-saga/effects';
import { CustomAxios, getResponseData } from '../../axios';
import { Store } from '../../store/store.model';

export type IntegrationFieldsModel = {
  name: string;
  displayName: string;
  valueType: string;
};

export type SyncAppMetadataState = {
  name: string;
  fields: IntegrationFieldsModel[];
};

// actions
export enum SyncAppSettingsActionTypes {
  FetchSyncAppMetadata = 'FETCH_SYNC_APP_PROVISION_METADATA',
  SetSyncAppMetadata = 'SET_SYNC_APP_PROVISION_METADATA',
}

type FetchProvisionMetadataAction = {
  type: SyncAppSettingsActionTypes.FetchSyncAppMetadata;
};

export type SyncAppSettingsActions =
  | {
      type: SyncAppSettingsActionTypes.SetSyncAppMetadata;
      payload: SyncAppMetadataState[];
    }
  | FetchProvisionMetadataAction;

export const setSyncAppMetadata = (
  payload: SyncAppMetadataState[]
): SyncAppSettingsActions => ({
  type: SyncAppSettingsActionTypes.SetSyncAppMetadata,
  payload,
});

export const fetchSyncAppMetadata = (): SyncAppSettingsActions => ({
  type: SyncAppSettingsActionTypes.FetchSyncAppMetadata,
});

// reducer
export type SyncAppSettingsState = {
  provisionMetadata: SyncAppMetadataState[];
};

export const syncAppSettingsReducer = (
  state: SyncAppSettingsState = { provisionMetadata: [] },
  action: SyncAppSettingsActions
): SyncAppSettingsState => {
  switch (action.type) {
    case SyncAppSettingsActionTypes.SetSyncAppMetadata:
      return { ...state, provisionMetadata: action.payload };
    default:
      return state;
  }
};

// sagas
const requestSyncAppMetadata = () =>
  CustomAxios.get('/support/v1/syncapp/provision/metadata').then(getResponseData);

export const handleFetchSyncAppMetadata = createRequestSaga<FetchProvisionMetadataAction>(
  {
    key: SyncAppSettingsActionTypes.FetchSyncAppMetadata,
    saga: function* () {
      const result = yield call(requestSyncAppMetadata);
      yield put(setSyncAppMetadata(result.integrations));
    },
  }
);

export function* syncAppSettingsSaga() {
  yield takeEvery(
    SyncAppSettingsActionTypes.FetchSyncAppMetadata,
    handleFetchSyncAppMetadata
  );
}

// selectors
const selectSyncAppProvisionMetadata = (state: Store): SyncAppMetadataState[] =>
  state.syncAppSettings?.provisionMetadata ?? [];

export const selectIntegrationsMetadata = (
  state: Store
): { [key: string]: IntegrationFieldsModel[] } =>
  selectSyncAppProvisionMetadata(state).reduce(
    (obj, metadata) => ({
      ...obj,
      [`${metadata.name}`]: metadata.fields,
    }),
    {}
  );
