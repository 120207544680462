import { css } from '@emotion/core';
import { useAlert } from '@weave/alert-system';
import { WeaveTheme } from '@weave/theme-original';
import { Heading, SpinningLoader } from '@weave/design-system';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { CustomAxios } from '../../redux/axios';

export const MediaViewer = () => {
  const { id: locationId, mediaId } = useParams<{ id: string; mediaId: string }>();
  const [fileUrl, setFileUrl] = useState<any>();
  const [loading, setLoading] = useState(false);
  const alerts = useAlert();

  const queryParams = new URLSearchParams(useLocation().search);
  const mediaType = queryParams.get('media_type');

  const fetchMedia = async () => {
    try {
      setLoading(true);

      const response = await CustomAxios.get(
        // Not sure what mediaType is required for. While testing, this could be set to
        // any string value other than an empty string and it seemed to make no
        // difference, the media file was still retrievable (as long as it was not an
        // empty string).
        `/support/v1/media/${locationId}/${mediaId}/${mediaType}`,
        { responseType: 'blob' }
      );

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);

      setFileUrl(url);
    } catch (error: any) {
      alerts.error('Error fetching media.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (mediaId && locationId) {
      fetchMedia();
    }
  }, [mediaId, locationId]);

  return (
    <section css={sectionStyles}>
      <Heading>Media Viewer</Heading>

      {loading && (
        <div css={loadingContainer}>
          <SpinningLoader />
        </div>
      )}

      {fileUrl && (
        <iframe
          title="media viewer"
          data-testid="media-view-iframe"
          css={iframeStyles}
          src={`${fileUrl}`}
        />
      )}
    </section>
  );
};

const sectionStyles = (theme: WeaveTheme) => css`
  flex: 1 1 auto;
  height: 92%;
  background-color: white;
  margin: 20px;
  border-radius: ${theme.borderRadius.medium};
  box-shadow: ${theme.shadows.heavy};
  padding: ${theme.spacing(3)};
`;

const loadingContainer = css`
  display: flex;
  justify-content: center;
`;

const iframeStyles = css`
  width: 100%;
  height: 96%;
`;
