import React from 'react';
import { showError, showSuccess } from '@weave/alert-system';
import { WeaveTheme } from '@weave/theme-original';
import { FormRow, Modal, SwitchField, TextField, useForm } from '@weave/design-system';
import { css } from '@emotion/core';
import { CustomAxios } from '../../redux/axios';
import { useDispatch } from 'react-redux';

interface Props {
  close: Function;
}

export const CreateFlagModal = (props: Props) => {
  const dispatch = useDispatch();

  const { getFieldProps, formProps, values, isComplete } = useForm({
    fields: {
      flagName: { type: 'text', required: true, value: '' },
      description: { type: 'text', required: true, value: '' },
      defaultValue: { type: 'switch', value: false },
    },
    onSubmit: function () {
      CustomAxios.post('/feature-flags/v1/flags', {
        name: values.flagName,
        description: values.description,
        default_value: values.defaultValue,
      })
        .then((res) => {
          dispatch(showSuccess('Feature Flag Created'));
          props.close(res.data);
        })
        .catch((err) => {
          dispatch(showError('Error creating feature flag'));
        });
    },
  });

  return (
    <Modal
      show={true}
      onClose={() => {
        props.close();
      }}
    >
      <Modal.Header>New Flag</Modal.Header>
      <Modal.Body>
        <form
          css={(theme: WeaveTheme) =>
            css`
              padding-top: ${theme.spacing(1)};
            `
          }
        >
          <FormRow>
            <TextField {...getFieldProps('flagName')} label="Name" />
          </FormRow>
          <FormRow>
            <TextField {...getFieldProps('description')} label="Description" />
          </FormRow>
          <FormRow>
            <SwitchField {...getFieldProps('defaultValue')} label="Enabled by default" />
          </FormRow>
        </form>
        <Modal.Actions
          // @ts-ignore It's the right type, Typescript just doesn't recognize that.
          onPrimaryClick={formProps.onSubmit}
          onSecondaryClick={() => {
            props.close();
          }}
          disablePrimary={!isComplete}
          primaryLabel="Create"
          secondaryLabel="Cancel"
        />
      </Modal.Body>
    </Modal>
  );
};
