import { connect } from 'react-redux';
import { SmsMetadata } from './sms-metadata.component';
import {
  resetSmsMetadata,
  smsMetadata,
} from '../../../../../redux/actions/sms/sms-metadata/sms-metadata.action';
import { selectCurrentLocationId } from '../../../../../redux/actions/location/current-location';
import { Store } from '../../../../../redux/store/store.model';

export const SmsMetadataContainer = connect(
  (store: Store) => ({
    locationID: selectCurrentLocationId(store),
    smsMetadata: store.smsMetadata.data,
    smsUUID: store.smsDetails.data.id,
    apptID: store.smsDetails.data.apt_id,
  }),
  {
    getSmsMetadata: smsMetadata,
    resetSmsMetadata: resetSmsMetadata,
  }
)(SmsMetadata);
