import { createRequestStateSelector } from '@weave/alert-system';
import { pick } from 'lodash';
import { createSelector } from 'reselect';
import { Store } from '../../store/store.model';
import { selectCurrentLocationFwdNumberIds } from '../location/location-entities/location-fwd-numbers';
import { FwdNumbersActionTypes } from './fwd-numbers.types';

export const selectFwdNumbersFetchingAll = createRequestStateSelector(
  FwdNumbersActionTypes.RequestList
);
export const selectFwdNumbersFetching = createRequestStateSelector(
  FwdNumbersActionTypes.RequestGet
);
export const selectFwdNumbersUpdating = createRequestStateSelector(
  FwdNumbersActionTypes.RequestUpdate
);
export const selectFwdNumbersCreating = createRequestStateSelector(
  FwdNumbersActionTypes.RequestCreate
);
export const selectFwdNumbersDeleting = createRequestStateSelector(
  FwdNumbersActionTypes.RequestDelete
);

const selector = (state: Store) => state.fwdNumbers;

export const selectAllFwdNumbers = createSelector(
  selector,
  selectFwdNumbersFetchingAll,
  (state, reqState) => ({
    ...reqState,
    fwdNumbers: state,
  })
);

export const selectFwdNumbers = createSelector(
  selectAllFwdNumbers,
  selectCurrentLocationFwdNumberIds,
  ({ fwdNumbers, ...reqState }, fwdNumberIds) => ({
    ...reqState,
    fwdNumbers: fwdNumberIds ? pick(fwdNumbers, fwdNumberIds) : {},
  })
);

export const selectFwdNumbersArray = createSelector(
  selectFwdNumbers,
  ({ fwdNumbers, ...reqState }) => ({
    ...reqState,
    fwdNumbers: Object.values(fwdNumbers),
  })
);

export const selectFwdNumber = (fwdNumberId: string) =>
  createSelector(selectFwdNumbers, (state) => ({
    ...state,
    fwdNumber: state.fwdNumbers[fwdNumberId],
  }));
