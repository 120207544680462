import React, { useState, useEffect, FC } from 'react';
import { css } from 'emotion';
import { NakedUl } from '@weave/design-system';
import { isEmpty, omit, isUndefined } from 'lodash';

import { DeviceSection } from './device-section.component';
import {
  DeviceOptionModel,
  DevicePendingChangesModel,
} from '../../../redux/actions/devices';
import { DeviceRowSelect } from './device-row-select.component';
import { useResources } from '../../../helpers/useResources';

interface Props {
  contactDirectoryID: string;
  locationId: string;
  messageWaitingIndicatorID: string | null;
  updating: boolean;
  voicemailBoxID?: string | null;
  voiceMailboxOptions: DeviceOptionModel[];
  updateDevice: (changes: DevicePendingChangesModel) => void;
}

type PendingChanges = Pick<
  DevicePendingChangesModel,
  'contactDirectoryID' | 'messageWaitingIndicatorID'
>;

export const DeviceSectionPhysical: FC<Props> = ({
  contactDirectoryID,
  locationId,
  messageWaitingIndicatorID,
  updating,
  voiceMailboxOptions,
  updateDevice,
}) => {
  const [pendingChanges, setPendingChanges] = useState<PendingChanges>({});

  const { data: contactDirectories } = useResources<DeviceOptionModel[]>(
    'support/v1/phone/devices/options/contact-directories',
    {
      deps: [locationId],
      suppressFetch: !locationId,
      resourceLabel: 'contact directories',
    }
  );

  useEffect(() => {
    // When the editable device values update, clear pending changes
    setPendingChanges({});
  }, [messageWaitingIndicatorID, contactDirectoryID]);

  return (
    <DeviceSection
      name="Physical Device Settings"
      showSaveButton
      hasRowChanges={!isEmpty(pendingChanges) && !updating}
      onSaveClick={() => updateDevice(pendingChanges)}
    >
      <NakedUl
        className={css`
          margin: 16px 0 0;
          max-width: 600px;
        `}
      >
        <DeviceRowSelect
          id="message-waiting-indicator-voice-mailbox"
          label="Message Waiting Indicator Voice Mailbox"
          value={
            !isUndefined(pendingChanges.messageWaitingIndicatorID)
              ? pendingChanges.messageWaitingIndicatorID
              : messageWaitingIndicatorID
          }
          onChange={(id: string) => {
            const newId = id === 'null' ? null : id;

            if (newId === messageWaitingIndicatorID) {
              // Clear pending changes
              setPendingChanges(omit(pendingChanges, 'messageWaitingIndicatorID'));
            } else {
              setPendingChanges({ ...pendingChanges, messageWaitingIndicatorID: newId });
            }
          }}
          options={voiceMailboxOptions}
        />
        <DeviceRowSelect
          id="contact-directory"
          label="Contact Directory"
          value={
            !isUndefined(pendingChanges.contactDirectoryID)
              ? pendingChanges.contactDirectoryID
              : contactDirectoryID
          }
          onChange={(id: string) => {
            //I think that by passing in null, it removes the directory id
            const newId = id === 'null' ? null : id;

            if (newId === contactDirectoryID) {
              // Clear pending changes
              setPendingChanges(omit(pendingChanges, 'contactDirectoryID'));
            } else {
              setPendingChanges({ ...pendingChanges, contactDirectoryID: newId });
            }
          }}
          options={contactDirectories}
        />
      </NakedUl>
    </DeviceSection>
  );
};
