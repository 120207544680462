import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/core';
import { useAlert } from '@weave/alert-system';
import { theme } from '@weave/theme-original';
import {
  Heading,
  IconButton,
  Modal,
  NakedButton,
  PrimaryButton,
  SearchField,
  Text,
  TextField,
  useFormField,
  useModalControl,
  XIconSmall,
} from '@weave/design-system';
import { Details } from './details';
import { firebaseDB } from './indexed-firebase/firebase';
import { useListener } from './indexed-firebase/use-listener';
import { IndexedContent } from '../header-container/wam-search/use-wam-search';

const container = css`
  margin: ${theme.spacing(2)};
  display: flex;
  h2,
  span {
    text-transform: capitalize;
  }
  > ul {
    width: 400px;
    list-style-type: none;
    height: 86vh;
    overflow-y: auto;
    margin-left: 0;
    padding-left: 0;
    > div {
      margin: ${theme.spacing(2)};
    }
    li {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: ${theme.spacing(2)};
      margin: ${theme.spacing(1)};
      margin-left: ${theme.spacing(3)};
      box-shadow: ${theme.shadows.heavy};
      cursor: pointer;
      p {
        margin: 0;
      }
    }
  }
`;

const addRouteStyle = css`
  outline: none;
  margin-left: ${theme.spacing(4)};
  color: ${theme.colors.gray500};
`;

const modalContainer = css`
  margin: ${theme.spacing(2)};
  > div {
    margin-top: ${theme.spacing(2)};
  }
  p {
    margin: ${theme.spacing(0, 1)};
    margin-bottom: ${theme.spacing(2)};
  }
`;

const removeBtn = css`
  position: relative;
  button {
    position: absolute;
    top: -8px;
    right: 0;
    margin-left: auto;
  }
  p {
    position: absolute;
    bottom: -4px;
    right: 0;
  }
`;

export type ModifierKeys =
  | 'additionalDisplayNames'
  | 'disabled'
  | 'additionalTerms'
  | 'hiddenTerms'
  | 'displayName'
  | 'customizationFlags';

export type Modifier = {
  [id: string]: {
    additionalDisplayNames?: string[];
    disabled?: boolean;
    additionalTerms?: string[];
    hiddenTerms?: string[];
    displayName?: string;
    customizationFlags: string[];
  };
};

type Props = {
  type: 'desktop' | 'portal' | 'wam';
};

export const EditSearch = ({ type }: Props) => {
  const [data, setData] = useState<IndexedContent[]>([]);
  const [selected, setSelected] = useState<IndexedContent>();
  const searchProps = useFormField({ type: 'text' });

  const { modalProps, triggerProps } = useModalControl();
  const customDisplayProps = useFormField({ type: 'text', value: '' }, [modalProps.show]);
  const customRouteProps = useFormField({ type: 'text', value: '' }, [modalProps.show]);

  const alert = useAlert();

  const desktopModifier: Modifier = useListener({
    ref: `desktop/modifier`,
    emptyState: {},
  });
  const portalModifier: Modifier = useListener({
    ref: `portal/modifier`,
    emptyState: {},
  });
  const modifier = type === 'desktop' ? desktopModifier : portalModifier;

  const fetch = async () => {
    const indexedRes = await firebaseDB.ref(`${type}/indexed`).once('value');
    const indexedData = indexedRes.val();
    const sortedData = indexedData.sort((a, b) => a.route.localeCompare(b.route));
    const additionalRes = await firebaseDB.ref(`${type}/additional-routes`).once('value');
    const additionalData = Object.entries(additionalRes.val() ?? {}).map(
      ([id, value]) => ({ ...(value as IndexedContent), id, isCustom: true })
    );
    setData([...sortedData, ...additionalData]);
  };

  useEffect(() => {
    fetch();
  }, [type]);

  const filteredData = useMemo(() => {
    return searchProps.value
      ? data.filter(
          (item: any) =>
            item.route.includes(searchProps.value) ||
            item.displayName.includes(searchProps.value)
        )
      : data;
  }, [searchProps.value, data.length]);

  const updateModifier = async (key, value) => {
    const ref = `${type}/modifier/${selected?.id}/${key}`;
    await firebaseDB.ref(ref).set(value);
  };

  const addCustomRoute = async () => {
    const route = customRouteProps.value;
    const displayName = customDisplayProps.value;

    if (route?.[0] !== '/') {
      alert.error(`Route needs to start with "/"`);
      return;
    }

    const routeAlreadyExists = data.find((item) => item?.route === route);
    if (routeAlreadyExists) {
      alert.error(`This route already exists.`);
      return;
    }

    await firebaseDB.ref(`${type}/additional-routes`).push({ displayName, route });
    modalProps.onClose();
    await fetch();
    alert.success(`Custom route added.`);
  };

  const removeRoute = async (id) => {
    try {
      await firebaseDB.ref(`${type}/additional-routes/${id}`).remove();
      alert.success(`Removed custom route.`);
      await fetch();
    } catch (err: any) {
      alert.error(`Error removing route.`);
      console.warn(err);
    }
  };

  return (
    <div css={container}>
      <ul>
        <SearchField
          {...searchProps}
          name="filter-routes"
          label="Filter Routes"
          placeholder=""
        />
        <NakedButton css={addRouteStyle} {...triggerProps}>
          Add Custom Route
        </NakedButton>
        <Modal {...modalProps}>
          <div css={modalContainer}>
            <Heading level={2}>Add Custom Route</Heading>
            <TextField {...customDisplayProps} name="display" label="Display Name" />
            <TextField {...customRouteProps} name="route" label="Route" />
            <Text>
              Routes have to begin with a "/" and cannot be edited after being saved.
            </Text>
            <PrimaryButton onClick={addCustomRoute}>Add Route</PrimaryButton>
          </div>
        </Modal>
        {filteredData.map((item: any) => (
          <li key={item.id} onClick={() => setSelected(item)}>
            <div>
              <Text weight="bold" as="span">
                {modifier?.[item.id]?.displayName || item.displayName}
              </Text>
              <Text color="light">{item.route}</Text>
            </div>
            <div css={removeBtn}>
              {item.isCustom && (
                <IconButton label="Remove Route" onClick={() => removeRoute(item.id)}>
                  <XIconSmall />
                </IconButton>
              )}
              {modifier?.[item.id]?.disabled && <Text color="light">Disabled</Text>}
            </div>
          </li>
        ))}
      </ul>
      {!!Object.keys(selected ?? {}).length && (
        <Details
          modifier={modifier}
          updateModifier={updateModifier}
          selected={selected}
        />
      )}
    </div>
  );
};
