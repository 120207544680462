import { connect } from 'react-redux';
import { CallBlocking } from './call-blocking/call-blocking.component';
import {
  addBlockedNumber,
  deleteBlockedNumber,
  getBlockedNumbers,
} from '../../../redux/actions/call-blocking';
import { selectCurrentLocationId } from '../../../redux/actions/location/current-location';

export const CallBlockingContainer = connect(
  (state: any) => ({
    blockedNumbers: state.callBlocking.blockedNumbers,
    locationId: selectCurrentLocationId(state),
    loading: state.callBlocking.loading,
  }),
  {
    getBlockedNumbers,
    deleteBlockedNumber,
    addBlockedNumber,
  }
)(CallBlocking);
