export const currencyFormatter = (amount = 0) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    // Set the following two options (minimumFractionDigits & maximumFractionDigits) to 0
    // to remove decimals and round to whole numbers instead.
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return formatter.format(amount);
};

export const upperCaseWord = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};
