import { uniq, without } from 'lodash';
import { Reducer } from 'redux';
import { LocationsState } from '../../locations/locations.types';

export enum LocationPhoneNumbersActionTypes {
  Add = 'LocationPhoneNumbers:Add',
  Remove = 'LocationPhoneNumbers:Remove',
}

export type AddLocationPhoneNumbersAction = {
  type: LocationPhoneNumbersActionTypes.Add;
  payload: { locationId: string; phoneNumberIds: string[] };
};

export type RemoveLocationPhoneNumbersAction = {
  type: LocationPhoneNumbersActionTypes.Remove;
  payload: { locationId: string; phoneNumberIds: string[] };
};

export type LocationPhoneNumbersActions =
  | AddLocationPhoneNumbersAction
  | RemoveLocationPhoneNumbersAction;

export const addPhoneNumbersToLocation = (
  locationId: string,
  phoneNumberIds: string[]
): LocationPhoneNumbersActions => ({
  type: LocationPhoneNumbersActionTypes.Add,
  payload: { locationId, phoneNumberIds },
});

export const removePhoneNumbersFromLocation = (
  locationId: string,
  phoneNumberIds: string[]
): LocationPhoneNumbersActions => ({
  type: LocationPhoneNumbersActionTypes.Remove,
  payload: { locationId, phoneNumberIds },
});

export const locationPhoneNumbersReducer: Reducer<
  LocationsState,
  LocationPhoneNumbersActions
> = (state = {}, action) => {
  if (!action.payload) {
    return state;
  }

  const location = state[action.payload.locationId];
  const prevPhoneNumberIds = location?.phoneNumberIds ?? [];
  if (!location) {
    return state;
  }

  const phoneNumberIds =
    {
      [LocationPhoneNumbersActionTypes.Add]: () =>
        uniq([...prevPhoneNumberIds, ...action.payload.phoneNumberIds]),
      [LocationPhoneNumbersActionTypes.Remove]: () =>
        without(prevPhoneNumberIds, ...action.payload.phoneNumberIds),
    }[action.type]?.() ?? prevPhoneNumberIds;

  return {
    ...state,
    [location.LocationID]: { ...location, phoneNumberIds },
  };
};
