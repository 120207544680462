import { useEffect, useMemo, useState } from 'react';

type KeyFilterParams = {
  item: any;
  key: string;
  value: any;
};

export function useFilter<T = any>(list: T[]) {
  const [filters, setFilters] = useState({});
  const [filteredList, setFilteredList] = useState<T[]>([]);

  useEffect(() => {
    let nextList: T[] = list;
    if (Object.keys(filters).length) {
      nextList = list.filter((item) => {
        return Object.entries(filters).every(([key, value]) =>
          handleKeyFilter({ item, key, value })
        );
      });
    }
    setFilteredList(nextList);
  }, [list, Object.values(filters).toString()]);

  const handleKeyFilter = ({ item, key, value }: KeyFilterParams) => {
    switch (key) {
      case 'autogeneratedBy':
        if ((value && item[key]) || (!value && !item[key])) return true;
        return false;
      case 'reminderId':
        return item['autogeneratedBy'] === value;
      case 'typeId':
        return item['relatedIds'][0].id === value;
      default:
        return item[key] === value;
    }
  };

  const removeFilter = (key) => {
    setFilters((filter) => {
      const newFilter = { ...filter };
      delete newFilter[key];
      return newFilter;
    });
  };

  const addFilter = (key, value) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  return {
    filteredList,
    filters,
    addFilter,
    removeFilter,
    clearFilters: () => setFilters({}),
  };
}

export function useDedupedData<T = any>(list: T[], keys: string[]) {
  const dedupe = (key: string) => {
    const values = list.map((item) => getValueForSelectKey({ item, key }));
    return Array.from(new Set(values)).filter((item) => item);
  };
  return useMemo(
    () => keys.reduce((obj, key) => ({ ...obj, [key]: dedupe(key) }), {}),
    [list]
  );
}

function getValueForSelectKey({ item, key }) {
  switch (key) {
    case 'reminderId':
      return item['autogeneratedBy'];
    case 'typeId':
      if (item['relatedIds'] && item['relatedIds'].length > 0)
        return item['relatedIds'][0].id;
      break;
    default:
      return item[key];
  }
}
