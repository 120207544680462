import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { actionBtn, copied } from './patient-history-lists/patient-list.style';

interface Props {
  uuid: string | undefined;
}

export const UuidCopier = ({ uuid }: Props) => {
  const [isCopied, setIsCopied] = useState(false);

  const updateCopy = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  const displayValue = uuid ? `${uuid.slice(0, 4)}...` : 'N/A';

  return (
    <CopyToClipboard text={uuid} onCopy={updateCopy}>
      <p className={actionBtn}>
        {displayValue} {isCopied && <span className={copied}>copied</span>}
      </p>
    </CopyToClipboard>
  );
};
