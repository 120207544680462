import React, { useState } from 'react';
import { css } from '@emotion/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { WeaveTheme } from '@weave/theme-original';
import { CopyIcon, Text } from '@weave/design-system';

const copyInfoStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const copyIconStyle = (theme: WeaveTheme) => css`
  cursor: pointer;
  margin: ${theme.spacing(0, 1)};

  &:hover:not(:disabled),
  &:focus {
    svg {
      fill: ${theme.colors.pressedBlue};
    }
  }
`;

const clipboardDiv = css`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

type Props = {
  text: string;
};

export const CopyInfo = ({ text }: Props) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const copiedCredential = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  return (
    <div css={copyInfoStyle}>
      <CopyToClipboard text={text} onCopy={copiedCredential}>
        <div css={clipboardDiv}>
          <Text as="span" color="light">
            {text}
          </Text>
          <CopyIcon css={copyIconStyle} />
        </div>
      </CopyToClipboard>
      {isCopied && (
        <Text as="span" color="success">
          Copied
        </Text>
      )}
    </div>
  );
};
