import { Reducer } from 'redux';
import { keyBy, omit } from 'lodash';
import { FwdNumbersActionTypes } from './fwd-numbers.types';
import { FwdNumbersActions, FwdNumberModel } from '.';

export type FwdNumbersState = { [id: string]: FwdNumberModel };
export const fwdNumbersReducer: Reducer<FwdNumbersState, FwdNumbersActions> = (
  state = {},
  action
) => {
  switch (action.type) {
    case FwdNumbersActionTypes.Add:
      return keyBy(
        [...Object.values(state), ...action.payload],
        (fwdNumber) => fwdNumber.id
      );
    case FwdNumbersActionTypes.Remove:
      return omit(state, action.payload);
    case FwdNumbersActionTypes.Update:
      return { ...state, [action.payload.id]: action.payload };
    case FwdNumbersActionTypes.UpdateMultiple:
      return {
        ...state,
        ...keyBy(action.payload, (fwdNumber) => fwdNumber.id),
      };
    default:
      return state;
  }
};
