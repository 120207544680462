import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { AuditContext } from './audit.context';
import { filtersContainer } from './styles/filters';
import { FilterKeys, Filters } from './audit.types';
import { Text, ErrorIconSmall } from '@weave/design-system';

export const AuditFilters = () => {
  const { location } = useHistory();
  const { filters, eventQuery, setFilters } = useContext(AuditContext);
  const filterKeys: FilterKeys[] = ['Location', 'Time', 'User', 'Action', 'Resource'];

  const deleteFilter = (key: string, value: string, filtersCopy: Filters) => {
    const index = filtersCopy?.[key].indexOf(value);

    if (index !== -1) filtersCopy?.[key].splice(index, 1);
    if (filtersCopy?.[key].length === 0) delete filtersCopy?.[key];
    setFilters(
      Object.keys(filtersCopy).length === 0 ? undefined : { ...filters, ...filtersCopy }
    );
  };

  return (
    <section css={filtersContainer} className="divider pb-3">
      <Text weight="bold">Active Filters</Text>
      <div className="filters">
        {filterKeys.map(
          (key) =>
            filters?.[key] && (
              <div key={key} className="filter">
                <span className="filter-key">{key}</span>
                {filters[key]?.map((v, i) => (
                  <div key={i} className="filter-value">
                    {v.display}

                    {!location.pathname.includes('/audits') && key === 'Location'
                      ? null
                      : eventQuery.status !== 'loading' && (
                          <ErrorIconSmall
                            size={12}
                            onClick={() => deleteFilter(key, v, filters)}
                          />
                        )}
                  </div>
                ))}
              </div>
            )
        )}
      </div>
    </section>
  );
};
