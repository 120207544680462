import { useCallback, useRef, useReducer } from 'react';
import { QueryKey, QueryOptions, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { selectCurrentLocationId } from '../../redux/actions/location/current-location';

export const useQueryWithSetter = <T = any>(
  key: QueryKey,
  getter: () => Promise<T>,
  options: QueryOptions<T> = {}
) => {
  const keyWithLocation: QueryKey = [
    useSelector(selectCurrentLocationId),
    ...(typeof key === 'string' ? [key] : key),
  ];
  const [_, rerender] = useReducer((state) => !state, false);

  const dataRef = useRef<T | undefined>();
  const { data: queryData, ...queryResult } = useQuery(keyWithLocation, getter, options);

  dataRef.current = queryData;
  const client = useQueryClient();

  const set = useCallback(
    (newData: T | undefined, updateCache = true) => {
      if (updateCache) {
        client.setQueryData(keyWithLocation, newData);
      }
      dataRef.current = newData;
      rerender();
    },
    [client]
  );

  return { ...queryResult, data: dataRef.current, set };
};
