import { OutageAlert } from '../../../components/outage-alerts/outage-alerts.types';
import {
  GetActiveOutageAlertsAction,
  OutageAlertActionTypes,
  SetActiveOutageAction,
} from './outage-alerts.types';

/**
 * @desc : Action for gettting active outage alerts from firebase/firestore
 */
export const getActiveOutageAlerts = (): GetActiveOutageAlertsAction => ({
  type: OutageAlertActionTypes.GET_ACTIVE_OUTAGE_ALERTS,
  payload: '',
});

/**
 * @desc : Action for setting firebase Token details in redux
 * @param data : OutageAlert[]
 */
export function setActiveOutageAlerts(data: OutageAlert[]): SetActiveOutageAction {
  return {
    type: OutageAlertActionTypes.SET_ACTIVE_OUTAGE_ALERTS,
    payload: data,
  };
}
