import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { CustomAxios, getResponseData } from '../../axios/axios';
import { createRequestSaga } from '@weave/alert-system';
import {
  FwdNumberModel,
  FwdNumberModelNoId,
  FwdNumbersActionTypes,
  PartialFwdNumber,
} from './fwd-numbers.types';
import { selectFwdNumber } from './fwd-numbers.selectors';
import { selectCurrentLocationId } from '../location/current-location';
import {
  addFwdNumbersToLocation,
  removeFwdNumbersFromLocation,
} from '../location/location-entities/location-fwd-numbers';
import { ResolvedPromise } from '../../store/store-utils';

type Action<ActionType extends FwdNumbersActionTypes, Payload = undefined> = {
  type: ActionType;
} & { payload: Payload };
type RequestListAction = Action<FwdNumbersActionTypes.RequestList>;
type RequestGetAction = Action<FwdNumbersActionTypes.RequestGet, string>;
type RequestUpdateAction = Action<
  FwdNumbersActionTypes.RequestUpdate,
  { id: string; update: PartialFwdNumber }
>;
type RequestDeleteAction = Action<FwdNumbersActionTypes.RequestDelete, string>;
type RequestCreateAction = Action<
  FwdNumbersActionTypes.RequestCreate,
  FwdNumberModelNoId
>;
type AddAction = Action<FwdNumbersActionTypes.Add, FwdNumberModel[]>;
type RemoveAction = Action<FwdNumbersActionTypes.Remove, string[]>;
type UpdateAction = Action<FwdNumbersActionTypes.Update, FwdNumberModel>;
type UpdateMultipleAction = Action<
  FwdNumbersActionTypes.UpdateMultiple,
  FwdNumberModel[]
>;

export type FwdNumbersActions =
  | RequestListAction
  | RequestGetAction
  | RequestUpdateAction
  | RequestDeleteAction
  | RequestCreateAction
  | AddAction
  | RemoveAction
  | UpdateAction
  | UpdateMultipleAction;

export const requestListFwdNumbers = (): RequestListAction => ({
  type: FwdNumbersActionTypes.RequestList,
  payload: undefined,
});
export const requestGetFwdNumber = (id: string): RequestGetAction => ({
  type: FwdNumbersActionTypes.RequestGet,
  payload: id,
});
export const requestUpdateFwdNumber = (
  id: string,
  update: FwdNumberModel
): RequestUpdateAction => ({
  type: FwdNumbersActionTypes.RequestUpdate,
  payload: { id, update },
});
export const requestDeleteFwdNumber = (id: string): RequestDeleteAction => ({
  type: FwdNumbersActionTypes.RequestDelete,
  payload: id,
});
export const requestCreateFwdNumber = (
  fwdNumber: FwdNumberModelNoId
): RequestCreateAction => ({
  type: FwdNumbersActionTypes.RequestCreate,
  payload: fwdNumber,
});

export const addFwdNumbers = (fwdNumbers: FwdNumberModel[]): AddAction => ({
  type: FwdNumbersActionTypes.Add,
  payload: fwdNumbers,
});
export const removeFwdNumbers = (ids: string[]): RemoveAction => ({
  type: FwdNumbersActionTypes.Remove,
  payload: ids,
});
export const updateFwdNumber = (fwdNumber: FwdNumberModel): UpdateAction => ({
  type: FwdNumbersActionTypes.Update,
  payload: fwdNumber,
});
export const updateMultipleFwdNumbers = (
  fwdNumbers: FwdNumberModel[]
): UpdateMultipleAction => ({
  type: FwdNumbersActionTypes.UpdateMultiple,
  payload: fwdNumbers,
});

const api = {
  getFwdNumbers: (): Promise<FwdNumberModel[]> =>
    CustomAxios.get(`support/v1/forwarding`).then(getResponseData),
  getFwdNumber: (fwdNumberId: string): Promise<FwdNumberModel> =>
    CustomAxios.get(`support/v1/forwarding/${fwdNumberId}`).then(getResponseData),
  updateFwdNumber: (fwdNumber: FwdNumberModel): Promise<FwdNumberModel> =>
    CustomAxios.put(`support/v1/forwarding/${fwdNumber.id}`, fwdNumber).then(
      getResponseData
    ),
  deleteFwdNumber: (fwdNumberId: string): Promise<FwdNumberModel> =>
    CustomAxios.delete(`support/v1/forwarding/${fwdNumberId}`).then(getResponseData),
  createFwdNumber: (fwdNumber: FwdNumberModelNoId): Promise<FwdNumberModel> =>
    CustomAxios.post(`support/v1/forwarding/`, fwdNumber).then(getResponseData),
  disableFwdNumbers: (locationId): Promise<any> =>
    CustomAxios.put(`support/v1/fraud/${locationId}/disable`),
  enableFwdNumbers: (locationId): Promise<any> =>
    CustomAxios.put(`support/v1/fraud/${locationId}/enable`),
  rebootFwdNumber: (sipUsername: string): Promise<any> =>
    CustomAxios.post(`support/v1/phone/reboot`, { Sipusername: sipUsername }),
  rebootAllFwdNumbers: (): Promise<any> => CustomAxios.post(`support/v1/phone/rebootAll`),
};

const listFwdNumbersSaga = createRequestSaga<RequestListAction>({
  key: FwdNumbersActionTypes.RequestList,
  displayErrors: true,
  onError: (err) => `Failed to load fwdNumber: ${err.message}`,
  saga: function* () {
    const fwdNumbers: ResolvedPromise<ReturnType<typeof api.getFwdNumbers>> = yield call(
      api.getFwdNumbers
    );
    const locationId: ReturnType<typeof selectCurrentLocationId> = yield select(
      selectCurrentLocationId
    );
    yield put(addFwdNumbers(fwdNumbers));
    yield put(
      addFwdNumbersToLocation(locationId, fwdNumbers?.map((num) => num.id) ?? [])
    );
  },
});
const getFwdNumberSaga = createRequestSaga<RequestGetAction>({
  key: FwdNumbersActionTypes.RequestGet,
  displayErrors: true,
  onError: (err) => `Failed to load fwdNumber: ${err.message}`,
  saga: function* ({ payload }) {
    const fwdNumber: ResolvedPromise<ReturnType<typeof api.getFwdNumber>> = yield call(
      api.getFwdNumber,
      payload
    );
    yield put(addFwdNumbers([fwdNumber]));
  },
});
const updateFwdNumberSaga = createRequestSaga<RequestUpdateAction>({
  key: FwdNumbersActionTypes.RequestUpdate,
  displayErrors: true,
  onError: (err) => `Failed to load fwdNumber: ${err.message}`,
  saga: function* ({ payload }) {
    const { fwdNumber }: ReturnType<ReturnType<typeof selectFwdNumber>> = yield select(
      selectFwdNumber(payload.id)
    );
    const newFwdNumber = { ...fwdNumber, ...payload.update };
    yield call(api.updateFwdNumber, newFwdNumber);
    yield put(updateFwdNumber(newFwdNumber));
  },
});
const deleteFwdNumberSaga = createRequestSaga<RequestDeleteAction>({
  key: FwdNumbersActionTypes.RequestDelete,
  displayErrors: true,
  onError: (err) => `Failed to load fwdNumber: ${err.message}`,
  saga: function* ({ payload }) {
    const locationId: ReturnType<typeof selectCurrentLocationId> = yield select(
      selectCurrentLocationId
    );
    yield call(api.deleteFwdNumber, payload);
    yield put(removeFwdNumbers([payload]));
    yield put(removeFwdNumbersFromLocation(locationId, [payload]));
  },
});
const createFwdNumberSaga = createRequestSaga<RequestCreateAction>({
  key: FwdNumbersActionTypes.RequestCreate,
  displayErrors: true,
  onError: (err) => `Failed to load fwdNumber: ${err.message}`,
  saga: function* ({ payload }) {
    const fwdNumber: FwdNumberModel = yield call(api.createFwdNumber, payload);
    const locationId: ReturnType<typeof selectCurrentLocationId> = yield select(
      selectCurrentLocationId
    );
    yield put(addFwdNumbersToLocation(locationId, [fwdNumber.id]));
    yield put(addFwdNumbers([fwdNumber]));
  },
});

export const fwdNumbersSaga = function* () {
  yield all([
    takeLatest(FwdNumbersActionTypes.RequestList, listFwdNumbersSaga),
    takeLatest(FwdNumbersActionTypes.RequestGet, getFwdNumberSaga),
    takeLatest(FwdNumbersActionTypes.RequestUpdate, updateFwdNumberSaga),
    takeLatest(FwdNumbersActionTypes.RequestDelete, deleteFwdNumberSaga),
    takeLatest(FwdNumbersActionTypes.RequestCreate, createFwdNumberSaga),
  ]);
};
