import { omit } from 'lodash';

import { MediaActions, MediaActionTypes } from './media.actions';
import { PhoneMedia } from '../';

export type Media = PhoneMedia;

export type MediaState = { [id: string]: Media };

const enforceArray = (value: Media | Media[]): Media[] =>
  Array.isArray(value) ? value : [value];

export const mediaReducer = (
  state: MediaState = {},
  action: MediaActions
): MediaState => {
  switch (action.type) {
    case MediaActionTypes.Add:
      return enforceArray(action.payload.media).reduce(
        (obj, newMedia) => ({ ...obj, [newMedia[action.payload.key]]: newMedia }),
        state
      );
    case MediaActionTypes.Delete:
      return omit(state, action.payload);
    case MediaActionTypes.Update: {
      const { id, updates } = action.payload;

      return {
        ...state,
        [id]: {
          ...state[id],
          ...updates,
        },
      };
    }
    default:
      return state;
  }
};
