import React from 'react';
import { css } from 'emotion';

import { InfoTooltip } from '../../../../shared/info-tooltip/info-tooltip.component';

export enum FaxInfoFlag {
  success = 'success',
  failure = 'failure',
  inProgress = 'inProgress',
  neutral = 'neutral',
}

export type InfoProps = {
  title: string;
  infoMessage: string;
  statusFlag?: FaxInfoFlag;
  tooltipInfo?: any;
  showInfo?: boolean;
};

export const FaxDisplayInfo = (props: InfoProps) => {
  const status = props.statusFlag || 'neutral';
  return (
    <div
      className={css`
        display: flex;
        align-items: center;
        height: 70px;
      `}
    >
      <div className="fax-info-title">{props.title}</div>
      {(status === 'success' || status === 'failure' || status === 'inProgress') && (
        <div className={`round-circle fax-circle-${status}`} />
      )}
      <span className={`fax-info-text ${status}`}>{props.infoMessage}</span>
      {props.tooltipInfo && props.showInfo && (
        <InfoTooltip body={props.tooltipInfo} tooltipPlacement="right" />
      )}
    </div>
  );
};
