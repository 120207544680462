import { theme } from '@weave/theme-original';
import { css } from 'emotion';

export const multiSyncAppContainer = css`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 18px;
  margin-top: 18px;
`;

export const collapseIcon = css`
  transition: all 0.5s ease-in-out;
  position: absolute;
  top: 9px;
  right: 0;
  width: 20px;
  height: 20px;
  color: black;
`;

export const flexRadioStyle = css`
  display: flex;
  align-items: center;
  margin-left: 32px;
  font-size: 14px;
`;

export const containerMarginStyle = css`
  padding: ${theme.spacing(2)};
`;

export const containerAccess = css`
  margin-left: 24px;
  display: flex;
  margin-bottom: 36px;
  align-items: center;
`;

export const collapseIconInverted = css`
  transform: rotateX(180deg);
`;

export const multiSyncAppName = css`
  color: #333b3d;
  margin-right: 8px;
`;

export const multiSyncAppColor = css`
  color: #999d9e;
`;

export const accessContainer = css`
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 10px 0 #dee2e3;
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const accessName = css`
  color: #00d0f3;
  font-size: 20px;
  font-weight: bold;
`;

export const containerMargin = css`
  margin-left: 12px;
  display: flex;
  margin-bottom: 32px;
  align-items: center;
`;

export const pmContainer = css`
  font-size: 16px;
  font-weight: bold;
  width: 250px;
`;

export const sourceName = css`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

export const dataContainer = css`
  display: flex;
  align-items: center;
`;

export const radioGrouping = css`
  display: flex;
  align-items: center;
  padding-left: 25px;
  padding-right: 32px;
`;

export const radioGroup = css`
  display: flex;
  align-item: center;
  & > div {
    margin-bottom: 0;
    margin-right: 30px;
    & > label {
      font-size: 14px;
    }
  }
`;

export const flex = css`
  display: flex;
  align-items: center;
`;

export const flexBetween = (isSyncApps: boolean | undefined) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: ${theme.spacing(3)};
  ${!isSyncApps
    ? `top: 48px;  position: sticky; z-index:1; background:${theme.colors.white};`
    : null}
  & svg {
    path {
      fill: #a4a8b0;
    }
  }
`;

export const cardContainer = (isSyncApps: boolean | undefined, isOpen: boolean) => css`
  background-color: ${isSyncApps && isOpen
    ? theme.colors.gray100
    : isSyncApps
    ? '#F4F5F7'
    : theme.colors.white};
  margin-top: ${isSyncApps ? theme.spacing(1) : theme.spacing(2)};
  margin-bottom: ${theme.spacing(3)};
`;

export const multiSyncAppLocation = css`
  display: flex;
  margin-top: ${theme.spacing(2)};
  margin-bottom: ${theme.spacing(2)};
  border-bottom: 1px solid ${theme.colors.white};
  padding: ${theme.spacing(3)};
`;

export const multiSyncAppText = css`
  display: flex;
  align-items: center;
  width: 25%;
  font-size: 1.5em;
  margin: 0;
`;

export const customHeaderWidth = css`
  width: 50%;
`;

export const cardContent = css`
  padding: 0 ${theme.spacing(3)};
`;

export const integrationLocation = (isLocationsAddress: boolean) => css`
  display: flex;
  align-self: center;
  height: 50px;
  > span:first-child {
    width: 25%;
    display: inline-flex;
    align-items: center;
  }
  > div:nth-child(2) {
    width: 25%;
    display: ${!isLocationsAddress ? 'flex' : 'block'};
    ${!isLocationsAddress ? 'align-items:center' : ''};
  }
`;

export const topBorder = css`
  border-top: 1px dotted ${theme.colors.gray500};
`;

export const addressText = css`
  color: ${theme.colors.gray500};
  font-size: ${theme.fontSize(14)};
  margin: 0;
`;

export const checkBoxFieldContainer = (isChildLocation: boolean) => css`
  display: flex;
  align-items: center;
  ${isChildLocation ? `border-top: 1px dotted ${theme.colors.gray500};` : ''}
  & label {
    font-size: ${theme.fontSize(14)};
  }
  > div {
    width: 200px;
  }
`;

export const mRightSpacing = (spacing = 2) => css`
  margin-right: ${theme.spacing(spacing)};
`;

export const mLeftSpacing = (spacing = 1) => css`
  margin-left: ${theme.spacing(spacing)};
`;

export const noPaddingMargin = css`
  padding: 0;
  margin: 0;
`;

export const locationDetailsText = css`
  display: flex;
`;

export const accessHeader = css`
  align-items: center;
`;
