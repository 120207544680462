import React from 'react';
import { useSelector } from 'react-redux';
import { Option, Select, Headline, Svg, Body } from '@weave/platform-ui-components';
import { useAlert } from '@weave/alert-system';
import { find } from 'lodash';

import * as styles from './phone-tree-greeting-section.styles';
import { CustomAxios } from '../../../../redux/axios';
import { PhoneTreeModel } from '../phone-tree.models';
import { Store } from '../../../../redux/store/store.model';
import { selectCurrentLocationId } from '../../../../redux/actions/location/current-location';
import { selectAuthToken } from '../../../../redux/actions/auth/auth.selectors';
import { PhoneMedia } from '../../../../redux/actions';

interface Props {
  phoneTree: PhoneTreeModel;
  phoneMedia: PhoneMedia[];
  updatePhoneTreeMediaId: (mediaId: string) => void;
}

export const PhoneTreeGreetingSection = ({
  phoneTree,
  phoneMedia,
  updatePhoneTreeMediaId,
}: Props) => {
  const { locationId, token } = useSelector((state: Store) => ({
    locationId: selectCurrentLocationId(state),
    token: selectAuthToken(state),
  }));
  const alerts = useAlert();
  const onMediaChange = async (mediaId: string) => {
    const oldMediaId = phoneTree.MediaID;

    if (mediaId === oldMediaId) {
      return;
    }

    // Optimistically update the mediaId
    updatePhoneTreeMediaId(mediaId);

    try {
      await CustomAxios.put(`support/v1/ivrmenus/${phoneTree.IVRMenuID}`, {
        MediaID: mediaId,
      });

      alerts.success('Phone tree greeting updated successfully');
    } catch {
      updatePhoneTreeMediaId(oldMediaId);
      alerts.error(
        `Error changing phone tree greeting. Please refresh the page and try again.`
      );
    }
  };

  const renderMediaFileOptions = () => {
    const orderedMediaFiles = [...phoneMedia].sort((a, b) => {
      const lowerA = a.FileName.toLowerCase();
      const lowerB = b.FileName.toLowerCase();

      if (lowerA > lowerB) {
        return 1;
      } else if (lowerB > lowerA) {
        return -1;
      }

      return 0;
    });

    return orderedMediaFiles.map((file) => {
      let fileName = file.FileName.replace(/.wav|.mp3|.mp4|.wma|.ogg|.m4a/, '');

      fileName = fileName.substring(fileName.length - 32, fileName.length);

      return (
        <Option key={file.MediaID} value={file.MediaID}>
          {fileName}
        </Option>
      );
    });
  };

  const renderAudio = () => {
    const mediaFile = find(phoneMedia, { MediaID: phoneTree.MediaID });

    if (!mediaFile) {
      return;
    }

    return (
      <audio
        controls
        src={`${mediaFile.FilePath}?token=${token}&location_id=${locationId}`}
        className={styles.audio}
        preload="metadata"
      >
        Your browser does not support the audio tag.
      </audio>
    );
  };

  return (
    <div>
      <div className={styles.section}>
        <Headline className={styles.subtitleContainer}>
          <Svg icon="PhoneCall" color="gray9" className={styles.subtitleIcon} />
          Menu Prompt
        </Headline>
        <Body className={styles.subtitleHelpText}>
          Select the audio file you want people to hear when they reach your Phone Tree.
        </Body>
      </div>
      <div className={styles.greetingContainer}>
        <Select
          className={styles.mediaDropdown}
          placeholder="Select greeting"
          value={phoneTree.MediaID}
          onChange={(mediaId: string) => onMediaChange(mediaId)}
          name="phoneTreeGreetingSelect"
        >
          {renderMediaFileOptions()}
        </Select>
        {renderAudio()}
      </div>
    </div>
  );
};
