import { useResource } from '../../../shared/hooks/use-resource.hook';
import { useMemo } from 'react';

interface Params {
  [key: string]: string;
}

type response = { data: { data } };

export const useWritebacks = (params: Params) => {
  const queryParams = useMemo(
    () =>
      Object.entries(params).reduce(
        (prev, [key, value]) => `${prev}&${key}=${value}`,
        '?'
      ),
    [Object.values(params).toString()]
  );

  const data: response = useResource({
    url: `/support/v1/writebacks${queryParams}`,
    deps: [queryParams],
  });

  const writebacks = data?.data?.data;

  return writebacks;
};
