import React from 'react';
import { css } from 'emotion';

import { Loader } from './loader.component';

const loaderStyles = css`
  margin: 20px auto;
`;

interface Props {
  loading: boolean;
  children: any;
}

export const ConditionalLoader = ({ loading, children }: Props) => {
  if (loading) {
    return (
      <div className={loaderStyles}>
        <Loader />
      </div>
    );
  }

  return children;
};
