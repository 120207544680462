import { call, put, takeLatest } from 'redux-saga/effects';
import { createRequestSaga, createRequestStateSelector } from '@weave/alert-system';
import { createSelector } from 'reselect';
import { Store } from '../../store/store.model';
import * as api from './api';
import {
  selectCurrentLocationId,
  selectCurrentLocationTimeZone,
} from '../location/current-location';
import { NotificationsSettings } from '../notifications-settings/notification-settings.types';
import { SmsModel } from '../../../models/sms-history.model';

// actions
export enum SmsHistoryActionTypes {
  Fetch = 'GET_MESSAGES_HISTORY',
  Set = 'SET_MESSAGES_HISTORY',
  Update = 'UPDATE_MESSAGES_HISTORY',
}

export type FetchSmsHistoryPayload = {
  end?: string;
  limit?: number;
  location_id?: string;
  order?: string;
  person_ids?: string;
  skip?: number;
  start?: string;
  typeId?: string;
  commxMigrationFlag?: boolean;
};

type FetchSmsHistoryAction = {
  type: SmsHistoryActionTypes.Fetch;
  payload?: FetchSmsHistoryPayload;
};

type SmsHistoryHistoryActions =
  | { type: SmsHistoryActionTypes.Set; payload: SmsModel[] }
  | FetchSmsHistoryAction;

export const fetchSmsHistory = (
  payload?: FetchSmsHistoryPayload
): SmsHistoryHistoryActions => ({
  type: SmsHistoryActionTypes.Fetch,
  payload,
});

export const setSmsHistory = (payload: SmsModel[]): SmsHistoryHistoryActions => ({
  type: SmsHistoryActionTypes.Set,
  payload: payload,
});

// reducer

export type SmsHistoryState = SmsModel[];

export const smsHistoryReducer = (
  state: SmsHistoryState = [],
  action: SmsHistoryHistoryActions
): SmsHistoryState => {
  switch (action.type) {
    case SmsHistoryActionTypes.Set:
      return action.payload;
    default:
      return state;
  }
};

// sagas

export const getMessageHistory = createRequestSaga<FetchSmsHistoryAction>({
  key: SmsHistoryActionTypes.Fetch,
  onError: () => 'Failed to load messages',
  saga: function* ({ payload }) {
    const yieldSmsHistory = yield call(api.requestSmsHistory, payload);
    yield put(setSmsHistory(yieldSmsHistory.smsList));
  },
});

export function* smsHistorySaga() {
  yield takeLatest(SmsHistoryActionTypes.Fetch, getMessageHistory);
}

// selectors
export const selectSmsHistory = (state: Store) => state.smsHistory;

const selectSmsHistoryRequestState = createRequestStateSelector(
  SmsHistoryActionTypes.Fetch
);

export type SmsHistoryData = {
  smsHistory: SmsHistoryState;
  loading: boolean;
  currentLocation: string;
  notificationSettings: NotificationsSettings;
  timezone: string;
};

export const selectSmsHistoryDashboard = createSelector(
  [selectSmsHistory, selectSmsHistoryRequestState],
  (smsHistory, { loading }) => ({
    data: smsHistory,
    loading,
  })
);

export const selectSmsHistoryData = createSelector(
  [
    selectSmsHistory,
    selectSmsHistoryRequestState,
    selectCurrentLocationId,
    selectCurrentLocationTimeZone,
    (state: Store) => state.notificationsSettings?.categories,
  ],
  (
    smsHistory,
    { loading },
    currentLocation,
    timezone,
    notificationsSettings
  ): SmsHistoryData => ({
    smsHistory,
    loading,
    currentLocation,
    timezone,
    notificationSettings: notificationsSettings ?? {},
  })
);
