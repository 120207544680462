import { createAction } from 'redux-actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { CustomAxios, getErrorMessage, getResponseData } from '../../axios';
import { Endpoint, getEndpoint } from './endpoints';
import { showError } from '@weave/alert-system';
import { getPaymentUrl } from '../merchant/merchant.action';

export const getHistory = createAction('GET_HISTORY');
export const getUnfilteredHistory = createAction('GET_UNFILTERED_HISTORY');
export const getHistorySuccess = createAction('GET_HISTORY_SUCCESS');
export const unsetLoading = createAction('UNSET_LOADING');

const handleGetHistory = function* (action) {
  const { title, locationId, searchParams, formatter } = action.payload;
  let endpoint = getEndpoint(title, locationId);

  // Some endpoints may need to hit a different URL. For now, there is only one.
  if (title === Endpoint.invoices) {
    const url = yield call(getPaymentUrl);
    endpoint = url + endpoint;
  }

  try {
    const response = yield call(CustomAxios.get, endpoint, searchParams);
    const data =
      (response?.data && formatter(response.data)) || getResponseData(response) || [];
    yield put(getHistorySuccess(data));
  } catch (error) {
    yield put(showError(getErrorMessage(error)));
    yield put(unsetLoading());
  }
};

// This is used for endpoints that don't support server-side filtering
const handleGetUnfilteredHistory = function* (action) {
  try {
    const { endpoint, locationId } = action.payload;
    const response = yield call(CustomAxios.get, getEndpoint(endpoint, locationId));
    yield put(getHistorySuccess(response.data.data));
  } catch (error: any) {
    yield put(showError(getErrorMessage(error)));
  }
};

export const getHistorySaga = function* () {
  yield takeEvery(getHistory.toString(), handleGetHistory);
  yield takeEvery(getUnfilteredHistory.toString(), handleGetUnfilteredHistory);
};
