import { handleActions } from 'redux-actions';
import {
  getAvailableJobTitles,
  getAvailableJobTitlesSuccess,
  setLoading,
} from './user-job-titles.action';

const initialState = {
  data: [],
  loading: false,
};

const userAvailableJobTitlesReducer = handleActions(
  {
    [getAvailableJobTitles.toString()]: (state, action) =>
      Object.assign({}, state, { loading: true }),
    [getAvailableJobTitlesSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { data: action.payload }),
    [setLoading.toString()]: (state, action) =>
      Object.assign({}, state, { loading: action.payload }),
  },
  initialState
);

export default userAvailableJobTitlesReducer;
