import React, { useState } from 'react';
import { css } from '@emotion/core';
import { Modal, PrimaryButton, Text } from '@weave/design-system';
import { AccountData } from './multi-location.types';
import { deletePreprovisions } from './multi-location.resource';

interface Props {
  accountData: AccountData[];
  setShowModal: (val: boolean) => void;
  showModal: boolean;
  setIsLoading: (val: boolean) => void;
  getData: () => void;
}

export const MultiModal = (props: Props) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const sendWCForm = async () => {
    const accounts = props.accountData.filter((account) => account.selected);
    const accountsToDelete = props.accountData.filter(
      (account) => !account.selected && account.provisioned
    );
    await deletePreprovisions(accountsToDelete);
    window.open(
      `https://weave.my.salesforce.com/servlet/servlet.Integration?scontrolCaching=1&lid=00b1N000001KQJg&eid=${accounts[0].id}`,
      '_blank'
    );
    setShowSuccessModal(true);
  };

  const closeModal = () => {
    setShowSuccessModal(false);
    props.setShowModal(false);
    props.setIsLoading(true);
    props.getData();
  };

  return (
    <Modal css={() => modalStyles} show={props.showModal} onClose={closeModal}>
      <Modal.Body>
        {showSuccessModal ? (
          <div>
            <Text css={() => titleText} weight="bold" textAlign="center">
              Success!
            </Text>
            <Text size="large" color="light" textAlign="center">
              Your Welcome Call Form has been successfully sent. Please follow up with the
              POC to complete the form.
            </Text>
            <div css={() => buttonContainer}>
              <PrimaryButton css={() => buttonStyles} size="large" onClick={closeModal}>
                Close
              </PrimaryButton>
            </div>
          </div>
        ) : (
          <div>
            <Text css={() => titleText} weight="bold" textAlign="center">
              Send Welcome Call Form
            </Text>
            <div css={() => containerStyles}>
              <Text weight="bold" textAlign="center">
                Welcome Call Form Summary
              </Text>
              <div>
                <Text color="light">Parent Account</Text>
                <Text>Parent Account</Text>
              </div>

              <div css={() => containerStyles}>
                <Text color="light">{`Locations (${
                  props.accountData.filter((account) => account.selected).length
                })`}</Text>
                {props.accountData
                  .filter((account) => account.selected)
                  .map((account) => (
                    <Text key={account.name}>{account.name}</Text>
                  ))}
              </div>

              <div css={() => containerStyles}>
                <Text color="light">Annual Recurring Revenue (ARR)</Text>
                <Text>
                  $
                  {props.accountData
                    .filter((account) => account.selected)
                    .reduce((acc, val) => acc + val.bookings, 0)
                    .toLocaleString()}
                </Text>
              </div>
            </div>
            <div css={() => buttonContainer}>
              <PrimaryButton
                css={() => buttonStyles}
                size="large"
                onClick={() => sendWCForm()}
              >
                Send WC Form
              </PrimaryButton>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

const titleText = css`
  font-size: 1.125rem;
`;

const buttonContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const buttonStyles = css`
  width: 300px;
  margin: 10px 0;
`;

const modalStyles = css`
  width: 500px;
`;

const containerStyles = css`
  margin: 20px 0;
`;
