import React from 'react';
import { cx, css } from 'emotion';
import { weaveBlue, weaveBlueDark } from '@weave/platform-ui-components';

interface Props {
  onLinkClick?: () => void;
  text: string;
}

export const PhoneTreeTextOption = ({ text, onLinkClick }: Props) => {
  const onClick = () => {
    if (onLinkClick) {
      return onLinkClick();
    }
  };

  return (
    <div
      className={cx(
        css`
          display: inline-block;
          margin-left: 24px;
          width: 246px;
        `,
        onLinkClick &&
          css`
            color: ${weaveBlue};
            cursor: pointer;
            transition: color 300ms;

            &:hover {
              color: ${weaveBlueDark};
            }
          `
      )}
      onClick={onClick}
    >
      {text}
    </div>
  );
};
