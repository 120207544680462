import { createAction, handleActions } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { CustomAxios } from '../../../redux/axios';
import { EventModel } from '../../../models/event.model';

export type EventPayload = string;

export type EventResultPayload = EventModel;

export const event = createAction<EventPayload>('EVENT');
export const eventSuccess = createAction<EventResultPayload>('EVENT_SUCCESS');
export const eventFailure = createAction<Error>('EVENT_FAILURE');

export const selectEvent = (state) => state.event;

export const handleEvent = function* (action) {
  try {
    const params = action.payload;
    const response = yield call(CustomAxios.get, `/support/v1/events${params}`);
    yield put(eventSuccess(response.data.data));
  } catch (error: any) {
    yield put(eventFailure(error));
  }
};

export const eventSaga = function* () {
  yield all([takeEvery(event.toString(), handleEvent)]);
};

export interface EventState {
  loading: boolean;
  data: EventModel;
  error?: Error;
}

export const initialEventState: EventState = {
  loading: false,
  data: {
    Description: '',
    DescriptionPMID: '',
    EventSubType: '',
    EventTime: '',
    EventType: '',
    ID: '',
    PMID: '',
    PersonID: '',
    Status: '',
  },
};

export const eventReducer = handleActions<EventState>(
  {
    [event.toString()]: (state) => Object.assign({}, state, { loading: true }),
    [eventSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { data: action.payload, loading: false }),
    [eventFailure.toString()]: (state, action) =>
      Object.assign({}, state, { error: action.payload, loading: false }),
  },
  initialEventState
);
