import React, { ElementType } from 'react';
import { Text } from '@weave/design-system';
import { theme, WeaveTheme } from '@weave/theme-original';
import { css } from '@emotion/core';
import { tint } from 'polished';

export type PaymentStatusColors =
  | keyof Omit<WeaveTheme['colors'], 'gray' | 'disabled'>
  | 'gray500';

type PaymentStatusChipProps = {
  color: PaymentStatusColors;
  children: string;
  as?: ElementType;
};

export const PaymentStatusChip = ({
  color,
  children,
  as = 'p',
  ...rest
}: PaymentStatusChipProps) => {
  const chipColor =
    color === 'gray500'
      ? theme.colors.gray[500]
      : theme.colors[color] ?? theme.colors.weaveBlue;

  return (
    <Text
      size="medium"
      weight="bold"
      css={css`
        color: ${chipColor};
        background-color: ${tint(0.93, chipColor)};
        border-radius: ${theme.borderRadius.small};
        margin: 0;
        padding: ${theme.spacing(0.5, 1)};
        flex: 0 0 auto;
        display: inline-flex;
      `}
      as={as}
      {...rest}
    >
      {children}
    </Text>
  );
};
