import React from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import {
  Heading,
  Text,
  FormRow,
  useControlledField,
  SwitchField,
  SecondaryButton,
  StarFilledIcon,
  SpinningLoader,
} from '@weave/design-system';
import { useState } from 'react';
import { PaymentMethod } from '../billing.types';
import { CarrotIconButton } from '../shared/carrot-icon-button';

type Props = {
  paymentMethod: PaymentMethod;
  loading: boolean;
  userHasBillingWritePermission: boolean;
  allowDelete: boolean;
  onMakeDefault: (paymentMethod: PaymentMethod) => void;
  onDelete: (paymentMethod: PaymentMethod) => void;
};

export const PaymentMethodCard = ({
  paymentMethod,
  loading,
  userHasBillingWritePermission,
  allowDelete,
  onMakeDefault,
  onDelete,
}: Props) => {
  const makeDefaultFieldProps = useControlledField({
    type: 'switch',
    value: paymentMethod.isDefault ?? false,
    onChange: () => onMakeDefault(paymentMethod),
  });

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <article css={[cardStyles, !isExpanded && nonExpandedCardStyles]}>
      <header css={headStyle}>
        <div css={defaultIconAndNameContainer}>
          {paymentMethod.isDefault && <StarFilledIcon css={defaultPaymentIcon} />}
          <Heading
            level={2}
            css={css`
              word-break: break-word;
            `}
          >
            {paymentMethod.name}
          </Heading>
        </div>

        <CarrotIconButton
          onClick={() => setIsExpanded((prev) => !prev)}
          label="Expand card"
          isCarrotUpState={isExpanded}
        />
      </header>

      <dl css={dlStyle}>
        <Text as="dt" color="light">
          {paymentMethod.type === 'ACH' ? 'Account Number' : 'Card Number'}
        </Text>
        <Text as="dd">{paymentMethod.hash}</Text>
        <Text as="dt" color="light">
          Payment Type
        </Text>
        <Text as="dd">{paymentMethod.type}</Text>
      </dl>

      {userHasBillingWritePermission && (
        <div css={[footerActionsContainer, isExpanded && expandedFooterActions]}>
          {loading && <SpinningLoader css={spinnerStyles} />}
          <div css={[loading && loadingOpacity]}>
            <FormRow
              css={css`
                &:last-child {
                  margin-bottom: 0;
                }
              `}
            >
              <SwitchField
                {...makeDefaultFieldProps}
                label="Make Primary Payment Method"
                name="makeDefault"
                disabled={paymentMethod.isDefault || loading}
              />
            </FormRow>
            {allowDelete && (
              <SecondaryButton disabled={loading} onClick={() => onDelete(paymentMethod)}>
                Delete Payment Method
              </SecondaryButton>
            )}
          </div>
        </div>
      )}
    </article>
  );
};

const cardStyles = css`
  background: #f4f4f4;
  border-radius: ${theme.borderRadius.medium};
  margin-bottom: ${theme.spacing(2)};
  margin-right: ${theme.spacing(2)};
  height: max-content;
`;

const nonExpandedCardStyles = css`
  padding-bottom: ${theme.spacing(2)};
`;

const headStyle = css`
  border-bottom: 1px solid ${theme.colors.gray400};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 100%;
  margin-bottom: ${theme.spacing(2)};
  padding: ${theme.spacing(2, 3, 1, 3)};

  h2 {
    margin: 0;
  }
`;

const defaultIconAndNameContainer = css`
  display: flex;
`;

const defaultPaymentIcon = css`
  fill: ${theme.colors.warning};
  margin-right: ${theme.spacing(1)};
`;

const dlStyle = css`
  margin: 0;
  padding: ${theme.spacing(0, 3)};

  dd {
    margin-bottom: ${theme.spacing(2)};
  }
`;

const footerActionsContainer = css`
  max-height: 0;
  transition: max-height 0.25s ease-out;
  overflow: hidden;
  position: relative;
  padding: ${theme.spacing(0, 3)};
`;

const expandedFooterActions = css`
  max-height: 500px;
  transition: max-height 0.25s ease-in, padding 0.25s ease-in;
  padding-bottom: ${theme.spacing(2)};
`;

const spinnerStyles = css`
  position: absolute;
  left: 35%;
  top: 20%;
`;

const loadingOpacity = css`
  opacity: 0.3;
`;
