import React from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import {
  ConfirmationModal,
  EditIcon,
  IconButton,
  Text,
  TrashIcon,
  useModalControl,
} from '@weave/design-system';
import { AppointmentTypeInterface } from './use-appointment-type.hook';

interface Props {
  appointmentType: AppointmentTypeInterface;
  remove: (id: string) => void;
  openEditModal: (editValues?: AppointmentTypeInterface) => void;
}

export const AppointmentType = (props: Props) => {
  const { appointmentType, openEditModal, remove } = props;
  const { modalProps: deleteModalProps, triggerProps: deleteTriggerProps } =
    useModalControl();

  const editAppointmentType = () => {
    openEditModal(appointmentType);
  };

  const handleRemove = async () => {
    await remove(appointmentType.ID);
    deleteModalProps.onClose();
  };

  return (
    <>
      <div
        css={css`
          display: flex;
          max-width: 640px;
          justify-content: space-between;
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <Text as="span">
          Name: <strong>{appointmentType.Name}</strong> - <i>(Minutes)</i>:{' '}
          <strong>{appointmentType.DurationMinutes} </strong>
        </Text>
        <div
          css={css`
            display: flex;
            margin-left: ${theme.spacing(2)};
          `}
        >
          <IconButton
            label="Delete Appointment"
            onClick={deleteTriggerProps.onClick}
            size="small"
          >
            <TrashIcon size={16} />
          </IconButton>
          <IconButton label="Edit Appointment" onClick={editAppointmentType} size="small">
            <EditIcon size={16} />
          </IconButton>
        </div>
      </div>
      <ConfirmationModal
        {...deleteModalProps}
        onConfirm={handleRemove}
        title="Delete appointment type"
        message="Are you sure you want to delete this appointment type?"
      />
    </>
  );
};
