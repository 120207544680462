import { handleActions } from 'redux-actions';
import { Task } from '../../../components/onboarding-container/onboarding-task.model';
import {
  getOnboarderSchedulingLinksFailure,
  getOnboarderSchedulingLinksSuccess,
  onboardingGetTasks,
  onboardingGetTasksFailure,
  onboardingGetTasksSuccess,
  onboardingSetCurrentTask,
  getOnboarders,
  getOnboardersSuccess,
  getOnboardersFailure,
  getOnboarderSchedulingLinks,
  deleteOnboarder,
  deleteOnboarderSuccess,
  deleteOnboarderFailure,
} from './onboarding-tasks.action';

const defaultState = {
  tasks: [],
  loading: false,
  currentTask: {},
  onboarders: [],
  getOnboardersLoading: false,
  loadingLinks: false,
  deleteLoading: false,
  schedulingLinks: {
    scheduleCustomizationLink: '',
    scheduleNetworkLink: '',
    scheduleSoftwareInstallLink: '',
    schedulePhoneInstallLink: '',
    scheduleSoftwareTrainingLink: '',
    schedulePhoneTrainingLink: '',
    salesforceUserId: '',
    id: '',
    deletedAt: '',
  },
};

export type Onboarder = { name: string; email: string };

export type OnboardingState = {
  tasks: Task[];
  loading: boolean;
  currentTask: Task;
  onboarders: Onboarder[];
  getOnboardersLoading: boolean;
  loadingLinks: boolean;
  deleteLoading: boolean;
  schedulingLinks: {
    scheduleCustomizationLink: string;
    scheduleNetworkLink: string;
    scheduleSoftwareInstallLink: string;
    schedulePhoneInstallLink: string;
    scheduleSoftwareTrainingLink: string;
    schedulePhoneTrainingLink: string;
    schedulePortingLink: string;
    salesforceUserId: string;
    id: string;
    deletedAt: string;
  };
};

export const reducer = handleActions(
  {
    [deleteOnboarder.toString()]: (state, action) =>
      Object.assign({}, state, { deleteLoading: true }),
    [deleteOnboarderSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { deleteLoading: false }),
    [deleteOnboarderFailure.toString()]: (state, action) =>
      Object.assign({}, state, { deleteLoading: false }),
    [onboardingGetTasks.toString()]: (state, action) =>
      Object.assign({}, state, { loading: true }),
    [onboardingGetTasksSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { loading: false, tasks: action.payload }),
    [onboardingGetTasksFailure.toString()]: (state, action) =>
      Object.assign({}, state, { loading: false, tasks: [] }),
    [getOnboarders.toString()]: (state, action) =>
      Object.assign({}, state, { getOnboardersLoading: true }),
    [getOnboardersSuccess.toString()]: (state, action) =>
      Object.assign({}, state, {
        getOnboardersLoading: false,
        onboarders: action.payload,
      }),
    [getOnboardersFailure.toString()]: (state, action) =>
      Object.assign({}, state, { getOnboardersLoading: false, onboarders: [] }),
    [onboardingSetCurrentTask.toString()]: (state, action) =>
      Object.assign({}, state, { currentTask: action.payload }),
    [getOnboarderSchedulingLinks.toString()]: (state, action) =>
      Object.assign({}, state, { loadingLinks: true }),
    [getOnboarderSchedulingLinksSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { schedulingLinks: action.payload, loadingLinks: false }),
    [getOnboarderSchedulingLinksFailure.toString()]: (state, action) =>
      Object.assign({}, state, {
        schedulingLinks: {
          scheduleCustomizationLink: '',
          scheduleNetworkLink: '',
          scheduleSoftwareTrainingLink: '',
          schedulePhoneTrainingLink: '',
          salesforceUserId: '',
          id: '',
          deletedAt: '',
        },
        loadingLinks: false,
      }),
  },
  defaultState
);
