import React from 'react';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/core';
import { WeaveTheme } from '@weave/theme-original';
import {
  FormFieldActionTypes,
  FormRow,
  Modal,
  ModalControlModalProps,
  SwitchField,
  TimeField,
  useForm,
} from '@weave/design-system';

import { putOfficeHours } from '../../../../../redux/actions/office-hours/office-hours.action';
import { OfficeHoursModel } from '../../models';

interface Props {
  modalProps: ModalControlModalProps;
  officeHours: OfficeHoursModel;
  onClose: () => void;
  dayOfWeek: string;
}

// Wraps the Modal to provide automatic state cleanup
export const OfficeHoursEditModal = (props: Props) => {
  return (
    <Modal {...props.modalProps} maxWidth={500}>
      <OfficeHoursEdit {...props} />
    </Modal>
  );
};

// TODO: Add custom validation to prevent using the same value for start and stop
// Probably should also make sure stop is greater than start.
// Unfortunately, custom validators against multiple fields have a bug right now, so we can't do that yet.
const OfficeHoursEdit = ({ onClose, dayOfWeek, officeHours }: Props) => {
  const dispatch = useDispatch();

  const initialOfficeIsOpen = officeHours.start !== officeHours.stop;
  const { formProps, getFieldProps, values, isComplete } = useForm({
    fields: {
      officeOpen: { type: 'switch', value: initialOfficeIsOpen },
      start: { type: 'time', value: officeHours.start },
      stop: { type: 'time', value: officeHours.stop },

      lunchOpen: {
        type: 'switch',
        value: initialOfficeIsOpen && officeHours.lunchStart !== officeHours.lunchStop,
      },
      lunchStart: { type: 'time', value: officeHours.lunchStart },
      lunchStop: { type: 'time', value: officeHours.lunchStop },

      breakOpen: {
        type: 'switch',
        value: initialOfficeIsOpen && officeHours.breakStart !== officeHours.breakStop,
      },
      breakStart: { type: 'time', value: officeHours.breakStart },
      breakStop: { type: 'time', value: officeHours.breakStop },
    },
    fieldStateReducer: (state, action) => {
      // If Open switches off, we need to turn off Lunch and Break as well
      if (
        action.type === FormFieldActionTypes.Update &&
        action.payload.name === 'officeOpen' &&
        action.payload.value === false
      ) {
        return {
          ...state,
          lunchOpen: {
            ...state.lunchOpen,
            value: action.payload.value && state.lunchOpen,
          },
          breakOpen: {
            ...state.breakOpen,
            value: action.payload.value && state.breakOpen,
          },
        };
      }
      return null;
    },
    onSubmit: (values) => {
      const emptyTime = '00:00:00';
      const officeHours: OfficeHoursModel = {
        start: (values.officeOpen && values.start) || emptyTime,
        stop: (values.officeOpen && values.stop) || emptyTime,
        lunchStart: (values.lunchOpen && values.lunchStart) || emptyTime,
        lunchStop: (values.lunchOpen && values.lunchStop) || emptyTime,
        breakStart: (values.breakOpen && values.breakStart) || emptyTime,
        breakStop: (values.breakOpen && values.breakStop) || emptyTime,
      };

      dispatch(
        putOfficeHours({
          officeHours,
          day: dayOfWeek,
        })
      );
      onClose();
    },
  });

  /* 
    TODO: There is a TimeRange component coming to the design system. This should be replaced when that is ready.

    BUG: For some time values, the TimeField misinterprets the input value. 
    For example, 00:30:00 should be 12:30am. However it is being interpreted as 3:00am. 
  */
  const interval = 15;
  return (
    <form {...formProps}>
      <Modal.Header>{dayOfWeek}</Modal.Header>
      <Modal.Body
        css={(theme: WeaveTheme) => css`
          padding-top: ${theme.spacing(2)};
          height: 410px;
        `}
      >
        <p>* For more granular control, you can type directly into the fields</p>
        <FormRow>
          <SwitchField {...getFieldProps('officeOpen')} label="Open / Close" />
        </FormRow>
        {values.officeOpen && (
          <FormRow>
            <TimeField {...getFieldProps('start')} label="Open" interval={interval} />
            <TimeField {...getFieldProps('stop')} label="Close" interval={interval} />
          </FormRow>
        )}
        <FormRow>
          <SwitchField {...getFieldProps('lunchOpen')} label="Lunch" />
        </FormRow>
        {values.lunchOpen && (
          <FormRow>
            <TimeField
              {...getFieldProps('lunchStart')}
              label="Open"
              interval={interval}
            />
            <TimeField
              {...getFieldProps('lunchStop')}
              label="Close"
              interval={interval}
            />
          </FormRow>
        )}
        <FormRow>
          <SwitchField {...getFieldProps('breakOpen')} label="Break" />
        </FormRow>
        {values.breakOpen && (
          <FormRow>
            <TimeField
              {...getFieldProps('breakStart')}
              label="Open"
              interval={interval}
            />
            <TimeField
              {...getFieldProps('breakStop')}
              label="Close"
              interval={interval}
            />
          </FormRow>
        )}
      </Modal.Body>
      <Modal.Actions
        primaryLabel="Save"
        onPrimaryClick="submit"
        disablePrimary={!isComplete}
        onSecondaryClick={onClose}
      />
    </form>
  );
};
