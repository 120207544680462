import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/core';
import { WeaveTheme } from '@weave/theme-original';
import { Modal, Text } from '@weave/design-system';
import {
  createSyncApp,
  selectIntegrationsSyncAppCount,
  selectLoadingSyncApps,
  selectLoginCredentialsPassword,
  selectLoginCredentialsUsername,
} from '../../../../redux/actions/sync-app';
import { Store } from '../../../../redux/store/store.model';
import { selectHasWeaveAcl } from '../../../../redux/actions/auth/auth.selectors';
import { CopyCredential } from './copy-credential.component';
import { CoreACLs } from '../../../../redux/actions/auth/auth.types';

interface Props {
  closeModal: () => void;
}

export const SyncAppCreate = ({ closeModal }: Props) => {
  const syncAppUsername = useSelector(selectLoginCredentialsUsername);
  const syncAppPassword = useSelector(selectLoginCredentialsPassword);
  const integrationSyncAppCount = useSelector(selectIntegrationsSyncAppCount);
  const loadingSyncApps = useSelector(selectLoadingSyncApps);
  const installAdvancedPermission = useSelector((state: Store) =>
    selectHasWeaveAcl(state, CoreACLs.SYNCAPPINSTALLADVANCED)
  );
  const dispatch = useDispatch();

  const handleCreateSyncApp = () => {
    dispatch(createSyncApp());
  };

  const renderSyncAppCredentials = () => {
    return (
      <>
        <Text
          textAlign="center"
          css={(theme: WeaveTheme) =>
            css`
              margin-top: ${theme.spacing(2)};
            `
          }
        >
          Sync app created successfully. <br /> The information for the new sync app is
          listed below
        </Text>

        <CopyCredential title="Username" value={syncAppUsername} />
        <CopyCredential title="Password" value={syncAppPassword} />
      </>
    );
  };

  return (
    <>
      <Modal.Header>Create Sync App</Modal.Header>

      <Modal.Body>
        <Text
          color="white"
          weight="bold"
          css={(theme: WeaveTheme) =>
            css`
              padding: ${theme.spacing(1)};
              background-color: ${theme.colors.error};
            `
          }
        >
          Do NOT do this unless you know what you're doing.
        </Text>

        {syncAppUsername && renderSyncAppCredentials()}
        {!loadingSyncApps && integrationSyncAppCount > 0 && !installAdvancedPermission && (
          <Text
            color="white"
            css={(theme: WeaveTheme) =>
              css`
                padding: ${theme.spacing(1)};
                background-color: ${theme.colors.weaveBlue};
              `
            }
          >
            If another sync app is needed please reach out to Integrations.
          </Text>
        )}
      </Modal.Body>

      <Modal.Actions
        disablePrimary={
          loadingSyncApps ||
          !!syncAppUsername ||
          (integrationSyncAppCount > 0 && !installAdvancedPermission)
        }
        onPrimaryClick={handleCreateSyncApp}
        primaryLabel={integrationSyncAppCount === 0 ? 'Create' : 'Create Another'}
        secondaryLabel="Cancel"
        onSecondaryClick={closeModal}
      />
    </>
  );
};
