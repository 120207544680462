export enum SetupState {
  AuthSuccess = 'AuthSuccess',
  AuthFailure = 'AuthFailure',
  IntegrationSuccess = 'IntegrationSuccess',
  IntegrationFailure = 'IntegrationFailure',
  HasUnIntegrated = 'HasUnIntegrated',
  NeverAuthenticated = '',
}

export enum AccountStatus {
  Active = 'active',
  Archived = 'archived',
}

export enum ExtendedAccountStatus {
  Active,
  Archived,
  NotSetup,
  Error,
}

export enum SiteName {
  Google = 'google',
  Facebook = 'facebook',
}
