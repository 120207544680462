import firebase from '../../config/outage-alerts-firebase-config';
import { OutageAlert } from './outage-alerts.types';

// configuration of firestore db collection
export const db = firebase.firestore().collection('service_outages');

/**
 * @desc: Function is defined to perform a get request in firestore db.
 * @param applicationName : string
 * @returns Promise
 * @note : This function will not provide any realtime updates as its just a get request
 *         for real-time updates we need onSnapshot function.
 */
export const getOutageAlertsByPortal = (applicationName) => {
  return db.where('application', '==', applicationName).get();
};

/**
 * @desc: Function is defined to add document in firestore DB
 * @param documentData : Object
 * @returns Promise
 */
export const addDocumentData = (documentData) => {
  return db.doc().set(documentData);
};

/**
 * @desc: Function is defined to update a specific document by document id in firestore db
 * @param documentData : Object
 * @param documentId : string
 * @returns Promise
 */
export const updatedDocumentData = (documentData: OutageAlert, documentId) => {
  return db.doc(documentId).update(documentData);
};

/**
 * @desc: Function is defined to delete a specific document by document id in firestore db
 * @param documentId : string
 * @returns Promise
 */
export const deleteDocumentData = (documentId) => {
  return db.doc(documentId).delete();
};
