import { createAction, handleActions } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { PatientsModel } from '../../../models/patient.model';
import { CustomAxios } from '../../axios';

export type PatientsPayload = {
  locationId: string;
  criteria: {
    search?: string;
    status?: string;
  };
};

export type PatientsResultPayload = any;

export const patients = createAction<PatientsPayload>('PATIENTS');
export const patientsSuccess = createAction<PatientsResultPayload>('PATIENTS_SUCCESS');
export const patientsFailure = createAction<Error>('PATIENTS_FAILURE');

export const handlePatients = function* (action) {
  try {
    const locationId = action.payload.locationId;
    const search = action.payload.criteria.search ?? '';
    const status = action.payload.criteria.status;
    const response = yield call(
      CustomAxios.get,
      `/support/v1/locations/${locationId}/persons?search=${search}&status=${status}`
    );
    yield put(patientsSuccess(response.data.data));
  } catch (error: any) {
    yield put(patientsFailure(error));
  }
};

export const patientsSaga = function* () {
  yield all([takeEvery(patients.toString(), handlePatients)]);
};

const defaultState = {
  data: [],
};

export type PatientsState = {
  data: PatientsModel[];
};

export const patientsReducer = handleActions(
  {
    [patients.toString()]: (state) => Object.assign({}, state, { loading: true }),
    [patientsSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { data: action.payload, loading: false }),
    [patientsFailure.toString()]: (state, action) =>
      Object.assign({}, state, { error: action.payload, loading: false }),
  },
  defaultState
);
