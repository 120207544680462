import { createAction } from 'redux-actions';
import { Moment } from 'moment';
import { call, put, takeEvery } from 'redux-saga/effects';
import { CustomAxios } from '../../../../axios';
import { AnalyticsOnboardingRequest } from '../find-onboarding-request/find-onboarding-request.action';

export type UpdateOnboardingRequestPayload = {
  demoDate?: Moment;
  saleDate?: Moment;
  status?: string;
  note?: string;
};

export const updateAnalyticsOnboardingRequest =
  createAction<UpdateOnboardingRequestPayload>('UPDATE_ANALYTICS_ONBOARDING_REQUEST');
export const updateAnalyticsOnboardingRequestSuccess =
  createAction<AnalyticsOnboardingRequest>('UPDATE_ANALYTICS_ONBOARDING_REQUEST_SUCCESS');
export const updateAnalyticsOnboardingRequestFailure = createAction<Error>(
  'UPDATE_ANALYTICS_ONBOARDING_REQUEST_FAILURE'
);

export const handleUpdateOnboardingRequest = function* (action) {
  try {
    const { status, demoDate, saleDate, note } = action.payload;
    const body = {
      status,
      note,
      demoDate: demoDate ? demoDate.toISOString() : null,
      saleDate: saleDate ? saleDate.toISOString() : null,
    };
    const response = yield call(
      CustomAxios.put,
      '/support/v1/analytics-admin/onboarding/request',
      body
    );
    yield put(updateAnalyticsOnboardingRequestSuccess(response.data.data));
  } catch (e: any) {
    yield put(updateAnalyticsOnboardingRequestFailure(e));
  }
};

export const updateOnboardingRequestSaga = function* () {
  yield takeEvery(
    updateAnalyticsOnboardingRequest.toString(),
    handleUpdateOnboardingRequest
  );
};
