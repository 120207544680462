import { createAction, handleActions } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { CustomAxios } from '../../../../../src/redux/axios';
import { AppointmentModel } from '../../../../models/appointment.model';

export type AppointmentPayload = string;

export type AppointmentResultPayload = AppointmentModel;

export const appointment = createAction<AppointmentPayload>('APPOINTMENT');
export const appointmentSuccess =
  createAction<AppointmentResultPayload>('APPOINTMENT_SUCCESS');
export const appointmentFailure = createAction<Error>('APPOINTMENT_FAILURE');

export const selectAppointment = (state) => state.appointment;

export const handleAppointment = function* (action) {
  try {
    const apptID = action.payload;
    const response = yield call(CustomAxios.get, `/support/v2/appointments/${apptID}`);
    yield put(appointmentSuccess(response.data.data));
  } catch (error: any) {
    yield put(appointmentFailure(error));
  }
};

export const appointmentSaga = function* () {
  yield all([takeEvery(appointment.toString(), handleAppointment)]);
};

export interface AppointmentStateModel {
  loading: boolean;
  data: AppointmentModel;
  error?: Error;
}

const defaultState: AppointmentStateModel = {
  loading: false,
  data: {
    AppointmentID: '',
    AppointmentPMID: '',
    PersonID: '',
    Length: 0,
    Operatory: '',
    Status: '',
    StatusPM: '',
    ConfirmationStatus: '',
    Type: '',
    EntryDate: '',
    SourceID: '',
    AppointmentDate: '',
    Provider: '',
    ClientLocationID: '',
  },
};

export const appointmentReducer = handleActions(
  {
    [appointment.toString()]: (state, action) =>
      Object.assign({}, state, { loading: true }),
    [appointmentSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { data: action.payload, loading: false }),
    [appointmentFailure.toString()]: (state, action) =>
      Object.assign({}, state, { error: action.payload, loading: false }),
  },
  defaultState
);
