import { handleActions } from 'redux-actions';
import { Authorization, defaultState } from '../auth.types';
import {
  getUser,
  getUserFailure,
  getUserSuccess,
  updateUser,
  updateUserFailure,
  updateUserSuccess,
} from './user.action';

export const userReducer = handleActions<Authorization, any>(
  {
    [updateUser.toString()]: (state: any) => {
      return Object.assign({}, state, { loading: true, success: false, error: false });
    },
    [updateUserSuccess.toString()]: (state: any, action) => {
      return Object.assign({}, state, { loading: false, success: true, error: false });
    },
    [updateUserFailure.toString()]: (state: any, action) => {
      return Object.assign(
        {},
        state,
        { loading: false, success: false },
        { error: action.payload }
      );
    },

    [getUser.toString()]: (state: any) => {
      return Object.assign({}, state, {
        getLoading: true,
        getSuccess: false,
        getError: false,
      });
    },
    [getUserSuccess.toString()]: (state: any, action) => {
      return Object.assign(
        {},
        state,
        { getLoading: false, getSuccess: true, getError: false },
        action.payload
      );
    },
    [getUserFailure.toString()]: (state: any, action) => {
      return Object.assign(
        {},
        state,
        { getLoading: false, getSuccess: false },
        { getError: action.payload }
      );
    },
  },
  defaultState
);
