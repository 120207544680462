import React, { useEffect } from 'react';
import { WeaveTheme } from '@weave/theme-original';
import { NumberField, Text, useFormField } from '@weave/design-system';
import { Product } from '../../sales-pricing.types';
import { css } from '@emotion/core';
import { currencyFormatter } from '../../pricing-calculator/pricing-calculator.helper';

interface Props {
  productData: Product;
  initialQuantity?: number;
  getProductServiceFeeText: (product: Product) => string;
  onPhoneProductQuantityChanged: (phoneProduct: Product, quantity: number) => void;
}

export const PhoneCard = ({
  productData,
  initialQuantity,
  getProductServiceFeeText,
  onPhoneProductQuantityChanged,
}: Props) => {
  const amountfieldProps = useFormField({
    type: 'number',
    value: initialQuantity?.toString(),
  });

  const hasAmount = () => {
    return !!amountfieldProps.value;
  };

  useEffect(() => {
    onPhoneProductQuantityChanged(productData, +amountfieldProps.value);
  }, [amountfieldProps.value]);

  return (
    <div css={(theme) => [cardStyles, hasAmount() && activeCardStyles(theme)]}>
      <div css={textGroupStyles}>
        <Text css={titleStyles} size="large">
          {productData.displayName}
        </Text>
        <Text css={priceText} size="small" color="light">
          {`${currencyFormatter(productData.price)}/phone +`}
        </Text>
        <Text size="small" color="light">
          {getProductServiceFeeText(productData)}
        </Text>
      </div>

      <div css={imgContainerStyles}>
        <img
          css={imageStyles}
          src={productData.image}
          alt={`${productData.displayName}`}
        />
      </div>

      <NumberField
        name="amount"
        label="Amount"
        {...amountfieldProps}
        id={`${productData.label}Input`}
        data-testid={`${productData.label}Input`}
      />
    </div>
  );
};

const cardStyles = css`
  width: 207px;
  min-height: 256px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  margin-right: 24px;
  margin-bottom: 24px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;

  @media only screen and (max-width: 450px) {
    margin-right: 0;
  }
`;
const activeCardStyles = (theme: WeaveTheme) => css`
  border: 2px solid ${theme.colors.weaveBlue};
`;
const textGroupStyles = css`
  text-align: center;
  z-index: 10;
`;
const titleStyles = css`
  margin-bottom: 4px;
`;
const priceText = css`
  margin: 0%;
`;
const imgContainerStyles = css`
  height: 100px;
`;
const imageStyles = css`
  height: 115px;
  position: relative;
  bottom: 15px;
`;
