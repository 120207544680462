import React from 'react';
import { ElementType, HTMLAttributes, ReactNode } from 'react';
import { theme } from '@weave/theme-original';
import { css } from '@emotion/core';

type Props = HTMLAttributes<HTMLElement> & {
  as?: ElementType;
  children: ReactNode;
};

const cardStyle = css`
  background: ${theme.colors.white};
  padding: ${theme.spacing(2, 3)};
  box-shadow: ${theme.shadows.heavy};
  border-radius: ${theme.borderRadius.medium};
`;

export const Card = ({ as = 'div', children, ...rest }: Props) => {
  const Component = as;
  return (
    <Component css={cardStyle} {...rest}>
      {children}
    </Component>
  );
};
