// TODO add rest of color pallet and other general styles in here
export const weaveBlue = '#37CFF1';
export const white = '#FFF';
export const black = '#000';
export const mainGrey = '#fafcfc';
export const borderGrey = '#d4dbdf';

export const weavePrimary = '#37CFF1';

// I stole these colors from Bootstrap
export const weaveSecondary = '#6c757d';
export const weaveSuccess = '#28a745';
export const weaveInfo = '#17a2b8';
export const weaveDanger = '#dc3545';
export const weaveWarning = '#ffc107';
