import React from 'react';
import ReactModal from 'react-modal';
import { css, Global } from '@emotion/core';
import { theme } from '@weave/theme-original';

interface Props {
  onRequestClose: (event: React.MouseEvent<HTMLElement>) => void;
  children: any;
  isOpen?: boolean;
  className?: any;
  size?: string;
  title: React.ReactNode;
  footer?: React.ReactNode;
  warningText?: string;
}

export function Modal(props: Props) {
  let width = '90%';
  if (props.size === 'small') {
    width = '450px';
  } else if (props.size === 'medium') {
    width = '700px';
  }

  return (
    <>
      <Global
        styles={css`
          .weave-modal-overlay {
            position: fixed;
            background-color: rgba(0, 0, 0, 0.5);
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 999;
          }
          body.ReactModal__Body--open {
            overflow: hidden;
          }
        `}
      />
      <ReactModal
        isOpen={props?.isOpen ?? true}
        onRequestClose={props.onRequestClose}
        ariaHideApp={false}
        css={css`
          background: ${theme.colors.white};
          border: 1px solid #ccc;
          border-radius: ${theme.borderRadius.small};
          margin: auto;
          max-height: 90%;
          outline: none;
          overflow: auto;
          padding: 0;
          position: relative;
          top: 50px;
          width: ${width};
        `}
        className={props.className}
        overlayClassName="weave-modal-overlay"
        portalClassName="weave-modal-portal"
      >
        {typeof props.title === 'string' ? (
          <div
            css={css`
              background-color: #37cff1;
              color: #fff;
              padding: 20px;
            `}
          >
            <h4
              css={css`
                font-size: 1.25em;
                font-weight: 400;
              `}
            >
              {props.title}
              <span
                css={css`
                  float: right;
                  cursor: pointer;
                `}
                onClick={props.onRequestClose}
              >
                X
              </span>
            </h4>
          </div>
        ) : (
          <>{props.title}</>
        )}
        {props.warningText && (
          <div
            hidden={!props.warningText}
            css={css`
              background-color: #f2dede;
              color: #a94442;
              padding: 20px;
              font-size: 11px;
            `}
          >
            {props.warningText}
          </div>
        )}
        <div
          css={css`
            padding: 20px;
          `}
        >
          {props.children}
        </div>
        {props.footer}
      </ReactModal>
    </>
  );
}
