import React, { FC } from 'react';
import { css } from 'emotion';
import { Text } from '@weave/design-system';
import { weaveTheme } from '@weave/theme-original/dist/weaveTheme';

export const LocationAlert: FC<{ color: 'success' | 'danger' }> = (props) => {
  return (
    <Text
      color="white"
      className={css`
        background-color: ${props.color === 'success'
          ? `${weaveTheme.colors.success}`
          : `${weaveTheme.colors.error}`};
        padding: 4px 8px;
        border-radius: 12px;
      `}
    >
      <>{props.children}</>
    </Text>
  );
};
