import React, { ReactElement, useState } from 'react';
import { css } from '@emotion/core';
import { WeaveTheme } from '@weave/theme-original';
import { Heading, MinusIcon, PlusIcon } from '@weave/design-system';

type Props = {
  children: ReactElement;
  title: string;
};

const iconStyles = (theme: WeaveTheme) => css`
  margin: auto 0 auto ${theme.spacing(2)};
  cursor: pointer;
  &:hover {
    color: ${theme.colors.gray[500]};
  }
`;

export const SettingsCard = ({ title, children }: Props) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  return (
    <div
      css={(theme: WeaveTheme) => css`
        background-color: ${theme.colors['white']};
        box-shadow: ${theme.shadows['light']};
        border-radius: ${theme.borderRadius['medium']};
        padding: ${theme.spacing(3)};
        margin-bottom: ${theme.spacing(3)};
      `}
    >
      <header
        onClick={() => setIsActive(!isActive)}
        css={css`
          display: flex;
        `}
      >
        <Heading
          level={2}
          css={(theme: WeaveTheme) => css`
            margin: 0 auto 0 0;
            ${isActive && `margin-bottom: ${theme.spacing(1)};`}
          `}
        >
          {title}
        </Heading>
        {isActive ? <MinusIcon css={iconStyles} /> : <PlusIcon css={iconStyles} />}
      </header>
      <div
        css={css`
          display: none;
          opacity: 0;
          transition: opacity 0.5s ease-out;
          ${isActive &&
          `
            display: block;
            opacity: 1;
          `}
        `}
      >
        {children}
      </div>
    </div>
  );
};
