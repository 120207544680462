import { useResource } from '../../../shared/hooks/use-resource.hook';

interface OptOutItem {
  tenant: string;
  optedOut: boolean;
}

interface OptOutData {
  [key: string]: OptOutItem[];
}

export const usePersonOptOut = (personID: string): OptOutData => {
  const handleOptOutData = (response) => {
    const { data } = response.data;
    const optOutData: OptOutData = data.reduce((prev, item) => {
      const element = {
        tenant: item.TenantNumber,
        optedOut: item.OptedOut,
      };

      const data =
        item.PersonNumber in prev ? [...prev[item.PersonNumber], element] : [element];

      return {
        ...prev,
        [item.PersonNumber]: data,
      };
    }, {});
    return optOutData;
  };

  const { data: optOutData } = useResource({
    url: `/support/v1/phones/sms/optout/${personID}`,
    deps: [personID],
    emptyState: {},
    transformData: handleOptOutData,
  });

  return optOutData as OptOutData;
};
