import { call, select, CallEffect } from 'redux-saga/effects';
import moment from 'moment';

import { CustomAxios, getResponseData } from '../../../axios';
import { getFactorTestResults } from '../handoff-hub.action';
import { FactorLogicResult, FactorStatus, FactorTest } from '../handoff-hub.reducer';
import { selectCurrentLocationId } from '../../location';
import { ResolvedPromise } from '../../../store/store-utils';

const APPT_WRITEBACKS_PASS_AMOUNT = 5;

// checkStatusMapping
export const apptConfirmedStatusMapCriteria = `One status must be marked as "Confirmed" in Appointment Status Mapping`;
// checkWritebacksActive
export const writebacksCountsCriteria = `${APPT_WRITEBACKS_PASS_AMOUNT} appointments must be written back to the Database`;
export const setConfirmationMappingCriteria = 'Confirmation Mapping must be set';
// checkSyncWorking
export const syncAppCountCriteria = `Must have at least 1 sync app`;

export const dataSyncApi = {
  getAppointmentStatusMappings: () =>
    CustomAxios.get(`support/v1/notifications/status-mappings/appointment`).then(
      getResponseData
    ),
  getSyncAppHealth: () =>
    CustomAxios.get('support/v1/syncapp/health').then(getResponseData),
  getAppointments: (): Promise<any[]> =>
    CustomAxios.get(`/support/v1/appointments`, {
      params: {
        start: moment().subtract(1, 'week').format('YYYY-MM-DD'),
        end: moment().add(1, 'day').format('YYYY-MM-DD'),
      },
    })
      .then(getResponseData)
      .then((data) => data ?? ''),
  getWritebackStatusMappings: () =>
    CustomAxios.get(`support/v1/notifications/status-mappings/writeback`)
      .then(getResponseData)
      .then((data) => data ?? []),
  getLocationSettings: (
    locationId: string
  ): Promise<{ key: string; value: string | boolean }[]> =>
    CustomAxios.get(`support/v1/locations/${locationId}/settings`)
      .then(getResponseData)
      .then((data) => data ?? []),
};

export const checkSyncWorking = function* (): IterableIterator<
  FactorLogicResult | CallEffect
> {
  const tests: FactorTest[] = [];
  let factorStatus: FactorStatus = 'pass';
  let factorTest: FactorTest | undefined = undefined;

  const syncData: ResolvedPromise<ReturnType<typeof dataSyncApi.getSyncAppHealth>> =
    yield call(dataSyncApi.getSyncAppHealth);
  const syncApps = syncData?.SyncApps;

  [factorTest, factorStatus] = getFactorTestResults(
    factorStatus,
    syncAppCountCriteria,
    syncApps.length,
    () => !!syncApps && syncApps.length >= 1
  );
  tests.push(factorTest);

  for (const syncApp of syncApps) {
    const health = syncApp.SyncAppHealth;
    if (health !== 'Healthy' && health !== 'Unhealthy') {
      factorStatus = 'fail';
      tests.push({
        status: 'fail',
        name: `Practice Management System: "${syncApp.PracticeManagementSystem}" should not be "Dead"`,
        actual: health,
      });
    } else {
      tests.push({
        status: 'pass',
        name: `Practice Management System: "${syncApp.PracticeManagementSystem}" should not be "Dead"`,
        actual: health,
      });
    }
  }

  return { status: factorStatus, tests };
};

export const checkWritebacksActive = function* () {
  const tests: FactorTest[] = [];
  let factorStatus: FactorStatus = 'pass';
  let factorTest: FactorTest | undefined = undefined;

  const locationId: ReturnType<typeof selectCurrentLocationId> = yield select(
    selectCurrentLocationId
  );
  const settings: ResolvedPromise<ReturnType<typeof dataSyncApi.getLocationSettings>> =
    yield call(dataSyncApi.getLocationSettings, locationId);
  const writeBacksEnabled = settings.some(
    (setting) =>
      setting.key === 'allow_appointment_writebacks' && setting.value === 'true'
  );

  if (!writeBacksEnabled) {
    factorStatus = 'off';
    tests.push({
      status: 'off',
      name: `Writebacks are not enabled for this location`,
      actual: 'off',
    });
  } else {
    const appointments: ResolvedPromise<ReturnType<typeof dataSyncApi.getAppointments>> =
      yield call(dataSyncApi.getAppointments);

    [factorTest, factorStatus] = getFactorTestResults(
      factorStatus,
      writebacksCountsCriteria,
      appointments.length,
      () => appointments.length >= APPT_WRITEBACKS_PASS_AMOUNT
    );
    tests.push(factorTest);

    const statusMappingSettings: any = yield call(dataSyncApi.getWritebackStatusMappings);

    const writebacksMappingIsSet = statusMappingSettings?.some(
      (setting) => setting?.Mapping?.Confirmed?.length > 0
    );

    [factorTest, factorStatus] = getFactorTestResults(
      factorStatus,
      setConfirmationMappingCriteria,
      writebacksMappingIsSet ? 'set' : 'not set',
      () => writebacksMappingIsSet
    );
    tests.push(factorTest);
  }

  return { status: factorStatus, tests };
};

export const checkStatusMapping = function* () {
  const tests: FactorTest[] = [];
  let factorStatus: FactorStatus = 'pass';
  let factorTest: FactorTest | undefined = undefined;

  const statusMappingSettings: ResolvedPromise<
    ReturnType<typeof dataSyncApi.getAppointmentStatusMappings>
  > = yield call(dataSyncApi.getAppointmentStatusMappings);

  const confirmedMappingIsSet = statusMappingSettings?.some(
    (setting) => setting?.Mapping?.Confirmed?.length > 0
  );

  [factorTest, factorStatus] = getFactorTestResults(
    factorStatus,
    apptConfirmedStatusMapCriteria,
    confirmedMappingIsSet ? 'set' : 'not set',
    () => confirmedMappingIsSet
  );
  tests.push(factorTest);

  return { status: factorStatus, tests };
};
