import React, { useEffect, useRef } from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import {
  ExternalLinkIcon,
  CheckboxField,
  IconButton,
  useControlledField,
  useFormField,
  TextField,
  Heading,
} from '@weave/design-system';
import { CustomizationFlags } from './customization-flags';
import { SearchableTerms } from './searchable-terms';
import { IndexedContent } from '../header-container/wam-search/use-wam-search';
import { Modifier, ModifierKeys } from './edit-search';

const detailContainer = css`
  width: 32%;
  margin: ${theme.spacing(4)};
  h2 {
    margin: 0;
    padding: 0;
  }
`;

export const termStyle = (isLockedIn = false, isHidden = false) => css`
  width: max-content;
  margin: ${theme.spacing(0.25)};
  cursor: pointer;
  border: 1px solid ${theme.colors.gray400};
  border-radius: ${theme.borderRadius.small};
  padding: ${theme.spacing(0.5)};
  ${isLockedIn &&
  `
    border: 2px solid ${theme.colors.gray400};
    background-color: ${theme.colors.gray200};
  `}
  ${isHidden &&
  `
    background-color: ${theme.colors.error};
    color: white;
    text-decoration: line-through;
  `}
`;

const desktopLink = `https://release-desktop-client.gke1-west3.wsf-prod-1.wstack.net/#`;

type Props = {
  selected: IndexedContent;
  modifier: Modifier;
  updateModifier: (key: ModifierKeys, value: any) => void;
};

export const Details = ({ selected, modifier, updateModifier }: Props) => {
  const initialRef = useRef(false);
  const displayNameProps = useFormField({ type: 'text', value: selected?.displayName }, [
    selected?.route,
  ]);
  const disableProps = useControlledField({
    type: 'checkbox',
    onChange: (value) => {
      if (selected?.id) {
        updateModifier('disabled', value);
      }
    },
    value: modifier?.[selected?.id as string]?.disabled,
  });

  useEffect(() => {
    if (initialRef.current && displayNameProps.value !== selected?.displayName) {
      updateModifier('displayName', displayNameProps.value);
    }
    initialRef.current = true;
  }, [displayNameProps.value]);

  const viewLink = (path) => {
    window.open(`${desktopLink}${path}`, '_blank');
  };

  return (
    <>
      <div css={detailContainer}>
        <div
          css={css`
            border-bottom: 1px solid ${theme.colors.gray300};
            padding-bottom: ${theme.spacing(2)};
            margin-bottom: ${theme.spacing(4)};
          `}
        >
          <div
            css={css`
              display: flex;
              justify-content: space-between;
            `}
          >
            <Heading level={2}>{selected?.route}</Heading>
            <IconButton label="Edit Display" onClick={() => viewLink(selected?.route)}>
              <ExternalLinkIcon color="success" />
            </IconButton>
          </div>
          <TextField
            css={css`
              width: 350px;
              margin-bottom: ${theme.spacing(1)};
            `}
            label="Display Name"
            name="display-name"
            {...displayNameProps}
          />
          <CheckboxField
            label="Disable Route"
            name="disable-route"
            labelPlacement="left"
            {...disableProps}
          />
        </div>

        <SearchableTerms
          selected={selected}
          modifier={modifier}
          updateModifier={updateModifier}
        />

        {/* <AdditionalDisplayName
          route={selected.route}
          modifier={modifier}
          updateModifier={updateModifier}
        /> */}
      </div>
      <CustomizationFlags
        id={selected?.id as string}
        modifier={modifier}
        updateModifier={updateModifier}
      />
    </>
  );
};
