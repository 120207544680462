import React, { useReducer } from 'react';
import {
  Select,
  Option,
  Modal,
  Input,
  Button,
  Secondary,
  Group,
} from '@weave/platform-ui-components';
import { useAlert } from '@weave/alert-system';
import { useSelector, useDispatch } from 'react-redux';
import { find, isUndefined, get } from 'lodash';
import { css } from 'emotion';
import { selectCallForwardingNumbers } from '../../../../../redux/actions/call-forwarding';
import { formatPhoneNumber } from '../../../../../helpers/utils';
import { isPhoneNumber } from '../../../../../helpers/validators';
import { createCallForwardingNumber } from '../../../../../redux/actions/call-forwarding';
import { CustomAxios } from '../../../../../redux/axios';
import { CallForwardingNumber } from '../../../../../redux/actions/call-forwarding/call-forwarding.types';

interface Props {
  activeForwardingId: string;
  onForwardingNumberChange: (forwardNumber: CallForwardingNumber) => void;
}

interface State {
  addModalIsOpen: boolean;
  submitting: boolean;
  numberToAdd: string;
  numberToAddError: string;
  nameToAdd: string;
  nameToAddError: string;
}

const initialState: State = {
  addModalIsOpen: false,
  submitting: false,
  numberToAdd: '',
  numberToAddError: '',
  nameToAdd: '',
  nameToAddError: '',
};

enum actionTypes {
  openModal = 'openModal',
  closeModal = 'closeModal',
  startFormSubmission = 'startFormSubmission',
  endFormSubmission = 'endFormSubmission',
  clearFormErrors = 'clearFormErrors',
  updateNumberToAdd = 'updateNumberToAdd',
  newNumberToAddError = 'newNumberToAddError',
  updateNameToAdd = 'updateNameToAdd',
  newNameToAddError = 'newNameToAddError',
  reset = 'reset',
}

function reducer(state, action) {
  switch (action.type) {
    case actionTypes.openModal:
      return { ...state, addModalIsOpen: true };
    case actionTypes.closeModal:
      return { ...state, addModalIsOpen: false };
    case actionTypes.startFormSubmission:
      return { ...state, submitting: true };
    case actionTypes.endFormSubmission:
      return { ...state, submitting: false };
    case actionTypes.clearFormErrors:
      return { ...state, nameToAddError: '', numberToAddError: '' };
    case actionTypes.updateNumberToAdd:
      return { ...state, numberToAdd: action.payload, numberToAddError: '' };
    case actionTypes.newNumberToAddError:
      return { ...state, numberToAddError: 'Please enter a valid phone number' };
    case actionTypes.updateNameToAdd:
      return { ...state, nameToAdd: action.payload, nameToAddError: '' };
    case actionTypes.newNameToAddError:
      return { ...state, nameToAddError: 'Please enter a valid name' };
    case actionTypes.reset:
      return { ...initialState };
    default:
      return state;
  }
}

export const PhoneTreeCallForwardingOption = ({
  activeForwardingId,
  onForwardingNumberChange,
}: Props) => {
  const callForwardingNumbers = useSelector(selectCallForwardingNumbers);
  const [state, localDispatch] = useReducer(reducer, initialState);
  const {
    addModalIsOpen,
    submitting,
    numberToAdd,
    numberToAddError,
    nameToAdd,
    nameToAddError,
  } = state;
  const dispatch = useDispatch();
  const alerts = useAlert();

  const onChange = (id: string) => {
    const forwardNumber: CallForwardingNumber | undefined = find(callForwardingNumbers, {
      id,
    });

    if (!isUndefined(forwardNumber)) {
      onForwardingNumberChange(forwardNumber);
    }
  };

  const isFormValid = () => {
    let isValid = true;

    localDispatch({ type: actionTypes.clearFormErrors });

    if (!numberToAdd || !isPhoneNumber(numberToAdd)) {
      localDispatch({ type: actionTypes.newNumberToAddError });
      isValid = false;
    }

    if (!nameToAdd) {
      isValid = false;
      localDispatch({ type: actionTypes.newNameToAddError });
    }

    return isValid;
  };

  const onAddNumberSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (submitting) {
      return;
    }

    localDispatch({ type: actionTypes.startFormSubmission });

    if (!isFormValid()) {
      return localDispatch({ type: actionTypes.endFormSubmission });
    }

    // Create the forwarding number
    try {
      const response = await CustomAxios.post(`support/v1/forwarding/`, {
        name: nameToAdd,
        number: numberToAdd,
      });
      const newForwardingNumber: CallForwardingNumber = get(response, 'data.data');

      // Add it to the call forwarding list in redux
      dispatch(createCallForwardingNumber(newForwardingNumber));

      // Update the instruction with the new forwarding number
      onForwardingNumberChange(newForwardingNumber);
    } catch {
      alerts.error('Failed to add forwarding number. Please try again.');
    }

    localDispatch({ type: actionTypes.reset });
  };

  return (
    <div
      className={css`
        display: inline-block;
        margin-left: 24px;
        width: 246px;
      `}
    >
      <Modal
        className={css`
          .react-modal-card {
            padding: 50px;
          }
        `}
        isOpen={addModalIsOpen}
        onRequestClose={() => localDispatch({ type: actionTypes.reset })}
        title="Add Forwarding Number"
      >
        <form
          onSubmit={onAddNumberSubmit}
          className={css`
            margin-top: 24px;
          `}
        >
          <Group>
            <Input
              value={nameToAdd}
              placeholder="Name"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                localDispatch({
                  type: actionTypes.updateNameToAdd,
                  payload: e.target.value,
                })
              }
              error={!!nameToAddError}
            />
            {nameToAddError && <Secondary>{nameToAddError}</Secondary>}
          </Group>
          <Group
            className={css`
              margin-top: 16px;
            `}
          >
            <Input
              value={numberToAdd}
              placeholder="Phone Number"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                localDispatch({
                  type: actionTypes.updateNumberToAdd,
                  payload: e.target.value,
                })
              }
              error={!!numberToAddError}
            />
            {numberToAddError && <Secondary>{numberToAddError}</Secondary>}
          </Group>
          <div
            className={css`
              margin-top: 32px;
              text-align: center;
            `}
          >
            <Button
              fill="outline"
              color="gray"
              type="button"
              onClick={() => localDispatch({ type: actionTypes.reset })}
              className={css`
                display: inline-flex;
                margin-right: 8px;
              `}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className={css`
                display: inline-flex;
              `}
            >
              Add Number
            </Button>
          </div>
        </form>
      </Modal>
      <Select
        className={css`
          div[class*='selectInput'] {
            margin: 0;
          }
        `}
        displayOptionCount={5}
        value={activeForwardingId}
        onChange={onChange}
        placeholder=""
        name="callForwardingNumberSelect"
        addOptionText="Add Forwarding Number..."
        onAddOptionClick={() => localDispatch({ type: actionTypes.openModal })}
      >
        {callForwardingNumbers.map((forwarding) => (
          <Option key={forwarding.id} value={forwarding.id}>
            {forwarding.name} {formatPhoneNumber(forwarding.number)}
          </Option>
        ))}
      </Select>
    </div>
  );
};
