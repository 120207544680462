import { css } from '@emotion/core';

export const officeHoursCard = css`
  width: 150px;
  display: flex;
  flex-direction: column;
  padding: 0 25px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);

  .office-hours-title {
    font-size: 1rem;
    color: #6c757d;
    margin: 0.75rem 0 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .office-hours-section {
    position: relative;
    padding: 15px 0;

    &:after {
      content: '';
      background: #d4dbdf;
      position: absolute;
      top: 0;
      left: 0;
      height: 1px;
      width: 25%;
    }

    .title {
      font-size: 0.875rem;
    }
  }

  .office-hours-row {
    margin: 15px 0;

    [class*='-time'] {
      font-weight: bold;
    }
  }

  .ribbon-container {
    padding-bottom: 20px;
    .ribbon {
      box-sizing: content-box;
      width: 0;
      height: 15px;
      border: 8px solid #37cff1;
      border-top: 0 solid;
      border-bottom: 3px solid rgba(0, 0, 0, 0);
      font: normal 100% normal Arial, Helvetica, sans-serif;
      color: black;
      text-overflow: clip;
      position: absolute;
    }
  }
`;
