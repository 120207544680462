import React from 'react';
import ReactLoading from 'react-loading';

type Loadtype =
  | 'blank'
  | 'balls'
  | 'bars'
  | 'bubbles'
  | 'cubes'
  | 'cylon'
  | 'spin'
  | 'spinningBubbles'
  | 'spokes';
type LoadSize = 'small' | 'medium' | 'large';

export const Loader = (props: { type?: Loadtype; size?: LoadSize }) => {
  const weaveBlue = '#37CFF1';
  const dimensions = {
    small: { h: 20, w: 20 },
    medium: { h: 35, w: 35 },
    large: { h: 50, w: 50 },
  }[props.size || 'medium'];
  return (
    <ReactLoading
      type={props.type || 'spin'}
      color={weaveBlue}
      height={dimensions.h}
      width={dimensions.w}
    />
  );
};
