import { createRequestSaga, CreateRequestSagaProps } from '@weave/alert-system';
import { differenceInSeconds } from 'date-fns';
import { omit, uniq } from 'lodash';
import { Reducer } from 'redux';
import { all, put, select } from 'redux-saga/effects';
import { Store } from '../../store/store.model';
import { selectCurrentLocationId } from '../location/current-location';

export enum CachedActionTypes {
  Add = 'CachedActions:Add',
  Remove = 'CachedActions:Remove',
  Clear = 'CachedActions:Clear',
}

export type GenericAction = {
  type: string;
  payload?: any;
  [k: string]: any;
};

export type AddCachedActionAction = {
  type: CachedActionTypes.Add;
  payload: string;
};

export type RemoveCachedActionAction = {
  type: CachedActionTypes.Remove;
  payload: string;
};

export type ClearCachedActionsAction = {
  type: CachedActionTypes.Clear;
  payload: { action?: string; locationId?: string };
};

export type CachedActionActions =
  | AddCachedActionAction
  | RemoveCachedActionAction
  | ClearCachedActionsAction;

export const addCachedAction = (action: string): CachedActionActions => ({
  type: CachedActionTypes.Add,
  payload: action,
});
export const removeCachedAction = (action: string): CachedActionActions => ({
  type: CachedActionTypes.Remove,
  payload: action,
});
export const clearCachedActions = (
  locationIdToRemove?: string,
  actionToRemove?: string
): CachedActionActions => ({
  type: CachedActionTypes.Clear,
  payload: { action: actionToRemove, locationId: locationIdToRemove },
});

export type CachedActionsState = {
  [k: string]: Date;
};

export const cachedActionsReducer: Reducer<CachedActionsState, CachedActionActions> = (
  state = {},
  action
) => {
  switch (action.type) {
    case CachedActionTypes.Add:
      return { ...state, [action.payload]: new Date() };
    case CachedActionTypes.Remove:
      return omit(state, action.payload);
    case CachedActionTypes.Clear: {
      let keysToOmit: string[] = [];
      if (action.payload.locationId) {
        keysToOmit = uniq([
          ...keysToOmit,
          ...Object.keys(state).filter((key) =>
            key.includes(action.payload.locationId as string)
          ),
        ]);
      }
      if (action.payload.action) {
        keysToOmit = uniq([
          ...keysToOmit,
          ...Object.keys(state).filter((key) =>
            key.includes(action.payload.action as string)
          ),
        ]);
      }
      if (keysToOmit.length) {
        return omit(state, keysToOmit);
      }
      return {};
    }
    default:
      return state;
  }
};

const getStringifiedPayload = (payload: any) =>
  payload === undefined
    ? 'undefined'
    : typeof payload === 'string'
    ? payload
    : JSON.stringify(payload);

const getCachedActionKey = (type: string, payload: any, locationId?: string) =>
  `${type}/${getStringifiedPayload(payload)}/${locationId ?? ''}`;

const defaultOnError = () => 'Oops! Something went wrong.';

/**
 * Identical to createRequestSaga, but keeps a record of its invocations to
 * determine whether it should run on future dispatches
 * It will skip the saga if the action has already been done with the same payload (JSON.stringified Equal)
 * This is useful for simple getters that might get re-dispatched unnecessarily.
 * It saves the action in the cachedActions key and keys it by `${type}:${JSON.stringify(payload)}:${locationId}`
 *
 * Add force: true to the action to force the saga to run
 * @param {boolean} [config.displayErrors] Should caught errors be displayed as toasts?
 * @param {string} config.key The key for loading + error state in store (usually the same redux action type that triggers the saga.)
 * @param {Function} [config.onError] Custom error messaging based on incoming error.
 * @param {Function} config.saga The saga function, minus try/catch and error handling.
 * @param {boolean} [config.locationMatch] (default true) if false will skip if just the payload matches
 */
type Props<T> = CreateRequestSagaProps<T> & {
  locationMatch?: boolean;
};
export const createCachedRequestSaga = <T extends GenericAction & { force?: boolean }>({
  displayErrors = false,
  key,
  onError = defaultOnError,
  saga,
  locationMatch = true,
}: Props<T>) =>
  function* (action: T) {
    let locationId: undefined | ReturnType<typeof selectCurrentLocationId>;
    if (locationMatch) {
      locationId = yield select(selectCurrentLocationId);
    }

    const cacheKey = getCachedActionKey(action.type, action.payload, locationId);

    //5 second timeout for now, just to prevent duplicate request on the same page load.
    //Will increase this more as it becomes more adopted.
    const timeout = 5;

    const cached: boolean = yield select(
      (state: Store) =>
        state.cachedActions[cacheKey] &&
        differenceInSeconds(state.cachedActions[cacheKey], new Date()) < timeout
    );
    if (cached && !action.force) {
      return;
    }

    // Todo: figure this out. I can't remove the action if an error happens, because onError can't be a generator.
    // And I can't catch an error, because createRequestSaga catches it itself.
    // will have to modify the createRequestSaga to allow me to prevent caching errored sagas.
    // for now, just know errored sagas will cache as well
    // const onErrorWrap: CreateRequestSagaProps<T>['onError'] = function*(error, action) => {
    //   yield put( removeCachedAction(action.type, action.payload, locationId) )
    //   return onError(error, action);
    // }

    yield all([
      yield put(addCachedAction(cacheKey)),
      yield createRequestSaga<T>({ displayErrors, key, onError, saga })(action),
    ]);
  };
