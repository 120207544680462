import { createAction } from 'redux-actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { CustomAxios } from '../../../../axios';
import { AnalyticsOnboardingRequest } from '../find-onboarding-request/find-onboarding-request.action';
import { Moment } from 'moment';

export type CreateOnboardingRequestPayload = {
  demoDate?: Moment;
  note?: string;
};

export const createAnalyticsOnboardingRequest =
  createAction<CreateOnboardingRequestPayload>('CREATE_ANALYTICS_ONBOARDING_REQUEST');
export const createAnalyticsOnboardingRequestSuccess =
  createAction<AnalyticsOnboardingRequest>('CREATE_ANALYTICS_ONBOARDING_REQUEST_SUCCESS');
export const createAnalyticsOnboardingRequestFailure = createAction<Error>(
  'CREATE_ANALYTICS_ONBOARDING_REQUEST_FAILURE'
);

export const handleCreateOnboardingRequest = function* (action) {
  try {
    const { note, demoDate } = action.payload;
    const body = { note, demoDate: demoDate ? demoDate.toISOString() : null };
    const response = yield call(
      CustomAxios.post,
      '/support/v1/analytics-admin/onboarding/request',
      body
    );
    yield put(createAnalyticsOnboardingRequestSuccess(response.data.data));
  } catch (e: any) {
    yield put(createAnalyticsOnboardingRequestFailure(e));
  }
};

export const createOnboardingRequestSaga = function* () {
  yield takeEvery(
    createAnalyticsOnboardingRequest.toString(),
    handleCreateOnboardingRequest
  );
};
