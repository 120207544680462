import { createRequestStateSelector } from '@weave/alert-system';
import { createSelector } from 'reselect';
import { Store } from '../../store/store.model';
import { VoicemailBoxesActionTypes } from './voice-mailboxes.types';

export const selectVoicemailBoxesFetchingAll = createRequestStateSelector(
  VoicemailBoxesActionTypes.RequestListMailboxes
);
export const selectVoicemailBoxUpdating = createRequestStateSelector(
  VoicemailBoxesActionTypes.RequestUpdateMailbox
);

export const selectVoicemailBoxesState = (state: Store) => state.voiceMailboxes;

export const selectVoicemailBoxes = createSelector(
  selectVoicemailBoxesState,
  selectVoicemailBoxesFetchingAll,
  (state, reqState) => ({
    ...reqState,
    voicemailBoxes: state.mailboxes,
  })
);

export const selectVoicemailBox = (voicemailBoxId) =>
  createSelector(selectVoicemailBoxes, ({ voicemailBoxes, ...reqState }) => ({
    ...reqState,
    voicemailBox: voicemailBoxes?.find((box) => box.mailboxID === voicemailBoxId),
  }));

export const selectVoicemailGreetings = createSelector(
  selectVoicemailBoxesState,
  (voicemailBoxes) => voicemailBoxes.allMailboxGreetings
);
