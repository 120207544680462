import { connect } from 'react-redux';

import { PatientRecall } from './patient-recall/patient-recall.component';
import {
  recallHistory,
  recallHistoryReset,
} from '../../../../../redux/actions/recall/recall-history.action';
import { RecallModel } from '../../../../../models/recall-history.model';
import {
  selectCurrentLocationId,
  selectCurrentLocationTimeZone,
} from '../../../../../redux/actions/location/current-location';
import { Store as RootStore } from '../../../../../redux/store/store.model';

interface Store extends RootStore {
  recallHistory: {
    recall: RecallModel[];
  };
}

interface OwnProps {
  personID: string;
}

export const PatientRecallContainer = connect(
  (store: Store, ownProps: OwnProps) => ({
    currentLocation: selectCurrentLocationId(store),
    timezone: selectCurrentLocationTimeZone(store),
    personID: ownProps.personID,
    recallHistory: store.recallHistory.recall,
  }),
  {
    getRecallHistory: recallHistory,
    recallHistoryReset,
  }
)(PatientRecall);
