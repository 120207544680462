import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyA2Hwm1SSYzgTUs1pV51xYDd2HkVqtJDP4',
  authDomain: 'indexed-content.firebaseapp.com',
  databaseURL: 'https://indexed-content-default-rtdb.firebaseio.com',
  projectId: 'indexed-content',
  storageBucket: 'indexed-content.appspot.com',
  messagingSenderId: '173109784833',
  appId: '1:173109784833:web:c327dfec3ec9680a70ab00',
};

firebase.initializeApp(firebaseConfig);

export const firebaseDB = firebase.database();
export const auth = firebase.auth();
