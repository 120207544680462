import React from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { MerchantPlanTypeLabels, MerchantPlanTypes } from '../../../models/pricing.model';
import {
  PaymentStatusChip,
  PaymentStatusColors,
} from '../../shared/status-chip/payment-status-chip.component';

const mapColor = (status: MerchantPlanTypes): PaymentStatusColors => {
  switch (status) {
    case MerchantPlanTypes.Full:
      return 'success';
    default:
      return 'studio';
  }
};

interface PlanTypeChipProps {
  planName: MerchantPlanTypes;
  size?: string;
}

const PlanTypeChip: React.FC<PlanTypeChipProps> = ({
  planName,
  size = theme.fontSize(16),
}) => {
  return (
    <PaymentStatusChip
      color={mapColor(planName)}
      css={css`
        font-size: ${size};
      `}
    >
      {MerchantPlanTypeLabels[planName]}
    </PaymentStatusChip>
  );
};

export default PlanTypeChip;
