import { PhoneMedia } from '../';

export enum MediaActionTypes {
  Add = 'ADD_MEDIA',
  Delete = 'DELETE_MEDIA',
  Update = 'UPDATE_MEDIA',
}

export type MediaActions =
  | {
      type: MediaActionTypes.Add;
      payload: AddMediaPayload;
    }
  | {
      type: MediaActionTypes.Delete;
      payload: string;
    }
  | {
      type: MediaActionTypes.Update;
      payload: UpdateMediaPayload;
    };

type AddMediaPayload = {
  key: string;
  media: PhoneMedia | PhoneMedia[];
};

type UpdateMediaPayload = {
  id: string;
  updates: { [key: string]: any };
};

export const addMedia = (payload: AddMediaPayload): MediaActions => ({
  type: MediaActionTypes.Add,
  payload,
});

export const deleteMedia = (payload: string): MediaActions => ({
  type: MediaActionTypes.Delete,
  payload,
});

export const updateMedia = (payload: UpdateMediaPayload): MediaActions => ({
  type: MediaActionTypes.Update,
  payload,
});
