import React, { useContext } from 'react';
import { differenceInMinutes, isAfter, isBefore, isEqual } from 'date-fns';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import {
  AlertInvertIconSmall,
  TimeField,
  useControlledField,
} from '@weave/design-system';
import { components } from '@weave/shared-proto-gateway/dist/ts/schedule-api';

import { OfficeHoursContext } from './office-hours.provider';
import {
  centerH,
  errorMessageStart,
  formatDateFromTime,
  warningMessageStart,
} from '../schedule-display-utils';

interface OfficeHoursEndFieldProps {
  startTime: string;
  endTime: string;
  nextStartTime: string | null;
  dayLabel: string;
  index: number;
  prevState: components['schemas']['scheduleReservedTime'];
}

export const OfficeHoursEndField = ({
  startTime,
  endTime,
  nextStartTime,
  dayLabel,
  index,
  prevState,
}: OfficeHoursEndFieldProps) => {
  const context = useContext(OfficeHoursContext);
  const { state, dispatch } = context;

  const dispatchEndError = (message: string, error: boolean) => {
    dispatch({
      type: 'UPDATE_ERRORS',
      payload: {
        dayLabel,
        index: index,
        timeType: 'end',
        message: message,
        messageType: error ? 'error' : 'warning',
        prevState: prevState,
      },
    });
  };

  const endTimeProps = useControlledField({
    type: 'time',
    value: endTime,
    onChange: (value) => {
      const inValue = value === '00:00:00' ? '24:00:00' : value;
      const newDuration = differenceInMinutes(
        formatDateFromTime(value === '00:00:00' ? '24:00:00' : value),
        formatDateFromTime(startTime)
      );
      if (value && newDuration !== parseInt(prevState?.DurationMinutes || '0')) {
        switch (true) {
          case isBefore(formatDateFromTime(value), formatDateFromTime(startTime)) &&
            value !== '00:00:00':
            dispatchEndError(
              `${errorMessageStart('end', index)}End time cannot be before start time.`,
              true
            );
            break;

          case isEqual(formatDateFromTime(value), formatDateFromTime(startTime)):
            dispatchEndError(
              `${errorMessageStart('end', index)}End time cannot match start time`,
              true
            );
            break;

          case nextStartTime &&
            isAfter(formatDateFromTime(value), formatDateFromTime(nextStartTime)):
            dispatchEndError(
              `${errorMessageStart('end', index)}End time conflicts with next time block`,
              true
            );
            break;

          case nextStartTime &&
            isEqual(formatDateFromTime(value), formatDateFromTime(nextStartTime)):
            dispatchEndError(
              `${warningMessageStart(
                'end',
                index
              )}No gap between time blocks. Consider merging with next timeblock`,
              false
            );
            dispatch({
              type: 'EDIT_END_TIMEBLOCK',
              payload: {
                canClearErrors: false,
                dayLabel,
                index,
                startTime,
                value: inValue,
              },
            });
            break;

          default:
            dispatch({
              type: 'EDIT_END_TIMEBLOCK',
              payload: {
                canClearErrors: true,
                dayLabel,
                index,
                startTime,
                value: inValue,
              },
            });
            break;
        }
      }
    },
  });

  return (
    <div
      css={[
        centerH,
        css`
          justify-content: center;
        `,
      ]}
    >
      <TimeField {...endTimeProps} name="endTime" label="End" />
      {state.errors[dayLabel][index]['end'].message.length > 0 && (
        <button
          onClick={() => {
            dispatch({
              type: 'TOGGLE_ERROR_ALERT',
              payload: {
                day: dayLabel,
                index: index,
                timeType: 'end',
              },
            });
          }}
          css={css`
            margin-left: ${theme.spacing(1)};
            width: auto;
            outline: none;
            border: none;
            background-color: none;
          `}
        >
          <AlertInvertIconSmall
            color={
              state.errors[dayLabel][index]['end'].messageType === 'warning'
                ? 'warn'
                : 'error'
            }
          />
        </button>
      )}
    </div>
  );
};
