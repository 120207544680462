import React, { useContext } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { UserModel } from '../../models/user.model';
import { AlertsContext } from '@weave/alert-system';
interface Props {
  userSearch: Function;
  userSelect: Function;
  loading: Boolean;
  userSearchResults: UserModel[];
}

const UserSearch = (props: Props) => {
  const { warning } = useContext(AlertsContext);

  const searchLocations = (query: string) => {
    props.userSearch({ state: query });
  };

  const handleSelection = (selectedUser) => {
    const user = selectedUser[0];
    if (user && user.Username && user.Username.toLowerCase().includes('getweave.com')) {
      warning('Can not add Weave user to location.');
      return;
    }

    props.userSelect({ state: user, isExistingUser: true });
  };
  return (
    <>
      <AsyncTypeahead
        id="userSearchBox"
        className="user-search-box"
        isLoading={props.loading}
        placeholder="Search by first name, last name, or username..."
        labelKey={(option) => `${option.FirstName} ${option.LastName} ${option.Username}`}
        onChange={handleSelection}
        onSearch={searchLocations}
        options={props.userSearchResults}
        emptyLabel="No results found"
      />
    </>
  );
};

export default UserSearch;
