export const Timezones = [
  {
    ID: 1,
    Value: 'America/Halifax',
  },
  {
    ID: 2,
    Value: 'America/Edmonton',
  },
  {
    ID: 3,
    Value: 'America/Vancouver',
  },
  {
    ID: 4,
    Value: 'America/Whitehorse',
  },
  {
    ID: 5,
    Value: 'America/Winnipeg',
  },
  {
    ID: 6,
    Value: 'America/Toronto',
  },
  {
    ID: 7,
    Value: 'America/Atikokan',
  },
  {
    ID: 8,
    Value: 'Canada/Atlantic',
  },
  {
    ID: 9,
    Value: 'Canada/Eastern',
  },
  {
    ID: 10,
    Value: 'Canada/Mountain',
  },
  {
    ID: 11,
    Value: 'Canada/Pacific',
  },
  {
    ID: 12,
    Value: 'Canada/Saskatchewan',
  },
  {
    ID: 13,
    Value: 'Canada/Yukon',
  },
  {
    ID: 14,
    Value: 'Pacific/Honolulu',
  },
  {
    ID: 15,
    Value: 'US/Alaska',
  },
  {
    ID: 16,
    Value: 'US/Arizona',
  },
  {
    ID: 17,
    Value: 'US/Central',
  },
  {
    ID: 18,
    Value: 'US/East-Indiana',
  },
  {
    ID: 19,
    Value: 'US/Eastern',
  },
  {
    ID: 20,
    Value: 'US/Hawaii',
  },
  {
    ID: 21,
    Value: 'US/Indiana-Starke',
  },
  {
    ID: 22,
    Value: 'US/Michigan',
  },
  {
    ID: 23,
    Value: 'US/Mountain',
  },
  {
    ID: 24,
    Value: 'US/Pacific',
  },
  {
    ID: 25,
    Value: 'America/Puerto_Rico',
  },
];
