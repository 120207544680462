import { createSelector } from 'reselect';
import { selectCurrentLocationId } from '../../current-location';
import { selectLocations } from '../../locations/locations.selectors';

export const selectLocationPhoneNumberIds = (locationId: string) =>
  createSelector(selectLocations, (locations) => locations[locationId]?.ringtoneIds);

export const selectCurrentLocationPhoneNumberIds = createSelector(
  selectLocations,
  selectCurrentLocationId,
  (locations, currentLocationId) => locations[currentLocationId]?.phoneNumberIds
);
