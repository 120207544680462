import React, { useEffect, useState } from 'react';
import { format, formatISO, parse, addYears } from 'date-fns';
import { fnsDatePickerFieldFormat, fnsTimeFieldFormat } from '../../../../helpers/utils';
import { theme } from '@weave/theme-original';
import {
  DatePickerField,
  DropdownField,
  Modal,
  OptionSwitchField,
  PrimaryButton,
  Text,
  TimeField,
  TextField,
  useForm,
  ModalControlModalProps,
} from '@weave/design-system';
import { css } from '@emotion/core';
import { useAppointmentTypesResource } from '../appointment-types/use-appointment-type.hook';
import { CopyCredential } from '../../../location-sidebar-container/sidebar-sync-app/sync-app-create/copy-credential.component';
import { today } from './appointment-request-log.component';
import {
  schedulingApi,
  SchedulingTypes,
} from '../../../../apis/protos/scheduling/scheduling.proto-api';

const fieldMarginBottom = css`
  margin-bottom: ${theme.spacing(2)};
`;

interface AppointmentRequestEditFormProps {
  selectedAppointment: Partial<SchedulingTypes['scheduleScheduleRequest']>;
  modalProps: ModalControlModalProps;
  refreshAppointmentRequests: () => void;
}

export const AppointmentRequestEditForm = ({
  selectedAppointment,
  modalProps,
  refreshAppointmentRequests,
}: AppointmentRequestEditFormProps) => {
  const methods = schedulingApi('/schedule/v1/requests/{scheduleRequestID.SID}')({
    'scheduleRequestID.SID': selectedAppointment?.ID?.uuid ?? '',
  });
  const [isNewAppointmentType, setIsNewAppointmentType] = useState<boolean>(false);
  const { appointmentTypes } = useAppointmentTypesResource();
  const { getFieldProps, seedValues, values } = useForm({
    fields: {
      status: { type: 'text', required: true },
      appointmentType: { type: 'dropdown', required: true },
      duration: {
        type: 'text',
        required: true,
      },
      appointmentDate: {
        type: 'datePicker',
        minDate: format(today, 'MM/dd/yyyy'),
        maxDate: format(addYears(today, 1), 'MM/dd/yyyy'),
        value: format(today, 'MM/dd/yyyy'),
        required: true,
      },
      appointmentTime: {
        type: 'time',
        minTime: '0:00',
        maxTime: '24:00',
        required: true,
      },
    },
  });

  useEffect(() => {
    if (selectedAppointment.ID) {
      seedValues({
        status: selectedAppointment?.Status || 'UnknownStatus',
        appointmentType: selectedAppointment?.AppointmentType || '',
        duration: String(parseInt(selectedAppointment?.Duration ?? '') / 60 || 5),
        appointmentDate:
          format(
            new Date(selectedAppointment?.DateTime ?? ''),
            fnsDatePickerFieldFormat
          ) || '',
        appointmentTime:
          format(new Date(selectedAppointment?.DateTime ?? ''), fnsTimeFieldFormat) || '',
      });
      setIsNewAppointmentType(false);
    }
  }, [selectedAppointment?.ID]);

  useEffect(() => {
    if (values.appointmentType === '') {
      setIsNewAppointmentType(true);
    }
  }, [values.appointmentType]);

  const handleEditAppointment = () => {
    const status = values?.status as typeof selectedAppointment.Status;
    methods.put({
      ...selectedAppointment,
      Status: status,
      AppointmentType: values.appointmentType,
      Duration: `${parseInt(values?.duration ?? '') * 60}s`,
      DateTime: formatISO(
        parse(
          `${values.appointmentDate} ${values.appointmentTime}`,
          'MM/dd/yyyy HH:mm:ss',
          new Date()
        )
      ),
    });
    refreshAppointmentRequests();
    setIsNewAppointmentType(true);
    modalProps.onClose();
  };

  return (
    <Modal {...modalProps}>
      <Modal.Header
        css={css`
          margin-bottom: ${theme.spacing(-0.5)};
        `}
      >
        Edit Appointment Request
      </Modal.Header>
      <Modal.Body>
        <CopyCredential
          title={`(${selectedAppointment?.ID?.uuid})`}
          value={selectedAppointment?.ID?.uuid ?? ''}
          valueHidden={true}
        />

        {selectedAppointment ? (
          <form>
            <OptionSwitchField
              {...getFieldProps('status')}
              name="Status"
              css={css`
                margin: ${theme.spacing(2, 0)};
              `}
            >
              <OptionSwitchField.Option value={'Pending'}>
                Pending
              </OptionSwitchField.Option>
              <OptionSwitchField.Option value="Accepted">
                Accepted
              </OptionSwitchField.Option>
              <OptionSwitchField.Option value="Rejected">
                Rejected
              </OptionSwitchField.Option>
            </OptionSwitchField>

            {appointmentTypes.length > 0 && !isNewAppointmentType && (
              <DropdownField
                label="Appointment Type"
                {...getFieldProps('appointmentType')}
              >
                {appointmentTypes.map((appointmentType) => {
                  return (
                    <DropdownField.Option
                      key={appointmentType.ID}
                      value={appointmentType.Name}
                      searchValue={appointmentType.Name}
                    >
                      {appointmentType.Name}
                    </DropdownField.Option>
                  );
                })}
                <DropdownField.Option value="" searchValue="add custom appointment type">
                  <i>(add custom appointment type)</i>
                </DropdownField.Option>
              </DropdownField>
            )}

            {(!appointmentTypes?.length || isNewAppointmentType) && (
              <TextField label="Appointment Type" {...getFieldProps('appointmentType')} />
            )}

            {isNewAppointmentType && (
              <PrimaryButton
                size="tiny"
                onClick={() => setIsNewAppointmentType(!isNewAppointmentType)}
                style={{ margin: theme.spacing(1, 0) }}
              >
                Select from existing appointment types
              </PrimaryButton>
            )}

            <TextField
              label="Duration"
              css={css`
                margin: ${theme.spacing(2, 0)};
              `}
              {...getFieldProps('duration')}
            />

            <DatePickerField
              label="Appointment Date"
              css={fieldMarginBottom}
              {...getFieldProps('appointmentDate')}
            />

            <TimeField
              label="Appointment Time"
              css={fieldMarginBottom}
              {...getFieldProps('appointmentTime')}
            />
          </form>
        ) : (
          <Text>No appointment selected.</Text>
        )}
      </Modal.Body>
      <Modal.Actions
        onPrimaryClick={handleEditAppointment}
        primaryLabel="Save"
        onSecondaryClick={modalProps.onClose}
        secondaryLabel="Cancel"
      />
    </Modal>
  );
};
