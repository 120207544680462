import React from 'react';
import moment from 'moment';

import { ReminderActionButtons } from '../auto-reminder-modal-container/auto-reminders-action-buttons';

import { ReminderDetails } from '../../../../redux/actions/notifications-settings/notification-settings.types';

import {
  EnabledToggle,
  formatNotificationTime,
} from '../auto-reminders-helpers/auto-reminder-helpers';

import * as styles from './auto-reminder-changelog.styles';

const getColoredIcons = (
  previousSettings: ReminderDetails | null,
  latterSetting: ReminderDetails
) => {
  const coloredIcons: string[] = [];
  if (!previousSettings) {
    return coloredIcons;
  }
  if (
    previousSettings.template.text !== latterSetting.template.text ||
    previousSettings.template.multi_template_text !==
      latterSetting.template.multi_template_text
  ) {
    coloredIcons.push('template');
  }
  if (previousSettings.delivery.time !== latterSetting.delivery.time) {
    coloredIcons.push('advanced_options');
  }
  return coloredIcons;
};

const SendTime = (setting: ReminderDetails) => (
  <span>{formatNotificationTime(setting.delivery, setting.send_before)}</span>
);

export const ChangeLogWrapper = (
  selectedAutoReminder: ReminderDetails,
  changeLog: ReminderDetails[],
  actionRouter: Function
) => {
  const { id, type } = selectedAutoReminder;
  return changeLog.map((change: ReminderDetails, i) => {
    const previousSettings = i === 0 ? null : changeLog[i - 1];
    return (
      <div
        key={`change-log-${id}-${i}`}
        onClick={actionRouter(change)('historic-template')}
        className={styles.changeLogRow}
      >
        <span>{moment(change['modified_at']).format('MM/DD/YY h:mm a')}</span>
        <span>{change.name}</span>
        <span>{SendTime(change)}</span>
        <span className="smallCol">
          <ReminderActionButtons
            id={id}
            type={type}
            actionRouter={actionRouter(change)}
            coloredIcons={getColoredIcons(previousSettings, change)}
            setActions={['template']}
          />
        </span>
        <span>
          <EnabledToggle enabled={change.enabled} disabled={true} />
        </span>
      </div>
    );
  });
};
