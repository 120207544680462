import React from 'react';
import { css } from '@emotion/core';
import { DragSource, DropTarget } from 'react-dnd';
import { findDOMNode } from 'react-dom';
import { ReviewsOrderItem } from './reviews-order-item.component';
import { WeaveTheme } from '@weave/theme-original';

interface ReviewsOrderItemComponentProps {
  connectDragSource: Function;
  connectDropTarget: Function;
  isDragging: boolean;
  canDrop: boolean;
  isOver: boolean;
  text: string;
  index: number;
}

const ReviewsOrderItemComponent = ({
  connectDragSource,
  connectDropTarget,
  isDragging,
  text,
  index,
}: ReviewsOrderItemComponentProps) => {
  const item = (
    <div>
      <div
        css={(theme: WeaveTheme) => [
          css`
            display: flex;
            align-items: center;
            margin: ${theme.spacing(1)};
            background: ${theme.colors.white};
            border: solid 1px ${theme.colors.gray[300]};
            padding: ${theme.spacing(1, 1, 1, 0.5)};
            border-radius: ${theme.borderRadius.small};
            border: solid 1px ${theme.colors.gray[300]};
            cursor: move;
          `,
          isDragging
            ? css`
                opacity: 0.4;
              `
            : css`
                opacity: 1;
              `,
        ]}
      >
        <ReviewsOrderItem text={text} index={index} />
      </div>
    </div>
  );

  return connectDragSource(connectDropTarget(item));
};

export const REVIEWS_CARD = 'REVIEWS_CARD';

const sourceOptions = {
  beginDrag(props) {
    return {
      text: props.text,
      index: props.index,
    };
  },
};

const sourceConnect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
});

const dragSource = DragSource(
  REVIEWS_CARD,
  sourceOptions,
  sourceConnect
)(ReviewsOrderItemComponent);

const targetOptions = {
  hover(props, monitor, component: React.ReactInstance) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    if (dragIndex === hoverIndex) {
      return false;
    }

    // eslint-disable-next-line react/no-find-dom-node
    const node: any = findDOMNode(component);
    const hoverBoundingRect = node.getBoundingClientRect();

    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 1.5;

    const clientOffset = monitor.getClientOffset();

    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    props.moveItem(dragIndex, hoverIndex);

    monitor.getItem().index = hoverIndex;
    return;
  },
};

const targetConnect = (connect) => ({
  connectDropTarget: connect.dropTarget(),
});

const dropTarget = DropTarget(REVIEWS_CARD, targetOptions, targetConnect)(dragSource);

export const ReviewsOrderItemContainer = dropTarget;
