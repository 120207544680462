import React, { useState, useEffect, FC } from 'react';
import { css } from 'emotion';
import { NakedUl } from '@weave/design-system';
import { isEmpty, omit, isUndefined } from 'lodash';

import { DeviceSection } from './device-section.component';
import {
  DeviceOptionModel,
  DevicePendingChangesModel,
} from '../../../redux/actions/devices';
import { DeviceRowSelect } from './device-row-select.component';
import { DeviceRowText } from './device-row-text.component';

interface Props {
  externalCallerName?: string;
  externalCallerNumber?: string;
  internalCallerName?: string;
  internalCallerNumber?: string;
  updating: boolean;
  voicemailBoxID?: string | null;
  voiceMailboxOptions: DeviceOptionModel[];
  updateDevice: (changes: DevicePendingChangesModel) => void;
}

interface PendingChanges {
  sipProfile: {
    externalCallerName?: string;
    externalCallerNumber?: string;
    internalCallerName?: string;
    internalCallerNumber?: string;
    voicemailBoxID?: string | null;
  };
}

export const DeviceSectionCallSettings: FC<Props> = ({
  externalCallerName,
  externalCallerNumber,
  internalCallerName,
  internalCallerNumber,
  updating,
  voicemailBoxID,
  voiceMailboxOptions,
  updateDevice,
}) => {
  const [pendingChanges, setPendingChanges] = useState<PendingChanges>({
    sipProfile: {},
  });

  useEffect(() => {
    // When the editable device values update, clear pending changes
    setPendingChanges({ sipProfile: {} });
  }, [
    voicemailBoxID,
    externalCallerName,
    externalCallerNumber,
    internalCallerName,
    internalCallerNumber,
  ]);

  return (
    <DeviceSection
      name="Call Settings"
      showSaveButton
      hasRowChanges={!isEmpty(pendingChanges.sipProfile) && !updating}
      onSaveClick={() => updateDevice(pendingChanges)}
    >
      <NakedUl
        className={css`
          margin: 16px 0 0;
          max-width: 600px;
        `}
      >
        <DeviceRowSelect
          id="voice-mailbox"
          label="Voice Mailbox"
          value={
            !isUndefined(pendingChanges.sipProfile?.voicemailBoxID)
              ? pendingChanges.sipProfile?.voicemailBoxID ?? null
              : voicemailBoxID
          }
          onChange={(id: string) => {
            const newId = id === 'null' ? null : id;
            if (newId === voicemailBoxID) {
              // Clear local changes
              setPendingChanges({
                ...pendingChanges,
                sipProfile: omit(pendingChanges.sipProfile, 'voicemailBoxID'),
              });
            } else {
              setPendingChanges({
                ...pendingChanges,
                sipProfile: { ...pendingChanges.sipProfile, voicemailBoxID: newId },
              });
            }
          }}
          options={voiceMailboxOptions}
        />
        <DeviceRowText
          name="External Caller Name"
          value={
            !isUndefined(pendingChanges.sipProfile?.externalCallerName)
              ? pendingChanges.sipProfile?.externalCallerName
              : externalCallerName || ''
          }
          onChange={(value: string) => {
            if (value === externalCallerName) {
              // Clear pending changes
              setPendingChanges({
                ...pendingChanges,
                sipProfile: omit(pendingChanges.sipProfile, 'externalCallerName'),
              });
            } else {
              setPendingChanges({
                ...pendingChanges,
                sipProfile: { ...pendingChanges.sipProfile, externalCallerName: value },
              });
            }
          }}
        />
        <DeviceRowText
          name="External Caller Number"
          value={
            !isUndefined(pendingChanges.sipProfile?.externalCallerNumber)
              ? pendingChanges.sipProfile?.externalCallerNumber
              : externalCallerNumber || ''
          }
          onChange={(value: string) => {
            if (isNaN(+value)) {
              return;
            }

            if (value === externalCallerNumber) {
              // Clear pending changes
              setPendingChanges({
                ...pendingChanges,
                sipProfile: omit(pendingChanges.sipProfile, 'externalCallerNumber'),
              });
            } else {
              setPendingChanges({
                ...pendingChanges,
                sipProfile: { ...pendingChanges.sipProfile, externalCallerNumber: value },
              });
            }
          }}
        />
        <DeviceRowText
          name="Internal Caller Name"
          value={
            !isUndefined(pendingChanges.sipProfile?.internalCallerName)
              ? pendingChanges.sipProfile?.internalCallerName
              : internalCallerName || ''
          }
          onChange={(value: string) => {
            if (value === internalCallerName) {
              // Clear pending changes
              setPendingChanges({
                ...pendingChanges,
                sipProfile: omit(pendingChanges.sipProfile, 'internalCallerName'),
              });
            } else {
              setPendingChanges({
                ...pendingChanges,
                sipProfile: { ...pendingChanges.sipProfile, internalCallerName: value },
              });
            }
          }}
        />
        <DeviceRowText
          name="Internal Caller Number"
          value={
            !isUndefined(pendingChanges.sipProfile?.internalCallerNumber)
              ? pendingChanges.sipProfile?.internalCallerNumber
              : internalCallerNumber || ''
          }
          onChange={(value: string) => {
            if (isNaN(+value)) {
              return;
            } else if (value === internalCallerNumber) {
              // Clear pending changes
              setPendingChanges({
                ...pendingChanges,
                sipProfile: omit(pendingChanges.sipProfile, 'internalCallerNumber'),
              });
            } else {
              setPendingChanges({
                ...pendingChanges,
                sipProfile: { ...pendingChanges.sipProfile, internalCallerNumber: value },
              });
            }
          }}
        />
      </NakedUl>
    </DeviceSection>
  );
};
