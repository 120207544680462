import React from 'react';
import { format, parseISO } from 'date-fns';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { Modal, ModalControlModalProps, Text } from '@weave/design-system';
import { CopyCredential } from '../../../location-sidebar-container/sidebar-sync-app/sync-app-create/copy-credential.component';
import { fnsDateTimeFormat } from '../../../../helpers/utils';
import { components } from '@weave/shared-proto-gateway/src/schedule-api';

const infoRowStyle = (border: boolean) => css`
  display: flex;
  justify-content: space-between;
  align-items: start;
  borderbottom: ${border ? `solid ${theme.colors.gray300} 1px` : 'none'};
  marginbottom: ${theme.spacing(1)};
`;

const sectionStyles = css`
  border-bottom: solid ${theme.colors.gray300} 1px;
  padding-top: ${theme.spacing(1.5)};
`;

const infoSection = (data, border: boolean) => {
  return data.map((item) => (
    <div key={item.title.split(' ').join('')} css={infoRowStyle(border)}>
      <Text
        css={css`
          margin-top: ${theme.spacing(1)};
        `}
      >
        <b
          css={css`
            margin-right: ${theme.spacing(0.5)};
          `}
        >
          {item.title}:
        </b>{' '}
        {item.value}
      </Text>
      <CopyCredential title={item.title} value={item.value} titleHidden valueHidden />
    </div>
  ));
};

interface AppointmentRequestInfoModalProps {
  selectedAppointment: Partial<components['schemas']['scheduleScheduleRequest']>;
  modalProps: ModalControlModalProps;
}
export const AppointmentRequestInfoModal = ({
  modalProps,
  selectedAppointment,
}: AppointmentRequestInfoModalProps) => {
  const infoRowItems = [
    {
      title: 'ID',
      value: selectedAppointment?.ID?.uuid || 'Unknown',
    },
    {
      title: 'Appointment Type',
      value: selectedAppointment?.AppointmentType || 'None Selected',
    },
    {
      title: 'Appointment Duration',
      value: selectedAppointment?.Duration
        ? `${parseInt(selectedAppointment.Duration) / 60} minutes`
        : 'Unknown',
    },
    {
      title: 'Selected Date',
      value: selectedAppointment?.DateTime
        ? format(parseISO(selectedAppointment.DateTime), fnsDateTimeFormat)
        : 'Not Selected',
    },
    {
      title: 'Note',
      value: selectedAppointment?.Note || 'None',
    },
    {
      title: 'Status',
      value: selectedAppointment?.Status || 'Unknown',
    },
    {
      title: 'Reviewed By',
      value: selectedAppointment?.ReviewedBy || 'Not Reviewed',
    },
    {
      title: 'Reviewed At',
      value: selectedAppointment?.ReviewedAt || 'Not Reviewed',
    },
    {
      title: 'Source',
      value: selectedAppointment?.Source || 'Unknown',
    },
    {
      title: 'Location ID',
      value: selectedAppointment?.LocationID?.uuid || 'Unknown',
    },
  ];

  const personInfoRows = (person) => [
    {
      title: 'Name',
      value: `${person.FirstName} ${person.LastName}`,
    },
    {
      title: 'Phone',
      value: person.PhoneNumber,
    },
    {
      title: 'Email',
      value: person.Email,
    },
  ];

  const requestedOpenings =
    selectedAppointment?.RequestedOpenings?.length &&
    selectedAppointment.RequestedOpenings.map((opening, i) => {
      return {
        title: `#${i + 1}`,
        value: format(parseISO(opening?.DateTime || ''), fnsDateTimeFormat),
      };
    });

  return (
    <Modal {...modalProps}>
      <Modal.Header>
        Additional Info for #{selectedAppointment?.ID?.uuid?.slice(0, 6) || 0}
        {selectedAppointment?.CreatedAt && (
          <Text>
            <i>
              Created at{' '}
              {format(parseISO(selectedAppointment.CreatedAt), fnsDateTimeFormat)}
            </i>
          </Text>
        )}
      </Modal.Header>

      <Modal.Body>
        {infoSection(infoRowItems.slice(0, 4), true)}

        {selectedAppointment?.RequestedOpenings?.length && (
          <div css={sectionStyles}>
            <Text>
              <b>
                <u>Requested Openings</u>
              </b>
            </Text>
            {infoSection(requestedOpenings, false)}
          </div>
        )}

        {selectedAppointment?.Schedulee && (
          <div css={sectionStyles}>
            <Text>
              <b>
                <u>Schedulee</u>
              </b>
            </Text>
            {infoSection(personInfoRows(selectedAppointment.Schedulee), false)}
          </div>
        )}

        {selectedAppointment?.Scheduler && (
          <div css={sectionStyles}>
            <Text>
              <b>
                <u>Scheduler</u>
              </b>
            </Text>
            {infoSection(personInfoRows(selectedAppointment.Scheduler), false)}
          </div>
        )}

        {infoSection(infoRowItems.slice(4, infoRowItems.length), true)}
      </Modal.Body>

      <Modal.Actions onPrimaryClick={modalProps.onClose} primaryLabel={'Close'} />
    </Modal>
  );
};
