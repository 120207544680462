import React, { FC } from 'react';
import { css } from '@emotion/core';
import { theme, WeaveTheme } from '@weave/theme-original';
import { Text, CheckIcon } from '@weave/design-system';
import { noop } from 'lodash';

type RowProps = {
  id: string;
  col1Value: string;
  col2Value?: string;
  col3Value?: string;
  isSelectable: boolean;
  isSelected: boolean;
  onClick: (id: string) => void;
};

type TableProps = {
  col1Title: string;
  col2Title?: string;
  col3Title?: string;
};

export const DevicesTableRow = ({
  id,
  col1Value,
  col2Value,
  col3Value,
  isSelectable,
  isSelected,
  onClick,
}: RowProps) => {
  return (
    <tr
      key={id}
      onClick={isSelectable ? onClick : noop}
      css={DevicesTableStyles(isSelectable)}
    >
      <td data-tooltip={col1Value}>{col1Value}</td>
      <td data-tooltip={col2Value}>{col2Value}</td>
      <td data-tooltip={col3Value}>{col3Value}</td>
      <td
        css={css`
          text-align: right;
        `}
      >
        {!isSelectable && <CheckIcon color="light" />}
        {isSelectable && isSelected && <CheckIcon color="primary" />}
      </td>
    </tr>
  );
};

export const DevicesTable: FC<TableProps> = ({
  children,
  col1Title,
  col2Title,
  col3Title,
}) => {
  return (
    <table
      css={css`
        width: 100%;
        display: grid;
      `}
    >
      <thead>
        <tr css={DevicesTableRowStyles}>
          <th>
            <Text size="small" color="light" weight="regular">
              {col1Title}
            </Text>
          </th>
          <th>
            <Text size="small" color="light" weight="regular">
              {col2Title}
            </Text>
          </th>
          <th>
            <Text size="small" color="light" weight="regular">
              {col3Title}
            </Text>
          </th>
          <th>{''}</th>
        </tr>
      </thead>
      <tbody
        css={(theme: WeaveTheme) => css`
          height: 230px;
          overflow: auto;

          &::-webkit-scrollbar {
            background-color: ${theme.colors.gray200};
            width: 4px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background-color: ${theme.colors.gray400};
          }
        `}
      >
        {children}
      </tbody>
    </table>
  );
};

const DevicesTableStyles = (isSelectable: boolean) => css`
  color: ${isSelectable ? theme.colors.gray600 : theme.colors.gray500};
  cursor: ${isSelectable ? 'pointer' : 'default'};
  height: 51px;
  display: flex;
  :hover {
    background-color: ${isSelectable ? theme.colors.gray200 : theme.colors.white};
  }
  td {
    padding: ${theme.spacing(1.5, 1)};
    width: 14rem;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    position: relative;
    &:not(:last-child):hover::after {
      position: absolute;
      content: attr(data-tooltip);
      font-size: 12px;
      left: 0em;
      top: 2.5em;
      background-color: #31353d;
      color: white;
      border-radius: 5px;
      padding: ${theme.spacing(0.2, 0.5)};
    }
    :last-of-type {
      padding: ${theme.spacing(1, 2, 1, 0)};
      width: 5%;
    }
  }
`;

const DevicesTableRowStyles = css`
  th {
    padding: ${theme.spacing(0, 1)};
    width: 14rem;
    :last-of-type {
      padding-left: ${theme.spacing(0)};
      width: 5%;
    }
  }
`;
