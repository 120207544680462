import { all } from 'redux-saga/effects';
import { postReviewsSignupSaga } from './post-reviews.action';
import { getReviewsSettingsSaga } from './get-reviews-settings.action';
import { postReviewsResyncSaga } from './post-reviews-resync.action';
import { updateReviewsSettingsSaga } from './update-reviews-settings.action';
import { sendReviewInvitationSaga } from './send-review-invitation.action';

export { postReviewsSignup } from './post-reviews.action';
export { getReviewsSettings } from './get-reviews-settings.action';
export { postReviewsResync } from './post-reviews-resync.action';
export { updateReviewsSettings } from './update-reviews-settings.action';
export { sendReviewInvitation } from './send-review-invitation.action';

export const reviewsSaga = function* () {
  yield all([
    postReviewsSignupSaga(),
    getReviewsSettingsSaga(),
    postReviewsResyncSaga(),
    updateReviewsSettingsSaga(),
    sendReviewInvitationSaga(),
  ]);
};

export { reviewsReducer } from './reviews.reducer';
