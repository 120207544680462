import { createAction, handleActions } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { EmailModel } from '../../../models/email-history.model';
import { CustomAxios } from '../../axios';

export type EmailHistoryPayload = {
  params: string;
  locationID: string;
};

export type EmailHistoryResultPayload = any;

export const emailHistory = createAction<EmailHistoryPayload>('EMAIL_HISTORY');
export const emailHistorySuccess = createAction<EmailHistoryResultPayload>(
  'EMAIL_HISTORY_SUCCESS'
);
export const emailHistoryFailure = createAction<Error>('EMAIL_HISTORY_FAILURE');
export const emailHistoryReset = createAction('EMAIL_HISTORY_RESET');

export const handleEmailHistory = function* (action) {
  try {
    const params = action.payload.params;
    const locationID = action.payload.locationID;
    const response = yield call(
      CustomAxios.get,
      `/support/v1/locations/${locationID}/emails${params}`
    );
    yield put(emailHistorySuccess(response.data.data));
  } catch (error: any) {
    yield put(emailHistoryFailure(error));
  }
};

export const emailHistorySaga = function* () {
  yield all([takeEvery(emailHistory.toString(), handleEmailHistory)]);
};

const defaultState = {
  emails: [],
  loading: false,
};

export type EmailHistoryState = {
  emails: EmailModel[];
  loading: boolean;
  error?: string;
};

export const emailHistoryReducer = handleActions(
  {
    [emailHistory.toString()]: (state) => Object.assign({}, state, { loading: true }),
    [emailHistorySuccess.toString()]: (state, action) =>
      Object.assign({}, state, { emails: action.payload, loading: false }),
    [emailHistoryFailure.toString()]: (state, action) =>
      Object.assign({}, state, { error: action.payload, loading: false }),
    [emailHistoryReset.toString()]: () => defaultState,
  },
  defaultState
);
