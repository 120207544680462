/* Each cell in the table needs to be scrollable to be able to see long text */
import { css } from 'emotion';

export const reactTableStyle = css`
  & .rt-td,
  & .rt-th {
    overflow-x: auto;
    text-overflow: unset;
  }
  & ::-webkit-scrollbar {
    width: 1px;
    background: transparent;
  }
  label: reactTableStyle;
`;
