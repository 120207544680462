import React from 'react';
import { css } from 'emotion';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import 'react-table/react-table.css';
import { Button, Input } from '@weave/platform-ui-components';
import { TableStyles } from '../../../../styles/table-style';
import { ConfirmationModal } from '../../../shared/confirmation-modal/confirmation-modal.component';
import { isPhoneNumber } from '../../../../helpers/validators';
import { Page } from '../../../shared/page/page.component';

interface Props {
  blockedNumbers: any;
  locationId: string;
  loading: boolean;

  getBlockedNumbers: () => void;
  deleteBlockedNumber: (id: string) => void;
  addBlockedNumber: (numberToBlock: string) => void;
}

interface State {
  deleteModalIsOpen: boolean;
  numberPendingDeletion: string;
  numberToAdd: string;
  numberToAddError: string;
}

export class CallBlocking extends React.Component<Props, State> {
  state = {
    deleteModalIsOpen: false,
    numberPendingDeletion: '',
    numberToAdd: '',
    numberToAddError: '',
  };

  componentDidMount() {
    this.props.getBlockedNumbers();
  }

  actionButtons = (blockedNumber) => {
    return (
      <div>
        <Button size="small" onClick={() => this.onOpenDeleteModal(blockedNumber.id)}>
          Remove
        </Button>
      </div>
    );
  };

  onAddBlockedNumbers = (e) => {
    e.preventDefault();
    const isValidNumber = isPhoneNumber(this.state.numberToAdd);

    if (this.state.numberToAdd && isValidNumber) {
      this.props.addBlockedNumber(this.state.numberToAdd);
      this.setState({ numberToAdd: '', numberToAddError: '' });
    } else {
      this.setState({ numberToAddError: 'Please enter a valid phone number' });
    }
  };

  onCloseDeleteModal = () => {
    this.setState({ deleteModalIsOpen: false, numberPendingDeletion: '' });
  };

  onDeleteBlockedNumber = (id: string) => {
    this.props.deleteBlockedNumber(id);
    this.setState({ deleteModalIsOpen: false, numberPendingDeletion: '' });
  };

  onNumberToAddChange = (e) => {
    this.setState({ numberToAdd: e.target.value });
  };

  onOpenDeleteModal = (id: string) => {
    this.setState({ numberPendingDeletion: id, deleteModalIsOpen: true });
  };

  render() {
    return (
      <Page
        loading={false}
        title="Call Blocking"
        subtitle="Phone numbers listed here will not be able to make calls to this office."
        expandedSubtitle=" Text messages from these numbers will continue to come through normally."
      >
        <ConfirmationModal
          isOpen={this.state.deleteModalIsOpen}
          onConfirmClick={() =>
            this.onDeleteBlockedNumber(this.state.numberPendingDeletion)
          }
          onRequestClose={this.onCloseDeleteModal}
          title="Are you sure you want to unblock this number?"
          confirmBtnTitle="Unblock"
        />
        <div>
          <form
            className={css`
              margin: 16px 0;
              max-width: 400px;
            `}
            onSubmit={this.onAddBlockedNumbers}
          >
            {this.state.numberToAddError && <div>{this.state.numberToAddError}</div>}
            <Input
              placeholder="Phone Number"
              onChange={this.onNumberToAddChange}
              value={this.state.numberToAdd}
              className={css`
                display: inline-flex;
              `}
            />
            <Button
              className={css`
                display: inline-flex !important;
                margin-left: 16px;
              `}
              type="submit"
            >
              Block Number
            </Button>
          </form>
        </div>
        <ReactTable
          columns={[
            {
              Header: 'Number',
              accessor: 'phoneNumber',
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ['phoneNumber'] }),
              filterAll: true,
            },
            {
              Header: 'Date Blocked',
              accessor: 'createdAt',
              filterable: false,
            },
            {
              Header: 'Actions',
              id: 'Actions',
              accessor: (blockedNumber) => {
                return this.actionButtons(blockedNumber);
              },
              filterable: false,
            },
          ]}
          filterable
          data={this.props.blockedNumbers}
          loading={this.props.loading}
          defaultPageSize={15}
          css={TableStyles}
        />
      </Page>
    );
  }
}
