import React from 'react';
import { css } from '@emotion/core';
import { CustomAxios } from '../../../../redux/axios';
import { useAlert } from '@weave/alert-system';
import { WeaveTheme } from '@weave/theme-original';
import {
  useFormField,
  FormRow,
  PrimaryButton,
  ButtonBar,
  SecondaryButton,
  Modal,
  PhoneField,
  ModalControlModalProps,
  phone,
} from '@weave/design-system';

type SolicitationModalContentProps = Pick<ModalControlModalProps, 'onClose'>;

const SolicitationModalContent = ({ onClose }: SolicitationModalContentProps) => {
  const phoneFieldProps = useFormField({ type: 'phone', required: true });
  const alerts = useAlert();

  const handleTestReviewSend = async () => {
    if (phoneFieldProps.error) {
      return;
    }

    try {
      await CustomAxios.post('support/v1/reviews/invite', {
        phone_Number: phoneFieldProps.value,
      });
      onClose();
      alerts.success(`Solicitation sent to ${phone(phoneFieldProps.value)}.`);
    } catch {
      alerts.error('Failed to send solicitation invitation!');
    }
  };

  return (
    <>
      <FormRow
        css={(theme: WeaveTheme) =>
          css`
            margin-top: ${theme.spacing(1)};
          `
        }
      >
        <PhoneField
          label="Enter Phone Number"
          name="send_test_phone_number"
          {...phoneFieldProps}
        />
      </FormRow>
      <ButtonBar>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <PrimaryButton disabled={!!phoneFieldProps.error} onClick={handleTestReviewSend}>
          Send
        </PrimaryButton>
      </ButtonBar>
    </>
  );
};

type SolicitationModalProps = ModalControlModalProps & SolicitationModalContentProps;

export const SolicitationModal = ({ onClose, show, ...rest }: SolicitationModalProps) => {
  return (
    <Modal onClose={onClose} show={show}>
      <Modal.Header>Send Review Solicitation</Modal.Header>
      <Modal.Body>
        <SolicitationModalContent onClose={onClose} {...rest} />
      </Modal.Body>
    </Modal>
  );
};
