import { createAction } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { CustomAxios } from '../../axios';
import { showError } from '@weave/alert-system';

export const getCallForwardingNumbers = createAction('GET_CALL_FORWARDING_NUMBERS');
export const getCallForwardingNumbersSuccess = createAction(
  'GET_CALL_FORWARDING_NUMBERS_SUCCESS'
);
export const getCallForwardingNumbersFailure = createAction(
  'GET_CALL_FORWARDING_NUMBERS_FAILURE'
);
export const createCallForwardingNumber = createAction('CREATE_CALL_FORWARDING_NUMBER');

const handleGetCallForwardingNumbers = function* () {
  try {
    const response = yield call(CustomAxios.get, 'support/v1/forwarding');

    yield put(getCallForwardingNumbersSuccess(response.data.data));
  } catch {
    yield put(
      showError('Failed to get call forwarding numbers. Please refresh the page.')
    );
    yield put(getCallForwardingNumbersFailure());
  }
};

export const callForwardingSaga = function* () {
  yield all([
    takeEvery(getCallForwardingNumbers.toString(), handleGetCallForwardingNumbers),
  ]);
};
