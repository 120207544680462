import React from 'react';

import { cx } from 'emotion';
import moment from 'moment-timezone';

import { MsgDirectionType, SmsModel } from '../../../../../models/sms-history.model';
import { PatientsModel } from '../../../../../models/patient.model';
import * as styles from './../sms-details.styles.js';

import { dateTimeZoneFormat } from '../../../../../helpers/utils';
import { history } from '../../../../../redux/store/history';
import { UuidCopier } from '../../../patient-details-container/uuid-copier';
import { alternateID } from '../../../patient-details-container/patient-history-lists/patient-sms-history-container/patient-sms-history/sms-history.card';

interface Payload {
  locationId: string;
  criteria: {
    status: string;
  };
}
export type Props = {
  locationID: string;
  smsDetails: SmsModel;
  timezone: string;
  patientList: PatientsModel[];
  getPatients: (payload: Payload) => void;
  hashedNumber: string;
};

export const MsgStatusTypeMapping = {
  STATUS_UNSPECIFIED: 'Unspecified',
  STATUS_NEW: 'New',
  STATUS_SENT: 'Sent',
  STATUS_NOT_SENT: 'Not Sent',
  STATUS_DELIVERED: 'Delivered',
  STATUS_RECEIVED: 'Received',
  STATUS_READ: 'Read',
  STATUS_ERROR: 'Error',
};

export const Error = 'Error';
export const NotSent = 'STATUS_NOT_SENT';

export class SmsInfo extends React.Component<Props> {
  renderSmsInfo = (labelTxt, dataTxt, style = '') => {
    return (
      <div className={styles.infoBox}>
        <label className={styles.infoBoxLeft}>{labelTxt}</label>
        <p className={cx(styles.infoBoxRight, style)}>{dataTxt}</p>
      </div>
    );
  };

  componentDidMount() {
    const patientListIsEmpty = !this.props.patientList.length;
    const locationIDExists = this.props.locationID;

    if (patientListIsEmpty && locationIDExists) {
      const payload = {
        locationId: this.props.locationID,
        criteria: {
          status: 'Active',
        },
      };
      this.props.getPatients(payload);
    }
  }

  routeToPatientDetails = () => {
    if (this.props.smsDetails.personId) {
      const personID = this.props.smsDetails.personId;
      const personPhone = this.props.smsDetails.personPhone;
      history.push(
        `/location/${this.props.locationID}/history/patient-details?personid=${personID}&personphone=${personPhone}`
      );
    }
  };

  copied = () => {
    this.setState({ copied: true });
    setTimeout(() => {
      this.setState({ copied: false });
    }, 3000);
  };

  render() {
    const { smsDetails, timezone } = this.props;
    const personId = smsDetails?.personId ? smsDetails.personId : alternateID;
    const date = timezone
      ? moment(smsDetails.createdAt).tz(timezone).format(dateTimeZoneFormat)
      : '';
    const statusStyle =
      smsDetails.status === Error || smsDetails.status === NotSent ? styles.error : '';
    const statusCode = MsgStatusTypeMapping[smsDetails.status];
    const isOutbound = smsDetails.direction === MsgDirectionType.Outbound;

    const smsStatus =
      statusCode + (smsDetails.statusDetails ? `: ${smsDetails.statusDetails}` : '');
    return (
      <div className={cx(styles.flexStyle, styles.smsInfoComponent)}>
        <div className={styles.smsInfoBox}>
          <div className={styles.infoBox}>
            <label className={styles.infoBoxLeft}>UUID</label>
            <div className={cx(styles.infoBoxRight, styles.clickable, styles.uuid)}>
              <UuidCopier uuid={smsDetails.id} />
            </div>
          </div>
          {this.renderSmsInfo('Date Created', date)}
          {this.renderSmsInfo('Direction', isOutbound ? 'Outbound' : 'Inbound')}
          {this.renderSmsInfo('Status', smsStatus, statusStyle)}
          <br />
          <div className={styles.infoBox}>
            <label className={styles.infoBoxLeft}>Patient ID</label>
            <div
              className={cx(styles.infoBoxRight, styles.linkBtn)}
              title="View Patient Details"
              onClick={() => this.routeToPatientDetails()}
            >
              {`${personId.slice(0, 4)}...`}
            </div>
          </div>
          <div className={styles.infoBox}>
            <label className={styles.infoBoxLeft}>Patient Number</label>
            <div className={styles.infoBoxRight}>
              <UuidCopier uuid={this.props.hashedNumber} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
