import {
  locationMissedCallSMSQuery,
  LocationMissedCallSMSResponse,
} from './queries/location-missed-call-sms';
import { weaveBlue, weaveGreen } from '@weave/platform-ui-components';

enum ChartType {
  doughnut = 'doughnut',
}

interface DoughnutChartData {
  chartDataProp: {
    datasets: {
      data: any[];
      [key: string]: any;
    }[];
  };
  total: number;
  chartLegend: any[];
  chartTooltipData: any[];
}

export interface MetricOption {
  label: string;
  query: any;
  graphConfig: {
    type: keyof typeof ChartType;
    assembleChartData: (data: any) => DoughnutChartData | undefined;
  };
}

export const missedCallSMSMetrics: MetricOption[] = [
  {
    label: 'Missed Call SMS Response',
    query: locationMissedCallSMSQuery,
    graphConfig: {
      type: ChartType.doughnut,
      assembleChartData: function (
        queryData: LocationMissedCallSMSResponse
      ): DoughnutChartData | undefined {
        if (!queryData) {
          return undefined;
        }

        const colors = [weaveGreen, weaveBlue];
        const labels = ['Responded', 'No Response'];

        const data = queryData.location.missedCallSMS;
        const responded = data.totals.responded;
        const notResponded = data.totals.sent - data.totals.responded;
        const total = data.totals.sent;

        const assembleLegendItem = (dataPoint, index) => {
          return {
            color: colors[index],
            data: [labels[index], dataPoint, `${Math.round((dataPoint / total) * 100)}%`],
          };
        };

        const assembleTooltipItem = (dataPoint, index) => {
          return {
            data: [
              dataPoint,
              `(${Math.round((dataPoint / total) * 100)}%)`,
              labels[index],
            ],
          };
        };

        return {
          chartDataProp: {
            datasets: [
              {
                data: [responded, notResponded],
                backgroundColor: colors,
                borderWidth: 0,
              },
            ],
          },
          total,
          chartLegend: [
            assembleLegendItem(responded, 0),
            assembleLegendItem(notResponded, 1),
          ],
          chartTooltipData: [
            assembleTooltipItem(responded, 0),
            assembleTooltipItem(notResponded, 1),
          ],
        };
      },
    },
  },
];
