import { createAction } from 'redux-actions';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { getErrorMessage } from '../../axios';
import { showError, showSuccess } from '@weave/alert-system';
import map from 'lodash/map';
import { getUsers } from './users-list.action';
import { UserModel } from '../../../models/user.model';
import { Store } from '../../store/store.model';
import { usersApi } from './users.api';
import { ResolvedPromise } from '../../store/store-utils';

/* ~~~~~~~~~~~~ TYPES ~~~~~~~~~~~~ */
export interface RolesModel {
  data?: any;
  ID?: string | number;
  Name: string;
  Type: string;
  value?: string;
  label?: string;
  TypeID?: string;
}

export interface JobTitlesModel {
  ID: string;
  Name: string;
  Type: string;
  TypeID: string;
  Vertical: string;
  ModifiedAt: string;
  CreatedAt: string;
  Deleted: boolean;
  LocationID: string;
}
export type AvailableRoles = {
  data: RolesModel[];
};
export type AvailableJobTitles = {
  data: JobTitlesModel[];
};

type UpdateUserRolesAction = {
  type: 'UPDATE_USER_ROLES';
  payload: UserModel & { LocationID: string };
};

export type UpdateUserAccessReq = Omit<UserModel, 'Roles' | 'JobTitles'> & {
  Roles: RolesModel['ID'][];
  LocationID: string;
};

/* ~~~~~~~~~~~~ SELECTORS ~~~~~~~~~~~~ */

export const selectAvailableRoles = (state: Store): RolesModel[] =>
  state.availableRoles.data;
export const selectUserFound = (state: Store): boolean => state.userSearch.userFound;

/* ~~~~~~~~~~~~ ACTION CREATORS ~~~~~~~~~~~~ */

export const getAvailableRoles = createAction('GET_AVAILABLE_ROLES');
export const getAvailableRolesSuccess = createAction('GET_AVAILABLE_ROLES_SUCCESS');
export const setLoading = createAction('SET_AVAILABLE_ROLES_LOADING');
export const updateUserRoles = createAction<UserModel>('UPDATE_USER_ROLES');
export const updateUserRolesSuccess = createAction('UPDATE_USER_ROLES_SUCCESS');

/* ~~~~~~~~~~~~ GENERATOR FUNCTIONS ~~~~~~~~~~~~ */

export const handleGetAvailableRoles = function* (action) {
  try {
    const availableRoles: ResolvedPromise<ReturnType<typeof usersApi.getAvailableRoles>> =
      yield call(usersApi.getAvailableRoles);
    yield put(getAvailableRolesSuccess(availableRoles || []));
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    yield put(showError(getErrorMessage(error)));
  }
};

export const handleUpdateUserRoles = function* (action: UpdateUserRolesAction) {
  const user = action.payload;
  const userDetails: UpdateUserAccessReq = {
    UserID: user.UserID,
    Username: user.Username,
    Type: user.Type,
    FirstName: user.FirstName,
    LastName: user.LastName,
    TicketID: user.TicketID,
    LocationID: user.LocationID,
    Roles: map(user.Roles, 'ID'),
  };
  try {
    yield call(usersApi.updateUserRoles, userDetails);
    yield put(showSuccess('User Roles Update Successful'));
    yield put(updateUserRolesSuccess(user));
    yield put(getUsers({ limit: 25 })); // get the new updates to render to the list
  } catch (error: any) {
    yield put(setLoading(false));
    yield put(showError(getErrorMessage(error)));
  }
};

/* ~~~~~~~~~~~~ SAGAS ~~~~~~~~~~~~ */

export const getAvailableRolesSaga = function* () {
  yield takeLatest(getAvailableRoles.toString(), handleGetAvailableRoles);
};

export const updateUserRolesSaga = function* () {
  yield takeEvery(updateUserRoles.toString(), handleUpdateUserRoles);
};
