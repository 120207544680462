import { createAction } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { CustomAxios, getErrorMessage } from '../../../axios';
import { showError, addAlert } from '@weave/alert-system';

export const upgradeSyncApp = createAction('UPGRADE_SYNC_APP');

export const handleUpgradeSyncApp = function* () {
  try {
    yield call(CustomAxios.post, '/support/v1/analytics-admin/onboarding/sync-app', {
      enabled: true,
    });
    yield put(addAlert({ type: 'info', message: 'Sync app upgrading' }));
  } catch (error: any) {
    yield put(showError(getErrorMessage(error)));
  }
};

export const upgradeSyncAppSaga = function* () {
  yield all([takeEvery(upgradeSyncApp.toString(), handleUpgradeSyncApp)]);
};
