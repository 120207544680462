import React, { useState, useEffect } from 'react';
import { CustomAxios } from '../../../redux/axios';
import { Text } from '@weave/design-system';
import { css } from '@emotion/core';
import {
  format,
  differenceInMinutes,
  differenceInSeconds,
  addDays,
  formatDistance,
} from 'date-fns';
import { friendlyStatusMap } from '../../shared/charts/map-friendly-statuses';
import { UuidCopier } from '../../history-list-container/patient-details-container/uuid-copier';
import { NotificationQueueModel } from '../../../models/notification-queue.model';
import { dateTimeDateFns } from '../../../helpers/utils';

const messageQueueContainer = css`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  & .container {
    position: relative;
    box-shadow: 4px 4px 8px #eee;
    border: 1px solid #eee;
    margin: 16px;
    padding: 16px;
    width: max-content;
  }
`;

const dataset = css`
  display: flex;
  width: 400px;
  span {
    font-weight: bold;
    text-align: right;
    width: 40%;
    padding-right: 16px;
  }
  & .rawStatus {
    p {
      margin: 0;
      padding: 0;
    }
    margin-bottom: 8px;
  }
`;

const titleUuid = css`
  display: flex;
  p {
    padding-right: 8px;
    padding-bottom: 0;
  }
`;

export const AutoMessageQueue = ({ reminderSetting, query }) => {
  const [queue, setQueue] = useState<NotificationQueueModel[]>([]);

  useEffect(() => {
    if (query.id) {
      CustomAxios.get(`/support/v1/notifications/queue?type_id=${query.id}`)
        .then((res) => setQueue(res.data.data || []))
        .catch((err) => console.error(err));
    }
  }, [query.id]);

  return (
    <>
      <Text weight="bold">Auto Message Queue</Text>
      <div css={messageQueueContainer}>
        {!queue.length && (
          <Text color="light" size="small">
            No records found in the auto message queue
          </Text>
        )}
        {!!queue.length &&
          queue.map((item) => {
            const hasBeenDelivered = !!item.delivered_at;
            const deliverAt = new Date(item.deliver_at);
            const deliveredAtDate = new Date(item.delivered_at);
            const deliveredAt = hasBeenDelivered
              ? format(deliveredAtDate, dateTimeDateFns)
              : 'N/A';
            const fourteenDays = addDays(deliverAt, 14);
            const now = new Date();
            const expiration = formatDistance(now, fourteenDays);
            const mins = differenceInMinutes(deliveredAtDate, deliverAt);
            const secs = differenceInSeconds(deliveredAtDate, deliverAt) - mins * 60;
            const queueDuration = hasBeenDelivered ? `${mins}mins ${secs}secs` : 'N/A';
            const status =
              item.status === 'delivered'
                ? 'Delivered to UCOMM'
                : friendlyStatusMap[item.status];
            const reminder = reminderSetting.find(
              (reminder) => reminder.id === item.notification_setting_id
            );

            type StyleStatus = 'default' | 'warn' | 'success' | 'error';

            let styleStatus: StyleStatus = 'default';
            if (status !== 'Delivered' && status !== 'Delivered to UCOMM') {
              if (status === 'Pending') {
                styleStatus = 'warn';
              } else if (status === 'Sending') {
                styleStatus = 'success';
              } else {
                styleStatus = 'error';
              }
            } else {
              styleStatus = 'success';
            }

            return (
              <div key={item.id} className="container">
                <div css={dataset}>
                  <Text as="span">Reminder</Text>
                  <div css={titleUuid}>
                    <Text>{reminder?.name}</Text>
                    <UuidCopier uuid={reminder?.id} />
                  </div>
                </div>

                <div css={dataset}>
                  <Text as="span">Status</Text>
                  <div className="rawStatus">
                    <Text color={styleStatus}>{status}</Text>
                    <Text>{item.status}</Text>
                  </div>
                </div>

                <div css={dataset}>
                  <Text as="span">Est send time</Text>
                  <Text>{format(deliverAt, dateTimeDateFns)}</Text>
                </div>
                <div css={dataset}>
                  <Text as="span">Actual send time</Text>
                  <Text>{deliveredAt}</Text>
                </div>
                <div css={dataset}>
                  <Text as="span">Queue duration</Text>
                  <Text>{queueDuration}</Text>
                </div>
                <Text color="light">This record will be removed in {expiration}</Text>
              </div>
            );
          })}
      </div>
    </>
  );
};
