import React from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { IconButton, InfoRoundIconSmall, Text, useTooltip } from '@weave/design-system';

import { formatDateFromUnixTimestamp } from '../../../helpers/utils';
import {
  healthTooltipStyle,
  reportLabelInfo,
  reportStyle,
} from '../location-sidebar.styles';

interface Props {
  reports: { [key: string]: string }[];
}

export const SyncAppReport = ({ reports }: Props) => {
  const { tooltipProps, triggerProps, Tooltip } = useTooltip({ placement: 'right' });

  return (
    <div css={reportLabelInfo}>
      <Text as="span" size="medium">
        {reports[0]?.integration}
      </Text>
      <IconButton label="Health Report" size="small" {...triggerProps}>
        <InfoRoundIconSmall />
      </IconButton>
      <Tooltip {...tooltipProps} hasInteractiveContent css={healthTooltipStyle}>
        <Text
          as="span"
          css={css`
            font-size: ${theme.fontSize(20)};
          `}
          textAlign="center"
        >
          Sync App Report
        </Text>
        {reports.map((report, index) => (
          <span key={index} css={reportStyle}>
            <Text as="span" weight="bold">
              {report?.reportType}
            </Text>
            <Text as="span">{report?.issue}</Text>
            {report?.lastUpdated && (
              <Text as="span" size="medium" weight="bold">
                {formatDateFromUnixTimestamp(report.lastUpdated)}
              </Text>
            )}
          </span>
        ))}
      </Tooltip>
    </div>
  );
};
