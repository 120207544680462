import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { createRequestSaga } from '@weave/alert-system';
import { selectCurrentLocationId } from '../location/current-location';
import { addPhoneNumbersToLocation } from '../location/location-entities';
import { PhoneNumber } from '../../../apis/phone-numbers/phone-numbers.types';
import { mockPhoneNumbers } from '../../../apis/phone-numbers/phone-numbers.mocks';

export enum PhoneNumbersActionTypes {
  RequestList = 'REQUEST_LIST_PHONE_NUMBERS',
  Add = 'ADD_PHONE_NUMBERS',
  Update = 'UPDATE_PHONE_NUMBERS',
}

type Action<ActionType extends PhoneNumbersActionTypes, Payload = undefined> = {
  type: ActionType;
} & { payload: Payload };
type RequestListAction = Action<PhoneNumbersActionTypes.RequestList>;
type AddAction = Action<PhoneNumbersActionTypes.Add, PhoneNumber[]>;
type UpdateAction = Action<
  PhoneNumbersActionTypes.Update,
  { id: string; phoneNumber: Partial<PhoneNumber> }
>;

export type PhoneNumbersActions = AddAction | RequestListAction | UpdateAction;

export const requestListPhoneNumbers = (): PhoneNumbersActions => ({
  type: PhoneNumbersActionTypes.RequestList,
  payload: undefined,
});
export const addPhoneNumbers = (phoneNumbers: PhoneNumber[]): AddAction => ({
  type: PhoneNumbersActionTypes.Add,
  payload: phoneNumbers,
});
export const updatePhoneNumber = (
  id: string,
  phoneNumber: Partial<PhoneNumber>
): UpdateAction => ({
  type: PhoneNumbersActionTypes.Update,
  payload: { id, phoneNumber },
});

// const api = {
//   listPhoneNumbers: (): Promise<PhoneNumber[]> =>
//     CustomAxios.get(`support/v1/phone/phone-numbers`).then( getResponseData ),
// }

const api = {
  listPhoneNumbers: (): Promise<PhoneNumber[]> =>
    new Promise((resolve) => resolve(mockPhoneNumbers())),
};

const requestListPhoneNumbersSaga = createRequestSaga<RequestListAction>({
  key: PhoneNumbersActionTypes.RequestList,
  onError: () => 'Failed to get phoneNumbers. Please refresh the page.',
  displayErrors: true,
  saga: function* () {
    const phoneNumbers: PhoneNumber[] = yield call(api.listPhoneNumbers);
    const locationId: ReturnType<typeof selectCurrentLocationId> = yield select(
      selectCurrentLocationId
    );
    yield put(addPhoneNumbers(phoneNumbers));
    yield put(
      addPhoneNumbersToLocation(locationId, phoneNumbers?.map((num) => num.id) ?? [])
    );
  },
});

export const phoneNumbersSaga = function* () {
  yield all([
    takeLatest(PhoneNumbersActionTypes.RequestList, requestListPhoneNumbersSaga),
  ]);
};
