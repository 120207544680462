import React from 'react';
import { css } from 'emotion';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import { locationDashboardStyles } from './location-dashboard.styles';
import { InteractiveCellText } from '../../interactive-cell-text.component';
import { dateTimeFormat } from '../../../../helpers/utils';
import { Syncs } from '../../../../redux/actions/smart-alerts/smart-alerts.action';
import { DotSvg } from './dot-svg';

interface Props {
  syncData: Syncs[];
}

export interface State {
  modalOpen: boolean;
  startDate: Date;
  endDate: Date;
}

const formatDate = (seconds: string | number): string => {
  if (!seconds) {
    return '';
  }

  return moment(seconds, ['X', 'YYYY-MM-DDTHH:mm:ssZ']).format(dateTimeFormat);
};

export default class SyncTableComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    moment.locale('en');
    momentLocalizer();

    this.state = {
      modalOpen: false,
      startDate: moment().subtract(1, 'week').toDate(),
      endDate: new Date(),
    };
  }

  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };

  render() {
    return (
      <tbody className={locationDashboardStyles.tableBody}>
        {typeof this.props.syncData === 'undefined'
          ? 'Loading props sync data'
          : this.props.syncData.map((sync, syncIndex) => (
              <tr key={syncIndex}>
                <td> Sync </td>
                <td className={locationDashboardStyles.dotColumn}>
                  <DotSvg color="error" />
                  {this.getSyncReportTime(sync.Reasons)}
                </td>
                <td
                  className={css`
                    padding: 10px;
                  `}
                >
                  {this.getSyncStatusDescription(sync.Reasons[2])}
                </td>
                <td>
                  <div className={locationDashboardStyles.tableActions}>
                    <InteractiveCellText
                      text={
                        sync.Reasons[2] === ''
                          ? 'There are no details for the alert'
                          : sync.Reasons[2]
                      }
                    />
                  </div>
                </td>
              </tr>
            ))}
      </tbody>
    );
  }

  private getSyncReportTime(syncReasons) {
    const reportTime = syncReasons[2].split('Reported at:')[1].split('UTC')[0];
    return reportTime
      ? moment(reportTime).format(dateTimeFormat)
      : this.getLastSyncDate(syncReasons[0]);
  }

  // syncLast is the first element in the sync.Reasons, which displayed when the last sync time was done
  private getLastSyncDate(syncLast) {
    const dateSync = syncLast.split('synced:')[1];
    return dateSync ? formatDate(dateSync) : 'Unable to get sync date';
  }

  // reasonElement is fed in by the third element in sync.Reasons, which illustrates why the sync might have failed
  private getSyncStatusDescription(reasonElement) {
    try {
      if (!reasonElement.includes('Errors:')) {
        return 'Sync app mismatch';
      }
      const syncDescription = reasonElement.split('Errors:').pop().split('|')[0];
      return syncDescription;
    } catch {
      return 'Invalid sync description';
    }
  }
}
