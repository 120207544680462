import { LogWrapper } from './auto-reminder-log-component';

import { getNotificationDeliverySummary } from '../../../../redux/actions/notifications-settings/notifications-settings.action';
import {
  NotificationReportDetails,
  ReminderDetails,
} from '../../../../redux/actions/notifications-settings/notification-settings.types';

import { connect } from 'react-redux';

const mapDispatchToProps = { getNotificationDeliverySummary };

interface MapStateToProps {
  deliveryReports: NotificationReportDetails[];
}

interface OwnProps {
  selectedAutoReminder: ReminderDetails;
}

type DispatchProps = {
  getNotificationDeliverySummary: (id: string) => void;
};

const mapStateToProps = (store, ownProps) => ({
  deliveryReports:
    store.notificationsSettings.deliveryReports[ownProps.selectedAutoReminder.id] || [],
});

export const LogWrapperContainer = connect<MapStateToProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(LogWrapper);
