import React, { useMemo, useState } from 'react';

import { ReminderDetails } from '../../../../redux/actions/notifications-settings/notification-settings.types';
import { SmsModel } from '../../../../models/sms-history.model';
import { css } from '@emotion/core';
import { Deliverability, totalColumns } from './auto-messaging-module';

interface Props {
  key?: any;
  name?: string;
  reminder?: ReminderDetails;
  autoMessages: SmsModel[];
  getDailyAverage: (autoMessages: SmsModel[]) => string;
  getNumberOfMessagesQueuedForToday: (id: string) => {
    sent: number;
    notSent: number;
    total: number;
  };
  getDeliverability: (autoMessages: SmsModel[]) => Deliverability;
  dropdownComponent?: JSX.Element;
}

export const AutoReminderDetails = ({
  name,
  reminder,
  autoMessages,
  getDailyAverage,
  getNumberOfMessagesQueuedForToday,
  getDeliverability,
  dropdownComponent,
}: Props) => {
  const [expanded, setExpanded] = useState(false);

  const deliverability = useMemo(() => getDeliverability(autoMessages), [autoMessages]);
  const { today, yesterday, overall } = deliverability;

  const queuedToday = reminder
    ? getNumberOfMessagesQueuedForToday(reminder.id)
    : undefined;

  const handleRowClick = () => {
    setExpanded(!expanded);
  };

  const getPercentage = (amount: number, total: number) => {
    if (!amount || !total) {
      return '0%';
    }

    const decimal = amount / total;

    return Math.round(decimal * 100) + '%';
  };

  return (
    <>
      <tr onClick={handleRowClick} css={trStyles}>
        {/* Name */}
        <td css={tdStyles}>{reminder?.name ?? name}</td>

        {/* colGroup: Deliverability - Today */}
        <td css={tdStyles}>
          <div css={flexColumn}>
            {today.errorCount} &nbsp;
            <strong>
              ({getPercentage(today.errorCount, today.errorCount + today.successCount)})
            </strong>
          </div>
        </td>
        <td css={tdStyles}>
          <div css={flexColumn}>
            {today.successCount} &nbsp;
            <strong>
              ({getPercentage(today.successCount, today.successCount + today.errorCount)})
            </strong>
          </div>
        </td>
        <td css={tdStyles}>{today.successCount + today.errorCount}</td>

        {/* colGroup: Deliverability - Yesterday */}
        <td css={tdStyles}>
          <div css={flexColumn}>
            {yesterday.errorCount} &nbsp;
            <strong>
              (
              {getPercentage(
                yesterday.errorCount,
                yesterday.errorCount + yesterday.successCount
              )}
              )
            </strong>
          </div>
        </td>
        <td css={tdStyles}>
          <div css={flexColumn}>
            {yesterday.successCount} &nbsp;
            <strong>
              (
              {getPercentage(
                yesterday.successCount,
                yesterday.successCount + yesterday.errorCount
              )}
              )
            </strong>
          </div>
        </td>
        <td css={tdStyles}>{yesterday.successCount + yesterday.errorCount}</td>

        {/* colGroup: Deliverability - 2 Week */}
        <td css={tdStyles}>
          <div css={flexColumn}>
            {overall.errorCount} &nbsp;
            <strong>
              (
              {getPercentage(
                overall.errorCount,
                overall.errorCount + overall.successCount
              )}
              )
            </strong>
          </div>
        </td>
        <td css={tdStyles}>
          <div css={flexColumn}>
            {overall.successCount} &nbsp;
            <strong>
              (
              {getPercentage(
                overall.successCount,
                overall.successCount + overall.errorCount
              )}
              )
            </strong>
          </div>
        </td>
        <td css={tdStyles}>{overall.successCount + overall.errorCount}</td>
        <td css={tdStyles}>{getDailyAverage(autoMessages)}</td>

        {/* colGroup: Queued Today */}
        <td css={tdStyles}>{queuedToday?.notSent ?? 'N/A'}</td>
        <td css={tdStyles}>{queuedToday?.sent ?? 'N/A'}</td>
        <td css={tdStyles}>{queuedToday?.total ?? 'N/A'}</td>
      </tr>
      {expanded && (
        <tr>
          <td />
          <td colSpan={totalColumns - 1}>
            <>
              {!!today.errors.length && (
                <>
                  <hr />
                  <h3 css={errorHeader}>Error Types - Today</h3>
                  <table>
                    <tbody>
                      {overall.errors.map(([key, value]) => (
                        <tr key={key} css={errorGroup}>
                          <td css={errorValue}>{value} : &nbsp;</td>
                          <td>{key}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
              {!!yesterday.errors.length && (
                <>
                  <hr />
                  <h3 css={errorHeader}>Error Types - Yesterday</h3>
                  <table>
                    <tbody>
                      {overall.errors.map(([key, value]) => (
                        <tr key={key} css={errorGroup}>
                          <td css={errorValue}>{value} : &nbsp;</td>
                          <td>{key}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
              {!!overall.errors.length && (
                <>
                  <h3 css={errorHeader}>Error Types - 2 Weeks</h3>
                  <table>
                    <tbody>
                      {overall.errors.map(([key, value]) => (
                        <tr key={key} css={errorGroup}>
                          <td css={errorValue}>{value} : &nbsp;</td>
                          <td>{key}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
              {!overall.errors.length && !today.errors.length && <div>No errors</div>}
              {dropdownComponent}
            </>
          </td>
        </tr>
      )}
    </>
  );
};

const errorHeader = css`
  display: inline-block;
  border-bottom: 1px solid #6c757e;
  padding: 8px;
  font-weight: bold;
`;
const errorGroup = css`
  padding: 4px;
`;
const errorValue = css`
  font-weight: bold;
  text-align: right;
`;
const trStyles = css`
  border-top: 1px solid #6c757e;
  cursor: pointer;
`;
const tdStyles = css`
  padding: 8px 16px;
  text-align: center;
`;
const flexColumn = css`
  display: flex;
  flex-direction: column;
`;
