import React from 'react';
import { IconButton, CaretDownIcon } from '@weave/design-system';
import { css } from '@emotion/core';

type Props = {
  isCarrotUpState: boolean;
  label: string;
  showLabelOnHover?: boolean;
  onClick: (event: any) => void;
};

export const CarrotIconButton = ({
  isCarrotUpState,
  label,
  showLabelOnHover,
  onClick,
}: Props) => {
  return (
    <IconButton
      showLabelOnHover={showLabelOnHover}
      onClick={onClick}
      label={label}
      css={[iconButtonStyles, isCarrotUpState && CaretUpIconButtonStyles]}
    >
      <CaretDownIcon />
    </IconButton>
  );
};

const iconButtonStyles = css`
  transition: transform 0.25s;
`;

const CaretUpIconButtonStyles = css`
  transform: rotate(0.5turn);
`;
