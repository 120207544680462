import React from 'react';
import { Modal, ModalControlModalProps, Tabs } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { AddGreetingMediaForm } from './add-greeting-media-form.component';
import { AddGreetingUploadForm } from './add-greeting-upload-form.component';
import * as styles from './greeting-upload-modal.styles';
import { PhoneMedia } from '../../../../redux/actions';
import { css } from '@emotion/core';
interface Props {
  activeMailboxId: string;
  locationId: string;
  phoneMedia: PhoneMedia[];
  uploadGreeting: (payload: {
    greetingFile: File | null;
    greetingName: string;
    mailboxId: string;
  }) => void;
  uploadGreetingConversion: (payload: { mediaId: string; mailboxId: string }) => void;
  modalProps: Pick<ModalControlModalProps, 'onClose' | 'show'>;
}

export const GreetingUploadModal = (props: Props) => {
  return (
    <Modal
      css={css`
        padding: ${theme.spacing(2)};
        text-align: center;
      `}
      {...props.modalProps}
      maxWidth={700}
    >
      <div>
        <div className={styles.containNav}>
          <Modal.Header>Add a Greeting to this Mailbox</Modal.Header>
          <Tabs
            initialTab="library"
            css={css`
              li {
                margin: 0;
                width: 50%;
              }
            `}
          >
            <Tabs.Bar>
              <Tabs.Tab id="library" controls="library-panel">
                Select from media library
              </Tabs.Tab>
              <Tabs.Tab id="upload" controls="upload-panel">
                Upload from computer
              </Tabs.Tab>
            </Tabs.Bar>
            <Tabs.Panel id="library-panel" controller="library">
              <AddGreetingMediaForm
                activeMailboxId={props.activeMailboxId}
                isOpen={props.modalProps.show}
                onRequestClose={props.modalProps.onClose}
                uploadGreetingConversion={props.uploadGreetingConversion}
              />
            </Tabs.Panel>
            <Tabs.Panel id="upload-panel" controller="upload">
              <AddGreetingUploadForm
                activeMailboxId={props.activeMailboxId}
                isOpen={props.modalProps.show}
                onRequestClose={props.modalProps.onClose}
                uploadGreeting={props.uploadGreeting}
              />
            </Tabs.Panel>
          </Tabs>
        </div>
      </div>
    </Modal>
  );
};
