import React from 'react';
import { WeaveTheme } from '@weave/theme-original';
import {
  TextField,
  useForm,
  FormRow,
  DateField,
  Modal,
  ModalControlModalProps,
} from '@weave/design-system';
import { PublishDetails } from './models';
import { css } from '@emotion/core';

type Props = Pick<ModalControlModalProps, 'onClose'> & {
  onSubmit: (data: PublishDetails) => void;
};

export const PublishModal = ({ onClose, onSubmit }: Props) => {
  const { formProps, getFieldProps } = useForm({
    fields: {
      time: { type: 'date' },
      batchSize: { type: 'number' },
      interval: { type: 'number' },
    },
    onSubmit: (values: any) => {
      const details: PublishDetails = {
        ReleaseTime: new Date(values.time).toISOString(),
        ReleaseBatchSize: +values.batchSize,
        ReleaseInterval: `${values.interval}h`,
      };
      onSubmit(details);
    },
  });

  return (
    <form {...formProps}>
      <Modal.Header>Publish Details</Modal.Header>
      <Modal.Body
        css={(theme: WeaveTheme) => css`
          padding-top: ${theme.spacing(1)};
        `}
      >
        <FormRow>
          <DateField {...getFieldProps('time')} label="Release Date" />
        </FormRow>
        <FormRow>
          <TextField {...getFieldProps('batchSize')} label="Batch Size" />
        </FormRow>
        <FormRow>
          <TextField {...getFieldProps('interval')} label="Batch Interval (in hours)" />
        </FormRow>
      </Modal.Body>
      <Modal.Actions
        primaryLabel="Submit"
        onPrimaryClick="submit"
        onSecondaryClick={onClose}
      />
    </form>
  );
};
