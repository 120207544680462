import { REACT_APP_FIREBASE_TOKEN_URL } from '../../../config/app';
import { AuthStorage } from '../auth/auth.types';

/**
 * @desc: Function is defined to get the firebase token from cloud function end point
 * @returns string : (Firebase Token)
 */
export const getFirebaseTokenFirebase = async () => {
  //Utilizing the token value from localstorage as it's the weave token
  //Please ignore using id_token for generating firebase token
  const accessToken = localStorage.getItem(AuthStorage.weave_token);
  const firebaseTokenResponse = fetch(REACT_APP_FIREBASE_TOKEN_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return (await firebaseTokenResponse).text();
};
