import { CustomAxios } from '../../../../redux/axios';
import {
  historyUrl,
  getRelevantReminder,
  checkDayOfWeekSettings,
  DebugLog,
  getSendLogs,
  getStatus,
  getDayOfWeekLogs,
  checkAvancedOptions,
} from '../reminder-checker';

const getSubtypeLogs = (reminder, orderData) => {
  const log: DebugLog[] = [];
  if (reminder.sub_type !== orderData.EventSubType) {
    log.push({
      type: 'error',
      message: `Reminder set to send to ${reminder.sub_type}, order type is currently ${orderData.EventSubType}`,
    });
  }
  return log;
};

const getTypeLogs = (reminder, orderData) => {
  const log: DebugLog[] = [];
  if (reminder.template.type !== orderData.Description) {
    log.push({
      type: 'error',
      message: `Reminder set to send to ${reminder.template.type}, order subtype is currently ${orderData.Description}`,
    });
  }
  return log;
};

export const checkOrderReminder = ({
  currentReminder,
  orderData,
  dayOfWeekSettings,
  setLogging,
}) => {
  let log: DebugLog[] = [];

  CustomAxios.get(historyUrl(currentReminder.id))
    .then((res) => {
      const { data } = res.data;

      const { reminder, sendDate, historyIndex, lastModifiedAt } = getRelevantReminder(
        currentReminder,
        data,
        orderData,
        'EventTime'
      );
      let reminderSendDate = sendDate;

      const dayOfWeekDate = checkDayOfWeekSettings({
        reminder,
        eventData: orderData,
        reminderSendDate,
        dayOfWeekSettings,
        dateFormat: 'EventTime',
      });

      const dayOfWeekLog: DebugLog[] = getDayOfWeekLogs(dayOfWeekDate, reminderSendDate);

      if (dayOfWeekDate) {
        reminderSendDate = new Date(dayOfWeekDate);
      }

      //Get Send Logs
      const sendLogs = getSendLogs({
        reminder,
        reminderSendDate,
        historyIndex,
        lastModifiedAt,
      });

      //Check Enabled
      const enabledLog: DebugLog[] = !reminder.enabled
        ? [{ type: 'error', message: `Not enabled at reminder send date` }]
        : [];

      //Check Type
      const typeLogs = getTypeLogs(reminder, orderData);

      //Check SubType
      const subtypeLogs = getSubtypeLogs(reminder, orderData);

      //Check Advanced Options
      const advancedOptionsLogs = checkAvancedOptions({
        reminder,
        eventData: orderData,
      });

      log = [
        ...log,
        ...sendLogs,
        ...dayOfWeekLog,
        ...enabledLog,
        ...typeLogs,
        ...subtypeLogs,
        ...advancedOptionsLogs,
      ];

      const status = getStatus(log);

      setLogging((logging) => ({
        ...logging,
        [currentReminder.id]: {
          status,
          display: true,
          log,
        },
      }));
    })
    .catch((err) => console.error(err));
};
