import { handleActions } from 'redux-actions';
import { AvailablePhoneNumbersModel } from '../../../components/phones/twilio/twilio-models.interface';
import {
  addNewNumberFailureMessage,
  addNewNumberSuccessMessage,
  clearAvailablePhoneNumbers,
  getAvailablePhoneNumbers,
  getAvailablePhoneNumbersFailure,
  getAvailablePhoneNumbersSuccess,
  newNumberFailureMessages,
  newNumberSuccessMessages,
  purchasePhoneNumbers,
  purchasePhoneNumbersSuccess,
} from './twilio.action';

export type TwilioState = {
  availablePhoneNumbers: AvailablePhoneNumbersModel;
  loadingAvailableNumbers: boolean;
  purchasingNumbers: boolean;
  newNumberSuccessMessages: string[];
  newNumberFailureMessages: string[];
};

const twilioDefaultState = {
  availablePhoneNumbers: {
    available_phone_numbers: [],
    uri: '',
  },
  loadingAvailableNumbers: false,
  purchasingNumbers: false,
  newNumberSuccessMessages: [],
  newNumberFailureMessages: [],
};

const twilioReducer = handleActions<any, any>(
  {
    [getAvailablePhoneNumbersSuccess.toString()]: (state, action) =>
      Object.assign({}, state, {
        availablePhoneNumbers: action.payload,
        loadingAvailableNumbers: false,
      }),
    [getAvailablePhoneNumbers.toString()]: (state, action) =>
      Object.assign({}, state, {
        loadingAvailableNumbers: true,
        newNumberSuccessMessages: [],
        newNumberFailureMessages: [],
        availablePhoneNumbers: { available_phone_numbers: [], uri: '' },
      }),
    [clearAvailablePhoneNumbers.toString()]: (state, action) =>
      Object.assign({}, state, {
        availablePhoneNumbers: { available_phone_numbers: [], uri: '' },
      }),
    [purchasePhoneNumbers.toString()]: (state, action) =>
      Object.assign({}, state, {
        purchasingNumbers: true,
        newNumberSuccessMessages: [],
        newNumberFailureMessages: [],
      }),
    [newNumberSuccessMessages.toString()]: (state, action) =>
      Object.assign({}, state, { newNumberSuccessMessages: action.payload }),
    [newNumberFailureMessages.toString()]: (state, action) =>
      Object.assign({}, state, { newNumberFailureMessages: action.payload }),
    [addNewNumberSuccessMessage.toString()]: (state, action) =>
      Object.assign({}, state, {
        newNumberSuccessMessages: [...state.newNumberSuccessMessages, action.payload],
      }),
    [addNewNumberFailureMessage.toString()]: (state, action) =>
      Object.assign({}, state, {
        newNumberFailureMessages: [...state.newNumberFailureMessages, action.payload],
      }),
    [getAvailablePhoneNumbersFailure.toString()]: (state, action) =>
      Object.assign({}, state, { loadingAvailableNumbers: false }),
    [purchasePhoneNumbersSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { purchasingNumbers: false }),
  },
  twilioDefaultState
);

export default twilioReducer;
