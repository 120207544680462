import { all, takeEvery } from 'redux-saga/effects';
import {
  fetchOptoSettings,
  fetchSettings,
  handleFetchOptoSettings,
  handleFetchSettings,
  handleUpdateOptoSettings,
  handleUpdateSettings,
  updateOptoSettings,
  updateSettings,
} from './status-mapping-settings.action';

export const statusMappingSettingsSaga = function* () {
  yield all([
    takeEvery(fetchSettings.toString(), handleFetchSettings),
    takeEvery(fetchOptoSettings.toString(), handleFetchOptoSettings),
    takeEvery(updateSettings.toString(), handleUpdateSettings),
    takeEvery(updateOptoSettings.toString(), handleUpdateOptoSettings),
  ]);
};
