import { connect } from 'react-redux';

import { DesktopClients } from './desktop-clients.component';
import { getDesktopClients } from '../../redux/actions/desktop-clients/desktop-clients.action';
import { selectCurrentLocation } from '../../redux/actions/location';
import { getUsers } from '../../redux/actions/users';

export const DesktopClientsContainer = connect(
  (state: any) => ({
    desktopClients: state.desktopClients.data,
    users: state.users.data,
    loading: state.desktopClients.loading || state.users.loading,
    location: selectCurrentLocation(state),
  }),
  (dispatch) => ({
    getDesktopClients: (locationId: string) => {
      dispatch(getDesktopClients({ locationId }));
    },
    getUsers: (limit?: number) => {
      dispatch(getUsers({ limit }));
    },
  })
)(DesktopClients);
