import _ from 'lodash';
import { createAction } from 'redux-actions';
import { call, put, select } from 'redux-saga/effects';
import { CustomAxios, getErrorMessage } from '../../axios';
import { showError, showSuccess } from '@weave/alert-system';
import {
  NotificationsHistory,
  NotificationsSettings,
  NotificationSettingsStore,
} from './notification-settings.types';

export const notificationsSettingsTypeMap = {
  appointment: 'appointments',
  birthday: 'birthdays',
  missedcall: 'missed_calls',
  recall: 'recall',
  review: 'reviews',
  order: 'orders',
};

export const selectNotificationHistory = (settingsHistory: NotificationsHistory, id) => {
  return settingsHistory[id] || [];
};

export const selectNotificationSetting = (settings: NotificationsSettings, id) => {
  return _.flatten(Object.values(settings).map((setting) => [...setting])).find(
    (setting) => setting.id === id
  );
};

export const selectNoficationSettingCategories = (store: Store) =>
  store.notificationsSettings.categories;

export const getNotificationDeliverySummary = createAction('GET_DELIVERY_SUMMARY');
export const getNotificationDeliverySummarySuccess = createAction(
  'GET_DELIVERY_SUMMARY_SUCCESS'
);

export const getNotificationDeliverySummaryFailure = createAction(
  'GET_DELIVERY_SUMMARY_FAILURE'
);

export const getNotificationHistory = createAction('GET_NOTIFICATION_HISTORY');
export const getNotificationHistorySuccess = createAction(
  'GET_NOTIFICATION_HISTORY_SUCCESS'
);
export const getNotificationHistoryFailure = createAction(
  'GET_NOTIFICATION_HISTORY_FAILURE'
);

export const resetNotificationSettings = createAction('RESET_NOTIFICATION_SETTINGS');

export const getNotificationAdvancedOptionsList = createAction(
  'GET_NOTIFICATION_OPTIONS_LIST'
);
export const getNotificationAdvancedOptionsListSuccess = createAction(
  'GET_NOTIFICATION_OPTIONS_LIST_SUCCESS'
);
export const getNotificationAdvancedOptionsListFailure = createAction(
  'GET_NOTIFICATION_OPTIONS_LIST_FAILURE'
);

export const getNotificationsSettings = createAction('GET_NOTIFICATIONS_SETTINGS');
export const getNotificationsSettingsSuccess = createAction(
  'GET_NOTIFICATIONS_SETTINGS_SUCCESS'
);
export const getNotificationsSettingsFailure = createAction(
  'GET_NOTIFICATIONS_SETTINGS_FAILURE'
);

export const updateNotificationToggle = createAction('UPDATE_NOTIFICATIONS_TOGGLE');

export const updateNotificationSettings = createAction('UPDATE_NOTIFICATIONS_SETTINGS');
export const updateNotificationSettingsSuccess = createAction(
  'UPDATE_NOTIFICATIONS_SETTINGS_SUCCESS'
);
export const updateNotificationSettingsFailure = createAction(
  'UPDATE_NOTIFICATIONS_SETTINGS_FAILURE'
);

export const notificationsSettingsUrl = '/support/v1/notifications/settings';
export const notificationsSettingsUrlSansSupport = '/support/v1/notifications';
export const deliverySummaryUrl = '/support/v1/queue/delivery_summary_report';

export const handleGetNotificationHistory = function* (action) {
  try {
    const id = action.payload;
    const response = yield call(
      CustomAxios.get,
      `${notificationsSettingsUrl}/${id}/history`
    );
    const data = (response.data && response.data.data) || [];
    yield put(getNotificationHistorySuccess({ id, data }));
  } catch (error: any) {
    yield put(showError(getErrorMessage(error)));
    yield put(getNotificationHistoryFailure());
  }
};

interface Store {
  notificationsSettings: NotificationSettingsStore;
}
export const handleGetNotificationAdvancedOptionsList = function* (action) {
  try {
    const type = action.payload;
    const options = yield select(
      (store: Store) => store.notificationsSettings.notificationAdvancedOptions[type]
    );
    if (!options.length) {
      const response = yield call(
        CustomAxios.get,
        `${notificationsSettingsUrlSansSupport}/advanced-options/?optionType=${type}`
      );
      const advancedOptionsData = response.data.data || { [type]: [] };
      Object.keys(advancedOptionsData).forEach((key) => {
        advancedOptionsData[key] = advancedOptionsData[key].filter((option) => !!option);
      });

      yield put(getNotificationAdvancedOptionsListSuccess(advancedOptionsData));
    }
  } catch (error: any) {
    yield put(showError(getErrorMessage(error)));
    yield put(getNotificationAdvancedOptionsListFailure());
  }
};

export const handleGetNotificationsSettings = function* () {
  try {
    const response = yield call(CustomAxios.get, notificationsSettingsUrl);
    const data = (response.data && response.data.data) || [];
    yield put(getNotificationsSettingsSuccess(data.notificationSettings));
  } catch (error: any) {
    yield put(showError(getErrorMessage(error)));
    yield put(getNotificationsSettingsFailure());
  }
};

export const handleUpdateNotificationToggle = function* (action) {
  try {
    const { id, ...updateData } = action.payload;
    const settings = yield select((store: any) => store.notificationsSettings.categories);
    const selectedSetting = selectNotificationSetting(settings, id);
    const response = yield call(
      CustomAxios.patch,
      `${notificationsSettingsUrl}/${id}`,
      updateData
    );

    const updatedSetting = { ...selectedSetting, ...response.data.data };
    yield put(updateNotificationSettingsSuccess(updatedSetting));
    yield put(showSuccess(`Updated auto reminder ${selectedSetting.name}`));
    yield put(getNotificationHistory(id));
    yield put(getNotificationsSettings());
  } catch (error: any) {
    yield put(showError(getErrorMessage(error)));
    yield put(updateNotificationSettingsFailure());
  }
};

export const handleUpdateNotificationSettings = function* (action) {
  try {
    const { id, ...updateData } = action.payload;
    const settings = yield select((store: any) => store.notificationsSettings.categories);
    const selectedSetting = selectNotificationSetting(settings, id);
    const response = yield call(CustomAxios.put, `${notificationsSettingsUrl}/${id}`, {
      ...selectedSetting,
      ...updateData,
    });

    const updatedSetting = { ...selectedSetting, ...response.data.data };
    yield put(updateNotificationSettingsSuccess(updatedSetting));
    yield put(showSuccess(`Updated auto reminder ${selectedSetting.name}`));
    yield put(getNotificationHistory(id));
    yield put(getNotificationsSettings());
  } catch (error: any) {
    yield put(showError(getErrorMessage(error)));
    yield put(updateNotificationSettingsFailure());
  }
};

export const handleGetDeliverySummary = function* (action) {
  try {
    const id = action.payload;
    const url = `${deliverySummaryUrl}?today=${new Date().toISOString()}&notification_setting_id=${
      action.payload
    }`;

    const response = yield call(CustomAxios.get, url);
    const data = response.data.data;
    yield put(getNotificationDeliverySummarySuccess({ id, data }));
  } catch (error: any) {
    yield put(showError(getErrorMessage(error)));
    yield put(getNotificationDeliverySummaryFailure());
  }
};
