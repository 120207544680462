import React from 'react';
import { weaveTheme } from '@weave/theme-original/dist/weaveTheme';

type DotSvgProps = {
  color: 'error' | 'success';
};

export const DotSvg = ({ color }: DotSvgProps) => {
  return (
    <svg height="20" width="20">
      <circle
        cx="10"
        cy="10"
        r="5"
        fill={color === 'success' ? weaveTheme.colors.success : weaveTheme.colors.error}
      />
    </svg>
  );
};
