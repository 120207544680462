import React from 'react';
import { css } from '@emotion/core';
import { WeaveTheme } from '@weave/theme-original';
import { Text } from '@weave/design-system';
import { SiteName } from './authentication-status-detail.component';

export type SiteErrorsProps = {
  errors: string[];
  site: SiteName;
};

export const SiteErrors = ({ errors, site }: SiteErrorsProps) => {
  if (errors.length === 0) {
    return null;
  }

  return (
    <div
      css={(theme: WeaveTheme) => css`
        margin: ${theme.spacing(1, 0, 0, 0)};
        padding: ${theme.spacing(1, 0, 0, 2)};
        border-top: solid 1px ${theme.colors.gray[300]};
      `}
    >
      <Text
        size="small"
        css={(theme: WeaveTheme) =>
          css`
            color: ${theme.colors.error};
            margin: 0;
          `
        }
      >
        This {site === SiteName.Google ? 'Google Location' : 'Facebook Page'} needs some
        love:
      </Text>
      <ul
        css={(theme: WeaveTheme) => css`
          margin: 0;
          padding: ${theme.spacing(0, 0, 0, 4)};
        `}
      >
        {errors.map((error, index) => (
          <Text
            as="li"
            size="small"
            color="error"
            css={css`
              margin: 0;
            `}
            key={index}
          >
            {error}
          </Text>
        ))}
      </ul>
    </div>
  );
};
