import { css } from 'emotion';
import { weaveBlue } from '../../../shared/styles/colors';

export const cardComponent = css`
  label: apptComponent;
  width: 400px;
  height: 400px;
  overflow-y: auto;
  margin: 5px;
  margin-bottom: 26px;
  background-color: #fff;
  color: #222;
  border: 1px solid #d4dbdf;
  border-radius: 5px;
  border-bottom: none;
`;

export const listBox = css`
  label: listBox;
  color: black;
  background-color: white;
  border-radius: 2px;
  display: block;
  width: 100%;
  height: 90%;
  overflow-y: auto;
`;

export const shortList = css`
  display: flex;
  justify-content: space-between;
  p {
    padding: 0px;
    margin: 0px;
  }
  span {
    padding: 0px;
    margin: 0px;
    margin-left: 16px;
  }
`;

export const listHeader = css`
  label: listHeader;
  font-weight: bold;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0px;
`;

export const listStyle = css`
  label: listStyle;
  text-transform: capitalize;
  border: 1px solid #d4dbdf;
  border-radius: 3px;
  background-color: #fafcfc;
  margin: 5px;
  padding: 10px;
  p {
    padding: 0px;
    margin: 0px;
  }
`;

export const flexDisplay = css`
  label: flexDisplay;
  display: flex;
  span {
    margin-right: 8px;
  }
`;

export const leftHeaderStyle = css`
  label: leftHeaderStyle;
  width: 80%;
`;

export const leftListStyle = css`
  label: leftListStyle;
  width: 40%;
`;

export const rightListStyle = css`
  label: rightListStyle;
  width: 60%;
  text-align: right;
`;

export const rightListHR = css`
  label: rightListHR;
  border-bottom: 1px solid #d4dbdf;
`;

export const error = css`
  label: error;
  color: #dc3545;
`;

export const listMeta = css`
  label: listMeta;
  width: 100%;
  text-align: center;
`;

export const noData = css`
  label: noData;
  color: #ddd;
  padding: 5%;
`;

export const uuid = css`
  text-transform: none;
`;

export const actionBtn = css`
  label: copyToClipboard;
  cursor: pointer;
  text-transform: none;
  color: ${weaveBlue};
  outline: none;
  margin: auto 8px;
`;

export const linkBtn = css`
  label: detailsLink;
  width: 25%;
  border: 1px solid ${weaveBlue};
  border-radius: 25px;
  cursor: pointer;
  color: white;
  background-color: ${weaveBlue};
  text-align: center;
`;

export const copied = css`
  margin-left: 5px;
  label: copied;
  color: #28a745;
`;

export const contactInfoBox = css`
  label: contactInfoBox;
  border: 1px solid #d4dbdf;
  min-width: 300px;
  text-transform: none;
  height: 220px;
`;

export const container = css`
  display: flex;
  & .left {
    width: 40%;
    padding-right: 16px;
    text-align: right;
    font-weight: bold;
  }
`;
