import React, { useState } from 'react';
import { WeaveTheme } from '@weave/theme-original';
import { Modal, Text, useModalControl } from '@weave/design-system';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';
import { Page } from '../../shared';
import { selectLocationChildren } from '../../../redux/actions/location/location-search';
import { CreateCloudSyncApp } from './create-cloud-sync-app.component';
import { MigrateSyncApp } from './migrate-sync-apps';

export type AlertProps = {
  title: string;
  description: string;
};

export const SyncAppSettingsPage = () => {
  const [alertProps, setAlertProps] = useState<AlertProps>({
    title: '',
    description: '',
  });
  const childrenLocations = useSelector(selectLocationChildren);
  const alertModalControls = useModalControl();

  const showAlertModal = (obj: AlertProps) => {
    setAlertProps(obj);
    alertModalControls.openModal();
  };

  return (
    <Page title="Sync App Settings">
      <CreateCloudSyncApp showAlertModal={showAlertModal} />
      {childrenLocations.length === 0 && <MigrateSyncApp />}
      <Modal {...alertModalControls.modalProps}>
        <Modal.Header>{alertProps.title}</Modal.Header>
        <Modal.Body>
          <Text
            textAlign="center"
            css={(theme: WeaveTheme) => css`
              margin: ${theme.spacing(0, 0, 1)};
            `}
          >
            {alertProps.description}
          </Text>
        </Modal.Body>
        <Modal.Actions
          onPrimaryClick={alertModalControls.closeModal}
          primaryLabel="Close"
        />
      </Modal>
    </Page>
  );
};
