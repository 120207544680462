import React, { useState } from 'react';
import 'react-widgets/dist/css/react-widgets.css';
import { PrimaryButton } from '@weave/design-system';
import { css } from '@emotion/core';

import { ConfirmationModal } from '../../../shared/confirmation-modal/confirmation-modal.component';

export const AutoMsgQueueControl = ({
  loading,
  canControlAutoMsgQueue,
  clearAutoMsgQueue,
  reloadAutoMsgQueue,
}) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const clearQueue = (event) => {
    event.preventDefault();

    clearAutoMsgQueue();
  };

  const reloadQueue = () => {
    reloadAutoMsgQueue();
    setConfirmationModalOpen(false);
  };

  if (canControlAutoMsgQueue !== true) {
    return null;
  } else {
    return (
      <>
        <PrimaryButton
          size="small"
          css={css`
            margin-left: 16px;
            width: auto;
          `}
          disabled={loading}
          onClick={clearQueue}
        >
          Clear Queue
        </PrimaryButton>
        <PrimaryButton
          size="small"
          css={css`
            margin-left: 16px;
            width: auto;
          `}
          disabled={loading}
          onClick={() => setConfirmationModalOpen(true)}
        >
          Reload Queue
        </PrimaryButton>
        <ConfirmationModal
          title="Are you sure you want to reload the queue?"
          note="This will reload the existing queue of auto texts."
          isOpen={confirmationModalOpen}
          onRequestClose={() => setConfirmationModalOpen(false)}
          onConfirmClick={reloadQueue}
        />
      </>
    );
  }
};
