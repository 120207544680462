import React, { useState, useEffect } from 'react';
import includes from 'lodash/includes';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import { SecondaryButton, PrimaryButton, ButtonBar, Modal } from '@weave/design-system';
import UserListModel from './UserListModel';
import { UserModel } from '../../models/user.model';
import { UserEditForm } from './user-edit-form.component';
import { Page } from '../shared/page/page.component';
import { PHONES, injectParams } from '../../constants/paths';
import { RouteComponentProps } from 'react-router-dom';
import { UserActionButtons } from './user-action-buttons';
import { WeaveUsersEditForm } from './weave-users-edit-form.component';
import { JobTitlesModel, RolesModel } from '../../redux/actions/users/user-roles.action';
import {
  ActionButtons,
  EditUserModal,
  WeaveEditUserModal,
  container,
} from './user-list.styles';
import {
  highlightInvitedUserRow,
  removeDenyMobileAccessRole,
  rolesDisplay,
} from './users.helpers';
import 'react-table/react-table.css';

type Props = {
  loading: boolean;
  locationId: string;
  userFound: boolean;
  userSearchText: string;
  selectedUser: UserModel;
  users: UserListModel[];
  availableRoles: RolesModel[];
  availableJobTitles: JobTitlesModel[];
  getUsers: (params) => void;
  getAvailableRoles: () => void;
  deleteUser: (UserModel) => void;
  getAvailableJobTitles: () => void;
  setUserSearchText: (payload: string) => void;
  userSelect: (payload: { state?: UserModel; isExistingUser?: false }) => void;
} & RouteComponentProps;

interface State {
  modalOpen: boolean;
  addUserModalOpen: boolean;
  roles: string;
  pageTitle: string;
  searchedEmail: string;
}

const UsersList = (props: Props, state: State) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [pageTitle, setPageTitle] = useState('');
  const [searchedEmail, setSearchedEmail] = useState('');

  const loadData = () => {
    const userLimit = 25;
    props.getUsers(userLimit);
    props.getAvailableRoles();
    props.getAvailableJobTitles();
  };

  useEffect(() => {
    loadData();
    setSearchedEmail(includes(props.userSearchText, '@') ? props.userSearchText : '');
    return () => {
      setSearchedEmail('');
      setPageTitle('');
    };
  }, [props.locationId, props.userSearchText]);

  const handleEditOpen = (user) => {
    setPageTitle('Edit User');
    props.userSelect({ state: user, isExistingUser: false });
    setModalOpen(true);
  };

  const handleEditClose = () => {
    props.setUserSearchText('');
    props.userSelect({});
    setModalOpen(false);
  };

  return (
    <Page
      title="Users"
      headerActions={
        <div css={ActionButtons} className="actions">
          <ButtonBar removeSpacing>
            <SecondaryButton onClick={loadData}>Refresh Data</SecondaryButton>
            <PrimaryButton
              onClick={() => {
                setModalOpen(true);
                setPageTitle('Add New User');
                props.userSelect({});
              }}
            >
              Add User
            </PrimaryButton>
          </ButtonBar>
        </div>
      }
    >
      <Modal
        show={modalOpen}
        css={props.selectedUser.Type === 'weave' ? WeaveEditUserModal : EditUserModal}
        onClose={() => setModalOpen(false)}
      >
        <Modal.Header>{pageTitle}</Modal.Header>
        <Modal.Body>
          {props.selectedUser.Type === 'weave' ? (
            <WeaveUsersEditForm
              selectedUser={props.selectedUser}
              handleEditClose={handleEditClose}
            />
          ) : (
            <UserEditForm
              searchedEmail={searchedEmail}
              selectedUser={props.selectedUser}
              handleEditClose={handleEditClose}
            />
          )}
        </Modal.Body>
      </Modal>
      <div css={container}>
        <ReactTable
          columns={[
            {
              Header: 'First Name',
              accessor: 'FirstName',
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ['FirstName'] }),
              filterAll: true,
            },
            {
              Header: 'Last Name',
              accessor: 'LastName',
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ['LastName'] }),
              filterAll: true,
            },
            {
              Header: 'Status',
              accessor: 'Status',
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ['Status'] }),
              filterAll: true,
            },
            {
              Header: 'Type',
              accessor: 'Type',
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ['Type'] }),
              filterAll: true,
            },
            {
              Header: 'Role',
              id: 'Role',
              accessor: (d) => {
                return rolesDisplay(removeDenyMobileAccessRole(d?.Roles));
              },
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ['Role'] }),
              filterAll: true,
            },
            {
              Header: 'Email',
              accessor: 'Username',
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ['Username'] }),
              filterAll: true,
              minWidth: 215,
            },
            {
              Header: '',
              id: 'hasMobileAccess',
              filterable: false,
              accessor: (user) => {
                const url = injectParams(PHONES.userMobileSupport, {
                  id: props.locationId,
                  userId: user.UserID,
                });
                return (
                  <SecondaryButton
                    size="tiny"
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push(url);
                    }}
                  >
                    Mobile
                  </SecondaryButton>
                );
              },
            },
            {
              Header: '',
              id: 'actionButtons',
              filterable: false,
              accessor: (user) => {
                return (
                  <UserActionButtons
                    user={user}
                    handleEditOpen={handleEditOpen}
                    deleteUser={props.deleteUser}
                  />
                );
              },
            },
          ]}
          filterable
          data={props.users ? props.users : []}
          loading={props.loading}
          pageSize={Math.min(props.users.length || 5, 25)}
          getTrProps={highlightInvitedUserRow}
        />
      </div>
    </Page>
  );
};

export default UsersList;
