import { connect } from 'react-redux';
import moment from 'moment';

import { OfficeHoursCardEdit } from '../../office-hours-card-edit/office-hours-card-edit.component';
import { updateOfficeHours } from '../../../../../../../redux/actions/office-hours/office-hours.action';
import { selectCurrentLocation } from '../../../../../../../redux/actions/location/current-location';

export const OfficeHoursCardEditContainer = connect(
  (store: any, props: any) => {
    const officeHours = store.officeHours.data;
    const dayOfWeek = props.dayOfWeek.toLowerCase();
    const officeHour = {};
    let val;
    let hour;
    let minute;
    let second;
    let momentTime;

    Object.keys(officeHours).forEach((key) => {
      if (key.toLowerCase().startsWith(dayOfWeek)) {
        val = officeHours[key];
        [hour, minute, second] = val.split(':');
        momentTime = moment()
          .hour(parseInt(hour, 10))
          .minute(parseInt(minute, 10))
          .second(parseInt(second, 10));

        officeHour[key.replace(dayOfWeek, '')] = momentTime;
      }
    });

    return {
      officeHour,
      loading: store.officeHours.loading,
      location: selectCurrentLocation(store),
    };
  },
  {
    updateOfficeHours,
  }
)(OfficeHoursCardEdit);
