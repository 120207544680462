import React, { useContext } from 'react';
import { isAfter, isBefore, isEqual } from 'date-fns';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import {
  AlertInvertIconSmall,
  NakedButton,
  TimeField,
  useControlledField,
} from '@weave/design-system';
import { components } from '@weave/shared-proto-gateway/dist/ts/schedule-api';

import { OfficeHoursContext } from './office-hours.provider';
import {
  centerH,
  errorMessageStart,
  formatDateFromTime,
  warningMessageStart,
} from '../schedule-display-utils';

interface OfficeHoursStartFieldProps {
  startTime: string;
  endTime: string;
  prevEndTime: string | null;
  dayLabel: string;
  index: number;
  prevState: components['schemas']['scheduleReservedTime'];
}

export const OfficeHoursStartField = ({
  startTime,
  endTime,
  prevEndTime,
  dayLabel,
  index,
  prevState,
}: OfficeHoursStartFieldProps) => {
  const context = useContext(OfficeHoursContext);
  const { state, dispatch } = context;

  const dispatchStartError = (message: string, error: boolean) => {
    dispatch({
      type: 'UPDATE_ERRORS',
      payload: {
        dayLabel,
        index,
        timeType: 'start',
        message,
        messageType: error ? 'error' : 'warning',
        prevState,
      },
    });
  };

  const startTimeProps = useControlledField({
    type: 'time',
    value: startTime,
    onChange: (value) => {
      const inValue = value;

      if (
        value &&
        (prevState?.TimeOfDay?.Hours !== parseInt([...value].slice(0, 2).join('') ?? 0) ||
          prevState?.TimeOfDay?.Minutes !==
            parseInt([...value].slice(3, 5).join('') ?? 0))
      ) {
        switch (true) {
          case isAfter(formatDateFromTime(value), formatDateFromTime(endTime)) &&
            endTime !== '00:00:00':
            dispatchStartError(
              `${errorMessageStart('start', index)}Start time cannot be after end time`,
              true
            );
            break;

          case isEqual(formatDateFromTime(value), formatDateFromTime(endTime)):
            dispatchStartError(
              `${errorMessageStart('start', index)}Start time cannot match end time`,
              true
            );
            break;

          case prevEndTime &&
            isBefore(formatDateFromTime(value), formatDateFromTime(prevEndTime)):
            dispatchStartError(
              `${errorMessageStart(
                'start',
                index
              )}Start time conflicts with previous time block`,
              true
            );
            break;

          case prevEndTime &&
            isEqual(formatDateFromTime(value), formatDateFromTime(prevEndTime)):
            dispatchStartError(
              `${warningMessageStart(
                'start',
                index
              )}No gap between time blocks. Consider merging with previous timeblock`,
              false
            );
            dispatch({
              type: 'EDIT_START_TIMEBLOCK',
              payload: {
                value: inValue,
                endTime,
                dayLabel,
                index,
                canClearErrors: false,
              },
            });
            break;

          default: {
            dispatch({
              type: 'EDIT_START_TIMEBLOCK',
              payload: {
                value: inValue,
                endTime,
                dayLabel,
                index,
                canClearErrors: true,
              },
            });
            break;
          }
        }
      }
    },
  });

  return (
    <div
      css={[
        centerH,
        css`
          justify-content: center;
        `,
      ]}
    >
      <TimeField {...startTimeProps} name="startTime" label="Start" />
      {state.errors[dayLabel][index]['start'].message.length > 0 && (
        <NakedButton
          onClick={() => {
            dispatch({
              type: 'TOGGLE_ERROR_ALERT',
              payload: {
                dayLabel,
                index,
                timeType: 'start',
              },
            });
          }}
          css={css`
            marginleft: ${theme.spacing(1)};
          `}
        >
          <AlertInvertIconSmall
            color={
              state.errors[dayLabel][index]?.['start'].messageType === 'warning'
                ? 'warn'
                : 'error'
            }
          />
        </NakedButton>
      )}
    </div>
  );
};
