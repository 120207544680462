import { connect } from 'react-redux';

import { MediaLibrary } from './media-library.component';
import { Store } from './../../../redux/store/store.model';
import {
  deletePhoneMedia,
  updatePhoneMedia,
  uploadPhoneMedia,
} from '../../../redux/actions/phone-media';
import { phoneMediaSelector } from './../../../redux/actions/phone-media';

export const MediaLibraryContainer = connect(
  (state: Store) => phoneMediaSelector(state),
  {
    deletePhoneMedia,
    updatePhoneMedia,
    uploadPhoneMedia,
  }
)(MediaLibrary);
