import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/core';
import { Button } from '@weave/platform-ui-components';
import { useAlert } from '@weave/alert-system';
import { WeaveTheme } from '@weave/theme-original';
import {
  Text,
  InfoRoundIconSmall,
  CaretRightIconSmall,
  ErrorIconSmall,
  NakedButton,
} from '@weave/design-system';

import { checkAppointmentReminder } from '../reminder-checker';
import { UuidCopier } from '../../../history-list-container/patient-details-container/uuid-copier';
import { checkOrderReminder } from '../order-debugger/order-reminders-debug';
import { ResourceHooks } from '../../../shared/hooks/resource-hooks';
import { sortByKey } from '../../auto-reminders/auto-reminders-helpers/auto-reminder-helpers';

const capitalize = css`
  text-transform: capitalize;
`;

const logContainer = css`
  margin-left: 16px;
  div {
    display: flex;
  }
  p,
  span {
    margin: 0;
    padding: 0;
  }
  span {
    margin-right: 16px;
    font-weight: bold;
  }
  svg {
    margin: 4px;
  }
`;

const reminderSettingContainer = css`
  position: relative;
  box-shadow: 4px 4px 8px #eee;
  border: 1px solid #eee;
  margin: 16px;
  padding: 16px;
`;

const titleUuid = css`
  display: flex;
  p {
    padding-right: 8px;
    padding-bottom: 0;
  }
`;

const reminderContainer = css`
  display: flex;
  & .btnDetail {
    display: flex;
    margin-left: auto;
    p {
      padding-right: 8px;
    }
  }
`;

const showDetailStyle = (theme: WeaveTheme) => css`
  color: ${theme.colors.weaveBlue};
  font-size: 14px;
  outline: none;
`;
interface Store {
  dayOfWeek: {
    settings: {
      Sunday: boolean;
      Monday: boolean;
      Tuesday: boolean;
      Wednesday: boolean;
      Thursday: boolean;
      Friday: boolean;
      Saturday: boolean;
    };
  };
}

const useWritebacks = ResourceHooks[`/support/v1/writebacks`];

const defaultDayOfWeek = {
  Friday: true,
  Monday: true,
  Saturday: true,
  Sunday: true,
  Thursday: true,
  Tuesday: true,
  Wednesday: true,
};

export const AutoRemindersDebug = ({ query, reminderSetting, data }) => {
  const type = query.type ?? '';
  const alert = useAlert();
  const [logging, setLogging] = useState({});
  const dayOfWeekSettings =
    useSelector((store: Store) => store.dayOfWeek.settings) ?? defaultDayOfWeek;
  const reminders = useMemo(() => {
    return sortByKey(reminderSetting, 'name', 'id') || [];
  }, [reminderSetting.length]);

  const writebacks = useWritebacks({ appointment_id: query.id });

  const handleReminderCheck = (currentReminder) => {
    const dataCheck = Object.values(data).reduce((item) => {
      if (item === 0) return '';
      return item;
    });
    if (!dataCheck) {
      alert.error(`This ${type} has no data to reconcile.`);
    } else {
      if (type === 'appointment') {
        checkAppointmentReminder({
          currentReminder,
          appointmentData: data,
          dayOfWeekSettings,
          writebacks,
          setLogging,
        });
      } else if (type === 'order') {
        checkOrderReminder({
          currentReminder,
          orderData: data,
          dayOfWeekSettings,
          setLogging,
        });
      }
    }
  };

  const handleLogginToggle = (itemId) => {
    setLogging((logging) => ({
      ...logging,
      [itemId]: {
        ...logging[itemId],
        display: !logging[itemId].display,
      },
    }));
  };

  return (
    <>
      <Text css={capitalize} weight="bold">
        {type} Reminders
      </Text>
      <Text>Reconcile a reminder to this {type}</Text>
      {reminders &&
        reminders.length &&
        reminders.map((item) => {
          const hasLogging = item.id in logging;
          const log = hasLogging ? logging[item.id] : {};
          return (
            <div key={item.id} css={reminderSettingContainer}>
              <div css={reminderContainer}>
                <div css={titleUuid}>
                  <Text>{item.name}</Text>
                  <UuidCopier uuid={item.id} />
                </div>
                <div className="btnDetail">
                  <Text>{log?.status}</Text>
                  <Button
                    size="small"
                    color="blue"
                    fill="outline"
                    onClick={() => handleReminderCheck(item)}
                  >
                    Run Check
                  </Button>
                </div>
              </div>
              {log?.display &&
                log?.log &&
                log?.log.map((log, index) => {
                  const labelInLog = 'label' in log;
                  const icon =
                    log.type === 'info' ? (
                      <InfoRoundIconSmall />
                    ) : log.type === 'suggestion' ? (
                      <CaretRightIconSmall />
                    ) : log.type === 'error' ? (
                      <ErrorIconSmall />
                    ) : (
                      ''
                    );
                  return (
                    <div css={logContainer} key={item.id + log.type + index}>
                      <div>
                        {labelInLog && <Text as="span">{log.label}</Text>}
                        <Text className={log.type}>
                          {!labelInLog && icon}
                          {log.message}
                        </Text>
                      </div>
                    </div>
                  );
                })}
              {!!log?.status && (
                <NakedButton
                  css={showDetailStyle}
                  onClick={() => handleLogginToggle(item.id)}
                >
                  {log?.display ? 'Show Less' : 'Show Details'}
                </NakedButton>
              )}
            </div>
          );
        })}
    </>
  );
};
