import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { InlineLoader } from './inline-loader.component';
import { WeaveTheme } from '@weave/theme-original';
import {
  useControlledField,
  Text,
  TextField,
  IconButton,
  CheckIcon,
  XIcon,
  EditSimpleIcon,
} from '@weave/design-system';

interface Props {
  editing: boolean;
  label: string;
  isUpdating: boolean;
  onCancelClick: () => void;
  onEditClick: () => void;
  onSaveClick: (value: string) => void;
  defaultValue: string;
  type?: 'text' | 'number';
}

export const SettingsTextField = ({
  label,
  defaultValue,
  isUpdating,
  onCancelClick,
  onEditClick,
  editing,
  onSaveClick,
  type = 'text',
}: Props) => {
  const [value, setValue] = useState(defaultValue);
  useEffect(() => {
    if (defaultValue !== value) {
      setValue(defaultValue);
    }
  }, [defaultValue]);
  const field = useControlledField({
    type: 'text',
    value,
    onChange: setValue,
    validator: () => {
      if (type === 'number') {
        return /^[0-9]*$/.test(value) ? '' : 'Must be only digits';
      }
      return '';
    },
  });
  return (
    <div
      css={(theme: WeaveTheme) => css`
        display: flex;
        align-items: center;
        margin-top: ${theme.spacing(2)};
      `}
    >
      <Text
        weight="bold"
        css={(theme: WeaveTheme) => css`
          margin-right: ${theme.spacing(1)};
        `}
      >
        {label}
      </Text>
      {editing && (
        <TextField
          {...field}
          label={label}
          name={label}
          css={(theme: WeaveTheme) =>
            css`
              margin-right: ${theme.spacing(1)};
            `
          }
        />
      )}
      {!editing && <Text>{value}</Text>}
      {editing && (
        <>
          <IconButton
            onClick={() => onSaveClick(value)}
            label={'Save'}
            css={(theme: WeaveTheme) => css`
              color: ${theme.colors.weaveBlue};
            `}
          >
            <CheckIcon></CheckIcon>
          </IconButton>
          <IconButton
            onClick={() => {
              setValue(defaultValue);
              onCancelClick();
            }}
            label="Cancel"
          >
            <XIcon />
          </IconButton>
        </>
      )}
      {!editing && (
        <IconButton
          onClick={onEditClick}
          label="Edit"
          css={(theme: WeaveTheme) => css`
            margin-left: ${theme.spacing(1)};
            margin-top: -${theme.spacing(2)};
            color: ${theme.colors.weaveBlue};
          `}
        >
          <EditSimpleIcon></EditSimpleIcon>
        </IconButton>
      )}
      {isUpdating && <InlineLoader />}
    </div>
  );
};
