import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { css } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useRequestState } from '@weave/alert-system';
import { TableStyles } from '../../../styles/table-style';
import 'react-table/react-table.css';

import { reactTableStyle } from '../../shared/server-data-table/react-table.style.js';
import { useDeepEffect } from '../../shared/hooks';
import { Page } from '../../shared';
import { dateFormat } from '../../../helpers/utils';
import {
  getLocationSettings,
  selectLocationSettings,
  LocationSettingsActionTypes,
} from '../../../redux/actions/location/location-settings/location-settings.action';
import { selectCurrentLocationId } from '../../../redux/actions/location/current-location';
import { PrimaryButton, TextField, useControlledField } from '@weave/design-system';

interface LocationSettingsModel {
  set: string;
  key: string;
  value: string;
}

const formatDates = (settings) =>
  settings.map((setting) => ({
    ...setting,
    created_at: moment(setting.created_at).format(dateFormat),
    modified_at: moment(setting.modified_at).format(dateFormat),
  }));

const columns = [
  {
    Header: 'Set',
    accessor: 'set',
  },
  {
    Header: 'Key',
    accessor: 'key',
  },
  {
    Header: 'Value',
    accessor: 'value',
    style: { whiteSpace: 'unset', wordWrap: 'break-word', display: 'inline' },
  },
  {
    Header: 'Modified at',
    accessor: 'modified_at',
  },
  {
    Header: 'Created at',
    accessor: 'created_at',
  },
  {
    Header: 'Source Id',
    accessor: 'source_id',
    style: { whiteSpace: 'unset', wordWrap: 'break-word' },
  },
];

export const LocationSettings = () => {
  const searchFieldProps = useControlledField({
    type: 'text',
    value: '',
    onChange: (value) => handleSearchChange(value),
  });
  const locationSettings = formatDates(useSelector(selectLocationSettings));
  const { loading } = useRequestState(LocationSettingsActionTypes.Fetch);
  const locationId = useSelector(selectCurrentLocationId);
  const [searchText, setSearchText] = useState<string>('');
  const [filteredSettings, setFilteredSettings] = useState<LocationSettingsModel[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (locationId) {
      dispatch(getLocationSettings(locationId));
    }
  }, [locationId, getLocationSettings]);

  useDeepEffect(() => {
    if (locationSettings) {
      setFilteredSettings(filterSettings(searchText, locationSettings));
    }
  }, [locationSettings]);

  const filterSettings = (
    searchText: string,
    locationSettingsList: LocationSettingsModel[]
  ) => {
    if (searchText) {
      searchText = searchText.toLowerCase();
      return locationSettingsList.filter((setting) => {
        return (
          setting.set.toLowerCase().includes(searchText) ||
          setting.key.toLowerCase().includes(searchText) ||
          setting.value.toLowerCase().includes(searchText)
        );
      });
    } else {
      return locationSettingsList;
    }
  };

  const handleSearchChange = (value) => {
    const filteredSettings = filterSettings(value, locationSettings);
    setSearchText(value);
    setFilteredSettings(filteredSettings);
  };

  const refresh = () => {
    dispatch(getLocationSettings(locationId));
  };

  return (
    <Page
      title="Locations"
      headerActions={
        <PrimaryButton
          size="small"
          css={css`
            width: 150px;
          `}
          onClick={refresh}
        >
          Refresh Data
        </PrimaryButton>
      }
    >
      <TextField
        name="search"
        label="Search in Set, Key and Value"
        {...searchFieldProps}
        clearable={true}
        value={searchText}
        css={css`
          margin: 24px 0;
          max-width: 300px;
        `}
      />
      <ReactTable
        columns={columns}
        data={filteredSettings}
        loading={loading}
        defaultPageSize={20}
        css={[css(reactTableStyle), TableStyles]}
      />
    </Page>
  );
};
