import { PhoneNumber } from './phone-numbers.types';
import faker from 'faker';

export const mockPhoneNumber = (data: Partial<PhoneNumber> = {}) => ({
  id: '1a',
  name: 'Engineering',
  number: '8011234567',
  ringtone: 'default',
  provider: 'Telnex',
  ...data,
});

export const mockPhoneNumbers = () => [
  mockPhoneNumber(),
  mockPhoneNumber({ id: '2a', name: 'Finance', number: faker.phone.phoneNumber() }),
  mockPhoneNumber({ id: '3a', name: 'Back Office', number: faker.phone.phoneNumber() }),
  mockPhoneNumber({ id: '4a', name: 'Front Office', number: faker.phone.phoneNumber() }),
  mockPhoneNumber({ id: '5a', name: 'On Call', number: faker.phone.phoneNumber() }),
  mockPhoneNumber({ id: '6a', name: 'Sales', number: faker.phone.phoneNumber() }),
];
