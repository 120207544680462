import _ from 'lodash';
import { connect } from 'react-redux';

import { Keystrokes } from './keystrokes/keystrokes.component';
import {
  disableTracer,
  enableTracer,
} from '../../redux/actions/internal-tools/internal-tools.action';

interface State {
  internalTools: {
    tracerEnabled: boolean;
    tracerId: string;
  };
}

export const KeystrokesContainer = connect(
  (state: State) => {
    return {
      tracerEnabled: _.get(state, 'internalTools.tracerEnabled', false),
      tracerId: _.get(state, 'internalTools.tracerId', ''),
    };
  },
  (dispatch) => ({
    enableTracer() {
      dispatch(enableTracer());
    },
    disableTracer() {
      dispatch(disableTracer());
    },
  })
)(Keystrokes);
