import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';

export const TableStyles = css`
  &.ReactTable {
    border: 0;
    color: ${theme.font.colors.default};
    font-size: ${theme.font.size.medium};
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.regular};
    text-align: left;

    .rt-table {
      .rt-thead {
        &.-header {
          box-shadow: none;
          border: 0;
        }
        &.-filters {
          border: 0;
        }
        .rt-tr {
          .rt-th {
            border-right: 0;
            padding: 16px 0;
            border-bottom: solid 1px ${theme.colors.gray300};
            &:focus {
              outline: 0;
            }
            &.-sort-desc,
            &.-sort-asc {
              box-shadow: none;
              &:before {
                padding: ${theme.spacing(0.25, 1, 0, 0)};
                text-align: right;
                float: left;
              }
            }
            &.-sort-desc {
              &:before {
                content: '▼';
              }
            }
            &.-sort-asc {
              &:before {
                content: '▲';
              }
            }
            div {
              font-size: ${theme.font.size.medium};
              color: ${theme.font.colors.light};
              text-align: left;

              &:focus {
                outline: 0;
              }
            }
          }
        }
      }
      .rt-tbody {
        .rt-tr-group {
          border-bottom: solid 1px ${theme.colors.gray[300]};
          &:last-child {
            border-bottom: solid 1px ${theme.colors.gray[300]};
          }
          .rt-tr {
            .rt-td {
              font-size: ${theme.font.size.large};
              color: ${theme.font.colors.default};
              align-items: center;
              border-right: 0;
              display: flex;
              padding: 16px 0;
            }
          }
        }
      }
    }
    .pagination-bottom {
      .-pagination {
        border-top: 0;
        box-shadow: none;
        display: inline-block;
        text-align: right;
        width: 100%;
        padding: 16px 0;
        .-previous {
          display: inline-block;
          .-btn {
            background: transparent;
            display: inline-block;
            width: auto;
            &:not([disabled]):hover {
              color: ${theme.font.colors.default};
            }
          }
        }
        .-center {
          display: inline-block;
          .-pageInfo {
            .-pageJump {
              input[type='number'] {
                -moz-appearance: textfield;
                width: 35px;
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }
              }
            }
          }
          .-pageSizeOptions {
            display: none;
          }
        }
        .-next {
          display: inline-block;
          .-btn {
            background: transparent;
            display: inline-block;
            width: auto;
            &:not([disabled]):hover {
              color: ${theme.font.colors.default};
            }
          }
        }
      }
    }
  }
`;
