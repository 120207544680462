import { connect } from 'react-redux';

import { PatientEmails } from './patient-emails/patient-emails.component';
import {
  emailHistory,
  emailHistoryReset,
} from '../../../../../redux/actions/emails/email-history.action';
import {
  selectCurrentLocationId,
  selectCurrentLocationTimeZone,
} from '../../../../../redux/actions/location/current-location';
import { Store } from '../../../../../redux/store/store.model';

interface OwnProps {
  personID: string;
}

export const PatientEmailsContainer = connect(
  (store: Store, ownProps: OwnProps) => ({
    currentLocation: selectCurrentLocationId(store),
    emailHistory: store.emailHistory.emails,
    personID: ownProps.personID,
    timezone: selectCurrentLocationTimeZone(store),
  }),
  {
    getEmailHistory: emailHistory,
    emailHistoryReset,
  }
)(PatientEmails);
