import { handleActions } from 'redux-actions';
import { setLoading } from './user-roles.action';
import {
  setUserFound,
  setUserSearchText,
  userSearch,
  userSearchSuccess,
} from './user-search.action';

const initialState = {
  data: [],
  loading: false,
  userFound: false,
  userSearchText: '',
};

const userSearchReducer = handleActions(
  {
    [userSearch.toString()]: (state, action) =>
      Object.assign({}, state, { loading: true }),
    [userSearchSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { data: action.payload }),
    [setLoading.toString()]: (state, action) =>
      Object.assign({}, state, { loading: action.payload }),
    [setUserFound.toString()]: (state, action) =>
      Object.assign({}, state, { userFound: action.payload }),
    [setUserSearchText.toString()]: (state, action) =>
      Object.assign({}, state, { userSearchText: action.payload }),
  },
  initialState
);

export default userSearchReducer;
