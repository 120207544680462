import React, { useState, useEffect } from 'react';
import { css } from '@emotion/core';
import { WeaveTheme } from '@weave/theme-original';
import { Card } from '../../../settings/billing/card';
import { ContentLoader } from '@weave/design-system';
import { SmsModel } from '../../../../models/sms-history.model';

interface Props {
  data: SmsModel[];
  loading: boolean;
}

export const SmsModule = (props: Props) => {
  const [errors, setErrors] = useState<[string, number][]>([]);
  const [errorCount, setErrorCount] = useState(0);
  const [successCount, setSuccessCount] = useState(0);

  useEffect(() => {
    if (!props.data) {
      return;
    }

    const errorsMap: { [key: string]: number } = {};
    let tmpErrorCount = 0;
    let tmpSuccessCount = 0;

    props.data.forEach((sms) => {
      if (!(sms.status === 'error')) {
        tmpSuccessCount++;
        return;
      }

      tmpErrorCount++;
      const detailMessage = sms.statusDetails ? sms.statusDetails : 'unknown';
      errorsMap[detailMessage]
        ? errorsMap[detailMessage]++
        : (errorsMap[detailMessage] = 1);
    });

    const errorsArray = Object.entries(errorsMap)
      .map((error) => error)
      .sort((err1, err2) => err2[1] - err1[1]);

    setErrorCount(tmpErrorCount);
    setSuccessCount(tmpSuccessCount);
    setErrors(errorsArray);
  }, [props.data]);

  return (
    <Card css={cardStyles}>
      <h1>SMS Deliverability</h1>
      <hr />
      {props.loading ? (
        <ContentLoader />
      ) : (
        <>
          <h2>2 Week Totals</h2>
          <div
            css={css`
              display: flex;
            `}
          >
            <p>
              <strong>Total: &nbsp;</strong>
            </p>
            <p
              css={(theme: WeaveTheme) =>
                `color: ${theme.colors.gray}; padding-right: 16px;`
              }
            >
              {errorCount + successCount}
            </p>

            <p>
              <strong>Success: &nbsp;</strong>
            </p>
            <p
              css={(theme: WeaveTheme) =>
                `color: ${theme.colors.success}; padding-right: 16px;`
              }
            >
              {successCount}
            </p>

            <p>
              <strong>Errors: &nbsp;</strong>
            </p>
            <p
              css={(theme: WeaveTheme) =>
                `color: ${theme.colors.error}; padding-right: 16px;`
              }
            >
              {errorCount}
            </p>
          </div>
          {!!errors?.length && (
            <>
              <hr />
              <h2>Error Types</h2>
              {errors.map(([key, value]) => (
                <div key={key}>
                  <strong>{value}</strong> : {key}
                </div>
              ))}
            </>
          )}
        </>
      )}
    </Card>
  );
};

const cardStyles = css`
  width: 50%;
`;
