import { createAction } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { CustomAxios, getErrorMessage } from '../../../axios';
import { showError, addAlert } from '@weave/alert-system';

export const enableAnalyticsEtl = createAction('ENABLE_ANALYTICS_ETL');

export const handleEnableAnalyticsEtl = function* () {
  try {
    yield call(CustomAxios.post, '/support/v1/analytics-admin/onboarding/etl', {
      enabled: true,
    });
    yield put(addAlert({ type: 'info', message: 'Enabled ETL' }));
  } catch (error: any) {
    yield put(showError(getErrorMessage(error)));
  }
};

export const enableAnalyticsEtlSaga = function* () {
  yield all([takeEvery(enableAnalyticsEtl.toString(), handleEnableAnalyticsEtl)]);
};
