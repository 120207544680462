import { createAction, handleActions } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { CustomAxios } from '../../../../redux/axios';

import { WritebacksModel } from '../../../../models/writebacks.model';

export type WritebacksByApptPayload = string;

export type WritebacksByApptResultPayload = WritebacksModel[];

export const writebacksByAppt =
  createAction<WritebacksByApptPayload>('WRITEBACKS_BY_APPT');
export const writebacksByApptSuccess = createAction<WritebacksByApptResultPayload>(
  'WRITEBACKS_BY_APPT_SUCCESS'
);
export const writebacksByApptFailure = createAction<Error>('WRITEBACKS_BY_APPT_FAILURE');
export const resetWritebacksByAppt = createAction('RESET_WRITEBACKS_BY_APPT');

export const selectWritebacksByAppt = (state) => state.writebacksByAppt;

export const handleWritebacksByAppt = function* (action) {
  try {
    const apptid = action.payload;
    const response = yield call(
      CustomAxios.get,
      `/support/v1/writebacks?appointment_id=${apptid}`
    );
    yield put(writebacksByApptSuccess(response.data.data));
  } catch (error: any) {
    yield put(writebacksByApptFailure(error));
  }
};

export const writebacksByApptSaga = function* () {
  yield all([takeEvery(writebacksByAppt.toString(), handleWritebacksByAppt)]);
};

export interface WritebacksByApptStateModel {
  loading: boolean;
  data: WritebacksModel[];
  error?: Error;
}

const defaultState: WritebacksByApptStateModel = {
  loading: false,
  data: [],
};

export const writebacksByApptReducer = handleActions(
  {
    [writebacksByAppt.toString()]: (state) => Object.assign({}, state, { loading: true }),
    [writebacksByApptSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { data: action.payload, loading: false }),
    [writebacksByApptFailure.toString()]: (state, action) =>
      Object.assign({}, state, { error: action.payload, loading: false }),
    [resetWritebacksByAppt.toString()]: () => defaultState,
  },
  defaultState
);
