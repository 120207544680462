import { CreateNewPricingModel } from '../../../models/pricing.model';
import { RequestAction } from '../../store/store-utils';

export enum MerchantActionTypes {
  CreateNewPricing = 'MERCHANT:CREATE_NEW_PRICING',
  CreateNewPricingSuccess = 'MERCHANT:CREATE_NEW_PRICING_SUCCESS',
  CreateNewPricingFailure = 'MERCHANT:CREATE_NEW_PRICING_FAILURE',
  GetPricings = 'MERCHANT:GET_PRICINGS',
  GetPricingsSuccess = 'MERCHANT:GET_PRICINGS_SUCCESS',
  GetPricingsFailure = 'MERCHANT:GET_PRICINGS_FAILURE',
}

export type CreateNewPricingAction = RequestAction<
  MerchantActionTypes.CreateNewPricing,
  { newPricing: CreateNewPricingModel; closeModal: () => void; editMode: boolean },
  CreateNewPricingModel
>;

export type GetPricingsAction = RequestAction<MerchantActionTypes.GetPricings>;
