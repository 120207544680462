import { css } from 'emotion';
import { gray5, black, white, gray9 } from '@weave/platform-ui-components';

export const chartAndTotalWrapper = css`
  display: grid;
  grid-template-rows: 1fr;
  margin-top: 40px;
`;

export const totalCenterText = css`
  grid-row: 1;
  justify-self: center;
  align-self: center;
  grid-column: 1;
  z-index: 25;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const chartWrapper = css`
  grid-row: 1;
  grid-column: 1;
`;

export const doughnutCenterTitle = css`
  color: ${gray5};
  font-size: 16px;
  line-height: 22px;
`;
export const doughnutCenterValue = css`
  color: ${black};
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
`;

export const legendWrapper = css`
  margin-top: 45px;
  border-top: 1px solid #e4e9eb;
`;

export const legendStyles = css`
  justify-items: center;
  align-items: center;
  font-size: 16px;
  margin-top: 16px;
  > div {
    color: ${gray9};
    font-size: 16px;
    line-height: 22px;
  }
  > div:nth-child(3) {
    color: ${gray5};
  }
  > div:nth-child(4) {
    font-weight: bold;
  }
`;

export const tooltipStyles = css`
  z-index: 100;
  box-sizing: border-box;
  border: 1px solid #e9edef;
  border-radius: 5px;
  background-color: ${white};
  box-shadow: 0 6px 8px 0 rgba(227, 233, 235, 0.4);
  padding: 14px 10px;
  z-index: 25;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > div {
    color: ${gray9};
    font-size: 12px;
    line-height: 16px;
    text-align: center;
  }

  > div:first-child {
    color: ${gray9};
    font-size: 16px;
    font-weight: bold;
  }

  > div:nth-child(2) {
    color: ${gray5};
    padding: 2px;
  }
`;
