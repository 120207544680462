import { call, put, takeLatest } from 'redux-saga/effects';
import { createRequestSaga } from '@weave/alert-system';
import { Store } from '../../store/store.model';
import { createSelector } from 'reselect';
import * as api from './api';
import { CurrentLocationActionTypes, RouteChangeAction } from '../location';

// actions
export enum FaxSettingsActionTypes {
  Fetch = 'GET_FAX_SETTINGS',
  Set = 'SET_FAX_SETTINGS',
}

type FetchFaxSettingsAction = { type: FaxSettingsActionTypes.Fetch; payload: string };

export type FaxProviderDIDInfo = {
  number: string;
  number_type: string;
  provider: string;
  status: string;
  status_callback: string;
  status_callback_method: string;
  voice_fallback_method: string;
  voice_fallback_url: string;
  voice_method: string;
  voice_url: string;
};

type FaxSettingsActions =
  | { type: FaxSettingsActionTypes.Set; payload: FaxProviderDIDInfo }
  | FetchFaxSettingsAction
  | RouteChangeAction;

export const fetchFaxSettings = (payload?: string): FaxSettingsActions => ({
  type: FaxSettingsActionTypes.Fetch,
  payload: payload || '',
});

export const setFaxSettings = (payload: FaxProviderDIDInfo): FaxSettingsActions => ({
  type: FaxSettingsActionTypes.Set,
  payload: payload,
});

// reducer
export type FaxSettingsState = FaxProviderDIDInfo;

const initialState = {
  number: '',
  number_type: '',
  provider: '',
  status: '',
  status_callback: '',
  status_callback_method: '',
  voice_fallback_method: '',
  voice_fallback_url: '',
  voice_method: '',
  voice_url: '',
};

export const faxSettingsReducer = (
  state: FaxSettingsState = initialState,
  action: FaxSettingsActions
): FaxSettingsState => {
  switch (action.type) {
    case FaxSettingsActionTypes.Set:
      return { ...state, ...action.payload };
    case CurrentLocationActionTypes.RouteChange:
      return initialState;
    default:
      return state;
  }
};

// sagas
export const getFaxSettings = createRequestSaga<FetchFaxSettingsAction>({
  key: FaxSettingsActionTypes.Fetch,
  displayErrors: true,
  onError: () => 'Failed to load fax settings.',
  saga: function* ({ payload }) {
    const DID = yield call(api.fetchDIDInfo);
    const providerDIDInfo = yield call(api.fetchProviderDIDInfo, DID.DID);
    yield put(setFaxSettings(providerDIDInfo));
  },
});

export function* faxSettingsSaga() {
  yield takeLatest(FaxSettingsActionTypes.Fetch, getFaxSettings);
}

// selectors
export const selectFaxSettings = (state: Store): FaxSettingsState => state.faxSettings;

export const selectFaxDID = createSelector(selectFaxSettings, ({ number }) => number);
