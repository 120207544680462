import { connect } from 'react-redux';

import { SmsWeaveMessage } from './sms-weave-message.component';
import { getNotificationsSettings } from '../../../../../redux/actions/notifications-settings/notifications-settings.action';
import { selectCurrentLocationId } from '../../../../../redux/actions/location/current-location';
import { Store } from '../../../../../redux/store/store.model';

interface OwnProps {
  uuid: string;
}

export const SmsWeaveMessageContainer = connect(
  (store: Store, ownProps: OwnProps) => ({
    locationID: selectCurrentLocationId(store),
    smsDetails: store.smsDetails.data,
    settings: store.notificationsSettings.categories,
    uuid: ownProps.uuid,
  }),
  {
    getNotificationsSettings,
  }
)(SmsWeaveMessage);
