import { CustomAxios } from '../../redux/axios/axios';
import { Opportunity } from './multi-location.types';

export const getChildAccounts = async (accountId: String) => {
  const parentResponse = await CustomAxios.get(
    `support/v1/salesforce/accounts/${accountId}`
  );

  const response = await CustomAxios.get(
    `support/v1/salesforce/accounts/${accountId}/children-accounts`
  );

  return [parentResponse.data.data.account, ...(response.data?.data?.accounts ?? [])];
};

export const getOpportunities = async (accountStrings: String) => {
  /*
  This logic is a bit confusing
  The relationship between accounts and opportunities is one to many,
  but we only want to select the most relevant opportunity per account
  If there are multiple opportunities, we select the relevant one as follows:
    1. Select the New Sale opportunity
    2. If multiple New Sale opportunities exist, select the most recent one
  */
  const response = await CustomAxios.get(
    `support/v1/salesforce/opportunities?account_ids=${accountStrings}`
  );
  const selectedOpportunities = response.data.data.opportunities.reduce((acc, opp) => {
    const oppWithSameAccId = acc.find(
      (opportunity) => opportunity.account_id === opp.account_id
    );
    if (oppWithSameAccId) {
      const index = acc.findIndex(
        (opportunity) => opportunity.account_id === opp.account_id
      );
      if (opp.record_type === 'New Sale' && oppWithSameAccId.record_type !== 'New Sale') {
        acc.splice(index, 1, opp);
      } else if (
        opp.record_type === 'New Sale' &&
        oppWithSameAccId.record_type === 'New Sale'
      ) {
        const sortedArr = [opp, oppWithSameAccId].sort((a, b) => {
          const dateA: any = new Date(a.close_date);
          const dateB: any = new Date(b.close_date);
          return dateB - dateA;
        });
        acc.splice(index, 1, sortedArr[0]);
      }
      return acc;
    } else if (opp.account_id) {
      return [...acc, opp];
    } else {
      return acc;
    }
  }, []);
  return selectedOpportunities;
};

export const getPreProvisions = async (opportunities: Opportunity[]) => {
  const preProvisions: any[] = [];
  for (let i = 0; i < opportunities.length; i++) {
    try {
      const response = await CustomAxios.get(
        `support/v1/preprovisions?oppid=${opportunities[i].id}`
      );
      preProvisions.push(response.data.data.pre_provision);
    } catch {
      preProvisions.push({ salesforce_account_id: opportunities[i].account_id });
    }
  }
  return preProvisions;
};

export const postAccountData = async (account) => {
  try {
    await CustomAxios.put(`support/v1/salesforce/accounts/${account.id}`, {
      ...account,
    });
  } catch (error: any) {
    console.log(error);
  }
};

export const postQuestionData = async (
  opportunities: Opportunity[],
  questionData: Opportunity
) => {
  const promises = opportunities.map(async (opportunity) => {
    try {
      await CustomAxios.put(`support/v1/salesforce/opportunities/${opportunity.id}`, {
        ...opportunity,
        multiple_locations_sharing_one_database:
          questionData.multiple_locations_sharing_one_database,
        multiple_databases_in_single_location:
          questionData.multiple_databases_in_single_location,
        do_these_locations_share_phone_number:
          questionData.do_these_locations_share_phone_number,
        cross_schedules_10_of_appointments:
          questionData.cross_schedules_10_of_appointments,
        needs_call_center: questionData.needs_call_center,
        multi_notes: questionData.multi_notes,
      });
    } catch (error: any) {
      console.error(error);
    }
  });

  await Promise.all(promises);
};

export const deletePreprovisions = async (accounts: any) => {
  const promises = accounts.map(async (account) => {
    try {
      await CustomAxios.delete(`support/v1/preprovisions/${account.id}`);
    } catch (error: any) {
      console.error(error);
    }
  });
  await Promise.all(promises);
};
