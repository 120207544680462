import React, { useEffect } from 'react';
import { theme } from '@weave/theme-original';
import { ContentLoader, Modal } from '@weave/design-system';
import { css } from '@emotion/core';
import {
  REACT_APP_TWOCP_PUBLIC_KEY_ACH,
  REACT_APP_TWOCP_PUBLIC_KEY_CC,
} from 'config/app';

interface Props {
  loading: boolean;
  onClose: () => void;
  onCreatePaymentMethod: (token: string, type: string) => void;
}

declare const SECCInitializeSecureForm: (
  ccapikey: string,
  chapikey: string,
  parentId: string,
  formId: string,
  width: number,
  styles: any,
  buttonStyles: any,
  callback: (token: string, type: string) => void
) => void;
const PUBLIC_API_KEY_2CP = REACT_APP_TWOCP_PUBLIC_KEY_CC;
const PUBLIC_API_KEY_2CP_ACH = REACT_APP_TWOCP_PUBLIC_KEY_ACH;

export const TwoCpBillingForm = ({ loading, onClose, onCreatePaymentMethod }: Props) => {
  useEffect(() => {
    setTimeout(() => {
      SECCInitializeSecureForm(
        PUBLIC_API_KEY_2CP,
        PUBLIC_API_KEY_2CP_ACH,
        'secureFormContainer',
        'masterForm',
        300,
        { FontSize: '14px' },
        { ButtonHidden: true },
        formSuccessCallback
      );
    }, 0);
  }, []);

  function formSuccessCallback(token, type, ...rest) {
    onCreatePaymentMethod(token, type);
  }

  return (
    <article>
      <ContentLoader show={loading} message="Submitting, please wait..." />
      <form id="masterForm">
        <Modal.Header>Add Payment Method</Modal.Header>
        <Modal.Body>
          <div id="secureFormContainer" css={iframeContainerStyles} />
        </Modal.Body>
        <Modal.Actions
          primaryLabel="Submit"
          // onPrimaryClick={() => onCreatePaymentMethod('test', 'test')}
          onPrimaryClick="submit"
          onSecondaryClick={onClose}
        />
      </form>
    </article>
  );
};

const iframeContainerStyles = css`
  padding: ${theme.spacing(2)};
  display: flex;
  width: 100%;
  justify-content: center;
`;
