import { connect } from 'react-redux';
import { DataCenterMover } from './data-center-mover.component';
import {
  selectCurrentLocationId,
  selectCurrentLocationDataCenter,
  selectCurrentLocationSlug,
} from '../../../../redux/actions/location';
import { selectHasWeaveAcl } from '../../../../redux/actions/auth/auth.selectors';
import { CoreACLs } from '../../../../redux/actions/auth/auth.types';

export const DataCenterMoverContainer = connect(
  (state: any) => ({
    canEdit: selectHasWeaveAcl(state, CoreACLs.PHONEDATACENTERWRITE),
    currentDataCenter: selectCurrentLocationDataCenter(state),
    locationId: selectCurrentLocationId(state) as string,
    slug: selectCurrentLocationSlug(state),
  }),
  {}
)(DataCenterMover);
