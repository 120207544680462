import React from 'react';
import moment from 'moment-timezone';

import * as styles from '../sms-list.styles';
import { SmsModel } from '../../../../../models/sms-history.model';
import { WritebacksModel } from '../../../../../models/writebacks.model';
import { dateTimeZoneFormat } from '../../../../../helpers/utils';

interface Props {
  locationID: string;
  timezone: string;
  smsDetails: SmsModel;
  writebacks: WritebacksModel[];
  apptID: string;
  getWritebacksByAppt: (payload: string) => void;
  resetWritebacksByAppt: () => void;
}

export class SmsWritebacks extends React.Component<Props, {}> {
  componentDidMount() {
    this.getWritebackData();
  }

  componentDidUpdate(prev) {
    if (prev.locationID !== this.props.locationID || prev.apptID !== this.props.apptID) {
      this.getWritebackData();
    }
  }

  componentWillUnmount() {
    this.props.resetWritebacksByAppt();
  }

  getWritebackData() {
    const apptid = this.props.apptID;
    if (apptid) {
      this.props.getWritebacksByAppt(apptid);
    }
  }

  render() {
    const apptID = this.props.apptID ? this.props.apptID : '';
    return (
      <div className={styles.box}>
        <p>
          <strong>Writebacks for </strong>
          {apptID.slice(0, 4)}..
        </p>
        <div className={styles.eventBox}>
          {!this.props.writebacks.length && <p className={styles.blur}>No Data</p>}
          {!!this.props.writebacks.length &&
            this.props.writebacks.map((item) => (
              <div className={styles.writebackItem} key={item.id}>
                <div className={styles.smsWriteback}>
                  <p className="left">Writeback</p>
                  <p>{item.status}</p>
                </div>

                <div className={styles.smsWriteback}>
                  <p className="left">Source</p>
                  <p>{item.source_type}</p>
                </div>

                <div className={styles.smsWriteback}>
                  <p className="left">Status</p>
                  <p>{item.writeback_status}</p>
                </div>

                <div className={styles.smsWriteback}>
                  <p className="left">Modified</p>
                  <p>
                    {moment(item.writeback_modified_at)
                      .tz(this.props.timezone)
                      .format(dateTimeZoneFormat)}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
}
