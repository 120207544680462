import React, { useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { DeliveryReportChart } from '../../shared/charts/delivery-reports-chart';
import { fetchDeliverySummary } from '../../../redux/actions/auto-msg-queue';
import { fetchQueuedTime } from '../../../redux/actions/auto-msg-queue/auto-msg-delivery-reports.action';
import { dateTimeZoneFormat } from '../../../helpers/utils';
import { selectCurrentLocationId } from '../../../redux/actions/location/current-location';
import { Store } from '../../../redux/store/store.model';

export const AutoMsgQueueChart = () => {
  const dispatch = useDispatch();
  const { deliverySummary, queueTimes, currentLocationId } = useSelector(
    (store: Store) => ({
      ...store.autoMsgQueue,
      currentLocationId: selectCurrentLocationId(store),
    })
  );

  useEffect(() => {
    dispatch(fetchDeliverySummary());
    dispatch(fetchQueuedTime());
  }, [currentLocationId]);

  const lastQueueTime = (!!queueTimes.length && queueTimes[0].value) || {
    QueuedAt: '',
    Status: '',
  };

  return (
    <div>
      {lastQueueTime.QueuedAt && (
        <span style={{ float: 'left' }}>
          Queued at : <b>{moment(lastQueueTime.QueuedAt).format(dateTimeZoneFormat)} </b>-
          status : <b>{lastQueueTime.Status}</b>
        </span>
      )}
      <div>
        {deliverySummary && !!deliverySummary.length && (
          <DeliveryReportChart deliverySummary={deliverySummary} />
        )}
      </div>
    </div>
  );
};
