import { connect } from 'react-redux';

import { SmsInfo } from './sms-info.component';
import { person } from '../../../../../redux/actions/patients/person.action';
import { patients } from '../../../../../redux/actions/patients/patients.action';
import {
  selectCurrentLocationId,
  selectCurrentLocationTimeZone,
} from '../../../../../redux/actions/location/current-location';
import { Store } from '../../../../../redux/store/store.model';

export const SmsInfoContainer = connect(
  (store: Store) => ({
    locationID: selectCurrentLocationId(store),
    smsDetails: store.smsDetails.data,
    timezone: selectCurrentLocationTimeZone(store),
    patientList: store.patients.data,
  }),
  {
    getPatients: patients,
    getPerson: person,
  }
)(SmsInfo);
