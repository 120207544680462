import React, { useState, useCallback } from 'react';
import { cx } from 'emotion';
import {
  ButtonBar,
  Heading,
  PrimaryButton,
  SecondaryButton,
  Text,
  TextField,
  useFormField,
} from '@weave/design-system';
import { useDropzone } from 'react-dropzone';

import * as styles from './add-greeting-upload-form.styles';

interface Props {
  activeMailboxId: string;
  isOpen: boolean;
  onRequestClose: () => void;
  uploadGreeting: (payload: {
    greetingFile: AudioFile | null;
    greetingName: string;
    mailboxId: string;
  }) => void;
}

interface AudioFile extends File {
  path: string;
}

export const AddGreetingUploadForm = (props: Props) => {
  const [error, setError] = useState('');
  const [greetingFile, setGreetingFile] = useState<AudioFile | null>(null);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      setGreetingFile(acceptedFiles[0]);
      setError('');
    } else if (rejectedFiles.length > 0) {
      setError('Invalid file type: Must be WAV or MP3');
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'audio/wav, audio/mpeg, video/mp4',
    onDrop,
  });
  const onBrowseClick = getRootProps().onClick;

  const fieldProps = useFormField({
    type: 'text',
    required: true,
  });

  const uploadGreeting = () => {
    if (!greetingFile) {
      return setError('Please select an audio file');
    }

    props.uploadGreeting({
      greetingFile: greetingFile,
      greetingName: fieldProps.value || greetingFile.name,
      mailboxId: props.activeMailboxId,
    });

    props.onRequestClose();
  };

  const renderDropZoneContents = (greetingFile: AudioFile | null, isDragActive) => {
    if (greetingFile?.name) {
      if (isDragActive) {
        return (
          <div className={styles.dropAnother}>
            <Text>Drop file here...</Text>
          </div>
        );
      }

      return <div className={styles.containFile}>File selected: {greetingFile.name}</div>;
    }

    if (isDragActive) {
      return (
        <div className={styles.dropFirst}>
          <Text>Drop the file here...</Text>
        </div>
      );
    }

    return (
      <React.Fragment>
        <Heading textAlign="center">Upload Media File</Heading>
        <PrimaryButton className={styles.browseButton} onClick={onBrowseClick}>
          Browse Files
        </PrimaryButton>
        <p>Drag and Drop WAV or MP3 file here</p>
      </React.Fragment>
    );
  };

  return (
    <div>
      <div className={styles.mainSection}>
        <div
          className={cx(styles.dropzone, greetingFile?.name ? 'hasFile' : '')}
          {...getRootProps({
            onClick: greetingFile?.name
              ? () => {
                  return;
                }
              : (event) => event.stopPropagation(),
          })}
        >
          <input {...getInputProps()} />
          {renderDropZoneContents(greetingFile, isDragActive)}
        </div>
        {error && <div className={styles.error}>{error}</div>}
        {greetingFile?.name && (
          <div className={styles.containRename}>
            <TextField {...fieldProps} label="Rename File" name="greetingName" />
            <div className={styles.greetingName}>
              <Text>Give your file a name you can remember</Text>
            </div>
          </div>
        )}
      </div>
      <hr className={styles.hr} />
      {!greetingFile?.name && (
        <React.Fragment>
          <Heading textAlign="center">Don't see your media file?</Heading>
          <div className={styles.instructions}>
            <Text>
              Dial *86 on your phone to record a new media file, then refresh this page to
              have it appear here for selection.
            </Text>
          </div>
        </React.Fragment>
      )}
      {greetingFile?.name && (
        <ButtonBar>
          <SecondaryButton onClick={props.onRequestClose}>Cancel</SecondaryButton>
          <PrimaryButton onClick={uploadGreeting}>Upload File</PrimaryButton>
        </ButtonBar>
      )}
    </div>
  );
};
