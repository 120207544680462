import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectCurrentLocationId } from '../../redux/actions/location/current-location';
import { PhoneRouter } from './phone-router.component';
import { requestListPhoneNumbers } from '../../redux/actions/phone-numbers';
import { getPhoneMedia } from '../../redux/actions/phone-media';

export const PhonePages = () => {
  const dispatch = useDispatch();
  const locationId = useSelector(selectCurrentLocationId);

  useEffect(() => {
    if (locationId) {
      dispatch(requestListPhoneNumbers());
      dispatch(getPhoneMedia());
    }
  }, [locationId]);

  if (!locationId) {
    return null;
  }

  return <PhoneRouter />;
};
