import { createAction } from 'redux-actions';
import { get, intersectionWith } from 'lodash';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { showError } from '@weave/alert-system';
import { CustomAxios, getErrorMessage } from '../../axios';

export const getAvailablePhoneNumbers = createAction('GET_AVAILABLE_PHONE_NUMBERS');
export const getAvailablePhoneNumbersFailure = createAction(
  'GET_AVAILABLE_PHONE_NUMBERS_FAILURE'
);
export const getAvailablePhoneNumbersSuccess = createAction(
  'GET_AVAILABLE_PHONE_NUMBERS_SUCCESS'
);
export const clearAvailablePhoneNumbers = createAction('CLEAR_AVAILABLE_PHONE_NUMBERS');
export const purchasePhoneNumbers = createAction('PURCHASE_PHONE_NUMBERS');
export const newNumberSuccessMessages = createAction('NEW_NUMBER_SUCCESS_MESSAGES');
export const newNumberFailureMessages = createAction('NEW_NUMBER_FAILURE_MESSAGES');
export const addNewNumberSuccessMessage = createAction('ADD_NEW_NUMBER_SUCCESS_MESSAGE');
export const addNewNumberFailureMessage = createAction('ADD_NEW_NUMBER_FAILURE_MESSAGE');
export const purchasePhoneNumbersSuccess = createAction('PURCHASE_PHONE_NUMBERS_SUCCESS');

export const purchaseSingleNumber = function* (num) {
  try {
    const response = yield call(
      CustomAxios.post,
      'support/v1/phone/numbers/purchasenewphonenumber',
      num
    );
    const data = response.data.data;
    yield put(addNewNumberSuccessMessage(`New Number (${data.phone_number}) purchased.`));
    return { response: data };
  } catch {
    yield put(addNewNumberFailureMessage(`Could not purchase ${num.phone_number}`));
  }
  return;
};

export const provisionSingleNumber = function* (num) {
  try {
    const response = yield call(
      CustomAxios.post,
      'support/v1/tenant/addnumbertophonedataservice',
      { number: num.response.phone_number }
    );
    const data = response.data.data;
    yield put(
      addNewNumberSuccessMessage(
        `New Number (${data.Number}) provisioned in phone data service. ${
          get(num, 'response.phone_number_capabilities.fax')
            ? 'If this number is intended for fax, please advise the porting team and they will reprovision this number'
            : ''
        }`
      )
    );
    return { response: data };
  } catch {
    yield put(
      addNewNumberFailureMessage(
        `Could not provision in phone data service ${num.response.phone_number}`
      )
    );
  }
  return;
};

export const purchaseTwilioPhoneNumbers = function* (action) {
  const { selectedNumbers, locationSlug } = action.payload;

  // clear ui messages
  yield put(newNumberFailureMessages([]));
  yield put(newNumberSuccessMessages([]));

  // purchase phone numbers
  const purchaseNumbersResponses = yield all(
    selectedNumbers.map((num) =>
      call(purchaseSingleNumber, {
        phone_number: num,
        friendly_name: locationSlug,
        trunk_sid: '',
        sms_application_sid: '',
      })
    )
  );

  // only provision numbers that we successfully purchased
  const numbersToProvision = intersectionWith(
    purchaseNumbersResponses,
    selectedNumbers,
    (a: any, b: string) => {
      return b === get(a, 'response.phone_number', '');
    }
  );

  yield all(numbersToProvision.map((num) => call(provisionSingleNumber, num)));

  yield put(purchasePhoneNumbersSuccess());
};

export const purchasePhoneNumbersSaga = function* () {
  yield takeLatest(purchasePhoneNumbers.toString(), purchaseTwilioPhoneNumbers);
};

export const getAvailablePhoneNumbersSaga = function* () {
  const getNumbers = function* (action) {
    try {
      const { region, locality, areacode, zipcode } = action.payload;
      const response = yield call(
        CustomAxios.get,
        `support/v1/phone/numbers/listavailablenumbers/?region=${region}&locality=${locality}&areacode=${areacode}&zipcode=${zipcode}`
      );
      const numbers = get(response, 'data.data', {
        available_phone_numbers: [],
        uri: '',
      });
      const uri = get(numbers, 'uri', '');
      let matchingURI = `${numbers.available_phone_numbers.length} phone numbers matched search by: `;

      if (uri.includes('AreaCode')) {
        matchingURI += 'desired area code, ';
      }
      if (uri.includes('InLocality')) {
        matchingURI += 'city, ';
      }
      if (uri.includes('InPostalCode')) {
        matchingURI += 'zip code, ';
      }
      if (uri.includes('InRegion')) {
        matchingURI += 'state, ';
      }
      matchingURI = matchingURI.slice(0, -2);
      numbers.uri = matchingURI;
      yield put(getAvailablePhoneNumbersSuccess(numbers));
    } catch (error: any) {
      yield put(showError(getErrorMessage(error)));
      yield put(getAvailablePhoneNumbersFailure());
    }
  };

  yield takeEvery(getAvailablePhoneNumbers.toString(), getNumbers);
};

