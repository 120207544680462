import React, { useEffect, useState } from 'react';
import { isBefore } from 'date-fns';
import { CustomAxios } from '../../../../redux/axios';
import { OnOffIndicator } from 'components/shared/on-off-indicator/on-off-indicator.component';
import { css } from '@emotion/core';
import { useThemeValues } from '@weave/design-system';

const currentStatusPillStyles = (theme) => css`
  background-color: ${theme.colors.weaveBlue};
  border-radius: ${theme.borderRadius.medium};
  color: ${theme.colors.white};
  padding: ${theme.spacing(0.5, 1)};
  margin-left: ${theme.spacing(1)};
`;

type FeatureFlagsEventsProps = {
  flagName: string;
  locationId: string;
};

export const FeatureFlagsEvents = ({ flagName, locationId }: FeatureFlagsEventsProps) => {
  const theme = useThemeValues();
  const [flagEvents, setFlagEvents] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(function () {
    loadEvents(flagName);
  }, []);

  const loadEvents = (flagName) => {
    setLoading(true);
    CustomAxios.get(`/feature-flags/v1/flags/${flagName}`).then((res) => {
      const flag = res.data;
      CustomAxios.get(
        `/feature-flags/v1/events?resource=location-feature-flag&locationId=${locationId}&resourceId=${flag.id}`
      )
        .then((res) => {
          if (res.data && res.data.hasOwnProperty('events')) {
            setFlagEvents(res.data.events);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const getUsername = (event) => {
    // try to get the username in the kv data if available otherwise fallback to UUID
    if (event.values.username) {
      return event.values.username;
    }

    return event.userId !== '00000000-0000-0000-0000-000000000000'
      ? event.userId
      : 'Unknown';
  };

  return (
    <div>
      {loading && <div style={{ textAlign: 'center' }}>Loading...</div>}

      {flagEvents.length !== 0 ? (
        <table className={'table table-bordered'}>
          <thead>
            <tr>
              <th>Modified Date</th>
              <th>User</th>
              <th>Operation</th>
              <th>New Value</th>
            </tr>
          </thead>
          <tbody>
            {flagEvents
              .sort((eventA: any, eventB: any) =>
                isBefore(new Date(eventA.modifiedAt), new Date(eventB.modifiedAt))
                  ? 1
                  : -1
              )
              .map((event, i: number) => {
                return (
                  <tr key={event.id.data}>
                    <th>
                      {new Date(event.modifiedAt).toUTCString()}
                      {i === 0 && (
                        <span css={currentStatusPillStyles(theme)}>Current Status</span>
                      )}
                    </th>
                    <th>{getUsername(event)}</th>
                    <th>{event.operation}</th>
                    <th>
                      <OnOffIndicator on={event.values.newValue === 'true'} />
                    </th>
                  </tr>
                );
              })}
          </tbody>
        </table>
      ) : (
        <div>No History Found</div>
      )}
    </div>
  );
};
