import React from 'react';
import { Text } from '@weave/design-system';

export const NoDataFound = ({ data }) => {
  return (
    <Text color="light" style={{ textAlign: 'center' }}>
      {data}
    </Text>
  );
};
