import React from 'react';
import { css } from '@emotion/core';
import ReactJson, { InteractionProps } from 'react-json-view';
import { useSelector } from 'react-redux';
import { useAlert } from '@weave/alert-system';
import {
  DropdownField,
  useFormField,
  Text,
  Heading,
  TextareaField,
  ButtonBar,
  PrimaryButton,
  SecondaryButton,
  useModalControl,
  ConfirmationModal,
} from '@weave/design-system';

import { Page } from '../shared';
import { selectWeaveUserAcls } from '../../redux/actions/auth/auth.selectors';
import { useResource } from '../shared/hooks';
import {
  addLocationsToRelease,
  enableRelease,
  disableRelease,
  removeLocationsFromRelease,
  DesktopReleaseUrl,
  deleteRelease,
} from './api';
import { DesktopReleaseModel } from './models';
import { Publish } from './publish';
import { CreateRelease } from './create-release';
import { CoreACLs } from '../../redux/actions/auth/auth.types';

const pageStyles = css`
  max-width: 500px;
  margin: auto;

  section {
    padding-top: 32px;
  }

  .react-json-view .string-value {
    font-family: monospace;
  }
`;

export const DesktopReleases = () => {
  const hasReleaseAcl =
    useSelector(selectWeaveUserAcls).indexOf(CoreACLs.APPRELEASEWRITE) >= 0;

  const dropDownFieldProps = useFormField({ type: 'dropdown' });
  const textAreaFieldProps = useFormField({ type: 'text' });
  const alert = useAlert();
  const { modalProps, triggerProps } = useModalControl();

  const releases = useResource<DesktopReleaseModel[]>({
    url: `${DesktopReleaseUrl}?name=weave_client&includeDisabled=true`,
    deps: [],
    emptyState: [],
  });

  const {
    data: selectedRelease,
    loading,
    refresh,
  } = useResource<DesktopReleaseModel>({
    url: `${DesktopReleaseUrl}/${dropDownFieldProps.value}`,
    deps: [dropDownFieldProps.value],
    suppressFetch: !dropDownFieldProps.value,
    emptyState: {},
  });

  // Release Time will return a default date if it isn't populated
  const isPublished = !(selectedRelease?.ReleaseTime?.startsWith('0001') ?? true);
  const { location: locations, ...displayFilters } = selectedRelease?.Filters ?? {};

  const downloadUrl = `https://storage.googleapis.com/software-updates.getweave.com/${selectedRelease?.Information?.filename}`;

  const handleAddLocations = () => {
    // Some minor validation to remove all non-guid characters, split on whitespace, ensure correct string length and uniqueness
    const newLocations = textAreaFieldProps.value
      .replace(/[^A-Fa-f0-9\-\s]/gi, '')
      .split(/\s/)
      .filter((id, index, self) => id.length === 36 && self.indexOf(id) === index);

    if (selectedRelease?.ID && newLocations?.length) {
      addLocationsToRelease(selectedRelease?.ID, newLocations)
        .then(() => {
          textAreaFieldProps.onChange({ name: 'AddLocations', value: '' });
          refresh();
          alert.success('Locations successfully added to release');
        })
        .catch((error) => {
          console.error(error);
          alert.error('Error adding locations to release');
        });
    } else {
      alert.error('No valid Ids found');
    }
  };

  const handleDeleteLocation = ({ existing_value: locationId }: InteractionProps) => {
    if (hasReleaseAcl && selectedRelease?.ID && typeof locationId === 'string') {
      removeLocationsFromRelease(selectedRelease?.ID, [locationId])
        .then(() => {
          refresh();
          alert.success('Location successfully removed from release');
        })
        .catch((error) => {
          console.error(error);
          alert.error('Error removing location');
        });
    } else {
      alert.error('Permission Error');
    }
  };

  const handleEnableRelease = () => {
    if (hasReleaseAcl && selectedRelease?.ID) {
      enableRelease(selectedRelease.ID).then(() => {
        refresh();
      });
    }
  };

  const handleDisableRelease = () => {
    if (hasReleaseAcl && selectedRelease?.ID) {
      disableRelease(selectedRelease.ID).then(() => {
        refresh();
      });
    }
  };

  const handleDeleteRelease = () => {
    modalProps.onClose();
    if (hasReleaseAcl && selectedRelease?.ID) {
      deleteRelease(selectedRelease.ID).then(() => {
        refreshData();
      });
    }
  };

  const refreshData = () => {
    dropDownFieldProps.onChange({ name: 'AddLocations', value: '' });
    releases.refresh();
    refresh();
  };

  return (
    <Page
      title="Desktop Release Manager"
      subtitle="This allows you to control when a build is made available to specific locations"
      showBackBtn={false}
      css={pageStyles}
      headerActions={<CreateRelease refresh={refreshData} />}
    >
      <DropdownField {...dropDownFieldProps} name="release" label="Release">
        {releases.data.map((release) => (
          <DropdownField.Option key={release.ID} value={release.ID}>
            {release.ID}
          </DropdownField.Option>
        ))}
      </DropdownField>

      {dropDownFieldProps?.value && selectedRelease?.ID && !loading && (
        <>
          <section>
            <Text>
              This release is currently{' '}
              <strong>{selectedRelease.Disabled ? 'disabled' : 'enabled'}</strong>
            </Text>
            {hasReleaseAcl && (
              <>
                {selectedRelease.Disabled && (
                  <>
                    <PrimaryButton size="tiny" onClick={handleEnableRelease}>
                      Enable
                    </PrimaryButton>

                    <SecondaryButton
                      {...triggerProps}
                      size="tiny"
                      css={css`
                        margin-left: 16px;
                      `}
                    >
                      Completely Delete Release
                    </SecondaryButton>
                    <ConfirmationModal
                      {...modalProps}
                      title="Confirm Delete"
                      message="Are you sure you want to delete this release?"
                      onConfirm={handleDeleteRelease}
                    />
                  </>
                )}
                {!selectedRelease.Disabled && (
                  <PrimaryButton size="tiny" onClick={handleDisableRelease}>
                    Disable
                  </PrimaryButton>
                )}
              </>
            )}
          </section>
          <section>
            <Heading level={2}>Current Filters</Heading>
            <ReactJson
              src={displayFilters}
              name="filters"
              enableClipboard={false}
              displayDataTypes={false}
              collapsed={true}
            />
          </section>

          {isPublished && (
            <section>
              <Heading level={2}>Publish Details</Heading>

              <ul>
                <li>
                  Release Date:{' '}
                  {new Date(selectedRelease.ReleaseTime).toLocaleDateString('lll')}
                </li>
                <li>Batch Size: {selectedRelease.ReleaseBatchSize}</li>
                <li>Batch Interval: {selectedRelease.ReleaseInterval}</li>
                <li>Updated Locations: {selectedRelease.UpdatedLocations}</li>
              </ul>
            </section>
          )}

          {locations && (
            <section>
              <>
                <Heading level={2}>Current Locations</Heading>
                <ReactJson
                  src={locations}
                  name="locations"
                  enableClipboard={false}
                  displayDataTypes={false}
                  onDelete={hasReleaseAcl ? handleDeleteLocation : undefined}
                />
              </>
            </section>
          )}

          <section>
            <Heading level={2}>Installers</Heading>
            <ul>
              <li>
                <a href={downloadUrl.replace('.zip', '.dmg')}>Mac</a>
              </li>
              <li>
                <a href={downloadUrl.replace('.zip', '.exe')}>Windows</a>
              </li>
              <li>
                <a href={downloadUrl.replace('.zip', '.tgz')}>Linux</a>
              </li>
            </ul>
          </section>

          {hasReleaseAcl && (
            <>
              {!isPublished && (
                <section>
                  <Heading level={2}>Bulk Add Location Ids</Heading>
                  <TextareaField
                    {...textAreaFieldProps}
                    label=""
                    placeholder="Whitespace separated list of LocationIds"
                    name="AddLocations"
                  />
                  <ButtonBar>
                    <PrimaryButton onClick={handleAddLocations}>
                      Add Locations
                    </PrimaryButton>
                  </ButtonBar>
                </section>
              )}

              <section>
                <Publish
                  releaseId={selectedRelease.ID}
                  onFinish={refreshData}
                  isPublished={isPublished}
                />
              </section>
            </>
          )}
        </>
      )}
    </Page>
  );
};
