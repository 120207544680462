import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { AutoReminderModal } from './auto-reminder-modal.component';
import {
  getNotificationHistory,
  getNotificationsSettings,
  selectNotificationHistory,
  selectNotificationSetting,
  updateNotificationSettings,
  updateNotificationToggle,
} from '../../../../redux/actions/notifications-settings/notifications-settings.action';
import {
  NotificationsHistory,
  NotificationsSettings,
  ReminderDetails,
  ReminderModalContent,
  ReminderUpdate,
  NotificationToggle,
} from '../../../../redux/actions/notifications-settings/notification-settings.types';

export type StoreProps = {
  selectedAutoReminder: ReminderDetails;
  loading: boolean;
  success?: string;
  error?: Error;
};

export type DispatchProps = {
  getNotificationsSettings: () => void;
  updateNotificationSettings: (statusMapSettings: ReminderUpdate) => void;
  updateNotificationToggle: (statusMapSettings: NotificationToggle) => void;
  getNotificationHistory: (reminderId: string) => void;
};

interface OwnProps {
  onRequestClose: (event: React.MouseEvent<HTMLElement>) => void;
  content: ReminderModalContent;
  reminderId: string;
}

interface Store {
  notificationsSettings: {
    loading: boolean;
    categories: NotificationsSettings;
    notificationsHistory: NotificationsHistory;
  };
}

const mapStateToProps = (store, ownProps) => ({
  selectedNotificationHistory: selectNotificationHistory(
    store.notificationsSettings.notificationsHistory,
    ownProps.reminderId
  ),
  selectedAutoReminder: selectNotificationSetting(
    store.notificationsSettings.categories,
    ownProps.reminderId
  ),
  loading: store.notificationsSettings.loading,
});

const mapDispatchToProps = {
  getNotificationsSettings,
  updateNotificationSettings,
  getNotificationHistory,
  updateNotificationToggle,
};

// withRouter is getting confused about ts. This makes it happy and we still get strict typing on everything we care about.
const AutoReminderModalWithRouter = withRouter(AutoReminderModal as any);

export const AutoReminderModalContainer = connect<
  StoreProps,
  DispatchProps,
  OwnProps,
  Store
>(
  mapStateToProps,
  mapDispatchToProps
)(AutoReminderModalWithRouter);
