import { css } from 'emotion';

export const locationDashboardStyles: any = {};

locationDashboardStyles.tableHeadRow = css`
  border-radius: 10px;
  background-color: #f6f7f7;
`;

locationDashboardStyles.tableBody = css`
  color: #8c9496;
  font-weight: 300;
`;

locationDashboardStyles.dotColumn = css`
  color: #333b3d;
`;

locationDashboardStyles.tableActions = css`
  display: flex;
  align-items: center;
  margin-top: -7px;
`;

locationDashboardStyles.viewStatusButton = css`
  width: 135px;
  font-size: 16px;
  height: 40px;
`;

locationDashboardStyles.reviewButton = css`
  width: 135px;
  font-size: 14px;
  height: 40px;
`;

locationDashboardStyles.pingingBandwidth = css`
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
`;

locationDashboardStyles.statusComplete = css`
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
`;

locationDashboardStyles.progress = css`
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
`;

locationDashboardStyles.alertStatusStyle = css`
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
`;

locationDashboardStyles.gotItButton = css`
  width: 250px;
  font-size: 14px;
  height: 48px;
  margin: 10px auto;
`;

locationDashboardStyles.tableView = css`
  width: 100%;

  th {
    border: none;
    border-bottom: none !important;
    color: #8c9496;
    font-weight: normal;
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
  }
  th:last-child {
    border-radius: 0 10px 10px 0;
  }
  th:first-child {
    border-radius: 10px 0 0 10px;
  }
  tr {
    border: none;
  }
  td {
    border: none;
    padding-top: 20px;
    padding-bottom: 17px;
    padding-left: 20px;
    font-size: 15px;
  }
  thead {
    border: none;
  }
  tbody {
    border: none;
  }
`;
