import { css } from 'emotion';
import { weaveBlue } from '../../../shared/styles/colors';

export const infoBox = css`
  label: infoBox;
  width: 50%;
`;
export const infoItem = css`
  label: infoItem;
  height: 35px;
`;
export const infoBoxLeft = css`
  label: infoBoxLeft;
  width: 30%;
  min-width: 150px;
  padding-left: 15px;
  padding-right: 15px;
`;
export const infoBoxRight = css`
  label: infoBoxLeft;
  width: 50%;
  min-width: 250px;
  padding-left: 15px;
  padding-right: 15px;
`;

export const flexStyle = css`
  label: flexStyle;
  display: flex;
`;

export const rightStyle = css`
  label: rightStyle;
  text-align: right;
`;

export const rightP = css`
  label: rightP;
  padding-bottom: 2px;
  white-space: nowrap;
  overflow-x: auto;
  overflow: hidden;
`;

export const copyToClipboard = css`
  label: copyToClipboard;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  color: ${weaveBlue};
`;

export const copied = css`
  margin-left: 5px;
  label: copied;
  color: #28a745;
`;
