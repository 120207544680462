import { connect } from 'react-redux';
import { MetadataDetails } from './metadata-details.component';
import { appointment } from '../../../../../redux/actions/appointments/appointment/appointment.action';
import { event } from '../../../../../redux/actions/events/event.action';
import { EventModel } from '../../../../../models/event.model';
import { selectCurrentLocationTimeZone } from '../../../../../redux/actions/location/current-location';
import { Store as RootStore } from '../../../../../redux/store/store.model';

interface Store extends RootStore {
  event: {
    data: EventModel;
  };
}

interface OwnProps {
  metadata: {
    relatedDataType: string;
    relatedDataID: string;
  };
}

export const MetadataDetailsContainer = connect(
  (store: Store, ownProps: OwnProps) => ({
    appointment: store.appointment.data,
    event: store.event.data,
    timezone: selectCurrentLocationTimeZone(store),
    metadata: ownProps.metadata,
  }),
  {
    getAppointment: appointment,
    getEvent: event,
  }
)(MetadataDetails);
