import React from 'react';
import { SmsModel } from '../../../../../models/sms-history.model';
import * as styles from '../sms-details.styles';

export type SmsPatientMessageProps = {
  smsDetails: SmsModel;
};

export const SmsPatientMessage = (props: SmsPatientMessageProps) => {
  return (
    <div>
      <p className={styles.blurAutoSetting}>Auto Message Response</p>
      <div className={styles.infoBox}>
        <label className={styles.infoBoxLeft}>Calculated Status:</label>
        <p className={styles.infoBoxRight}>
          {props.smsDetails.apt_calculated_status +
            ' ' +
            props.smsDetails.apt_calculated_status_details}
        </p>
      </div>
    </div>
  );
};
