import { keyBy } from 'lodash';
import { PhoneNumbersActions, PhoneNumbersActionTypes } from './phone-numbers.actions';
import { PhoneNumbersState } from './phone-numbers.types';

export const phoneNumbersReducer = (
  state: PhoneNumbersState = {},
  action: PhoneNumbersActions
): PhoneNumbersState => {
  switch (action.type) {
    case PhoneNumbersActionTypes.Add:
      return {
        ...state,
        ...keyBy(action.payload, (num) => num.id),
      };
    case PhoneNumbersActionTypes.Update:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...action.payload.phoneNumber,
        },
      };
    default:
      return state;
  }
};
