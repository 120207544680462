import { handleActions } from 'redux-actions';
import { filter } from 'lodash';

import {
  CallSettingsState,
  ForwardingNumber,
  ForwardingStatus,
  SMSNumber,
} from '../../../components/phones/call-settings-container/models';

import {
  addForwardingNumberFailure,
  addForwardingNumberSuccess,
  callSettings,
  callSettingsFailure,
  callSettingsSuccess,
  deleteForwardingNumberFailure,
  deleteForwardingNumberSuccess,
  fetchCallRecordingStatusSuccess,
  fetchForwardingStatusSuccess,
  fetchNumbersSuccess,
  fetchSmsNumbersSuccess,
  updateForwardingNumberFailure,
  updateForwardingNumberSuccess,
} from './call-settings.action';

const defaultState: CallSettingsState = {
  forwardingNumbers: [],
  forwardingStatus: new ForwardingStatus(),
  callRecordingStatus: false,
  activeSmsNumber: new SMSNumber(),
  smsNumberList: [],
  loading: true,
};

export const callSettingsReducer = handleActions<CallSettingsState, any>(
  {
    [callSettings.toString()]: (state: CallSettingsState) =>
      Object.assign({}, state, { loading: true }),
    [callSettingsSuccess.toString()]: (state: CallSettingsState) =>
      Object.assign({}, state, {
        loading: false,
      }),
    [callSettingsFailure.toString()]: (state: CallSettingsState, action) =>
      Object.assign({}, state, {
        error: action.payload,
        loading: false,
      }),
    [fetchNumbersSuccess.toString()]: (state: CallSettingsState, action) =>
      Object.assign({}, state, {
        forwardingNumbers: action.payload || [],
      }),
    [fetchForwardingStatusSuccess.toString()]: (state: CallSettingsState, action) =>
      Object.assign({}, state, {
        forwardingStatus: action.payload,
      }),
    [fetchCallRecordingStatusSuccess.toString()]: (state: CallSettingsState, action) =>
      Object.assign({}, state, {
        callRecordingStatus: action.payload,
      }),
    [fetchSmsNumbersSuccess.toString()]: (state: CallSettingsState, action) => {
      const smsNumberList = action.payload || [];
      const activeSmsNumber = smsNumberList.find((smsNumber: SMSNumber) => {
        return smsNumber.Active;
      });

      return Object.assign({}, state, {
        activeSmsNumber: activeSmsNumber || new SMSNumber(),
        smsNumberList,
      });
    },
    [addForwardingNumberSuccess.toString()]: (state: CallSettingsState, action) =>
      Object.assign({}, state, {
        forwardingNumbers: [...state.forwardingNumbers, action.payload],
      }),
    [addForwardingNumberFailure.toString()]: (state: CallSettingsState, action) =>
      Object.assign({}, state, {
        error: action.payload,
      }),
    [updateForwardingNumberSuccess.toString()]: (state: CallSettingsState, action) => {
      const updatedForwardingNumber: ForwardingNumber = action.payload;
      const forwardingNumbers = state.forwardingNumbers.map(
        (forwardingNumber: ForwardingNumber) => {
          return forwardingNumber.id === updatedForwardingNumber.id
            ? updatedForwardingNumber
            : forwardingNumber;
        }
      );

      return Object.assign({}, state, {
        forwardingNumbers,
      });
    },
    [updateForwardingNumberFailure.toString()]: (state: CallSettingsState, action) =>
      Object.assign({}, state, {
        error: action.payload,
      }),
    [deleteForwardingNumberSuccess.toString()]: (state: CallSettingsState, action) => {
      const forwardingNumbers = filter(
        state.forwardingNumbers,
        (forwardingNumber: ForwardingNumber) => forwardingNumber.id !== action.payload
      );

      return Object.assign({}, state, {
        forwardingNumbers,
      });
    },
    [deleteForwardingNumberFailure.toString()]: (state: CallSettingsState, action) =>
      Object.assign({}, state, {
        error: action.payload,
      }),
  },
  defaultState
);
