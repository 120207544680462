import { Reducer } from 'redux';
import { keyBy, omit } from 'lodash';
import { DepartmentActionTypes } from './department.types';
import { DepartmentActions, DepartmentState } from './';

export const departmentReducer: Reducer<DepartmentState, DepartmentActions> = (
  state = {},
  action
) => {
  switch (action.type) {
    case DepartmentActionTypes.Add:
      return keyBy(
        [...Object.values(state), ...action.payload],
        (department) => department.ID
      );
    case DepartmentActionTypes.Remove:
      return omit(state, action.payload);
    case DepartmentActionTypes.Update:
      return {
        ...state,
        [action.payload.ID]: {
          ...state[action.payload.ID],
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
