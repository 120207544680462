import { handleActions } from 'redux-actions';
import {
  getAvailableRoles,
  getAvailableRolesSuccess,
  setLoading,
  updateUserRoles,
  updateUserRolesSuccess,
} from './user-roles.action';

const initialState = {
  data: [],
  loading: false,
};

const userRolesReducer = handleActions(
  {
    [getAvailableRoles.toString()]: (state, action) =>
      Object.assign({}, state, { loading: true }),
    [getAvailableRolesSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { data: action.payload }),
    [updateUserRoles.toString()]: (state, action) =>
      Object.assign({}, state, { loading: true }),
    [updateUserRolesSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { loading: false }),
    [setLoading.toString()]: (state, action) =>
      Object.assign({}, state, { loading: action.payload }),
  },
  initialState
);

export default userRolesReducer;
