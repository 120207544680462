import React from 'react';
import { Text } from '@weave/design-system';
import { Modal, Button } from '@weave/platform-ui-components';
import { css } from 'emotion';

interface Props {
  image?: any;
  note?: string;
  onRequestClose: () => void;
  onConfirmClick: () => void;
  isOpen: boolean;
  confirmBtnTitle?: string;
  title: string;
  confirmBtnColor?: 'blue' | 'green' | 'red';
}

export const ConfirmationModal = ({ image, ...props }: Props) => {
  return (
    <Modal
      className={css`
        .react-modal-card {
          max-width: 500px;
          min-width: 360px;
          padding: 40px 24px;
        }
      `}
      title={props.title}
      onRequestClose={props.onRequestClose}
      isOpen={props.isOpen}
    >
      <div
        className={css`
          margin-top: 16px;
          text-align: center;
        `}
      >
        {image && image}
        {props.note && (
          <Text
            size="medium"
            className={css`
              display: block;
              margin-bottom: 32px;
            `}
          >
            {props.note}
          </Text>
        )}
        <div
          className={css`
            display: flex;
            justify-content: center;
            padding: 0 16px;
          `}
        >
          <Button
            className={css`
              margin-right: 16px;
              min-width: 100px;
              display: inline-block !important;
            `}
            color="gray"
            fill="outline"
            onClick={props.onRequestClose}
          >
            Cancel
          </Button>
          <Button
            className={css`
              min-width: 100px;
              display: inline-block !important;
            `}
            color={props.confirmBtnColor || 'blue'}
            onClick={props.onConfirmClick}
          >
            {props.confirmBtnTitle || 'Continue'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
