import React from 'react';
import { PrimaryButton } from '@weave/design-system';
import { css } from '@emotion/core';

type Props = {
  stepNames: string[];
  currentStep: number;
  onForwardButtonClicked: () => void;
  onBackButtonClicked: () => void;
  nextStepIsAvailable: boolean;
  prevStepIsAvailable: boolean;
};

export const Footer = ({
  stepNames,
  onForwardButtonClicked,
  onBackButtonClicked,
  currentStep,
  nextStepIsAvailable,
  prevStepIsAvailable,
}: Props) => {
  return (
    <div css={buttonGroup}>
      {prevStepIsAvailable && (
        <PrimaryButton css={buttonStyles} onClick={onBackButtonClicked}>
          {`Back To ${stepNames[currentStep - 1]}`}
        </PrimaryButton>
      )}
      {nextStepIsAvailable && (
        <PrimaryButton css={buttonStyles} onClick={onForwardButtonClicked}>
          {`Select ${stepNames[currentStep + 1]}`}
        </PrimaryButton>
      )}
    </div>
  );
};

const buttonGroup = css`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;
const buttonStyles = css`
  width: 318px;
  height: 48px;
  margin-bottom: 16px;

  @media only screen and (max-width: 680px) {
    width: 220px;
  }
`;
