import React from 'react';
import moment from 'moment';
import { Button } from '@weave/platform-ui-components';
import { css } from '@emotion/core';

import { TimeRangePicker } from './time-range-picker/time-range-picker.component';
import { ConfirmationModal } from '../../../../../shared/confirmation-modal/confirmation-modal.component';

interface Props {
  officeHour: {
    HoursStart: moment.Moment;
    HoursStop: moment.Moment;
    LunchHoursStart: moment.Moment;
    LunchHoursStop: moment.Moment;
    BreakHoursStart: moment.Moment;
    BreakHoursStop: moment.Moment;
  };
  handleModalOpenToggle: () => void;
  modalOpen: boolean;
  title: string;
  dayOfWeek: string;
  openHoursStatus: boolean;
  updateOfficeHours: (OfficeHoursApi) => void;
  editSave: boolean;
}

interface State {
  officeHours: any;
  officeOpen: boolean;
  lunchOpen: boolean;
  breakOpen: boolean;
  popoverOpen: boolean;
  canEdit: boolean;
  dirty: boolean;
  // initialOfficeStateClosed: boolean;
}

export class OfficeHoursCardEdit extends React.Component<Props, State> {
  closedOfficeHoursFormat = '00:00:00';

  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false,
      // initialOfficeStateClosed: this.props.officeHour.HoursStart.format('HH:mm:ss') === this.closedOfficeHoursFormat && this.props.officeHour.HoursStop.format('HH:mm:ss') === this.closedOfficeHoursFormat,
      canEdit: false,
      dirty: false,
      officeHours: {
        officeStart: this.props.officeHour.HoursStart,
        officeStop: this.props.officeHour.HoursStop,
        lunchStart: this.props.officeHour.LunchHoursStart,
        lunchStop: this.props.officeHour.LunchHoursStop,
        breakStart: this.props.officeHour.BreakHoursStart,
        breakStop: this.props.officeHour.BreakHoursStop,
      },
      officeOpen:
        this.props.officeHour.HoursStart.format() !==
        this.props.officeHour.HoursStop.format(),
      lunchOpen:
        this.props.officeHour.HoursStart.format() !==
          this.props.officeHour.HoursStop.format() &&
        this.props.officeHour.LunchHoursStart.format() !==
          this.props.officeHour.LunchHoursStop.format(),
      breakOpen:
        this.props.officeHour.HoursStart.format() !==
          this.props.officeHour.HoursStop.format() &&
        this.props.officeHour.BreakHoursStart.format() !==
          this.props.officeHour.BreakHoursStop.format(),
    };
  }

  handleOfficerHoursChange = (date, field) => {
    const officeHoursClone = { ...this.state.officeHours };
    officeHoursClone[field] = date;
    this.setState({
      dirty: true,
      officeHours: officeHoursClone,
    });
  };

  cancel = () => {
    this.props.handleModalOpenToggle();
  };

  isValid = () => {
    const {
      officeHours: {
        officeStart,
        officeStop,
        lunchStart,
        lunchStop,
        breakStart,
        breakStop,
      },
      officeOpen,
      lunchOpen,
      breakOpen,
    } = this.state;
    const officeDifference = officeStop.diff(officeStart);
    const lunchDifference = lunchStop.diff(lunchStart);
    const breakDifference = breakStop.diff(breakStart);
    if (!this.state.dirty) {
      return false;
    }

    if (officeOpen) {
      const officeValid = !officeOpen || (officeOpen && officeDifference > 0);
      const lunchValid = this.lunchIsValid(lunchOpen, lunchDifference);
      const breakValid = !breakOpen || (breakOpen && breakDifference > 0);
      return officeValid && lunchValid && breakValid;
    } else {
      return !lunchOpen && !breakOpen;
    }
  };

  // check if current times are equal or not, right now they can be equal, needs to change

  lunchIsValid = (lunchOpen, lunchDifference) => {
    return !lunchOpen || (lunchOpen && lunchDifference > 0);
  };

  save = () => {
    const newTime = moment().utc();
    newTime.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const officeHoursClone = { ...this.state.officeHours };
    // Toggle office hours on/off
    if (!this.state.officeOpen) {
      officeHoursClone.officeStart = newTime;
      officeHoursClone.officeStop = newTime;
      officeHoursClone.lunchStart = newTime;
      officeHoursClone.lunchStop = newTime;
      officeHoursClone.breakStart = newTime;
      officeHoursClone.breakStop = newTime;
    }
    // Toggle lunch break on/off
    if (!this.state.lunchOpen) {
      officeHoursClone.lunchStart = newTime;
      officeHoursClone.lunchStop = newTime;
    }
    // Toggle office break on/off
    if (!this.state.breakOpen) {
      officeHoursClone.breakStart = newTime;
      officeHoursClone.breakStop = newTime;
    }
    this.setState(
      {
        dirty: false,
        officeHours: officeHoursClone,
      },
      () => {
        this.props.updateOfficeHours({
          officeHours: this.state.officeHours,
          day: this.props.dayOfWeek,
        });
      }
    );
    this.cancel();
  };

  toggleOpenHours = () => {
    this.setState((currentState) => {
      let lunchIsOpen = currentState.lunchOpen;
      let breakIsOpen = currentState.breakOpen;
      if (currentState.officeOpen) {
        lunchIsOpen = false;
        breakIsOpen = false;
      }
      return {
        dirty: true,
        officeOpen: !currentState.officeOpen,
        lunchOpen: lunchIsOpen,
        breakOpen: breakIsOpen,
      };
    });
  };

  toggleLunchHours = () => {
    this.setState((prevState) => {
      return { lunchOpen: !prevState.lunchOpen, dirty: true };
    });
  };

  toggleBreakHours = () => {
    this.setState((prevState) => {
      return { breakOpen: !prevState.breakOpen, dirty: true };
    });
  };

  toggleConfirmation = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
      dirty: true,
    });
  };

  render() {
    const disableSave = !this.isValid();

    return (
      <>
        <ConfirmationModal
          title="Are you sure you want to save office hours?"
          isOpen={this.state.popoverOpen}
          onRequestClose={this.cancel}
          onConfirmClick={this.save}
          confirmBtnTitle="Confirm"
        />
        <TimeRangePicker
          title="Office"
          description="Opens/Closes the Office"
          open={this.state.officeOpen}
          start={this.state.officeHours.officeStart}
          stop={this.state.officeHours.officeStop}
          onChange={(open, start, stop) => {
            if (open !== this.state.officeOpen) {
              this.toggleOpenHours();
              return;
            } else if (this.state.officeHours.officeStart !== start) {
              this.handleOfficerHoursChange(start, 'officeStart');
            } else {
              this.handleOfficerHoursChange(stop, 'officeStop');
            }
          }}
        />
        <TimeRangePicker
          title="Lunch"
          description="Schedule Lunch"
          open={this.state.lunchOpen}
          start={this.state.officeHours.lunchStart}
          stop={this.state.officeHours.lunchStop}
          onChange={(open, start, stop) => {
            if (open !== this.state.lunchOpen) {
              this.toggleLunchHours();
              return;
            } else if (this.state.officeHours.lunchStart !== start) {
              this.handleOfficerHoursChange(start, 'lunchStart');
            } else {
              this.handleOfficerHoursChange(stop, 'lunchStop');
            }
          }}
        />
        <TimeRangePicker
          title="Break"
          description="Take a break"
          open={this.state.breakOpen}
          start={this.state.officeHours.breakStart}
          stop={this.state.officeHours.breakStop}
          onChange={(open, start, stop) => {
            if (open !== this.state.breakOpen) {
              this.toggleBreakHours();
              return;
            } else if (this.state.officeHours.breakStart !== start) {
              this.handleOfficerHoursChange(start, 'breakStart');
            } else {
              this.handleOfficerHoursChange(stop, 'breakStop');
            }
          }}
        />
        <div
          css={css`
            text-align: center;
          `}
        >
          <Button
            onClick={this.cancel}
            css={css`
              display: inline-block !important;
            `}
            fill="outline"
            color="gray"
          >
            Cancel
          </Button>
          <Button
            id="confirm-popover"
            onClick={this.toggleConfirmation}
            disabled={disableSave}
            css={css`
              display: inline-block !important;
              margin-left: 8px;
            `}
          >
            Save
          </Button>
        </div>
      </>
    );
  }
}
