import React from 'react';
import {
  AnalyticsIcon,
  EmailIcon,
  ExternalLinkIcon,
  ListIcon,
  SettingsIcon,
} from '@weave/design-system';

const actionMap = {
  appointment: ['changeLog', 'advanced_options', 'template', 'logs'],
  review: ['goToReview', 'template'],
  birthday: ['changeLog', 'advanced_options', 'template', 'logs'],
  order: ['changeLog', 'advanced_options', 'template', 'logs'],
  missedcall: ['changeLog', 'template', 'logs'],
  default: ['changeLog', 'advanced_options', 'template', 'logs'],
};

const actionIconMap = {
  template: EmailIcon,
  logs: AnalyticsIcon,
  advanced_options: SettingsIcon,
  goToReview: ExternalLinkIcon,
  changeLog: ListIcon,
};

const getActions = (type) => {
  return Object.keys(actionMap).includes(type) ? actionMap[type] : actionMap.default;
};
interface ReminderActionButtonsInterface {
  id: string;
  actionRouter: Function;
  type: string;
  className?: string;
  setActions?: string[];
  coloredIcons?: string[];
}

export const ReminderActionButtons = ({
  id,
  className = '',
  actionRouter,
  type,
  setActions = [],
  coloredIcons = [],
}: ReminderActionButtonsInterface) => {
  const actions = setActions.length ? setActions : getActions(type);

  return (
    <span className={className}>
      {actions.map((action) => {
        const Icon = actionIconMap[action];

        return (
          <Icon
            color={coloredIcons.includes(action) ? 'warn' : 'light'}
            key={`modal-${action}-${id}`}
            onClick={actionRouter(action)}
          />
        );
      })}
    </span>
  );
};
