import { LocationModel } from '../redux/actions/location/locations/locations.types';

export type LocationHistory = {
  history: LocationHistoryModel;
};

export class LocationHistoryModel {
  id: string;
  name: string;
  slug: string;
  created: number;

  constructor(location: LocationModel) {
    this.id = location.LocationID;
    this.name = location.Name;
    this.slug = location.Slug;
    this.created = Date.now();
  }
}
