import isBefore from 'date-fns/isBefore';
import { createRequestStateSelector } from '@weave/alert-system';

import { Store } from '../../store/store.model';
import { PhoneMediaActionTypes } from './phone-media.actions';
import { PhoneMedia, PhoneMediaState } from './phone-media.reducer';
import { MediaState } from '../media/media.reducer';
import { selectMedia } from '../media/media.selectors';
import { selectCurrentLocationId } from '../location/current-location';
import { createSelector } from 'reselect';
import { selectAuthToken } from '../auth/auth.selectors';

const selectPhoneMediaState = (state: Store) => state.phoneMedia;

export const selectCurrentLocationPhoneMediaIds = createSelector(
  selectPhoneMediaState,
  selectCurrentLocationId,
  (phoneMedia: PhoneMediaState, locationId): string[] => phoneMedia[locationId]
);

const sortByCreatedAt = (a: PhoneMedia, b: PhoneMedia): number => {
  if (a.CreatedAt === b.CreatedAt) {
    return 0;
  } else if (isBefore(new Date(a.CreatedAt), new Date(b.CreatedAt))) {
    return 1;
  } else {
    return -1;
  }
};

export const selectPhoneMedia = createSelector(
  selectMedia,
  selectCurrentLocationPhoneMediaIds,
  (media: MediaState, phoneMediaIds: string[] = []): PhoneMedia[] =>
    Object.values(media)
      .filter((mediaFile) => phoneMediaIds.indexOf(mediaFile?.MediaID) > -1)
      .sort(sortByCreatedAt)
);

const selectPhoneMediaGetRequestState = createRequestStateSelector(
  PhoneMediaActionTypes.RequestGet
);

const selectPhoneMediaUpdateRequestState = createRequestStateSelector(
  PhoneMediaActionTypes.RequestUpdate
);

const selectPhoneMediaUploadRequestState = createRequestStateSelector(
  PhoneMediaActionTypes.RequestUpload
);

const selectPhoneMediaDeleteRequestState = createRequestStateSelector(
  PhoneMediaActionTypes.RequestDelete
);

const selector = (store: Store): PhoneMediaState => store.phoneMedia;

export const selectPhoneMediaLoading = createSelector(
  selector,
  selectPhoneMediaGetRequestState,
  selectPhoneMediaDeleteRequestState,
  selectPhoneMediaUpdateRequestState,
  selectPhoneMediaUploadRequestState,
  (
    state,
    { loading: getting },
    { loading: deleting },
    { loading: updating },
    { loading: uploading }
  ): boolean => getting || deleting || updating || uploading
);

export const phoneMediaSelector = createSelector(
  selectCurrentLocationId,
  selectAuthToken,
  selectPhoneMedia,
  selectPhoneMediaLoading,
  (locationId, token, phoneMedia, loadingPhoneMedia) => ({
    locationId,
    token,
    phoneMedia,
    loadingPhoneMedia,
  })
);
