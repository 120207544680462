import { IndexedContent } from '../../header-container/wam-search/use-wam-search';
import { OutageAlertExtended } from '../outage-alerts.types';

interface PostRoutesFetch {
  routesData: IndexedContent[];
  isLoading: boolean;
}

interface PreOutageFetch {
  showOutageAlertForm: boolean;
  isLoading: boolean;
  outageAlertsData: OutageAlertExtended[];
}

interface PostOutageFetch {
  isLoading: boolean;
  outageAlertsData: OutageAlertExtended[];
}

interface SetAndShowOutageAlertForm {
  showOutageAlertForm: boolean;
  currentOutageAlertDetails: OutageAlertExtended | {};
}

type OutageAlertsDetailsActions =
  | {
      type: 'SET_OUTAGE_ALERT_DATA';
      payload: { outageAlertsData: OutageAlertExtended[] };
    }
  | {
      type: 'SET_CURRENT_OUTAGE_ALERT';
      payload: { currentOutageAlertDetails: OutageAlertExtended | {} };
    }
  | {
      type: 'SET_SHOW_ALERT_FORM';
      payload: { showOutageAlertForm: boolean };
    }
  | {
      type: 'SET_ROUTES_DATA';
      payload: { routesData: IndexedContent[] };
    }
  | {
      type: 'SET_LOADING';
      payload: { isLoading: boolean };
    }
  | {
      type: 'SET_DISABLE_ADD';
      payload: { disableAdd: boolean };
    }
  | {
      type: 'POST_ROUTES_FETCH';
      payload: PostRoutesFetch;
    }
  | {
      type: 'PRE_OUTAGE_FETCH';
      payload: PreOutageFetch;
    }
  | {
      type: 'SET_AND_SHOW_OUTAGE_ALERT_FORM';
      payload: SetAndShowOutageAlertForm;
    }
  | {
      type: 'POST_OUTAGE_ALERT_FETCH';
      payload: PostOutageFetch;
    };

export type OutageAlertsDetailsStatesReducerState = {
  outageAlertsData: OutageAlertExtended[];
  currentOutageAlertDetails: OutageAlertExtended | {};
  showOutageAlertForm: boolean;
  routesData: IndexedContent[];
  isLoading: boolean;
  disableAdd: boolean;
};

export const setDisableAdd = (disableAdd: boolean): OutageAlertsDetailsActions => ({
  type: 'SET_DISABLE_ADD',
  payload: { disableAdd: disableAdd },
});

export const setIsLoading = (isLoading: boolean): OutageAlertsDetailsActions => ({
  type: 'SET_LOADING',
  payload: { isLoading: isLoading },
});

export const setShowOutageAlertForm = (
  showOutageAlertForm
): OutageAlertsDetailsActions => ({
  type: 'SET_SHOW_ALERT_FORM',
  payload: { showOutageAlertForm: showOutageAlertForm },
});

export const postRouteFetch = (payload: PostRoutesFetch): OutageAlertsDetailsActions => ({
  type: 'POST_ROUTES_FETCH',
  payload: payload,
});

export const preOutageFetch = (payload: PreOutageFetch): OutageAlertsDetailsActions => ({
  type: 'PRE_OUTAGE_FETCH',
  payload: payload,
});

export const postOutageAlertFetch = (
  payload: PostOutageFetch
): OutageAlertsDetailsActions => ({
  type: 'POST_OUTAGE_ALERT_FETCH',
  payload: payload,
});

export const setAndShowOutageAlertForm = (
  payload: SetAndShowOutageAlertForm
): OutageAlertsDetailsActions => ({
  type: 'SET_AND_SHOW_OUTAGE_ALERT_FORM',
  payload: payload,
});

export const outageAlertsDetailsStatesReducer = (
  state: OutageAlertsDetailsStatesReducerState,
  action: OutageAlertsDetailsActions
): OutageAlertsDetailsStatesReducerState => {
  switch (action.type) {
    case 'SET_OUTAGE_ALERT_DATA':
      return { ...state, outageAlertsData: action.payload.outageAlertsData };
    case 'SET_CURRENT_OUTAGE_ALERT':
      return {
        ...state,
        currentOutageAlertDetails: action.payload.currentOutageAlertDetails,
      };
    case 'SET_SHOW_ALERT_FORM':
      return { ...state, showOutageAlertForm: action.payload.showOutageAlertForm };
    case 'SET_ROUTES_DATA':
      return { ...state, routesData: action.payload.routesData };
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload.isLoading };
    case 'SET_DISABLE_ADD':
      return { ...state, disableAdd: action.payload.disableAdd };
    case 'POST_ROUTES_FETCH':
      return {
        ...state,
        routesData: action.payload.routesData,
        isLoading: action.payload.isLoading,
      };
    case 'PRE_OUTAGE_FETCH':
      return {
        ...state,
        showOutageAlertForm: action.payload.showOutageAlertForm,
        isLoading: action.payload.isLoading,
        outageAlertsData: action.payload.outageAlertsData,
      };
    case 'SET_AND_SHOW_OUTAGE_ALERT_FORM':
      return {
        ...state,
        showOutageAlertForm: action.payload.showOutageAlertForm,
        currentOutageAlertDetails: action.payload.currentOutageAlertDetails,
      };
    case 'POST_OUTAGE_ALERT_FETCH':
      return {
        ...state,
        isLoading: action.payload.isLoading,
        outageAlertsData: action.payload.outageAlertsData,
      };
    default:
      return state;
  }
};
