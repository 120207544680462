import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { NakedButton, WeaveLogoIcon } from '@weave/design-system';

import { LocationTab } from '../location-tab/location-tab.component';
import { ProfileInfo } from '../profile-info/profile-info.component';
import { history } from '../../../redux/store/history';
import {
  selectCurrentLocation,
  setCurrentLocationId,
  unsetCurrentLocationId,
} from '../../../redux/actions/location';
import { removeLocation, selectLocationsArray } from '../../../redux/actions/location';
import { selectIsAuthenticated } from '../../../redux/actions/auth/auth.selectors';
import { headerStyles } from './header.styles';

export const Header = () => {
  const currentLocation = useSelector(selectCurrentLocation);
  const locations = useSelector(selectLocationsArray);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const dispatch = useDispatch();

  const removeSelectedLocation = (locationId: string) =>
    dispatch(removeLocation(locationId));
  const selectLocation = (locationId: string) => {
    if (locationId !== currentLocation?.LocationID) {
      dispatch(setCurrentLocationId(locationId));
    }
  };
  const unsetLocation = () => dispatch(unsetCurrentLocationId());

  return (
    <header className="header" css={headerStyles}>
      <div className="container-fluid">
        <div className="group">
          <div className="group">
            <div className="logo">
              <NakedButton
                css={css`
                  align-items: center;
                  color: ${theme.colors.white};
                  display: flex;
                  font-size: ${theme.fontSize(16)};
                  margin-left: ${theme.spacing(1)};
                  width: 100px;
                `}
                onClick={() => {
                  history.push('/');
                  unsetLocation();
                }}
              >
                <span
                  css={css`
                    font-size: ${theme.fontSize(20)};
                    padding: 2px ${theme.spacing(1)} 0 0;
                  `}
                >
                  WAM
                </span>
                <WeaveLogoIcon color="white" />
              </NakedButton>
            </div>
          </div>
          {isAuthenticated && (
            <div className="group">
              <div className="location-tabs">
                {locations.map((location) => {
                  return (
                    <LocationTab
                      key={location.LocationID}
                      active={location.LocationID === currentLocation?.LocationID}
                      location={location}
                      removeLocation={removeSelectedLocation}
                      selectLocation={selectLocation}
                    />
                  );
                })}
              </div>
              <ProfileInfo />
            </div>
          )}
        </div>
      </div>
    </header>
  );
};
