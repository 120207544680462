import React, { FC } from 'react';
import { WeaveTheme } from '@weave/theme-original';
import { IconProps, Text } from '@weave/design-system';

import { tabHeader, activeTabHeader } from './sync-app-card.styles';

interface Props {
  setActiveTab: (val: string | any) => void;
  icon: FC<IconProps>;
  activeStatus: boolean;
  label: string;
  syncTab: string;
}

export const SyncAppTabHeader = ({
  icon,
  activeStatus,
  label,
  setActiveTab,
  syncTab,
}: Props) => {
  const handleClick = () => {
    setActiveTab(syncTab);
  };

  const SvgComponent = icon;

  return (
    <div
      css={(theme: WeaveTheme) => [
        tabHeader(theme),
        activeStatus && activeTabHeader(theme),
      ]}
      onClick={handleClick}
    >
      <SvgComponent {...(activeStatus && { color: 'primary' })} />
      <Text as="span" weight="bold">
        {label}
      </Text>
    </div>
  );
};
