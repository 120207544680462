export interface SmsModel {
  id: string;
  sms_id?: string;
  locationId?: string;
  location_slug?: string;
  workstation_id?: string;
  workstation_name?: string;
  household_id?: string;
  personId?: string;
  patient_first_name?: string;
  patient_last_name?: string;
  patient_preferred_name?: string;
  patient_gender?: string;
  photo?: string;
  date_completed?: string;
  completed_by_id?: string;
  read_by_id?: string;
  read_by_name_first?: string;
  read_by_name_last?: string;
  read_by_username?: string;
  createdAt: string;
  createdBy?: string | null;
  created_by_name_first?: string;
  created_by_name_last?: string;
  created_by_username: string;
  created_by_photo?: string;
  personPhone: string;
  direction: MsgDirection;
  type?: string;
  to?: string;
  body?: string;
  status: string;
  statusDetails?: string;
  autogeneratedBy: string;
  apt_id?: string;
  deleted?: boolean;
  pushed?: boolean;
  apt_calculated_status?: string;
  apt_calculated_status_details?: string;
  apt_writeback_id?: string;
  sentiment_dismissed_at?: string;
  apt_status?: string;
  apt_datetime?: string;
  apt_type?: string;
  apt_practitioner?: string;
  type_id?: string;
  weave_number?: string;
  num_media?: number;
  relatedIds?: RelatedIds[];
}

export type MsgDirection =
  | 'DIRECTION_OUTBOUND'
  | 'DIRECTION_INBOUND'
  | 'DIRECTION_UNSPECIFIED';

export enum MsgDirectionType {
  Outbound = 'DIRECTION_OUTBOUND',
  Inbound = 'DIRECTION_INBOUND',
  Unspecified = 'DIRECTION_UNSPECIFIED',
}

interface RelatedIds {
  id: string;
  type: RelatedIdTypes;
}

type RelatedIdTypes =
  | 'RELATED_TYPE_UNSPECIFIED'
  | 'RELATED_TYPE_APPOINTMENT'
  | 'RELATED_TYPE_ORDER'
  | 'RELATED_TYPE_RECALL'
  | 'RELATED_TYPE_PERSON'
  | 'RELATED_TYPE_MEDIA'
  | 'RELATED_TYPE_GROUP_QUICK_FILL'
  | 'RELATED_TYPE_GROUP_SCHEDULE'
  | 'RELATED_TYPE_REVIEW'
  | 'RELATED_TYPE_MISSED_CALL'
  | 'RELATED_TYPE_BIRTHDAY'
  | 'RELATED_TYPE_ONLINE_SCHEDULE_REQUEST'
  | 'RELATED_TYPE_MMS_CONSENT_REQUEST';
